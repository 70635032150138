import { Component, OnInit } from '@angular/core';
import { Organisation, OrganisationService } from 'src/app/organisation/organisation.service';
import { Contract, ContractService } from '../contract.service';
import { DateRange } from 'src/app/shared/components';
import * as momentJ from 'moment-jalaali';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { PgMessagesService, MessageLevel } from 'src/app/shared/services/pg-messages.service';

@Component({
  selector: 'app-data-entry',
  templateUrl: './contract-data-entry.component.html',
})
export class ContractDataEntryComponent implements OnInit {

  organisations: Organisation[];
  sellers: Organisation[];
  buyers: Organisation[];
  destinations: Organisation[];
  transporterOrganisations: Organisation[];
  sources: Organisation[];
  currencies: string[] = ['دلار', 'درهم'];
  goods: string[] = ['LPG', 'propane', 'butane', 'gasoline'];
  selectedContractId: string;
  transporters: any[] = [];
  source: Organisation;
  seller: Organisation;
  buyer: Organisation;

  registrationDate: DateRange;
  contract: Contract;
  duration: DateRange;

  mode: string;
  modeString: string;

  success: boolean;
  showMessage: boolean;
  message: string;
  disabled = false;
  remove: boolean;

  constructor(private _contractService: ContractService,
              private _organisationService: OrganisationService,
              private _activatedRoute: ActivatedRoute,
              private _pgMessagesService: PgMessagesService) { }

  ngOnInit() {
    // this.clear();
    this._activatedRoute.paramMap.subscribe(p => {
      this.mode = p.get('mode');
      this.disabled = (p.get('mode') === 'edit');
      forkJoin([
        this._organisationService.getLoadingsLocations(),
        this._organisationService.getDischargeLocations(),
        this._organisationService.getSellers(),
        this._organisationService.getBuyers(),
        this._organisationService.getTransporters()
      ]).subscribe(results => {
        this.sources = results[0];
        this.destinations = results[1];
        this.sellers = results[2];
        this.buyers = results[3];
        this.transporterOrganisations = results[4];
        if (this.mode === 'new') {
          this.selectedContractId = null;
          this.duration = {
            startDate: momentJ().endOf('day').toDate(),
            endDate: momentJ().startOf('day').add(10, 'd').toDate(),
          };
          this.registrationDate = momentJ().startOf('day').toDate();
          this.transporters.push(null);
          this.initContract();
        } else if (this.mode !== 'new') {
          this.selectedContractId = p.get('contractNo');
          this._contractService.get(this.selectedContractId).subscribe(cnt => {
            this.contract = cnt;
            this.source = this.sources.find(s => cnt.sourceOrganisationId === s.name);
            this.seller = this.sellers.find(s => s.name === cnt.sellerOrganisationId);
            this.buyer = this.buyers.find(b => b.name === cnt.buyerOrganisationId);
            this.duration = {
              startDate: new Date(this.contract.startDate),
              endDate: new Date(this.contract.expirationDate),
            };
            this.contract.transporterOrganisationId.forEach(t => {
              this.transporters.push(this.transporterOrganisations.find(d => d.name === t));
              if (this.transporters.length < 2) {
                this.remove = false;
              }
            });
            this.registrationDate = {
              startDate: new Date(this.contract.registrationDate),
              endDate: new Date(this.contract.registrationDate)
            };
            this.duration = {
              startDate: new Date(this.contract.startDate),
              endDate: new Date(this.contract.expirationDate)
            };
          });
        }
      });
    });

  }


  dateValidator(date: Date) {
    const now = new Date();
    if (date < now) {
      return false;
    } else {
      return true;
    }
  }

  removeTransporter(index: number) {
    this.transporters.splice(index, index + 1);
    if (this.transporters.length < 2) {
      this.remove = false;
    }
  }

  contractNumberGenerator() {
    const sellerId = this.contract.sellerOrganisationId.toUpperCase().split('.');
    const buyerId = this.contract.buyerOrganisationId.toUpperCase().split('.');
    return this.registrationDate.startDate.getFullYear() + '.' +
      (this.registrationDate.startDate.getMonth() + 1) + '.' +
      this.registrationDate.startDate.getDate() + '.' +
     sellerId[sellerId.length - 1].substring(0, 4) + '.' +
     buyerId[buyerId.length - 1].substring(0, 4); // + '.' +
    //  transporterId[transporterId.length - 1].substring(0, 4) ;
  }

  addNewTransporter() {
    const transporter: Organisation = null;
    // = {
    //   name: null,
    //   internalName: null,
    //   address: null,
    //   manager: null,
    //   parentOrganisation: null,
    //   nameTranslations: null,
    //   properties: null,
    //   location: null,
    //   timezone: null
    // };
    this.transporters.push(transporter);
    if (this.transporters.length > 1) {
      this.remove = true;
    }
  }

  initContract() {
      if (this.mode === 'new') {
        this.contract = {
          contractNo : '',
          registrationDate: this.registrationDate.startDate.getTime(),
          sellerOrganisationId: null,
          buyerOrganisationId: null,
          transporterOrganisationId: null,
          startDate: this.duration.startDate.getTime(),
          expirationDate: this.duration.endDate.getTime(),
          goodId: 'LPG',
          quantity: null,
          destinationOrganisationId: '',
          verify: false,
          sourceOrganisationId: null,
          internalContractNo: null
        };
      }
    }


  onInsert() {
    this.contract.sourceOrganisationId = this.source.name;
    this.contract.buyerOrganisationId = this.buyer.name;
    this.contract.sellerOrganisationId = this.seller.name;
    this.contract.transporterOrganisationId = this.transporters.map(t => t.name);
    if (this.mode === 'new') {
      this.contract.contractNo = this.contractNumberGenerator();
      this.contract.registrationDate =  this.registrationDate.startDate.getTime();
      this.contract.startDate = this.duration.startDate.getTime();
      this.contract.expirationDate = this.duration.endDate.getTime();
      this._contractService.add(this.contract).subscribe(() => {
        this.message = 'Contract No ' + this.contract.contractNo + ' imported';
        this._pgMessagesService.publishMessage({
          message: this.message,
          topic: 'New Contract',
          level: MessageLevel.Info
        });
      } , () => {
        this.message = 'Error';
        this._pgMessagesService.publishMessage({
          message: this.message,
          topic: 'Error Contract',
          level: MessageLevel.Error
        });
      });
    } else if (this.mode !== 'new') {
      this.contract.startDate = this.duration.startDate.getTime();
      this.contract.expirationDate = this.duration.endDate.getTime();
      this._contractService.update(this.contract).subscribe(() => {
        this.message = 'Contract No ' + this.contract.contractNo + ' edited';
        this._pgMessagesService.publishMessage({
          message: this.message,
          topic: 'Edit Contract',
          level: MessageLevel.Info
        });
      } , () => {
        this.message = 'Error';
        this._pgMessagesService.publishMessage({
          message: this.message,
          topic: 'New Contract',
          level: MessageLevel.Error
        });
      });
    }
  }

}

import { Component, EventEmitter, OnInit } from '@angular/core';
import { BunkerType, OffHireBunker, OffHireItem, Vessel, VesselOffHire, VesselsService } from '../vessels.service';
import { forkJoin } from 'rxjs';
import { KeycloakService } from 'src/app/authentication/keycloak.service';
import { Organisation, OrganisationService } from 'src/app/organisation/organisation.service';
import { DateRange } from 'src/app/shared/components';
import { DateTimeHelpers } from 'src/app/shared/helpers/date-time.helpers';
import { ActionType, DataBaseActionService } from 'src/app/shared/services/database-action.service';
import { DocumentReference } from 'src/app/documents/documents.service';
import { PgTitleComponent } from 'src/app/shared/components/title-bar/title-bar.component';
import { ActivatedRoute } from '@angular/router';
import { MessageLevel, PgMessagesService } from 'src/app/shared/services/pg-messages.service';

@Component({
  selector: 'app-vessel-off-hire',
  templateUrl: './vessel-off-hire.component.html'
})
export class VesselOffHireComponent implements OnInit, PgTitleComponent {
  title = new EventEmitter<string>();

  vessels: Vessel[];
  _vessel: Vessel;
  ports: Organisation[];
  fromPort: Organisation;
  toPort: Organisation;
  fromDate: DateRange;
  toDate: DateRange;
  bunker: string;
  status: string;
  offHireType: string;
  amount: number;
  bunkers: OffHireBunker[] = [];
  productName = [
    BunkerType.HFO,
    BunkerType.VLSFO,
    BunkerType.ULSFO,
    BunkerType.MGO,
    BunkerType.LSMGO
  ];

  documentId: string;

  offHireStatus: Record<string, string> = {
    potential: 'Potential',
    claimed: 'Calimed',
    accepted: 'Accepted'
  };

  offHireTypes: Record<string, string> = {
    dryDock: 'Dry Dock',
    breakDown: 'Break Down',
  };

  vesselOffHire: VesselOffHire = {
    id: '',
    documentId: null,
    vesselIMO: '',
    status: '',
    offHireType: '',
    from: undefined,
    to: undefined,
    bunker: [],
    relatedOffHire: null,
    usd: 0,
    isDeleted: false,
    actions: []
  };

  offHires: VesselOffHire[];
  relatedOffHire: VesselOffHire;

  offHireFormatter: (vo: VesselOffHire) => string;

  set vessel(v: Vessel) {
    if (v) {
      this._vesselService.getALLVesselOffHire(v.IMO).subscribe(off => {
        this.offHires = off;
        this.offHireFormatter = (vessekOffHire: VesselOffHire) => {
          const from = this.ports.find(p => p.name === vessekOffHire.from.locationId)?.nameTranslations['en'];
          const to = this.ports.find(p => p.name === vessekOffHire.to.locationId).nameTranslations['en'];
          return `
          ${this.offHireTypes[vessekOffHire.offHireType]} 
            ${this.offHireStatus[vessekOffHire.status]} 
            ${from} 
            ${vessekOffHire.from.time.split('T')[0]} to  
            ${to} 
            ${vessekOffHire.to.time.split('T')[0]}`;
        };
      });
      this._vessel = v;
    }
  }

  get vessel() {
    return this._vessel;
  }

  constructor(
    private _vesselService: VesselsService,
    private _keycloakService: KeycloakService,
    private _organisationService: OrganisationService,
    private _actionService: DataBaseActionService,
    private _activateRoute: ActivatedRoute,
    private _pgMessagesService: PgMessagesService
  ) { }

  ngOnInit() {
    this.title.emit('Vessel Off hire');
    this._activateRoute.paramMap.subscribe(p => {
      const id = p.get('id');
      forkJoin([
        this._vesselService.getActiveVessels(),
        this._organisationService.getPorts(),
      ]).subscribe(results => {
        this.vessels = results[0];
        this.ports = results[1];
        if (id) {
          this._vesselService.getOneOffHire(id).subscribe(offHire => {
            this.vesselOffHire = offHire;
            this._vessel = results[0].find(v => v.IMO === offHire.vesselIMO);
            this.documentId = offHire.documentId;
            this.fromPort = this.ports.find(p => p.name === offHire.from.locationId);
            this.fromDate = {
              startDate: new Date(DateTimeHelpers.getLocalDateIsoStringToShow(offHire.from.time)),
              endDate: new Date(DateTimeHelpers.getLocalDateIsoStringToShow(offHire.from.time))
            };
            this.toPort = this.ports.find(p => p.name === offHire.to.locationId);
            this.toDate = {
              startDate: new Date(DateTimeHelpers.getLocalDateIsoStringToShow(offHire.to.time)),
              endDate: new Date(DateTimeHelpers.getLocalDateIsoStringToShow(offHire.to.time))
            };
            this.bunkers = offHire.bunker;
            this.amount = offHire.usd;
            this.offHireType = offHire.offHireType;
            this.status = offHire.status;
          });
        }
      });
      this.bunkers.push({ amount: null, bunkerType: null });
    });
  }

  vesselFormatter(v: Vessel) {
    return v.name.name;
  }

  hasRole(role: string) {
    return this._keycloakService.hasRole(role);
  }

  dateChange(date: DateRange, selectedPort: string) {
    const timeZoneId = this._organisationService.getOrganisationTimezone(selectedPort, this.ports);
    const d = DateTimeHelpers.getISODateStringZoneId(date.startDate, timeZoneId);
    return d;
  }

  newBunker() {
    this.bunkers.push({ amount: null, bunkerType: null });
  }

  uploadDocument(r: DocumentReference) {
    this.documentId = r.id;
    this.vesselOffHire.documentId = r.id;
  }

  save() {
    const from: OffHireItem = {
      locationId: this.fromPort.name,
      time: this.dateChange(this.fromDate, this.fromPort.name)
    };

    const to: OffHireItem = {
      locationId: this.toPort.name,
      time: this.dateChange(this.toDate, this.toPort.name)
    };
    this.vesselOffHire.id = '00000000-0000-0000-0000-000000000000';
    this.vesselOffHire.vesselIMO = this._vessel.IMO;
    this.vesselOffHire.status = this.status;
    this.vesselOffHire.offHireType = this.offHireType;
    this.vesselOffHire.from = from;
    this.vesselOffHire.to = to;
    this.vesselOffHire.bunker = this.bunkers;
    this.vesselOffHire.relatedOffHire = this.relatedOffHire.id;
    this.vesselOffHire.usd = this.amount;
    this.vesselOffHire.isDeleted = false;
    this.vesselOffHire.actions = [this._actionService.userAction(ActionType.Update)];
    this._vesselService.addOffHire(this.vesselOffHire).subscribe(() => {
      this._pgMessagesService.publishMessage({
        message: `The ${this._vessel.name.name} off hire imported`,
        topic: 'New Off  hire Added',
        level: MessageLevel.Info
      });
    }, () => {
      this._pgMessagesService.publishMessage({
        message: `Error`,
        topic: 'Off hire Insertion Error',
        level: MessageLevel.Error
      });
    });
  }
}

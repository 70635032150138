import { Component } from '@angular/core';

import * as L from 'leaflet';
import { Map } from 'leaflet';
import 'iso8601-js-period';
import 'leaflet.markercluster';
import 'leaflet-toolbar';
import 'leaflet-easyprint';
import 'leaflet-timedimension';
import 'leaflet.fullscreen';
import { GeoCoordinate } from '../models/GeoCoordinate';
import { DeviceLocation, GPSTrackerCoordinate } from '..';
import { MapMarker, OpenStreetService } from './open-street.service';

export interface Geometry {
  type: string;
  coordinates: number[];
}

export interface PointProp {
  name: string;
  locations?: GeoCoordinate[];
  lastLocation: DeviceLocation;
  color: string;
  opacity?: number;
}

export interface OpenstreetPoint {
  type: string;
  properties: MapMarker;
  geometry: Geometry;
}

@Component({
  selector: 'app-open-street',
  templateUrl: './open-street.component.html'
})
export class OpenStreetComponent {
  _map: Map;
  _mainLayer: any;
  _routeLayer: any;
  _clusterLayer: any;

  constructor(private _openStreetService: OpenStreetService) {
    _openStreetService.openStreetmarkersUpdated$.subscribe(markers => {
      this.setupMap();
      this.drawLatestLocations(markers);
    });

    _openStreetService.markersReset$.subscribe(() => {
      this.resetmarkers();
    });

    _openStreetService.pathUpdated$.subscribe(s => {
      this.drawRoutMap(s);
    });

    _openStreetService.fitMapBound$.subscribe(s => {
      this.fitMapBound(s);
    });

    _openStreetService.clusterMarkersRequested.subscribe(s => {
      this.drowClusteredLayer(s);
    });
  }

  setupMap() {
    if (!this._map) {
      const baseLayer = L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png', {
        attribution: `&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> &copy;
        <a href="https://carto.com/attributions">CARTO</a>`
      });

      const mapOptions = {
        zoomControl: false,
        zoom: 14,
        layers: [baseLayer]
      };

      this._map = L.map('mapid', mapOptions);

      L.control.zoom({position: 'topleft'}).addTo(this._map);
      L.control.fullscreen().addTo(this._map);

      L.easyPrint({
        title: 'Print the map',
        position: 'topleft',
        sizeModes: ['A4Landscape']
      }).addTo(this._map);

      L.Toolbar2.Action.extend({
        initialize(map, myAction) {
          this.map = map;
          this.myAction = myAction;
          L.Toolbar2.Action.prototype.initialize.call(this);
        },
        addHooks() {
          this.myAction.disable();
        }
      });
    }
  }

  resetmarkers() {
    if (this._mainLayer) {
      this._map.removeLayer(this._mainLayer);
    }
  }


  drawLatestLocations(markers: MapMarker[]) {
    const mainLayer = this.getLatestLocationLayer(markers);
    if (markers.length > 1) {
      this.removeLayers();
    }
    this._mainLayer = mainLayer;
    this._map.addLayer(mainLayer);
  }

  drawRoutMap(points: GPSTrackerCoordinate[]) {
    if(this._routeLayer) {
      this._map.removeLayer(this._routeLayer);
      this._routeLayer = null;
    }
    if (points) {
      this._routeLayer = L.polyline(points.map(g => [g.geoCoordinate.latitude, g.geoCoordinate.longitude])).addTo(this._map);
    }
  }

  removeLayers() {
    if(this._routeLayer) {
      this._map.removeLayer(this._routeLayer);
      this._routeLayer = null;
    }
    if(this._mainLayer) {
      this._map.removeLayer(this._mainLayer);
      this._mainLayer = null;
    }
    if(this._clusterLayer) {
      this._map.removeLayer(this._clusterLayer);
      this._clusterLayer = null;
    }
  }

  drowClusteredLayer(mapMarkers: MapMarker[]) {
    this.removeLayers();
    this._clusterLayer = L.markerClusterGroup(
      {
			maxClusterRadius: 50,
			spiderfyOnMaxZoom: false,
      showCoverageOnHover: false,
		});
    mapMarkers.map(mapMarker => {
      const m = this.getMainLocationMarker(mapMarker);
      this._clusterLayer.addLayer(m);
    });
    this._map.addLayer(this._clusterLayer);
  }

  getMainLocationMarker(mapMarker: MapMarker) {
    const markerOptions: L.MarkerOptions = {
      icon: L.divIcon({
        html: `<i class="fa fa-caret-right" style="font-size:20px; color:${mapMarker.color}; opacity: 1;
          transform: rotate(${mapMarker.rotation - 90}deg);">`,
        iconSize: [10, 10],
        className: 'myDivIcon',
        iconAnchor: [0, 10]
      }),
    };

    const marker = L.marker([mapMarker.geoCoordinate.latitude, mapMarker.geoCoordinate.longitude], markerOptions)
      .bindPopup(mapMarker.infoWindowContent, {maxWidth: 500, closeButton: false,});
    if (mapMarker.popUpIsOpen) {
      marker.on('add', (event) => {
        event.target.openPopup();
      });
    }
    marker.on('click',  () => {
      this._openStreetService.markerSelected(mapMarker);
    });
    return marker;
  }

  fitMapBound(bounds: GeoCoordinate[]) {
    this._map.fitBounds(bounds.map(m => [m.latitude, m.longitude]));
  }

  private getLatestLocationLayer(trucks: MapMarker[]) {
    const pointGeoJson: OpenstreetPoint[] = trucks.map(marker => ({
        type: 'Feature',
        properties: marker,
        geometry: {
          type: 'Point',
          coordinates: [marker.geoCoordinate.latitude, marker.geoCoordinate.longitude]
        }
      }));

    return L.geoJSON(pointGeoJson as any, {
      pointToLayer: (feature) => {
        if (feature.geometry.coordinates) {
          return this.getMainLocationMarker(feature.properties);
        }
      },
      filter: () => true
    });
  }

}

import {Component, EventEmitter, NgZone, OnInit} from '@angular/core';
import { Subject } from 'rxjs';
import { UserProfile, DigitalIdFile, SecurityService } from '../security.service';
import {DataTablesService} from '../../shared/datatables/data-tables.service';
import {Router} from '@angular/router';
import {PgTitleComponent} from '../../shared/components/title-bar/title-bar.component';
import {KeycloakService} from 'src/app/authentication/keycloak.service';

@Component({
  selector: 'app-digital-ids',
  templateUrl: './users-list.component.html',
})
export class UsersListComponent implements OnInit, PgTitleComponent {
  title = new EventEmitter<string>();

  dtOptions: any;
  dtTrigger: Subject<any> = new Subject<any>();
  userProfiles: UserProfile[];

  constructor(private _securityService: SecurityService,
              private _router: Router,
              private _zone: NgZone,
              private _keycloakService: KeycloakService) { }

  ngOnInit() {
    this.title.emit('Users Profile');
    const currentComponent = this;
    const buttons = [{
      text: 'New User',
      action: () => currentComponent.addRecord()
      }];
    this.dtOptions = DataTablesService.getOptionsEn(buttons);
    this._securityService.get().subscribe(d => {
      this.userProfiles = d;
      this.dtTrigger.next(false);
    });
  }

  formatDigitalIdsList(digitalIdFiles: DigitalIdFile[]) {
    if (digitalIdFiles) {
      return digitalIdFiles.map(d => d.email).join(', ');
    } else {
      return '';
    }
  }

  addRecord() {
    this._router.navigate(['security/digital-id/new']).then();
  }

  editRecord(digitalId: UserProfile) {
    this._router.navigate(['security/digital-id', digitalId.portalUserName]).then();
  }
}

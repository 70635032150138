<div class="row">
  <div class="col-md-6">
    <form #contractEntry="ngForm" class="form-horizontal">
      <div class>
        <div class="form-group">
          <label for="internalNo"
            class="col-form-label col-md-4">Commitment NO</label>
          <div class="col-md-8">
            <input type="text"
              class="form-control"
              name="internalNo"
              id="internalNo"
              [ngModel]="contract?.internalContractNo"
              (ngModelChange)="contract.internalContractNo = $event"
              [disabled]="disabled"
              required>
          </div>
        </div>
        <div class="form-group">
          <label for="registrationDate"
            class="col-form-label col-md-4">Registration Date</label>
          <div class="col-md-8">
            <app-date-range
              id="registrationDate"
              [singleDateSelector]="true"
              [(dateRange)]="registrationDate"
              [disabled]="disabled">
            </app-date-range>
          </div>
        </div>
        <div class="form-group">
          <label for="expDate" class="col-form-label col-md-4">Comitment's
            duration</label>
          <div class="col-md-8">
            <app-date-range
              id="registrationDate"
              [(dateRange)]="duration"
              [disabled]="disabled">
            </app-date-range>
          </div>
        </div>

        <div class="form-group visible-xs visible-sm">
          <label for="seller"
            class="col-form-label col-md-4">Seller</label>
          <div class="col-md-8">
            <select class="form-control"
              name="sellerId"
              id="seller"
              [(ngModel)]="seller"
              [disabled]="disabled"
              required>
              <option *ngFor="let seller of sellers" [ngValue]="seller">
                {{seller.nameTranslations['en']}}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group visible-md visible-lg">
          <label for="destination2"
            class="col-form-label col-md-4">Seller</label>
          <div class="col-md-8">
            <app-organisation-selector
              id="seller2"
              class="pg-component"
              [(organisation)]="seller"
              [organisationLanguage]="'fa'"
              name="seller2"
              [organisations]="sellers"
              required>
            </app-organisation-selector>
          </div>
        </div>
        <div class="form-group visible-xs visible-sm">
          <label for="buyer"
            class="col-form-label col-md-4">Buyer</label>
          <div class="col-md-8">
            <select class="form-control"
              name="buyerId"
              id="buyer"
              [(ngModel)]="buyer"
              [disabled]="disabled"
              required>
              <option *ngFor="let buyer of buyers" [ngValue]="buyer">
                {{buyer.nameTranslations['en']}}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group visible-md visible-lg">
          <label for="destination2"
            class="col-form-label col-md-4">Buyer</label>
          <div class="col-md-8">
            <app-organisation-selector
              id="buyer"
              class="pg-component"
              [(organisation)]="buyer"
              [organisationLanguage]="'fa'"
              name="buyer"
              [organisations]="buyers"
              required>
            </app-organisation-selector>
          </div>
        </div>
        <div *ngFor="let transporter of transporters; let in=index">
          <div class="form-group">
            <label for="transporter{{in}}"
              class="col-form-label col-md-4">Transporter</label>
            <div class="col-md-8">
              <select class="form-control"
                name="transporterId{{in}}"
                id="transporterId{{in}}"
                [(ngModel)]=transporters[in]
                #name="ngModel"
                required>
                <option *ngFor="let transporter of transporterOrganisations"
                  [ngValue]="transporter">
                  {{transporter.nameTranslations['en']}}
                </option>
              </select>
            </div>
          </div>
          <!-- <div class="form-group visible-md visible-lg">
            <label for="destination2" class="col-form-label col-md-4">Added Transporter</label>
            <div class="col-md-8">
                <input  auto-complete
                        id="transporterId{{in}}2"
                        [source]="transporterOrganisations"
                        [value-formatter]="organisationValueFormatter"
                        [list-formatter]="organisationValueFormatter"
                        class="form-control col-md-8"
                        name="transporterId{{in}}2"
                        [max-num-list]="10"
                        [(ngModel)]="transporters[in]"
                        required>
            </div>
          </div> -->
          <br />
          <div class="form-group col-md-12" *ngIf="remove">
            <label for="newTransporterButton"
              class="col-form-label col-md-4">Delete</label>
            <button class="btn btn-link" id="newTransporterButton"
              (click)="removeTransporter(in)">
              <i class="fa fa-minus"></i>
            </button>
          </div>
        </div>
        <div class="form-group col-md-12">
          <label for="newTransporterButton"
            class="col-form-label col-md-4">New Transporter</label>
          <button class="btn btn-link" id="newTransporterButton"
            (click)="addNewTransporter()">
            <i class="fa fa-plus"></i>
          </button>
        </div>
        <!-- <div class="form-group col-md-12">
          <label for="transporter"
                 class="col-form-label col-md-4">کالا</label>
          <select class="form-control col-md-8"
                  name="good"
                  id="good"
                  [ngModel]="contract?.goodId"
                  (ngModelChange)="contract.goodId = $event"
                  required>
            <option *ngFor="let good of goods" value="{{good}}">
              {{good}}
            </option>
          </select>
        </div> -->
        <div class="form-group  visible-xs visible-sm">
          <label for="source"
            class="col-form-label col-md-4">Source</label>
          <div class="col-md-8">
            <select class="form-control"
              name="sourceId"
              id="source"
              [(ngModel)]="source"
              required
              [disabled]="disabled">
              <option *ngFor="let source of sources" [ngValue]="source">
                {{source.nameTranslations['en']}}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group visible-md visible-lg">
          <label for="destination2"
            class="col-form-label col-md-4">Source</label>
          <div class="col-md-8">
            <app-organisation-selector
              id="source2"
              class="pg-component"
              [(organisation)]="source"
              [organisationLanguage]="'fa'"
              name="source2"
              [organisations]="sources"
              required>
            </app-organisation-selector>
          </div>
        </div>
        <!-- <div class="form-group col-md-12">
          <label for="destination"
                 class="col-form-label col-md-4">مقصد</label>
          <select class="form-control col-md-8"
                  name="destinationId"
                  id="destination"
                  [ngModel]="contract?.destinationOrganisationId"
                  (ngModelChange)="contract.destinationOrganisationId = $event"
                  required>
            <option *ngFor="let destination of destinations" value="{{destination.name}}">
              {{destination.nameTranslations['fa']}}
            </option>
          </select>
        </div> -->
        <!-- <div class="form-group col-md-12">
          <label for="currency"
                 class="col-form-label col-md-4">نوع ارز</label>
          <select class="form-control col-md-8"
                  name="currency"
                  id="currency"
                  [ngModel]="contract?.currency"
                  (ngModelChange)="contract.currency = $event"
                  required>
            <option *ngFor="let currency of currencies" value="{{currency}}">
              {{currency}}
            </option>
          </select>
        </div> -->

        <div class="form-group">
          <label for="quantity"
            class="col-form-label col-md-4">Quantity</label>
          <div class="col-md-8">
            <input type="number"
              class="form-control"
              name="quantity"
              id="quantity"
              [ngModel]="contract?.quantity"
              (ngModelChange)="contract.quantity = $event"
              [disabled]="disabled"
              required>
          </div>
        </div>

        <!-- <div class="form-group col-md-12">
          <label for="costPerTon" class="col-form-label col-sm-4">قیمت هر تن</label>
            <input  type="number"
                    class="form-control col-md-8"
                    name="costPerTon"
                    id="costPerTon"
                    [ngModel]="contract?.costPerTon"
                    (ngModelChange)="contract.costPerTon = $event"
                    required>
        </div> -->
        <div>
          <div class="col-md-4"></div>
          <input type="button"
            class="btn btn-primary"
            name="pay"
            value="Save"
            (click)="onInsert()"
            [disabled]="!contractEntry.form.valid">
        </div>
        <div
          [ngClass]="{'alert':true, 'alert-danger':!success, 'alert-success':success}"
          *ngIf="showMessage">{{message}}</div>
      </div>
    </form>
  </div>
</div>

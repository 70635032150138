<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-body">
        <form #truckReport ="ngForm">
          <div class="row">
            <div class="form-group col-md-6 row">
              <label class="col-form-label col-md-4 " for="reportDateRangePicker">محدوده گزارش</label>
              <div class="col-md-8">
                <app-date-range id="reportStartDatePicker"
                    [(dateRange)]="dateRange"
                    [jalali]="true"
                    [timePicker] ="true"
                    >
                </app-date-range>
              </div>
            </div>
            <div class="form-group col-md-5 row">
                <label for="truck" class="col-form-label col-md-4 ">شماره کشنده</label>
                <div class="col-md-8">
                  <app-auto-complete
                    [formatter]="truckValueFormatter"
                    [(ngModel)]="selectedTruck"
                    [items]="trucks"
                    name="truck">
                  </app-auto-complete>
                </div>
            </div>
            <div class="col-md-1 col-xs-12">
              <input type="button" class="btn btn-primary" (click)="report()" value =" گزارش" [disabled]="!truckReport.form.valid">
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-md-6">
    <div class="card">
      <div style="height: 80vh;">
        <!-- <app-map-component></app-map-component> -->
      </div>
    </div>
  </div>

  <div class="col-md-6">
    <div class="row">
      <div class="col-lg-6 col-xs-6">
        <div class="card bg-primary">
          <div class="inner">
            <p>مسافت طی شده</p>
            <h3>{{selectedTruckTotalDistanceTraveled | number:'1.0-0'}} کیلومتر</h3>

          </div>
          <div class="icon">
            <i class="ion ion-bag"></i>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-xs-6">
        <div class="card bg-yellow">
          <div class="inner">
            <p>میانگین مسافت روزانه</p>
            <h3>{{selectedTruckTotalDistanceTraveledDaily | number:'1.0-2'}} کیلومتر</h3>

          </div>
          <div class="icon">
            <i class="ion ion-bag"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <div>
          <div id="speedContainer" style="width:100%; height:300px;"></div>
        </div>
      </div>
    </div>
    <div class="card">
        <div class="card-body">
          <div>
            <div id="altitudeContainer" style="width:100%; height:300px;"></div>
          </div>
        </div>
    </div>

  </div>
</div>


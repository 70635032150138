import {Component, EventEmitter, OnInit} from '@angular/core';
import {forkJoin} from 'rxjs';
import {Organisation, OrganisationService} from '../../../organisation/organisation.service';
import {NauticalMile, Vessel, VesselsService} from '../vessels.service';
import {MessageLevel, PgMessagesService} from '../../../shared/services/pg-messages.service';
import {DateRange} from '../../../shared/components';
import * as moment from 'moment';
import {VesselBudget, VesselManagementService} from '../services/vessel-management.service';
import {UserLocalStoreService} from '../../../shared/services/user-local-store.service';
import {PriceIndex, MarketService} from '../../../market/market.service';
import {PgTitleComponent} from '../../../shared/components/title-bar/title-bar.component';

interface VoyagePlannerSettings {
  stops: [Organisation, number][];
  ballastSpeed: number;
  ladenSpeed: number;
  startDate: DateRange;
  selectedVessel: Vessel;
  tonnage: number;
  daysAtPorts: number;
}

@Component({
  selector: 'app-voyage-planner',
  templateUrl: './voyage-planner.component.html'
})
export class VoyagePlannerComponent implements OnInit, PgTitleComponent {
  title: EventEmitter<string> = new EventEmitter();

  availablePorts: Organisation[];
  ports: Organisation[];
  distances: NauticalMile[];
  stop: Organisation;
  stops: [Organisation, number][] = [];
  eta: string;
  _ladenSpeed = 12;
  _ballastSpeed = 12;
  _startDate: DateRange;
  _ladenHfoConsumption: number;
  _ballastHfoConsumption: number;
  _daysAtPorts: number;
  _tonnage: number;
  hours: number;
  days: number;
  totalLadenHfoConsumption: number;
  totalBallastHfoConsumption: number;
  totalHfoConsumption: number;
  vessels: Vessel[];
  _selectedVessel: Vessel;
  consumptionTable: any[];
  ladenHfoConsumptionTable: any;
  ballastHfoConsumptionTable: any;
  prices: PriceIndex[];
  lastAvailableHFOPriceDate: Date;
  lastAvailableHFOPrice: number;
  budgets: VesselBudget[];
  lastDailyManagementCost: VesselBudget;
  totalCost: number;
  costPerTon: number;

  get ladenSpeed() {
    return this._ladenSpeed;
  }
  set ladenSpeed(value: number) {
    this._ladenSpeed = value; this.recalculate();
  }
  get ballastSpeed() {
    return this._ballastSpeed;
  }
  set ballastSpeed(value: number) {
    this._ballastSpeed = value; this.recalculate();
  }
  get startDate() {
    return this._startDate;
  }
  set startDate(value: DateRange) {
    this._startDate = value; this.recalculate();
  }
  get ladenHfoConsumption() {
    return this._ladenHfoConsumption;
  }
  set ladenHfoConsumption(value: number) {
    this._ladenHfoConsumption = value; this.recalculate();
  }
  get ballastHfoConsumption() {
    return this._ballastHfoConsumption;
  }
  set ballastHfoConsumption(value: number) {
    this._ballastHfoConsumption = value; this.recalculate();
  }
  get selectedVessel() {
    return this._selectedVessel;
  }
  set selectedVessel(value: Vessel) {
    this._selectedVessel = value;
    this.drawConsumptionTable();
    this.recalculate();
  }
  get daysAtPorts() {
    return this._daysAtPorts;
  }
  set daysAtPorts(value: number) {
    this._daysAtPorts = value;
    this.recalculate();
  }
  get tonnage() {
    return this._tonnage;
  }
  set tonnage(value: number) {
    this._tonnage = value;
    this.recalculate();
  }

  constructor(private _organisationService: OrganisationService,
              private _vesselsService: VesselsService,
              private _vesselsManagementService: VesselManagementService,
              private _userLocalStorage: UserLocalStoreService,
              private _pgMessagesService: PgMessagesService,
              private _marketService: MarketService) {
    this._startDate = {
      startDate: new Date(),
      endDate: undefined
    };
  }

  ngOnInit() {
    this.title.emit('Voyage planner');
    const endTime = moment();
    const startTime = moment().add(-30, 'd');
    forkJoin([
      this._organisationService.getPorts(),
      this._vesselsService.getNauticalMiles(),
      this._vesselsService.getActiveVessels(),
      this._vesselsManagementService.getConsumptionTable(),
      this._marketService.getPrices('Bunker Index', startTime.unix() * 1000, endTime.unix() * 1000),
      this._vesselsManagementService.getBudgets()
    ]).subscribe(results => {
      this.ports = results[0].filter(p => p.properties['isPort']);
      this.availablePorts = this.ports;
      this.distances = results[1];
      this.vessels = results[2];
      this.consumptionTable = results[3];
      this.prices = results[4];
      this.budgets = results[5];
    });
  }

  addStop(stop: Organisation) {
    if (stop) {
      if (this.stops.length === 0) {
        this.stops.push([this.stop, 0]);
      } else if (stop === this.stops[this.stops.length - 1][0]) {
        this._pgMessagesService.publishMessage({
          level: MessageLevel.Error,
          topic: 'Voyage planner',
          message: 'Cannot add the same stop as the last one'
        });
      } else {
        const source = this.stops[this.stops.length - 1][0];
        const distance = this.distances.find(d =>
          d.sourceOrganisationId === source.name && d.destinationOrganisationId === stop.name);
        if (!distance) {
          this._pgMessagesService.publishMessage({
            level: MessageLevel.Error,
            topic: 'Voyage planner',
            message: `Distance between ${source.nameTranslations['en']} and ${this.stop.nameTranslations['en']}
            does not exits.
            `
          });
        } else {
          this.stops.push([stop, distance.distance]);
        }
      }
      this.availablePorts = this.ports.filter(p => this.distances.some(d => d.sourceOrganisationId === stop.name
        && d.destinationOrganisationId === p.name));
    }
    this.recalculate();
  }

  reset() {
    this.stops = [];
    this.availablePorts = this.ports;
    this.recalculate();
  }

  selectLadenSpeed(speed: number, consumption: number) {
    this.ladenSpeed = speed;
    this.ladenHfoConsumption = Math.round(consumption);
  }

  selectBallastSpeed(speed: number, consumption: number) {
    this.ballastSpeed = speed;
    this.ballastHfoConsumption = Math.round(consumption);
  }

  private recalculate() {
    if (this.stops.length > 0) {
      const distance = this.stops.map(s => s[1]).reduce((a, b) => a + b, 0);
      const startTime = moment(this._startDate.startDate);
      this.hours = distance / this.ladenSpeed;
      this.days = this.hours / 24;
      const ballastDays = distance / this.ballastSpeed / 24;
      this.eta = startTime.add(this.hours, 'h').format('MMMM Do YYYY, h:mm:ss a');
      this.totalLadenHfoConsumption = this.ladenHfoConsumption * this.days;
      this.totalBallastHfoConsumption = this.ballastHfoConsumption * ballastDays;
      this.totalHfoConsumption = this.totalLadenHfoConsumption + this.totalBallastHfoConsumption;
      const lastAvailableHfoPrice = this.prices.filter(p => p.productId === 'VLSFO 0.5')
        .sort((a, b) => b.timestamp - a.timestamp)[0];
      this.lastAvailableHFOPriceDate = new Date(lastAvailableHfoPrice.timestamp);
      this.lastAvailableHFOPrice = lastAvailableHfoPrice.price;
      this.lastDailyManagementCost = this.budgets.filter(b => b.year === moment().year()
        && b.vesselIMO === this.selectedVessel.IMO).sort((a, b) =>
        b.quarter.localeCompare(a.quarter))[0];
      if (!this.lastDailyManagementCost) {
        this._pgMessagesService.publishMessage({
          level: MessageLevel.Error,
          topic: `Voyage planner`,
          message: `Daily cost not found for ${this.selectedVessel.name.name}`
        });
      } else {
        this.totalCost = this.totalHfoConsumption * this.lastAvailableHFOPrice +
          this.lastDailyManagementCost.dailyCost * (this.days + ballastDays + this.daysAtPorts);
        this.costPerTon = this.totalCost / this.tonnage;
      }
    } else {
      this.hours = 0;
      this.days = 0;
      this.eta = '';
      this.totalLadenHfoConsumption = 0;
    }
    this._userLocalStorage.save<VoyagePlannerSettings>(`VoyagePlannerComponent`, {
      stops: this.stops,
      selectedVessel: this.selectedVessel,
      ladenSpeed: this.ladenSpeed,
      ballastSpeed: this.ballastSpeed,
      startDate: this.startDate,
      tonnage: this.tonnage,
      daysAtPorts: this.daysAtPorts
    });
  }

  private drawConsumptionTable() {
    this.ladenHfoConsumptionTable = this.consumptionTable[this.selectedVessel.IMO].filter(c => c[0] === true)
      .map(c => [c[1], c[2]['lsfo'][0] * 100 / 100, c[2]['lsfo'][1]]).sort((a, b) => a[0] - b[0]);
    this.ballastHfoConsumptionTable = this.consumptionTable[this.selectedVessel.IMO].filter(c => c[0] === false)
      .map(c => [c[1], c[2]['lsfo'][0] * 100 / 100, c[2]['lsfo'][1]]).sort((a, b) => a[0] - b[0]);
  }

  private setSettings() {
    const currentSettings = this._userLocalStorage.get<VoyagePlannerSettings>('VoyagePlannerComponent');
    if (currentSettings) {
      if (currentSettings.stops) {
        this.stops = currentSettings.stops;
      }
      if (currentSettings.selectedVessel) {
        this.selectedVessel = this.vessels.find(v => v.IMO === currentSettings.selectedVessel.IMO);
      }
      if (currentSettings.ladenSpeed) {
        this.ladenSpeed = currentSettings.ladenSpeed;
      }
      if (currentSettings.ballastSpeed) {
        this.ballastSpeed = currentSettings.ballastSpeed;
      }
      if (currentSettings.startDate) {
        this.startDate = currentSettings.startDate;
      }
      if (currentSettings.tonnage) {
        this.tonnage = currentSettings.tonnage;
      }
      if (currentSettings.daysAtPorts) {
        this.daysAtPorts = currentSettings.daysAtPorts;
      }
    }
    this.recalculate();
  }
}

import { Component, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { forkJoin, Subject } from 'rxjs';
import { CorrespondenceService, CorrespondenceTemplate } from '../correspondence.service';
import { DataTableDirective } from 'angular-datatables';
import { DocumentService } from '../../documents/documents.service';
import { Organisation, OrganisationService } from '../../organisation/organisation.service';
import { DataTablesService } from '../../shared/datatables/data-tables.service';
import { PgMessagesService, MessageLevel } from 'src/app/shared/services/pg-messages.service';
import { KeycloakService } from 'src/app/authentication/keycloak.service';
import { Router } from '@angular/router';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-correspondence',
  templateUrl: './correspondence-templates.component.html',
})
export class CorrespondenceTemplatesComponent implements OnInit {
  @ViewChild(DataTableDirective, {static: true})
  dtElement: DataTableDirective;

  title = new EventEmitter<string>();

  isDataTablesInitialised = false;
  dtOptions = {};
  dtTrigger: Subject<any> = new Subject<any>();

  template: CorrespondenceTemplate;
  templates: CorrespondenceTemplate[];
  organisations: Organisation[];

  constructor(private _correspondenceService: CorrespondenceService,
              private _documentsService: DocumentService,
              private _organisationService: OrganisationService,
              private _pgMessagesService: PgMessagesService,
              private _keycloakService: KeycloakService,
              private _router: Router) { }

  ngOnInit(): void {
    this.title.emit('Correspondence Templates');
    forkJoin([
      this._correspondenceService.getAllTemplates(),
      this._organisationService.get()
    ])
    .subscribe(result => {
      this.templates = result[0];
      this.organisations = result[1];
      this.initialiseDataTables();
    });
    const currentComponent = this;
    const buttons = [
        {
          text: 'نامه وارده',
          action: () => currentComponent.startNewIncomingCorrespondence()
        }
      ];

    this.dtOptions = DataTablesService.getOptions(buttons);
  }


  startNewCorrespondence(template: CorrespondenceTemplate) {
    this._router.navigate(['correspondence/new/false', template.fileId, template.organisationId]);
  }

  startNewIncomingCorrespondence() {
    this._router.navigate(['correspondence/new/true']);
  }

  organisationFormatter(id: string) {
    return this.organisations.find(o => o.name === id).nameTranslations['fa'];
  }

  initialiseDataTables() {
    if (!this.isDataTablesInitialised) {
      this.dtTrigger.next(false);
    } else {
      this.dtElement.dtInstance.then((dtInstance) => {
        dtInstance.destroy();
        this.dtTrigger.next(false);
      });
    }
    this.isDataTablesInitialised = true;
  }

  downloadTemplate(template: CorrespondenceTemplate) {
    this._documentsService.download(template.fileId).subscribe(b => {
      saveAs(b.body, `${template.title}.docx`);
    }, () => {
      this._pgMessagesService.publishMessage({
        message: 'امکان دریافت فایل وجود ندارد',
        level: MessageLevel.Error,
        topic: 'Correspondences template'
      });
    });
  }

}

/* eslint-disable @typescript-eslint/naming-convention */
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  BillOfLading,
  DataBaseAction,
  ProductQuantity,
  Vessel,
  VesselsService,
  Voyage
} from '../../vessels.service';
import {Organisation, OrganisationService} from 'src/app/organisation/organisation.service';
import {DateRange} from 'src/app/shared/components';
import {VoyagePartComponent} from '../voyage-part-component';
import {DateTimeHelpers} from '../../../../shared/helpers/date-time.helpers';
import { KeycloakService} from 'src/app/authentication/keycloak.service';
import { ActionType } from 'src/app/shared/services/database-action.service';


@Component({
  selector: 'app-bl',
  templateUrl: './bl.component.html',
})
export class BlComponent implements OnInit, VoyagePartComponent {

  @Output() changed: EventEmitter<Voyage> = new EventEmitter();
  @Output() cancelled: EventEmitter<any> = new EventEmitter();

  key = 'Bill of Lading';
  products: ProductQuantity[] = [];

  remove: boolean;
  showMessage: boolean;
  isVerified: boolean;

  ports: Organisation[];
  loadingPort: Organisation;
  dischargePort: Organisation;
  shippers: Organisation[];
  consignees: Organisation[];
  notifying: Organisation[];
  agents: Organisation[];
  agent: Organisation;
  shipper: Organisation;
  consignee: Organisation;
  notifier: Organisation;

  loadingStsVesselVoyage = [];

  _selectedFileId: string;
  _updatedFileId: string;
  _selectedVoyage: Voyage;
  vessels: Vessel[];
  voyages: Voyage[];
  loadingSTS = false;
  mode = 'new';
  selectedDate: DateRange;

  _selectedBillOfLading: BillOfLading;

  @Input() set fileId(value: string) {
    this._selectedFileId = value;
  }
  @Input() set updatedFileId(value: string) {
    this._updatedFileId = value;
  }
  @Input() set data(value: any) {
    this._selectedVoyage = value as Voyage;
  }



  productNames = ['Propane', 'Butane', 'LPG MIX'];

  constructor(private _vesselService: VesselsService,
              private _organisationService: OrganisationService,
              private _keycloakService: KeycloakService) { }

  ngOnInit() {
    if (this._selectedVoyage.billOfLadings.find(b => b.fileId === this._selectedFileId)) {
      this._selectedBillOfLading = this._selectedVoyage.billOfLadings.find(b => b.fileId === this._selectedFileId);
      this.isVerified = this._vesselService.isVerified(this._selectedBillOfLading);
      this.products = this._selectedBillOfLading.products;
      if (this._selectedBillOfLading.stsLoadings.length > 0) {
        this._selectedBillOfLading.stsLoadings.forEach(d => {
          this._vesselService.getVoyagesByIMO(d.vesselIMO).subscribe(retrivedVoyages => {
            const temp = {
              IMO: d.vesselIMO,
              shipVoyageNo: d.shipVoyageNo,
              vesselName: d.vesselName,
              voyages: retrivedVoyages
            };
            this.loadingStsVesselVoyage.push({value: temp});
          });
        });
        // this._loadingSTS = this._selectedBillOfLading.stsLoading;
        this.loadingSTS = true;
        // this.getVesselVoyages(this._selectedBillOfLading.stsLoading.vesselIMO);
      }
      this.mode = 'edit';
    } else {
      this._selectedBillOfLading = {
        no: '',
        shipperOrganisationId: '',
        consigneeOrganisationId: '',
        agentOrganisationId: '',
        notifyingOrganisationId: '',
        date: new Date().getTime(),
        loadingPortOrganisationId: '',
        dischargingPortOrganisationId: '',
        products: [],
        fileId: '',
        revise: false,
        stsLoadings: null,
        actions: []
      };
      this.products.push({
        product: '',
        grossWeight: null,
        density: null
      });
      this.mode = 'new';
    }

    this._organisationService.get().subscribe(o => {
      this.ports = o.filter(organisation =>
        (organisation.properties['isPort'] && organisation.properties['isPort'] === true) ||
        (organisation.properties['isAnchorage'] && organisation.properties['isAnchorage'] === true));
      this.shippers = o.filter(organisation => organisation.properties['isShipper'] &&
        organisation.properties['isShipper'] === true
      );
      this.shipper = this.shippers.find(a => a.name === this._selectedBillOfLading.shipperOrganisationId);
      this.consignees = o.filter(organisation => organisation.properties['isConsignee'] &&
        organisation.properties['isConsignee'] === true
      );
      this.consignee =  this.consignees.find(a => a.name === this._selectedBillOfLading.consigneeOrganisationId);
      this.agents = o.filter(organisation => organisation.properties['isShippingAgent'] &&
        organisation.properties['isShippingAgent'] === true
      );
      this.agent = this.agents.find(a => a.name === this._selectedBillOfLading.agentOrganisationId);
      this.notifying = o.filter(organisation => organisation.properties['isNotifiying'] &&
        organisation.properties['isNotifiying'] === true
      );
      this.notifier = this.notifying.find(a => a.name === this._selectedBillOfLading.notifyingOrganisationId);
      if (this.mode === 'edit') {
        this.loadingPort = this.ports.find(p => p.name === this._selectedBillOfLading.loadingPortOrganisationId);
        this.dischargePort = this.ports.find(p => p.name === this._selectedBillOfLading.dischargingPortOrganisationId);
        this.selectedDate = {
          startDate: new Date(DateTimeHelpers
            .reversOffsetTimestampWithZone(this._selectedBillOfLading.date, this.loadingPort.timezone)),
          endDate: new Date(DateTimeHelpers
            .reversOffsetTimestampWithZone(this._selectedBillOfLading.date, this.loadingPort.timezone))
        };
      }
    });

    this._vesselService.getVessels().subscribe(vessels => {
      this.vessels = vessels;
    });
  }

  hasRole(role: string) {
    return this._keycloakService.hasRole(role);
  }

  addNewProduct() {
    const product = {
      product: '',
      grossWeight: null,
      density: null
    };
    this.products.push(product);
    if (this.products.length > 1) {
      this.remove = true;
    }
  }

  removeProduct(index: number) {
    this.products.splice(index, index + 1);
    if (this.products.length === 1) {
      this.remove = false;
    }
  }

  stsMap(element: any) {
    return {
      vesselIMO: element.value.IMO,
      vesselName: element.value.vesselName,
      shipVoyageNo: element.value.shipVoyageNo
    };
  }

  saveBillOfLading() {
    this._selectedBillOfLading.products = this.products;
    this._selectedBillOfLading.agentOrganisationId = this.agent.name;
    this._selectedBillOfLading.consigneeOrganisationId = this.consignee.name;
    if (this.notifier !== undefined) {
      this._selectedBillOfLading.notifyingOrganisationId = this.notifier.name;
    }
    this._selectedBillOfLading.shipperOrganisationId = this.shipper.name;
    if (this._updatedFileId) {
      this._selectedBillOfLading.fileId = this._updatedFileId;
    } else {
      this._selectedBillOfLading.fileId = this._selectedFileId;
    }
    this._selectedBillOfLading.stsLoadings = this.loadingStsVesselVoyage.map(this.stsMap);
    this._selectedBillOfLading.loadingPortOrganisationId = this.loadingPort.name;
    this._selectedBillOfLading.dischargingPortOrganisationId = this.dischargePort.name;
    if (!this.loadingPort.timezone) {
      console.error(`Timezone offset is missing for selected organisation Id: ${this.loadingPort.timezone}`);
    } else if (this._selectedBillOfLading.date !== this.selectedDate.startDate.getTime()) {
      this._selectedBillOfLading.date = DateTimeHelpers.offsetTimestampWithZone(this.selectedDate.startDate.getTime(), this.loadingPort.timezone);
    }
    this.changed.emit(this._selectedVoyage);
  }

  stsLoading(organisation: Organisation) {
    if (organisation.properties['isAnchorage'] === true) {
      this.loadingSTS = true;
      const tempSTS = {
        vesselIMO: '',
        shipVoyageNo: '',
        voyages: []
      };
      this.loadingStsVesselVoyage.push({value: tempSTS});
    } else {
      this.loadingSTS = false;
      this.loadingStsVesselVoyage = [];
    }
  }

  getAllVesselVoyages(imo: string, index: number, vesselVOyages: any) {
    this._vesselService.getVoyagesByIMO(imo).subscribe(retrivedVoyages => {
        const temp = {
          IMO: imo,
          shipVoyageNo: '',
          voyages: retrivedVoyages
        };
        vesselVOyages.splice(index, 1, {value: temp});
    });
  }

  addLoadingSTS() {
    this.loadingSTS = true;
    const tempSTS = {
      vesselIMO: '',
      shipVoyageNo: '',
      voyages: []
    };
    this.loadingStsVesselVoyage.push({value: tempSTS});
  }

  removeLoadingSTS(index: number) {
    this.loadingStsVesselVoyage.splice(index, 1);
  }

  verify() {
    if (this._vesselService.verify(this._selectedBillOfLading, 'Bill of Lading')) {
      this.saveBillOfLading();
    }
  }

  removeVerification() {
    if (this._vesselService.removeVerification(this._selectedBillOfLading, 'Bill of Lading')) {
      this.saveBillOfLading();
    }
  }

  addUserAction() {
    if (this.mode === 'new') {
      if (this._selectedVoyage.billOfLadings) {
        this._selectedVoyage.billOfLadings.push(this._selectedBillOfLading);
      } else {
        this._selectedVoyage.billOfLadings = [this._selectedBillOfLading];
      }
      const action: DataBaseAction = {
        userName: this._keycloakService.getUser().username,
        timestamp: new Date().getTime(),
        userAction: ActionType.Create
      };
      this._selectedBillOfLading.actions.push(action);
    }
    if (this.mode === 'edit' && this.isVerified !== true) {
      const action: DataBaseAction = {
        userName: this._keycloakService.getUser().username,
        timestamp: new Date().getTime(),
        userAction: ActionType.Update
      };
      if (this._selectedBillOfLading.actions === undefined) {
        this._selectedBillOfLading.actions = [];
      }
      this._selectedBillOfLading.actions.push(action);
    }
  }

  deleteBL() {
    if (confirm('Are you sure to delete this BL')) {
      const i = this._selectedVoyage.billOfLadings.findIndex(b => b.fileId === this._selectedBillOfLading.fileId);
      this._selectedVoyage.billOfLadings.splice(i, 1);
      this.changed.emit(this._selectedVoyage);
    }
  }
}

import {
  Component,
  OnInit,
  ViewChild,
  EventEmitter,
} from '@angular/core';
import { forkJoin, Subject } from 'rxjs';
import {
  CorrespondenceService,
  Correspondence,
} from '../correspondence.service';
import { CorrespondenceToolbarComponent } from '../correspondence-toolbar/correspondence-toolbar.component';
import { DataTableDirective } from 'angular-datatables';
import { DocumentService } from '../../documents/documents.service';
import {
  Organisation,
  OrganisationService,
} from '../../organisation/organisation.service';
import { DataTablesService } from '../../shared/datatables/data-tables.service';
import {
  PgTitleComponent,
  PgToolbarComponent,
} from '../../shared/components/title-bar/title-bar.component';
import {
  PgMessagesService,
  MessageLevel,
} from 'src/app/shared/services/pg-messages.service';
import { Router } from '@angular/router';
import { saveAs } from 'file-saver';
import { DateRange } from '../../shared/components';
import { UserLocalStoreService } from 'src/app/shared/services/user-local-store.service';

interface CorrespondencesComponentSettings {
  companyName: string;
  incoming: boolean;
}
@Component({
  selector: 'app-correspondences',
  templateUrl: './correspondences.component.html',
})
export class CorrespondencesComponent
  implements OnInit, PgTitleComponent, PgToolbarComponent {
  @ViewChild(DataTableDirective, { static: true })
  dtElement: DataTableDirective;
  title = new EventEmitter<string>();
  subTitle = new EventEmitter<string>();
  toolbarComponentType = CorrespondenceToolbarComponent;
  toolbarComponent: CorrespondenceToolbarComponent;
  isDataTablesInitialised = false;
  dtOptions = {};
  dtTrigger: Subject<any> = new Subject<any>();
  _settings: CorrespondencesComponentSettings;
  dateRange: DateRange;
  showList: boolean;
  dateRangeToolbar: DateRange;
  companies: Organisation[];
  filterCorrespondences: Correspondence[];
  correspondence: Correspondence;
  correspondences: Correspondence[];
  organisations: Organisation[];
  organisationItemToolbar: Organisation;
  selectedCompanyName: string;
  totalLoadingWeight: number;
  dailyWeight: number;
  incoming: boolean;
  selectedCalendar: string;
  selectedOrganisation: Organisation;
  lastDayTotalWeight: number;

  get incomingItem() {
    return this.incoming;
  }
  set incomingItem(value: boolean) {
    this.incoming = value;
  }

  get dateRangeItem() {
    return this.dateRangeToolbar;
  }
  set dateRangeItem(value: DateRange) {
    this.dateRangeToolbar = value;
  }

  get organisation() {
    return this.organisationItemToolbar;
  }
  set organisation(value: Organisation) {
    this.organisationItemToolbar = value;
  }


  constructor(
    private _correspondenceService: CorrespondenceService,
    private _documentsService: DocumentService,
    private _organisationService: OrganisationService,
    private _pgMessagesService: PgMessagesService,
    private _userLocalStorage: UserLocalStoreService,
    private _router: Router
  ) {}

  ngOnInit(): void {
    this.localStorage();
    this.getData();
  }

  getData() {
    forkJoin([
      this._correspondenceService.getAllCorrespondences(),
      this._organisationService.get(),
    ]).subscribe((result) => {
      this.correspondences = result[0];
      this.filterCorrespondences = result[0];
      this.organisations = result[1];
      this.initialiseDataTables();
      this.setToolbar();
    });
    this.dtOptions = DataTablesService.getOptions();
  }

  setToolbar() {
    this.title.emit('Correspondences');
    this.organisation = this.organisations.find(o => o.name === this._settings.companyName);
    this.toolbarComponent.selectedOrganisation = this.organisation;
    this.toolbarComponent.incomingItem.subscribe((t) => {
      this.incoming = t;
      this.refreshData(t, this.organisation.name);
      this.filter();
    });
    this.toolbarComponent.showList.subscribe(() => {
      this.showAll();
    });
    this.toolbarComponent.dateRangeItem.subscribe((t) => {
      this.dateRangeToolbar = t;
      this.filter();
    });
    this.toolbarComponent.organisation.subscribe((t) => {
      this.organisation = t;
      this.refreshData(this.incoming, t.name);
      this.filter();
    });
  }

  localStorage() {
    this._settings =
          this._userLocalStorage.get<CorrespondencesComponentSettings>(
            'CorrespondencesComponent'
          );
          if (!this._settings) {
            this._settings = { companyName: 'iran.parsagroup.orsagas', incoming: true};
          }
  }

  filter() {
    this.filterCorrespondences = this.correspondences;
    if (this.incoming !== undefined) {
      this.filterCorrespondences = this.filterCorrespondences.filter(a => a.incoming === this.incoming);
    } if (this.organisation) {
      this.filterCorrespondences = this.filterCorrespondences.filter(
        (a) => (a.organisationId === this.organisation.name || a.toId === this.organisation.name)
      );
    }
    if (this.dateRangeItem) {
      this.filterCorrespondences = this.filterCorrespondences.filter(a =>
        a.timestamp >= this.dateRangeItem.startDate.getTime() &&
        a.timestamp <= this.dateRangeItem.endDate.getTime()
      );
    }

  }

  refreshData(incomig: boolean, company: string) {
    this._settings = {
      companyName: company,
      incoming: incomig,
    };
    this._userLocalStorage.save('CorrespondencesComponent', this._settings);
  }

  initialiseDataTables() {
    if (!this.isDataTablesInitialised) {
      this.dtTrigger.next(false);
    } else {
      this.dtElement.dtInstance.then((dtInstance) => {
        dtInstance.destroy();
        this.dtTrigger.next(false);
      });
    }
    this.isDataTablesInitialised = true;
  }

  showAll() {
    this.filterCorrespondences = this.correspondences;
  }

  organisationFormatter(id: string) {
    return this.organisations.find((o) => o.name === id).nameTranslations['fa'];
  }

  edit(correspondence: Correspondence) {
    this._router.navigate([
      'correspondence/edit',
      correspondence.incoming,
      correspondence.createdFileId,
      correspondence.organisationId,
      correspondence.ref,
    ]);
  }

  download(fileId: string, fileExtention: string, index: number) {
    this._documentsService.download(fileId).subscribe(
      (b) => {
        saveAs(b.body, `${this.correspondences[index].title}.${fileExtention}`);
      },
      () => {
        this._pgMessagesService.publishMessage({
          message: 'Could not download this file.',
          level: MessageLevel.Error,
          topic: 'Correspondences',
        });
      }
    );
  }
}

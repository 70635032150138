<div class="input-group">
  <a class="btn btn-outline-secondary ms-1" *ngIf="showTools"
  (click)="tools('disabled')">HideTools
  </a>
  <app-auto-complete 
    class="ms-1"
    [formatter]="truckListFormatter"
    placeholder="Truck" [ngModel]="_selectedTruck"
    (ngModelChange)="selectTruck($event)"
    name="truck" [items]="filteredTruks">
  </app-auto-complete>
  <app-date-range
    class="ms-1"
    *ngIf="showTools || showReportLink"
    id="date"
    name="date"
    [singleDateSelector]="!showReportLink"
    (dateRangeChange)="date($event)"
    [jalali]="true"
    [timePicker] ="true">
  </app-date-range>
  <select
    *ngIf="showTools && !showReportLink"
    class="form-control ms-1"
    name="color"
    id="color"
    [(ngModel)]="_colorCodeByTimestam"
    (change)="colorCodeTimestamp()">
    <option value="0"></option>
    <option value="1">less than a week</option>
    <option value="2">less than a Month</option>
    <option value="3">More Than Month</option>
  </select>
  <select
  *ngIf="!showReportLink"
    name="selectedOrganisationId"
    class="form-control ms-1"
    id="selectedOrganisationId"
    [(ngModel)]="_selectedOrganisationId"
    (ngModelChange)="selectOrganisation()">
    <option></option>
    <option
      *ngFor="let org of organisationsColours"
      [ngValue]="org.organisationId">
      {{ formatOrganisation(org.organisationId) }}
    </option>
  </select>
  <a class="btn btn-outline-secondary ms-1" *ngIf="!showTools && !showReportLink"
  (click)="tools('show')">ShowTools
  </a>
  <a class="btn btn-outline-secondary ms-1"
  *ngIf="!showReportLink"
  (click)="cluster()">Cluster
  </a>
  <a class="btn btn-outline-secondary ms-1" *ngIf="!showReportLink"
  (click)="colorCodeOrganisation()">Coloring
  </a>
  <a class="btn btn-outline-secondary ms-1"
  (click)="showAll()">Show All
  </a>
  <a class="btn btn-outline-secondary ms-1"
  (click)="zoomOutMap()">Zoom Out
  </a>
  <a class="btn btn-outline-secondary ms-1"
  (click)="showOutOfReach()" style="color: orange">Off {{ _numberOfUnavailableTrucks }}
  </a>
  <a class="btn btn-outline-secondary ms-1"
    (click)="showAvailables()" style="color: green">On {{ _numberOfAvailableTrucks }}
  </a>
</div>

import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Crew, CrewChangeServices } from 'src/app/transportation/vessels/crew-change/crew-change.service';

@Component({
  selector: 'app-crew-profile-toolbar',
  templateUrl: './crew-profile-toolbar.component.html',
})
export class CrewProfileToolbarComponent {

  crew: Crew;

  constructor(private _router: Router,
    private _crewChangeService: CrewChangeServices) {
  }


  addAppraisal() {
    this._router.navigate(['vessels/crew-change/appraisal',this.crew.id]).then();
  }

  addCrewChange() {
    this._router.navigate(['vessels/crew-change/crew-change-data-entry/', this.crew.id]).then();
  }

  editCrew() {
    this._router.navigate(['vessels/crew-change/crew/', this.crew.id]).then();
  }

  onBoard() {
    if(this.crew) {
      const onBoard = this._crewChangeService.getCrewStatus(this.crew);
      if(onBoard === 'On-board') {
        return true;
      } else {
        return false;
      }
    }
  }

}

/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { KeycloakService } from 'src/app/authentication/keycloak.service';


export enum ActionType {
  Create = 'Create',
  Update = 'Update',
  Verify = 'Verify',
  Delete = 'Delete',
  AdminRevert = 'AdminRevert',
}

export class DataBaseAction {
  userName: string;
  timestamp: number;
  userAction: ActionType;
}

@Injectable({
  providedIn: 'root'
})
export class DataBaseActionService {

  constructor(private _keycloakService: KeycloakService) { }

  userAction(actionType: ActionType) {
      const action: DataBaseAction = {
        userName: this._keycloakService.getUser().username,
        timestamp: new Date().getTime(),
        userAction: actionType
    };
    return action;
  }
}

<div class="row">
  <div style="height: 70vh;  margin-bottom: 10px" class="col-md-8">
    <!-- <app-map-component></app-map-component> -->
    <app-open-street></app-open-street>
  </div>
  <div class="col-md-4">
    <div class="row" *ngIf="trucksInfo && trucksInfo.length < 2">
      <div class="col-md-12">
        <div class="info-box card shadow-sm bg-primary">
          <div class="card-body">
            <div class="inner">
              <p>Distance covered</p>
              <h3>{{selectedTruckTotalDistanceTraveled | number:'1.0-0'}}<sub>Km</sub></h3>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-lg-6 col-xs-6">
        <div class="card bg-yellow">
          <div class="inner">
            <p>میانگین مسافت روزانه</p>
            <h3>{{selectedTruckTotalDistanceTraveledDaily | number:'1.0-2'}} کیلومتر</h3>

          </div>
          <div class="icon">
            <i class="ion ion-bag"></i>
          </div>
        </div>
      </div> -->
      <div style="height: 30vh;" class="col-md-12">
        <div class="card ">
          <div class="card-body">
            <div>
              <div id="speedContainer" style="width:100%; height:180px;"></div>
            </div>
          </div>
        </div>
      </div>
      <div style="height: 30vh;" class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div>
              <div id="altitudeContainer"
                style="width:100%; height:180px;"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="trucksInfo && trucksInfo.length >= 2">
      <div style="height: 70vh;  margin-bottom: 10px" class="overflow-auto">
        <table
          class="table table-sm table-striped table-bordered text-center">
          <thead class="sticky-top bg-secondary">
            <tr>
              <td>کشنده </td>
              <td>منطقه</td>
              <td>به روز رسانی</td>
              <!-- <td>سرعت</td> -->
              <td>تاریخ بارگیری</td>
              <td>وزن</td>
              <td>مبدا</td>
              <td>مقصد</td>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let truck of trucksInfo; let i=index;"
              [ngClass]="{'bg-warning': truck.outDated}">
              <td>{{truck.truckNumber }}</td>
              <td>{{truck.organisation}}</td>
              <td>{{truck.time}}</td>
              <!-- <td>{{truck.speed}}</td> -->
              <td>{{truck.lastLoading.date}}</td>
              <td>{{truck.lastLoading.weight}}</td>
              <td>{{truck.lastLoading.source}}</td>
              <td>{{truck.lastLoading.destination}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

import { Injectable } from '@angular/core';

import { HttpWrapper } from '../shared/services';
import { Observable } from 'rxjs';

export interface PhoneNumber {
  category: string;
  number: string;
}


export interface Address {
  address: string;
  city: string;
  postalCode: string;
  country: string;
}


export interface PhoneBookData {
  title: string;
  name: string;
  companyName: string;
  phoneNumbers: PhoneNumber[];
  addresses: Address[];
  createdOn: number;
  updatedOn: number;
  isDeleted: boolean;
}

@Injectable()

export class PhoneBookService {
  constructor(private _httpWrapper: HttpWrapper) { }

  add(data: PhoneBookData): Observable<PhoneBookData> {
    return this._httpWrapper.post('phonebook', data);
  }

  getNumbers(): Observable<PhoneBookData[]> {
    return this._httpWrapper.get<PhoneBookData[]>('phonebook');
  }

  getOneContact(title: string, name: string, companyName: string): Observable<PhoneBookData[]> {
    return this._httpWrapper.get<PhoneBookData[]>(`phonebook/${title}/${companyName}/${name}`);
  }

  update(data: PhoneBookData): Observable<PhoneBookData> {
    return this._httpWrapper.put('phonebook', data);
  }

  findPhoneNumberFromCategory(phoneNumbers: PhoneNumber[], category: string) {
    if (phoneNumbers.filter(p => p.category === category).length !== 0) {
      return phoneNumbers.filter(p => p.category === category)[0].number;
    } else {
      return '';
    }
  }
}


<div class="col-md-12">
  <div class="card" style="padding: 5px">
    <div class="card-header with-border">
      <h3 class="card-title">Date</h3>
    </div>
    <div class="card-body">
      <form #lgpReportForm="ngForm">
        <div class="row">
          <div class="col-md-6">
            <app-date-range
              [singleDateSelector]="false"
              [(dateRange)]="dateRange">
            </app-date-range>
          </div>
          <div class="col-md-2 col-xs-12">
            <button class="btn btn-primary" (click)="pricingPlotAll()">All
              Indices Graph</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="col-md-12">
  <div class="card" style="padding: 5px">
    <div class="card-header with-border">
      <h3 class="card-title">Providers</h3>
    </div>
    <div class="card-body">
      <form #pricing="ngForm">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <select class="form-control"
                id="selectedProvider"
                name="selectedProvider"
                [(ngModel)]="selectedProvider"
                required>
                <option *ngFor="let provider of publishers"
                  [ngValue]="provider">
                  {{provider}}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-1 col-xs-12">
            <button class="btn btn-primary" (click)="pricingList()"
              [disabled]="!pricing.form.valid">List Of Prices</button>
          </div>
          <div class="col-md-2 col-xs-12">
            <button class="btn btn-primary" (click)="marketFiles()"
              [disabled]="!pricing.form.valid">Files</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

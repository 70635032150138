import {Component, EventEmitter, OnInit} from '@angular/core';
import { Organisation, OrganisationService } from 'src/app/organisation/organisation.service';
import { Quota, LPGDistService } from 'src/app/lpg-dist/lpg-dist.service';
import { forkJoin } from 'rxjs';
import { DateRange } from 'src/app/shared/components';
import * as momentJ from 'moment-jalaali';
import { PgMessagesService, MessageLevel } from 'src/app/shared/services/pg-messages.service';
import {PgTitleComponent} from '../../../../shared/components/title-bar/title-bar.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-lpg-quota-entry',
  templateUrl: './lpg-quota-entry.component.html',
})
export class LpgQuotaEntryComponent implements OnInit, PgTitleComponent {
  title = new EventEmitter<string>();

  companies: Organisation[];
  sources: Organisation[];
  destinations: Organisation[];
  organisations: Organisation[];
  message: string;
  source: Organisation;
  selectedDate: DateRange;
  type: string;
  value: number;
  destination: Organisation;

  selectedQuota: Quota;
  importedData: Quota[] = [];

  disable: boolean;

  edit: boolean;

  company: Organisation;

  constructor(private _organisationService: OrganisationService,
              private _lpgDistService: LPGDistService,
              private _activateRoute: ActivatedRoute,
              private _pgMessagesService: PgMessagesService) { }

  ngOnInit() {
    this.title.emit('Quota');
    this._activateRoute.queryParams.subscribe(p => {
      const companyId = p['company'];
      const sourceOrganisationId = p['sourceOrganisationId'];
      const destination = p['destination'];
      this.selectedDate = {
        startDate:  momentJ().subtract('day', 1).startOf('day').toDate(),
        endDate:  momentJ().subtract('day', 1).startOf('day').toDate(),
      };
      forkJoin([
        this._organisationService.get(),
        this._organisationService.getRootOrganisations(),
        this._lpgDistService.getLatestDeterminateQuota(companyId, sourceOrganisationId, destination)
      ])
      .subscribe(data => {
        this.companies  = data[1];
        this.organisations = data[0];
        if(companyId && sourceOrganisationId && destination && data[2]) {
          this.selectedDate = {
            startDate: new Date(data[2].timestamp),
            endDate: new Date(data[2].timestamp)
          };
          this.value = data[2].value;
          this.company = this.organisations.find(o => o.name === companyId);
          this.type = 'destination';
          this.destination = this.organisations.find(o => o.name === destination);
          this.source = this.organisations.find(o => o.name === sourceOrganisationId);
          this.edit = true;
        }
      });
    });

  }

  fetchDate() {
    forkJoin([
      this._organisationService.get(),
      this._organisationService.getRootOrganisations()
    ])
    .subscribe(data => {
      this.companies  = data[1];
      this.organisations = data[0];
    });
  }

  organisationValueFormatter(data: Organisation) {
    return data.nameTranslations['fa'];
  }

  fotmatOrganisation(organisationId: string) {
    const organisation = this.organisations.find(o => o.name === organisationId);
    return (organisation !== undefined) ? organisation.nameTranslations['fa'] : '';
  }

  fotmatType(quotaType: string) {
    if (quotaType === 'quota') {
      return 'سهمیه';
    } else if (quotaType === 'sold') {
      return 'فروش';
    } else if (quotaType === 'stored') {
      return 'موجودی';
    } else if (quotaType === 'destination') {
      return 'تعیین مبدا';
    }
  }

  sourceFilter() {
    if(!this.edit && this.company) {
      this.source = null;
      this.destination = null;
      this.value = null;
      if (this.type === 'quota') {
        this.sources = this.organisations.filter(o => (o.properties['isLoading'] === true && o.name.includes('niopdc'))
         || (o.properties['isUnloading'] === true && o.name.includes(this.company.name))
         );
      }
      if (this.type === 'destination') {
        this.sources = this.organisations.filter(o => (o.properties['isLoading'] === true && o.name.includes('niopdc')));
        this.destinations = this.organisations.filter(o => o.properties['isUnloading'] === true && o.name.includes(this.company.name));
      }
      if (this.type === 'sold' || this.type === 'stored') {
        this.sources = this.organisations.filter(o => o.properties['isUnloading'] === true && o.name.includes(this.company.name));
      }
    }
  }

  onSubmit() {
    const timestampConst = (this.type === 'quota' || this.type === 'destination') ?
      momentJ(this.selectedDate.startDate).startOf('jMonth').toDate().getTime() :
      momentJ(this.selectedDate.startDate).startOf('day').toDate().getTime();
    this.selectedQuota = {
      timestamp: timestampConst,
      quotaType: this.type,
      organisationId: this.company.name,
      sourceOrganisationId: this.source.name,
      destination: (this.type === 'destination') ? this.destination.name : null,
      value: +this.value,
      isDeleted: false
    };
    this._lpgDistService.addQuota(this.selectedQuota).subscribe((result: any) => {
      this.importedData.push(result);
      this.message = `A quota is Imported`;
        this._pgMessagesService.publishMessage({
          message: this.message,
          topic: 'New quota',
          level: MessageLevel.Info
        });
    }, () => {
      this.message = 'Error';
        this._pgMessagesService.publishMessage({
          message: this.message,
          topic: 'A quota Insertion Error',
          level: MessageLevel.Error
    });
  });
}
}

import { Component, EventEmitter, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import * as Highcharts from 'highcharts';
import * as moment from 'moment-jalaali';

import { Organisation, OrganisationService } from '../../../organisation/organisation.service';
import { DateRange } from '../../../shared/components';
import { LPGDistService, LPGDistData, ToolbarItem } from '../../lpg-dist.service';
import { LpgDistToolbarComponent } from 'src/app/lpg-dist/lpg-dist-toolbar/lpg-dist-toolbar.component';
import { UserLocalStoreService } from 'src/app/shared/services/user-local-store.service';
import {
  PgDateRangeTitleComponent,
  PgDateRangeTitleEnum, PgTitleComponent,
  PgToolbarComponent
} from '../../../shared/components/title-bar/title-bar.component';

interface CompareReportComponentSettings {
  companyId: string;
  calendar: boolean;
}

@Component({
  selector: 'app-compare-report',
  templateUrl: './compare-report.component.html'
})
export class CompareReportComponent implements OnInit,
  PgTitleComponent, PgDateRangeTitleComponent, PgToolbarComponent {
  title = new EventEmitter<string>();
  dateRangeSubTitle = new EventEmitter<[DateRange, PgDateRangeTitleEnum]>();
  toolbarComponentType = LpgDistToolbarComponent;
  toolbarComponent: LpgDistToolbarComponent;
  dateRange: DateRange;
  startYear: number;
  endYear: number;
  selectedOrganisation: Organisation;
  companies: Organisation[];
  totalLoadingWeight: number;
  dailyWeight: number;

  _settings: CompareReportComponentSettings;


  constructor(private _lpgDistService: LPGDistService,
              private _organisationService: OrganisationService,
              private _userLocalStorage: UserLocalStoreService) { }


  setToolbar() {
    this.selectedOrganisation = this.companies.find(c => c.name === this._settings.companyId);
    this.title.emit('Yearly Loadings ' + ' ' + this.selectedOrganisation.nameTranslations['en']);
    this.dateRangeSubTitle.emit([this.dateRange, PgDateRangeTitleEnum.defaultTitle]);
    this.toolbarComponent.dateRange = this.dateRange;
    this.toolbarComponent.organisations = this.companies
    .filter(o =>  ['domestic', 'isSeller'].filter(s => o.properties[s]).length > 0);
    this.toolbarComponent.selectedOrganisation =  this.companies.find(o => o.name === this.selectedOrganisation.name);
    this.toolbarComponent.jalali = this._settings.calendar;
    this.toolbarComponent.singleDate = true;
    this.toolbarComponent.visibleOrganisation = true;
    this.toolbarComponent.itemsSelected.subscribe (t => {
      this.refreshData(t);
    });
  }

  refreshData(item: ToolbarItem) {
    this._settings = {
      companyId: this.companies.find(o => o.name === item.companyId).name,
      calendar: item.selectedCalander
    };
    this._userLocalStorage.save('CompareReportComponent', this._settings);
    this.dateRange = item.dateRange;
    this.setDate();
    this.fetchData();
  }

  setDate() {
    const startTime = moment(this.dateRange.endDate).subtract(3, 'years');
    this.startYear = moment(startTime).startOf('jYear').unix() * 1000;
    this.endYear = moment(this.dateRange.endDate).endOf('jYear').unix() * 1000;
  }

  fetchData() {
    forkJoin([
      this._organisationService.get(),
      this._lpgDistService.getOrganisationSummary(this._settings.companyId,
        this.startYear,
        this.endYear)
    ]).subscribe(result => {
      this.companies = result[0];
      this.setToolbar();
      this.totalLoadingWeight = result[1].reduce((p, c) => p + c.loadingWeight, 0);
      this.dailyWeight = result[1].reduce((p, c) => p + c.loadingWeight, 0) / moment(this.dateRange.endDate).diff(this.dateRange.startDate, 'days');
      this.report(result[1]);
    });
  }

  ngOnInit(): void {
    this._settings = this._userLocalStorage.get<CompareReportComponentSettings>('CompareReportComponent');
    if (!this._settings || !this._settings.companyId) {
      this._settings = { companyId: 'iran.parsagroup.orsagas', calendar: true };
    }
    const endDate = moment().endOf('day').toDate();
    const startDate = moment(endDate).startOf('jMonth').toDate();
    this.dateRange = {
      startDate: new Date (startDate),
      endDate: new Date (endDate)
    };
    this.setDate();
    this.fetchData();
  }



  groupBy<T>(list: T[], keyGetter: (item: T) => (string | number)): Map<(string | number), T[]> {
    const mapArray = new Map<(string | number), T[]>();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = mapArray.get(key);
      if (!collection) {
        mapArray.set(key, [item]);
      } else {
        collection.push(item);
      }
    });

    return mapArray;
  }

  report(data: LPGDistData[]) {
    const yearSeries = this.comparativeReport(data, 'jYear');
    const monthSeries = this.comparativeReport(data, 'jMonth');
    this.plotBarChart(monthSeries[0], 'sourceMonthlyBarChartContainer', monthSeries[1]);
    this.plotLineChart(monthSeries[0], 'dailyReportLineChartContainer', monthSeries[1]);
    this.plotBarChart(yearSeries[0], 'sourceYearlyBarChartContainer', yearSeries[1]);
  }

  comparativeReport(data: LPGDistData[], range: string) {
    moment.loadPersian();
    let series = [];
    const startTime = this.startYear;
    let startYear = moment(this.endYear).startOf('jYear').unix() * 1000;
    let endYear = this.endYear;
    let result: any[] = [];
    let category = [];
    while (endYear > startTime) {
      let groupByYear = [];
      groupByYear = data.filter(d => d.loadTimestamp >= startYear && d.loadTimestamp < endYear);
      let groupByDate: any;
      if(range === 'jMonth') {
        groupByDate = this.groupBy<LPGDistData>(groupByYear, i => moment(i.loadTimestamp).startOf('jMonth').unix() * 1000);
      } else {
        groupByDate = this.groupBy<LPGDistData>(groupByYear, i => moment(i.loadTimestamp).startOf('jYear').unix() * 1000);
      }

      groupByDate.forEach((values, key) => {
        const totalPergroup = values.reduce((p, v) => p + v.loadingWeight, 0);
        if (range === 'jMonth') {
          result.push([key, moment(key).format('jMMMM'), Math.round(totalPergroup / 1000)]);
        } else {
          result.push([key, moment(key).format('jYYYY'), Math.round(totalPergroup / 1000)]);
        }
      });
      result = result.sort((a, b) => a[0] - b[0]);
      const seri = {
        name: moment(startYear).format('jYYYY'),
        data: result.map(r => r[2])
      };

      if (result.length > category.length && range === 'jMonth') {
        category = result.map(r => r[1]);
      } else {
        category.push(result.map(r => r[1])[0]);
      }
      series.push(seri);
      endYear = startYear;
      startYear = moment(startYear - 1).startOf('jYear').unix() * 1000;
      result = [];
    }
    if (range === 'jYear') {
      category.reverse();
      const seri = {
        name: 'مقایسه سالانه',
        data: series.map(s => s.data[0]).reverse(),
        colorByPoint: true
      };
      series = [];
      series.push(seri);
    }

    series = series.sort((a, b) => a.name - b.name);
    return [series, category];
  }

  plotLineChart(data, containerId, groups) {
    Highcharts.chart(containerId, {
      title: {
        text: ''
      },
      xAxis: {
        categories: groups,
      },
      yAxis: {
        title: {
          text: 'وزن (تن)'
        }
      },
      series: data,
      tooltip: {
        formatter() {
          return `${this.x} ${this.series.name} <br> ${this.y} تن `;
        }
      }
    });

  }

  plotBarChart(data, containerId, groups) {
    Highcharts.chart(containerId, {
      title: {
        text: ''
      },
      chart: {
        type: 'column'
      },
      xAxis: {
        categories: groups
      },
      yAxis: {
        title: {
          text: 'وزن (تن)'
        }
      },
      series: data,
      tooltip: {
        formatter() {
          return `${this.x} ${this.series.name} <br> ${this.y} تن`;
        }
      }
    });

  }
}

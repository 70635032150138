<div class="row ">
    <div class="col-md-12">
        <table  datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" id="tblSearchCust"
                class="table table-sm table-striped  table-bordered">
          <thead>
            <tr>
              <td>Commitment Number</td>
              <td>Seller</td>
              <td>Buyer</td>
              <td>Transporter</td>
              <td>Source</td>
              <td>Quantity</td>
              <td>Registration Date</td>
              <td>EXP Date</td>
              <td>Verify</td>
              <td>Edit</td>
              <td  *appHideIfNotInRole="['admin-editor']">ویرایش مدیر</td>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let cnt of contracts">
            <td>{{cnt.internalContractNo}}</td>
            <td>{{formatOrganisation(cnt.sellerOrganisationId)}}</td>
            <td>{{formatOrganisation(cnt.buyerOrganisationId)}}</td>
            <td>{{formatOrganisation(cnt.transporterOrganisationId[0])}}</td>
            <td>{{formatOrganisation(cnt.sourceOrganisationId)}}</td>
            <td>{{cnt.quantity}}</td>
            <td>{{formatDate(cnt.startDate)}}</td>
            <td>{{formatDate(cnt.expirationDate)}}</td>
            <td class="visible-md visible-lg">
              <input id="verify" name="verify" type="checkbox" class="btn-link" (change)="onVerify(cnt)" [checked]="cnt.verify"
                     [disabled]="!hasRole('contract-verifier')">
            </td>
            <td > <button id="edit" name="edit" type="submit" class="btn btn-link" (click)="editRecord(cnt)"
                          [disabled]="!hasRole('contract-verifier')">
              <i class="fa fa-pencil-square-o"></i>
            </button></td>
            <td *appHideIfNotInRole="['admin-editor']"> 
              <button id="edit" name="edit" type="submit" class="btn btn-link" (click)="adminEdit(cnt)">
              <i class="fa fa-user"></i>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
    </div>
  </div>

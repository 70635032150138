<ul class="nav flex-row-reverse">
    <li class="nav-item col-sm-6 col-md-6">
        <app-date-range
            id="selectedDateRangeGregorian"
            [(dateRange)]="dateRange"
            (dateRangeChange)="selectDateRange($event)"
            >
        </app-date-range>
    </li>
    <li class="nav-item">
        <app-auto-complete
            [formatter]="vesselListFormatter"
            placeholder="Vessel"
            [ngModel]="_selectedVessel"
            (ngModelChange)="selectVessel($event)"
            name="vessel"
            [items]="vessels">
        </app-auto-complete>
    </li>
</ul>

<div class="row ">
  <div class="col-md-12">
      <table  datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" id="tblSearchCust"
              class="table table-sm table-striped  table-bordered">
        <thead>
          <tr>
            <td>Commitment Number</td>
            <td>Source</td>
            <td>Buyer</td>
            <td>Persian start date</td>
            <td>Persian EXP date</td>
            <td>Gregorian start date</td>
            <td>Gregorian Exp date</td>
            <td>Quantity</td>
            <td>Sum of loading</td>
            <td>Estimated</td>
            <td>Remainder</td>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let cnt of balanceData">
          <td>{{cnt.internalContractNo}}</td>
          <td>{{formatOrganisation(cnt.sourceOrganisationId)}}</td>
          <td>{{formatOrganisation(cnt.buyerOrganisationId)}}</td>
          <td>{{cnt.startDate | persianDate }}</td>
          <td>{{cnt.expirationDate | persianDate}}</td>
          <td>{{formatDates(cnt.startDate)}}</td>
          <td>{{formatDates(cnt.expirationDate)}}</td>
          <td>{{cnt.quantity}}</td>
          <td>{{cnt.loading}}</td>
          <td>{{cnt.estimated}}</td>
          <td>{{cnt.balance}}</td>
        </tbody>
      </table>
  </div>
</div>

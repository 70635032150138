<ul class="nav flex-row-reverse">
  <li class="nav-item">
    <div *ngIf="visibleOrganisation && jalali">
      <app-date-range id="selectedDateRangeGregorian"
        [singleDateSelector]="singleDate"
        [(jalali)]="jalali"
        [(dateRange)]="dateRange">
      </app-date-range>
    </div>
  </li>
  <li class="nav-item">
    <div *ngIf="visibleOrganisation && jalali">
      <app-organisation-selector
        class="form-control pg-component form-control-navbar"
        style="text-align: center"
        placeholder="Company" [ngModel]="selectedOrganisation"
        (ngModelChange)="emitOrganisation($event)"
        [organisationLanguage]="'fa'" name="organisation"
        [organisations]="organisations" required>
      </app-organisation-selector>
    </div>
    <div *ngIf="visibleOrganisation && !jalali">
      <app-organisation-selector class="form-control pg-component"
        style="text-align: center" placeholder="Company"
        [ngModel]="selectedOrganisation"
        (ngModelChange)="emitOrganisation($event)" [organisationLanguage]="'en'"
        name="organisation" [organisations]="organisations" required>
      </app-organisation-selector>
    </div>
  </li>
  <li class="nav-item">
    <a class="btn text-muted" (click)="inputOutput('input')">Incoming</a>
  </li>
  <li class="nav-item">
    <a class="btn text-muted" (click)="inputOutput('output')">Outgoing</a>
  </li>
  <li class="nav-item">
    <a class="btn text-muted" (click)="showAll()">ShowAll</a>
  </li>
</ul>
import {Component, ComponentFactoryResolver, EventEmitter, OnInit, Type, ViewChild} from '@angular/core';
import {BillOfLading, VesselsService, Voyage, Discharge} from 'src/app/transportation/vessels/vessels.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DocumentItem} from 'src/app/documents/document-item';
import {DocumentReference, DocumentService} from 'src/app/documents/documents.service';
import {DocumentDirective} from 'src/app/documents/document.directive';
import {BlComponent} from './bl/bl.component';
import {NorComponent} from './nor/nor.component';
import {DischargeComponent} from 'src/app/transportation/vessels/voyage/discharge/discharge.component';
import {VoyagePartComponent} from 'src/app/transportation/vessels/voyage/voyage-part-component';
import {SofComponent} from 'src/app/transportation/vessels/voyage/sof/sof.component';
import {Organisation, OrganisationService} from 'src/app/organisation/organisation.service';
import {DateTimeHelpers} from 'src/app/shared/helpers/date-time.helpers';
import {KeycloakService} from 'src/app/authentication/keycloak.service';
import {forkJoin} from 'rxjs';
import {LookupService} from 'src/app/shared/services/lookup.service';
import {CommentComponent} from 'src/app/transportation/vessels/voyage/comment/comment.component';
import {MessageLevel, PgMessagesService} from 'src/app/shared/services/pg-messages.service';
import {MiscDocumentComponent} from './misc-document/misc-document.component';
import {saveAs} from 'file-saver';
import {VoyageToolbarComponent} from './voyage-toolbar/voyage-toolbar.component';
import * as _ from 'lodash';
import {
  PgSubTitleComponent,
  PgTitleComponent,
  PgToolbarComponent
} from '../../../shared/components/title-bar/title-bar.component';
import { ActionType, DataBaseActionService } from 'src/app/shared/services/database-action.service';
import { ChartererComponent } from './charterer/charterer.component';


@Component({
  selector: 'app-voyage',
  templateUrl: './voyage.component.html',
  styleUrls: ['./voyage.component.css']
})
export class VoyageComponent implements OnInit, PgTitleComponent, PgSubTitleComponent, PgToolbarComponent {
  @ViewChild(DocumentDirective) documentHost: DocumentDirective;

  subTitle: EventEmitter<string> = new EventEmitter();
  title: EventEmitter<string> = new EventEmitter();
  toolbarComponentType?: Type<any> = VoyageToolbarComponent;
  get toolbarComponent() {
    return this._toolbar;
  }
  set toolbarComponent(value: VoyageToolbarComponent) {
    this._toolbar = value;
    this.toolbarComponent.addDocument.subscribe(addRequest => {
      this.showAddDocument(addRequest[0], addRequest[1]);
    });
  }

  selectedVoyage: Voyage;
  showTabs: boolean;
  // showAddMetaData: boolean;
  showDocumentUpload: boolean;
  canSkipDocumentUpload: boolean;

  selectedDocumentType: string;
  selectedDocumentIsVerified: boolean;
  currentFiles: FileList;
  uploadedFileId: string;

  organisations: Organisation[];
  allSofItems: string [][];
  ports: Organisation[];
  volumes: any;

  calculateLaytime = VesselsService.calculateLaytime;
  calculateBerthTime = VesselsService.calculateBerthTime;
  voyageLengthFromLoadingToDischarge = VesselsService.voyageLengthFromLoadingToDischarge;

  providers =  [
    new DocumentItem(BlComponent, 'Bill of Lading', ''),
    new DocumentItem(NorComponent, 'NOR', ''),
    new DocumentItem(SofComponent, 'SOF', ''),
    new DocumentItem(DischargeComponent, 'Discharge', ''),
    new DocumentItem(CommentComponent, 'Comment', ''),
    new DocumentItem(MiscDocumentComponent, 'Misc-Document', ''),
    new DocumentItem(ChartererComponent, 'Charterer', ''),
  ];

  private _toolbar: VoyageToolbarComponent;

  constructor(private _vesselsService: VesselsService,
              private _documentsService: DocumentService,
              private _lookupService: LookupService,
              private _componentFactoryResolver: ComponentFactoryResolver,
              private _activatedRoute: ActivatedRoute,
              private _organisationService: OrganisationService,
              private _pgMessagesService: PgMessagesService,
              private _keycloakService: KeycloakService,
              private _actionService: DataBaseActionService,
              private _router: Router) {
    this.selectedVoyage = {
      id: '00000000-0000-0000-0000-000000000000',
      vesselIMO: '',
      vesselName: '',
      charterer: null,
      shipVoyageNo: null,
      agentVoyageNo: null,
      nors: [],
      sofs: [],
      discharges: [],
      billOfLadings: [],
      completed: false,
      comments: [],
      miscDocuments: [],
      commitmentNo: null,
      actions: [],
    };
  }

  hasRole(role: string) {
    return this._keycloakService.hasRole(role);
  }

  ngOnInit() {
    this._activatedRoute.queryParamMap.subscribe(() => {
      this._vesselsService.getVoyage(this.selectedVoyage.id).subscribe(updatedVoyage => {
        this.selectedVoyage = updatedVoyage;
        this.showDocumentUpload = false;
        this.selectedDocumentType = '';
        this.selectedDocumentIsVerified = false;
        this.showTabs = true;
        this.volumes = this.calculateVolumes();
      });
    });
    this._activatedRoute.paramMap.subscribe(p => {
      forkJoin([
        this._vesselsService.getVoyage(p.get('id')),
        this._lookupService.get('voyage_sof_all'),
        this._organisationService.getPorts(),
        this._organisationService.get()
      ]).subscribe(results => {
        const v = results[0];
        this.selectedVoyage = v;
        this.allSofItems = results[1].items;
        this.ports = results[2];
        this.organisations = results[3];
        this.selectedVoyage.sofs.forEach(sof => {
          sof.entries = sof.entries.sort((s1, s2) => s1[1] - s2[1]);
        });
        this.volumes = this.calculateVolumes();
        this.title.emit(v.vesselName);
        const agentVoyageNo = (v.agentVoyageNo) ? '(' + v.agentVoyageNo + ')' : '';
        this.subTitle.emit(`${v.shipVoyageNo} ${agentVoyageNo}`);
        this.toolbarComponent.currentVoyageId = this.selectedVoyage.id;
        if (p.get('docType')) {
          this.selectedDocumentType = p.get('docType');
          this.uploadedFileId = p.get('fileId');
          this.loadComponent(p.get('fileId'));
        } else {
          this.showTabs = true;
          this.canSkipDocumentUpload = false;
        }
      });
    });
  }

  saveVoyage() {
    if(this.selectedVoyage.actions === undefined) {
      this.selectedVoyage.actions = [];
    }
    this.selectedVoyage.actions.push(this._actionService.userAction(ActionType.Update));
    this.updateVoyage(this.selectedVoyage);
    this.uploadedFileId = null;
  }


  uploadDocument(docReference: DocumentReference) {
    const previousFileId = this.uploadedFileId;
    this.uploadedFileId = docReference.id;
    this.showTabs = false;
    this.showDocumentUpload = false;
    this.loadComponent(this.uploadedFileId, previousFileId);
    this.currentFiles = null;
  }

  findNorLocations(norType: string) {
    const loadingNors = this.selectedVoyage.nors.filter(n => n.norType === norType);
    if (loadingNors && loadingNors.length > 0) {
      return loadingNors.map(n => this.findOrganisation(n.portOrganisationId));
    }
  }

  findIfDocumentTypeIsVerified(fileId: string, documentType: string) {
    let userActions = [];
    if (documentType === 'Bill of Lading') {
      userActions = this.selectedVoyage.billOfLadings.find(b => b.fileId === fileId).actions;
    } else if (documentType === 'NOR') {
      userActions = this.selectedVoyage.nors.find(b => b.fileId === fileId).actions;
    } else if (documentType === 'SOF') {
      userActions = this.selectedVoyage.sofs.find(b => b.fileId === fileId).actions;
    } else if (documentType === 'Discharge') {
      userActions = this.selectedVoyage.discharges.find(b => b.fileId === fileId).actions;
    }
    if (userActions && userActions.length > 0) {
      return userActions.sort((t1, t2) => t2.timestamp - t1.timestamp)[0].userAction === ActionType.Verify;
    } else {
      return false;
    }
  }

  edit(fileId: string, documentType: string) {
    this.selectedDocumentType = documentType;
    this.selectedDocumentIsVerified = this.findIfDocumentTypeIsVerified(fileId, documentType);
    this.showDocumentUpload = false;
    this.showTabs = false;
    this.uploadedFileId = fileId;
    setTimeout(() => {
      this.loadComponent(fileId);
    }, 1);
  }

  loadComponent(fileId: string, previousFileId: string = null) {
    const selectedType = this.providers.filter(d => d.key === this.selectedDocumentType)[0];
    const componentFactory =
      this._componentFactoryResolver.resolveComponentFactory(selectedType.component);

    const viewContainerRef = this.documentHost.viewContainerRef;
    viewContainerRef.clear();
    const component = viewContainerRef.createComponent(componentFactory);

    if (previousFileId) {
      (component.instance as VoyagePartComponent).fileId = previousFileId;
      (component.instance as VoyagePartComponent).updatedFileId = fileId;
    } else {
      (component.instance as VoyagePartComponent).fileId = fileId;
      (component.instance as VoyagePartComponent).updatedFileId = null;
    }
    (component.instance as VoyagePartComponent).data = this.selectedVoyage;
    (component.instance as VoyagePartComponent).changed.subscribe(v => {
      this.updateVoyage(v);
    });
    (component.instance as VoyagePartComponent).cancelled.subscribe(() => {
      this.cancelled();
    });
  }

  showAddDocument(documentType: string, canSkip = false) {
    this.selectedDocumentType = documentType;
    this.selectedDocumentIsVerified = false;
    this.showDocumentUpload = true;
    this.showTabs = false;
    this.uploadedFileId = null;
    this.canSkipDocumentUpload = canSkip;
    if (this.documentHost) {
      this.documentHost.viewContainerRef.clear();
    }
  }

  showTabsToggle() {
    this.showTabs = true;
  }

  updateVoyage(voyage: Voyage) {
    this._vesselsService.updateVoyage(voyage).subscribe(v => {
      this._pgMessagesService.publishMessage({
        level: MessageLevel.Info,
        message: `Updated voyage ${voyage.shipVoyageNo} for ${voyage.vesselName}`,
        topic: 'Voyage'
      });
      this._router.navigate(['vessels/voyage', v.id], {
        queryParams: {
          t: new Date().getTime()
        }
      }).then();
    });
  }

  cancelled() {
    this.selectedDocumentType = '';
    this.selectedDocumentIsVerified = false;
    this.showTabs = true;
    this.uploadedFileId = null;
    this._activatedRoute.paramMap.subscribe(p => {
      if (p.get('id')) {
        this._vesselsService.getVoyage(p.get('id')).subscribe(v => {
          this.selectedVoyage = v;
        });
      }
    });
  }

  formatDate(dt: number, portOrganisationId: string) {
    if (this.ports) {
      const selectedTimezone = this._organisationService.getOrganisationTimezone(portOrganisationId, this.ports);
      if (selectedTimezone) {
        return DateTimeHelpers.getDateFromTimestamp(selectedTimezone, dt);
      } else {
        console.error(`Cannot find timezone for organisation Id: ${portOrganisationId}`);
      }
    }
  }

  formatProducts(bl: BillOfLading) {
    return bl.products.map(p => `${p.product}: ${p.grossWeight}`).join(', ');
  }

  findOrganisation(organisationId: string) {
    if (this.organisations) {
      const organisation = this.organisations.find(p => p.name === organisationId);
      if (organisation) {
        return organisation.nameTranslations['en'];
      } else {
        return 'not found';
      }
    }
  }

  updateDocument(documentType: string, currentFileId: string) {
    this.selectedDocumentType = documentType;
    this.selectedDocumentIsVerified = false;
    this.showDocumentUpload = true;
    this.showTabs = false;
    this.uploadedFileId = currentFileId;
  }

  skipDocumentUpload() {
    this.uploadedFileId = null;
    this.showDocumentUpload = false;
    this.showTabs = false;
    this.loadComponent(null);
    this.currentFiles = null;
  }

  downloadFile(fileId: string, prefix: string) {
    this._documentsService.download(fileId).subscribe(b => {
      saveAs(b.body, `${prefix}_${fileId}.pdf`);
    }, () => {
      this._pgMessagesService.publishMessage({
        message: 'Could not download this file.',
        level: MessageLevel.Error,
        topic: 'Voyage document'
      });
    });
  }

  calculateVolumes() {
    const sumOfRevise = (this.selectedVoyage.billOfLadings) ?
      _.chain(this.selectedVoyage.billOfLadings.filter(bill => bill.revise === true).flatMap(b => b.products))
      .groupBy(b => b.product)
      .map((v, k) => {
        const grossWeight = v.reduce((x, y) => x + y.grossWeight, 0);
        return {
          product: k,
          grossWeight
        };
      }).value() : null;

    const sumOfReal = (this.selectedVoyage.billOfLadings) ?
      _.chain(this.selectedVoyage.billOfLadings.filter(bill => bill.revise !== true).flatMap(b => b.products))
      .groupBy(b => b.product)
      .map((v, k) => {
        const grossWeight = v.reduce((x, y) => x + y.grossWeight, 0);
        return {
          product: k,
          grossWeight
        };
      }).value() : null;

    const dischWeight = (this.selectedVoyage.discharges) ?
      _.chain(this.selectedVoyage.discharges.flatMap(dch => dch.measurements.filter(f => f.primary === true)))
      .groupBy(d => d.product)
      .map((v, k) => {
        const weight = v.reduce((x, y) => x + y.weight, 0);
        return {
          product: k,
          weight
        };
      }).value(): null;

    const productDiffs = (dischWeight && sumOfReal) ?
      dischWeight.map(d => {
        const load = sumOfReal.find(b => b['product'] === d['product']);
        let productDiff = null;
        if (load) {
          productDiff = d['weight'] - load['grossWeight'];
        }
        return {
          product: d['product'],
          diff: productDiff
        };
      }) : null;

      const totalLoading = sumOfReal.map(p => p.grossWeight).reduce((x, y) => x + y , 0);
      const totalDischarge = dischWeight.map(p => p.weight).reduce((x, y) => x + y , 0);
      const allDifferences = totalDischarge - totalLoading;
    return {
      loadPorts: this.findNorLocations('loading'),
      dischargePorts: this.findNorLocations('discharging'),
      loadingRealWeight: sumOfReal,
      loadingReviseWeight: sumOfRevise,
      dischargeWeight: dischWeight,
      diff: productDiffs,
      allDiff: allDifferences,
      allLoading: totalLoading,
      allDischarging: totalDischarge,
      noLoadingPorts: this._vesselsService.countPorts(this.selectedVoyage, 'loading'),
      noDischargePorts: this._vesselsService.countPorts(this.selectedVoyage, 'discharge'),
      timeBetweenLoadingDis: this.voyageLengthFromLoadingToDischarge(this.selectedVoyage),
      loadingBerthTime: this.calculateBerthTime(this.selectedVoyage, 'loading'),
      dischargeBerthTime: this.calculateBerthTime(this.selectedVoyage, 'discharge'),
      loadingLaytime: this.calculateLaytime(this.selectedVoyage, 'loading'),
      dischargeLaytime: this.calculateLaytime(this.selectedVoyage, 'discharge')
    };
  }

  containsPrimary(discharge: Discharge) {
    return discharge.measurements.find(m => m.primary);
  }

  sumDischarges(discharge: Discharge) {
    return _.chain(discharge.measurements.filter(f => f.primary === true))
      .groupBy(d => d.product)
      .map((v, k) => {
        const weight = v.reduce((x, y) => x + y.weight, 0);
        return {
          product: k,
          weight
        };
      }).value();
  }
}

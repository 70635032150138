<form #commentForm="ngForm"
  class="form-horizontal"
  (ngSubmit)="saveCharterer()">
  <div class="form-group row">
    <label for="charterer" class="col-form-label col-md-4 ">Charterer</label>
    <div class="col-md-8">
      <app-organisation-selector class="pg-component"
        [(organisation)]="charterer" [organisationLanguage]="'en'"
        name="charterer" [organisations]="organisations" required>
      </app-organisation-selector>
    </div>
  </div>
  <div class="btn-group pull-right mt-3" role="group" aria-label="Button group">
    <button type="button"
      id="cancel"
      class="btn btn-outline-secondary btn-light me-1"
      (click)="cancelled.emit()">Back to voyage
    </button>
    <button type="submit"
      id="submit"
      class="btn btn-primary"
      [disabled]="!commentForm.form.valid">Save
    </button>
  </div>
</form>

<div class="btn-group" role="toolbar"
  aria-label="Button group with nested dropdown">
  <div class="btn-group me-1"
    *appHideIfNotInRole="['vessels-crew-change-editor']">
    <button class="btn btn-outline-secondary btn-light dropdown-toggle"
      type="button" id="addButtonGroup" data-bs-toggle="dropdown"
      aria-expanded="false">
      Add
    </button>
    <ul class="dropdown-menu" aria-labelledby="addButtonGroup">
      <li><a class="dropdown-item" (click)="addCrew()">Crew</a></li>
      <li><a class="dropdown-item" (click)="addAppraisal()">Appraisal</a></li>
    </ul>
  </div>
  <div class="btn-group">
    <button class="btn btn-outline-secondary btn-light dropdown-toggle"
      type="button" id="downloadButtonGroup" data-bs-toggle="dropdown"
      aria-expanded="false">
      Download
    </button>
    <ul class="dropdown-menu" aria-labelledby="downloadButtonGroup">
      <li><a class="dropdown-item" (click)="downloadCrew()">Crew</a></li>
      <!--      <li><a class="dropdown-item" (click)="downloadLatestStatusCrew()">Latest Crew Changes</a></li>-->
      <li><a class="dropdown-item" (click)="downloadAllCrewChanges()">All Crew
          Changes</a></li>
    </ul>
  </div>
</div>

import {Component, EventEmitter, OnInit, ViewChild} from '@angular/core';
import {Vessel, VesselsService} from '../vessels.service';
import {Observable, Subject} from 'rxjs';
import * as moment from 'moment';
import {VesselBudget, VesselManagementService} from '../services/vessel-management.service';
import {DataTableDirective} from 'angular-datatables';
import {DataTablesService} from '../../../shared/datatables/data-tables.service';
import {MessageLevel, PgMessagesService} from '../../../shared/services/pg-messages.service';
import {PgTitleComponent} from '../../../shared/components/title-bar/title-bar.component';

@Component({
  selector: 'app-vessel-management-cost',
  templateUrl: './vessel-management-cost.component.html',
})
export class VesselManagementCostComponent implements OnInit, PgTitleComponent {
  @ViewChild(DataTableDirective, {static: true})
  dtElement: DataTableDirective;
  title: EventEmitter<string> = new EventEmitter();

  budgets: Observable<VesselBudget[]>;
  vessels: Observable<Vessel[]>;
  vessel: Vessel;
  years: number[];
  selectedYear: number;
  selectedQuarter: string;
  daily: number;

  isDataTablesInitialised = false;

  dtOptions: any;
  dtTrigger: Subject<any> = new Subject<any>();

  constructor(private _vesselsService: VesselsService,
              private _vesselManagementService: VesselManagementService,
              private _pgMessagesService: PgMessagesService) { }

  ngOnInit() {
    this.title.emit('Vessels Daily budget');
    this.vessels = this._vesselsService.getActiveVessels();
    this.budgets = this._vesselManagementService.getBudgets();
    const thisYear = moment().year();
    this.years = Array.of(thisYear - 1, thisYear, thisYear + 1);
    this.dtOptions = DataTablesService.getOptionsWithNoColumnsEn();
  }

  addBudget() {
    const vesselBudget: VesselBudget = {
      vesselName: this.vessel.name.name,
      vesselIMO: this.vessel.IMO,
      year: this.selectedYear,
      quarter: this.selectedQuarter,
      dailyCost: this.daily
    };

    this._vesselManagementService.saveBudget(vesselBudget).subscribe(() => {
      this._pgMessagesService.publishMessage({
        message: 'Successfully saved budget',
        topic: 'Vessel budget',
        level: MessageLevel.Info
      });
      this.budgets = this._vesselManagementService.getBudgets();
    }, () => {
      this._pgMessagesService.publishMessage({
        message: 'Failed to save budget',
        topic: 'Vessel budget',
        level: MessageLevel.Error
      });
    });
  }
}

import {Component, EventEmitter, OnInit} from '@angular/core';
import {UserProfile, DigitalIdFile, SecurityService} from '../security.service';
import {ActivatedRoute} from '@angular/router';
import {DocumentReference, DocumentService} from '../../documents/documents.service';
import {MessageLevel, PgMessagesService} from '../../shared/services/pg-messages.service';
import {saveAs} from 'file-saver';
import {PgTitleComponent} from '../../shared/components/title-bar/title-bar.component';
import {KeycloakService} from 'src/app/authentication/keycloak.service';

@Component({
  selector: 'app-user-profile-data-entry',
  templateUrl: './user-profile-data-entry.component.html',

})
export class UserProfileDataEntryComponent implements OnInit, PgTitleComponent {
  title = new EventEmitter<string>();

  digitalId: UserProfile;
  mode = 'new';
  success = true;
  showMessage = false;

  selectedDigitalIdFile: DigitalIdFile;
  showAddNewDigitalId = false;
  currentFiles: FileList;

  constructor(private _securityService: SecurityService,
              private _documentsService: DocumentService,
              private _pgMessagesService: PgMessagesService,
              private _activatedRoute: ActivatedRoute,
              private _keycloakService: KeycloakService) { }

  ngOnInit() {
    this.title.emit('User profile');
    this.digitalId = {
      portalUserName: '',
      profileImageId: '',
      email: '',
      fullName: '',
      digitalIdFiles: [],
      confirm: false
    };

    this.selectedDigitalIdFile = {
      email: '',
      fileId: '',
    };

    this._activatedRoute.paramMap.subscribe(p => {
      if (p.get('portalUserName') && p.get('portalUserName') !== 'new') {
        this.mode = 'edit';
        this._securityService.getById(p.get('portalUserName')).subscribe(d => {
          this.digitalId = d;
        });
      }
    });
  }

  onAddNewId() {
    this._securityService.add(this.digitalId).subscribe(() => {
      this.mode = 'edit';
      this._pgMessagesService.publishMessage({
        topic: 'Digital Ids',
        level: MessageLevel.Info,
        message: 'Digital Id added successfully.'
      });
    }, (error) => {
      if (error.status === 409) {
        this._pgMessagesService.publishMessage({
          topic: 'Digital Ids',
          level: MessageLevel.Error,
          message: 'Digital Id already exists.'
        });        this.success = false;
      } else {
        this._pgMessagesService.publishMessage({
          topic: 'Digital Ids',
          level: MessageLevel.Error,
          message: 'Unknown error happened. Please get in touch with support.'
        });
      }
    });
  }

  showAddNewDigitalIdForm() {
    this.showAddNewDigitalId = true;
    this.selectedDigitalIdFile = {
      email: '',
      fileId: '',
    };
  }

  updateDigitalId() {
    this._securityService.update(this.digitalId).subscribe(d => {
      this.digitalId = d;
      this._pgMessagesService.publishMessage({
        topic: 'Digital Ids',
        level: MessageLevel.Info,
        message: 'Digital Id updated successfully.'
      });
      this.showAddNewDigitalId = false;
    }, () => {
      this.success = false;
      this._pgMessagesService.publishMessage({
        topic: 'Digital Ids',
        level: MessageLevel.Error,
        message: 'Unknown error happened. Please get in touch with support.'
      });
    });
  }

  addNewDigitalIdFile(r: DocumentReference) {
    this.selectedDigitalIdFile.fileId = r.id;
    if (this.digitalId.digitalIdFiles) {
      this.digitalId.digitalIdFiles.push(this.selectedDigitalIdFile);
    } else {
      this.digitalId.digitalIdFiles = [this.selectedDigitalIdFile];
    }
    this.updateDigitalId();
  }

  downloadFile(fileId: string, fileName: string) {
    this._documentsService.download(fileId).subscribe(b => {
      saveAs(b.body, `${fileName}.p12`);
    }, () => {
      this._pgMessagesService.publishMessage({
        message: 'Could not download this file.',
        level: MessageLevel.Error,
        topic: 'Digital Ids'
      });
    });
  }

  deleteDigitalId(digitalIdFile: DigitalIdFile) {
    if (confirm(`Are you sure you want to delete digital Id for ${digitalIdFile.email}?`)) {
      const index = this.digitalId.digitalIdFiles.indexOf(digitalIdFile);
      this.digitalId.digitalIdFiles.splice(index, 1);
      this.updateDigitalId();
    }
  }
}

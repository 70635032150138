import {Component, EventEmitter, OnInit, ViewChild} from '@angular/core';
import { Organisation, OrganisationService } from 'src/app/organisation/organisation.service';
import { VesselsService, NauticalMile } from 'src/app/transportation/vessels/vessels.service';
import { forkJoin, Subject } from 'rxjs';
import { PgMessagesService, MessageLevel } from 'src/app/shared/services/pg-messages.service';
import { DataTableDirective } from 'angular-datatables';
import { DataTablesService } from '../../../shared/datatables/data-tables.service';
import {PgTitleComponent} from '../../../shared/components/title-bar/title-bar.component';

@Component({
  selector: 'app-nautical-mile',
  templateUrl: './nautical-mile.component.html'
})
export class NauticalMileComponent implements OnInit, PgTitleComponent {
  @ViewChild(DataTableDirective, {static: true})
  dtElement: DataTableDirective;
  title = new EventEmitter<string>();

  ports: Organisation[];
  selectedNauticalMile: NauticalMile;
  port1: string;
  port2: string;
  distance: number;
  importedData: NauticalMile[] = [];

  dtOptions: any;
  dtTrigger: Subject<any> = new Subject<any>();
  isDataTablesInitialised = false;

  constructor(private _organisationService: OrganisationService,
              private _vesselsService: VesselsService,
              private _pgMessagesService: PgMessagesService) {
    this.dtOptions = DataTablesService.getOptionsWithNoColumnsEn();
  }

  ngOnInit() {
    this.title.emit('Nautical distances');
    forkJoin([
      this._organisationService.getPorts(),
      this._vesselsService.getNauticalMiles(),
    ]).subscribe(results => {
        this.ports = results[0].filter(p => p.properties['isPort']);
        this.importedData = results[1];
        this.datatableInit();
    });
    this.selectedNauticalMile = {
      sourceOrganisationId: null,
      destinationOrganisationId: null,
      distance: null
    };
  }

  datatableInit() {
    if (!this.isDataTablesInitialised) {
      this.dtTrigger.next(false);
    } else {
      this.dtElement.dtInstance.then((dtInstance) => {
        dtInstance.destroy();
        this.dtTrigger.next(false);
      });
    }
    this.isDataTablesInitialised = true;
  }

  add() {
    this.selectedNauticalMile = {
      sourceOrganisationId: this.port1,
      destinationOrganisationId: this.port2,
      distance: +this.distance,
    };
    this._vesselsService.addNauticalMile(this.selectedNauticalMile)
      .subscribe(() => {
        this.importedData.push(this.selectedNauticalMile);
        this.datatableInit();
        this._pgMessagesService.publishMessage({
          message: `Distance ${this.distance} Imported`,
          topic: 'New Distance',
          level: MessageLevel.Info
        });
        }, () => {
        this._pgMessagesService.publishMessage({
          message: 'Error happened while importing this distance',
          topic: 'Import Nautical mile',
          level: MessageLevel.Error
        });
      });
  }

  formatPorts(portOrganisationId: string) {
    const port = this.ports.find(p => p.name === portOrganisationId);
    return (port !== undefined) ? port.nameTranslations['en'] : '';
  }
}

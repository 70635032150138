
<div style="display: inline">
  <div class="boxContainer">
    <div class="registration-ui-before" dir="ltr">
      <div style="top: 20px; position: relative; font-size: .8em">
        I.R.<br>IRAN
      </div>
    </div>
    <div class="registration-ui">
      <input type="text"
             size="3"
             maxlength="3"
             [disabled]="disabled"
             [(ngModel)]="_registrationNumber[1]"
             (keyup)="keyup()"
             tabindex="4">
      <input type="text"
             size="1"
             placeholder="ع"
             [disabled]="true"
             maxlength="1"
             value="ع"
             [(ngModel)]="_registrationNumber[3]"
             tabindex="3">
      <input type="text"
             size="2"
             maxlength="2"
             [disabled]="disabled"
             [(ngModel)]="_registrationNumber[0]"
             (keyup)="keyup()"
             tabindex="2">
    </div>
    <div class="registration-ui-after">
      <input type="text"
             size="2"
             maxlength="2"
             [disabled]="disabled"
             [(ngModel)]="_registrationNumber[2]"
             (keyup)="keyup()"
             tabindex="4">
    </div>
  </div>
</div>

import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTablesService } from 'src/app/shared/datatables/data-tables.service';
import { KeycloakService } from 'src/app/authentication/keycloak.service';
import { ActivatedRoute  } from '@angular/router';
import { DateRange } from 'src/app/shared/components';
import { PriceIndex, MarketService } from '../market.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { DateTimeHelpers } from '../../shared/helpers/date-time.helpers';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-market-list',
  templateUrl: './market-list.component.html',

})
export class MarketListComponent implements OnInit {

  @ViewChild(DataTableDirective, {static: true})
  dtElement: DataTableDirective;

  selectedProvider: string;
  dateRange: DateRange;
  prices: PriceIndex[];

  isDataTablesInitialised = false;

  dtOptions: any;
  dtTrigger: Subject<any> = new Subject<any>();

  constructor(private _activateRoute: ActivatedRoute,
              private _pricingService: MarketService,
              private _keyclockService: KeycloakService) { }

  ngOnInit() {
    const component = this;
    const buttons = [ { text: 'Excel', action: () => component.xlsxReport()}, ];
    this.dtOptions = DataTablesService.getOptionsEn(buttons);
    this._activateRoute.paramMap.subscribe(p => {
      this.selectedProvider = p.get('provider');
      this.dateRange = {
        startDate: new Date (+p.get('startDate')),
        endDate: new Date(+p.get('endDate'))
      };
      this._pricingService.getPrices(this.selectedProvider,
        this.dateRange.startDate.getTime(),
        this.dateRange.endDate.getTime()).subscribe(prices => {
        this.prices = prices;
        this.datatableInit();
      });
    });
   }

  formatProductDates(timestamp: number) {
    return DateTimeHelpers.formatLocalDate(timestamp);
  }

  datatableInit() {
    if (!this.isDataTablesInitialised) {
      this.dtTrigger.next(false);
    } else {
      this.dtElement.dtInstance.then((dtInstance) => {
        dtInstance.destroy();
        this.dtTrigger.next(false);
      });
    }
    this.isDataTablesInitialised = true;
  }

  xlsxReport() {
    this._pricingService.getExcelReport(
      this.selectedProvider,
      this.dateRange.startDate.getTime(),
      this.dateRange.endDate.getTime()).subscribe(file => {
        saveAs(file.body, `${DateTimeHelpers.formatLocalDate(this.dateRange.startDate.getTime())}-to-` +
        `${DateTimeHelpers.formatLocalDate(this.dateRange.endDate.getTime())}-reports.xlsx`);
      });
  }
}

<div class="row">
  <div class="col-md-12 col-xs-12">
      <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
          id="tankerList"
          class="table table-sm table-striped table-bordered"
          style="width:100%">
          <thead>
              <tr>
                  <th></th>
                  <th>IMO</th>
                  <th>Name</th>
                  <th>Status</th>
                  <th>Type</th>
                  <th>From</th>
                  <th>From Date</th>
                  <th>To</th>
                  <th>To Date</th>
                  <th>Diff(days)</th>
                  <th>Amount</th>
              </tr>
          </thead>
          <tbody>
              <tr *ngFor="let offHire of offHires">
                  <td>
                      <button id="edit" name="edit" type="submit"
                          class="btn btn-link" (click)="editRecord(offHire)">
                          <i class="fa fa-pencil-square-o"></i>
                      </button> 
                  </td>
                  <td>{{offHire.vesselIMO}}</td>
                  <td>{{vesselFormatter(offHire.vesselIMO)}}</td>
                  <td>{{offHireStatus[offHire.status]}}</td>
                  <td>{{offHireTypes[offHire.offHireType]}}</td>
                  <td>{{organisationFormatter(offHire.from.locationId)}}</td>
                  <td>{{offHire.from.time}}</td>
                  <td>{{organisationFormatter(offHire.to.locationId)}}</td>
                  <td>{{offHire.to.time}}</td>
                  <td>{{offHiresDays(offHire.from.time, offHire.to.time)}}</td>
                  <td>{{offHire.usd}}</td>
              </tr>
          </tbody>
      </table>
  </div>
</div>

import {Component, EventEmitter, Output} from '@angular/core';
import {KeycloakService} from '../../../../authentication/keycloak.service';
import {Router} from '@angular/router';
import { DateRange } from 'src/app/shared/components';
import moment from 'moment';

@Component({
  selector: 'app-vessels-toolbar',
  templateUrl: './vessels-toolbar.component.html'
})
export class VesselsToolbarComponent  {

  dateRange: DateRange = {
    startDate: new Date(),
    endDate: new Date()
  };

  @Output()
  activeFilterChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  searchTextChange: EventEmitter<string> = new EventEmitter<string>();

  _activeVesselsFilter = true;
  get activeVesselsFilter() {
    return this._activeVesselsFilter;
  }

  set activeVesselsFilter(value: boolean) {
    this._activeVesselsFilter = value;
    this.activeFilterChange.emit(this._activeVesselsFilter);
  }

  _searchText = '';

  get searchText() {
    return this._searchText;
  }

  set searchText(value: string) {
    this.searchTextChange.emit(value);
  }

  constructor(private _router: Router,
              private _keycloakService: KeycloakService) {
  }

  addVessel() {
    this._router.navigate(['vessel']).then();
  }

  navigateToInternalAuditPage() {
    this._router.navigate(['vessel/internal-audit']).then();
  }

  addVoyage() {
    this._router.navigate(['vessels/voyage']).then();
  }


  addOffHire() {
    this._router.navigate(['vessels/off-hire']).then();
  }

  navigateToVoyages() {
    this.dateRange.startDate = moment().subtract('year', 1).toDate();
    this.dateRange.endDate = moment().toDate();
    this._router.navigate(['vessels/voyages/'], {
      queryParams: {
        startTime: this.dateRange?.startDate.getTime(),
        endTime: this.dateRange?.endDate.getTime()
      }
    }).then();
  }

  navigateToFleetHistory() {
    this.dateRange.startDate = moment().subtract('year', 1).toDate();
    this.dateRange.endDate = moment().toDate();
    this._router
    .navigate(['vessels/navigation-history/',
      this.dateRange?.startDate.getTime(),
      this.dateRange?.endDate.getTime()]).then();
  }

  navigateToBunker() {
    this.dateRange.startDate = moment().subtract('year', 1).toDate();
    this.dateRange.endDate = moment().toDate();
    this._router
    .navigate(['vessels/bunker/',
      this.dateRange?.startDate.getTime(),
      this.dateRange?.endDate.getTime()]).then();
  }

  navigateToNoonReports() {
    this.dateRange.startDate = moment().subtract('year', 1).toDate();
    this.dateRange.endDate = moment().toDate();
    this._router.navigate(['vessels/noon-reports/'], {
      queryParams: {
        startTime: this.dateRange?.startDate.getTime(),
        endTime: this.dateRange?.endDate.getTime()
      }
    }).then();
  }

  navigateToNoonReportsV202005() {
    this.dateRange.startDate = moment().subtract('year', 1).toDate();
    this.dateRange.endDate = moment().toDate();
    this._router.navigate(['vessels/noon-reports-v202005/'], {
      queryParams: {
        startTime: this.dateRange?.startDate.getTime(),
        endTime: this.dateRange?.endDate.getTime()
      }
    }).then();
  }

  navigateToNoonReportsV202205() {
    this.dateRange.startDate = moment().subtract('year', 1).toDate();
    this.dateRange.endDate = moment().toDate();
    this._router
    .navigate(['vessels/noon-reports-v202205/',
      this.dateRange?.startDate.getTime(),
      this.dateRange?.endDate.getTime()]).then();
  }

  navigateToPortDues() {
    this.dateRange.startDate = moment().subtract('year', 1).toDate();
    this.dateRange.endDate = moment().toDate();
    this._router.navigate(['vessels/reports/'], {
      queryParams: {
        startTime: this.dateRange?.startDate.getTime(),
        endTime: this.dateRange?.endDate.getTime()
      }
    }).then();
  }
}

<div class="row">
    <div class="col-md-6">
        <div class="card">
            <div class="card-body">
                <form class="form-horizontal">
                    <div class="form-group row">
                        <label for="vesselName"
                            class="col-form-label col-md-4 ">Name</label>
                        <div class="col-md-8">
                            <input type="text"
                                name="vesselName"
                                id="vesselName"
                                tabindex="1"
                                class="form-control"
                                [(ngModel)]="name"
                                required>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="lpgPricePayment"
                            class="col-form-label col-md-4 ">IMO</label>
                        <div class="col-md-8">
                            <input type="text"
                                name="lpgPricePayment"
                                id="lpgPricePayment"
                                class="form-control"
                                tabindex="2"
                                [(ngModel)]="imo"
                                required>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="flag"
                            class="col-form-label col-md-4 ">Flag</label>
                        <div class="col-md-8">
                            <app-organisation-selector
                                id="flag"
                                class="pg-component"
                                [(organisation)]="flag"
                                [organisationLanguage]="'en'"
                                name="flag"
                                [organisations]="flags"
                                required>
                            </app-organisation-selector>
                        </div>
                    </div>
                    <div class="form-group pull-right">
                        <label for="newVessel"
                            class="col-form-label col-md-4"></label>
                        <div class="col-md-8">
                            <input type="submit" id="newVessel"
                                class="btn btn-primary" value="Save"
                                (click)="addVessel()">
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

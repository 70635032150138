/* eslint-disable @typescript-eslint/naming-convention */
import {Injectable} from '@angular/core';
import {HttpWrapper} from '../../shared/services';
import {Observable} from 'rxjs';
import {GeoCoordinate} from '../models/GeoCoordinate';
import * as _ from 'lodash';
import * as moment from 'moment';
import {DateRange} from '../../shared/components';
import {map} from 'rxjs/operators';
import {KeycloakService} from '../../authentication/keycloak.service';
import { ActionType } from 'src/app/shared/services/database-action.service';

export interface Document {
  documentType: string;
  documentId: string;
  issueDate: number;
  isDeleted?: boolean;
}

export class STS {
  vesselIMO: string;
  vesselName: string;
  shipVoyageNo: string;
}

export class DataBaseAction {
  userName: string;
  timestamp: number;
  userAction: ActionType;
}

export interface ToolbarItem {
  dateRange: DateRange;
  type: string;
  firstSOF: string;
  secondeSOF: string;
}
export interface DurationSof {
  vesselIMO: string;
  vesselName: string;
  shipVoyageNo: number;
  startTime: number;
  endTime: number;
  portOrganisationId: string;
}


export interface VoyageActions {
  actions: DataBaseAction[];
}

export interface OwnershipOperationProtection {
  ownerId: string;
  technicalOperatorId: string;
  commercialOperatorId: string;
  builderId: string;
  managerId: string;
  protectionIndemnityId: string;
}

export interface DryDock {
  nextDryDock: string;
  lastDryDock: string;
}

export interface DimensionsCapacities {
  GRT: number;
  NRT: number;
  capacity: number;
  draft: number;
  deadWeight: number;
  LOA: number;
  beam: number;
  lightShip: number;
  numberOfTanks: number;
}

export interface CharterPartySpeed {
  economicalLadenSpeed: number,
  maximumBallastSpeed: number
}


export interface VesselSpec {
  vesselType: string;
  yearBuilt: number;
  email: string;
  photoIds: string[];
  shipParticularId: string;
  charterPartySpeed: CharterPartySpeed;
  dimensionsCapacities: DimensionsCapacities;
  ownershipOperationProtection?: OwnershipOperationProtection;
  dryDock?: DryDock;
}


export interface CertificateInfo {
  expirationDate: number;
  issueDate: number;
  fileId: string;
}

export interface Certificate {
  name: string;
  abbr: string;
  info: CertificateInfo;
  history: CertificateInfo[];
}

export interface VesselName {
  name: string;
  flag: string;
  from: number;
}

export interface Vessel {
  IMO: string;
  name: VesselName;
  spec: VesselSpec;
  active: boolean;
  certificates: Certificate[];
  previousNames: VesselName[];
  documents: Document[];
  actions: DataBaseAction[];
}

export interface ProductQuantity {
  product: string;
  grossWeight: number;
  density: number;
}

export interface BillOfLading extends VoyageActions {
  no: string;
  shipperOrganisationId: string;
  consigneeOrganisationId: string;
  agentOrganisationId: string;
  notifyingOrganisationId: string;
  loadingPortOrganisationId: string;
  dischargingPortOrganisationId: string;
  date: number;
  products: ProductQuantity[];
  fileId: string;
  revise: boolean;
  stsLoadings: STS[];
  // stsDischarge: STS[];
  actions: DataBaseAction[];
}

export interface Nor extends VoyageActions {
  timestamp: number;
  norType: string;
  portOrganisationId: string;
  fileId: string;
  actions: DataBaseAction[];
}

export interface Sof extends VoyageActions {
  sofType: string;
  portOrganisationId: string;
  entries: [string, number][];
  fileId: string;
  actions: DataBaseAction[];
}

export interface ProviderMeasurement {
  provider: string;
  providerId: string;
  product: string;
  weight: number;
  primary: boolean;
}

export interface Discharge extends VoyageActions {
  fileId: string;
  portOrganisationId: string;
  date: number;
  consigneeOrganisationId: string;
  sts: STS;
  measurements: ProviderMeasurement[];
  actions: DataBaseAction[];
  billOfLadingNumbers: string[];
}

export interface Comment {
  incidentTimestamp: number;
  content: string;
  author: string;
  authorCommentTimestamp: number;
  authorTimezone: string;
  fileId: string;
}

export interface MiscDocument {
  miscDocumentType: string;
  comment: string;
  fileId: string;
}

export interface Laycan {
  startTimestamp: number;
  endTimestamp: number;
  ETATimestamp: number;
  organisationId: string;
}
export interface NauticalMile {
  sourceOrganisationId: string;
  destinationOrganisationId: string;
  distance: number;
}

export interface BunkeringSof {
  alongside: string;
  hoseConnected: string;
  commencedPumping: string;
  completedPumping: string;
  hoseDisconnected: string;
}

export interface BunkeringSpec {
  viscosity: number;
  density: number;
  water: number;
  sulphur: number;
  flashPoint: number;
}

export enum BunkerType {
  HFO = 'HFO',
  VLSFO = 'VLSFO',
  ULSFO = 'ULSFO',
  MGO = 'MGO',
  LSMGO = 'LSMGO',
}

export interface BunkeringDeliveryNote {
  fileId: string;
  fuelFileIds: string[];
  bdnNumber: string;
  vesselName: string;
  vesselIMO: string;
  date: string;
  sof: BunkeringSof;
  product: string;
  quantity: number;
  priorRob: number;
  isDeleted: boolean;
  supplierOrganisationId: string;
  locationOrganisationId: string;
  spec: BunkeringSpec;
  actions: DataBaseAction[];
}


export interface LoadingPermission {
  fileId: string;
  vesselIMO: string;
  vesselName: string;
  variation: number;
  letterNumber: string;
  jettyNumber: string;
  loadingRate: string;
  laytimeStartDate: number;
  laytimeEndDate: number;
  productNames: string;
  products: ProductQuantity[];
  shipperOrganisationId: string;
  consigneeOrganisationId: string;
  agentOrganisationId: string;
  surveyorOrganisationId: string;
  destinationOrganisationId: string;
  laycan: Laycan;
}

export interface Voyage {
  id: string;
  vesselIMO: string;
  vesselName: string;
  shipVoyageNo: number;
  agentVoyageNo: string;
  charterer: string;
  commitmentNo: string;
  nors: Nor[];
  sofs: Sof[];
  discharges: Discharge[];
  billOfLadings: BillOfLading[];
  completed: boolean;
  comments: Comment[];
  miscDocuments: MiscDocument[];
  actions: DataBaseAction[];
}

export interface VesselType {
  name: string;
  code: string;
  description: string;
}

export interface GenericNoonReportValidationRule {
  rowNumber: number;
  columnNumber: number;
  errorMessage: string;
}

export interface VesselBunker {
  foConsumption: number;
  doConsumption: number;
  robFO: number;
  robDO: number;
  fW: number;
}

export interface NoonReport {
  vesselIMO: string;
  vesselName: string;
  timestamp: number;
  timezoneOffset: number;
  location: string;
  geoCoordinate: GeoCoordinate;
  distanceSinceLastReport: number;
  distanceTotal: number;
  distanceToGo: number;
  voyageFrom: string;
  voyageTo: string;
  etaTime: string;
  etaDate: string;
  hoursAtSeaSinceLastReport: number;
  hoursAtSeaTotal: number;
  vesselSpeedSinceLastReport: number;
  vesselSpeedAverage: number;
  conditionLB: string;
  seaCondition: string;
  bunkerStatus: VesselBunker;
  comment: string;
}

export interface VoyageLength {
  voyage: Voyage;
  days: number;
}

export interface Demurrage {
  voyageId: string;
  vesselName: string;
  vesselIMO: string;
  shipVoyageNo: number;
  portOrganisationId: string;
  portName: string;
  portTimeZone: string;
  dischargeNor: number;
  dischargeAllFast: number;
  dischargeHoseDisconnected: number;
  exceededLaytimeHours: number;
  demurrage: number;
}

export interface OffHireBunker {
  bunkerType: string;
  amount: number
}

export interface OffHireItem {
  locationId: string;
  time: string
}

export interface VesselOffHire {
  id: string;
  documentId: string;
  vesselIMO: string;
  status: string;
  offHireType: string;
  from: OffHireItem;
  to: OffHireItem;
  bunker: OffHireBunker[];
  relatedOffHire: string;
  usd: number;
  isDeleted: boolean;
  actions: DataBaseAction[]
}

@Injectable()
export class VesselsService {

  static norCodes = ['D01011', 'D01096', 'L01011', 'L01096'];
  static allFastCodes = ['D01051', 'L01051'];
  static hoseDisconnectCodes = ['D02071', 'D02102', 'D02103', 'D02128', 'D02129', 'D02135', 'D02142', 'L02051',
    'L02078', 'L02079', 'L02091', 'L02092', 'L02102'];

  constructor(private _httpWrapper: HttpWrapper,
              private _keycloakService: KeycloakService) { }

  static calculateLaytime(voyage: Voyage, norSofType: string) {
    const sofs = voyage.sofs.filter(s => s.sofType === norSofType).flatMap(s => s.entries);
    const nors = sofs.filter(s => VesselsService.norCodes.some(c => c === s[0])).map(c => c[1])
      .sort((a, b) => a - b);
    const disconnects = sofs.filter(s => VesselsService.hoseDisconnectCodes.some(c => c === s[0])).map(c => c[1])
      .sort((a, b) => b - a);

    if (nors && nors.length > 0 && disconnects && disconnects.length > 0) {
      return moment(disconnects[0]).diff(moment(nors[0]), 'hours', true);
    } else {
      return null;
    }
  }

  static voyageLengthFromLoadingToDischarge(v: Voyage) {
    const loadings = v.nors.filter(n => n.norType === 'loading')
      .sort((a, b) => a.timestamp - b.timestamp);

    const discharges = v.nors.filter(n => n.norType === 'discharging')
      .sort((a, b) => b.timestamp - a.timestamp);

    if (loadings.length !== 0 && discharges.length !== 0) {
      return moment(discharges[0].timestamp).diff(moment(loadings[0].timestamp), 'days');
    } else if (loadings.length !== 0) {
      return `${moment().diff(moment(loadings[0].timestamp), 'days')} ->`;
    }
  }

  static calculateBerthTime(v: Voyage, sofType: string) {
    const sofs = v.sofs.filter(s => s.sofType === sofType).flatMap(s => s.entries);
    const nors = sofs.filter(s => VesselsService.norCodes.some(c => c === s[0])).map(c => c[1])
      .sort((a, b) => a - b);
    const allFasts = sofs.filter(s => VesselsService.allFastCodes.some(c => c === s[0])).map(c => c[1])
      .sort((a, b) => a - b);

    if (nors && nors.length > 0 && allFasts && allFasts.length > 0) {
      return moment(allFasts[0]).diff(moment(nors[0]), 'hours', true);
    } else {
      return null;
    }
  }

  getVessels() {
    return this._httpWrapper.get<Vessel[]>('vessels');
  }

  getActiveVessels() {
    return this._httpWrapper.get<Vessel[]>('vessels/active');
  }

  getVessel(imo: string) {
    return this._httpWrapper.get<Vessel>(`vessels/vessel/${imo}`);
  }

  getVesselTypes() {
    return this._httpWrapper.get<VesselType[]>('vessels/types');
  }

  add(data: Vessel) {
    return this._httpWrapper.post('vessels', data);
  }

  addLoadingPermission(data: LoadingPermission) {
    return this._httpWrapper.post('vessels/loading-permission', data);
  }

  addNauticalMile(data: NauticalMile) {
    return this._httpWrapper.post('distance/nauticalMile', data);
  }

  getNauticalMiles(): Observable<NauticalMile[]> {
    return this._httpWrapper.get('distance/nauticalMile');
  }

  upsertBunkering(data: BunkeringDeliveryNote) {
    return this._httpWrapper.post('vessels/bunker', data);
  }

  getAllLoadingPermission(): Observable<LoadingPermission[]> {
    return this._httpWrapper.get<LoadingPermission[]>(`vessels/loading-permission`);
  }

  getAllBunkeringDeliveryNote(): Observable<BunkeringDeliveryNote[]> {
    return this._httpWrapper.get<BunkeringDeliveryNote[]>(`vessels/bunker`);
  }

  getOneBunkeringDeliveryNote(vesslIMO: string, product: string, timestamp: string): Observable<BunkeringDeliveryNote> {
    return this._httpWrapper.get<BunkeringDeliveryNote>(`vessels/bunker/${vesslIMO}/${product}/${timestamp}`);
  }

  updateVessel(data: Vessel): Observable<Vessel> {
    return this._httpWrapper.put('vessels', data);
  }

  updateBunkeringDeliveryNote(data: BunkeringDeliveryNote) {
    return this._httpWrapper.put('vessles/bunker', data);
  }

  getVoyagesBetween(dateRange: DateRange) {
    return this._httpWrapper.get<Voyage[]>(`vessels/voyages/${dateRange.startDate.getTime()}/${dateRange.endDate.getTime()}`);
  }

  getActiveVesselVoyagesBetween(dateRange: DateRange) {
    return this._httpWrapper.get<Voyage[]>(`vessels/voyages/active/${dateRange.startDate.getTime()}/${dateRange.endDate.getTime()}`);
  }

  getOnGoingVoyages() {
    return this._httpWrapper.get<Voyage[]>(`vessels/voyages/ongoing`);
  }

  getLastVoyages() {
    return this._httpWrapper.get<Voyage[]>(`vessels/voyages/last`);
  }

  getLastActiveVesselVoyages() {
    return this._httpWrapper.get<Voyage[]>(`vessels/voyages/active/last`);
  }

  getALLVesselOffHire(imo: string) {
    return this._httpWrapper.get<VesselOffHire[]>(`vessels/off-hire/vessel/${imo}`);
  }

  addOffHire(offHire: VesselOffHire) {
    return this._httpWrapper.post('vessels/off-hire', offHire);
  }

  getOffHires() {
    return this._httpWrapper.get<VesselOffHire[]>(`vessels/off-hire/all`);
  }

  getOneOffHire(id: string) {
    return this._httpWrapper.get<VesselOffHire>(`vessels/off-hire/${id}`);
  }

  mapToVoyageLength(allVoyages: Voyage[]): VoyageLength[] {
    const lookups = _.groupBy(allVoyages, (item) => item.vesselName);
    for (const l of Object.keys(lookups)) {
      lookups[l] = lookups[l].sort((a, b) => a.shipVoyageNo - b.shipVoyageNo);
    }
    return allVoyages.map(v1 => {
      const voyages = lookups[v1.vesselName];
      const index = voyages.indexOf(v1);
      let numberOfDays: number = null;
      if ((index + 1) !== voyages.length) {
        const nextVoyage = voyages[index + 1];
        const firstNorDate = v1.nors.filter(n => n.norType === 'loading')
          .sort((a, b) => b.timestamp - a.timestamp);
        const secondNorDate = nextVoyage.nors.filter(n => n.norType === 'loading')
          .sort((a, b) => b.timestamp - a.timestamp);

        if (firstNorDate.length > 0 && secondNorDate.length > 0) {
          numberOfDays = moment(secondNorDate[0].timestamp).diff(moment(firstNorDate[0].timestamp), 'days');
        }
      }

      return {
        voyage: v1,
        days: numberOfDays
      };
    });
  }

  getVoyagesBetweenWithLengths(dateRange: DateRange): Observable<VoyageLength[]> {
    return this._httpWrapper.get<Voyage[]>(`vessels/voyages/${dateRange.startDate.getTime()}/${dateRange.endDate.getTime()}`)
      .pipe(map(this.mapToVoyageLength));
  }

  getActiveVoyagesBetweenWithLengths(dateRange: DateRange): Observable<VoyageLength[]> {
    return this._httpWrapper.get<Voyage[]>(`vessels/voyages/active/${dateRange.startDate.getTime()}/${dateRange.endDate.getTime()}`)
      .pipe(map(this.mapToVoyageLength));
  }

  getVoyagesByIMO(IMO: string) {
    return this._httpWrapper.get<Voyage[]>(`vessels/voyage/getByImo/${IMO}`);
  }

  getVoyage(voyageId: string) {
    return this._httpWrapper.get<Voyage>(`vessels/voyage/${voyageId}`);
  }

  getSOFDuration(startDate: number, endDate: number, sofType: string, startItem: string, endItem: string) {
    return this._httpWrapper.get<DurationSof[]>
    (`vessels/voyage/get-sof-duration/${startDate}/${endDate}/${sofType}/${startItem}/${endItem}`);
  }

  getSOFDurationExcel(startDate: number, endDate: number, sofType: string, startItem: string, endItem: string) {
    return this._httpWrapper.download(`vessels/voyage/get-sof-duration/excel/${startDate}/${endDate}/${sofType}/${startItem}/${endItem}`);
  }

  findPreviousVoyageId(voyageId: string) {
    return this._httpWrapper.get<string>(`vessels/voyage/previous/${voyageId}`);
  }

  findNextVoyageId(voyageId: string) {
    return this._httpWrapper.get<string>(`vessels/voyage/next/${voyageId}`);
  }

  addVoyage(voyage: Voyage) {
    return this._httpWrapper.post<Voyage, Voyage>('vessels/voyages', voyage);
  }

  updateVoyage(voyage: Voyage) {
    return this._httpWrapper.put<Voyage, Voyage>('vessels/voyages', voyage);
  }

  importNoonReport(date: string, reportType: string, fileList: FileList): Observable<GenericNoonReportValidationRule[]> {
    return this._httpWrapper.postMultiPart(`vessels/noon-report/${date}`, this.toFormData(reportType, fileList));
  }

  getNoonReports(startDate: number, endDate: number): Promise<NoonReport[]> {
    return this._httpWrapper.get<NoonReport[]>(`vessels/noon-reports/${startDate}/${endDate}`).toPromise();
  }

  getNoonReportsBetween(dr: DateRange): Observable<NoonReport[]> {
    return this._httpWrapper.get<NoonReport[]>(`vessels/noon-reports/${dr.startDate.getTime()}/${dr.endDate.getTime()}`);
  }

  totalVoyageLoading(voyage: Voyage) {
    if (voyage.billOfLadings) {
      const result = voyage.billOfLadings.filter(bill => bill.revise !== true)
      .map(b => b.products.map(p => p.grossWeight).reduce((x, y) => x + y , 0))
        .reduce((m, n) => m + n , 0);
      return {
        vesselIMO: voyage.vesselIMO,
        total: Math.round(result * 1000) / 1000
      };
    }
  }

  portDischarge(voyage: Voyage) {
    if (voyage.billOfLadings.length > 0) {
      return voyage.billOfLadings.map(b => (
        {
          vesselIMO: voyage.vesselIMO,
          voyageNo: voyage.shipVoyageNo,
          loadingPortOrganisationId: b.loadingPortOrganisationId,
          dischargingPortOrganisationId: b.dischargingPortOrganisationId,
          total: Math.round(_.sumBy(b.products, i => i.grossWeight)),
        }
      ));
    }
  }

  findVessel(imo: string , vessels: Vessel[]) {
    const vessel = vessels.find(v => v.IMO === imo);
    if (vessel) {
      return vessel.name.name;
    } else {
      return 'Vessel not found';
    }
  }

  reportVesselsSummary(data: any[]) {
    const grouped = _.groupBy(data, (item) => item.vesselIMO);
    return _(grouped).map((items, key) => (
      {
        vesselIMO: key,
        totalWeight: Math.round(_.sumBy(items, i => i.total)),
        count: _.size(items)
      }
    )).value();
  }

  reportPortSummary(data: any[]) {
    const grouped = _.groupBy(data, (item) => item.dischargingPortOrganisationId);
    return _(grouped).map((items, key) => (
      {
        dischargingPortOrganisationId: key,
        totalWeight: Math.round(_.sumBy(items, i => i.total)),
        count: _.size(items)
      }
    )).value();
  }

  reportCountrySummary(data: any[]) {
    const grouped = _.groupBy(data, (item) => item.country);
    return _(grouped).map((items, key) => ({
      country: key,
      totalWeight: Math.round(_.sumBy(items, i => i.dischargeWeight)),
      count: _.size(items)
    })).value();
  }

  reportPortUse(voyage: Voyage) {
    return voyage.sofs.map(s => {
      const portDues = [];
      const allFasts =  s.entries.filter(e =>  e[0] === 'L01051' || e[0] === 'D01051')
        .sort((l1, l2) => l1[1] - l2[1]);
      const clearJetties = s.entries.filter(e => e[0] === 'D03016' || e[0] === 'L03016')
        .sort((l1, l2) => l1[1] - l2[1]);
      while (allFasts.length > 0 && clearJetties.length > 0) {
        portDues.push(moment(clearJetties.pop()[1]).diff(moment(allFasts.pop()[1]), 'hours', true));
      }
      const nort = s.entries.filter(e => e[0] === 'L01011' || e[0] === 'D01011')
        .sort((e1, e2) => e1[1] - e2[1])[0];
      const hoseDisconnect = s.entries.filter(e => e[0] === 'L02051' || e[0] === 'D02051')
        .sort((e1, e2) => e2[1] - e1[1])[0];
      const layTime = (nort !== undefined && hoseDisconnect !== undefined) ?
        Math.round((moment(hoseDisconnect[1]).diff(moment(nort[1]), 'hours', true)) * 100) / 100  : null;
      return {
        vesselName: voyage.vesselName,
        voyageNo: voyage.shipVoyageNo,
        vesselIMO: voyage.vesselIMO,
        sofType: s.sofType,
        portId: s.portOrganisationId,
        portDues: portDues.length > 0 ? Math.round(_.sumBy(portDues) * 100) / 100 : 'N/A',
        layTimePerPort: layTime ? layTime : 'N/A'
      };
    });
  }

  isVerified(voyageActions: VoyageActions) {
    if (voyageActions.actions !== undefined) {
      return voyageActions.actions.sort((t1, t2) => t2.timestamp - t1.timestamp)[0].userAction === ActionType.Verify;
    } else {
      return false;
    }
  }

  getLastVoyagePerImo(imos: string[]) {
    return this._httpWrapper.post<Voyage[], string[]>('vessels/voyages/last', imos).toPromise();
  }

  getDemurrage(dateRange: DateRange, dailyRate: number, allowedLaytime) {
    return this._httpWrapper.get<Demurrage[]>(
      `voyages/demurrage/${dateRange.startDate.getTime()}/${dateRange.endDate.getTime()}/${dailyRate}/${allowedLaytime}`);
  }

  downloadDemurrageExcel(dateRange: DateRange, dailyRate: number, allowedLaytime) {
    return this._httpWrapper.download(
      `voyages/demurrage/download/${dateRange.startDate.getTime()}/${dateRange.endDate.getTime()}/${dailyRate}/${allowedLaytime}`);
  }

  downloadReportTemplate(imo: string) {
    return this._httpWrapper.download(`vessels/noon-reports/template/${imo}`);
  }

  downloadVoyageActivityExcel(startTimestamp: number, endTimestamp: number) {
    return this._httpWrapper.download(`vessels/voyages/excel/${startTimestamp}/${endTimestamp}`);
  }

  countPorts(v: Voyage, sofType: string) {
    if (v.sofs && v.sofs.filter(n => n.sofType === sofType).length > 0) {
      return _.chain(v.sofs)
        .filter(n => n.sofType === sofType)
        .map(n => n.portOrganisationId)
        .groupBy(n => n)
        .map((items, key) => key)
        .value().length;
    } else {
      return null;
    }
  }
  verify(item: VoyageActions, partName: string) {
    if (confirm(`Are you sure you want to verify this ${partName}? Once verified this action cannot be undone.`)) {
      if (item.actions === undefined) {
        item.actions = [];
      }
      const action: DataBaseAction = {
        userName: this._keycloakService.getUser().username,
        timestamp: new Date().getTime(),
        userAction: ActionType.Verify
      };
      item.actions.push(action);
      return true;
    }
    return false;
  }

  removeVerification(item: VoyageActions, partName: string) {
    if (confirm(`Are you sure you want to remove this ${partName} verification?`)) {
      const action: DataBaseAction = {
        userName: this._keycloakService.getUser().username,
        timestamp: new Date().getTime(),
        userAction: ActionType.AdminRevert
      };
      item.actions.push(action);
      return true;
    }
    return false;
  }

  private toFormData(docType: string, fileList: FileList) {
    const formData = new FormData();
    formData.append('report-type', docType);
    formData.append('file', fileList.item(0));
    return formData;
  }

  calculateVesselAge(v: Vessel) {
    return moment().diff(moment(new Date(v.spec.yearBuilt, 1, 1)), 'years');
  }

}

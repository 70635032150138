import { Pipe, PipeTransform } from '@angular/core';
import { DateTimeHelpers } from '../helpers/date-time.helpers';

@Pipe({
  name: 'pgDate'
})
export class PgDatePipe implements PipeTransform {

  transform(value: any): any {
    if (typeof value === 'number') {
      return DateTimeHelpers.formatLocalDate(value);
    } else if (typeof value === 'object') {
      return DateTimeHelpers.formatLocalDate(value.getTime());
    }
    return null;
  }

}

import {Component, EventEmitter, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DateRange} from 'src/app/shared/components';
import {Vessel, VesselsService, VoyageLength} from 'src/app/transportation/vessels/vessels.service';
import { Subject } from 'rxjs';
import { DataTablesService } from 'src/app/shared/datatables/data-tables.service';
import { DataTableDirective } from 'angular-datatables';
import { Organisation, OrganisationService } from 'src/app/organisation/organisation.service';
import {
  PgDateRangeTitleComponent,
  PgDateRangeTitleEnum, PgTitleComponent
} from '../../../shared/components/title-bar/title-bar.component';

@Component({
  selector: 'app-reports',
  templateUrl: './voyage-reports.component.html',
})
export class VoyageReportsComponent implements OnInit, PgTitleComponent, PgDateRangeTitleComponent {
  @ViewChild(DataTableDirective, {static: true})
  dtElement: DataTableDirective;
  title = new EventEmitter<string>();
  dateRangeSubTitle = new EventEmitter<[DateRange, PgDateRangeTitleEnum]>();

  dateRange: DateRange;
  vessels: Vessel[];
  voyages: VoyageLength[];
  ports: Organisation[];
  portDues: any[];

  isDataTablesInitialised = false;
  dtOptions1: any;
  dtOptions3: any;
  dtTrigger: Subject<any> = new Subject<any>();

  constructor(private _activateRoute: ActivatedRoute,
              private _vesselsService: VesselsService,
              private _organisationService: OrganisationService) { }

  async ngOnInit() {
    this.dtOptions1 = DataTablesService.getOptionsEn();
    this.dtOptions3 = DataTablesService.getOptionsEn();

    this._activateRoute.queryParams.subscribe(p => {
      const startDate = +p['startTime'];
      const endDate = +p['endTime'];
      this.dateRange = {
        startDate: new Date(startDate),
        endDate: new Date(endDate)
      };
    });
    this.title.emit('Voyages report');
    this.dateRangeSubTitle.emit([this.dateRange, PgDateRangeTitleEnum.defaultTitle]);

    this.voyages = await this._vesselsService.getVoyagesBetweenWithLengths(this.dateRange).toPromise();
    this.vessels = await this._vesselsService.getVessels().toPromise();
    this.ports = await this._organisationService.get().toPromise();
    this.dataAggregation();
  }

  datatableInit() {
    if (!this.isDataTablesInitialised) {
      this.dtTrigger.next(false);
    } else {
      this.dtElement.dtInstance.then((dtInstance) => {
        dtInstance.destroy();
        this.dtTrigger.next(false);
      });
    }
    this.isDataTablesInitialised = true;
  }

  findPort(portId: string) {
    return this.ports.find(o => portId === o.name).nameTranslations['en'];
  }

  dataAggregation() {
    this.portDues = this.voyages.flatMap(v => this._vesselsService.reportPortUse(v.voyage));
    this.datatableInit();
  }

}

import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { TrucksComponent } from './trucks/trucks-fleet-status/trucks.component';
// import { MapComponent } from './trucks/map/map.component';
import { TrucksService } from './trucks/trucks.service';
import { TrucksListComponent } from './trucks/trucks-list/trucks-list.component';
import { TrucksReportComponent } from './trucks/trucks-report/trucks-report.component';
import { DataTablesModule } from 'angular-datatables';
import { TruckDataEntryComponent } from './trucks/data-entry/truck-data-entry/truck-data-entry.component';
import { NumberPlateComponent } from './components/number-plate/number-plate.component';
import { VesselsComponent } from './vessels/vessels/vessels.component';
import { VesselsService } from './vessels/vessels.service';
import { VoyagesComponent } from './vessels/voyages/voyages.component';
import { VoyageComponent } from './vessels/voyage/voyage.component';
import { DocumentsModule } from '../documents/documents.module';
import { BlComponent } from './vessels/voyage/bl/bl.component';
import { NorComponent } from './vessels/voyage/nor/nor.component';
import { UnLocationsService } from './vessels/un-locations.service';
import { SofComponent } from './vessels/voyage/sof/sof.component';
import { CrewChangeServices } from './vessels/crew-change/crew-change.service';
import { DischargeComponent } from './vessels/voyage/discharge/discharge.component';

import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ImportNoonReportComponent } from './vessels/noon-reports/import-noon-report/import-noon-report.component';
import { NoonReportsComponent } from './vessels/noon-reports/noon-reports/noon-reports.component';
import { DateTimeTextComponent } from './components/date-time-text/date-time-text.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { FleetStatusComponent } from './vessels/fleet-status/fleet-status.component';
import { CommentComponent } from 'src/app/transportation/vessels/voyage/comment/comment.component';
import { VoyageReportsComponent } from './vessels/reports/voyage-reports.component';
import { MiscDocumentComponent } from './vessels/voyage/misc-document/misc-document.component';
import { GpsDataEntryComponent } from './trucks/data-entry/gps-data-entry/gps-data-entry.component';
import { GpsListComponent } from './trucks/gps-list/gps-list.component';
import { TankerDataEntryComponent } from './trucks/data-entry/tanker-data-entry/tanker-data-entry.component';
import { TankerListComponent } from './trucks/tanker-list/tanker-list.component';
import { SofTemplateComponent } from './vessels/sof-template/sof-template.component';
import { SofEntryComponent } from './vessels/sof-templates/sof-entry/sof-entry.component';
import { SofTemplateService } from './vessels/sof-templates/sof-template.service';
import { SofTemplatesComponent } from './vessels/sof-templates/sof-templates.component';
import { SelectVoyageReportComponent } from './vessels/select-report/select-voyage-report.component';
import { BunkerDataEntryComponent } from './vessels/bunker/bunker-data-entry.component';
import { BunkerListComponent } from './vessels/bunker/bunker-list/bunker-list.component';
import { NauticalMileComponent } from './vessels/nautical-mile/nautical-mile.component';
import { FleetStatusToolbarComponent } from './vessels/fleet-status/fleet-status-toolbar/fleet-status-toolbar.component';
import { BunkerStatusComponent } from './vessels/bunker-status/bunker-status.component';
import { FleetNavigationHistoryComponent } from './vessels/fleet-navigation-history/fleet-navigation-history.component';
import { VoyagePlannerComponent } from './vessels/voyage-planner/voyage-planner.component';
import { VesselManagementCostComponent } from './vessels/vessel-management-cost/vessel-management-cost.component';
import { TrackerMonitorListComponent } from './trucks/tracker-monitor-list/tracker-monitor-list.component';
import { MapToolbarComponent } from './trucks/map-toolbar/map-toolbar.component';
import { VoyageToolbarComponent } from './vessels/voyage/voyage-toolbar/voyage-toolbar.component';
import { NewVoyageComponent } from './vessels/new-voyage/new-voyage.component';
import { SeaSharkStatusComponent } from './vessels/sea-shark-status/sea-shark.component';
import { NoonReportsV202005Component } from './vessels/noon-reports/noon-reports-v202005/noon-reports-v202005.component';
import { NoonReportsV202205Component } from './vessels/noon-reports/noon-reports-v202205/noon-reports-v202205.component';
import { AuthenticationModule } from '../authentication/authentication.module';
import { CertificateComponent } from './vessels/certificate/certificate.component';
import { CrewChangeComponent } from './vessels/crew-change/crew-change-data-entry/crew-change.component';
import { CrewChangeListComponent } from './vessels/crew-change/crew-changes/crew-changes.component';
import { CrewComponent } from './vessels/crew-change/crew/crew.component';
import { OpenStreetComponent } from './openstreet/open-street.component';
import { FuelAnalysisComponent } from './vessels/fuel-analysis/fuel-analysis.component';
import { VesselsReportToolbarComponent } from './vessels/vessels-report-toolbar/vessels-report-toolbar.component';
import { LoadingTimeComponent } from './vessels/loading-time/loading-time.component';
import { LoadingTimeToolbarComponent } from './vessels/loading-time/loading-time-toolbar/loading-time-toolbar.component';
import { WeatherConditionPipe } from './vessels/noon-reports/noon-reports-v202205/weather-condition.pipe';
import { OrganisationModule } from '../organisation/organisation.module';
import { VesselDataEntryComponent } from './vessels/vessel/vessel-data-entry/vessel-data-entry.component';
import { VesselToolbarComponent } from './vessels/vessel/vessel-toolbar/vessel-toolbar.component';
import { VesselSpecComponent } from './vessels/vessel/vessel-spec/vessel-spec.component';
import { VesselInternalAuditComponent } from './vessels/vessel/vessel-internal-audit/vessel-internal-audit.component';
import { VesselInternalAuditsComponent } from './vessels/vessel/vessel-internal-audits/vessel-internal-audits.component';
import { NewVesselComponent } from './vessels/vessel/new-vessel/new-vessel.component';
import { VesselInformationComponent } from './vessels/vessel/vessel-information/vessel-information.component';
import { FleetPersonnelManagementComponent } from './vessels/crew-change/fleet-personnel/fleet-personnel-management.component';
import { FleetPersonnelToolbarComponent } from './vessels/crew-change/fleet-personnel/fleet-personnel-toolbar/fleet-personnel-toolbar.component';
import { OnboardsComponent } from './vessels/crew-change/onboards/onboards.component';
import { BunkerListToolbarComponent } from './vessels/bunker/bunker-list-toolbar/bunker-list-toolbar.component';
import { VesselsToolbarComponent } from './vessels/vessels/vessels-toolbar/vessels-toolbar.component';
import { AuditsReportsService } from './vessels/audits-reports.service';
import { CrewAppraisalComponent } from './vessels/crew-change/appraisal/crew-appraisal.component';
import { CrewAssignmentsComponent } from './vessels/crew-change/crew-assignments/crew-assignments.component';
import { CrewAppraisalsComponent } from './vessels/crew-change/appraisals/crew-appraisals.component';
import { CrewContractComponent } from './vessels/crew-change/crew-change-data-entry/contract/crew-contract.component';
import { CrewChangeToolbarComponent } from './vessels/crew-change/crew-change-data-entry/crew-change-toolbar-component/crew-change-toolbar.component';
import { CrewChangePlansComponent } from './vessels/crew-change/crew-change-data-entry/crew-change-plans/crew-change-plans.component';
import { CrewProfileComponent } from './vessels/crew-change/crew/crew-profile/crew-profile.component';
import { CrewProfileToolbarComponent } from './vessels/crew-change/crew/crew-profile/crew-profile-toolbar/crew-profile-toolbar.component';
import { ChartererComponent } from './vessels/voyage/charterer/charterer.component';
import { VesselOffHireComponent } from './vessels/vessel-off-hire/vessel-off-hire.component';
import { VesselsOffHiresComponent } from './vessels/vessel-off-hire/vessels-off-hires/vessels-off-hires.component';
import { FleetSummeryReportComponent } from './vessels/fleet-status/fleet-summery-report/fleet-summery-report.component';


@NgModule({
  imports: [
    SharedModule,
    AuthenticationModule,
    DocumentsModule,
    DataTablesModule,
    PdfViewerModule,
    LeafletModule,
    OrganisationModule,
    ],
  exports: [
    TrucksComponent,
    TrucksListComponent,
    TrucksReportComponent,
    BlComponent,
    NorComponent],
  declarations: [
    TrucksComponent,
    // MapComponent,
    TrucksListComponent,
    TrucksReportComponent,
    TruckDataEntryComponent,
    NumberPlateComponent,
    VesselsComponent,
    BlComponent,
    NorComponent,
    VoyagesComponent,
    VoyageComponent,
    SofComponent,
    DischargeComponent,
    ImportNoonReportComponent,
    NoonReportsComponent,
    DateTimeTextComponent,
    FleetStatusComponent,
    CommentComponent,
    VoyageReportsComponent,
    MiscDocumentComponent,
    GpsDataEntryComponent,
    GpsListComponent,
    SofTemplateComponent,
    SofTemplatesComponent,
    SofEntryComponent,
    MiscDocumentComponent,
    TankerDataEntryComponent,
    TankerListComponent,
    SelectVoyageReportComponent,
    NauticalMileComponent,
    BunkerDataEntryComponent,
    BunkerListComponent,
    FleetStatusToolbarComponent,
    BunkerStatusComponent,
    FleetNavigationHistoryComponent,
    VoyagePlannerComponent,
    VesselManagementCostComponent,
    TrackerMonitorListComponent,
    MapToolbarComponent,
    VoyageToolbarComponent,
    NewVoyageComponent,
    SeaSharkStatusComponent,
    NoonReportsV202005Component,
    NoonReportsV202205Component,
    CertificateComponent,
    CrewChangeComponent,
    CrewChangeListComponent,
    CrewComponent,
    FleetPersonnelManagementComponent,
    OpenStreetComponent,
    FuelAnalysisComponent,
    VesselsReportToolbarComponent,
    LoadingTimeComponent,
    LoadingTimeToolbarComponent,
    WeatherConditionPipe,
    VesselDataEntryComponent,
    VesselToolbarComponent,
    VesselSpecComponent,
    VesselInternalAuditComponent,
    VesselInternalAuditsComponent,
    NewVesselComponent,
    VesselInformationComponent,
    FleetPersonnelToolbarComponent,
    OnboardsComponent,
    BunkerListToolbarComponent,
    VesselsToolbarComponent,
    CrewAppraisalComponent,
    CrewAssignmentsComponent,
    CrewAppraisalsComponent,
    CrewContractComponent,
    CrewChangeToolbarComponent,
    CrewChangePlansComponent,
    CrewProfileComponent,
    CrewProfileToolbarComponent,
    ChartererComponent,
    VesselOffHireComponent,
    VesselsOffHiresComponent,
    FleetSummeryReportComponent],

  providers: [
    TrucksService,
    VesselsService,
    CrewChangeServices,
    SofTemplateService,
    UnLocationsService,
    AuditsReportsService
  ]
})
export class TransportationModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentDirective } from './document.directive';
import { AuthenticationModule } from '../authentication/authentication.module';


@NgModule({
  imports: [ CommonModule, AuthenticationModule ],
  exports: [ DocumentDirective ],
  declarations: [ DocumentDirective ]
})
export class DocumentsModule { }

<div class="btn-group" role="toolbar">
  <div class="btn-group me-1" aria-label="Button group with nested dropdown">
    <button class="btn btn-outline-secondary btn-light dropdown-toggle "
      type="button" id="reportButtonGroup" data-bs-toggle="dropdown"
      aria-expanded="false">
      Reports
    </button>
    <ul class="dropdown-menu" aria-labelledby="reportButtonGroup">
      <li><a class="dropdown-item"
          (click)="navigateToVoyages()">Voyages</a></li>
      <li><a class="dropdown-item" routerLink="/vessels/status">Fleet
          Status</a></li>
      <li><a class="dropdown-item"
          routerLink="vessels/fleet-personnel-management">Fleet
          Personnel</a></li>
      <li><a class="dropdown-item" (click)="navigateToFleetHistory()">Navigation
          history</a></li>
      <li><a class="dropdown-item" (click)="navigateToBunker()">Bunker</a></li>
      <li><a class="dropdown-item" (click)="navigateToNoonReports()">Noon
          Reports</a></li>
      <li><a class="dropdown-item" (click)="navigateToNoonReportsV202005()">Noon
          Reports V202005</a></li>
      <li><a class="dropdown-item" (click)="navigateToNoonReportsV202205()">Noon
          Reports V202205</a></li>
      <li><a class="dropdown-item" routerLink="/vessels/loading-time">Loading
          Time</a></li>
      <li><a class="dropdown-item"
          routerLink="/vessels/voyage/bunker/bunker-list">Bunkering</a></li>
      <li><a class="dropdown-item"
          routerLink="/vessels/noon-reports/analysis">Report analysis</a></li>
      <!-- <li><a class="dropdown-item" routerLink="/vessels/demurrage">Demurrage calculator</a></li> -->
      <!-- <li><a class="dropdown-item"  (click)="navigateToPortDues()" *appHideIfNotInRole="['vessels-viewer']">Port Dues Report</a></li> -->
    </ul>
  </div>
  <div class="btn-group me-1" aria-label="Button group with nested dropdown"
    *appHideIfNotInRole="['vessels-editor', 'vessels-audit-editor', 'vessels-sof-template-editor', 'distances-viewer', 'vessels-noon-reports-editor']">
    <button class="btn btn-outline-secondary btn-light dropdown-toggle "
      type="button" id="addButtonGroup" data-bs-toggle="dropdown"
      aria-expanded="false">
      Add
    </button>
    <ul class="dropdown-menu" aria-labelledby="addButtonGroup">
      <li><a class="dropdown-item" *appHideIfNotInRole="['vessels-editor']"
          (click)="addVessel()">Vessel</a></li>
      <li><a class="dropdown-item" *appHideIfNotInRole="['vessels-voyages-editor']"
          (click)="addVoyage()">Voyage</a></li>
      <li><a class="dropdown-item" *appHideIfNotInRole="['vessels-off-hire-editor']"
          (click)="addOffHire()">Off Hire</a></li>
      <li><a class="dropdown-item" *appHideIfNotInRole="['distances-viewer']"
          routerLink="/vessels/nautical-mile">Nautical mile</a></li>
      <li><a class="dropdown-item"
          *appHideIfNotInRole="['vessels-sof-template-editor']"
          routerLink="/vessels/sof-templates">Sof Templates</a></li>
      <li><a class="dropdown-item"
          *appHideIfNotInRole="['vessels-noon-reports-editor']"
          routerLink="/vessels/noon-reports/import-noon-report">Import Noon
          Reports</a></li>
      <li><a class="dropdown-item"
          *appHideIfNotInRole="['vessels-audit-editor']"
          (click)="navigateToInternalAuditPage()">Audit</a></li>
    </ul>
  </div>
  <div class="btn-group">
    <button class="btn btn-outline-secondary btn-light dropdown-toggle"
      type="button" id="filterButtonGroup" data-bs-toggle="dropdown"
      aria-expanded="false">
      Filter
    </button>
    <ul class="dropdown-menu" aria-labelledby="filterButtonGroup">
      <li><a class="dropdown-item"
          (click)="this.activeVesselsFilter = true">Active vessels</a></li>
      <li><a class="dropdown-item"
          (click)="this.activeVesselsFilter = false">All vessels</a></li>
      <li><a class="dropdown-item"><input type="text" class="form-control"
            placeholder="Search" [(ngModel)]="searchText"></a></li>
    </ul>
  </div>
</div>
import { Routes} from '@angular/router';
import { UsersListComponent } from './list/users-list.component';
import { UserProfileDataEntryComponent } from './data-entry/user-profile-data-entry.component';
import { UserProfileComponent } from './user-profile/user-profile.component';


export const securityRoutes: Routes = [
  { path: 'security/digital-id/:portalUserName', component: UserProfileDataEntryComponent },
  { path: 'security/digital-ids', component: UsersListComponent },
  { path: 'profile', component: UserProfileComponent }

];

<div class="row">
  <div class="col-md-6 ">
    <div class="card card-body">
      <form class="form-horizontal" #NauticalMile="ngForm">
        <div class="form-group row">
          <label for="Port1"
            class="col-form-label col-md-4 ">Port 1</label>
          <div class="col-md-8">
            <select class="form-control"
              name="Port1"
              id="Port1"
              tabindex="1"
              [(ngModel)]="port1"
              required>
              <option *ngFor="let por of ports" [ngValue]="por.name">
                {{por.nameTranslations['en']}}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group row">
          <label for="Port2"
            class="control-label col-md-4 ">Port 2</label>
          <div class="col-md-8">
            <select class="form-control"
              name="Port2"
              id="Port2"
              tabindex="2"
              [(ngModel)]="port2"
              required>
              <option *ngFor="let por2 of ports" value="{{por2.name}}">
                {{por2.nameTranslations['en']}}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group row">
          <label for="distance" class="control-label col-md-4 ">Distance</label>
          <div class="col-md-8">
            <input type="text"
              class="form-control"
              name="distance"
              id="distance"
              tabindex="3"
              [(ngModel)]="distance">
          </div>
        </div>
        <div>
          <div class="col-md-4 "></div>
          <button type="submit"
            class="btn btn-primary"
            (click)="add()"
            [disabled]="!NauticalMile.form.valid"
            tabindex="4">Save
          </button>
        </div>
      </form>
    </div>
  </div>
  <div class="col-md-6">
    <table datatable
      [dtOptions]="dtOptions"
      [dtTrigger]="dtTrigger"
      class="table table-sm table-striped table-bordered">
      <thead>
        <tr>
          <td>Port1</td>
          <td>Port2</td>
          <td>distance</td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let dt of importedData; let i=index;">
          <td>{{formatPorts(dt.sourceOrganisationId)}}</td>
          <td>{{formatPorts(dt.destinationOrganisationId)}}</td>
          <td>{{dt.distance}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

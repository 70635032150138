<div class="row">
  <div class="col-md-12">
    <div class="card shadow-sm ">
      <div class="card-header with-border">Upload Photo</div>
      <div class="card-body" *ngIf="vessel">
        <app-slideshow *ngIf="vessel.spec.photoIds"
          [fileId]="vessel.spec.photoIds" (rmFileId)="deleteMode($event)"
          [photoName]="vessel.name.name" [role]="role"></app-slideshow>
        <div class="form-group row col-md-12"
          *appHideIfNotInRole="['vessels-editor']">
          <label for="uploader" class="col-form-label col-md-1">File</label>
          <div class="col-md-4">
            <app-chunk-file-uploader
              [accept]="'image/*'"
              id="uploader"
              name="uploader"
              [disabled]="disabled"
              (filesChanged)="savePhoto($event)">
            </app-chunk-file-uploader>
          </div>
          <div class="col-md-1">
            <button type="button" class="btn btn-primary"
              (click)="onSubmit()">Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="form-group row">
  <div class="col-md-6" *appHideIfNotInRole="['vessels-editor']">
    <div class="card shadow-sm ">
      <div class="card-header">
        <h3 class="card-title">Documents</h3>
      </div>
      <div class="card-body">
        <div class="form-group row">
          <label class="col-form-label col-md-2" for="Type">Type</label>
          <div class="col-md-10">
            <select class="form-select" id="type" [(ngModel)]="type">
              <option value='Form C'>Form C</option>
              <option value='Q88'>Q88</option>
            </select>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-form-label col-md-2" for="IssueDate">Issue
            Date</label>
          <div class="col-md-10">
            <app-date-range 
              id="IssueDate" 
              name="IssueDate"
              [singleDateSelector]="true"
              [(dateRange)]="issueDate"
              [appDateRangeRequired]>
            </app-date-range>
          </div>
        </div>
        <div class="form-group row">
          <label for="changeNameSelectFormCFile"
            class="col-form-label col-md-2">Form</label>
          <div class="col-md-10">
            <app-chunk-file-uploader
              [accept]="'.pdf'"
              name="form"
              (filesChanged)="uploadForm($event)">
            </app-chunk-file-uploader>
          </div>
        </div>
        <div class="form-group row pull-right">
          <div class="col-md-12">
            <button type="submit" class="btn btn-primary"
              (click)="onSubmit()">Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6" *appHideIfNotInRole="['vessels-editor']">
    <div class="card shadow-sm ">
      <div class="card-header">
        <h3 class="card-title">Change Name</h3>
      </div>
      <div class="card-body">
        <div class="form-group row">
          <label class="col-form-label col-md-2" for="startDate">From</label>
          <div class="col-md-10">
            <app-date-range id="startDate" name="startDate" tabindex="1"
              [(dateRange)]="endDateForCurrentPeriod"
              [singleDateSelector]="true" [appDateRangeRequired]>
            </app-date-range>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-form-label col-md-2" for="type">New Name</label>
          <div class="col-md-10">
            <input type="text" name="vesselName" id="vesselName"
              class="form-control" [(ngModel)]="newName" required>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-form-label col-md-2" for="flag">Flag</label>
          <div class="col-md-10">
            <app-organisation-selector
              id="flag"
              class="pg-component"
              [(organisation)]="flag"
              [organisationLanguage]="'en'"
              name="flag"
              [organisations]="flags"
              required>
            </app-organisation-selector>
          </div>
        </div>
        <div class="form-group row pull-right">
          <div class="col-md-12">
            <button type="submit" class="btn btn-primary"
              (click)="changeName()">Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="form-group row" *ngIf="vessel">
  <div id="history_tab" class="col-md-6">
    <div class="card shadow-sm ">
      <div class="card-header">
        <h3 class="card-title">History</h3>
      </div>
      <div class="card-body">
        <table class="table table-sm table-striped table-bordered">
          <thead>
            <tr>
              <td *appHideIfNotInRole="['vessels-editor']"></td>
              <td>File</td>
              <td>Date Update</td>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let document of vessel.documents">
              <ng-container>
                <td *appHideIfNotInRole="['vessels-editor']">
                  <button id="delete" name="delete" type="submit"
                    class="btn btn-link"
                    (click)="removeDocument(document.documentId)">
                    <i class="fa fa-trash-o"></i>
                  </button>
                </td>
                <td>
                  <a
                    (click)="downloadformId(document.documentId, document.documentType)">
                    <i class="btn btn-link">{{document.documentType}}</i>
                  </a>
                </td>
                <td>{{document.issueDate | date}}</td>
              </ng-container>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div id="history_tab" class="col-md-6">
    <div class="card shadow-sm ">
      <div class="card-header">
        <h3 class="card-title">Latest Name</h3>
      </div>
      <div class="card-body">
        <table class="table table-sm table-striped  table-bordered ">
          <thead>
            <tr>
              <td>Name</td>
              <td>From</td>
              <td>Flag</td>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let pre of vessel.previousNames">
              <ng-container>
                <td>
                  {{pre.name}}
                </td>
                <td>{{pre.from | date}}</td>
                <td>{{pre.flag}}</td>
              </ng-container>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {TimestampWithZone} from 'src/app/shared/components/date-time-zone/date-time-zone.component';

export enum MessageLevel {
  Debug,
  Info,
  Warning,
  Error,
  Critical,
  Oops
}

export interface Message {
  level: MessageLevel;
  topic: string;
  message: string;
}

export interface PublishedMessage {
  message: Message;
  time: TimestampWithZone;
  userId: string;
}

@Injectable({
  providedIn: 'root',
})
export class PgMessagesService {

  _messagesPublishedSource = new Subject<PublishedMessage>();
  messagePublished = this._messagesPublishedSource.asObservable();

  publishMessage(passedMessage: Message) {
    this._messagesPublishedSource.next({
      message: passedMessage,
      time: {
        timestamp: new Date().getTime(),
        timeZone: '',
      },
      userId: ''
    });
  }
}

import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, NgZone  } from '@angular/core';
import * as moment from 'moment';
import { ContractService, Contract } from './../contract.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject ,  Observable } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { OrganisationService, Organisation } from './../../organisation/organisation.service';
import { KeycloakService } from 'src/app/authentication/keycloak.service';
import { DataTablesService } from '../../shared/datatables/data-tables.service';
import { DateTimeHelpers } from 'src/app/shared/helpers/date-time.helpers';

@Component({
  selector: 'app-contract-detail',
  templateUrl: './contract-detail.component.html'
})
export class ContractDetailComponent implements AfterViewInit, OnInit, OnDestroy  {
  @ViewChild(DataTableDirective, {static: true})
  dtElement: DataTableDirective;

  contracts: Contract[];
  data: Observable<Contract[]>;
  companies: Organisation[];
  _organisationsCache: { [key: string]: Organisation} = {};

  dtOptions = {};
  dtTrigger: Subject<any> = new Subject<any>();
  isDataTablesInitialised = false;


  constructor(
    private _contractService: ContractService,
    private _activateRoute: ActivatedRoute,
    private _router: Router,
    private _organisationService: OrganisationService,
    private _keycloakService: KeycloakService,
    private _zone: NgZone
  ) {
    window['ContractDetailComponent'] = {component: this, zone: this._zone};
   }




  ngOnInit() {
    const buttons = [{
        text: 'New Commitment',
        action: () => {
            window['ContractDetailComponent'].component.addRecord();
          }
        }];
    this.dtOptions = DataTablesService.getOptionsEn(buttons);
    this.fillDataTable();
  }

  editRecord(contract: Contract) {
    this._router.navigate(['contracts/entry', 'edit', contract.contractNo]);
  }

  adminEdit(contract: Contract) {
    this._router.navigate(['contracts/entry', 'admin', contract.contractNo]);
  }

  addRecord() {
    this._router.navigate(['contracts/entry', 'new']);
  }

  formatDate(timestamp: number) {
    return DateTimeHelpers.getDate('Asia/Tehran', timestamp, 'YYYY/MM/DD');
  }

  fillDataTable() {
    this._activateRoute.paramMap.subscribe(p => {
      const startDate = Number(p.get('startDate'));
      const endDate = Number(p.get('endDate'));
      if (startDate && endDate) {
        this._contractService.getHistory(startDate, endDate).subscribe(c => {
          this.contracts = c;
          if (!this.isDataTablesInitialised) {
            this.dtTrigger.next(false);
          } else {
            this.dtElement.dtInstance.then((dtInstance) => {
              dtInstance.destroy();
              this.dtTrigger.next(false);
            });
          }
          this.isDataTablesInitialised = true;
        });
        this._organisationService.get().subscribe(organisations => {
          organisations.forEach(o => {
            this._organisationsCache[o.name] = o;
          });
        });
      }
    });
  }

  formatOrganisation(organisationId: string) {
    const foundOrganisation = this._organisationsCache[organisationId];
    if (foundOrganisation) {
      return foundOrganisation.nameTranslations['en'];
    } else {
      return '';
    }
  }

  formatDates(timestamp: number) {
    return moment(timestamp).format('YYYY/MM/DD');
}

  onVerify(data: Contract) {
    // this.busyIndicator = true;
    data.verify = !data.verify;
    this._contractService.update(data)
      .subscribe(() => {
        this.fillDataTable();
      });
  }

  hasRole(roleName: string) {
    return this._keycloakService.hasRole(roleName);
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.fillDataTable();
  }
}

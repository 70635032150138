import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { VoyagePartComponent } from 'src/app/transportation/vessels/voyage/voyage-part-component';
import { Voyage, Comment } from 'src/app/transportation/vessels/vessels.service';
import { MessageLevel, PgMessagesService } from 'src/app/shared/services/pg-messages.service';
import { KeycloakService } from 'src/app/authentication/keycloak.service';
import * as moment from 'moment-timezone';
import {DateTimeHelpers} from '../../../../shared/helpers/date-time.helpers';
import { DateRange } from 'src/app/shared/components';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
})
export class CommentComponent implements OnInit, VoyagePartComponent {
  @Output() changed: EventEmitter<Voyage> = new EventEmitter();
  @Output() cancelled: EventEmitter<any> = new EventEmitter();
  key = 'Comment';
  _uploadedFileId: string;
  _newUploadedFileId: string;
  _selectedVoyage: Voyage;
  _selectedDate: DateRange;

  comment: Comment;

  @Input() set fileId(value: string) {
    this._uploadedFileId = value;
  }
  @Input() set data(value: any) {
    this._selectedVoyage = value as Voyage;
  }
  @Input() set updatedFileId(value: string) {
    this._newUploadedFileId = value;
  }



  constructor(private _pgMessagesService: PgMessagesService,
    private _keycloakService: KeycloakService) {
    this.comment = {
      fileId: null,
      incidentTimestamp: null,
      author: null,
      content: null,
      authorCommentTimestamp: null,
      authorTimezone: null
    };
  }

  ngOnInit() {
    this._pgMessagesService.publishMessage({
      message: 'Navigated to comments component',
      level: MessageLevel.Debug,
      topic: 'Voyages'
    });
  }

  saveComment() {
    this.comment.fileId = this._uploadedFileId;
    this.comment.author = this._keycloakService.getUser().username;
    this.comment.authorCommentTimestamp = new Date().getTime();
    this.comment.authorTimezone = moment.tz.guess();
    this.comment.incidentTimestamp = this._selectedDate.startDate.getTime();
    if (this._newUploadedFileId) {
      this.comment.fileId = this._newUploadedFileId;
    }

    if (this._selectedVoyage.comments) {
      this._selectedVoyage.comments.push(this.comment);
    } else {
      this._selectedVoyage.comments = [this.comment];
    }

    this.changed.emit(this._selectedVoyage);
  }

  deleteComment() {
    if (confirm(`Are you sure to delete comment on ${DateTimeHelpers.formatLocalDate(this.comment.authorCommentTimestamp)}`)) {
      const i = this._selectedVoyage.comments.findIndex(c => c.fileId === this.comment.fileId);
      this._selectedVoyage.comments.splice(i, 1);
      this.changed.emit(this._selectedVoyage);
    }
  }
}

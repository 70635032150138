import { Component, EventEmitter, OnInit } from '@angular/core';
import { Vessel, VesselsService } from '../../vessels.service';
import { DataBaseActionService, ActionType } from 'src/app/shared/services/database-action.service';
import { Router } from '@angular/router';
import { PgMessagesService,MessageLevel } from 'src/app/shared/services/pg-messages.service';
import { Organisation, OrganisationService } from 'src/app/organisation/organisation.service';

@Component({
  selector: 'app-new-vessel',
  templateUrl: './new-vessel.component.html',
})
export class NewVesselComponent implements OnInit {
  vessel: Vessel;
  name: string;
  imo: string;
  flag: Organisation;
  flags: Organisation[];
  title = new EventEmitter<string>();

  constructor(private _vesselsService: VesselsService,
    private _actionService: DataBaseActionService,
    private _router: Router,
    private _organisationService: OrganisationService,
    private _pgMessagesService: PgMessagesService,) { }

  ngOnInit(): void {
    this.title.emit('New Vessel');
      this._organisationService.get().subscribe(x => {
      this.flags = x.filter(z => z.properties['country'] === true);
    });
  }


  addVessel() {
    this.vessel = {
      IMO: this.imo,
      name: {
        name: this.name,
        flag: this.flag.name,
        from: new Date().getTime(),
      },
      spec: {
        vesselType: '',
        dimensionsCapacities: {
          GRT: 0,
          NRT: 0,
          capacity: 0,
          draft: 0,
          deadWeight: 0,
          LOA: 0,
          beam: 0,
          lightShip: null,
          numberOfTanks: null,
        },
        yearBuilt: 0,
        email: null,
        photoIds: [],
        shipParticularId: null,
        charterPartySpeed: null,
        ownershipOperationProtection: null
      },
      active: false,
      certificates: [],
      previousNames: [],
      documents: [],
      actions: [],
    };
    this.saveVessel();
  }

  saveVessel() {
    this.vessel.actions.push(this._actionService.userAction(ActionType.Create));
    this._vesselsService.add(this.vessel).subscribe(() => {
      this._pgMessagesService.publishMessage({
        level: MessageLevel.Info,
        topic: 'Vessel',
        message: `the vessel ${this.vessel.IMO} added to list`
      });
      this._router.navigate(['vessel', this.vessel.IMO]).then();
    }, () => {
      this._pgMessagesService.publishMessage({
        level: MessageLevel.Error,
        topic: 'Vessel',
        message: 'There was a problem to add this vessel'
      });
    });
  }

}

<div class="row">
  <div class="col-md-6">
    <div class="card">
      <form class="form-horizontal" #newCorrespondence="ngForm">
        <div class="card-body">
          <div class="form-group row" *ngIf="edit">
            <label for="provider" class="col-form-label col-md-4 col-sm-4 ">Provider</label>
            <div class="col-md-8 col-sm-8">
              <input type="text"
              class="form-control"
              name="provider"
              id="provider"
              [ngModel]="correspondence?.providerUserName"
              tabindex="1"
              disabled
              required>
            </div>
          </div>
          <div class="form-group row">
            <label for="owner" class="col-form-label col-md-4 col-sm-4 ">From</label>
            <div class="col-md-8 col-sm-8">
                <app-organisation-selector
                  [(organisation)]="organisation"
                  [organisationLanguage]= "'fa'"
                  name="organisation"
                  [organisations]="organisations"
                  [disabled]= "edit || (!edit && !incoming)"
                  tabindex="2"
                  required>
                </app-organisation-selector>
            </div>
          </div>
          <div class="form-group row" *ngIf="edit || (!edit && incoming)">
            <label for="selectedDate" class="col-form-label col-md-4 col-sm-4 ">Date</label>
            <div class="col-md-8 col-sm-8">
              <app-date-range id="selectedDate"
              [jalali]="true"
              style="padding: 0"
              [singleDateSelector]="true"
              [(dateRange)]="dateRange"
              [disabled] = " edit"
              tabindex="3">
              </app-date-range>
            </div>
          </div>
          <div class="form-group row" *ngIf="incoming">
            <label for="reference" class="col-form-label col-md-4 col-sm-4 ">Main Referenece</label>
            <div class="col-md-8 col-sm-8">
              <input type="text"
              class="form-control"
              name="incomingReference"
              id="incomingReference"
              [ngModel]="correspondence?.incomingReference"
              (ngModelChange)="correspondence.incomingReference = $event"
              [disabled] = "edit"
              tabindex="4"
              required>
            </div>
          </div>
          <div class="form-group row">
            <label for="title" class="col-form-label col-md-4 col-sm-4 ">Title</label>
            <div class="col-md-8 col-sm-8">
              <input type="text"
              class="form-control"
              name="title"
              id="title"
              [ngModel]="correspondence?.title"
              (ngModelChange)="correspondence.title = $event"
              [disabled] = "edit"
              tabindex="5"
              required>
            </div>
          </div>
          <div class="form-group row">
            <label for="owner" class="col-form-label col-md-4 col-sm-4 ">To</label>
            <div class="col-md-8 col-sm-8">
                <app-organisation-selector
                  [organisation]="toOrganisation"
                  (organisationChange)="getCorrespondence($event)"
                  [organisationLanguage]= "'fa'"
                  name="to"
                  [organisations]="organisations"
                  [disabled] = "edit"
                  tabindex="6"
                  required>
                </app-organisation-selector>
            </div>
          </div>
          <div class="form-group row">
            <label for="keywords" class="col-form-label col-md-4 col-sm-4 ">Keywords</label>
            <div class="col-md-8 col-sm-8">
              <input type="text"
              class="form-control"
              name="keywords"
              id="keywords"
              [ngModel]="correspondence?.keywords"
              (ngModelChange)="correspondence.keywords = $event"
              tabindex="7"
              [disabled]="edit"
              >
            </div>
          </div>
          <div *ngFor="let doc of correspondence.relatedDocsReferences; let in=index" class="">
            Related to
            <a
              class="btn btn-link">
              {{correspondenceFormatter2(doc)}}
            </a>
            <a
              class="btn btn-link"
              (click)="removeRelatedDoc(in)">
              <i class="fa fa-minus"></i>
            </a>
          </div>
          <div *ngIf="edit">
            <div class="form-group row">
              <label for="related" class="col-form-label col-md-4 col-sm-4 ">Related Correspondence</label>
              <div class="col-md-8 col-sm-8">
                  <app-auto-complete
                    [formatter]="corresPondenceFormatter"
                    [(ngModel)]="relatedDoc"
                    name="relatedDoc"
                    [items]="correspondences">
                </app-auto-complete>
              </div>
            </div>
            <div class="col-md-12 ">
              <input
              type="button"
              name="relatedDoc"
              value="Add"
              class="btn btn-primary"
              (click)="addRelatedDoc()"
              [disabled] ="!relatedDoc">
            </div>
          </div>
          <div *ngIf="!edit && !incoming" class="">
            <button   id="download"
            name="download"
            type="submit"
            class="btn btn-link"
            (click)="downloadTemplate()"
            tabindex="9">
              <a>Get Correspondence Template file</a>
            </button>
          </div>
          <div class="form-group row" *ngIf="!edit">
            <label for="licenseNumber" class="col-form-label col-md-4 ">Main File</label>
            <app-file-uploader  class="col-md-7"
                                [accept]="'.docx, .pdf'"
                                name="uploadedCertificate"
                                [disabled] = "edit"
                                [(ngModel)]="mainFiles"
                                tabindex="10"
                                required>
            </app-file-uploader>
          </div>
          <div class="col-md-12 ">
            <input
            type="button"
            name="create"
            value="Save"
            class="btn btn-primary"
            (click)="create()"
            tabindex="11"
            [disabled]="!newCorrespondence.form.valid"
            >
          </div>
          <div *ngIf="edit" class="">
            <button   id="download"
                      name="download"
                      type="submit"
                      class="btn btn-link"
                      tabindex="12"
                      (click)="downloadNew()">
              <a>
                <i class="fa fa-file" aria-hidden="true"></i>
                Main file</a>
            </button>
          </div>
          <div *ngIf="edit" >
            <div *ngFor="let attachment of correspondence.attachments; let in=index" class="">
              <a
                class="btn btn-link"
                (click)="downloadAttachment(in)">
                <i class="fa fa-paperclip"></i>
                Attachment {{in + 1}}
              </a>
              <a
                class="btn btn-link"
                (click)="removeAttachment(in)">
                <i class="fa fa-minus"></i>
              </a>
            </div>
          </div>
          <div *ngIf="edit">
            <div class="form-group row">
              <label for="newattachment"
              class="col-form-label col-md-4 col-sm-4 ">New Attachment</label>
            <app-file-uploader  class="col-md-8 col-sm-8"
                                [accept]="'.pdf, .xlsx, .docx, .jpeg, .jpg'"
                                name="attachment"
                                id="attachment"
                                [(ngModel)]="attachment"
                                tabindex="13"
                                required>
            </app-file-uploader>
            </div>
            <div class="col-md-12 ">
              <input
              type="button"
              name="addAttachment"
              value="Save"
              class="btn btn-primary"
              (click)="addAttachment()"
              [disabled] ="!attachment"
              tabindex="14"
            >
            </div>
          </div>
          <div class="form-group row" *ngIf="edit && !incoming">
            <label for="licenseNumber" class="col-form-label col-md-4 col-sm-4 ">Signed File</label>
            <app-file-uploader  class="col-md-7 col-sm-7"
                                [accept]="'.pdf'"
                                name="signedFile"
                                id="signedFile"
                                [(ngModel)]="signedFiles"
                                tabindex="15"
                                required>
            </app-file-uploader>
            <input  type="button"
                    name="uploadSignedFile"
                    value="Save"
                    class="btn btn-primary"
                    (click)="uploadSignedFile()"
                    [disabled]="!signedFiles"
                    tabindex="16"
                    >
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="col-md-6" *ngIf="uploadedFileId">
    <app-pdf-viewer [fileId]="uploadedFileId"></app-pdf-viewer>
  </div>
</div>

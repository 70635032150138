import { Component, EventEmitter, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { Vessel, VesselsService, Voyage } from 'src/app/transportation/vessels/vessels.service';
import {
  Crew,
  CrewChange, CrewChangeItem,
  CrewChangeServices, CrewContract
} from 'src/app/transportation/vessels/crew-change/crew-change.service';
import { MessageLevel, PgMessagesService } from 'src/app/shared/services/pg-messages.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Organisation, OrganisationService } from 'src/app/organisation/organisation.service';
import { KeycloakService } from 'src/app/authentication/keycloak.service';
import { DateTimeHelpers } from 'src/app/shared/helpers/date-time.helpers';
import { LookupService } from '../../../../shared/services/lookup.service';
import moment from 'moment';
import {PgToolbarComponent} from '../../../../shared/components/title-bar/title-bar.component';
import {
  CrewChangeToolbarComponent
} from './crew-change-toolbar-component/crew-change-toolbar.component';

import { v4 as uuidv4 } from 'uuid';


@Component({
  selector: 'app-crew-change',
  templateUrl: './crew-change.component.html'
})
export class CrewChangeComponent implements OnInit, PgToolbarComponent {
  title = new EventEmitter<string>();
  crewChange: CrewChange;
  crew: Crew;
  organisations: Organisation[];
  planMode: boolean;
  voyages: Voyage[];
  selectedVessel: Vessel;
  selectedVoyage: Voyage;
  cancelMode: boolean;
  vessels: Vessel[];
  message: string;
  crewId: string;
  crewChangeId: string;
  ranks: string[];

  toolbarComponentType = CrewChangeToolbarComponent;
  toolbarComponent: CrewChangeToolbarComponent;

  selectedCrewPreviousAssignments : CrewChange[] = null;

  constructor(
    private _vesselsService: VesselsService,
    private _crewChangeService: CrewChangeServices,
    private _keycloakService: KeycloakService,
    private _organisationService: OrganisationService,
    private _lookupService: LookupService,
    private _activateRoute: ActivatedRoute,
    private _router: Router,
    private _pgMessagesService: PgMessagesService
  ) { }

  ngOnInit(): void {
    this.voyages = [];
    this.planMode = false;
    this._activateRoute.paramMap.subscribe((p) => {
      this.crewId = p.get('id');
      this.crewChangeId = p.get('crewChangeId');
      forkJoin([
        this._vesselsService.getActiveVessels(),
        this._organisationService.get(),
        this._lookupService.get('ranks'),
      ]).subscribe((results) => {
        this.vessels = results[0];
        this.organisations = results[1];
        this.ranks = results[2].items.map(i => i[0]);
        this.initCrewChange();
      });
    });
  }

  rankFormatter(data: any) {
    return data;
  }

  voyageFormatter(v: any) {
    return `${v.shipVoyageNo}`;
  }

  initCrewChange() {
    this._crewChangeService.getOneCrewById(this.crewId)
    .subscribe((crew) => {
      this.title.emit(`Crew change`);
      this.crew = crew;
      if (this.crewChangeId) {
        this.cancelMode = false;
        this.crewChange = crew.crewChanges.find(cr => cr.id === this.crewChangeId);
        this.selectedVessel = this.vessels.find((v) => v.IMO === this.crewChange.vesselIMO);
        this.selectVessel(this.selectedVessel);
        this.toolbarComponent.crewChange = this.crewChange;
        this.toolbarComponent.crew = crew;
        this.toolbarComponent.cancelPlan.subscribe(() => this.cancelAll());
        this.toolbarComponent.deleteCrewChange.subscribe(() => this.remove());
        this.toolbarComponent.newContractExtension.subscribe(() => this.newContractExtension());
      } else {
          this.crewChange = {
            id: '00000000-0000-0000-0000-000000000000',
            voyageNumber: null,
            vesselName: null,
            vesselIMO: null,
            rank: null,
            isCancelled: false,
            isDeleted: false,
            item: [],
            contract: null,
          };
          this.cancelMode = false;
      }
    });
  }

  selectVessel(v: Vessel) {
    if (v) {
      this.crewChange.vesselIMO = v.IMO;
      this.crewChange.vesselName = v.name.name;
      this._vesselsService.getVoyagesByIMO(v.IMO).subscribe((voyages) => {
        this.voyages = voyages.sort((v1, v2) => v2.shipVoyageNo - v1.shipVoyageNo);
        if(this.crewChangeId) {
          this.selectedVoyage = voyages.find(voyage => voyage.shipVoyageNo === this.crewChange.voyageNumber);
        }
      });
    }
  }

  cancelAll() {
    if (confirm(`Are you sure you want to Cancel this CrewChange?`)) {
      this.crewChange.isCancelled = true;
      this.save();
    }
  }

  remove() {
    if (confirm(`Are you sure you want to delete this crew change?`)) {
      const index = this.crew.crewChanges.findIndex(c => c.id === this.crewChange.id);
      this.crew.crewChanges.splice(index,1);
      this.update();
    }
  }

  vesselFormatter(vessel: Vessel) {
    return vessel.name.name;
  }

  save() {
    this.crewChange.voyageNumber = this.selectedVoyage.shipVoyageNo;
    if (this.crewChangeId) {
      const index = this.crew.crewChanges.findIndex(c => c.id === this.crewChange.id);
      this.crew.crewChanges[index] = this.crewChange;
    } else {
      this.crewChange.id = uuidv4();
      this.crew.crewChanges.push(this.crewChange);
    }
    this.update();
  }

  update() {
    this._crewChangeService.updateCrew(this.crew)
      .subscribe({next: () => {
        this._pgMessagesService.publishMessage({
          message: `${this.crew.firstName} ${this.crew.lastName}`,
          topic: 'Crew change added',
          level: MessageLevel.Info,
        });
        this._router.navigate(['vessels/crew-change/crew-change-data-entry', this.crew.id, this.crewChange.id]).then();
      }, error: () => {
        this._pgMessagesService.publishMessage({
          message: 'Error',
          topic: 'Crew change Insertion Error',
          level: MessageLevel.Error,
        });
      }
    });
  }

  hasRole(roleName: string) {
    return this._keycloakService.hasRole(roleName);
  }

  extractDateTime(crewChangeItem: CrewChangeItem) {
    if (crewChangeItem && crewChangeItem.time && crewChangeItem.place) {
      const zoneId = this.organisations.find(o => o.name === crewChangeItem.place).timezone;
      return DateTimeHelpers.getDateFromTimestamp(zoneId, crewChangeItem.time);
    }
  }

  crewChangeFindPlan(ch: CrewChange, plan: string) {
    return ch.item?.find(c => c.plan === plan);
  }

  duration(ch: CrewChange) {
    const on = this.crewChangeFindPlan(ch, 'On Board');
    const off = this.crewChangeFindPlan(ch, 'Off Board');
    if (on && off) {
      return moment(off?.time)
        .diff(moment(on?.time), 'months', true)
        .toFixed(2);
    } else {
      return '-';
    }
  }

  saveContract(crewContract: CrewContract) {
    this.crewChange.contract = crewContract;
    this.save();
  }

  newContractExtension() {
    if(this.crewChange.contract) {
      const extension: CrewContract = {
        contractDocumentId: null,
        date: null,
        basicSalaryUSD: null,
        durationInMonths: null,
        guaranteedOvertimeUSD: null,
        leavePayUSD: null,
        leaveSubsistenceUSD: null,
        managementOrganisationId: null,
        tankerAllowanceUSD: null,
        extensions: []
      };
      this.crewChange.contract.extensions.push(extension);
    }
  }

}

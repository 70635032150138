
<div class="row" style="padding-top: 5px">
  <div class="col-md-12">
    <table datatable
      [dtOptions]="dtOptions"
      [dtTrigger]="dtTrigger"
      class="table table-sm table-striped table-bordered">
      <thead>
        <tr>
          <td></td>
          <td>Vessel</td>
          <td>Date</td>
          <td>Voyage Number</td>
          <td>Type</td>
          <td>Lat/Long</td>
          <td>From</td>
          <td>To</td>
          <td>ETA</td>
          <td>Speed</td>
          <td>Laden/Ballast</td>
          <td>Master</td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let report of noonReportsLocations">
          <td>
            <div *ngIf="report.fileId">
              <a (click)="downloadFile(report)">
                <i class="fa fa-file-excel-o" aria-hidden="true"></i>
              </a> &nbsp; &nbsp;
            </div>
          </td>
          <td>{{report.vesselName}}</td>
          <td>{{formatDate(report.date)}}</td>
          <td>{{report.voyageNumber}}</td>
          <td>{{report.reportType}}</td>
          <td>{{report.geoCoordinate.latitude |
            number}}/{{report.geoCoordinate.longitude | number}}</td>
          <td>{{report.from}}</td>
          <td>{{report.to}}</td>
          <td>{{report.eta}}</td>
          <td>{{report.averageSpeed | number}}</td>
          <td>{{report.isLaden? 'Laden': 'Ballast'}}</td>
          <td>{{report.master}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

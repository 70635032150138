import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { NoonReportsService, NoonReportV202005 } from '../../services/noon-reports.service';
import { PgTitleComponent } from 'src/app/shared/components/title-bar/title-bar.component';
import { forkJoin, Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { DataTablesService } from 'src/app/shared/datatables/data-tables.service';
import { DateTimeHelpers } from 'src/app/shared/helpers/date-time.helpers';
import { Organisation, OrganisationService } from 'src/app/organisation/organisation.service';
import { Vessel, VesselsService, Voyage } from '../../vessels.service';
import * as _ from 'lodash';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { DocumentService } from 'src/app/documents/documents.service';


@Component({
  selector: 'app-fleet-summery-report',
  templateUrl: './fleet-summery-report.component.html',
})
export class FleetSummeryReportComponent implements OnInit, PgTitleComponent {
  title = new EventEmitter<string>();


  @ViewChild(DataTableDirective, {static: true})
  dtElement: DataTableDirective;
  isDataTablesInitialised = false;
  dtOptions = {};
  dtTrigger: Subject<any> = new Subject<any>();

  bunkerDtElement: DataTableDirective;
  isBunkerDataTablesInitialised = false;
  bunkerDtOptions = {};
  bunkerDtTrigger: Subject<any> = new Subject<any>();

  reports: any[];
  organisations: Organisation[];
  constructor(
    private _noonReportService: NoonReportsService,
    private _organisationService: OrganisationService,
    private _vesselService: VesselsService,
    private _documentService: DocumentService

  ) {}

  ngOnInit() {
    this.title.emit('Vessel Management report');
    const component = this;
    const buttons = [{
      text: 'Download',
      action: () => component.downloadReport()
    }];
    this.dtOptions = DataTablesService.getOptionsEn(buttons, [], 20 , true);
    this.bunkerDtOptions = DataTablesService.getOptionsEn([], [], 20, true);

    forkJoin(
      [
        this._noonReportService.getVesselLatestReport(),
        this._organisationService.get()

      ]
    ).subscribe(results => {
      this.reports = results[0];
      this.organisations = results[1];
      this.initialiseDataTables();
      this.initialiseBunkerDataTables();
    });
  }

  initialiseDataTables() {
    if (!this.isDataTablesInitialised) {
      this.dtTrigger.next(false);
    } else {
      this.dtElement.dtInstance.then((dtInstance) => {
        dtInstance.destroy();
        this.dtTrigger.next(false);
      });
    }
    this.isDataTablesInitialised = true;
  }

  initialiseBunkerDataTables() {
    if (!this.isBunkerDataTablesInitialised) {
      this.bunkerDtTrigger.next(false);
    } else {
      this.bunkerDtElement.dtInstance.then((dtInstance) => {
        dtInstance.destroy();
        this.bunkerDtTrigger.next(false);
      });
    }
    this.isDataTablesInitialised = true;
  }

  formatDate(timestamp: number) {
    if(timestamp > 0) {
      return DateTimeHelpers.formatUTCDate(timestamp);
    } else {
      return '';
    }
  }

  organisationFormatter(id: string) {
    const name = id ? this.organisations.find(o => o.name === id).nameTranslations['en'] : '';
    if(name) {
      return name.substring(0,8);
    }
  }

  vesselAge(vessel: Vessel) {
    return this._vesselService.calculateVesselAge(vessel);
  }

  inssurance(vessel: Vessel) {
    return vessel.certificates.find(c => c.name.includes('P&I'));
  }

  formatCargoOnboard(report: NoonReportV202005) {
    return _.chain(report.tanksCondition)
      .groupBy(r => r.product)
      .map((items, key) => {
        const sum = Math.round(items.map(i => i.quantity).reduce((p, c) => p + c, 0));
        if(sum > 0) {
          return `${key[0]}: ${sum}`;
        }
      })
      .value().filter(v => v !== undefined).join(', ');
  }

  blFormatter(voyage: Voyage, laden: boolean) {
    const bl = ((voyage.billOfLadings.filter(b => b.revise).length > 0) ?
      voyage.billOfLadings.filter(b => b.revise) :
      voyage.billOfLadings.filter(b => !b.revise)).sort((a, b) => b.date - a.date)[0];
      if(bl && laden) {
        return this.formatDate1(bl.date, bl.loadingPortOrganisationId);
      }
  }

  formatDate1(dt: number, portOrganisationId: string) {
    if (this.organisations) {
      const selectedTimezone = this._organisationService.getOrganisationTimezone(portOrganisationId, this.organisations);
      if (selectedTimezone) {
        return DateTimeHelpers.getDateFromTimestamp(selectedTimezone, dt);
      } else {
        console.error(`Cannot find timezone for organisation Id: ${portOrganisationId}`);
      }
    }
  }

  bunkerLastRecievedDate(report: NoonReportV202005, bunkerType: string) {
    return report.noonReportBunkerStatus.lastReceived.find(l => l.receivedType === bunkerType).timestamp;
  }


  bunkerLastRecievedFormatter(report: NoonReportV202005, bunkerType: string) {
    return report.noonReportBunkerStatus.lastReceived
    .filter(l => l.receivedType.includes(bunkerType))
    .map(n => n.quantityMT)
    .reduce((a, b) => a + b, 0);
  }

  robFormatter(report: NoonReportV202005, bunkerType: string) {
    return Math.round(report.noonReportBunkerStatus.rob
    .filter(l => l.bunkerType.includes(bunkerType))
    .map(n => n.quantityMT)
    .reduce((a, b) => a + b, 0) * 1000) / 1000;
  }

  downloadReport() {
    this._noonReportService.getVesselLatestReportExcel().subscribe(b => {
      saveAs(b.body, `latest-reports.xlsx`);
    });
  }

  capitalize(text: string) {
    const temp = text.trim();
    return temp[0].toLocaleUpperCase() + temp.substring(1).toLocaleLowerCase();
  }

  windSeaFormatter(noonReport: NoonReportV202005) {
    return `${noonReport.condition.windForce} 
    ${noonReport.condition.windForceDirection} / 
    ${noonReport.condition.seaForce} 
    ${noonReport.condition.seaForceDirection}`;
  }

  checkDryDockExpirey(vessel: Vessel) {
    if(vessel.spec.dryDock) {
      const diff = moment(vessel.spec.dryDock.nextDryDock).diff(moment(), 'days');
      if (diff > 90) {
        return 2;
      } else if (diff <= 90 && diff >= 0) {
        return 1;
      } else if (diff < 0) {
        return 0;
      }
    }
  }

  checkUpdatedNoon(noonreport: NoonReportV202005) {
    return moment(noonreport.date.timestamp).isBefore(moment(), "day");
  }

  checkEta(noonreport: NoonReportV202005) {
    return noonreport.voyageDetails.eta < new Date().getTime();
  }

  downloadFile(report: NoonReportV202005) {
    this._documentService.download(report.fileId).subscribe(b => {
      const n = report.vesselName.split(' ').map(s => s.toLowerCase()).join('-');
      saveAs(b.body, `${n}-${DateTimeHelpers.formatLocalDate(report.date.timestamp)}-report.xlsx`);
    });
  }

  speedFormatter(speed: number) {
    return Math.round(speed * 100) / 100;
  }



}

<div class="row">
  <div class="col-md-12">
    <div id="mapid" style="height: 70vh; margin-bottom: 10px"> </div>
  </div>
</div>

<div class="row">
  <div class="col-md-4 col-xs-12">
    <div class="card shadow-sm" *appHideIfNotInRole="['vessels-viewer']">
      <div class="card-header">Last voyages</div>
      <div class="card-body">
        <div class="table table-sm-responsive">
          <table datatable
            [dtOptions]="dtOptions"
            [dtTrigger]="dtTrigger"
            class="table table-sm no-margin">
            <thead>
              <tr>
                <th>Vessel</th>
                <th>Voyage</th>
                <th>Days</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let v of ongoingVoyages">
                <td><a
                    [routerLink]="['/vessel/', v.vesselIMO]">{{v.vesselName}}</a></td>
                <td><a
                    [routerLink]="['/vessels/voyage', v.id]">{{v.shipVoyageNo}}
                  </a></td>
                <td>{{calculateDays(v)}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-8 col-xs-12">
    <div class="card shadow-sm ">
      <div class="card-header">Arrivals & Departures</div>
      <div class="card-body">
        <div class="table table-sm-responsive">
          <table datatable
            [dtOptions]="dtOptions"
            [dtTrigger]="dtTrigger"
            class="table table-sm no-margin">
            <thead>
              <tr>
                <th>Vessel</th>
                <th>Voyage</th>
                <th>Type</th>
                <th>Date</th>
                <th>Source</th>
                <th>Destination</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let v of arrivalDepartures">
                <td>{{v.vesselName}}</td>
                <td>{{v.voyageDetails.voyageNumber}}</td>
                <td>{{v.reportType}}</td>
                <td>{{v.date.timestamp | date: 'yyyy-MM-dd HH:mm'}}</td>
                <td>{{v.voyageDetails.source}}</td>
                <td>{{v.voyageDetails.destination}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

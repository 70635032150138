<div class="row">
  <div class="col-md-6">
    <div class="card card-body">
      <form class="form-horizontal" *ngIf="newOrganisation"
      #organisationForm="ngForm">
      <div class="form-group row">
        <label for="name" class="col-form-label col-md-4 ">شناسه</label>
        <div class="col-md-8">
          <input type="text"
            id="name"
            name="name"
            class="form-control"
            required
            [disabled]="edit"
            [(ngModel)]="newOrganisation.internalName">
        </div>
      </div>
      <div class="form-group row">
        <label for="parentOrganisation"
          class="col-form-label col-md-4 ">مجموعه</label>
        <div class="col-md-8">
          <input type="text"
            id="parentOrganisation"
            name="parentOrganisation"
            class="form-control"
            [disabled]="true"
            [(ngModel)]="newOrganisation.parentOrganisation">
        </div>
      </div>
      <div class="form-group row">
        <label for="farsiName" class="col-form-label col-md-4 ">نام
          فارسی</label>
        <div class="col-md-8">
          <input type="text"
            id="farsiName"
            name="farsiName"
            class="form-control"
            required
            [(ngModel)]="newOrganisation.nameTranslations['fa']">
        </div>
      </div>
      <div class="form-group row">
        <label for="englishName" class="col-form-label col-md-4 ">نام
          انگلیسی</label>
        <div class="col-md-8">
          <input type="text"
            id="englishName"
            name="englishName"
            class="form-control"
            required
            [(ngModel)]="newOrganisation.nameTranslations['en']">
        </div>
      </div>
      <div class="form-group row">
        <label for="manager" class="col-form-label col-md-4 ">مدیر</label>
        <div class="col-md-8">
          <input type="text"
            id="manager"
            name="manager"
            class="form-control"
            required
            [(ngModel)]="newOrganisation.manager">
        </div>
      </div>
      <div class="form-group row">
        <label for="address" class="col-form-label col-md-4 ">آدرس</label>
        <div class="col-md-8">
          <input type="text"
            id="address"
            name="address"
            class="form-control"
            required
            [(ngModel)]="newOrganisation.address">
        </div>
      </div>
      <div class="form-group row">
        <label for="latitude"
          class="col-form-label col-md-4">latitude</label>
        <div class="col-md-3">
          <input type="number"
            id="latitude"
            name="latitude"
            class="form-control"
            required
            [(ngModel)]="newOrganisation.location.latitude">
        </div>
        <label for="longitude"
          class="col-form-label col-md-2 ">longitude</label>
        <div class="col-md-3">
          <input type="number"
            id="longitude"
            name="longitude"
            class="form-control"
            required
            [(ngModel)]="newOrganisation.location.longitude">
        </div>
      </div>
      <div class="form-group row">
        <label for="tz" class="col-form-label col-md-4 ">Timezone</label>
        <div class="col-md-8">
          <app-auto-complete
            [formatter]="formatter"
            [(ngModel)]="newOrganisation.timezone"
            [items]="availableTimeZones"
            name="tz"
            id="tz"
            required>
          </app-auto-complete>
        </div>
      </div>
      <div class="form-group row" *ngIf="showAdditionalData">
        <label for="emailDomain" class="col-form-label col-md-4 ">New Extended item</label>
        <div class="col-md-8">
          <select
            class="form-control"
            [(ngModel)] ="addData"
            name="addData"
            (ngModelChange)="addExtendedProp($event)"
          >
            <option *ngFor="let key of objectKeys(additionalProp)" [ngValue]="key">
              {{additionalProp[key]}}
            </option>
          </select>
        </div>
      </div>
      <div class="form-group row" *ngIf="showAvailableProp">
        <label for="emailDomain" class="col-form-label col-md-4 ">New Property item</label>
        <div class="col-md-8">
          <select
            class="form-control"
            [(ngModel)] ="addProp"
            name="addProp"
            (ngModelChange)="addAavailableProp($event)"
          >
            <option *ngFor="let key of objectKeys(availableProperties)" [ngValue]="key">
              {{availableProperties[key]}}
            </option>
          </select>
        </div>
      </div>
      <div class="form-group row" *ngFor="let f of objectKeys(additionalPropDic)">
          <label for="emailDomain" class="col-form-label col-md-4 ">{{additionalProp[f]}}</label>
          <div class="col-md-6">
            <input type="text"
            class="form-control"
            id="{{f}}"
            name="{{f}}"
            [(ngModel)]="additionalPropDic[f]">
          </div>
          <div class="col-md-2">
            <input type="button" (click)="removeAdditionalProp(f)" value="Delete" class="btn btn-outline-secondary btn-light col-md-12">
          </div>
      </div>
      <div class="form-group row">
        <div class="col-md-4 offset-md-4"
          *ngFor="let f of objectKeys(propertyDic)">
          <input type="checkbox"
            id="{{f}}"
            name="{{f}}"
            [(ngModel)]="propertyDic[f]">&nbsp;&nbsp;{{availableProperties[f]}}
        </div>
      </div>
      <div class="btn-group pull-right mt-3 col-md-4" role="group">
        <button type="submit"
          class="btn btn-outline-secondary btn-light me-1"
          (click)="add()"
          [disabled]="!organisationForm.form.valid">Save
        </button>
        <input type="button" (click)="showAdditionalProp()" value="Additional Data" class="btn btn-outline-secondary btn-light me-1">
        <input type="button" (click)="showAvailables()" value="New Flag" class="btn btn-outline-secondary btn-light me-1">
      </div>
    </form>
    </div>
  </div>
  </div>

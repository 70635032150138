<div class="row">
  <div class="col-md-12"> 
    <table  datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" id="tblSearchCust"
            class="table table-sm table-striped  table-bordered ">
      <thead>
      <tr>
        <td>Date</td>
        <td>Persian Date</td>
        <td>Provider</td>
        <td>Index</td>
        <td>Product</td>
        <td>Price</td>
      </tr>
      </thead>
      <tbody>
        <tr *ngFor="let price of prices; let i=index;">
            <td>{{formatProductDates(price.timestamp)}}</td>
            <td>{{price.timestamp | persianDateOnly}}</td>
            <td>{{price.provider}}</td>
            <td>{{price.index}}</td>
            <td>{{price.productId}}</td>
            <td>{{price.price}}</td>
          </tr>
      </tbody>
    </table>
  </div>
</div>


<div class="navbar-nav bd-navbar-nav px-3">
  <a class="nav-link" data-bs-toggle="dropdown" [attr.aria-expanded]="showMessages" 
      [ngClass]="{'show':showMessages, 'text-warning': messages.length > 0}">
    {{messages.length}} <i class="far fa-bell"></i>
  </a>
  <ul id="messages" class="dropdown-menu dropdown-menu-end mx-3  col-md-2 col-sm-4" 
      [ngClass]="{'show':showMessages}"
      aria-labelledby="bd-versions" 
      [attr.data-bs-popper]="(showMessages) ? 'static' : ''">
    <li class="nav-item" >
      <div>
        <span class="dropdown-item dropdown-header">You have {{messages.length}} Notifications</span>
        <div *ngFor="let m of messages">
          <div class="dropdown-divider"></div>
          <a class="dropdown-item">
            <i [ngClass]="textStyle(m.message.level,true)"></i>
            <a [ngClass]="textStyle(m.message.level,false)" class="px-2"
              >{{m.message.topic}}</a
            >
            <div class="text-sm text-wrap">{{m.message.message}}</div>
            <p class="text-sm text-muted">
              <i class="far fa-clock mr-1"></i
              >{{moment(m.time.timestamp).locale("en").fromNow()}}
            </p>
          </a>
        </div>
      </div>
    </li>
  </ul>
</div>
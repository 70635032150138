import {Component, EventEmitter, OnInit, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { Subject, forkJoin } from 'rxjs';
import { LPGDistService, Quota, ToolbarItem } from '../../lpg-dist.service';
import * as moment from 'moment-jalaali';
import { DataTableDirective } from 'angular-datatables';
import { DataTablesService } from 'src/app/shared/datatables/data-tables.service';
import { Organisation, OrganisationService } from 'src/app/organisation/organisation.service';
import { DateRange } from 'src/app/shared/components';
import { LpgDistToolbarComponent } from 'src/app/lpg-dist/lpg-dist-toolbar/lpg-dist-toolbar.component';
import { UserLocalStoreService } from 'src/app/shared/services/user-local-store.service';
import {DateTimeHelpers} from '../../../shared/helpers/date-time.helpers';
import {saveAs} from 'file-saver';
import { PgMessagesService, MessageLevel } from 'src/app/shared/services/pg-messages.service';
import {
  PgDateRangeTitleComponent,
  PgDateRangeTitleEnum, PgTitleComponent,
  PgToolbarComponent
} from '../../../shared/components/title-bar/title-bar.component';


interface DailyReportComponentSettings {
  companyId: string;
  calendar: boolean;
}

@Component({
  selector: 'app-daily-report',
  templateUrl: './daily-report.component.html',
})
export class DailyReportComponent implements OnInit, PgTitleComponent, PgDateRangeTitleComponent, PgToolbarComponent {
  @ViewChild(DataTableDirective, {static: true})
  dtElement: DataTableDirective;

  title = new EventEmitter<string>();
  dateRangeSubTitle = new EventEmitter<[DateRange, PgDateRangeTitleEnum]>();
  toolbarComponentType = LpgDistToolbarComponent;
  toolbarComponent: LpgDistToolbarComponent;
  startDate: Date;
  endDate: Date;
  endOfMonth: Date;
  organisationsLoading: any[] = [];
  sortedOrganisationsLoadings: any[] = [];
  organisationDischarge: any[] = [];
  groupedByRootData: any[] = [];
  organisations: Organisation[];
  quotas: Quota[];
  test = true;
  dateRange: DateRange;

  dtOptions = {};
  discharcheOptions = {};
  dtTrigger: Subject<any> = new Subject<any>();
  isDataTablesInitialised = false;

  dischargeDtElement: DataTableDirective;

  totalWheightElement: DataTableDirective;

  _settings: DailyReportComponentSettings;

  constructor(
    private _lpgDistService: LPGDistService,
    private _organisationService: OrganisationService,
    private _router: Router,
    private _userLocalStorage: UserLocalStoreService,
    private _pgMessageService: PgMessagesService) { }

  ngOnInit() {
    this.dtOptions = DataTablesService.getOptions();
    this._settings = this._userLocalStorage.get<DailyReportComponentSettings>('DailyReportComponent');
    if (!this._settings) {
      this._settings = { companyId: 'iran.parsagroup', calendar: true };
    }
    this.setDates();
    this.fillDataTable();
  }
  setDates() {
    if(this.dateRange) {
      this.endDate = this.dateRange.endDate;
    } else {
      this.endDate = moment().subtract(1, 'days').endOf('day').toDate();
    }
    if (this._settings.calendar) {
      this.startDate  = moment(this.endDate).startOf('jMonth').toDate();
      this.endOfMonth = moment(this.endDate).endOf('jMonth').toDate();
    } else {
      this.startDate  = moment(this.endDate).startOf('month').toDate();
      this.endOfMonth = moment(this.endDate).endOf('month').toDate();
    }
  }

  fillDataTable() {
      this.dateRange = {
        startDate: this.startDate,
        endDate: this.endDate
      };
      this.title.emit('Daily report');
      this.dateRangeSubTitle.emit([this.dateRange, PgDateRangeTitleEnum.persian]);
      forkJoin([
        this._lpgDistService.getDomesticOrganisationReport(
          this._settings.companyId,
          this.startDate.getTime(),
          this.endDate.getTime(), this.endOfMonth.getTime()),
          this._organisationService.get(),
        ]).subscribe(result => {
          this.isDataTablesInitialised = false;
          this.organisations = result[1];
          this.sortedOrganisationsLoadings = result[0].filter(d => d.organisation !== 'iran.parsagroup').map(r => (
            {
              organisation: r.organisation,
              loadings: r.loadings,
              paymentBalances: r.paymentBalances
            })
          );
          this.groupedByRootData = result[0].filter(d => d.organisation !== 'iran.parsagroup').map(r =>
             ({
              organisation: r.organisation,
              discharges: r.discharges.sort((d1, d2) => d1.rootOrganisation > d2.rootOrganisation),
            })
          );
          this.dateRange = {
            startDate: this.startDate,
            endDate: this.endDate
          };
          this.toolbarComponent.dateRange = {
            startDate: this.dateRange.endDate,
            endDate: this.dateRange.endDate
          };
          this.toolbarComponent.visibleOrganisation = true;
          this.toolbarComponent.organisations = this.organisations
          .filter(o =>  ['domestic'].filter(s => o.properties[s]).length > 0);
          this.toolbarComponent.selectedOrganisation =  this.organisations.find(o => o.name === this._settings.companyId);
          this.toolbarComponent.jalali = this._settings.calendar;
          this.toolbarComponent.singleDate = true;
          this.toolbarComponent.itemsSelected.subscribe (t => {
            this.refresh(t);
          });
      });
  }

  organisationFormatter(data: string) {
    return this.organisations.find(o => o.name === data).nameTranslations['fa'];
  }

  daysBetweenTwoTime(later: number, sooner: number) {
    const a = moment(later);
    const b = moment(sooner);
    return a.diff(b, 'days') + 1;
  }

  getSumOfLoadings(organisationLoading: any[]) {
    return {
      sumOfLoading: Math.round(organisationLoading.reduce((p, v) => p + v.total, 0)),
      sumOfDailyLoading: Math.round(organisationLoading.reduce((p, v) => p + v.selectedDayLoading, 0)),
      sumOfEstimate: Math.round(organisationLoading.reduce((p, v) => p + v.estimate, 0)),
      sumOfQuota: Math.round(organisationLoading.reduce((p, v) => p + v.quota, 0)),
      sumOfQuotaToDate: Math.round(organisationLoading.reduce((p, v) => p + v.quotaToDate, 0)),
      sumOfbalance: Math.round(organisationLoading.reduce((p, v) => p + v.balance, 0)),
    };
  }

  getSumOfDischarge(organisationLoading: any[]) {
    return {
      sumOfLoading: Math.round(organisationLoading.reduce((p, v) => p + v.total, 0)),
      sumOfDailyLoading: Math.round(organisationLoading.reduce((p, v) => p + v.selectedDayDischarge, 0)),
      sumOfEstimate: Math.round(organisationLoading.reduce((p, v) => p + v.estimate, 0)),
      sumOfQuota: Math.round(organisationLoading.reduce((p, v) => p + v.quota, 0)),
      sumOfQuotaToDate: Math.round(organisationLoading.reduce((p, v) => p + v.quotaToDate, 0)),
      sumOfLoadingbalance: Math.round(organisationLoading.reduce((p, v) => p + v.balance, 0)),
      sumOfSold: Math.round(organisationLoading.reduce((p, v) => p + v.sold, 0)),
      sumOfStored: Math.round(organisationLoading.reduce((p, v) => p + v.stored, 0)),
    };
  }

  getSumOfAllLoadings() {
    const data = this.sortedOrganisationsLoadings.map(l => this.getSumOfLoadings(l.loadings));
    return {
      sumOfLoading: Math.round(data.reduce((p, v) => p + v.sumOfLoading, 0)),
      sumOfDailyLoading: Math.round(data.reduce((p, v) => p + v.sumOfDailyLoading, 0)),
      sumOfEstimate: Math.round(data.reduce((p, v) => p + v.sumOfEstimate, 0)),
      sumOfbalance: Math.round(data.reduce((p, v) => p + v.sumOfbalance, 0)),
      average: Math.round(data.reduce((p, v) => p + v.sumOfLoading, 0) / this.daysBetweenTwoTime(this.endDate.getTime()
      , this.startDate.getTime())),
      payments: this.sortedOrganisationsLoadings.reduce((p, v) => p + v.paymentBalances, 0)
    };
  }


  xlsxReport() {
    this._lpgDistService.downLoadDailyReportExcel(
      this._settings.companyId,
      this.startDate.getTime(),
      this.endDate.getTime(),
      this.endOfMonth.getTime()
    ).subscribe(b => {
        saveAs(b.body, `${DateTimeHelpers.formatPersianLocalDate(this.dateRange.startDate.getTime())}-to-` +
          `${DateTimeHelpers.formatPersianLocalDate(this.dateRange.endDate.getTime())}-lpg-dist-daily-report.xlsx`);
    }, () => {
        this._pgMessageService.publishMessage({
          level: MessageLevel.Error,
          message: 'Could not download excel report',
          topic: 'Daily Report'
        });
    });
  }

  lpgDistQuotaDataEntry() {
    this._router.navigate(['lpg-dist/quta-entry']).then();
  }

  refresh(item: ToolbarItem) {
    this._settings = {
      companyId: item.companyId,
      calendar: item.selectedCalander
    };
    this._userLocalStorage.save('DailyReportComponent', this._settings);
    this.dateRange = item.dateRange;
    this.setDates();
    this.fillDataTable();
  }
}

<div class="row">
  <div class="col-md-6">
    <div>
      <fieldset style="border: 0;"
        [disabled]="!hasRole('vessels-off-hire-editor')">
        <form class="form-horizontal" #CrewChange="ngForm">
          <div class="card">
            <div class="card-body">
              <div class="form-group row">
                <div class="">
                  <div class="form-group row">
                    <label for="vessel"
                      class="col-form-label col-md-4 ">Vessel</label>
                    <div class="col-md-8">
                      <app-auto-complete
                        name="vessel"
                        id="vessel"
                        [formatter]="vesselFormatter"
                        [(ngModel)]="vessel"
                        [items]="vessels"
                        required>
                      </app-auto-complete>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="status"
                      class="col-form-label col-md-4 ">Status</label>
                    <div class="col-md-8">
                      <select
                      class="form-control"
                      name="status"
                      id="status"
                      [(ngModel)]="status"
                      required>
                      <option value="potential">Potential</option>
                      <option value="claimed">Claimed</option>
                      <option value="accepted">Accepted</option>
                    </select>
                    </div>
                  </div>
                  <div class="form-group row" *ngIf="offHires && offHires.length > 0">
                    <label for="related"
                      class="col-form-label col-md-4 ">Related Off Hire</label>
                    <div class="col-md-8">
                      <app-auto-complete
                        name="related"
                        id="related"
                        [formatter]="offHireFormatter"
                        [(ngModel)]="relatedOffHire"
                        [items]="offHires"
                        >
                      </app-auto-complete>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="type"
                      class="col-form-label col-md-4 ">Type</label>
                    <div class="col-md-8">
                      <select
                      class="form-control"
                      name="type"
                      id="type"
                      [(ngModel)]="offHireType"
                      required>
                      <option value="dryDock">Dry Dock</option>
                      <option value="breakDown">Break Down</option>
                    </select>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="from"
                      class="col-form-label col-md-4 ">From</label>
                      <div class="col-md-4">
                        <app-organisation-selector
                          id="fromPort"
                          class="pg-component"
                          [(organisation)]="fromPort"
                          [organisationLanguage]="'en'"
                          name="fromPort"
                          [organisations]="ports"
                          required>
                        </app-organisation-selector>
                      </div>
                      <div class="col-md-4">
                        <app-date-range
                          id="date"
                          [singleDateSelector]="true"
                          [(dateRange)]="fromDate"
                          [timePicker]="true"
                          [localeDateFormat]="'YYYY-MM-DD HH:mm'"
                          >
                        </app-date-range>
                      </div>
                  </div>
                  <div class="form-group row">
                    <label for="from"
                      class="col-form-label col-md-4 ">To</label>
                      <div class="col-md-4">
                        <app-organisation-selector
                          id="toPort"
                          class="pg-component"
                          [(organisation)]="toPort"
                          [organisationLanguage]="'en'"
                          name="toPort"
                          [organisations]="ports"
                          required>
                        </app-organisation-selector>
                      </div>
                      <div class="col-md-4">
                        <app-date-range
                          id="date"
                          [singleDateSelector]="true"
                          [(dateRange)]="toDate"
                          [timePicker]="true"
                          [localeDateFormat]="'YYYY-MM-DD HH:mm'"
                          >
                        </app-date-range>
                      </div>
                  </div>
                  <ng-container *ngFor="let item of bunkers; let i = index;">
                    <div class="form-group row">
                      <label for="product"
                        class="col-form-label col-md-4 ">Bunker</label>
                      <div class="col-md-4">
                        <select
                          class="form-control"
                          name="Product_{{i}}"
                          id="Product"
                          tabindex="4"
                          [(ngModel)]="bunkers[i].bunkerType"
                          required>
                          <option
                            *ngFor="let product of productName"
                            value="{{ product }}">
                            {{ product }}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-4">
                        <input type="number" class="form-control" [(ngModel)]="bunkers[i].amount" name="item_{{i}}" required>
                      </div>
                    </div>
                  </ng-container>
                  <div class="form-group row">
                    <label for="amount"
                      class="col-form-label col-md-4 ">Amount/USD</label>
                    <div class="col-md-8">
                      <input type="number" class="form-control" name="amount" [(ngModel)]="amount" required>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="licenseNumber"
                      class="col-form-label col-md-4 ">File</label>
                    <div class="col-md-8">
                      <app-chunk-file-uploader
                        [accept]="'.pdf'"
                        name="document"
                        (filesChanged)="uploadDocument($event)">
                      </app-chunk-file-uploader>
                    </div>
                  </div>
                </div>
              </div>
              <div class="btn-group pull-right mt-3">
                <button
                  type="submit"
                  class="btn btn-outline-secondary btn-light  me-1"
                  id="save"
                  [disabled]="!CrewChange.form.valid || !fromDate || !toDate"
                  (click)="save()">
                  Save
                </button>
                <button class="btn btn-outline-secondary btn-light  me-1" (click)="newBunker()">Add Bunker</button>
              </div>
            </div>
          </div>
        </form>
      </fieldset>
    </div>
  </div>
  <div class="col-md-6" *ngIf="documentId">
    <app-pdf-viewer [fileId]="documentId"></app-pdf-viewer>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <div>
      <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
        id="lpgDistDetail"
        class="table table-sm table-striped  table-bordered "
        style="width:100%">
      </table>
    </div>
  </div>
</div>

<div class="row">
  <div class="card col-md-12 overflow-auto vh-100">
    <div class="card-body">
      <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
        id="noonReports"
        class="table table-bordered table-striped justify-content-center">
        <thead class="sticky-top bg-body">
          <tr>
            <td> </td>
            <td>Vessel</td>
            <td>Date</td>
            <td>Voyage Number</td>
            <td>Type</td>
            <td>Lat/Long</td>
            <td>From</td>
            <td>To</td>
            <td>ETA</td>
            <td>Average Speed</td>
            <td>Speed</td>
            <td>Laden/Ballast</td>
            <td>Distance to go</td>
            <td>Distance by ME</td>
            <td>Distance covered</td>
            <td>LSFO consumption</td>
            <td>MGO consumption</td>
            <td>LSFO ROB</td>
            <td>MGO ROB</td>
            <td>Sea force</td>
            <td>Wind force</td>
            <td>Current</td>
            <td>Cargo</td>
            <td>Master</td>
            <!-- <td>comments</td> -->
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let report of reports">
            <td>
              <div *ngIf="report.fileId">
                <a *appHideIfNotInRole="['admin-editor']"
                  (click)="delete(report)" type="submit" class="btn btn-link">
                  <i class="fa fa-trash" aria-hidden="true"></i>
                </a>
                <a (click)="downloadFile(report)" type="submit"
                  class="btn btn-link">
                  <i class="fa fa-file-excel-o" aria-hidden="true"></i>
                </a>
              </div>
            </td>
            <td>{{report.vesselName}}</td>
            <td>{{formatDate(report.date.timestamp)}}</td>
            <td>{{report.voyageDetails.voyageNumber}}</td>
            <td>{{report.reportType}}</td>
            <td>{{report.position.geoCoordinate.latitude |
              number}}/{{report.position.geoCoordinate.longitude | number}}
            </td>
            <td>{{report.voyageDetails.source}}</td>
            <td>{{report.voyageDetails.destination}}</td>
            <td>{{formatDate(report.voyageDetails.eta)}}</td>
            <td>{{report.speed.averageSpeed | number}}</td>
            <td>{{report.speed.speed | number}}</td>
            <td>{{report.voyageDetails.isLaden ? 'Laden': 'Ballast'}}</td>
            <td>{{report.voyageDetails.distanceToGo}}</td>
            <td>{{report.voyageDetails.distanceByMainEngine}}</td>
            <td>{{report.voyageDetails.distanceCovered}}</td>
            <td>{{getTotalBunkerConsumption(report, 'lsfo')}}</td>
            <td>{{getTotalBunkerConsumption(report, 'lsmgo')}}</td>
            <td>{{getRob(report, 'lsfo')}}</td>
            <td>{{getRob(report, 'lsmgo')}}</td>
            <td>{{report.condition.seaForce}}&nbsp;{{report.condition.seaForceDirection}}</td>
            <td>{{report.condition.windForce}}&nbsp;{{report.condition.windForceDirection}}</td>
            <td>{{report.condition.currentForce}}&nbsp;{{report.condition.currentForceDirection}}</td>
            <td>{{formatCargoOnboard(report)}}</td>
            <td>{{report.master}}</td>
            <!-- <td>{{report.comments}}</td> -->
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { SelectedLocation } from '../../services/noon-reports.service';

@Component({
  selector: 'app-fleet-status-toolbar',
  templateUrl: './fleet-status-toolbar.component.html',
  styleUrls: ['./fleet-status-toolbar.component.css']
})
export class FleetStatusToolbarComponent implements OnInit {
  @Output()
  itemsSelected: EventEmitter<SelectedLocation[]> = new EventEmitter<SelectedLocation[]>();
  locations: SelectedLocation[];
  active: boolean;
  visible: boolean;

  ngOnInit() {
    this.active = true;
    this.visible = false;
  }

  onSelectAll() {
    this.active = !this.active;
    this.locations.forEach(i => {
      i.selected = this.active;
    });
    if (this.itemsSelected) {
      this.itemsSelected.emit(this.locations);
    }
  }

  onItemSelectedInternal(i) {
    i.selected = !i.selected;
    if (this.itemsSelected) {
      this.itemsSelected.emit(this.locations);
    }
  }
}

import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MessageLevel, PgMessagesService } from '../services/pg-messages.service';

@Injectable()
export class GlobalHttpInterceptor implements HttpInterceptor {

  constructor(private _messageService: PgMessagesService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          if (!(error.error instanceof ErrorEvent)) {
            switch (error.status) {
              case 404: // notFound
                break;
              case 409: // Conflict
                break;
              case 410: // gone
                break;
              default:
                this._messageService.publishMessage({
                  message: `${error.message} With Code: ${error.status} => ${error.statusText}. Please inform Admin`,
                  topic: 'Oops',
                  level: MessageLevel.Oops
                });
                break;
            }
          }
        } else {
          console.error('some thing else happened');
        }
        return throwError(error);

      })
    );
  }
}


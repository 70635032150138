import { Component, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { LookupService, LookupItem } from 'src/app/shared/services/lookup.service';
import { OrganisationService, Organisation } from 'src/app/organisation/organisation.service';
import { forkJoin } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { DataTablesService } from 'src/app/shared/datatables/data-tables.service';
import { Subject } from 'rxjs';
import {PgTitleComponent} from '../../../shared/components/title-bar/title-bar.component';

@Component({
  selector: 'app-organisation-map',
  templateUrl: './organisation-map.component.html',
})
export class OrganisationMapComponent implements OnInit, PgTitleComponent {
  @ViewChild(DataTableDirective, {static: true})
  dtElement: DataTableDirective;

  title = new EventEmitter<string>();
  organisations: Organisation[];
  notMapped: string[];
  mappedOrganisations: LookupItem;
  organisation: Organisation;
  showDropDown: boolean;
  index: number;

  dtOptions = {};
  dtTrigger: Subject<any> = new Subject<any>();
  isDataTablesInitialised = false;


  constructor(private _lookupService: LookupService,
              private _organisationService: OrganisationService) { }

  ngOnInit() {
    this.title.emit('Kantar Organisations Map');
    this.fillTables();
    this.dtOptions = DataTablesService.getOptionsEn();
  }

  fillTables() {
    forkJoin([
      this._lookupService.getKantarNotMappedOrganisations(),
      this._organisationService.get(),
      this._lookupService.get('mapped_kantar_organisations')
    ]).subscribe(data => {
      this.notMapped = data[0];
      this.organisations = data[1];
      this.mappedOrganisations = data[2];
      this.initialiseTankersDataTables();
    });
  }

  initialiseTankersDataTables() {
    if (!this.isDataTablesInitialised) {
      this.dtTrigger.next(false);
    } else {
      this.dtElement.dtInstance.then((dtInstance) => {
        dtInstance.destroy();
        this.dtTrigger.next(false);
      });
    }
    this.isDataTablesInitialised = true;
  }

  editRecord(i: number) {
    this.index = i;
    this.showDropDown = true;
  }

  saveMapped(kantarOrganisation: string) {
    if (!this.mappedOrganisations) {
      this.mappedOrganisations = {
        categoryName: 'mapped_kantar_organisations',
        items: [[kantarOrganisation, this.organisation.name]]
      };
      this._lookupService.add(this.mappedOrganisations).subscribe(() => {
        this.showDropDown = false;
        this.fillTables();
      });
    } else {
      this.mappedOrganisations.items.push([kantarOrganisation, this.organisation.name]);
      this._lookupService.update(this.mappedOrganisations).subscribe(() => {
        this.showDropDown = false;
        this.fillTables();
      });
    }
  }

}

import { Injectable } from '@angular/core';

import { HttpWrapper } from '../shared/services';
import { Observable } from 'rxjs';


export interface ContractLoadings {
  contractNo: string;
  total: number;
}

export interface ContractLoadingBuyer {
  contractNo: string;
  destination: string;
  total: number;
}


export interface SipmleContract {
  contractNo: string;
  registrationDate: number;
  buyerOrganisation: string;
  transporterOrganisation: string;
  startDate: number;
  expirationDate: number;
  quantity: number;
}



export interface Contract {
  contractNo: string;
  internalContractNo: string;
  registrationDate: number;
  sellerOrganisationId: string;
  buyerOrganisationId: string;
  transporterOrganisationId: string[];
  startDate: number;
  expirationDate: number;
  goodId: string;
  quantity: number;
  destinationOrganisationId: string;
  verify: boolean;
  sourceOrganisationId: string;
}

export interface TempContract {
  contractNo: string;
  buyer: string;
  source: string;
  quantity: number;
}

@Injectable()

export class ContractService {

  constructor(private _httpWrapper: HttpWrapper) { }

  add(contract: Contract): Observable<Contract> {
    return this._httpWrapper.post('contracts', contract);
  }

  update(contract: Contract): Observable<Contract> {
    return this._httpWrapper.put('contracts', contract);
  }

  getHistory(startDate: number, endDate: number): Observable<Contract[]> {
    return this._httpWrapper.get(`contracts/${startDate}/${endDate}`);
  }

  get(contractNo: string): Observable<Contract> {
    return this._httpWrapper.get(`contracts/${contractNo}`);
  }

  getValidContracts(): Observable<Contract[]> {
    return  this._httpWrapper.get(`contracts/valid/x/y/z`);
  }

  getContractBalance(): Observable<[ContractLoadings, ContractLoadings][]> {
    return this._httpWrapper.get(`contracts/balance/x/y`);
  }

  getContractBalancePerBuyer(): Observable<any[]> {
    return this._httpWrapper.get(`contracts/buyer/x/y`);
  }

  getBySource(source: string): Observable<Contract[]> {
    return this._httpWrapper.get(`contracts/source/portal/${source}`);
  }

  getByBuyer(buyer: string): Observable<Contract[]> {
    return this._httpWrapper.get(`contracts-buyer/${buyer}`);
  }

  getBalanceBySource(source: string): Observable<SipmleContract[]> {
    return this._httpWrapper.get(`contracts/kantar/balance/source/${source}`);
  }

}

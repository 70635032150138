import { Injectable } from '@angular/core';
import { HttpWrapper } from '../shared/services';
import { Observable } from 'rxjs';
import { Organisation } from '../organisation/organisation.service';
import { OrganisationService } from '../organisation/organisation.service';
import { Distance } from 'src/app/transportation';


import * as _ from 'lodash';
import { DateRange } from '../shared/components';


export interface Modify {
  insertedOn: number;
  updatedOn: number;
}

export interface LPGDistData {
  company: string;
  companyId: string; // owner organisation
  loadTimestamp: number;
  billOfLadingNumber: string;
  truckNumber: string;
  tankNumber: string;
  source: string;
  sourceOrganisationId: string;
  destinationOrganisationId: string;
  destination: string;
  primaryDestination: string;
  loadingWeight: number;
  unloadTimestamp: number;
  comments: string;
  isDeleted: boolean;
  unload: boolean;
  verify: boolean;
  driverName: string;
  contractNo: string;
  transporterOrganisationId: string;
  modify: Modify;
  foreignKey: string;
}

export interface LpgDistReportSummary {
  company: string;
  name: string;
  totalWeight: number;
  count: number;
  balance: number;
}

export interface LpgDistReportTotalWeight {
  source: string;
  total: number;
}

export interface Quota {
  timestamp: number;
  quotaType: string;
  organisationId: string;
  sourceOrganisationId: string;
  destination: string;
  value: number;
  isDeleted: boolean;
}

export interface Payment {
  company: string;
  companyId: string;
  payTimestamp: number;
  paymentBillNo: string;
  source: string;
  sourceOrganisationId: string;
  totalWeight: number;
  currency: string;
  costPerKilo: number;
  wholePayment: number;
  isDeleted: boolean;
}

export interface LpgDistDistanceCost {
  source: string;
  destination: string;
  totalWeight: number;
  distance: number;
  cost: number;
}

export interface ToolbarItem {
  companyId?: string;
  dateRange?: DateRange;
  selectedCalander?: boolean;
  truckNo?: string;
}

export interface GroupedLoading{
  companyId: string,
  count: number,
  loadings: LPGDistData[]
}


export interface TruckReport{
  truckNumber: string,
  manager: string,
  count: number,
  groupedLoadings: GroupedLoading[]
}


@Injectable()
export class LPGDistService {

  constructor(private _httpWrapper: HttpWrapper,
    private _organisationService: OrganisationService) { }

  add(data: LPGDistData): Observable<LPGDistData> {
    return this._httpWrapper.post('lpg-dist', data);
  }

  getLpgDistRecord(companyId: string, loadTimestamp: number, billOfLadingNumber: string): Observable<LPGDistData> {
    return this._httpWrapper.get<LPGDistData>(`lpg-dist/get-one/${companyId}/${loadTimestamp}/${billOfLadingNumber}`);
  }
  getNiopdcRecord(foreignKeyr: string): Observable<LPGDistData> {
    return this._httpWrapper.get<LPGDistData>(`lpg-dist/niopdc/${foreignKeyr}`);
  }


  addPayment(data: Payment): Observable<Payment> {
    return this._httpWrapper.post('lpg-dist/payments', data);
  }

  addQuota(data: Quota): Observable<Quota> {
    return this._httpWrapper.post('lpg-dist/quota', data);
  }

  updateQuota(item: Quota): Observable<Quota> {
    return this._httpWrapper.put('lpg-dist/quota', item);
  }

  getPaymentHistory(companyId: string, startDate: number, endDate: number): Observable<Payment[]> {
    return this._httpWrapper.get<Payment[]>(`lpg-dist/payments/${companyId}/${startDate}/${endDate}`);
  }

  getOnePaymentRecord(companyId: string, payTimestamp: number, paymentBillNo: string): Observable<Payment> {
    return this._httpWrapper.get<Payment>(`lpg-dist/payments/get-one/${companyId}/${payTimestamp}/${paymentBillNo}`);
  }

  getTotalLoadingPerSource(companyName: string): Observable<LpgDistReportTotalWeight[]> {
    return this._httpWrapper.get<LpgDistReportTotalWeight[]>(`lpg-dist/aggregate/${companyName}`);
  }

  getOrganisation(): Observable<Organisation[]> {
    return this._httpWrapper.get<Organisation[]>('organisations');
  }

  getOrganisationSummary(companyId: string, startDate: number, endDate: number): Observable<LPGDistData[]> {
    return this._httpWrapper.get<LPGDistData[]>(`lpg-dist/${companyId}/${startDate}/${endDate}`);
  }

  getTruckOwnerLpgData(startDate: number, endDate: number): Observable<any[]> {
    return this._httpWrapper.get<LPGDistData[]>(`lpg-dist/organisations/all/${startDate}/${endDate}`);
  }

  getDomesticOrganisationReport(companyName: string, startDate: number, endDate: number, endOfMonth: number): Observable<any[]> {
    return this._httpWrapper.get<LPGDistData[]>(`lpg-dist/domestic/${companyName}/${startDate}/${endDate}/${endOfMonth}`);
  }

  getDomesticOrganisationPaymentsReport(companyName: string, endDate: number): Observable<any[]> {
    return this._httpWrapper.get<any[]>(`lpg-dist/domestic-payments/x/y/z/${companyName}/${endDate}`);
  }

  getAllDomesticOrganisationPaymentsReport(companyName: string, endDate: number): Observable<any[]> {
    return this._httpWrapper.get<any[]>(`lpg-dist/domestic-payments/x/y/z/${companyName}/${endDate}`);
  }

  getQuota(startTime: number, endTime: number): Observable<Quota[]> {
    return this._httpWrapper.get<Quota[]>(`lpg-dist/quota/get/history/${startTime}/${endTime}`);
  }

  getLatestDeterminateQuota(companyId: string, sourceId: string, destitationId: string): Observable<Quota> {
    return this._httpWrapper.get<Quota>(`lpg-dist/quota/${companyId}/${sourceId}/${destitationId}`);
  }
  update(data: LPGDistData): Observable<LPGDistData> {
    return this._httpWrapper.put('lpg-dist', data);
  }

  updatePayment(data: Payment): Observable<Payment> {
    return this._httpWrapper.put('lpg-dist/payments/update', data);
  }

  getTotalWeight(dataTable: LPGDistData[]) {
    return dataTable.reduce(((previousValue, currentValue) => previousValue + currentValue.loadingWeight), 0);
  }

  getTrucksReport(startDate: number, endDate: number): Observable<TruckReport[]> {
    return this._httpWrapper.get(`lpg-dist/trucks/${startDate}/${endDate}`);
  }

  getTrucksReportExcel(startDate: number, endDate: number) {
    return this._httpWrapper.download(`lpg-dist/trucks/excel/${startDate}/${endDate}`);
  }

  downloadLoadingExcel(companyId: string, startTimestamp: number, endTimestamp: number) {
    return this._httpWrapper.download(`lpg-dist/loadings/excel/${companyId}/${startTimestamp}/${endTimestamp}`);
  }

  downloadPaymentsExcel(companyId: string, startTimestamp: number, endTimestamp: number) {
    return this._httpWrapper.download(`lpg-dist/payments/excel/${companyId}/${startTimestamp}/${endTimestamp}`);
  }

  reportSourceSummary(data: LPGDistData[]) {
    const summary: LpgDistReportSummary[] = [];
    const companies = data.map(d => d.company).filter((item, i, cate) => cate.indexOf(item) === i).sort();
    const categories = data.map(d => d.source).filter((item, i, cate) => cate.indexOf(item) === i).sort();
    companies.map(cmpny => {
      categories.map(c => {
        const total = Math.round(data.filter(d => d.source === c && d.company === cmpny)
          .map(d => d.loadingWeight)
          .reduce(((a, b) => a + b), 0));
        const sourceSummary: LpgDistReportSummary = {
          company: cmpny,
          name: c,
          totalWeight: total,
          count: 0,
          balance: 0
        };
        summary.push(sourceSummary);
      });
    });
    return summary;
  }

  reportDestinationSummary(data: LPGDistData[], quta: Quota[]) {
    const categories = data.map(d => d.destinationOrganisationId).filter((item, i, cate) => cate.indexOf(item) === i).sort();
    return categories.map(category => {
      const totalWeight = Math.floor(data.filter(d => d.destinationOrganisationId === category)
      .map(d => d.loadingWeight)
      .reduce(((a, b) => a + b), 0));
      const totalQuota = Math.floor(quta.filter(d => d.destination === category)
      .map(d => d.value)
      .reduce(((a, b) => a + b), 0));
      return {
        name: category,
        totalWeight,
        totalQuota,
        remained: totalQuota - totalWeight
      };
    });
  }

  reportSourceSummary2(data: LPGDistData[], quta: Quota[]) {
    const categories2 = data.map(d => d.sourceOrganisationId)
    .filter((item, i, cate) => cate.indexOf(item) === i)
    .sort();
    const categories = quta.filter(q => q.quotaType ==='destination')
    .map(d => d.sourceOrganisationId)
    .filter((item, i, cate) => cate.indexOf(item) === i)
    .sort();
    const finalCategories = [...new Set([...categories ,...categories2])];
    return finalCategories.map(category => {
      const totalWeight = Math.floor(data.filter(d => d.sourceOrganisationId === category)
      .map(d => d.loadingWeight)
      .reduce(((a, b) => a + b), 0));
      const totalQuota = Math.floor(quta.filter(d => d.sourceOrganisationId === category && d.quotaType ==='destination')
      .map(d => d.value)
      .reduce(((a, b) => a + b), 0));
      return {
        name: category,
        totalWeight,
        totalQuota,
        remained: totalQuota - totalWeight
      };
    });
  }

  reportTruckSummary(data: any[]) {
    const grouped = _.groupBy(data, (item) =>
      `${item.truckOwnerOrganisationId}§${item.truckOwnerOrganisationName}§${item.lpgData.truckNumber}§${item.lpgData.tankNumber}`);
    return _(grouped).map((items, key) => {
      const keys = key.split('§');
      return {
        organisationName: keys[1],
        truckNumber: keys[2],
        tankNumber: keys[3],
        totalWeight: _.sumBy(items, i => i.lpgData.loadingWeight),
        count: _.size(items)
      };
    }).value();
  }

  delete(data: LPGDistData) {
    return this._httpWrapper.delete(`lpg-dist/${data.companyId}/${data.billOfLadingNumber}/${data.loadTimestamp}`);
  }

  correctTimestamp(date: Date): number {
    return date.getTime() - (date.getHours() * 3600
      + date.getMinutes() * 60
      + date.getSeconds()) * 1000
      - date.getMilliseconds();
  }

  reportSourceDestinationSummary(data: any[]) {
    const grouped = _.groupBy(data, (item) =>
      `${item.sourceOrganisationId}§${item.destinationOrganisationId}`);
    return _(grouped).map((items, key) => {
      const keys = key.split('§');
      return {
        sourceOrganisationId: keys[0],
        destinationOrganisationId: keys[1],
        totalWeight: _.sumBy(items, i => i.loadingWeight),
      };
    }).value();
  }

  reportSumDeteminatedQuota(data: Quota[]) {
    const grouped = _.groupBy(data, (item) =>
      `${item.sourceOrganisationId}§${item.destination}`);
    return _(grouped).map((items, key) => {
      const keys = key.split('§');
      return {
        sourceOrganisationId: keys[0],
        destinationOrganisationId: keys[1],
        value: _.sumBy(items, i => i.value),
      };
    }).value();
  }

  reportSourcePrimaryDestinationSummary(data: LPGDistData[]) {
    const filteredData = data.filter(d => d.primaryDestination);
    const grouped = _.groupBy(filteredData, (item) =>
      `${item.sourceOrganisationId}§${item.primaryDestination}`);
    return _(grouped).map((items, key) => {
      const keys = key.split('§');
      return {
        sourceOrganisationId: keys[0],
        primaryDestinationOrganisationId: keys[1],
        totalWeight: _.sumBy(items, i => i.loadingWeight),
      };
    }).value();
  }


  sourceDestinationSummary(
    data: LPGDistData[],
    organisations: Organisation[],
    distances: Distance[],
    quotas: Quota[]) {
    const summary = this.reportSourceDestinationSummary(data);
    const primaryDestinationSummary = this.reportSourcePrimaryDestinationSummary(data);
    const distanceMap = distances.reduce((obj, item) => {
      obj[item.originOrganisationId + item.destinationOrganisationId] =  Math.round(item.distance / 1000);
      return obj;
      }, {});


    const determinatedQuota =  this.reportSumDeteminatedQuota(quotas.filter(x => x.quotaType === 'destination'));
    const quotaMap = determinatedQuota
    .reduce((obj, item) => {
      obj[item.sourceOrganisationId + item.destinationOrganisationId] = item.value;
      return obj;
    }, {});
    const merged = [...summary, ...determinatedQuota].map(m => ({
      sourceOrganisationId: m.sourceOrganisationId,
      destinationOrganisationId: m.destinationOrganisationId
    })).filter((value, index, self) =>
      index === self.findIndex((t) => (
        t.sourceOrganisationId === value.sourceOrganisationId && t.destinationOrganisationId === value.destinationOrganisationId
      ))
    );
    const loadingMap = summary.reduce((obj, item) => {
      obj[item.sourceOrganisationId + item.destinationOrganisationId] = item.totalWeight;
      return obj;
    }, {});

    const primaryDestinationMap = primaryDestinationSummary
    .reduce((obj, item) => {
      obj[item.sourceOrganisationId + item.primaryDestinationOrganisationId] = item.totalWeight;
      return obj;
    }, {});
    return merged.map(s => {
      const source = organisations.find(o => o.name === s.sourceOrganisationId);
      const destination = organisations.find(o => o.name === s.destinationOrganisationId);
      if (source && destination) {
        const calculatedDistance = distanceMap[source.name + destination.name];
        const calculatedQuota = quotaMap[source.name + destination.name];
        const primaryDestinationWeight = primaryDestinationMap[source.name + destination.name];
        const totalWeight = loadingMap[source.name + destination.name];
          return {
            source: source.name,
            destination: destination.name,
            totalWeight,
            primaryWeight: primaryDestinationWeight,
            distance: calculatedDistance,
            quota: calculatedQuota,
            remaind: (calculatedQuota)? calculatedQuota - primaryDestinationWeight: null ,
            cost: calculatedDistance * totalWeight
          };
      }
    });
  }

  downLoadDailyReportExcel(companyName: string, startDate: number, endDate: number, endOfMonth: number) {
    return this._httpWrapper.download(`lpg-dist/domestic/excel/${companyName}/${startDate}/${endDate}/${endOfMonth}`);
  }

  importLpgDistFile(organisation: string, fileList: FileList, price: string): Observable<LPGDistData[]> {
    return this._httpWrapper.postMultiPart(`lpg-dist/file/${organisation}/${price}`, this.toFormData(fileList));
  }

  importQuotaFile(fileList: FileList): Observable<Quota[]> {
    return this._httpWrapper.postMultiPart('lpg-dist/quota/file',
      this.toFormData(fileList));
  }

  importPaymentFile(fileList: FileList, companyId: string, wholePayment: string): Observable<Payment[]> {
    return this._httpWrapper.postMultiPart(`lpg-dist/payments/file/${companyId}/${wholePayment}`,this.toFormData(fileList));
  }


  private toFormData(fileList: FileList) {
    const formData = new FormData();
    formData.append('file', fileList.item(0));
    return formData;
  }


}

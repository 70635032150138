import { Component, OnInit, ViewChild, NgZone, EventEmitter } from '@angular/core';
import { Organisation, OrganisationService } from '../organisation.service';
import { DataTableDirective } from 'angular-datatables';
import { DataTablesService } from './../../shared/datatables/data-tables.service';
import { Subject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { PgTitleComponent } from '../../shared/components/title-bar/title-bar.component';

@Component({
  selector: 'app-organisations-list',
  templateUrl: './organisations-list.component.html',
})
export class OrganisationsListComponent implements OnInit, PgTitleComponent {
  @ViewChild(DataTableDirective, {static: true})
  dtElement: DataTableDirective;

  title = new EventEmitter<string>();
  organisations: Organisation[];
  temporarlyList: Organisation[];
  dtOptions = {};
  dtTrigger: Subject<any> = new Subject<any>();
  isDataTablesInitialised = false;

  parentOrganisation = '';

  constructor(private _organisatiosnService: OrganisationService,
              private _router: Router,
              private _zone: NgZone,
              private _activatedRoute: ActivatedRoute) {
                window['OrganisationsListComponent'] = {component: this, zone: this._zone};
              }

  columnsFormatter() {
    return  [
        {
          title:'نام فارسی',
          data: '',
          render: (data, type, row) => row.nameTranslations['fa']
        },
        {
          title:'نام انگلیسی',
          data: 'source',
          render: (data, type, row) => row.nameTranslations['en']
        },
        {
          title:'آدرس',
          data: 'address',
        },
        {
          title:'Timezone',
          data: 'timezone',
        },
        {
          title:'',
          render: () => this.editNavigationButton()
        },
      ];
  }

  editNavigationButton() {
    return ` <button id="filter" name="filter" type class="btn btn-link">
                <i class="fa fa-code-branch"></i>
              </button>
              <button id="add" name="add" type class="btn btn-link">
                <i class="fa fa-plus"></i>
              </button>
              <button id="edit" name="edit" type class="btn btn-link">
                <i class="fa fa-edit"></i>
              </button>`;
  }



  ngOnInit() {
    this.title.emit('Organisations List');
    const buttons = [{
      text: 'ایجاد سازمان جدید',
      action: () => window['OrganisationsListComponent'].component.addRecord()
      }];
    this.dtOptions = DataTablesService.getOptions(buttons);
    this._activatedRoute.paramMap.subscribe(p => {
      this._organisatiosnService.get().subscribe(organisations => {
        this.organisations = organisations;
        const organisationId = p.get('parentOrganisationId');
        this.temporarlyList = (organisationId) ?
          organisations.filter(organisation => organisation.parentOrganisation === organisationId) :
          organisations.filter(organisation => organisation.parentOrganisation === '');
        this.datatableInit(this._router, this.edit, this.add, this.organisationFilter);
      });
    });
  }

  addRecord() {
    this._router.navigate(['/organisations/new/root']).then(_ => _);
  }

  datatableInit(
    router: Router,
    edit: (organisation: Organisation, router: Router) => void,
    add: (organisation: Organisation, router: Router) => void,
    filter: (organisation: Organisation, router: Router) => void,
  ) {
    this.dtElement.dtOptions.columns = this.columnsFormatter();
    this.dtElement.dtOptions.columnDefs = [{targets: '_all', defaultContent: ''}];
    if (!this.isDataTablesInitialised) {
      this.dtTrigger.next(false);
    }
    this.dtElement.dtInstance.then((dtInstance) => {
      dtInstance.clear().draw();
      dtInstance.rows.add(this.temporarlyList).draw();
      dtInstance.on('click', 'tbody tr td button', function () {
        if (this.id === 'edit') {
          edit(dtInstance.row(this.closest('tr')).data(), router);
        } else if (this.id === 'add') {
          add(dtInstance.row(this.closest('tr')).data(), router);
        } else if (this.id === 'filter') {
          filter(dtInstance.row(this.closest('tr')).data(), router);
        }
      });
    });
    this.isDataTablesInitialised = true;
  }

  organisationFilter(organisation: Organisation, router: Router) {
    router.navigate(['organisations/list', organisation.name]).then();
  }

  edit(organisation: Organisation, router: Router) {
    router.navigate(['organisations', 'edit', organisation.name]).then();
  }

  add(organisation: Organisation, router: Router) {
    router.navigate(['organisations', 'new', organisation.name]).then();
  }
}

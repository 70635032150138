import { Component, EventEmitter, OnInit } from '@angular/core';
import { Organisation, OrganisationService } from 'src/app/organisation/organisation.service';
import { Vessel, VesselsService } from '../../vessels.service';
import { forkJoin } from 'rxjs';
import { PgMessagesService, MessageLevel } from 'src/app/shared/services/pg-messages.service';
import { ActionType, DataBaseActionService } from 'src/app/shared/services/database-action.service';
import { AuditReport, AuditsReportsService } from '../../audits-reports.service';
import { DocumentReference } from '../../../../documents/documents.service';
import { TimestampWithZone } from '../../../../shared/components/date-time-zone/date-time-zone.component';
import { DateTimeHelpers } from '../../../../shared/helpers/date-time.helpers';
import {KeycloakService} from '../../../../authentication/keycloak.service';

@Component({
  selector: 'app-vessel-internal-audit',
  templateUrl: './vessel-internal-audit.component.html',
})
export class VesselInternalAuditComponent implements OnInit {
  vessels: Vessel[];
  _selectedVessel: Vessel;

  get selectedVessel() {
    return this._selectedVessel;
  }

  set selectedVessel(vessel: Vessel) {
    if (vessel) {
      this._selectedVessel = vessel;
      this.auditReport.vesselName = vessel.name.name;
      this.auditReport.vesselIMO = vessel.IMO;
    }
  }

  locations: Organisation[];
  _selectedLocation: Organisation;
  get selectedLocation() {
    return this._selectedLocation;
  }

  set selectedLocation(value: Organisation) {
    if(value) {
      this._selectedLocation = value;
      this.auditReport.locationOrganisationId = value.name;
      this.auditDate = {
        timestamp: null,
        timeZone: value.timezone
      };
    }
  }

  _auditDate: TimestampWithZone;
  maxAuditReportDate = new Date();
  get auditDate() {
    return this._auditDate;
  }

  set auditDate(value: TimestampWithZone) {
    if(value) {
      this._auditDate = value;
      this.auditReport.isoDate = DateTimeHelpers.getISODateStringZoneId(new Date(value.timestamp), value.timeZone);
    }
  }

  uploadedDocument: DocumentReference;
  auditReport: AuditReport = {
    vesselIMO: null,
    vesselName: null,
    isoDate: null,
    locationOrganisationId: null,
    surveyorId: "",
    surveyorName: "",
    documentId: null,
    overallRating: 0,
    actions: [],
  };

  title = new EventEmitter<string>();

  constructor(private _organisationService: OrganisationService,
              private _vesselsService: VesselsService,
              private _pgMessagesService: PgMessagesService,
              private _actionService: DataBaseActionService,
              private _keycloakService: KeycloakService,
              private _auditService: AuditsReportsService) { }

  ngOnInit(): void {
    this.title.emit('Add internal audit');
    forkJoin([
      this._organisationService.getPorts(),
      this._vesselsService.getActiveVessels(),
    ])
      .subscribe(results => {
        this.locations = results[0];
        this.vessels = results[1];
      });
  }

  vesselsFormatter(vessel: Vessel) {
    if (vessel) {
      return vessel.name.name;
    }
  }

  documentFileAdded(doc: DocumentReference) {
    this.uploadedDocument = doc;
    this.auditReport.documentId = doc.id;
  }

  save() {
    this.auditReport.actions.push(this._actionService.userAction(ActionType.Update));
    this.auditReport.surveyorId = this._keycloakService.getUser().username;
    this.auditReport.surveyorName = this._keycloakService.getUser().fullName;
    this._auditService.add(this.auditReport).subscribe({ next: () => {
      this._pgMessagesService.publishMessage({
        level: MessageLevel.Info,
        topic: 'Vessel',
        message: `An audit report is added.`
      });
      // this._router.navigate(['vessel', this.vessel.IMO]).then(_ => {});
    }, error: () => {
      this._pgMessagesService.publishMessage({
        level: MessageLevel.Info,
        topic: 'Vessel',
        message: `Error occurred while adding an audit report.`
      });
    }
    });
  }
}

import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {SofTemplate, SofTemplateDefinitionEntry, SofTemplateService} from '../sof-templates/sof-template.service';
import {forkJoin} from 'rxjs';
import {MessageLevel, PgMessagesService} from '../../../shared/services/pg-messages.service';

@Component({
  selector: 'app-sof-template',
  templateUrl: './sof-template.component.html'
})
export class SofTemplateComponent implements OnInit {
  mode = 'new';
  id: string;
  sofTemplate: [SofTemplateDefinitionEntry, string][];
  allSofEntries: [SofTemplateDefinitionEntry, string][];
  unusedSofEntries: [SofTemplateDefinitionEntry, string][];

  selectedUnusedSofEntries: [SofTemplateDefinitionEntry, string][];
  selectedUsedSofEntries: [SofTemplateDefinitionEntry, string][];
  _selectedSofType: string;
  swap: any; // doing this because I liked to see how javascript can do functional

  get selectedSofType() {
    return this._selectedSofType;
  }
  set selectedSofType(value: string) {
    if (value.toLowerCase().startsWith('l')) {
      value = 'loading';
    } else {
      value = 'discharge';
    }
    this._selectedSofType = value;

    if (this.mode === 'new') {
      this.sofTemplate = [];

      let firstLetter = 'D';
      if (value === 'loading') {
        firstLetter = 'L';
      }
      this.unusedSofEntries = this.allSofEntries
        .filter(s => s[0].code.startsWith(firstLetter))
        .filter(s => !this.sofTemplate.find(s1 => s1[0].code === s[0].code))
        .sort((a, b) =>
          (a[0].code > b[0].code) ? 1 : -1);
    }
  }

  constructor(private _activatedRoute: ActivatedRoute,
              private _sofTemplateService: SofTemplateService,
              private _pgMessagesService: PgMessagesService) {
    this.swap = (x, y) => ([...xs]) => xs.length > 1
      ? ([xs[x], xs[y]] = [xs[y], xs[x]], xs)
      : xs;
  }

  ngOnInit() {
    this._activatedRoute.paramMap.subscribe(p => {
      this.id = p.get('id');
      if (this.id) {
        this.mode = 'edit';
        forkJoin([this._sofTemplateService.getSofTemplate(this.id),
        this._sofTemplateService.getAllSofItems()]).subscribe(results => {
          this.sofTemplate = results[0];
          const firstLetter = this.sofTemplate[0][0].code[0];
          this.selectedSofType = firstLetter;
          this.unusedSofEntries = results[1]
            .filter(s => s[0].code.startsWith(firstLetter))
            .filter(s => !this.sofTemplate.find(s1 => s1[0].code === s[0].code));
            // .sort((a, b) =>
            //   (a[0].code > b[0].code) ? 1 : -1);
        });
      } else {
        this.mode = 'new';
        this._sofTemplateService.getAllSofItems().subscribe(s => {
          this.allSofEntries = s;
        });
      }
    });
  }

  verifyAdd() {
    let isRequired = false;
    if (confirm(`Are these items required in the template?`)) {
      isRequired = true;
    }

    for (const i of this.selectedUnusedSofEntries) {
      i[0].isRequired = isRequired;
      this.sofTemplate.push(i);
    }

    for (const i of this.selectedUnusedSofEntries) {
      this.unusedSofEntries.splice(this.unusedSofEntries.indexOf(i), 1);
    }

    // this.sofTemplate.sort(
    //   (a, b) => a[0].code > b[0].code ? 1 : -1);
  }

  verifyRemove() {
    for (const i of this.selectedUsedSofEntries) {
      this.unusedSofEntries.push(i);
    }

    for (const i of this.selectedUsedSofEntries) {
      this.sofTemplate.splice(this.sofTemplate.indexOf(i), 1);
    }
  }

  updateTemplate(template: SofTemplate) {
    this._sofTemplateService.updateTemplate(template).subscribe(s => {
      this._pgMessagesService.publishMessage({
        level: MessageLevel.Info,
        topic: `Sof Template`,
        message: `Updated an Sof Template with Id: ${s.id}`
      });
    }, () => {
      this._pgMessagesService.publishMessage({
        level: MessageLevel.Error,
        topic: `Sof Template`,
        message: `Failed to updated an Sof Template with Id: ${template.id}`
      });
    });
  }

  addTemplate(template: SofTemplate) {
    this._sofTemplateService.addTemplate(template).subscribe(s => {
      this._pgMessagesService.publishMessage({
        level: MessageLevel.Info,
        topic: `Sof Template`,
        message: `Added an Sof Template with Id: ${s.id}`
      });
    }, () => {
      this._pgMessagesService.publishMessage({
        level: MessageLevel.Error,
        topic: `Sof Template`,
        message: `Failed to add an Sof Template with Id: ${template.id}`
      });
    });
  }

  save() {
    let identication = this.id;
    if (this.mode === 'new') {
      identication = this.id.split(' ').map(s => s.trim()).join('_');
    }
    const template: SofTemplate = {
      id: identication,
      sofType: this.sofTemplate[0][0].code[0] === 'L' ? 'loading' : 'discharge',
      entries: this.sofTemplate.map(s => s[0])
    };

    if (this.mode === 'new') {
      this.addTemplate(template);
    } else {
      this.updateTemplate(template);
    }
  }

  up() {
    for (const i of this.selectedUsedSofEntries) {
      const currentIndex = this.sofTemplate.indexOf(i);
      if (currentIndex !== 0) {
        this.sofTemplate = this.swap(currentIndex, currentIndex - 1)(this.sofTemplate);
      }
    }
  }

  down() {
    for (const i of this.selectedUsedSofEntries) {
      const currentIndex = this.sofTemplate.indexOf(i);
      if (currentIndex !== this.sofTemplate.length) {
        this.sofTemplate = this.swap(currentIndex, currentIndex + 1)(this.sofTemplate);
      }
    }
  }
}

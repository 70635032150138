import { Component, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MapToolbarItem, OrganisationIdColour, Truck } from 'src/app/transportation/trucks/trucks.service';
import { Organisation, OrganisationService } from 'src/app/organisation/organisation.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { KeycloakService } from 'src/app/authentication/keycloak.service';
import { DateRange } from 'src/app/shared/components';

@Component({
  selector: 'app-map-toolbar',
  templateUrl: './map-toolbar.component.html'
})
export class MapToolbarComponent implements OnDestroy {
  @Output()
  selectedOrganisationId: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  recenter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  zoomOut: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  showOutOfReachTruck: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  showAvailableTrucks: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  colorCodeByOrganisation: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  colorCodeByTimestamp: EventEmitter<number> = new EventEmitter<number>();
  @Output()
  dateRange: EventEmitter<DateRange> = new EventEmitter<DateRange>();
  @Output()
  clusterTrucks: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  selectedTruck: EventEmitter<Truck> = new EventEmitter<Truck>();

  _numberOfUnavailableTrucks: number;
  _numberOfAvailableTrucks: number;
  _showInformationPanel: boolean;
  _colorCodeByOrganisation = false;
  _colorCodeByTimestam: number;
  _clusterTrucks = false;
  _selectedOrganisationId: string;
  _selectedTruck: Truck;
  organisationsColours: OrganisationIdColour[];
  showTools: boolean;
  trucks: Truck[];
  filteredTruks: Truck[];
  _organisationsCache: {
    [key: string]: Organisation;
  } = {};
  daterange: DateRange;


  showReportLink = false;


  toolbarItem: MapToolbarItem;

  private eventsSubscription: Subscription;

  constructor(private _organisationService: OrganisationService,
    private _keycloakService: KeycloakService,
              private _router: Router) { }

  ngOnDestroy() {
    if (this.eventsSubscription) {
      this.eventsSubscription.unsubscribe();
    }
  }

  formatOrganisation(organisationId: string) {
    if (organisationId) {
      const foundOrganisation = this._organisationsCache[organisationId];
      if (foundOrganisation) {
        return foundOrganisation.nameTranslations['fa'];
      } else {
        return '';
      }
    }
  }

  truckListFormatter(truck: Truck) {
    const driver = (typeof truck.driver === 'undefined') ? 'N/A' : truck.driver.associatedObject.name;
    const tanker = (typeof truck.tanker === 'undefined') ? 'N/A' : truck.tanker.associatedObject.number;
    return truck.formattedRegistrationNumber + '***' + tanker + '***' + driver;
  }

  truckValueFormatter(truck: Truck) {
    return truck.formattedRegistrationNumber;
  }

  tools(t: string) {
    if (t === 'show') {
      this.showTools = true;
    } else {
      this.showTools = false;
    }
  }


  showAll() {
    this.showReportLink = false;
    this.recenter.emit();
  }

  zoomOutMap() {
    this.zoomOut.emit();
  }

  showOutOfReach() {
    this.showOutOfReachTruck.emit();
  }

  showAvailables() {
    this.showAvailableTrucks.emit();
  }

  date(dr: DateRange) {
    if(dr) {
      this.daterange = dr;
      this.dateRange.emit(this.daterange);
    }
  }

  selectOrganisation() {
    if (this._selectedOrganisationId) {
      this.filteredTruks = this.trucks
      .filter(truck => truck.organisationId.associatedObject === this._selectedOrganisationId);
    } else {
      this.filteredTruks = this.trucks;
    }
    this.selectedOrganisationId.emit(this._selectedOrganisationId);
  }


  colorCodeOrganisation() {
    this._colorCodeByOrganisation = !this._colorCodeByOrganisation;
    this.colorCodeByOrganisation.emit(this._colorCodeByOrganisation);
  }

  colorCodeTimestamp() {
    this.colorCodeByTimestamp.emit(this._colorCodeByTimestam);
  }

  cluster() {
    this._clusterTrucks = !this._clusterTrucks;
    this.clusterTrucks.emit(this._clusterTrucks);
  }

  selectTruck(truck: Truck) {
    if (truck && truck.formattedRegistrationNumber) {
      this.showReportLink = true;
      this._selectedTruck = truck;
      this.selectedTruck.emit(truck);
    }
  }

  reportInDetail() {
    const now = new Date().getTime();
    const aWeekBefore = now - 48 * 3600 * 1000;
    this._router.navigate(
      ['/trucks-report',
      this._selectedTruck.tracker.associatedObject.imei,
      aWeekBefore,
      now
    ]);
  }

}

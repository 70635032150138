import { Component, EventEmitter, OnInit } from '@angular/core';
import { Vessel, VesselsService } from '../../vessels.service';
import { forkJoin } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentReference, DocumentService } from 'src/app/documents/documents.service';
import { KeycloakService } from 'src/app/authentication/keycloak.service';
import { saveAs } from 'file-saver';
import { PgMessagesService, MessageLevel } from 'src/app/shared/services/pg-messages.service';
import { SlideshowComponent } from 'src/app/shared/components/slideshow/slideshow.component';
import { DateRange } from 'src/app/shared/components';
import { DataBaseActionService, ActionType } from 'src/app/shared/services/database-action.service';
import { Organisation, OrganisationService } from 'src/app/organisation/organisation.service';

@Component({
  selector: 'app-vessel-information',
  templateUrl: './vessel-information.component.html',
})
export class VesselInformationComponent implements OnInit {
  title = new EventEmitter<string>();
  fileId: string;
  vessel: Vessel;
  newItem: boolean;
  role: boolean;
  disabled: boolean;
  currentFiles: FileList;
  currentForms: FileList;
  uploadedFileId: string;
  selectedDocumentType: string;
  endDateForCurrentPeriod: DateRange = {
    startDate: new Date(),
    endDate: new Date()
  };
  issueDate = {
    startDate: new Date(),
    endDate: new Date(),
  };
  type: string;
  index: number;
  slideShowType = SlideshowComponent;
  slideShow: SlideshowComponent;
  removeFileId: string;
  newName: string;
  flags: Organisation[];
  flag: Organisation;
  q88FileId: string;


  constructor(
    private _router: Router,
    private _activateRoute: ActivatedRoute,
    private _vesselsService: VesselsService,
    private _documentService: DocumentService,
    private _actionService: DataBaseActionService,
    private _keycloakService: KeycloakService,
    private _documentsService: DocumentService,
    private _organisationService: OrganisationService,
    private _pgMessagesService: PgMessagesService,
  ) { }

  ngOnInit(): void {
    this._activateRoute.params.subscribe((params) => {
      const imo = params['imo'];
      if (imo) {
        this._vesselsService.getVessel(imo).subscribe(x => this.title.emit(`Information ${x.name.name}`));
        forkJoin([
          this._vesselsService.getVessel(imo),
          this._organisationService.get()
        ]).subscribe((result) => {
          this.hasRole('vessels-editor');
          this.vessel = result[0];
          this.vessel.documents = result[0].documents
            .filter(d => !d.isDeleted)
            .sort((d1, d2) => d2.issueDate - d1.issueDate);
          this.vessel.previousNames = result[0].previousNames.sort((n1, n2) => n2.from - n1.from);
          this.flags = result[1].filter(x => x.properties['country'] === true);
        });
      }
    });
  }

  hasRole(roleName: string) {
    if (this._keycloakService.hasRole(roleName)) {
      return this.role = true;
    } else {
      return this.role = false;
    }
  }

  downloadForm(type: string) {
    if (this.vessel) {
      const link = this.vessel.documents.filter(doc => doc.documentType === type);
      if (link.length > 0) {
        this._documentService.download(link[0].documentId).subscribe(f => {
          saveAs(f.body, `${this.vessel.name.name}_${type}.pdf`);
        });
      }
    }
  }

  deleteMode(id: string) {
    const b = this.vessel.spec.photoIds.indexOf(id);
    if (confirm(`Are you sure you want to delete this Photo?`)) {
      this.vessel.spec.photoIds.splice(b, 1);
      this.onSubmit();
      }
  }

  uploadForm(form: DocumentReference) {
    const documents = {
      documentType: this.type,
      documentId: form.id,
      issueDate: this.issueDate.startDate.getTime()
    };
    this.vessel.documents.push(documents);
    this.currentForms = null;
  }

  changeName() {
    if (!this.vessel.previousNames) {
      this.vessel.previousNames = [];
    } else {
      const temp = this.vessel.name;
      this.vessel.previousNames.push(temp);
    }
    this.vessel.name.from = this.endDateForCurrentPeriod.startDate.getTime();
    this.vessel.name.flag = this.flag.name;
    this.vessel.name.name = this.newName;
    this.onSubmit();
  }

  onSubmit() {
    this.vessel.actions.push(this._actionService.userAction(ActionType.Update));
    this._vesselsService.updateVessel(this.vessel).subscribe(
      (s) => {
        this.vessel = s;
        this.vessel.documents = s.documents
          .filter(d => !d.isDeleted)
          .sort((d1, d2) => d2.issueDate - d1.issueDate);
        this.vessel.previousNames = s.previousNames.sort((n1, n2) => n2.from - n1.from);
        this._pgMessagesService.publishMessage({
          level: MessageLevel.Info,
          topic: 'Vessel',
          message: `The vessel ${this.vessel.IMO} information edited.`,
        });
        this._router.navigate(['vessels/vessel-information/', this.vessel.IMO]).then();
      },
      () => {
        this._pgMessagesService.publishMessage({
          level: MessageLevel.Error,
          topic: 'Vessel',
          message: 'There is a problem to add this vessel.',
        });
      }
    );
  }

  savePhoto(docReference: DocumentReference) {
    this.fileId = docReference.id;
    if (this.vessel.spec.photoIds === undefined) {
      this.vessel.spec.photoIds = [];
      this.vessel.spec.photoIds[0] = this.fileId;
    } else {
      const a = this.vessel.spec.photoIds.length;
      this.vessel.spec.photoIds[a] = this.fileId;
    }
    this.currentFiles = null;
  }

  downloadDocumentId(id: string) {
    const a = id.split('/').slice(-1)[0];
    this._documentsService.download(a).subscribe(b => {
      saveAs(b.body, `${this.vessel.name.name}.jpg`);
    },
      () => {
        this._pgMessagesService.publishMessage({
          message: 'Could not download this file.',
          level: MessageLevel.Error,
          topic: 'Voyage document'
        });
      });
  }

  downloadformId(id: string, type: string) {
    this._documentService.download(id).subscribe(b => {
      saveAs(b.body, `${this.vessel.name.name}_${type}.pdf`);
    }, () => {
      this._pgMessagesService.publishMessage({
        message: 'Could not download this file.',
        level: MessageLevel.Error,
        topic: 'Voyage document'
      });
    });
  }

  removeDocument(id: string) {
    if (confirm(`Are you sure you want to delete the document?`)) {
      this.vessel.documents.find(d => d.documentId === id).isDeleted = true;
      this.onSubmit();
    }
  }
}

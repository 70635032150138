<div class="row">
  <div class="col-md-6">
    <div class="card card-body">
      <style>
        .table tbody tr.highlight td {
          background-color: #ddd;
        }
      </style>
      <table
        id="infoTable"
        datatable
        [dtOptions]="dtOptions"
        [dtTrigger]="dtTrigger"
        class="table table-sm table-striped table-bordered dt-responsive"
        style="width: 100%;"
      >
        <thead>
          <tr>
            <th></th>
            <th>Name</th>
            <th>Issue Date</th>
            <th>End Date</th>
            <th *appHideIfNotInRole="['vessels-certificates-editor']">File</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of vessel?.certificates; let i = index">
            <td class="text-center" >
              <a
                id="save"
                *ngIf="index === i"
                class="fa fa-save me-1"
                type="button"
                (click)="saveCertificate(i)"
              >
              </a>
              <a
                id="cancel"
                *ngIf="index === i && !newItem"
                (click)="editMode(i, 'cancel')"
                class="fa fa-times me-1"
                aria-hidden="true"
              ></a
              >
              <a
                id="delete"
                *ngIf="hasRole('admin-editor') && index === i"
                class="fa fa-trash me-1"
                (click)="editMode(i, 'delete')"
                aria-hidden="true"
              >
              </a>
              <a
                *ngIf="index !== i && !newItem && hasRole('vessels-certificates-editor')"
                class="btn btn-link me-1"
                type="button"
                (click)="editMode(i, 'edit')"
                [disabled]="!hasRole('vessels-certificates-editor')"
              >
              <i class="fa fa-pencil-square-o"></i>
              </a>
                <a
                  (click)="downloadFile(item.info.fileId, vessel?.name.name)"
                  *ngIf="item.info.fileId"
                >
                  <i class="fa fa-download" aria-hidden="true"></i>
                </a>
            </td>
            <td class="text-center" >
              <div>
                <i type="button" class="btn btn-link" (click)="viewPDF(i)">
                {{ maxLength (item.name) }}
              </i>
            </div>
            </td>
            <td class="text-center">
              <div *ngIf="index !== i">
                {{ dateTime(item.info.issueDate) }}
              </div>
              <app-date-range
                *ngIf="index === i"
                id="certificates{{ i }}"
                name="certificates{{ i }}"
                [singleDateSelector]="true"
                [(dateRange)]="isuueDate"
                appDateRangeRequired="true"
              ></app-date-range>
            </td>
            <td class="text-center">
              <div *ngIf="index !== i">
                {{dateTime(item.info.expirationDate)}}&nbsp;
                ({{duration(item.info.expirationDate)}})
              </div>
              <app-date-range
                *ngIf="index === i"
                id="certificates{{ i }}"
                name="certificates{{ i }}"
                [(dateRange)]="expirationDate"
                [singleDateSelector]="true"
                appDateRangeRequired="true"
                >
              </app-date-range>
            </td>
            <td *appHideIfNotInRole="['vessels-certificates-editor']" class="text-center">
                <app-file-uploader
                  [accept]="'.pdf'"
                  id="certificates{{ i }}"
                  name="certificates{{ i }}"
                  [disabled]="index !== i"
                  [(ngModel)]="currentFiles[i]"
                >
                </app-file-uploader>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="card" *ngIf="showAddItem">
      <div class="card-body row">
        <label for="capacity" class="col-form-label col-md-3"
          >Add New Item</label
        >
        <div class="col-md-8">
            <app-auto-complete
            [formatter]="lookupItemListFormatter"
            [(ngModel)]="certificateItem"
            [items]="certificatesListToShow.items"
            name="truck">
          </app-auto-complete>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="fileId" class="col-md-6">
    <div class="card">
      <h5 style="font-weight: bold" class="card-header">
        {{name}}
      </h5>
      <div class="card-body">
        <ul class="list-group list-group-flush">
          <li class="list-group-item">
            <table class="table table-sm table-striped table-bordered">
            <thead>
              <tr>
                <td>Start</td>
                <td>Expire</td>
                <td>File</td>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of history">
                <td>{{ dateTime(item.issueDate) }}</td>
                <td>{{ dateTime(item.expirationDate) }}</td>
                <td>
                  <a
                    (click)="downloadFile(item.fileId, vessel?.name)"
                    *ngIf="item.fileId"
                  >
                    <i class="fa fa-download" aria-hidden="true"></i>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </li>
        <li class="list-group-item">
          <app-pdf-viewer [fileId]="fileId"></app-pdf-viewer>
        </li>
    </ul>
      </div>
    </div>
  </div>
</div>

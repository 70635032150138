<section class="content">
  <div class="row">
    <div class="col-md-12">
      <div class="card ">
        <div class="card-header">
          <h3 class="card-title">نمودار ماهانه خطی</h3>
        </div>
        <div class="card-body">
          <div id="dailyReportLineChartContainer"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="card ">
        <div class="card-header">
          <h3 class="card-title">نمودار ماهانه ستونی</h3>
        </div>
        <div class="card-body">
          <div id="sourceMonthlyBarChartContainer"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="card ">
        <div class="card-header">
          <h3 class="card-title">نمودار سالانه</h3>
        </div>
        <div class="card-body">
          <div id="sourceYearlyBarChartContainer"></div>
        </div>
      </div>
    </div>
  </div>
</section>
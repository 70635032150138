<div class="row">
  <div class="col-md-6 container">
    <div class="card shadow-sm ">
      <div class="card-header">
        <a class="btn btn-link text-decoration-none"
          [routerLink]="['/vessels/vessel-information', vessel?.IMO]">
          Information
        </a>
      </div>
      <div class="card-body">
        <div class="row g-0 bg-body-secondary position-relative">
          <div class="col-md-6 mb-md-0 p-md-4">
            <img alt="{{vessel?.name?.name}}" [src]='photo' class="w-100">
          </div>
          <div class="col-md-6 p-4 ps-md-0">
            <h5 class="mt-0 fw-semibold">IMO: {{vessel?.IMO}}</h5>
            <p>Flag: {{vessel?.name.flag}}</p>
            <p>From: {{dateTime(vessel?.name.from)}}</p>
            <p *ngIf="master">Master: {{master}}</p>
            <p (click)="downloadFile()" *ngIf="vessel && vessel.active">
              <i class="fa fa-download" aria-hidden="true"></i>
            </p>
            <input type="checkbox"
              class="btn-check"
              id="btn-check-2-outlined"
              [checked]="vessel?.active"
              (click)="vesselActive()"
              [disabled]="!hasRole('vessels-editor')">
            <label [ngClass]="{
            'btn-outline-success': vessel?.active,
             'btn-outline-secondary': !vessel?.active
             }" class="btn" for="btn-check-2-outlined">{{vessel?.active ?
              'Active' : 'Inactive'}}</label>
          </div>
        </div>
      </div>
    </div>

    <div class="card shadow-sm ">
      <div class="card-header">
        <a class="btn btn-link text-decoration-none"
          *ngIf="hasRole('vessels-editor')"
          [routerLink]="['/vessel/spec', vessel?.IMO]">
          Specifications
        </a>
        <a class="btn btn-link text-decoration-none"
          *ngIf="!hasRole('vessels-editor')">
          Specifications
        </a>
        <a *ngIf="vessel?.spec?.shipParticularId"
          (click)="downloadShipParticular(vessel.spec.shipParticularId)"
          class="pull-right">
          <i class="fa fa-download" aria-hidden="true"></i>
        </a>
      </div>
      <div class="card-body">
        <div class="row g-2">
          <div class="col-md">
            <div class="form-floating">
              <input readonly class="form-control-plaintext" id="yearBuilt"
                value={{vessel?.spec.yearBuilt}}>
              <label for="yearBuilt">Year Built</label>
            </div>
          </div>
          <div class="col-md">
            <div class="form-floating">
              <input readonly class="form-control-plaintext" id="vesselType"
                value={{vessel?.spec.vesselType}}>
              <label for="vesselType">Vessel Type</label>
            </div>
          </div>
          <div class="col-md">
            <div class="form-floating">
              <input readonly class="form-control-plaintext" id="grt"
                value={{vessel?.spec.dimensionsCapacities.GRT}}>
              <label for="grt">GRT (ton)</label>
            </div>
          </div>
        </div>
        <div class="row g-2">
          <div class="col-md">
            <div class="form-floating">
              <input readonly class="form-control-plaintext" id="nrt"
                value={{vessel?.spec.dimensionsCapacities.NRT}}>
              <label for="nrt">NRT (ton)</label>
            </div>
          </div>
          <div class="col-md">
            <div class="form-floating">
              <input readonly class="form-control-plaintext" id="beam"
                value={{vessel?.spec.dimensionsCapacities.beam}}>
              <label for="beam">Beam (meter)</label>
            </div>
          </div>
          <div class="col-md">
            <div class="form-floating">
              <input readonly class="form-control-plaintext" id="capacity"
                value={{vessel?.spec.dimensionsCapacities.capacity}}>
              <label for="capacity">Capacity (m<sup>3</sup>)</label>
            </div>
          </div>
        </div>
        <div class="row g-2">
          <div class="col-md">
            <div class="form-floating">
              <input readonly class="form-control-plaintext" id="draft"
                value={{vessel?.spec.dimensionsCapacities.draft}}>
              <label for="draft">Draft (meter)</label>
            </div>
          </div>
          <div class="col-md">
            <div class="form-floating">
              <input readonly class="form-control-plaintext" id="deadWeight"
                value={{vessel?.spec.dimensionsCapacities.deadWeight}}>
              <label for="deadWeight">Dead weight (ton)</label>
            </div>
          </div>
          <div class="col-md">
            <div class="form-floating">
              <input readonly class="form-control-plaintext" id="loa"
                value={{vessel?.spec.dimensionsCapacities.LOA}}>
              <label for="loa">LOA (meter)</label>
            </div>
          </div>
        </div>
        <div class="row g-2">
          <div class="col-md">
            <div class="form-floating">
              <input readonly class="form-control-plaintext" id="lightShip"
                value={{vessel?.spec.dimensionsCapacities.lightShip}}>
              <label for="lightShip">Lightship (ton)</label>
            </div>
          </div>
          <div class="col-md">
            <div class="form-floating">
              <input readonly class="form-control-plaintext" id="email"
                value={{vessel?.spec.email}}>
              <label for="email">Email address</label>
            </div>
          </div>
          <div class="col-md">
            <div class="form-floating">
              <input readonly class="form-control-plaintext" id="numberOfTanks"
                value={{vessel?.spec.dimensionsCapacities.numberOfTanks}}>
              <label for="numberOfTanks">Number Of Tanks</label>
            </div>
          </div>
        </div>
        <div class="row g-2" *ngIf="vessel && vessel.spec && vessel.spec.ownershipOperationProtection">
          <div class="col-md">
            <div class="form-floating">
              <input readonly class="form-control-plaintext" id="owner"
                value={{findOrganisation(vessel?.spec.ownershipOperationProtection.ownerId)}}>
              <label for="owner">Owner</label>
            </div>
          </div>
          <div class="col-md">
            <div class="form-floating">
              <input readonly class="form-control-plaintext"
                id="technicalOperator"
                value={{findOrganisation(vessel?.spec.ownershipOperationProtection.technicalOperatorId)}}>
              <label for="technicalOperator">Technical Operator</label>
            </div>
          </div>
          <div class="col-md">
            <div class="form-floating">
              <input readonly class="form-control-plaintext"
                id="commercialOperator"
                value={{findOrganisation(vessel?.spec.ownershipOperationProtection.commercialOperatorId)}}>
              <label for="commercialOperator">Commercial Operator</label>
            </div>
          </div>
        </div>
        <div class="row g-2" *ngIf="vessel && vessel.spec && vessel.spec.ownershipOperationProtection">
          <div class="col-md-4" *ngIf="vessel?.spec.ownershipOperationProtection.builderId">
            <div class="form-floating">
              <input readonly class="form-control-plaintext" id="builder"
                value={{findOrganisation(vessel?.spec.ownershipOperationProtection.builderId)}}>
              <label for="owner">Builder</label>
            </div>
          </div>
          <div class="col-md-4" *ngIf="vessel?.spec.ownershipOperationProtection.managerId">
            <div class="form-floating">
              <input readonly class="form-control-plaintext"
                id="manager"
                value={{findOrganisation(vessel?.spec.ownershipOperationProtection.managerId)}}>
              <label for="manager">Manager</label>
            </div>
          </div>
          <div class="col-md" *ngIf="vessel?.spec.ownershipOperationProtection.protectionIndemnityId">
            <div class="form-floating">
              <input readonly class="form-control-plaintext"
                id="protectionIndemnity"
                value={{findOrganisation(vessel?.spec.ownershipOperationProtection.protectionIndemnityId)}}>
              <label for="protectionIndemnity">Protection & Indemnity</label>
            </div>
          </div>
        </div>
        <div class="row g-2" *ngIf="vessel && vessel.spec && vessel.spec.dryDock">
          <div class="col-md-4" *ngIf="vessel?.spec.dryDock.lastDryDock">
            <div class="form-floating">
              <input readonly class="form-control-plaintext" id="builder"
                value={{vessel?.spec.dryDock.lastDryDock}}>
              <label for="owner">Last Dry Dock</label>
            </div>
          </div>
          <div class="col-md" *ngIf="vessel?.spec.dryDock.nextDryDock">
            <div class="form-floating">
              <input readonly class="form-control-plaintext"
                id="manager"
                value={{vessel?.spec.dryDock.nextDryDock}}>
              <label for="manager">Next Dry Dock</label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card shadow-sm ">
      <div class="card-header">
        <a [routerLink]="['/vessels/certificate', vessel?.IMO]"
          class="btn btn-link text-decoration-none">
          Certificates
        </a>
        <div class="btn-toolbar pull-right" role="toolbar"
          aria-label="Toolbar with button groups">
          <div class="btn-group me-2" role="group" aria-label="First group">
            <button type="button" class="btn btn-secondary"
              (click)="filteredCertificates = certificates">All</button>
            <button type="button" class="btn btn-primary"
              (click)="filteredCertificates = fineCertificates">{{fineCertificates.length}}</button>
            <button type="button" class="btn btn-success"
              (click)="filteredCertificates = warningCertificates">{{warningCertificates.length}}</button>
            <button type="button" class="btn btn-warning"
              (click)="filteredCertificates = dangerCertificates">{{dangerCertificates.length}}</button>
            <button type="button" class="btn btn-danger"
              (click)="filteredCertificates = expiredCertificates">{{expiredCertificates.length}}</button>
          </div>
        </div>
      </div>
      <div class="card-body">
        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
          id="certificatesTable"
          class="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Issue Date</th>
              <th>End Date</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of filteredCertificates">
              <td><a class="btn btn-link text-decoration-none"
                  (click)="downloadDocument(item.info.fileId, item.name)">{{
                  maxlenght (item.name) }}</a></td>
              <td>{{ dateTime(item.info.issueDate) }}</td>
              <td [ngClass]="colorCode(item.info.expirationDate)">{{
                dateTime(item.info.expirationDate) }}&nbsp;({{
                duration(item.info.expirationDate) }})</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="card shadow-sm ">
      <div class="card-header"><b>Q88</b><a class="btn btn-link pull-right"
          (click)="downloadDocument(q88FileId, 'Q88')">
          <i class="fa fa-download">
          </i>
        </a>
      </div>
      <div *ngIf="q88FileId">
        <app-pdf-viewer [fileId]="q88FileId"></app-pdf-viewer>
      </div>
    </div>
  </div>

  <div class="col-md-6 container">
    <div *ngIf="(volumes !== undefined)" class="card shadow-sm ">
      <div class="card-header">
        <a class="btn btn-link text-decoration-none"
          [routerLink]="['/vessels/voyage', selectedVoyage.id]">
          Last Voyage
        </a>
      </div>
      <div class="card-body">
        <table class="table table-sm table-striped table-bordered">
          <thead>
            <tr>
              <td class="bg-info text-center">Items</td>
              <td class="bg-primary text-center">Loading</td>
              <td class="bg-success text-center">Discharging</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="text-center">No. Of Ports</td>
              <td class="text-center">{{volumes.noLoadingPorts | number}}</td>
              <td>{{volumes.noDischargePorts | number}}</td>
            </tr>
            <tr>
              <td class="text-center">Ports</td>
              <td class="text-center">
                <ng-container *ngFor="let p of volumes.loadPorts">
                  <span>{{p}}</span>
                  <br>
                </ng-container>
              </td>
              <td class="text-center">
                <ng-container *ngFor="let p of volumes.dischargePorts">
                  <span>{{p}}</span>
                  <br>
                </ng-container>
              </td>
            </tr>
            <tr>
              <td class="text-center">Weight (Ton)</td>
              <td class="text-center">
                <span>Real: <br>
                  <ng-container *ngFor="let p of volumes.loadingRealWeight">
                    {{p['product']}}: {{p['grossWeight'] | number}}<br>
                  </ng-container>
                </span>
                <span>Revise: <br>
                  <ng-container *ngFor="let p of volumes.loadingReviseWeight">
                    {{p['product']}}: {{p['grossWeight'] | number}}<br>
                  </ng-container>
                </span>
              </td>
              <td class="text-center">
                <ng-container *ngFor="let p of volumes.dischargeWeight">
                  {{p['product']}}: {{p['weight'] | number}}<br>
                </ng-container>
              </td>
            </tr>
            <tr>
              <td class="text-center">Berth Time (Hours)</td>
              <td class="text-center">{{volumes.loadingBerthTime | number}}
              </td>
              <td>{{volumes.dischargeBerthTime | number}} </td>
            </tr>
            <tr>
              <td class="text-center">Laytime (Hours)</td>
              <td class="text-center">{{volumes.loadingLaytime | number}} </td>
              <td>{{volumes.dischargeLaytime | number}} </td>
            </tr>
            <tr>
              <td class="text-center">Total Real Weight</td>
              <td class="text-center">{{volumes.allLoading | number}} </td>
              <td class="text-center">{{volumes.allDischarging | number}} </td>
            </tr>
          </tbody>
          <thead>
            <tr>
              <td colspan="3"
                class="text-center bg-warning text-center">Differences</td>
            </tr>
            <tr>
              <td class="text-center" colspan="2">Time between Loading Discharge
                (Days)</td>
              <td class="text-center">Weight Difference (Ton)</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="text-center"
                colspan="2">{{volumes.timeBetweenLoadingDis}} </td>
              <td>
                <ng-container *ngFor="let d of volumes.diff">
                  <span>{{d['product']}}: {{d['diff'] | number}}</span><br>
                </ng-container>
              </td>
            </tr>
            <tr>
              <td class="text-center" colspan="2">Total Difference</td>
              <td class="text-center">{{volumes.allDiff | number}} </td>
            </tr>
          </tbody>
        </table>
        <span class="info">Weight differences are between Real BL and primary
          discharges</span>
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        <b>Audits</b>
      </div>
      <div class="card-body" *ngIf="vessel">
        <app-vessel-internal-audits [vesselIMO]="vessel.IMO" />
      </div>
    </div>

    <div class="card" *appHideIfNotInRole="['vessels-crew-viewer']">
      <div class="card-header">
        <a class="btn btn-link text-decoration-none"
          [routerLink]="['/vessels/fleet-personnel-management']">
          On Board Crews
        </a>
      </div>
      <div class="card-body">
        <table
          id="crewTable"
          datatable class="table table-sm"
          [dtOptions]="crewDtOptions"
          [dtTrigger]="crewDtTrigger">
          <thead>
            <tr>
              <td>Name</td>
              <td>Rank</td>
              <td>On Board Date</td>
              <td>Duration(Month)</td>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let c of onBoardCrews">
              <ng-container>
                <td>
                  <a class="btn btn-link text-decoration-none text-capitalize"
                    [routerLink]="['/vessels/crew-change/crew/profile/', c.id]">{{c.firstName}}
                    {{c.lastName}}
                  </a>
                </td>
                <td>
                  {{getLastCrewChange(c.crewChanges).rank}}
                </td>
                <td>
                  {{planTime(c.crewChanges, 'On Board')}}
                </td>
                <td>
                  {{onboardTimeMonthsDays(c.crewChanges)}}
                </td>
              </ng-container>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="card shadow-sm ">
      <div class="card-header">
        <b>Form C</b>
        <a class="btn btn-link pull-right"
          (click)="downloadDocument(formCFileId,'Form_C')">
          <i class="fa fa-download">
          </i>
        </a>
      </div>
      <div *ngIf="formCFileId">
        <app-pdf-viewer [fileId]="formCFileId"></app-pdf-viewer>
      </div>
    </div>
  </div>
</div>

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {VoyagePartComponent} from 'src/app/transportation/vessels/voyage/voyage-part-component';
import {Sof, Voyage, DataBaseAction, VesselsService} from 'src/app/transportation/vessels/vessels.service';
import {Organisation, OrganisationService} from 'src/app/organisation/organisation.service';
import {LookupService} from 'src/app/shared/services/lookup.service';
import {TimestampWithZone} from 'src/app/shared/components/date-time-zone/date-time-zone.component';
import {forkJoin} from 'rxjs';
import * as _ from 'lodash';
import { MessageLevel, PgMessagesService } from 'src/app/shared/services/pg-messages.service';
import { KeycloakService } from 'src/app/authentication/keycloak.service';
import { ActionType } from 'src/app/shared/services/database-action.service';

interface SofEntryField {
  code: string;
  sofItem: string;
  timestampWithZone: TimestampWithZone;
  required: boolean;
  selected: boolean;
}

interface SofType {
  templateId: string;
  description: string;
  type: string;
}

@Component({
  selector: 'app-sof',
  templateUrl: './sof.component.html',
})
export class SofComponent implements OnInit, VoyagePartComponent {

  @Output() changed: EventEmitter<Voyage> = new EventEmitter();
  @Output() cancelled: EventEmitter<any> = new EventEmitter();

  key = 'SOF';
  _uploadedFileId: string;
  _newUploadedFileId: string;
  _selectedVoyage: Voyage;
  _mode: string;

  isVerified: boolean;

  _selectedSofType: SofType;

  allSofItems: string [][];
  selectableSofItems: string[][];
  sofEntries: SofEntryField[];

  _extraSofEntry: [string, string, string];
  extraSofEntries: SofEntryField[] = [];

  disableSelectedPort = false;
  disableSelectedSOFType = true;
  showAddItem = false;


  get selectedSofType() {
    return this._selectedSofType;
  }

  set selectedSofType(value: SofType) {
    this._selectedSofType = value;
    this.selectedSof.sofType = value.type;
    this.filterSofItemsWithType(value.type);
    this.getSofItems(value.templateId);
  }

  get extraSofEntry() {
    return this._extraSofEntry;
  }

  set extraSofEntry(value: [string, string, string]) {
    if (value && this.allSofItems.map(s => s[1]).includes(value[1])) {
      this.showAddItem = false;
      this._extraSofEntry = value;
      this.extraSofEntries.push({
        code: value[0],
        sofItem: value[2],
        timestampWithZone: {
          timestamp: null,
          timeZone: this.port.timezone
        },
        required: false,
        selected: true
      });

      this._extraSofEntry = null;
    }
  }

  @Input() set fileId(value: string) {
    this._uploadedFileId = value;
  }
  @Input() set updatedFileId(value: string) {
    this._newUploadedFileId = value;
  }
  @Input() set data(value: any) {
    this._selectedVoyage = value as Voyage;
  }

  selectedSof: Sof;
  ports: Organisation[];
  _port: Organisation;

  selectedSOFTypes: SofType[];

  get port() {
    return this._port;
  }

  set port(value: Organisation) {
    this._port = value;
    this.disableSelectedSOFType = this._mode === 'edit';
  }

  constructor(private _organisationsService: OrganisationService,
              private _vesselService: VesselsService,
              private _lookupService: LookupService,
              private _pgMessagesService: PgMessagesService,
              private _keycloakService: KeycloakService) { }

  ngOnInit() {
    if (this._selectedVoyage.sofs.find(sof => sof.fileId === this._uploadedFileId)) {
      this.initialiseEditMode();
    } else {
      this.initialiseNewMode();
    }
  }

  hasRole(role: string) {
    return this._keycloakService.hasRole(role);
  }

  saveSOF() {
    if (this.isVerified !== true) {
      const duplicatedEntries = this.findDuplicatedEntries();
      if (duplicatedEntries.length > 0) {
        duplicatedEntries
          .map(s => `Found ${s.length} ${s[0].sofItem}s with same time`)
          .forEach(m => {
            this._pgMessagesService.publishMessage({
              message: m,
              topic: 'SOF',
              level: MessageLevel.Error
            });
          });
        return;
      }
      const duplicatedTimeEntries = this.findDuplicatedTimeEntries();
      if (duplicatedTimeEntries.length > 0) {
        duplicatedTimeEntries
          .map(s => `${s.map(s1 => s1.sofItem).join(', ')} have same times`).forEach(m => {
            this._pgMessagesService.publishMessage({
              message: m,
              topic: 'SOF',
              level: MessageLevel.Warning
            });
          });
      }
      this.selectedSof.portOrganisationId = this.port.name;
      this.selectedSof.entries = this.sofEntries.filter(s => s.selected).map(s =>
        [s.code, s.timestampWithZone.timestamp]);
      this.selectedSof.entries = this.selectedSof.entries
        .concat(this.extraSofEntries.map(s => [s.code, s.timestampWithZone.timestamp]));
      if (this._newUploadedFileId) {
        this.selectedSof.fileId = this._newUploadedFileId;
      }
      this.selectedSof.portOrganisationId = this.port.name;
      if (!this._selectedVoyage.sofs.includes(this.selectedSof)) {
        if (!this._selectedVoyage.sofs) {
          this._selectedVoyage.sofs = [];
        }
        this._selectedVoyage.sofs.push(this.selectedSof);
      }
    }
    this.changed.emit(this._selectedVoyage);
  }

  organisationValueFormatter(data: Organisation) {
    return data.nameTranslations['en'];
  }

  removeSofItem(index: number) {
    this.extraSofEntries.splice(index, 1);
  }

  lookupItemListFormatter(item: [string, string, string]) {
    return item[1];
  }


  showAddSofItem() {
    this.showAddItem = true;
  }

  dateTimeZoneFocused(index: number) {
    if (index !== 0 && this.sofEntries[index].timestampWithZone.timestamp === null) {
      let search = true;
      for (let index1 = index - 1; index1 !== -1 && search ; index1 = index1 - 1) {
        if (this.sofEntries[index1].timestampWithZone !== null &&
            this.sofEntries[index1].timestampWithZone.timestamp !== null) {
          this.sofEntries[index].timestampWithZone = {
            timestamp: this.sofEntries[index1].timestampWithZone.timestamp,
            timeZone: this.sofEntries[index1].timestampWithZone.timeZone
          };
          search = false;
        }
      }
    }
  }

  skipRequiredSofEntry(l: SofEntryField) {
    if (l.selected) {
      if (confirm(`Are you sure you want to skip ${l.sofItem}`)) {
        l.selected = !l.selected;
      }
    } else {
      l.selected = !l.selected;
    }
  }

  addUserAction()  {
    if (this._mode === 'edit') {
      const action: DataBaseAction = {
        userName: this._keycloakService.getUser().username,
        timestamp: new Date().getTime(),
        userAction: ActionType.Update
      };
      if (this.selectedSof.actions === undefined) {
        this.selectedSof.actions = [];
      }
      this.selectedSof.actions.push(action);
    } else {
      const action: DataBaseAction = {
        userName: this._keycloakService.getUser().username,
        timestamp: new Date().getTime(),
        userAction: ActionType.Create
      };
      this.selectedSof.actions.push(action);
    }
  }

  verify() {
    if (this._vesselService.verify(this.selectedSof, 'SOF')) {
      this.saveSOF();
    }
  }

  removeVerification() {
    if (this._vesselService.removeVerification(this.selectedSof, 'SOF')) {
      this.saveSOF();
    }
  }

  deleteSOF() {
    if (confirm('Are you sure to delete this SOF')) {
      const i = this._selectedVoyage.sofs.findIndex(s => s.fileId === this.selectedSof.fileId);
      this._selectedVoyage.sofs.splice(i, 1);
      this.changed.emit(this._selectedVoyage);
    }
  }

  private initialiseEditMode()  {
    this.selectedSof = this._selectedVoyage.sofs.find(sof => sof.fileId === this._uploadedFileId);
    this.isVerified = this._vesselService.isVerified(this.selectedSof);
    this.selectedSof.entries = this.selectedSof.entries.sort((s1, s2) => s1[1] - s2[1]);
    this.disableSelectedSOFType = true;
    this.disableSelectedPort = true;
    this._mode = 'edit';

    forkJoin([this._organisationsService.getPorts(),
    this._lookupService.get('voyage_sof_all')]).subscribe(results => {
      this.ports = results[0];
      this.port = this.ports.find(p => p.name === this.selectedSof.portOrganisationId);
      this.allSofItems = results[1].items;
      this.filterSofItemsWithType(this.selectedSof.sofType);
      this.sofEntries = this.selectedSof.entries.map(s => {
        const foundSof = this.findSofEntryByCode(s[0]);
        return {
          code: s[0],
          sofItem: foundSof[2],
          timestampWithZone: {
            timestamp: s[1],
            timeZone: this.port.timezone
          },
          required: true,
          selected: true
        };
      });
    });
  }

  private initialiseNewMode() {
    this.selectedSof = {
      sofType: '',
      portOrganisationId: '',
      entries: [],
      fileId: this._uploadedFileId,
      actions: []
    };

    forkJoin([this._organisationsService.getPorts(),
      this._lookupService.get('voyage_sof_templates'),
      this._lookupService.get('voyage_sof_all')])
      .subscribe(results => {
        this.ports = results[0];
        this.allSofItems = results[2].items;
        this.selectedSOFTypes = results[1].items.map(s => (
          {
            templateId: s[0],
            description: s[1],
            type: s[2]
          }
        ));
      });
  }

  private findSofEntryByCode(code: string) {
    const foundSof = this.allSofItems.find(s => s[0] === code);
    if (!foundSof) {
      console.error(`Could not find Sof entry for code ${code}`);
      return null;
    }
    return foundSof;
  }

  private filterSofItemsWithType(sofType: string) {
    if (sofType === 'loading') {
      this.selectableSofItems = this.allSofItems.filter(s => s[0][0] === 'L');
    } if (sofType === 'discharge') {
      this.selectableSofItems = this.allSofItems.filter(s => s[0][0] === 'D');
    }
  }

  private getSofItems(templateId: string) {
    this._lookupService.get(templateId).subscribe(items => {
      this.sofEntries = items.items.map(i => {
        const foundSof = this.findSofEntryByCode(i[0]);
        return {
          code: i[0],
          sofItem: foundSof[2],
          timestampWithZone: {
            timestamp: null,
            timeZone: this.port.timezone
          },
          required: (i[1] !== 'False' && i[1] !== 'false'),
          selected: (i[1] !== 'False' && i[1] !== 'false')
        };
      });
    });
  }

  private findDuplicatedEntries(): SofEntryField[][] {
    const combinedEntries = this.sofEntries.concat(this.extraSofEntries).filter(s => s.selected);
    const grouped = _.groupBy(combinedEntries, i => [i.sofItem, i.timestampWithZone.timestamp]);
    return _(grouped).filter((entries) => entries.length > 1).value();
  }

  private findDuplicatedTimeEntries(): SofEntryField[][] {
    const combinedEntries = this.sofEntries.concat(this.extraSofEntries).filter(s => s.selected);
    const grouped = _.groupBy(combinedEntries, i => i.timestampWithZone.timestamp);
    return _(grouped).filter((entries) => entries.length > 1).value();
  }
}

<nav class="navbar fixed-top p-0 navbar-expand flex-row bd-navbar border-bottom">
  <div class="navbar-brand toggled">
  </div>
  <div class="flex-grow-1 navbar-nav bd-navbar-nav flex-wrap" >
    <a class="nav-link" role="button" (click)="toggleSidebar($event)"><i class="fas fa-bars"></i></a>
    <a class="nav-link" routerLink="/home">Home</a>
    <span *ngFor="let s of shortcut">
      <a class="nav-link" (click)="navigateTo(s.link)">{{s.name}}</a>
    </span>
  </div>
  <app-messages></app-messages>
</nav>

import {Component, EventEmitter, Output} from '@angular/core';
import {Crew, CrewChange} from '../../crew-change.service';
import {saveAs} from 'file-saver';
import {MessageLevel, PgMessagesService} from '../../../../../shared/services/pg-messages.service';
import {DocumentService} from '../../../../../documents/documents.service';

@Component({
  selector: 'app-crew-change-toolbar',
  templateUrl: './crew-change-toolbar.component.html'
})
export class CrewChangeToolbarComponent {

  private _crewChange: CrewChange;
  private _crew: Crew;

  public get crewChange() {
    return this._crewChange;
  }
  public set crewChange(value: CrewChange) {
    this._crewChange = value;
  }

  public get crew() {
    return this._crew;
  }

  public set crew(value: Crew) {
    this._crew = value;
  }

  @Output() cancelPlan = new EventEmitter<void>();
  @Output() deleteCrewChange = new EventEmitter<void>();
  @Output() newContractExtension = new EventEmitter<void>();

  constructor(private _documentService: DocumentService,
              private _messagesService: PgMessagesService) { }


  downloadContract() {
    if (this.crewChange.contract) {
      this._documentService.download(this.crewChange.contract.contractDocumentId).subscribe(b => {
        saveAs(b.body, `${this.crew.firstName}-${this.crew.lastName}-contract.pdf`);
      }, () => {
        this._messagesService.publishMessage({
          message: 'Could not download this file.',
          level: MessageLevel.Error,
          topic: 'Crew contract'
        });
      });
    }
  }


  cancelAll() {
    if (confirm('Are you sure you want to cancel the assignment')) {
      this.cancelPlan.emit();
    }
  }

  delete() {
    if (confirm(`Are you sure you want to delete this crew change?`)) {
      this.deleteCrewChange.emit();
    }
  }

  addContractExtension() {
    this.newContractExtension.emit();
  }
}

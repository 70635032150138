<div class="row">
  <div class="col-md-6">
    <div class>
      <fieldset style="border: none">
        <form class="form-horizontal" #CrewAppraisal="ngForm">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">Crew Appraisal</h3>
            </div>
            <div class="card-body">
              <div class="form-group row">
                <label for="rank"
                  class="col-form-label col-md-4 ">Position/Rank</label>
                <div class="col-md-8">
                  <app-auto-complete
                    name="rank"
                    id="rank"
                    [formatter]="rankFormatter"
                    [(ngModel)]="rank"
                    [items]="ranks"
                    required>
                  </app-auto-complete>
                </div>
              </div>
              <div class="form-group row">
                <label for="selectedVessel"
                  class="col-form-label col-md-4 ">Vessel</label>
                <div class="col-md-8">
                  <select id="selectedVessel"
                    name="selectedVessel" class="form-control"
                    [disabled]="!rank"
                    [(ngModel)]="selectedVessel"
                    required>
                    <option *ngFor="let vessel of vessels" [ngValue]="vessel">
                      {{vessel.name.name}}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <label for="selectedManagement"
                  class="col-form-label col-md-4 ">Technical Management</label>
                <div class="col-md-8">
                  <app-organisation-selector
                    name="selectedManagement"
                    id="selectedManagement"
                    [(organisation)]="selectedTechnicalManagement"
                    [organisations]="technicalManagements"
                    [organisationLanguage]="'en'"
                    [disabled]="!selectedVessel"
                    required>
                  </app-organisation-selector>
                </div>
              </div>
              <div class="form-group row">
                <label for="appraisalDate"
                  class="col-form-label col-md-4 ">Date</label>
                <div class="col-md-8">
                  <input type="date"
                    class="form-control"
                    [disabled]="!selectedTechnicalManagement"
                    [(ngModel)]="appraisalDate"
                    id="appraisalDate"
                    name="appraisalDate"
                    required />
                </div>
              </div>
              <div class="form-group row">
                <label for="master"
                  class="col-form-label col-md-4 text-right">Master</label>
                <div class="col-md-8">
                  <app-auto-complete
                    name="master"
                    id="master"
                    [formatter]="crewFormatter"
                    [(ngModel)]="master"
                    [disabled]="!appraisalDate"
                    [items]="crews"
                    required>
                  </app-auto-complete>
                </div>
              </div>
              <div class="form-group row">
                <label for="assessor"
                  class="col-form-label col-md-4 text-right">Assessor</label>
                <div class="col-md-8">
                  <app-auto-complete
                    name="assessor"
                    id="assessor"
                    [formatter]="crewFormatter"
                    [(ngModel)]="assessor"
                    [disabled]="!master"
                    [items]="crews"
                    required>
                  </app-auto-complete>
                </div>
              </div>
              <div class="form-group row">
                <label for="reportFile"
                  class="col-form-label col-md-4 ">Report</label>
                <div class="col-md-8">
                  <app-chunk-file-uploader
                    id="reportFile"
                    [disabled]="!assessor"
                    [accept]="'.pdf'"
                    (filesChanged)="documentFileAdded($event)"
                    required />
                </div>
              </div>
              <div class="btn-group pull-right mt-3"
                *ngIf="hasRole('vessels-crew-change-editor')">
                <button
                  type="button"
                  id="add"
                  class="btn btn-outline-secondary btn-light"
                  (click)="add()">
                  Add
                </button>
                &nbsp;
              </div>
            </div>
          </div>
        </form>
      </fieldset>
    </div>
  </div>
  <div class="col-md-6">
    <app-crew-assignments [crew]="selectedCrew"> </app-crew-assignments>
    <app-pdf-viewer *ngIf="document" [fileId]="document?.id" />
  </div>

</div>

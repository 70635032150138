import { Component, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DateRange } from '../../../../shared/components';
import { LPGDistData, LpgDistReportSummary, LPGDistService, Payment, ToolbarItem } from './../../../lpg-dist.service';
import * as momentJ from 'moment-jalaali';
import { Organisation, OrganisationService } from '../../../../organisation/organisation.service';
import {  Router } from '@angular/router';
import { Subject} from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { DataTablesService } from '../../../../shared/datatables/data-tables.service';
import { LpgDistToolbarComponent } from 'src/app/lpg-dist/lpg-dist-toolbar/lpg-dist-toolbar.component';
import { UserLocalStoreService } from 'src/app/shared/services/user-local-store.service';
import {
  PgDateRangeTitleComponent, PgDateRangeTitleEnum, PgTitleComponent,
  PgToolbarComponent
} from '../../../../shared/components/title-bar/title-bar.component';
import { saveAs } from 'file-saver';
import { DateTimeHelpers } from 'src/app/shared/helpers/date-time.helpers';
import { KeycloakService } from 'src/app/authentication/keycloak.service';


@Component({
  selector: 'app-lpg-dist-payment-detail',
  templateUrl: './lpg-dist-payment-detail.component.html',
})
export class LpgDistPaymentDetailComponent implements OnInit, OnDestroy,
  PgTitleComponent, PgDateRangeTitleComponent, PgToolbarComponent {

  @ViewChild(DataTableDirective, {static: true})
  dtElement: DataTableDirective;

  title = new EventEmitter<string>();
  dateRangeSubTitle = new EventEmitter<[DateRange, PgDateRangeTitleEnum]>();
  toolbarComponentType = LpgDistToolbarComponent;
  toolbarComponent: LpgDistToolbarComponent;
  company: Organisation;
  selectedOrganisation: Organisation;

  companies: Organisation[] = [];
  total: number;
  paymentSummary: LpgDistReportSummary[] = [];
  loading: LPGDistData[] = [];
  edit: boolean;
  paymentData: Payment;

  dataTable: Payment[];

  showEntry: boolean;
  dateRange: DateRange;
  organisations: Organisation[];


  dtOptions = {};
  dtTrigger: Subject<any> = new Subject<any>();
  isDataTablesInitialised = false;

  _settings: ToolbarItem;


  constructor(private _lpgDistService: LPGDistService,
    private _router: Router,
    private _organisationService: OrganisationService,
    private _keycloakService: KeycloakService,
    private _userLocalStorage: UserLocalStoreService) {}
    columnsFormatter() {
      return [
        {
          title: 'شرکت',
          data: 'companyId',
          render: (data) => this.fotmatOrganisation(data),
        },
        {
          title: 'تاریخ',
          data: 'payTimestamp',
          render: (data) => DateTimeHelpers.formatPersianLocalDateTime(data)
        },
        {
          title: 'میدا',
          data: 'sourceOrganisationId',
          render: (data) => this.fotmatOrganisation(data)
        },
        {
          title: 'شماره فیش',
          data: 'paymentBillNo',
          type: 'number'
        },
        {
          title: 'میزان خریداری شده',
          data: 'totalWeight',
        },
        {
          title: 'مبلغ خریداری شده',
          data: 'wholePayment',
        },
        ...this.hasRole('lpg-dist-editor') ? [{
          title: 'ویرایش',
          render: () => this.editButton()
        }] : [],
        ...this.hasRole('lpg-dist-editor') ? [{
          title: 'حذف',
          render: () => this.deleteButton(),
        }] : [],
      ];
    }

    editButton() {
      return `<button id="edit" name="edit" type="submit" class="btn btn-link">
                <i class="fa fa-pencil-square-o"></i>
              </button>`;
    }

    deleteButton() {
      return `<button id="delete" name="delete" type="submit"  class="btn btn-link">
                  <i class="fa fa-trash-o"></i>
                </button>`;
    }

    hasRole(roleName: string) {
      return this._keycloakService.hasRole(roleName);
    }

  intitPage() {
      this._settings = this._userLocalStorage.get<ToolbarItem>('LpgDistPaymentDetailComponent');
      if (!this._settings || !this._settings.dateRange) {
        const endDate = momentJ().endOf('day').toDate().getTime();
        const startDate  = momentJ(endDate).startOf('jMonth').toDate().getTime();
        this._settings = {
          companyId: 'iran.parsagroup.orsagas',
          dateRange: {
            startDate: new Date(startDate),
            endDate: new Date(endDate)
          }
         };
         this.dateRange = {
          startDate: new Date(startDate),
          endDate: new Date(endDate)
        };
      } else {
        this.dateRange = {
          startDate: new Date(this._settings.dateRange.startDate),
          endDate: new Date(this._settings.dateRange.endDate)
        };
      }
      this._organisationService.get().subscribe(c => {
        this.organisations = c;
        this.selectedOrganisation =  this.organisations.find(co => co.name === this._settings.companyId);
        this.toolbarComponent.dateRange = {
          startDate: this.dateRange.startDate,
          endDate: this.dateRange.endDate
        };
        this.toolbarComponent.jalali = true;
        this.toolbarComponent.singleDate = false;
        this.toolbarComponent.visibleOrganisation = true;
        this.updateTable(this.editPayment, this._router, this._lpgDistService);
        this.toolbarComponent.organisations = this.organisations
        .filter(o =>  ['domestic'].filter(s => o.properties[s]).length > 0);
        this.toolbarComponent.selectedOrganisation =  this.organisations.find(co => co.name === this._settings.companyId);
        this.toolbarComponent.itemsSelected.subscribe (t => {
          this.refresh(t);
        });
      });
  }

  updateTable(
    editRecord: (selectedRecord: Payment, _router: Router) => void,
    router: Router,
    lpgDistService: LPGDistService
  ) {
    this.title.emit('Payments List');
    this.dateRangeSubTitle.emit([this.dateRange, PgDateRangeTitleEnum.persian]);
    this._lpgDistService.getPaymentHistory(this.selectedOrganisation.name,
      this.dateRange.startDate.getTime(),
      this.dateRange.endDate.getTime()).subscribe(sum => {
        this.dataTable = this.sortArray(sum);
        this.dtElement.dtOptions.columns = this.columnsFormatter();
        this.dtElement.dtOptions.columnDefs = [{ targets: '_all', defaultContent: '' }];
        if (!this.isDataTablesInitialised) {
          this.dtTrigger.next(null);
        }
        this.dtElement.dtInstance.then((dtInstance) => {
          dtInstance.clear().draw();
          dtInstance.rows.add(this.dataTable).draw();
          dtInstance.on('click', 'tbody tr td button', function () {
            if (this.id === 'edit') {
              editRecord(dtInstance.row(this.closest('tr')).data(), router);
            } else if (this.id === 'delete') {
              const payment = dtInstance.row(this.closest('tr')).data();
              payment.isDeleted = true;
              lpgDistService.updatePayment(payment).subscribe(() => {
                dtInstance.row(this.closest('tr')).remove().draw();
            });
          }
          });
        });
        this.isDataTablesInitialised = true;
      });
  }

  ngOnInit() {
    const currentComponent = this;
    const buttons = [
        {
          text: 'خروجی اکسل',
          action: () => currentComponent.xlsxReport()
        }
      ];
    this.dtOptions = DataTablesService.getOptions(buttons);
    this.intitPage();
  }

  organisationValueFormatter(data: Organisation) {
    return data.nameTranslations['fa'];
  }

  getTotalWight() {
    this.total = this.dataTable.reduce(((previousValue, currentValue) =>
      previousValue + currentValue.totalWeight), 0);
  }

  editPayment(selectedRecord: Payment, router: Router) {
    router.navigate(['/lpg-dist/payments/edit',
      encodeURIComponent(selectedRecord.companyId),
      encodeURIComponent(selectedRecord.payTimestamp.toString()),
      encodeURIComponent(selectedRecord.paymentBillNo)])
      .then(_ => _);
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  fotmatOrganisation(organisationId: string) {
    const organisation = this.organisations.find(o => o.name === organisationId);
    return (organisation !== undefined) ? organisation.nameTranslations['fa'] : '';
  }

  xlsxReport() {
    this._lpgDistService.downloadPaymentsExcel(
      this.selectedOrganisation.name,
      this.dateRange.startDate.getTime(),
      this.dateRange.endDate.getTime()).subscribe(file => {
        saveAs(file.body, `lpg-dist-payments.xlsx`);
      });
  }

  sortArray(data: Payment[]) {
    const test = data.sort((l1, l2) => {
      if (l1.payTimestamp < l2.payTimestamp) {
        return -1;
      }
      if (l1.payTimestamp > l2.payTimestamp) {
        return 1;
      }
      return 0;
    });
    return test;
  }

  refresh(item: ToolbarItem) {
    if(item.companyId) {
      this._settings = item;
      this._userLocalStorage.save('LpgDistPaymentDetailComponent', this._settings);
      this.selectedOrganisation = this.organisations.find(o => o.name === item.companyId);
    }
    this.dateRange = {
      startDate: new Date(this._settings.dateRange.startDate),
      endDate: new Date(this._settings.dateRange.endDate)
    };
    this.updateTable(this.editPayment, this._router, this._lpgDistService);
  }
}

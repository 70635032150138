import { Component, EventEmitter, OnInit} from '@angular/core';
import { TrucksService, TankerData } from 'src/app/transportation/trucks/trucks.service';
import { Organisation, OrganisationService } from 'src/app/organisation/organisation.service';
import { DateRange } from 'src/app/shared/components';
import { ActivatedRoute } from '@angular/router';
import { DocumentReference } from 'src/app/documents/documents.service';
import { PgMessagesService, MessageLevel } from 'src/app/shared/services/pg-messages.service';
import { DateTimeHelpers } from 'src/app/shared/helpers/date-time.helpers';
import { forkJoin } from 'rxjs';
import { PgTitleComponent } from '../../../../shared/components/title-bar/title-bar.component';
import { KeycloakService } from 'src/app/authentication/keycloak.service';

@Component({
  selector: 'app-tanker-data-entry',
  templateUrl: './tanker-data-entry.component.html',
})
export class TankerDataEntryComponent implements OnInit, PgTitleComponent {
  title = new EventEmitter<string>();

  selectedRegistrationNumber: string;
  dateRange = {
    startDate: new Date(),
    endDate: new Date(),
  };
  uploadedFileId: string;
  currentFiles: FileList;
  selectedDocumentType: string;
  selectedTanker: TankerData;
  owner: string;
  organisation: Organisation;
  organisations: Organisation[];
  showMessage: boolean;
  message: string;
  tankerNumber: string;
  mode: string;
  edit: boolean;
  capacity: number;
  licenseNumber: number;
  zoom = 1;
  history: any[];
  pageNumber = 1;
  organisationName: string;

  constructor(
    private _truckService: TrucksService,
    private _organisationService: OrganisationService,
    private _keycloakService: KeycloakService,
    private _activateRoute: ActivatedRoute,
    private _pgMessagesService: PgMessagesService) { }

  ngOnInit() {
    this._organisationService.getTransporters().subscribe(o => {
      this.organisations = o;
    });
    this.title.emit('Tanker');
    this._activateRoute.paramMap.subscribe(p => {
      this.mode = p.get('mode');

      if (p.get('mode') === 'edit') {
        this.edit = true;
        this.selectedRegistrationNumber = p.get('tankerNumber');
        this.owner = p.get('owner');
        forkJoin([
          this._truckService.getOneTanker(this.selectedRegistrationNumber, this.owner),
          this._organisationService.getTransporters(),
          this._truckService.getTankerHistory(this.selectedRegistrationNumber)
        ]).subscribe(result => {
            this.capacity = result[0].capacity;
            this.licenseNumber = result[0].licenseNumber;
            this.dateRange = {
              startDate: new Date(result[0].licenseExpireDate),
              endDate: new Date(result[0].licenseExpireDate)
            };
            this.organisations = result[1];
            this.organisation = this.organisations.find(o => o.name === result[0].owner);
            this.tankerNumber = result[0].number;
            this.uploadedFileId = result[0].fileId;
            this.history = result[2];
          });
      }
    });

    this.selectedTanker = {
      fileId: '00000000-0000-0000-0000-000000000000',
      number: null,
      owner: null,
      licenseExpireDate: null,
      licenseNumber: null,
      capacity: null,
    };
  }

  dateFormatter(timestamp: number) {
    if(timestamp !== 0) {
      return DateTimeHelpers.formatPersianLocalDate(timestamp);
    }
  }

  save() {
    this.owner = this.organisation.name;
    this.selectedTanker = {
      fileId: this.uploadedFileId,
      number: this.tankerNumber,
      owner: this.owner,
      licenseExpireDate: this.dateRange.startDate.getTime(),
      licenseNumber: this.licenseNumber,
      capacity: + this.capacity,
    };
    if (this.mode === 'new') {
      this.edit = false;
      this._truckService.addTanker(this.selectedTanker).subscribe(() => {
        this.message = `Tanker No ${this.selectedTanker.number} Imported`;
        this._pgMessagesService.publishMessage({
          message: this.message,
          topic: 'New Tanker',
          level: MessageLevel.Info
        });
      }, () => {
        this.message = 'Error';
        this._pgMessagesService.publishMessage({
          message: this.message,
          topic: 'Conflict',
          level: MessageLevel.Error
        });
      });
    } else if (this.mode !== 'new') {
      this.edit = true;
      this._truckService.updateTanker(this.selectedTanker).subscribe(() => {
        this.message =  `Tanker No ${this.selectedTanker.number} Edit`;
        this._pgMessagesService.publishMessage({
          message: this.message,
          topic: 'Edit Tanker',
          level: MessageLevel.Info
        });
      }, () => {
        this.message = 'Error';
        this._pgMessagesService.publishMessage({
          message: this.message,
          topic: 'Tracker Insertion Error',
          level: MessageLevel.Error
        });
      });
    }
  }

  uploadDocument(r: DocumentReference) {
    this.uploadedFileId = r.id;
    this.currentFiles = null;
  }


  dateRangeChange(dr: DateRange) {
    this.dateRange.startDate = dr.startDate;
    this.dateRange.endDate = dr.endDate;
  }

  hasRole(roleName: string) {
    return this._keycloakService.hasRole(roleName);
  }
}

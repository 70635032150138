import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnalyticsInterceptor } from './analytics.interceptor';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  exports: [],
  providers: [AnalyticsInterceptor]
})
export class AnalyticsModule { }

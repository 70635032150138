
import {Component, EventEmitter, OnInit} from '@angular/core';
import { Payment, LPGDistService } from '../../../lpg-dist.service';
import { DateRange } from '../../../../shared/components';
import { Organisation, OrganisationService } from '../../../../organisation/organisation.service';
import * as momentJ from 'moment-jalaali';
import { ActivatedRoute } from '@angular/router';
import { PgMessagesService, MessageLevel } from 'src/app/shared/services/pg-messages.service';
import {PgTitleComponent} from '../../../../shared/components/title-bar/title-bar.component';

@Component({
  selector: 'app-lpg-payment-entry',
  templateUrl: 'lpg-payment-entry.component.html'
})
export class LpgPaymentEntryComponent implements OnInit, PgTitleComponent {
  title = new EventEmitter<string>();

  mode: string;
  edit: boolean;

  payment: Payment;
  source: Organisation;
  sources: Organisation[] = [];
  companies: Organisation[] = [];
  company: Organisation;
  currencies: string[] = ['دلار', 'ریال', 'درهم'];
  payTimestamp: Date;
  daterange: DateRange;
  success: boolean; // = false;
  message: string;



  constructor(private _lpgDistService: LPGDistService,
              private _activatedRoute: ActivatedRoute,
              private _organisationService: OrganisationService,
              private _pgMessagesService: PgMessagesService) { }

  initializePayment(edit: boolean, companyId: string) {
    this._organisationService.getSellers().subscribe(sources => {
      this.sources = sources;
    });
    this._organisationService.getDomestics().subscribe(companies => {
      this.companies = companies;
      this.company = this.companies.filter(c => c.name === companyId)[0];
      if (edit) {
        this.source = this.sources.find(x => this.payment.sourceOrganisationId === x.name);
        this.daterange = {
          startDate: new Date(this.payment.payTimestamp),
          endDate: momentJ().startOf('day').toDate()
        };
      } else {
        this.payment = {
          company: this.company.nameTranslations['fa'],
          companyId: this.company.name,
          payTimestamp: null,
          paymentBillNo: '',
          source: '',
          sourceOrganisationId: null,
          totalWeight: null,
          currency: 'ریال',
          costPerKilo: null,
          wholePayment: null,
          isDeleted: null
         };
      }
    });
  }

  ngOnInit() {
    this.title.emit('Payment');

    this._activatedRoute.paramMap.subscribe(p => {
      this.mode = p.get('mode');
      const company = p.get('company');
      this.daterange = {
        startDate: momentJ().startOf('day').toDate(),
        endDate: momentJ().startOf('day').toDate()
      };
        if (this.mode === 'new') {
          this.edit = false;
          this.initializePayment(false, company);
        } else if (this.mode === 'edit') {
          const payTimestamp = Number(p.get('payTimestamp'));
          const paymentBillNo = p.get('paymentBillNo');
          this._lpgDistService.getOnePaymentRecord(company, payTimestamp, paymentBillNo).subscribe(selectedPayment => {
            this.payment = selectedPayment;
            this.initializePayment(true, company);
            this.edit = true;
          });
        }

    });
  }


  addPayment() {
    this.payment.company = this.companies.filter(c => c.name === this.payment.companyId)[0].nameTranslations['fa'];
    this.payment.sourceOrganisationId = this.source.name;
    this.payment.source = this.source.nameTranslations['fa'];
    this.payment.wholePayment = + this.payment.wholePayment;
    this.payment.totalWeight = + this.payment.totalWeight;
    this.payment.costPerKilo = + this.payment.costPerKilo;
    if (!this.edit) {
      this.payment.payTimestamp = this._lpgDistService.correctTimestamp(this.daterange.startDate);
      this._lpgDistService.addPayment(this.payment).subscribe(() => {
        this.message = 'فیش واریزی به شماره ' + this.payment.paymentBillNo + ' و با مبلغ ' + this.payment.wholePayment + ' ثبت شد';
        this._pgMessagesService.publishMessage({
          message: this.message,
          topic: 'New Payment',
          level: MessageLevel.Info
        });
      }, () => {
        this.message = 'Error';
        this._pgMessagesService.publishMessage({
          message: this.message,
          topic: 'Error',
          level: MessageLevel.Error
        });
      });
    } else {
      this._lpgDistService.updatePayment(this.payment).subscribe(() => {
        this.message = 'فیش واریزی به شماره ' + this.payment.paymentBillNo + ' و با مبلغ ' + this.payment.wholePayment + ' ثبت شد';
        this._pgMessagesService.publishMessage({
          message: this.message,
          topic: 'New Payment',
          level: MessageLevel.Info
        });
      }, () => {
        this.message = 'Error';
        this._pgMessagesService.publishMessage({
          message: this.message,
          topic: 'Error',
          level: MessageLevel.Error
        });
      });
    }

  }
}

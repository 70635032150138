<div class="card" *ngIf="selectedCrew">
  <div class="card-header">
    <h3 class="card-title">Appraisals</h3>
  </div>
  <div class="card-body">
    <div>
      <table class="table table-sm table-striped table-bordered">
        <thead>
        <tr>
          <th></th>
          <th>Date</th>
          <th>Vessel</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let a of selectedCrew.appraisals; let i = index;">
          <td>
            <a (click)="downloadFile(a)">
              <i class="fa fa-download" aria-hidden="true"></i>
            </a>
          </td>
          <td>{{a.date}}</td>
          <td>{{a.vesselName}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>




<div class="row">
  <div class="card card-body col-md-12">
    <table
      datatable
      [dtOptions]="dtOptions1"
      [dtTrigger]="dtTrigger"
      id="voyageReport"
      class="table table-sm table-striped table-bordered">
      <thead>
        <tr>
          <td>Vessel</td>
          <td>Ship Voyage Number</td>
          <td>Port Name</td>
          <td>SOF type</td>
          <td>Port Dues Time</td>
          <td>Laytime per Port</td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let portDue of portDues">
          <td>{{portDue.vesselName}}</td>
          <td>{{portDue.voyageNo}}</td>
          <td>{{findPort(portDue.portId)}}</td>
          <td>{{portDue.sofType}}</td>
          <td>{{portDue.portDues}}</td>
          <td>{{portDue.layTimePerPort}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

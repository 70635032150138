<form #sofForm="ngForm"
  class="form-horizontal">

  <fieldset style="border: 0;" #verified
    [disabled]=" isVerified || !hasRole('vessels-voyages-editor')">
    <!-- Port -->
    <div class="form-group row">
      <label for="port2"
        class="col-form-label col-md-4 ">Port</label>
      <div class="col-md-8">
        <app-organisation-selector
          class="pg-component"
          [(organisation)]="port"
          [organisationLanguage]="'en'"
          name="port2"
          [organisations]="ports"
          [disabled]="disableSelectedPort"
          required>
        </app-organisation-selector>
      </div>
    </div>

    <!-- New -->
    <div class="form-group row" *ngIf="_mode !== 'edit'">
      <label for="loadingDischarging"
        class="col-form-label col-md-4 ">Type</label>
      <div class="col-md-8">
        <select id="loadingDischarging"
          name="selectedDocumentType"
          class="form-control"
          [disabled]="disableSelectedSOFType"
          [(ngModel)]="selectedSofType"
          required>
          <option *ngFor="let sofType of selectedSOFTypes" [ngValue]="sofType">
            {{sofType.description}}
          </option>
        </select>
      </div>
    </div>

    <!-- Edit -->
    <div class="form-group row" *ngIf="_mode === 'edit'">
      <label for="loadingDischargingLabel"
        class="col-form-label col-md-4 ">Type</label>
      <div class="col-md-8">
        <input id="loadingDischargingLabel"
          class="form-control"
          type="text" disabled="true" value="{{selectedSof.sofType}}">
      </div>
    </div>

    <!-- Selected type SOF entries -->
    <div class="form-group mt-3">
      <table class="col-md-12">
        <tr *ngFor="let l of sofEntries; let i=index" >
          <td class="col-form-label col-md-4 ">{{l.sofItem}}</td>
          <td class="p-2"><input type="checkbox"
              [disabled]="l.required"
              [checked]="l.selected"
              (change)="l.selected = !l.selected"></td>
          <td class="col-md-6">
            <app-date-time-zone
              name="sofEntry{{i}}"
              [(ngModel)]="sofEntries[i].timestampWithZone"
              [disabled]="!(l.selected)"
              (focused)="dateTimeZoneFocused(i)"
              [appDateTimeZoneRequired]="l.selected">
            </app-date-time-zone>
          </td>
          <td class="pull-right p-2">
            <input type="checkbox"
              *ngIf="l.required"
              [checked]="l.selected"
              (change)="skipRequiredSofEntry(l)">
          </td>
        </tr>
      </table>
    </div>

    <ng-container *ngFor="let item of extraSofEntries; let i=index">
      <div class="form-group row">
        <label for="sofItem{{i}}"
          class="col-form-label col-md-4 ">{{item.sofItem}}</label>
        <div class="col-md-1"></div>
        <div class="col-md-6">
          <app-date-time-zone
            id="sofItem{{i}}"
            name="sofItem{{i}}"
            [(ngModel)]="item.timestampWithZone">
          </app-date-time-zone>
        </div>
        <button class="btn btn-link" (click)="removeSofItem(i)">
          <i class="fa fa-minus"></i>
        </button>
      </div>
    </ng-container>

    <div class="form-group" *ngIf="showAddItem">
      <div class="form-group row">
        <label for="SofItem2" class="col-form-label col-md-4 ">Item</label>
        <div class="col-md-1"></div>
        <div class="col-md-6">
          <app-auto-complete
            [formatter]="lookupItemListFormatter"
            [(ngModel)]="extraSofEntry"
            [items]="selectableSofItems"
            name="truck">
          </app-auto-complete>
        </div>
      </div>
    </div>

  </fieldset>
  <!--  Form buttons -->
  <div class="btn-group pull-right mt-3">
    <ng-container *ngIf="isVerified && hasRole('admin-editor')">
      <button type="button" id="removeVerify"
        class="btn btn-outline-secondary btn-light  me-1"
        (click)="removeVerification()"
        [disabled]="!isVerified">Remove verification
      </button>
    </ng-container>
    <ng-container *ngIf="!isVerified && hasRole('voyage-verifier')">
      <button type="button" id="verify"
        class="btn btn-outline-secondary btn-light  me-1" (click)="verify()"
        [disabled]="isVerified">Verify
      </button>
    </ng-container>
    <ng-container
      *ngIf="_mode ==='edit' && hasRole('vessels-voyages-editor')">
      <button type="button" id="delete"
        class="btn btn-outline-secondary btn-light  me-1"
        (click)="deleteSOF()"
        [disabled]="isVerified">Delete
      </button>
    </ng-container>
    <ng-container *appHideIfNotInRole="['vessels-voyages-editor']">
      <button type="button" id="sofItem"
        class="btn btn-outline-secondary btn-light  me-1"
        (click)="showAddSofItem()"
        [disabled]="isVerified">Add item
      </button>
    </ng-container>
    <div *ngIf="!isVerified && hasRole('vessels-voyages-editor')">
      <button type="submit"
        id="submit"
        class="btn btn-primary  me-1"
        (click)="addUserAction(); saveSOF()"
        [disabled]="!sofForm.form.valid">Save
      </button>
    </div>
  </div>
</form>

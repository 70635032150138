import {Component, EventEmitter, OnInit, ViewChild} from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTablesService } from '../../../../shared/datatables/data-tables.service';
import { DateRange } from '../../../../shared/components';
import * as moment from 'moment';
import { Vessel, VesselsService } from '../../vessels.service';
import { DateTimeHelpers } from '../../../../shared/helpers/date-time.helpers';
import { DataTableDirective } from 'angular-datatables';
import { NoonReportLocation, NoonReportsService } from '../../services/noon-reports.service';
import {MessageLevel, PgMessagesService} from '../../../../shared/services/pg-messages.service';
import {DocumentService} from '../../../../documents/documents.service';
import { saveAs } from 'file-saver';
import {
  PgDateRangeTitleComponent,
  PgDateRangeTitleEnum, PgTitleComponent
} from '../../../../shared/components/title-bar/title-bar.component';

@Component({
  selector: 'app-noon-reports',
  templateUrl: './noon-reports.component.html',
})
export class NoonReportsComponent implements OnInit, PgTitleComponent, PgDateRangeTitleComponent {
  @ViewChild(DataTableDirective, {static: true}) dtElement: DataTableDirective;

  title = new EventEmitter<string>();
  dateRangeSubTitle = new EventEmitter<[DateRange, PgDateRangeTitleEnum]>();

  dtOptions: any;
  dtTrigger: Subject<any> = new Subject<any>();

  dateRange: DateRange;
  noonReportsLocations: NoonReportLocation[];
  vessels: Vessel[];
  options: any;

  tableInitialised = false;

  constructor(private _vesselsService: VesselsService,
              private _router: Router,
              private _activatedRoute: ActivatedRoute,
              private _noonReportsService: NoonReportsService,
              private _documentsService: DocumentService,
              private _pgMessagesService: PgMessagesService) {
    this.dateRange = {
      startDate: moment().startOf('day').subtract(7, 'd').toDate(),
      endDate: moment().endOf('day').toDate()
    };
  }

  ngOnInit() {
    this.dtOptions = DataTablesService.getOptionsEn([], [[2, 'desc']]);
    this._activatedRoute.queryParams.subscribe(p => {
      const startDate = +p['startTime'];
      const endDate = +p['endTime'];
      this.dateRange = {
        startDate: new Date(startDate),
        endDate: new Date(endDate)
      };
      this.title.emit('Noon reports');
      this.dateRangeSubTitle.emit([this.dateRange, PgDateRangeTitleEnum.defaultTitle]);

      this.getReport();
    });
  }

  getReport() {
    this._noonReportsService.getLocations(this.dateRange).subscribe(locations => {
      this.noonReportsLocations = locations;
      if (!this.tableInitialised) {
        this.dtTrigger.next(false);
        this.tableInitialised = true;
      } else {
        this.dtElement.dtInstance.then((dtInstance) => {
          dtInstance.destroy();
          this.dtTrigger.next(false);
        });
      }
    });
  }

  formatDate(timestamp: number) {
    return DateTimeHelpers.formatLocalDate(timestamp);
  }

  downloadFile(report: NoonReportLocation) {
    this._documentsService.download(report.fileId).subscribe(b => {
      const n = report.vesselName.split(' ').map(s => s.toLowerCase()).join('-');
      saveAs(b.body, `${n}-${DateTimeHelpers.formatLocalDate(report.date)}-report.xlsx`);
    }, () => {
      this._pgMessagesService.publishMessage({
        message: 'Could not download report.',
        level: MessageLevel.Error,
        topic: 'Vessel report'
      });
    });
  }
}

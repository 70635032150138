import {Component, EventEmitter, OnInit} from '@angular/core';

import * as L from 'leaflet';
import {Map} from 'leaflet';
import 'iso8601-js-period';
import 'leaflet.markercluster';
import 'leaflet-toolbar';
import 'leaflet-easyprint';
import 'leaflet-timedimension';
import 'leaflet.fullscreen';
import {ActivatedRoute, Router} from '@angular/router';
import {VesselsService} from '../vessels.service';
import * as moment from 'moment';
import {MessageLevel, PgMessagesService} from '../../../shared/services/pg-messages.service';
import { NoonReportsService, SelectedLocation } from '../services/noon-reports.service';
import {DateRange} from '../../../shared/components';
import {FleetStatusToolbarComponent} from '../fleet-status/fleet-status-toolbar/fleet-status-toolbar.component';
import { Organisation, OrganisationService } from 'src/app/organisation/organisation.service';
import {
  PgDateRangeTitleComponent,
  PgDateRangeTitleEnum, PgTitleComponent,
  PgToolbarComponent
} from '../../../shared/components/title-bar/title-bar.component';

@Component({
  selector: 'app-fleet-navigation-history',
  templateUrl: './fleet-navigation-history.component.html',
})
export class FleetNavigationHistoryComponent implements OnInit, PgTitleComponent, PgDateRangeTitleComponent, PgToolbarComponent {
  title = new EventEmitter<string>();
  dateRangeSubTitle = new EventEmitter<[DateRange, PgDateRangeTitleEnum]>();
  toolbarComponentType = FleetStatusToolbarComponent;
  toolbarComponent: FleetStatusToolbarComponent;

  _map: Map;
  _selectedDateRange: DateRange;
  locations: SelectedLocation[];
  ports: Organisation[];

  _toolbar: any;
  _currentVesselsLayer: any;
  _currentControlLayer: any;

  constructor(private _vesselsService: VesselsService,
              private _noonReportV202005sService: NoonReportsService,
              private _activatedRoute: ActivatedRoute,
              private _pgMessagesService: PgMessagesService,
              private _router: Router,
              private _organisationService: OrganisationService) { }

  ngOnInit() {
    this._activatedRoute.paramMap.subscribe(p => {
      this._selectedDateRange = {
        startDate: p.get('startTime') ? new Date(+p.get('startTime')) :
          moment().startOf('day').add(-7, 'days').toDate(),
        endDate: p.get('endTime') ? new Date(+p.get('endTime')) :
          moment().endOf('day').toDate()
      };
      this.title.emit('Fleet Navigation history');
      this.dateRangeSubTitle.emit([this._selectedDateRange, PgDateRangeTitleEnum.defaultTitle]);

      this._organisationService.getPorts().subscribe(organisations => {
        this.ports = organisations.filter(o => o.location.latitude !== 0 && o.location.longitude !== 0);
        this.setupMap();
        this.refreshData();
      });
    });
  }

  refreshData() {
    this._noonReportV202005sService.getLocations(this._selectedDateRange)
      .subscribe(results => {
        this.locations = this._noonReportV202005sService.groupLocations(results)
          .sort((a, b) => a.vesselName.localeCompare(b.vesselName));
        this.toolbarComponent.locations = this.locations;
        this.toolbarComponent.itemsSelected.subscribe(l => this.onItemSelected(l));
        if (this.locations.length === 0) {
          this._pgMessagesService.publishMessage({
            message: `Noon reports are missing`,
            topic: 'Noon reports',
            level: MessageLevel.Warning
          });
        } else {
          this.drawLocationsWithTimeline(this.locations);
        }
    });
  }

  setupMap() {
    if (!this._map) {
      this._map = L.map('mapid', {
        zoom: 14,
        fullscreenControl: true,
        fullscreenControlOptions: {
          position: 'topleft'
        },
        timeDimensionControl: true,
        timeDimensionControlOptions: {
          timeSliderDragUpdate: true,
          loopButton: false,
          autoPlay: false,
          speedSlider: false,
          playerOptions: {
            transitionTime: 1000,
            loop: false
          }
        },
        timeDimension: true,
      } as any);
      this._map.scrollWheelZoom.disable();

      L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png', {
        attribution: `&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy;
        <a href="https://carto.com/attributions">CARTO</a>`
      }).addTo(this._map);

      const L2: any = L;
      L2.easyPrint({
        title: 'Print the map',
        position: 'topleft',
        sizeModes: ['A4Landscape']
      }).addTo(this._map);

      const component = this;
      const refreshAction = L2.Toolbar2.Action.extend({
        options: {
          toolbarIcon: {
            html: '<i class="fa fa-window-maximize"></i>',
            tooltip: 'Reset bounds'
          }
        },
        addHooks:  () => {
          const bounds = component._noonReportV202005sService.getLocationBounds(component.locations);
          component._map.fitBounds(bounds);
        }
      });

      // const showPopupsAction = L2.Toolbar2.Action.extend({
      //   options: {
      //     toolbarIcon: {
      //       html: '<i class="fa fa-comment"></i>',
      //       tooltip: 'Show details'
      //     }
      //   },
      //   addHooks: function () { component.showPopups(); }
      // });

      L2.Toolbar2.Action.extend({
        initialize(map, myAction) {
          this.map = map;
          this.myAction = myAction;
          L2.Toolbar2.Action.prototype.initialize.call(this);
        },
        addHooks() {
          this.myAction.disable();
        }
      });

      this._toolbar = new L2.Toolbar2.Control({
        position: 'topleft',
        // actions: [refreshAction, showPopupsAction]
        actions: [refreshAction]
      });
      this._toolbar.addTo(this._map);
      this.getPortLocationMarkerLayer(this.ports);
    }
  }

  getPortLocationMarkerLayer(ports: Organisation[]) {
    ports.map(p => {
      const colour =  'gray';
      const markerOptions: L.MarkerOptions = {
        icon: L.divIcon({
          html: `<i class="fa fa-anchor" style="font-size:15px; color:${colour}; opacity: 0.9">
                 </i><br><i style="color: ${colour}"></i>`,
          iconSize: [30, 30],
          className: 'portDivIcon'
        }),
      };
    L.marker([p.location.latitude, p.location.longitude], markerOptions)
    .bindPopup(`${p.nameTranslations['en']}`, {maxWidth: 500, closeButton: false}).addTo(this._map);
    });
  }

  onItemSelected(locations: SelectedLocation[]) {
    this.drawLocationsWithTimeline(locations.filter(r => r.selected));
  }

  drawLocationsWithTimeline(locations: SelectedLocation[]) {
    const bounds = this._noonReportV202005sService.getLocationBounds(locations);
    const [vesselsLayer, controlLayer] = this.getVesselsLocationLayer(locations);

    if (this._currentControlLayer) {
      this._map.removeLayer(this._currentControlLayer);
    }

    if (this._currentVesselsLayer) {
      this._map.removeLayer(this._currentVesselsLayer);
    }
    this._currentVesselsLayer = vesselsLayer;
    this._currentControlLayer = controlLayer;

    this._map.fitBounds(bounds);
    this._map.addLayer(vesselsLayer);
    this._map.addLayer(controlLayer);

    const maxDate = locations.flatMap(r => r.locations.map(n => n.date)).sort((a, b) => b - a)[0];
    const m: any = this._map;
    m.timeDimension.setCurrentTime(maxDate);
  }

  private getVesselsLocationLayer(reports: SelectedLocation[]) {
    const L2: any = L;
    const m: any = this._map;

    const pointGeoJson = reports.map(r => (
      {
        type: 'Feature',
        properties: {
          name: r.vesselName,
          locations: r.locations,
          times: r.locations.map(n => n.date)
        },
        geometry: {
          type: 'MultiPoint',
          coordinates: r.locations.map(n =>
            [n.geoCoordinate.longitude, n.geoCoordinate.latitude])
        }
      }
    ));

    const geoJsonLayer = L.geoJSON(pointGeoJson as any, {
      pointToLayer: (feature, latLng) => {
        if (feature.geometry.coordinates.length === 1) {
          const currentReports = feature.properties.locations.find(r =>
            r.date === m.timeDimension.getCurrentTime());
          if (currentReports) {
            return this._noonReportV202005sService.getVesselLocationMarkerLayer(currentReports);
          }
        }
        return L.circleMarker(latLng, {
          opacity: 0.2,
          radius: 1
        });
      },
      filter: () => true
    });

    const geoJsonControlLayer = L2.timeDimension.layer.geoJson(geoJsonLayer, {
      duration: 'PT1H',
      updateTimeDimension: true,
      updateTimeDimensionMode: 'replace',
      addlastPoint: true,
      waitForReady: true,
    });

    return [geoJsonLayer, geoJsonControlLayer];
  }


}

import {Component, Input} from '@angular/core';
import {Crew, CrewAppraisal} from '../crew-change.service';
import {MessageLevel, PgMessagesService} from '../../../../shared/services/pg-messages.service';
import {DocumentService} from '../../../../documents/documents.service';
import { saveAs } from 'file-saver';


@Component({
  selector: 'app-crew-appraisals',
  templateUrl: './crew-appraisals.component.html',
  styleUrls: ['./crew-appraisals.component.css']
})
export class CrewAppraisalsComponent {

  _selectedCrew: Crew;

  constructor(private _documentsService: DocumentService,
              private _pgMessagesService: PgMessagesService) { }

  @Input()
  set selectedCrew(crew: Crew) {
    this._selectedCrew = crew;
  }

  get selectedCrew() {
    return this._selectedCrew;
  }

  downloadFile(appraisal: CrewAppraisal) {
    this._documentsService.download(appraisal.documentId).subscribe(b => {
      saveAs(b.body, `${this.selectedCrew.firstName}-${this.selectedCrew.lastName}-${appraisal.date}.pdf`);
    }, () => {
      this._pgMessagesService.publishMessage({
        message: 'Could not download this file.',
        level: MessageLevel.Error,
        topic: 'Crew appraisal'
      });
    });

  }
}

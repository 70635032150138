<div class="card">
  <div class="card-header">
    <h5 class="card-title fw-bold">{{_timelineHeader}}</h5>
  </div>
  <div class="card-body">
    <div class="vertical-timeline vertical-timeline--animate vertical-timeline--one-column" *ngFor="let item of _timelineItems">
      <div class="vertical-timeline-item vertical-timeline-element">
        <div>
          <span class="vertical-timeline-element-icon bounce-in">
            <i class="badge badge-dot badge-dot-xl" [ngClass]="item.iconStyles ? item.iconStyles : 'bg-primary'">&nbsp;</i>
          </span>
          <div class="vertical-timeline-element-content bounce-in">
            <h4 class="timeline-title">{{item.title}}</h4>
            <p>{{item.description}}</p>
            <span class="vertical-timeline-element-date">{{item.date}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
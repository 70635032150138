<div class="row">
  <div class="col-md-6">
    <div class="card card-body">
      <fieldset style="border: 0;" [disabled] = " !hasRole('phonebook-editor')">
      </fieldset>
    <form class="form-horizontal" #phoneBook="ngForm">
        <div class="form-group row">
            <label for="name" class="col-form-label col-md-4 "> عنوان</label>
            <div class="col-md-8">
                <input 
                  [disabled]="mode === 'edit'"
                  type="text"
                  class="form-control"
                  name="name"
                  id="name"
                  [ngModel]="phoneBookData?.title"
                  (ngModelChange)="phoneBookData.title = $event"
                  required>
            </div>
        </div>
          <div class="form-group row">
            <label for="title" class="col-form-label col-md-4 "> نام مخاطب</label>
            <div class="col-md-8">
                <input 
                [disabled]="mode === 'edit'"
                type="text"
                class="form-control"
                name="title"
                id="title"
                [ngModel]="phoneBookData?.name"
                (ngModelChange)="phoneBookData.name = $event"
                required>
            </div>
          </div>
          <div class="form-group row">
              <label for="companyName" class="col-form-label col-md-4 "> نام شرکت</label>
              <div class="col-md-8">
                  <input 
                  [disabled]="mode === 'edit'"
                  type="text"
                  class="form-control"
                  name="companyName"
                  id="companyName"
                  [ngModel]="phoneBookData?.companyName"
                  (ngModelChange)="phoneBookData.companyName = $event"
                  required>
              </div>
          </div>
          <div *ngFor="let phoneNumber of phoneNumbers; let in=index">
              <div class="form-group row col-md-12" *ngIf="hasRole('phonebook-editor') && remove">
                  <label for="removeNumber"
                    class="col-form-label col-md-4 "> حذف شماره تلفن</label>
                  <button class="btn btn-link" (click)="removePhoneNumber(in)">
                      <i class="fa fa-minus"></i>
                  </button>
              </div>
              <div class="form-group row" >
                  <label for="category"
                         class="col-form-label col-md-4 ">تلفن</label>
                  <div class="col-md-8">
                      <select class="form-control"
                              name="phoneNumbersId{{in}}.category"
                              id="phoneNumbers{{in}}.category"
                              [(ngModel)]=phoneNumbers[in].category
                              #name="ngModel"
                              required>
                              <option value="home" >خانه</option>
                              <option value="office">دفتر</option>
                              <option value="mobile">همراه</option>
                              <option value="fax">فاکس</option>
                              <option value="other">دیگر شماره ها</option>
                      </select>
                  </div>
                </div>
              <div class="form-group row">
                  <label for="phoneNumber" class="col-form-label col-md-4 ">شماره</label>
                  <div class="col-md-8">
                      <input 
                          type="text"
                          class="form-control"
                          name="phoneNumbersId{{in}}.number"
                          id="phoneNumbers{{in}}.number"
                          [(ngModel)]=phoneNumbers[in].number
                          #name="ngModel"
                          >
                  </div>
              </div>
          </div>
          <div class="form-group row col-md-12" *appHideIfNotInRole="['phonebook-editor']">
              <label for="newNumber"
                class="col-form-label col-md-4 ">شماره جدید</label>
              <button class="btn btn-link" (click)="addNewPhoneNumber()">
                  <i class="fa fa-plus"></i>
              </button>
          </div>
        <div *ngFor="let address of addresses; let in=index">
            <div class="form-group row col-md-12" *appHideIfNotInRole="['phonebook-editor']">
                <label for="removeAddress"
                  class="col-form-label col-md-4 "> حذف آدرس</label>
                <button class="btn btn-link" (click)="removeAddress(in)">
                    <i class="fa fa-minus"></i>
                </button>
            </div>
            <div class="form-group row">
                <label for="address" class="col-form-label col-md-4 ">کشور</label>
                <div class="col-md-8">
                    <input 
                        type="text"
                        class="form-control"
                        name="addressId{{in}}.country"
                        id="addresses{{in}}.country"
                        [(ngModel)]=addresses[in].country
                        #name="ngModel"
                        >
                </div>
            </div>
            <div class="form-group row">
              <label for="address" class="col-form-label col-md-4 ">شهر</label>
              <div class="col-md-8">
                  <input 
                        type="text"
                        class="form-control"
                        name="addressId{{in}}.city"
                        id="addresses{{in}}.city"
                        [(ngModel)]=addresses[in].city
                        #name="ngModel"
                        >
              </div>
            </div>
            <div class="form-group row">
              <label for="address" class="col-form-label col-md-4 ">آدرس</label>
              <div class="col-md-8">
                  <input 
                      type="text"
                      class="form-control"
                      name="addressId{{in}}.address"
                      id="addresses{{in}}.address"
                      [(ngModel)]=addresses[in].address
                      #name="ngModel"
                      >
              </div>
            </div>
            <div class="form-group row">
              <label for="address" class="col-form-label col-md-4 ">کد پستی</label>
              <div class="col-md-8">
                  <input 
                      type="text"
                      class="form-control"
                      name="addressId{{in}}.postCode"
                      id="addresses{{in}}.postCode"
                      [(ngModel)]=addresses[in].postCode
                      #name="ngModel"
                      >
              </div>
            </div>
        </div>
        <div class="form-group row col-md-12" *appHideIfNotInRole="['phonebook-editor']">
            <label for="transporter"
              class="col-form-label col-md-4 ">آدرس جدید</label>
            <button class="btn btn-link" (click)="addNewAddress()">
                <i class="fa fa-plus"></i>
            </button>
        </div>
        <div class="form-group row col-md-12" *appHideIfNotInRole="['phonebook-editor']">
            <label for="isDeleted"
                   class="col-form-label col-md-4 ">حذف مخاطب</label>
            <input type="checkbox"
                   id="isDeleted"
                   name="isDeleted"
                   [ngModel]="phoneBookData?.isDeleted"
                   (ngModelChange)="phoneBookData.isDeleted = $event">
          </div>
        
        <div [ngClass]="{'alert':true, 'alert-danger':!success, 'alert-success':success}" *ngIf="showMessage">{{message}}</div>
      </form>
      <div *appHideIfNotInRole="['phonebook-editor']">
        <div class="pull-right">
        <input    type="button" 
                  class="btn btn-primary" 
                  name="pay" 
                  value="Save" 
                  (click)="onSubmit()"
                  [disabled]="!phoneBook.form.valid">
                </div>
    </div>
    </div>
  </div>
</div>
<div class="row" >
    <div class="col-md-12 col-xs-12">
        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" 
               class="table table-sm table-striped table-bordered">
            <thead>
            <tr>
                <th>شماره پلاک</th>
                <th>شماره مخزن</th>
                <th>نام راننده</th>
                <th>شماره ردیاب</th>
                <th>شرکت حمل و نقل</th>
                <th>آخرین به روز رسانی</th>
                <th>در دسترس</th>
                <th>تعداد قطع</th>
                <th>فعال</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let truck of trucks">
                <td><p style="white-space: pre"> {{truck.formattedRegistrationNumber}} </p></td>
                <td>{{truck.tanker?.associatedObject.number}}
            </td>
                <td>{{truck.driver?.associatedObject.name}}</td>
                <td>{{truck.tracker?.associatedObject.imei }}</td>
                <td>{{formatOrganisation(truck.organisationId.associatedObject)}}</td>
                <td>
                    {{truck.lastLocation?.time | persianDate}}
                </td>
                <td>
                    {{isTruckOutdated(truck)}}
                </td>
                <td>
                    {{getOutlierLocations(truck)}}
                </td> 
                <td class="visible-md visible-lg">
                        <input id="active" name="active" type="checkbox" class="btn-link" [checked]="truck.active"
                               [disabled]="true">
                </td>
            </tr>
            </tbody>
        </table>
    </div >
</div>

import { Route } from '@angular/router';
import { CorrespondenceTemplatesComponent } from './templates/correspondence-templates.component';
import { NewCorrespondenceTemplateComponent } from './new-correspondence-template/new-correspondence-template.component';
import { NewCorrespondenceComponent } from './new-correspondence/new-correspondence.component';
import { CorrespondencesComponent } from './correspondences/correspondences.component';

export const correspondenceRoutes: Route[] = [
  { path: 'correspondence/templates', component: CorrespondenceTemplatesComponent },
  { path: 'correspondence/templates/new', component: NewCorrespondenceTemplateComponent},
  { path: 'correspondence/:mode/:type', component: NewCorrespondenceComponent},
  { path: 'correspondence/:mode/:type/:fileId/:organisationId', component: NewCorrespondenceComponent},
  { path: 'correspondence/:mode/:type/:fileId/:organisationId/:reference', component: NewCorrespondenceComponent},
  { path: 'correspondence', component: CorrespondencesComponent},

];

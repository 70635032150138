<form #commentForm="ngForm"
  class="form-horizontal"
  (ngSubmit)="saveMiscDocument()">
  <div class="form-group row">
    <label for="selectedMiscDocumentType"
      class="col-form-label col-md-4 ">Type</label>
    <div class="col-md-8">
      <select id="selectedMiscDocumentType"
        name="selectedMiscDocumentType"
        [(ngModel)]="miscDocument.miscDocumentType"
        class="form-control"
        [required]>
        <option></option>
        <option *ngFor="let t of documentTypes" [value]="t">{{t}}</option>
      </select>
    </div>
  </div>
  <div class="form-group row">
    <label for="comment"
      class="col-form-label col-md-4 ">Comment</label>
    <div class="col-md-8">
      <textarea id="comment"
        name="comment"
        class="form-control"
        cols="40" rows="5"
        [(ngModel)]="miscDocument.comment"
        required></textarea>
    </div>
  </div>

  <div class="btn-group">
    <button type="button"
      id="cancel"
      class="btn btn-outline-secondary btn-light"
      (click)="cancelled.emit()">Back to voyage
    </button>
    <button type="submit"
      id="submit"
      class="btn btn-primary"
      [disabled]="!commentForm.form.valid">Save
    </button>
  </div>
</form>


import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'persianDate' })
export class PersianDatePipe implements PipeTransform {
  transform(date: any): any {
    if (date) {
      if (typeof date.getMonth === 'function') {
        return date.toLocaleDateString('fa-IR') + ' ' + date.toLocaleTimeString('fa-IR');
      } else if (typeof date === 'number') {
        const tempDate = new Date(date);
        return tempDate.toLocaleDateString('fa-IR') + ' ' + tempDate.toLocaleTimeString('fa-IR');
      }
    }
  }
}

import {Component, EventEmitter, OnInit} from '@angular/core';
import { DateRange } from '../../../shared/components';
import { Router } from '@angular/router';
import * as moment from 'moment';
import {PgTitleComponent} from '../../../shared/components/title-bar/title-bar.component';

@Component({
  selector: 'app-select-report',
  templateUrl: './select-voyage-report.component.html',
})
export class SelectVoyageReportComponent implements OnInit, PgTitleComponent {
  title = new EventEmitter<string>();

  dateRange: DateRange;

  constructor(private _router: Router) { }

  ngOnInit() {
    this.title.emit('Select voyages report');
    this.dateRange = {
      startDate: moment().subtract(12, 'months').toDate(),
      endDate: moment().toDate()
    };
  }

  selectedDateRangeChanged(e: DateRange) {
    this.dateRange = e;
  }
}

<div class="btn-group pull-right" role="group"
  aria-label="Button group with nested dropdown">
  <button type="button" id="showSummary"
    class="btn btn-outline-secondary btn-light  me-1"
    (click)="navigateToVoyageWithTimestamp(this.currentVoyageId)">Summary
  </button>
  <button type="button" id="previousVoyage"
    class="btn btn-outline-secondary btn-light  me-1"
    (click)="navigateToVoyage(previousVoyageId)"
    [disabled]="previousVoyageId === ''">Previous voyage
  </button>
  <button type="button" id="nextVoyage"
    class="btn btn-outline-secondary btn-light  me-1"
    (click)="navigateToVoyage(nextVoyageId)"
    [disabled]="nextVoyageId === ''">Next voyage
  </button>
  <ng-container *appHideIfNotInRole="['vessels-voyages-editor']">
    <button class="btn btn-outline-secondary btn-light dropdown-toggle  me-1"
      type="button" id="addButtonGroup" data-bs-toggle="dropdown"
      aria-expanded="false">
      Add
    </button>
    <ul class="dropdown-menu" aria-labelledby="addButtonGroup">
      <li><a class="dropdown-item" (click)="showAddDocument('NOR')">NOR</a></li>
      <li><a class="dropdown-item" (click)="showAddDocument('SOF')">SOF</a></li>
      <li><a class="dropdown-item"
          (click)="showAddDocument('Bill of Lading')">Bill Of Lading</a></li>
      <li><a class="dropdown-item"
          (click)="showAddDocument('Discharge')">Discharge</a></li>
      <li><a class="dropdown-item"
          (click)="showAddDocument('Comment', true)">Comment</a></li>
      <li><a class="dropdown-item"
          (click)="showAddDocument('Misc-Document')">Document</a></li>
      <li><a class="dropdown-item"
          (click)="showAddDocument('Charterer', true)">Charterer</a></li>
    </ul>
  </ng-container>
</div>

import { Route } from '@angular/router';
import { MarketComponent } from './market.component';
import { MarketFilesComponent } from './market-files/market-files.component';
import { ImportPublicationComponent } from './market-list/import-publication/import-publication.component';
import { MarketListComponent } from './market-list/market-list.component';
import { MarketReportComponent } from './market-report/market-report.component';


export const marketRoutes: Route[] = [
  { path: 'pricing', component: MarketComponent },
  { path: 'pricing/report', component: MarketReportComponent },
  { path: 'pricing/report/:startDate/:endDate', component: MarketReportComponent },
  { path: 'pricing/files/:publisher/:startDate/:endDate', component: MarketFilesComponent },
  { path: 'pricing/file', component: ImportPublicationComponent },
  { path: 'pricing/:provider/:startDate/:endDate', component: MarketListComponent },
];

<div class="card">
  <div class="card-header">
    <h3 class="card-title">Onboarding stages</h3>
  </div>
  <div class="card-body">
    <div class="form-group row">
      <fieldset style="border: 0;"
        [disabled]="crewChange?.isCancelled || !hasRole('vessels-crew-change-editor')">
        <div class="col-md-12" *ngIf="organisations">
          <ng-container *ngFor="let item of items; let i = index;">
            <div class="form-group row">
              <label for="{{item.plan + 'place'}}"
                class="col-form-label col-md-4 ">{{item.plan}}</label>
              <div class="col-md-4">
                <app-organisation-selector
                  id="item.plan + 'place'"
                  class="pg-component"
                  [organisation]="plannedPlaces[i]"
                  (organisationChange)="setChangePlanPlace(item, $event, i)"
                  [organisationLanguage]="'en'"
                  name="item.plan + 'place'"
                  [organisations]="places"
                  required>
                </app-organisation-selector>
              </div>
              <div class="col-md-4">
                <app-date-time-zone
                  [ngModel]="plannedTimes[i]"
                  (ngModelChange)="setChangePlanTime(item, $event, i)"
                  name="{{item.plan + 'time'}}"
                  [disabled]="(i !== (items.length -1) || !item.place)" />
              </div>
            </div>
          </ng-container>
        </div>
        <div class="btn-group pull-right mt-3">
          <button
            type="button"
            id="addPlan"
            class="btn btn-outline-secondary btn-light  me-1"
            *ngIf="hasRole('vessels-crew-change-editor') && crewChange?.isCancelled === false"
            (click)="addPlan()">
            Add Plan
          </button>
          <button
            type="button"
            id="removeLastPlan"
            class="btn btn-outline-secondary btn-light  me-1"
            [disabled]="crewChange.item.length === 0"
            *ngIf="hasRole('vessels-crew-change-editor') && crewChange?.isCancelled === false"
            (click)="removeLastPlan()">
            Remove last
          </button>
          <button
            type="button"
            id="save"
            class="btn btn-outline-secondary btn-light  me-1"
            *ngIf="hasRole('vessels-crew-change-editor') && crewChange?.isCancelled === false"
            (click)="save()"
            [disabled]="disabled">
            Save
          </button>
        </div>
      </fieldset>
    </div>
  </div>
</div>

import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Crew, CrewChangeServices } from '../crew-change.service';
import moment from 'moment';
import { DataTablesService } from '../../../../shared/datatables/data-tables.service';

@Component({
  selector: 'app-onboards',
  templateUrl: './onboards.component.html'
})
export class OnboardsComponent implements OnInit {
  isDataTablesInitialised = false;
  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions = {};
  filteredByRanks: Crew[];

  _crews: Crew[];
  _ranks: string[];

  @Input() set ranks(ranks: string[]) {
    if (ranks) {
      this._ranks= ranks;
    }
  }

  @Input() set onBoards(onBoards: Crew[]) {
    if (onBoards && this._ranks) {
      this._crews= onBoards;
      this.filteredByRanks = onBoards.filter(c => c.crewChanges.filter(c => this._ranks.includes(c.rank)).length > 0);
      this.dtTrigger.next(false);
    }
  }

  constructor(private _crewChangeService: CrewChangeServices) { }

  ngOnInit(): void {
    this.dtOptions = DataTablesService.getOptionsWithNoColumnsEn();
  }

  getAge(c: Crew) {
    if (c) {
      return moment().diff(moment(c.birthDate), 'years');
    }
  }
}

<div class="row" style="padding-top: 5px">
  <div class="col-md-12 overflow-auto">
    <table datatable
           [dtOptions]="dtOptions"
           [dtTrigger]="dtTrigger"
           class="table table-sm table-striped table-bordered">
      <thead>
      <tr>
        <td></td>
        <td>Vessel</td>
        <td>Date</td>
        <td>Voyage Number</td>
        <td>Type</td>
        <td>Lat/Long</td>
        <td>From</td>
        <td>To</td>
        <td>ETA</td>
<!--        <td>Average Speed</td>-->
<!--        <td>Speed</td>-->
<!--        <td>Laden/Ballast</td>-->
        <td>Distance to go</td>
        <td>Distance by ME</td>
        <td>Distance covered</td>
        <td>LSFO consumption</td>
        <td>HFO consumption</td>
        <td>LSMGO consumption</td>
        <td>LSFO ROB</td>
        <td>HFO ROB</td>
        <td>LSMGO ROB</td>
        <td>Sea force</td>
        <td>Wind force</td>
        <td>Current</td>
<!--        <td>Cargo</td>-->
<!--        <td>Master</td>-->
        <td>Comments</td>
        <td>Submit time</td>
        <td>Delete time</td>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let report of reports">
        <td>
<!--          <div *ngIf="report.fileId">-->
<!--            <a (click)="downloadFile(report)">-->
<!--              <i class="fa fa-file-excel-o" aria-hidden="true"></i>-->
<!--            </a>  &nbsp; &nbsp;-->
<!--          </div>-->
          <a class="btn btn-link" *ngIf="report?.deletedAction?.timestamp">
            <i class="fa fa-times" aria-hidden="true"></i>
          </a>
          <a class="btn btn-link" *ngIf="!report?.deletedAction?.timestamp">
            <i class="fa fa-check" aria-hidden="true"></i>
          </a>
        </td>
        <td>{{report.vesselInfo.vesselName}}</td>
        <td>{{report.reportDate.isoDate}}</td>
        <td>{{report.voyageNumber}}</td>
        <td>{{report.reportType}}</td>
        <td>{{report.vesselLocation.geoCoordinate | geoDms}}</td>
        <td>{{report.currentPlan.sourceId | organisationIdToEnName: organisationsMap}}</td>
        <td>{{report.currentPlan.destinationId | organisationIdToEnName: organisationsMap}}</td>
        <td>{{report.currentPlan.eta}}</td>
<!--        <td>{{report.speed.averageSpeed | number}}</td>-->
<!--        <td>{{report.speed.speed | number}}</td>-->
<!--        <td>{{report.voyageDetails.isLaden ? 'Laden': 'Ballast'}}</td>-->
        <td>{{report.distances.distanceToGo | number}}</td>
        <td>{{report.distances.distanceByMainEngine | number}}</td>
        <td>{{report.distances.distanceCovered | number}}</td>
        <td>{{getTotalBunkerConsumption(report, 'VLSFO')}}</td>
        <td>{{getTotalBunkerConsumption(report, 'HFO')}}</td>
        <td>{{getTotalBunkerConsumption(report, 'LSMGO')}}</td>
        <td>{{getRob(report, 'VLSFO')}}</td>
        <td>{{getRob(report, 'HFO')}}</td>
        <td>{{getRob(report, 'LSMGO')}}</td>
        <td>{{report.weatherCondition.seaForce | weather}}</td>
        <td>{{report.weatherCondition.windForce | weather}}</td>
        <td>{{report.weatherCondition.currentForce | weather}}</td>
<!--        <td>{{formatCargoOnboard(report)}}</td>-->
<!--        <td>{{report.master}}</td>-->
        <td>{{report.comments}}</td>
        <td>{{formatDate(report?.submittedAction?.timestamp)}}</td>
        <td>{{formatDate(report?.deletedAction?.timestamp)}}</td>
      </tr>
      </tbody>
    </table>
  </div>
</div>

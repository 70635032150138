import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-number-plate',
  templateUrl: './number-plate.component.html',
  styleUrls: ['./number-plate.component.css']
})
export class NumberPlateComponent {

  @Output() registrationNumberUpdated = new EventEmitter<string[]>();
  @Input() disabled: boolean;

  _registrationNumber = ['', '', '', 'ع', 'ایران'];

  @Input() set registrationNumber(value: string[]) {
    if (value && (value.length === 4 || value.length === 5)) {
      this._registrationNumber = value;
    }
  }

  keyup() {
    if (this._registrationNumber[0].length === 2 &&
      this._registrationNumber[1].length === 3 &&
      this._registrationNumber[2].length === 2 &&
      this._registrationNumber[3].length === 1) {
      this.registrationNumberUpdated.emit(this._registrationNumber);
    }
  }

}

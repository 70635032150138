import { Component, OnInit } from '@angular/core';
import { KeycloakService, UserData } from 'src/app/authentication/keycloak.service';
import { ShortcutLinksService, Shortcut } from '../../services/shortcut-links.service';
import { Router } from '@angular/router';

declare let $: any;


@Component({
  selector: 'app-header-bar',
  templateUrl: 'header-bar.component.html',
  styleUrls: ['header-bar.component.css']
})
export class HeaderBarComponent implements OnInit {

  shortcut: Shortcut[];
  userData: UserData;

  constructor(private _keycloakService: KeycloakService,
    private _shortcutLinksService: ShortcutLinksService,
    private _router: Router) {
  }

  ngOnInit() {
    this.userData = this._keycloakService.getUser();
    this._shortcutLinksService.shortcutPublished.subscribe(sh => {
      this.shortcut = sh.filter(s => s.link);
    });
    this.contentClick();
  }

  navigateTo(l: string) {
    this._router.navigate([l]);
  }

  toggleSidebar(e) {
    e.preventDefault();
    $(".page-content").toggleClass("toggled");
    $(".sidebar").toggleClass("toggled");
    $(".navbar-brand").toggleClass("toggled");
    $(".navbar-brand").css('width', '');
  }

  contentClick() {
    $(".page-content").click(() => {
      if ($(window).width() < 768 && $(".sidebar.toggled").length === 0) {
        $(".page-content").toggleClass("toggled");
        $(".sidebar").toggleClass("toggled");
        $(".navbar-brand").toggleClass("toggled");
      }
      if($("#messages").hasClass("show")) {
        $("#messages").removeClass("show");
      }
    });
  }
}

import { Injectable } from '@angular/core';

import { HttpWrapper } from '../../../shared';
import { Observable } from 'rxjs';
import { DataBaseAction } from '../vessels.service';
import moment from 'moment/moment';
import { Organisation } from 'src/app/organisation/organisation.service';
import { DateTimeHelpers } from 'src/app/shared/helpers/date-time.helpers';

export interface CrewContract {
  managementOrganisationId: string;
  date: string;
  durationInMonths: number;
  basicSalaryUSD: number;
  guaranteedOvertimeUSD: number;
  leavePayUSD: number;
  leaveSubsistenceUSD: number;
  tankerAllowanceUSD: number;
  contractDocumentId: string;
  extensions: CrewContract[]
}

export interface AppraisalItem {
  item: string,
  grade?: string,
  category: string,
  remark?: string
}


export interface CrewAppraisal {
  id: string;
  date: string;
  documentId: string;
  masterId: string;
  assessorId: string;
  appraisals: AppraisalItem[]
}

export interface CrewChange {
  id: string;
  voyageNumber: number;
  vesselName: string;
  vesselIMO: string;
  rank: string;
  isCancelled: boolean;
  isDeleted: boolean;
  item: CrewChangeItem[];
  contract: CrewContract;
  appraisals?: CrewAppraisal[]
}

export interface CrewChangeItem {
  plan: string;
  place: string;
  time: number;
  isCancelled: boolean;
}

export interface SeamanDetails {
  seamanNumber: string;
  seamanExpirationDate: string;
  seamanFileId: string;
}


export interface PassportDetails {
  passportNumber: string;
  passportIssueDate: string;
  passportExpirationDate: string;
  passportFileId: string;
}


export interface CrewIds {
  passport: PassportDetails[];
  seaman: SeamanDetails[];
  contactInfo: string[];
}

export interface Crew {
  id: string;
  firstName: string;
  lastName: string;
  middleName: string;
  photoDocumentId: string;
  birthDate: string;
  passportDetails: PassportDetails;
  seamanDetails: SeamanDetails;
  nationality: string;
  contactNumber: string;
  cvDocumentId: string;
  actions: DataBaseAction[];
  crewChanges: CrewChange[];
  oldDetails: CrewIds;
}

@Injectable()
export class CrewChangeServices {
  constructor(private _httpWrapper: HttpWrapper) { }

  getPlanningPhases() {
    return ['Incoming Flight', 'On Board', 'Off Board', 'Outgoing Flight'];
  }

  saveCrew(data: Crew): Observable<Crew> {
    return this._httpWrapper.post('vessels/crew/add', data);
  }

  updateCrew(data: Crew) {
    return this._httpWrapper.post('vessels/crew/update', data);
  }

  getCrewAppraisalItems(documentId: string) {
    return this._httpWrapper.get<any[]>(`vessels/crew/appraisal/${documentId}`);
  }

  getCrewByIMO(imo: string) {
    return this._httpWrapper.get<Crew[]>(`vessels/crew/get-by-vessel/${imo}`);
  }

  getOnBoardCrewByIMO(imo: string) {
    return this._httpWrapper.get<Crew[]>(`vessels/crew-change/on-board-crew/${imo}`);
  }

  getAllCrew(): Observable<Crew[]> {
    return this._httpWrapper.get<Crew[]>('vessels/crew/getAll');
  }

  getExcelReport() {
    return this._httpWrapper.download(`vessels/crew/excel`);
  }

  getLastExcelReport() {
    return this._httpWrapper.download(`vessels/crew/LastPlanExcel`);
  }

  getCrew(firstName: string, lastName: string, birthDate: string): Observable<Crew[]> {
    return this._httpWrapper.get<Crew[]>(`vessels/crew/${firstName}/${lastName}/${birthDate}`);
  }

  getOneCrewById(id: string): Observable<Crew> {
    return this._httpWrapper.get<Crew>(`vessels/crew/getone/${id}`);
  }

  calculateOnboardTime(crewChange: CrewChange) {
    if (crewChange) {
      const onboardItem = crewChange.item.find(c => c.plan === 'On Board');
      const offBoardItem = crewChange.item.find(c => c.plan === 'Off Board');
      if (onboardItem && !offBoardItem) {
        const today = new Date().getTime();
        if(onboardItem.time < today) {
          return moment().diff(moment(onboardItem.time), 'months', true).toFixed(2);
        } else {
          return 'On Board time is affter today';
        }
      }
      if (onboardItem && offBoardItem) {
        if(onboardItem.time < offBoardItem.time) {
          return moment(offBoardItem.time).diff(moment(onboardItem.time), 'months', true).toFixed(2);
        } else {
          return 'Off Board befre on Borad';
        }
      }
    }
    return '';
  }

  downloadCrew() {
    return this._httpWrapper.download(`vessels/crew/download`);
  }

  getCrewStatus(crew: Crew) {
    if (crew.crewChanges
      .filter(c => !c.isCancelled && !c.isDeleted &&
        !c.item.map(c1 => c1.plan).includes(this.getPlanningPhases()[3])
      ).length !== 0) {
      return 'On-board';
    }
    const currentTime = Date.now();
    if (crew.seamanDetails && crew.passportDetails && crew.seamanDetails.seamanExpirationDate &&
        (new Date(crew.seamanDetails.seamanExpirationDate).getTime() > currentTime) &&
        crew.passportDetails.passportExpirationDate &&
        new Date(crew.passportDetails.passportExpirationDate).getTime() > currentTime
        ) {
      return 'Rest';
    }
    return 'Incomplete';
  }

  findPlanAndOrganisation(crewChange: CrewChange, selectedPlan: string, organisationsMap: Map<string, Organisation>): [CrewChangeItem, Organisation] {
    const selectedPlanItem = crewChange?.item?.find(p => p.plan === selectedPlan);
    if (selectedPlanItem && organisationsMap) {
      const org = organisationsMap.get(selectedPlanItem.place);
      if (!org) {
        console.error(`Organisation not found for id ${selectedPlanItem.place}`);
      } else {
        return  [selectedPlanItem, org];
      }
    } else {
      return [undefined, undefined];
    }
  }

  planTime(crewChange: CrewChange, selectedPlan: string, organisationsMap: Map<string, Organisation>) {
    const [selectedPlanItem, org] = this.findPlanAndOrganisation(crewChange, selectedPlan, organisationsMap);
    if (selectedPlanItem && org) {
      return `${DateTimeHelpers.getDateTimeFromTimestamp(org.timezone, selectedPlanItem.time)}`;
    }
  }

  planPlace(crewChange: CrewChange, selectedPlan: string, organisationsMap: Map<string, Organisation>) {
    const [selectedPlanItem, org] = this.findPlanAndOrganisation(crewChange, selectedPlan, organisationsMap);
    if (selectedPlanItem && org) {
      return org.nameTranslations['en'];
    }
  }

  onboardTimeMonthsDays(crewChange: CrewChange) {
    const firstItem = crewChange?.item?.find(p => p.plan === 'On Board');
    const secondItem = crewChange?.item?.find(p => p.plan === 'Off Board');
    if (firstItem) {
      return `${moment(secondItem?.time).diff(moment(firstItem.time), 'months', true).toFixed(2)}
      (${moment(secondItem?.time).diff(moment(firstItem.time), 'days', true).toFixed(2)} days)`;
    } else {
      return 0;
    }
  }

  travelTimeDays(crewChange: CrewChange) {
    const incoming = crewChange?.item?.find(p => p.plan === 'Incoming Flight');
    const onBoard = crewChange?.item?.find(p => p.plan === 'On Board');
    const offBoard = crewChange?.item?.find(p => p.plan === 'Off Board');
    const outgoing = crewChange?.item?.find(p => p.plan === 'Outgoing Flight');
    if (incoming && onBoard && offBoard && outgoing) {
      return (moment(onBoard.time).diff(moment(incoming.time), 'days', true) +
        moment(outgoing.time).diff(moment(offBoard.time), 'days', true)).toFixed(2);
    } else if (incoming && onBoard) {
      return moment(onBoard.time).diff(moment(incoming.time), 'days', true).toFixed(2);
    } else {
      return 0;
    }
  }

  getLastCrewChange(crewChanges: CrewChange[]) {
    if(crewChanges && crewChanges.length > 0) {
      const temp = crewChanges
        .filter(c => !c.isCancelled && !c.isDeleted)
        .sort((a, b) => Math.max(...b.item.map(i => i.time)) - Math.max(...a.item.map(i => i.time)));
      return temp[0];
    }
    return;
  }

  calculateRating(crewAppraisals: CrewAppraisal[]) {
    let average = 0;
    if(crewAppraisals && crewAppraisals.length > 0) {
      const rate = crewAppraisals
      .flatMap(appraisal => appraisal.appraisals)
      .filter(a => a.category !== 'Training')
      .map(a => a.grade)
      .map(i => {
        if(i === 'a') {
          return 100;
        } else if (i === 'b') {
          return 80;
        } else if (i === 'c') {
          return 60;
        } else if (i === 'd') {
          return 40;
        } else if (i === 'e') {
          return 20;
        }
      });
      average = (rate.reduce((a, b)=> a+b, 0) / rate.length) / 20;
    }
    return [Array(Math.round(average)).fill(1), Array(Math.round(5 - average)).fill(1), Math.round(average * 10) / 10];
  }
}

<div class="card card-primary col-md-4">
  <div class="card-body card-profile">
    <div class="row justify-content-md-center">
      <img class="profile-user-img img-responsive img-circle"  [src]='thumbnail' alt="User profile picture">
    </div>   
    <div  class="d-flex justify-content-center">
      <label class="btn small text-muted">
        <i class="fa fa-camera" aria-hidden="true"></i>
        <input type="file"
        id="selectFile"
        name="selectFile"
        accept=".jpg"
        (change)=" openUpload($event)" hidden>
      </label>
    </div>
    <h3 class="profile-username text-center">{{user?.fullName}}</h3>

    <p class="text-muted text-center">{{user?.email}}</p>
    <ul id="portalUserName" class="list-group list-group-unbordered text-center">
      <li class="list-group-item" *ngFor="let digitalIdFile of user?.digitalIdFiles">
        <i class="fa fa-lock"></i>
        <a (click)="downloadFile(digitalIdFile.fileId, digitalIdFile.email)" class="btn btn-link">{{digitalIdFile.email}}</a>
      </li>
    </ul>
  </div>
  <!-- /.box-body -->
</div>

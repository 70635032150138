
import { NgModule } from '@angular/core';
import { HideIfNotInRoleDirective } from './hide-if-not-in-role.directive';
import { HideIfNotHaveAllRoleDirective } from './hide-if-not-have-all-roles.directive';


@NgModule({
  imports: [],
  exports: [ HideIfNotInRoleDirective, HideIfNotHaveAllRoleDirective ],
  declarations: [ HideIfNotInRoleDirective, HideIfNotHaveAllRoleDirective ]
})
export class AuthenticationModule { }

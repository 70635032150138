import {Component, EventEmitter, OnInit, ViewChild} from '@angular/core';
import {NoonReportBunker, NoonReportsService, NoonReportV202005, SailingStatus} from '../services/noon-reports.service';
import * as _ from 'lodash';
import {DateRange} from '../../../shared/components';
import {ActivatedRoute} from '@angular/router';
import * as Highcharts from 'highcharts';
import {DateTimeHelpers} from '../../../shared/helpers/date-time.helpers';
import {DataTableDirective} from 'angular-datatables';
import {Subject} from 'rxjs';
import {DataTablesService} from '../../../shared/datatables/data-tables.service';
import {UserLocalStoreService} from '../../../shared/services/user-local-store.service';
import {MessageLevel, PgMessagesService} from '../../../shared/services/pg-messages.service';
import {saveAs} from 'file-saver';
import {
  PgDateRangeTitleComponent,
  PgDateRangeTitleEnum, PgTitleComponent
} from '../../../shared/components/title-bar/title-bar.component';


interface VesselReports {
  vesselName: string;
  vesselIMO: string;
  reports: NoonReportV202005[];
}

interface VesselConsumptionPerDay {
  vesselName: string;
  vesselIMO: string;
  date: string;
  consumption: Map<string, number>;
}

interface BunkerStatusComponentSettings {
  selectedFuelType: string;
}

@Component({
  selector: 'app-bunker-status',
  templateUrl: './bunker-status.component.html'
})
export class BunkerStatusComponent implements OnInit, PgTitleComponent, PgDateRangeTitleComponent {
  @ViewChild(DataTableDirective, {static: true}) dtElement: DataTableDirective;

  title = new EventEmitter<string>();
  dateRangeSubTitle = new EventEmitter<[DateRange, PgDateRangeTitleEnum]>();
  _selectedDateRange: DateRange;
  consumptionPerDay: VesselConsumptionPerDay[];
  reports: NoonReportV202005[];
  _settings: BunkerStatusComponentSettings;
  get selectedFuelType(): string {
    return this._settings.selectedFuelType;
  }
  set selectedFuelType(value: string) {
    this._settings.selectedFuelType = value;
    this._userLocalStorage.save('BunkerStatusComponent', this._settings);
    this.prepareSummaryReport();
  }

  dtOptions: any;
  isDataTableInitialised = false;
  dtTrigger: Subject<any> = new Subject<any>();

  constructor(private _noonReportService: NoonReportsService,
              private _activatedRoute: ActivatedRoute,
              private _userLocalStorage: UserLocalStoreService,
              private _pgMessagesService: PgMessagesService) { }

  private static groupNoonReportV202005(reports: NoonReportV202005[]): VesselReports[] {
    return _.chain(reports)
      .groupBy(item => `${item.vesselName}§${item.vesselIMO}`)
      .map((items, key) => {
        const vessel = key.split('§')[0];
        const imo = key.split('§')[1];
        return {
          reports: items.sort((a, b) => a.date.timestamp - b.date.timestamp),
          vesselName: vessel,
          vesselIMO: imo
        };
      }).value();
  }

  ngOnInit() {
    const component = this;
    this.dtOptions = DataTablesService.getOptionsWithNoColumnsEn([{
        text: 'Download consumptions',
        action: () => component.downloadConsumptions()
      }], true,
      [[1, 'desc'], [0, 'asc']]);
    this._settings = this._userLocalStorage.get<BunkerStatusComponentSettings>('BunkerStatusComponent');
    if (!this._settings) {
      this._settings = { selectedFuelType: 'lsfo', };
    }
    this._activatedRoute.paramMap.subscribe(p => {
      this._selectedDateRange = {
        startDate: new Date(+p.get('startTime')),
        endDate: new Date(+p.get('endTime'))
      };
      this.title.emit('Bunker status report');
      this.dateRangeSubTitle.emit([this._selectedDateRange, PgDateRangeTitleEnum.defaultTitle]);

      this._noonReportService.getNoonReportsV202005Between(this._selectedDateRange).subscribe(reports => {
        this.reports = reports;
        this.plotConsumption(BunkerStatusComponent.groupNoonReportV202005(reports));
        this.prepareSummaryReport();
      });
    });
  }

  downloadConsumptions() {
    this._noonReportService.downloadConsumptionsReport(this._selectedDateRange.startDate.getTime(),
      this._selectedDateRange.endDate.getTime()).subscribe(b => {
      saveAs(b.body, `${DateTimeHelpers.formatLocalDate(this._selectedDateRange.startDate.getTime())}-to-` +
        `${DateTimeHelpers.formatLocalDate(this._selectedDateRange.endDate.getTime())}-consumptions.xlsx`);
    }, () => {
      this._pgMessagesService.publishMessage({
        level: MessageLevel.Error,
        message: 'Could not download bunker consumption report',
        topic: 'Voyages report'
      });
    });

  }

  totalBunkerConsumption(reports: NoonReportV202005[]): NoonReportBunker[] {
    const d: NoonReportBunker[] = reports.map(n =>
      n.noonReportBunkerStatus.sailingBunkerConsumption.map(i => i.bunkerConsumption)
        .concat(n.noonReportBunkerStatus.otherBunkerConsumption.map(i => i.bunkerConsumption))
        .flatMap(i => i)
      ).flatMap(i => i);
    return _.chain(d).groupBy(b => b.bunkerType)
      .map((v, k) => (
        {
          bunkerType: k,
          quantityMT: v.map(i => i.quantityMT).reduce((x, y) => x + y, 0)
        }
      )).value();
  }

  plotConsumption(data: VesselReports[]) {
    const d = data.map(i => (
      {
        vesselName: i.vesselName,
        bunker: this.totalBunkerConsumption(i.reports)
      }
    ));
    const chartSeries = _.chain(d).map(n => n.bunker).flatMap(i => i).groupBy(i => i.bunkerType)
      .map((v, k) => (
        {
          name: k,
          data: v.map(b => Math.round(b.quantityMT * 100) / 100)
        }
      )).value();
    Highcharts.chart('bunkerConsumption', {
      chart: { type: 'column' },
      title: { text: 'Fuel consumption' },
      xAxis: { categories: d.map(v => v.vesselName) },
      yAxis: {
        min: 0,
        title: {
          text: 'Total fuel consumption'
        },
        stackLabels: {
          enabled: true,
        }
      },
      legend: {
        align: 'right',
        x: -30,
        verticalAlign: 'top',
        y: 25,
        floating: true,
        backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || 'white',
        borderColor: '#CCC',
        borderWidth: 1,
        shadow: false
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: true
          }
        }
      },
      series: [{
        type: 'column',
        data: chartSeries
      }],
    });
  }

  groupConsumptions(v: NoonReportV202005[]): Map<string, number> {
    const consumptions = new Map(Object.keys(SailingStatus).map(sailingKey => (
      [sailingKey, NoonReportV202005.getTotalBunkerConsumption(
        v.map(v1 => NoonReportV202005.getSailingBunkerConsumption(v1, sailingKey as SailingStatus)),
        this.selectedFuelType)]
    )));

    consumptions.set('boilers', NoonReportV202005.getTotalBunkerConsumption(
      v.map(v1 => NoonReportV202005.getOtherBunkerConsumption(v1, 'boilers')),
      this.selectedFuelType));
    consumptions.set('powerGenerators', NoonReportV202005.getTotalBunkerConsumption(
      v.map(v1 => NoonReportV202005.getOtherBunkerConsumption(v1, 'powerGenerators')),
      this.selectedFuelType));
    consumptions.set('total', Array.from(consumptions.values()).reduce((i, j) => i + j, 0));
    consumptions.set('ME distance', v.reduce((i, j) => i + j.voyageDetails.distanceByMainEngine, 0));

    return consumptions;
  }

  resetTable() {
    if (!this.isDataTableInitialised) {
      this.dtTrigger.next(false);
      this.isDataTableInitialised = true;
    } else {
      this.dtElement.dtInstance.then((dtInstance) => {
        dtInstance.destroy();
        this.dtTrigger.next(false);
      });
    }
  }

  prepareSummaryReport() {
    if (this._settings && this._settings.selectedFuelType) {
      this.consumptionPerDay = _.chain(this.reports)
        .groupBy(item => `${item.vesselName}§${item.vesselIMO}§${DateTimeHelpers.formatLocalDate(item.date.timestamp)}`)
        .map((items, key) => {
          const vessel = key.split('§')[0];
          const imo = key.split('§')[1];
          return {
            vesselName: vessel,
            vesselIMO: imo,
            date: key.split('§')[2],
            consumption: this.groupConsumptions(items)
          };
        }).value();

      this.resetTable();
    }
  }
}

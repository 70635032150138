<div class="btn-group" role="toolbar">
  <div class="btn-group"  aria-label="Button group with nested dropdown">
      <button class="btn btn-outline-secondary btn-light dropdown-toggle"
              type="button" id="selectButtonGroup" data-bs-toggle="dropdown" aria-expanded="false">
              Select vessels
      </button>
      <ul class="dropdown-menu me-3" aria-labelledby="selectButtonGroup">
        <li><a class="dropdown-item">
          <input
          class="form-check-input"
          type="checkbox"
          (click)="onSelectAll()"
          id="dropdown"
          checked
          [checked]="active"
          />      
          Select All
        </a>
        </li>
        <li *ngFor="let i of locations; let v = index">
          <a class="dropdown-item">
            <input
            type="checkbox"
            [ngModel]="i.selected"
            (ngModelChange)="onItemSelectedInternal(i)"
            class="form-check-input"
            id="{{ v }}"
          />
          {{ i.vesselName }}
          </a>
        </li>
      </ul>
  </div>
</div>

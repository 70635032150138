import { Injectable } from '@angular/core';
import { HttpWrapper } from '../shared';
import { forkJoin, Observable, switchMap } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

export interface DocumentReference {
  id: string;
}

interface FileChunk {
  partNumber: number;
  blob: Blob;
}

interface UploadedChunks {
  id: string;
  numberOfChunks: number;
  documentType: string;
  contentType: string;
  fileName: string;
}

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  constructor(private _httpWrapper: HttpWrapper) { }

  add(docType: string, fileList: FileList, fileName: string = null): Observable<DocumentReference> {
    return this._httpWrapper.postMultiPart<DocumentReference>('docs', this.toFormData(docType, fileName, fileList));
  }

  download(fileId: string) {
    return this._httpWrapper.download(`docs/download/${fileId}`);
  }

  private toFormData(docType: string,fileName: string = null, fileList: FileList) {
    const formData = new FormData();
    formData.append('documentType', docType);
    formData.append('fileName', fileName);
    formData.append('file', fileList.item(0));
    return formData;
  }

  addChunks(docType: string, fileToUpload: any, fileName: string = null): Observable<DocumentReference> {
    const fileId = uuidv4();
    const chunkSize =  900000;
    const numberOfChunks = Math.ceil(fileToUpload.size / chunkSize);

    const chunks = [...Array(numberOfChunks).keys()].map(i => {
      const start = i * chunkSize;
      const chunkEnd = Math.min(start + chunkSize, fileToUpload.size);
      const chunk = fileToUpload.slice(start, chunkEnd);
      return {
        partNumber: i,
        blob: chunk,
      };
    });

    return this.uploadChunks(fileId, chunks, docType, fileName);
  }

  uploadChunks(id: string, chunks: FileChunk[], docType: string, fileName: string) {
    return forkJoin(chunks.map(c => {
      const formData = new FormData();
      formData.append('id', id);
      formData.append('partNumber', c.partNumber.toString());
      formData.append('file', c.blob);
      return this._httpWrapper
        .postMultiPart(`docs/upload-chunk`, formData);
      }
    )).pipe(switchMap(() => {
      const uploadedChunks: UploadedChunks = {
        id: id,
        numberOfChunks: chunks.length,
        contentType: '',
        fileName: fileName,
        documentType: docType
      };
      return this.verifyChunks(uploadedChunks);
    }));
  }

  private verifyChunks(uploadedChunks: UploadedChunks) {
    return this._httpWrapper.post<DocumentReference, UploadedChunks>(`docs/verify-chunks`, uploadedChunks);
  }
}

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpTokenInterceptor } from './authentication/http-token-interceptor';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { routes } from './app.routes';

import { HomeModule } from './home/home.module';
import { SharedModule } from './shared/shared.module';
import { AuthenticationModule } from './authentication/authentication.module';
import { TransportationModule } from './transportation/transportation.module';
import { FormsModule } from '@angular/forms';
import { LPGDistModule } from './lpg-dist/lpg-dist.module';
import { PhoneBookModule } from './phone-book/phone-book.module';
import { OrganisationModule } from './organisation/organisation.module';
import { ContractModule } from './contract/contract.module';
import { DataTablesModule } from 'angular-datatables';
import { SecurityModule } from './security/security.module';
import { LoaderService} from './shared/components/loader/loader.service';
import { LoaderInterceptor} from './shared/components/loader/loader.interceptor';
import { MarketModule } from './market/market.module';
import { GlobalHttpInterceptor } from './shared/handler-error/global-http-interceptor';
import { HandlerErrorComponent } from './shared/handler-error/handler-error.component';
import { CorrespondenceModule } from './correspondence/correspondence.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AnalyticsModule } from './analytics/analytics.module';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes, {}),
    HomeModule,
    AuthenticationModule,
    TransportationModule,
    LPGDistModule,
    PhoneBookModule,
    OrganisationModule,
    ContractModule,
    SharedModule,
    DataTablesModule,
    MarketModule,
    SecurityModule,
    CorrespondenceModule,
    AnalyticsModule,
    ServiceWorkerModule.register('/sw.js', { enabled: true })
  ],
  declarations: [AppComponent, HandlerErrorComponent],

  providers: [LoaderService, {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpTokenInterceptor,
    multi: true
  }, { provide:
    HTTP_INTERCEPTORS,
    useClass: LoaderInterceptor,
    multi: true
  }, {
    provide: HTTP_INTERCEPTORS,
    useClass: GlobalHttpInterceptor,
    multi: true
  }],

  bootstrap: [AppComponent],
  exports: [RouterModule]
})
export class AppModule { }

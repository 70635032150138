import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { UserLocalStoreService } from '../services/user-local-store.service';

export interface Shortcut {
  name: string;
  link: string;
}

@Injectable({
  providedIn: 'root',
})
export class ShortcutLinksService {

  _shortcutSource = new Subject<Shortcut[]>();
  shortcutPublished = this._shortcutSource.asObservable();
  constructor(private _userLocalStorage: UserLocalStoreService) { }

  publishShortcuts(shortcuts: Shortcut[]) {
    this._shortcutSource.next(shortcuts);
    this._userLocalStorage.save('ShortCut', shortcuts);
  }
}

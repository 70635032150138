<div *appHideIfNotInRole="['phonebook-viewer']">
  <div class="row">
    <div class="col-md-12">
      <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
        id="tblSearchCust"
        class="table table-sm table-striped  table-bordered ">
        <thead>
          <tr>
            <th>عنوان</th>
            <th>نام</th>
            <th>شرکت</th>
            <th>شماره دفتر</th>
            <th>شماره همراه</th>
            <th>مشاهده جزییات</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let info of phoneBookData">
            <td>{{info.title}}</td>
            <td>{{info.name}}</td>
            <td>{{info.companyName}}</td>
            <td>{{findPhoneNumberFromCategory(info.phoneNumbers,
              'office')}}</td>
            <td>{{findPhoneNumberFromCategory(info.phoneNumbers,
              'mobile')}}</td>
            <td>
              <button id="info" name="info" type="submit" class="btn btn-link"
                (click)="showinfo(info)"
                *appHideIfNotInRole="['phonebook-viewer']">
                <i class="fa fa-info"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-handler-error',
  templateUrl: './handler-error.component.html',
})
export class HandlerErrorComponent implements OnInit {


  statusText: string;
  message: string;
  status: string;

  constructor(private _activateRoute: ActivatedRoute,
  ) { }

  ngOnInit() {

    this._activateRoute.paramMap.subscribe(p => {
      this.statusText = p.get('statusText');
      this.status = p.get('status');
      this.message = p.get('message');
    });
  }
}

<div class="row" style="text-align: center;">
  <div class="col-lg-3 col-md-3">
    <div class="card shadow-sm card-body bg-primary info-box">
      <div class="inner">
        <h3>{{getSumOfAllLoadings().sumOfDailyLoading }}</h3>
        <p>روزانه</p>
      </div>
    </div>
  </div>
  <div class="col-lg-2 col-md-2">
    <div class="card shadow-sm card-body bg-warning info-box">
      <div class="inner">
        <h3>{{getSumOfAllLoadings().average }}</h3>
        <p>متوسط</p>
      </div>
    </div>
  </div>
  <div class="col-lg-2 col-md-2">
    <div class="card shadow-sm card-body bg-success info-box">
      <div class="inner">
        <h3>{{getSumOfAllLoadings().sumOfLoading }}</h3>
        <p>جمع</p>
      </div>
    </div>
  </div>
  <div class="col-lg-2 col-md-2">
    <div class="card shadow-sm card-body bg-info info-box">
      <div class="inner">
        <h3>{{getSumOfAllLoadings().sumOfEstimate }}</h3>
        <p>تخمین</p>
      </div>
    </div>
  </div>
  <div class="col-lg-3 col-md-3">
    <div class="card shadow-sm card-body bg-navy info-box">
      <div class="inner">
        <h3>{{getSumOfAllLoadings().payments }}
          <button id="excel" name="excel" type="submit" class="btn btn-link"
            (click)="xlsxReport()">
            <i class="fa fa-file-excel"></i>
          </button>
          <button id="excel" name="excel" type="submit" class="btn btn-link"
            (click)="lpgDistQuotaDataEntry()"
            *appHideIfNotInRole="['lpg-dist-editor']">
            <i class="fa fa-wpforms"></i>
          </button>
        </h3>
        <p>مجموع مانده حواله ریالی
        </p>
      </div>
      <div class="icon">
        <i class="ion ion-bag"></i>
      </div>
    </div>
  </div>
  <div class="col-md-6 form-group">
    <div *ngFor="let organisation of sortedOrganisationsLoadings">
      <div class="card shadow-sm">
        <div class="card-header">
          <div class="col-md-12 bg-secondary" style="text-align: center;">
            <table style="width: 100%;">
              <tr>
                <td style="width: 50%;"> برداشت شرکت
                  {{organisationFormatter(organisation.organisation)}}</td>
                <td style="width: 50%;"> مانده ریالی
                  {{organisation.paymentBalances}}</td>
              </tr>
            </table>

          </div>
        </div>
        <div class="card-body col-md-12">
          <table id="loadingTable"
            class="table table-sm table-striped table-responsive table-bordered w-100 d-block d-md-table ">
            <thead>
              <tr>
                <td> مبدا</td>
                <td>سهمیه ماه</td>
                <td>سهمیه تا این تاریخ</td>
                <td>بارگیری روزانه</td>
                <td> مجموع بارگیری</td>
                <td>تعادل</td>
                <td> تخمین آخر ماه</td>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let loading of organisation.loadings">
                <td>{{organisationFormatter(loading.sourceOrganisationId)
                  }}</td>
                <td>{{loading.quota | number }}</td>
                <td>{{loading.quotaToDate | number }}</td>
                <td>{{loading.selectedDayLoading | number }}</td>
                <td>{{loading.total | number }}</td>
                <td>{{loading.balance | number }}</td>
                <td>{{loading.estimate | number }}</td>
              </tr>
              <tr class="bg-gray">
                <td>مجموع</td>
                <td>{{getSumOfLoadings(organisation.loadings).sumOfQuota |
                  number }}</td>
                <td>{{getSumOfLoadings(organisation.loadings).sumOfQuotaToDate |
                  number }}</td>
                <td>{{getSumOfLoadings(organisation.loadings).sumOfDailyLoading
                  | number }}</td>
                <td>{{getSumOfLoadings(organisation.loadings).sumOfLoading |
                  number }}</td>
                <td>{{getSumOfLoadings(organisation.loadings).sumOfbalance |
                  number }}</td>
                <td>{{getSumOfLoadings(organisation.loadings).sumOfEstimate|
                  number }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6 col-xs-6">
    <div *ngFor="let discharge of groupedByRootData">
      <div class="card shadow-sm">
        <div class="card-header">
          <div class="col-md-12 bg-secondary" style="text-align: center;"> تخلیه
            در تاسیسات
            {{organisationFormatter(discharge.organisation)}}</div>
        </div>
        <div class="card-body col-md-12">
          <table id="dischargeTable"
            class="table table-sm table-striped table-responsive table-bordered w-100 d-block d-md-table"
            style="width:100%">
            <thead>
              <tr>
                <td>مقصد</td>
                <td>سهمیه ماه</td>
                <td>سهمیه تا این تاریخ</td>
                <td>بارگیری روزانه</td>
                <td>فروش</td>
                <td>موجودی</td>
                <td>مجموع بارگیری</td>
                <td>تعادل</td>
                <td>تخمین آخر ماه</td>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let discharge of discharge.discharges">
                <td>{{organisationFormatter(discharge.destinationOrganisationId)
                  }}</td>
                <td>{{discharge.quota | number }}</td>
                <td>{{discharge.quotaToDate | number }}</td>
                <td>{{discharge.selectedDayDischarge | number }}</td>
                <td>{{discharge.sold | number }}</td>
                <td>{{discharge.stored | number }}</td>
                <td>{{discharge.total | number }}</td>
                <td>{{discharge.balance | number }}</td>
                <td>{{discharge.estimate | number }}</td>
              </tr>
              <tr class="bg-gray">
                <td>مجموع</td>
                <td>{{getSumOfDischarge(discharge.discharges).sumOfQuota |
                  number }}</td>
                <td>{{getSumOfDischarge(discharge.discharges).sumOfQuotaToDate |
                  number }}</td>
                <td>{{getSumOfDischarge(discharge.discharges).sumOfDailyLoading
                  | number }}</td>
                <td>{{getSumOfDischarge(discharge.discharges).sumOfSold | number
                  }}</td>
                <td>{{getSumOfDischarge(discharge.discharges).sumOfStored |
                  number }}</td>
                <td>{{getSumOfDischarge(discharge.discharges).sumOfLoading |
                  number }}</td>
                <td>{{getSumOfDischarge(discharge.discharges).sumOfLoadingbalance
                  | number }}</td>
                <td>{{getSumOfDischarge(discharge.discharges).sumOfEstimate|
                  number }}</td>
              </tr>
            </tbody>
          </table>

        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-md-4">
    <div class="card">
      <form class="form-horizontal" #truckInformation="ngForm">
        <div class="card-body">
          <div class="form-group row">
            <label for="truckNumber" class="col-form-label col-md-4 ">شماره
              کشنده</label>
            <div class="col-md-8 col-sm-12 col-sx-12" style="direction: rtl">
              <app-number-plate id="truckNumber"
                [registrationNumber]="selectedTruck?.truckNumber"
                (registrationNumberUpdated)="selectedTruck.truckNumber = $event"
                [disabled]="mode !== 'new'"
                tabindex="1">
              </app-number-plate>
            </div>
          </div>
          <div class="form-group row">
            <label for="VIN" class="col-form-label col-md-4 ">VIN</label>
            <div class="col-md-8 col-sm-11 col-sx-11">
              <input type="text"
                class="form-control"
                name="VIN"
                id="VIN"
                [ngModel]="selectedTruck?.VIN"
                (ngModelChange)="selectedTruck.VIN = $event"
                tabindex="4"
                required
                [disabled]="mode !== 'new'">
            </div>
          </div>
          <div class="form-group row">
            <label for="organisationId" class="col-form-label col-md-4 ">شرکت
              دارنده کشنده</label>
            <div class="col-md-8 col-sm-11 col-xs-11">
              <select class="form-control"
                name="organisationId"
                id="organisationId"
                [(ngModel)]="transporter"
                (ngModelChange)="notUsedTankers()"
                tabindex="5"
                required
                [disabled]="editableField !== 'transporter' && mode !=='new'">
                <option *ngFor="let organisation of organisations"
                  value="{{organisation.name}}">
                  {{organisation.nameTranslations['fa']}}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <label for="tanker" class="col-form-label col-md-4 ">شماره
              تانکر</label>
            <div class="col-md-8">
              <app-auto-complete
                [formatter]="tankerValueFormatter"
                [(ngModel)]="tanker"
                [items]="tankersList"
                name="tanker"
                [disabled]="editableField !== 'tanker' && mode !=='new'">
              </app-auto-complete>
            </div>
          </div>
          <div class="form-group row">
            <label for="driverName" class="col-form-label col-md-4 ">نام
              راننده</label>
            <div class="col-md-8 col-sm-11 col-xs-11">
              <input type="text"
                class="form-control"
                name="driverName"
                id="driverName"
                [(ngModel)]="driver.name"
                tabindex="7"
                [disabled]="editableField !== 'driver' && mode !=='new'">
            </div>
          </div>
          <div class="form-group row">
            <label for="imei" class="col-form-label col-md-4 ">شماره دستگاه
              ردیاب</label>
            <div class="col-md-8 col-sm-11 col-xs-11">
              <app-auto-complete
                [formatter]="trackerValueFormatter"
                [(ngModel)]="tracker"
                [items]="trackersList"
                name="tracker"
                [disabled]="editableField !== 'tracker' && mode !=='new'">
              </app-auto-complete>
            </div>
          </div>
          <div class="form-group row">
            <label for="licenseNumber"
              class="col-form-label col-md-4 ">File</label>
            <div class="col-md-8">
              <app-chunk-file-uploader
                [accept]="'.pdf, .jpg'"
                name="document"
                (filesChanged)="uploadDocument($event)"
                [disabled]="editableField !== 'file' && mode !=='new'">
              </app-chunk-file-uploader>
            </div>
          </div>
          <div class="form-group row">
            <label for="isActive" class="col-form-label col-md-4 ">فعال</label>
            <div class="col-md-8">
              <input type="checkbox"
                id="isActive"
                name="isActive"
                [ngModel]="selectedTruck?.active"
                (ngModelChange)="selectedTruck.active = $event"
                tabindex="9"
                [disabled]="editableField === 'information'">
            </div>
          </div>
          <div class="form-group row">
            <label for="isDeleted" class="col-form-label col-md-4 ">حذف
              شده</label>
            <div class="col-md-8">
              <input type="checkbox"
                id="isDeleted"
                name="isDeleted"
                [ngModel]="selectedTruck?.isDeleted"
                (ngModelChange)="selectedTruck.isDeleted = $event"
                [disabled]="editableField === 'information'">
            </div>
          </div>
          <div class="form-group row">
            <div>
              <button type="submit"
                class="btn btn-primary pull-right"
                [disabled]="!truckInformation.form.valid"
                (click)="add()"
                [disabled]="editableField === 'information'"
                tabindex="15">Save
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="card card-body">
      <div class="col-md-12">
        <table
          class="table table-sm table-striped table-bordered">
          <thead>
            <tr>
              <th>نوع</th>
              <th>شناسه</th>
              <th>تاریخ شروع</th>
              <th>تاریخ پایان</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let object of associatedObjectList">
              <td>{{object.type}}</td>
              <td>{{object.objectId}}</td>
              <td>{{object.startTime}}</td>
              <td>{{object.endTime}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="col-md-4" *ngIf="selectedTruck && selectedTruck.fileId">
    <app-pdf-viewer [fileId]="selectedTruck.fileId"></app-pdf-viewer>
  </div>
  <div class="col-md-4" *ngIf="tanker && tanker.fileId">
    <app-pdf-viewer [fileId]="tanker.fileId"></app-pdf-viewer>
  </div>
</div>

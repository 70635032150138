
<section class="content">
  <div class="row">
    <div class="col-lg-3">
      <div class="card card-body shadow-sm  bg-info info-box">
        <div class="inner">
          <h3>{{numberOfBunkering}}</h3>
          <p>Bunker operations</p>
        </div>
        <div class="icon">
          <i class="icon icon-bag"></i>
        </div>
      </div>
    </div>
    <div class="col-lg-3">
      <div class="card card-body shadow-sm  bg-info info-box">
        <div class="inner">
          <h3>{{numberOfBunkeringLocations}}</h3>
          <p>Bunkering locations</p>
        </div>
        <div class="icon">
          <i class="icon icon-bag"></i>
        </div>
      </div>
    </div>
    <div class="col-lg-3">
      <div class="card card-body shadow-sm  bg-info info-box">
        <div class="inner">
          <h3>{{numberOfSuppliers}}</h3>
          <p>Suppliers</p>
        </div>
        <div class="icon">
          <i class="icon icon-bag"></i>
        </div>
      </div>
    </div>
    <div class="col-lg-3">
      <div class="card card-body shadow-sm bg-info info-box">
        <div class="inner">
          <h3 class="text-truncate">{{organisations?.get(biggestSupplierOrganisationId)?.nameTranslations['en']}}</h3>
          <p>Supplied {{biggestSupplierProvidedTonnage | number}} tons</p>
        </div>
        <div class="icon">
          <i class="icon icon-bag"></i>
        </div>
      </div>
    </div>
  </div>
  <div class="row" >
    <div class="col-md-12">
      <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" id="table-bunker"
             class="table table-sm table-striped table-bordered">
        <thead>
        <tr>
          <th>Document & Edit</th>
          <th>BDN NO</th>
          <th>Vessel</th>
          <th>Supplier</th>
          <th>Location</th>
          <th>Product</th>
          <th>Quantity</th>
          <th>Date</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let bdn of bunkeringDeliveryNote">
          <td>
            <a (click)="downloadFile(bdn.fileId, bdn.vesselName)" *ngIf ="bdn.fileId">
              <i class="fa fa-download me-2" aria-hidden="true"></i>
            </a>
            <button id="edit"
                    name="edit"
                    type="submit"
                    class="btn btn-link"
                    (click)="editRecord(bdn)">
              <i class="fa fa-pencil-square-o"></i>
            </button>
          </td>
          <td>{{bdn.bdnNumber}}</td>
          <td>{{bdn.vesselName}}</td>
          <td>{{organisations.get(bdn.supplierOrganisationId).nameTranslations['en']}}</td>
          <td>{{organisations.get(bdn.locationOrganisationId).nameTranslations['en']}}</td>
          <td>{{bdn.product}}</td>
          <td>{{bdn.quantity}}</td>
          <td>{{dateTime(bdn.date)}}</td>
        </tr>
        </tbody>
      </table>
    </div >
  </div>
</section>

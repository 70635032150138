<div class="row">
  <div class="card col-md-12 overflow-auto vh-100">
    <div class="card-body">
      <table
        datatable
        [dtOptions]="dtOptions"
        [dtTrigger]="dtTrigger"
        id="voyages"
        class="table table-bordered table-striped justify-content-center">
        <thead class="sticky-top bg-body">
          <tr>
            <td>Vessel</td>
            <td>Ship <br> Voyage <br> Number</td>
            <td>Loading NOR</td>
            <td>Loading <br> Port</td>
            <td>Discharge NOR</td>
            <td>Discharge <br /> Country</td>
            <td>Discharge <br /> Port</td>
            <td>Last B/L date</td>
            <td>Sum of Real</td>
            <td>Sum of Revise</td>
            <td>Last Discharge </td>
            <td>Ports *</td>
            <td>Berth time **</td>
            <td>Length ***</td>
            <td>Voyage Length ****</td>
            <td>Total Laytime *****</td>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let v of voyagesLength">
            <td>{{v.voyage.vesselName}}</td>
            <td><a
                [routerLink]="['/vessels/voyage', v.voyage.id]">{{v.voyage.shipVoyageNo}}</a></td>
            <td>{{formatNorDates(v.voyage, 'loading')}}</td>
            <td>{{formatNorPort(v.voyage, 'loading')}}</td>
            <td>{{formatNorDates(v.voyage, 'discharging')}}</td>
            <td>{{formatNorLocation(v.voyage, 'discharging')}}</td>
            <td>{{formatNorPort(v.voyage, 'discharging')}}</td>
            <td>{{formatProductDates(v.voyage)}}</td>
            <td>{{formatProductWeights(v.voyage) | number}}</td>
            <td>{{formatReviseWeights(v.voyage)}}</td>
            <td>{{formatDischargeDates(v.voyage)}}</td>
            <td>{{_vesselsService.countPorts(v.voyage,
              'loading')}}/{{_vesselsService.countPorts(v.voyage,
              'discharge')}}</td>
            <td>{{calculateBerthTime(v.voyage, 'loading') | number:
              '1.2-2'}}</td>
            <td>{{voyageLengthFromLoadingToDischarge(v.voyage)}}</td>
            <td>{{v.days}}</td>
            <td>{{calculateLaytime(v.voyage, 'loading') | number: '1.2-2'}}/
              {{calculateLaytime(v.voyage, 'discharge') | number: '1.2-2'}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div class="row">
  <i>
    <ul>
      <li>* No of Loading ports/No of discharge ports</li>
      <li>** Berth time</li>
      <li>*** Length from loading to discharge</li>
      <li>**** Length between 2 loading NORs in days</li>
      <li>***** Laytimes are in hours</li>
    </ul>
  </i>
</div>

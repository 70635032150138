import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { VoyagePartComponent } from '../voyage-part-component';
import { Voyage } from '../../vessels.service';
import { Organisation, OrganisationService } from 'src/app/organisation/organisation.service';

@Component({
  selector: 'app-charterer',
  templateUrl: './charterer.component.html',
})
export class ChartererComponent implements OnInit, VoyagePartComponent {
  @Output() changed: EventEmitter<Voyage> = new EventEmitter();
  @Output() cancelled: EventEmitter<any> = new EventEmitter();
  key = 'Misc-Document';
  _uploadedFileId: string;
  _selectedVoyage: Voyage;
  _newUploadedFileId: string;

  charterer: Organisation;
  organisations: Organisation[];
  documentTypes: string[];

  constructor(
    private organisationService: OrganisationService
  ) {}

  @Input() set fileId(value: string) {
    this._uploadedFileId = value;
  }
  @Input() set data(value: any) {
    this._selectedVoyage = value as Voyage;
  }
  @Input() set updatedFileId(value: string) {
    this._newUploadedFileId = value;
  }
  ngOnInit(): void {
    this.organisationService.get().subscribe(organisations => {
      this.organisations = organisations.filter(o => o.properties['isCharterer'] === true);
      if(this._selectedVoyage.charterer) {
        this.charterer = this.organisations.find(o => o.name === this._selectedVoyage.charterer);
      }
    });
  }

  saveCharterer() {
    this._selectedVoyage.charterer = this.charterer.name;
    this.changed.emit(this._selectedVoyage);
  }

  deleteChartere() {
    if (confirm(`Are you sure to delete this document`)) {
      this._selectedVoyage.charterer = null;
      this.changed.emit(this._selectedVoyage);
    }
  }

}

import {Component, EventEmitter, OnInit} from '@angular/core';
import {SofDefinitionEntry, SofTemplateService} from '../sof-template.service';
import {MessageLevel, PgMessagesService} from '../../../../shared/services/pg-messages.service';
import {PgTitleComponent} from '../../../../shared/components/title-bar/title-bar.component';

@Component({
  selector: 'app-sof-entry',
  templateUrl: './sof-entry.component.html'
})
export class SofEntryComponent implements OnInit, PgTitleComponent {
  title = new EventEmitter<string>();

  sofEntry: SofDefinitionEntry = {
    sofType: 'L',
    phase: '01',
    events: '',
    canonical: ''
  };

  constructor(private _sofTemplateService: SofTemplateService,
              private _pgMessagesService: PgMessagesService) { }

  ngOnInit() {
    this.title.emit('New Sof Item');
  }

  saveEntry() {
    this._sofTemplateService.add(this.sofEntry).subscribe(() => {
      this._pgMessagesService.publishMessage({
        message: `Added the new SOF definition.`,
        level: MessageLevel.Info,
        topic: `Sof entry`
      });

    }, () => {
      this._pgMessagesService.publishMessage({
        message: `Could not add new entry.`,
        level: MessageLevel.Error,
        topic: `Sof entry`
      });
    });
  }
}

import {Component, EventEmitter, OnInit} from '@angular/core';

import * as L from 'leaflet';
import { Map } from 'leaflet';
import 'iso8601-js-period';
import 'leaflet.markercluster';
import 'leaflet-toolbar';
import 'leaflet-easyprint';
import 'leaflet-timedimension';
import 'leaflet.fullscreen';
import {DateTimeHelpers} from '../../../shared/helpers/date-time.helpers';
import {PgTitleComponent} from '../../../shared/components/title-bar/title-bar.component';

@Component({
  selector: 'app-sea-shark-status',
  templateUrl: './sea-shark.component.html'
})
export class SeaSharkStatusComponent implements OnInit, PgTitleComponent {
  title = new EventEmitter<string>();
  _map: Map;

  ngOnInit() {
    this.title.emit('Sea shark status');
    this.setupMap();
  }

  getLocationPopupContent(report: any) {
    return `
<h4></h4>
<table>
<tr>
<td>Date &nbsp;</td>
<td>${DateTimeHelpers.formatLocalDate(report['Date '])}</td>
</tr>
<tr>
<td>Report &nbsp;</td>
<td>${report['Comment']}</td>
</tr>
</table>
`;
  }

  getMarker(report: any) {
    const markerOptions: L.MarkerOptions = {
      icon: L.divIcon({
        html: `<i class='fa fa-ship' style='font-size:20px; opacity: 0.9'>
               </i><br>`,
        iconSize: [30, 30],
        className: 'myDivIcon'
      }),
    };

    return L.marker([report['lat'], report['lng']], markerOptions)
      .bindPopup(this.getLocationPopupContent(report), {maxWidth: 500});
  }

  getBounds(reports: any[]) {
    const points: number[][] = reports.map(r => [r['lat'], r['lng']]);
    return new L.LatLngBounds(points);
  }

  setupMap() {
    if (!this._map) {
      this._map = L.map('mapid1', {
        zoom: 14,
        fullscreenControl: true,
        fullscreenControlOptions: {
          position: 'topleft'
        },
      } as any);
      this._map.scrollWheelZoom.disable();

      const baseMap = {
        'osm': L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          attribution: ''
        })
      };

      const overlayMaps = {
        'Territorial sea (12 nm)': L.tileLayer.wms('https://geo.vliz.be/geoserver/MarineRegions/wms', {
          layers: 'eez_12nm',
          transparent: true
        }),
        'Internal waters': L.tileLayer.wms('https://geo.vliz.be/geoserver/MarineRegions/wms', {
          layers: 'eez_internal_waters',
          transparent: true
        }),
        '24 nm': L.tileLayer.wms('https://geo.vliz.be/geoserver/MarineRegions/wms', {
          layers: 'eez_24nm',
          transparent: true
        }),
        'Maritime boundaries': L.tileLayer.wms('https://geo.vliz.be/geoserver/MarineRegions/wms', {
          layers: 'eez_boundaries',
          transparent: true
        }),
      };

      for (const k in overlayMaps) {
        if (Object.prototype.hasOwnProperty.call(overlayMaps, k)) {
          overlayMaps[k].setOpacity(0.3);
        }
      }

      baseMap['osm'].addTo(this._map);

      L.control.layers(baseMap, overlayMaps).addTo(this._map);

      /* eslint-disable */
      const reports = [ {Date: 1542369600000, lat: 25.2966666667, lng: 56.5133333333, Position: 25, Comment: 'NOON REPORT'},
                        {Date: 1542456000000, lat: 25.295, lng: 56.515, Position: 25, Comment: 'NOON REPORT'},
                        {Date: 1542542400000, lat: 23.4666666667, lng: 59.0866666667, Position: 23, Comment: 'NOON REPORT'},
                        {Date: 1542628800000, lat: 19.8483333333, lng: 58.8033333333, Position: 19, Comment: 'NOON REPORT'},
                        {Date: 1542715200000, lat: 16.7933333333, lng: 55.6816666667, Position: 16, Comment: 'NOON REPORT'},
                        {Date: 1542801600000, lat: 14.6516666667, lng: 51.53, Position: 14, Comment: 'NOON REPORT'},
                        {Date: 1542888000000, lat: 13.1216666667, lng: 47.49, Position: 13, Comment: 'NOON REPORT'},
                        {Date: 1542974400000, lat: 12.6816666667, lng: 43.335, Position: 12, Comment: 'NOON REPORT'},
                        {Date: 1543060800000, lat: 16.19, lng: 41.085, Position: 16, Comment: 'NOON REPORT'},
                        {Date: 1543147200000, lat: 19.1883333333, lng: 38.8366666667, Position: 19, Comment: 'NOON REPORT'},
                        {Date: 1543233600000, lat: 22.91, lng: 37.01, Position: 22, Comment: 'NOON REPORT'},
                        {Date: 1543320000000, lat: 24.015, lng: 36.02, Position: 24, Comment: 'NOON REPORT'},
                        {Date: 1543406400000, lat: 24.2716666667, lng: 35.945, Position: 24, Comment: 'NOON REPORT'},
                        {Date: 1543492800000, lat: 26.7783333333, lng: 34.6733333333, Position: 26, Comment: 'NOON REPORT'},
                        {Date: 1543579200000, lat: 29.7783333333, lng: 32.58, Position: 29, Comment: 'NOON REPORT'},
                        {Date: 1543622400000, lat: 28.8066666667, lng: 32.8866666667, Position: 28, Comment: 'NOON REPORT'},
                        {Date: 1543752000000, lat: 25.77, lng: 38.355, Position: 25, Comment: 'NOON REPORT'},
                        {Date: 1543838400000, lat: 25.1083333333, lng: 35.555, Position: 25, Comment: 'NOON REPORT'},
                        {Date: 1543924800000, lat: 24.7333333333, lng: 35.4483333333, Position: 24, Comment: 'NOON REPORT'},
                        {Date: 1544011200000, lat: 24.7433333333, lng: 35.3283333333, Position: 24, Comment: 'NOON REPORT'},
                        {Date: 1544097600000, lat: 24.7516666667, lng: 35.38, Position: 24, Comment: 'NOON REPORT'},
                        {Date: 1544184000000, lat: 24.5833333333, lng: 35.3566666667, Position: 24, Comment: 'NOON REPORT'},
                        {Date: 1544270400000, lat: 24.5783333333, lng: 35.4316666667, Position: 24, Comment: 'NOON REPORT'},
                        {Date: 1544356800000, lat: 24.7183333333, lng: 35.3133333333, Position: 24, Comment: 'NOON REPORT'},
                        {Date: 1544443200000, lat: 24.61, lng: 35.3816666667, Position: 24, Comment: 'NOON REPORT'},
                        {Date: 1544529600000, lat: 24.5933333333, lng: 35.3916666667, Position: 24, Comment: 'NOON REPORT'},
                        {Date: 1544616000000, lat: 24.6916666667, lng: 35.43, Position: 24, Comment: 'NOON REPORT'},
                        {Date: 1544702400000, lat: 24.21, lng: 36.045, Position: 24, Comment: 'NOON REPORT'},
                        {Date: 1544788800000, lat: 24.7483333333, lng: 35.6083333333, Position: 24, Comment: 'NOON REPORT'},
                        {Date: 1544875200000, lat: 24.645, lng: 35.4133333333, Position: 24, Comment: 'NOON REPORT'},
                        {Date: 1544961600000, lat: 24.725, lng: 35.4416666667, Position: 24, Comment: 'NOON REPORT'},
                        {Date: 1545048000000, lat: 24.6266666667, lng: 35.5083333333, Position: 24, Comment: 'NOON REPORT'},
                        {Date: 1545134400000, lat: 23.8133333333, lng: 35.7033333333, Position: 23, Comment: 'NOON REPORT'},
                        {Date: 1545220800000, lat: 23.8783333333, lng: 35.675, Position: 23, Comment: 'NOON REPORT'},
                        {Date: 1548244800000, lat: 23.8783333333, lng: 35.6733333333, Position: 23, Comment: 'NOON REPORT'},
                        {Date: 1556193600000, lat: 23.8233333333, lng: 35.8666666667, Position: 23, Comment: 'NOON REPORT'},
                        {Date: 1556280000000, lat: 26.3616666667, lng: 34.4216666667, Position: 26, Comment: 'NOON REPORT'},
                        {Date: 1556366400000, lat: 29.1316666667, lng: 32.7983333333, Position: 29, Comment: 'NOON REPORT'},
                        {Date: 1556452800000, lat: 29.4983333333, lng: 32.4766666667, Position: 29, Comment: 'NOON REPORT'},
                        {Date: 1557921600000, lat: 29.4983333333, lng: 32.4766666667, Position: 29, Comment: 'NOON REPORT'},
                        {Date: 1543708800000, lat: 25.15, lng: 35.55, Position: 25, Comment: 'By Master in Report'},
                        {Date: 1543881600000, lat: 24.8, lng: 35.55, Position: 24, Comment: 'By Master in Report'},
                        {Date: 1545098040000, lat: 23.77, lng: 35.775, Position: 23, Comment: 'as per Egyptian navy instructions '},
                        {Date: 1545091200000, lat: 23.8783333333, lng: 35.6733333333, Position: 23, Comment: 'as per Egyptian navy instructions '},
                        {Date: 1548115200000, lat: 24.7033333333, lng: 35.2383333333, Position: 24, Comment: 'as per Egyptian navy instructions '},
                        {Date: 1548115200000, lat: 23.9416666667, lng: 35.5541666667, Position: 23, Comment: 'as per Egyptian navy instructions '},
                        {Date: 1548115200000, lat: 23.8813333333, lng: 35.6733333333, Position: 23, Comment: 'as per Egyptian navy instructions '},
                        {Date: 1548115200000, lat: 23.8988333333, lng: 35.6631666667, Position: 23, Comment: 'as per Egyptian navy instructions '},
                        // tslint:disable-next-line:max-line-length
                        {Date: 1548115200000, lat: 23.9086666667, lng: 35.6555, Position: 23, Comment: 'as per Egyptian navy instructions '},
                        {Date: 1548115200000, lat: 23.91, lng: 35.5708333333, Position: 23, Comment: 'as per Egyptian navy instructions '},
                        {Date: 1548115200000, lat: 23.9416666667, lng: 35.5541666667, Position: 23, Comment: 'as per Egyptian navy instructions '}];

      this._map.fitBounds(this.getBounds(reports));

      const pointGeoJson = reports.map(r => {
        return {
          type: 'Feature',
          properties: {
            report: r
          },
          geometry: {
            type: 'Point',
            coordinates: [r['lat'], r['lng']]
          }
        };
      });

      L.geoJSON(<any>pointGeoJson, {
        pointToLayer: (feature, latLng) => {
          if (feature.geometry.coordinates) {
            return this.getMarker(feature.properties.report);
          }
        },
        onEachFeature: (feature, layer) => { },
        filter: feature => true
      }).addTo(this._map);

    }
  }
}

import {Component, EventEmitter, OnInit, ViewChild} from '@angular/core';
import {NoonReportsService, NoonReportV202005} from '../../services/noon-reports.service';
import { ActivatedRoute } from '@angular/router';
import {DateRange} from '../../../../shared/components';
import {Subject} from 'rxjs';
import { Vessel } from '../../vessels.service';
import {DataTableDirective} from 'angular-datatables';
import {DocumentService} from '../../../../documents/documents.service';
import {MessageLevel, PgMessagesService} from '../../../../shared/services/pg-messages.service';
import * as moment from 'moment';
import { DataTablesService } from '../../../../shared/datatables/data-tables.service';
import { DateTimeHelpers } from '../../../../shared/helpers/date-time.helpers';
import { saveAs } from 'file-saver';
import * as _ from 'lodash';
import {
  PgDateRangeTitleComponent,
  PgDateRangeTitleEnum, PgTitleComponent
} from '../../../../shared/components/title-bar/title-bar.component';

@Component({
  selector: 'app-noon-reports-v202005',
  templateUrl: './noon-reports-v202005.component.html'
})
export class NoonReportsV202005Component implements OnInit, PgTitleComponent, PgDateRangeTitleComponent {

  @ViewChild(DataTableDirective, {static: true}) dtElement: DataTableDirective;
  tableInitialised = false;

  title = new EventEmitter<string>();
  dateRangeSubTitle = new EventEmitter<[DateRange, PgDateRangeTitleEnum]>();

  dtOptions: any;
  dtTrigger: Subject<any> = new Subject<any>();

  dateRange: DateRange;
  reports: NoonReportV202005[];
  vessels: Vessel[];
  options: any;

  constructor(private _activatedRoute: ActivatedRoute,
              private _noonReportsService: NoonReportsService,
              private _documentsService: DocumentService,
              private _pgMessagesService: PgMessagesService) {
    this.dateRange = {
      startDate: moment().startOf('day').subtract(7, 'd').toDate(),
      endDate: moment().endOf('day').toDate()
    };
  }

  ngOnInit(): void {
    const component = this;
    this.dtOptions = DataTablesService.getOptionsEn([{
      text: 'Download',
      action: () => component.downloadReport()
    }], [[2, 'desc']], 50);
    this.title.emit('Noon reports V202005');
    this._activatedRoute.queryParams.subscribe(p => {
      const startDate = +p['startTime'];
      const endDate = +p['endTime'];
      this.dateRange = {
        startDate: new Date(startDate),
        endDate: new Date(endDate)
      };
      this.dateRangeSubTitle.emit([this.dateRange, PgDateRangeTitleEnum.defaultTitle]);
      this.getReport();
    });
  }

  getReport() {
    this._noonReportsService.getNoonReportsV202005Between(this.dateRange).subscribe(reports => {
      this.reports = reports;
      if (!this.tableInitialised) {
        this.dtTrigger.next(false);
        this.tableInitialised = true;
      } else {
        this.dtElement.dtInstance.then((dtInstance) => {
          dtInstance.destroy();
          this.dtTrigger.next(false);
        });
      }
    });
  }

  formatDate(timestamp: number) {
    if (timestamp > 0) {
      return DateTimeHelpers.formatLocalDate(timestamp);
    } else {
      return '';
    }
  }

  downloadReport() {
    this._noonReportsService.downloadReports(this.dateRange.startDate.getTime(),
      this.dateRange.endDate.getTime()).subscribe(b => {
      saveAs(b.body, `${DateTimeHelpers.formatLocalDate(this.dateRange.startDate.getTime())}-to-` +
        `${DateTimeHelpers.formatLocalDate(this.dateRange.endDate.getTime())}-reports.xlsx`);
    }, () => {
      this._pgMessagesService.publishMessage({
        level: MessageLevel.Error,
        message: 'Could not download noon reports',
        topic: 'Voyages report'
      });
    });
  }

  delete(n: NoonReportV202005) {
    if (confirm(`Are you sure you want to delete this item?`)) {
      n.isDeleted = true;
      this._noonReportsService.deleteNoonV202005(n).subscribe(
        () => {
          this.getReport();
          this._pgMessagesService.publishMessage({
            level: MessageLevel.Info,
            topic: 'Noon Report Item',
            message: `${n.vesselName} item Removed`,
          });
        });
    }
  }

  downloadFile(report: NoonReportV202005) {
    this._documentsService.download(report.fileId).subscribe(b => {
      const n = report.vesselName.split(' ').map(s => s.toLowerCase()).join('-');
      saveAs(b.body, `${n}-${DateTimeHelpers.formatLocalDate(report.date.timestamp)}-report.xlsx`);
    }, () => {
      this._pgMessagesService.publishMessage({
        message: 'Could not download report.',
        level: MessageLevel.Error,
        topic: 'Vessel report'
      });
    });
  }

  formatCargoOnboard(report: NoonReportV202005) {
    return _.chain(report.tanksCondition)
      .groupBy(r => r.product)
      .map((items, key) => `${key}: ${Math.round(items.map(i => i.quantity).reduce((p, c) => p + c, 0))}`)
      .value().join(', ');
  }

  getTotalBunkerConsumption(report: NoonReportV202005, fuelType: string) {
    return NoonReportV202005.getTotalSailingConsumption(report.noonReportBunkerStatus.sailingBunkerConsumption, fuelType)
      + NoonReportV202005.getTotalOtherConsumption(report.noonReportBunkerStatus.otherBunkerConsumption, fuelType);
  }

  getRob(report: NoonReportV202005, fuelType: string) {
    return NoonReportV202005.getRob(report.noonReportBunkerStatus.rob, fuelType);
  }
}

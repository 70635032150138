<div class="row">
  <div class="col-md-12">
    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
      class="table table-bordered table-striped">
      <thead>
        <tr>
          <td>Publisher</td>
          <td>File Name</td>
          <td>Date</td>
          <td></td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let doc of marketDocs">
          <td>{{doc.publisher}}</td>
          <td>{{doc.fileName}}</td>
          <td>{{formatDate(doc.timestamp)}}</td>
          <td>
            <a (click)="downloadFile(doc.docId, doc.fileName)"
              class="btn btn-link">
              <i class="fa fa-download" aria-hidden="true"></i>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

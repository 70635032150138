import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '../shared/shared.module';
import { PhoneBookComponent } from './phone-book.component';
import { PhoneBookService } from './phone-book.service';
import { DataTablesModule } from 'angular-datatables';
import { PhoneBookDataEntryComponent } from './phone-book-data-entry/phone-book-data-entry.component';
import { AuthenticationModule } from '../authentication/authentication.module';


@NgModule({
  imports: [SharedModule, FormsModule, ReactiveFormsModule, DataTablesModule, AuthenticationModule],
  declarations: [PhoneBookComponent, PhoneBookDataEntryComponent],
  exports: [PhoneBookComponent],
  providers: [PhoneBookService]
})
export class PhoneBookModule { }

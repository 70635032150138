import {Component, OnInit} from '@angular/core';
import {VERSION} from '../environments/version';
import {AnalyticsInterceptor} from './analytics/analytics.interceptor';
declare let $: any;

@Component({
  selector: 'app-sd-app',
  templateUrl: 'app.component.html',
})
export class AppComponent implements OnInit {
  version: string;
  versionDetail: string;
  component: any;

  constructor(private _analyticsInterceptor: AnalyticsInterceptor) {
    this.version = VERSION.version;
    this.versionDetail = VERSION.hash;
  }

  ngOnInit(): void {
    this.changeSideBarWidth();
    this._analyticsInterceptor.register();
  }

  routerOutletActivated(component: any) {
    this.component = component;
  }

  changeSideBarWidth() {
      $("#sidebar-wrapper").hover(()=> {
        if($("#wrapper").hasClass('toggled')) {
          $("#brand").css('width', '250px');
        }
      }, () => {
        if($("#wrapper").hasClass('toggled')) {
          $("#brand").css('width', '4.6rem');
        }
      });
  }
}

<div class="row">
  <div class="col-md-6">
    <div class="card card-body">
      <div class="row">
        <div class="col-md-3">
          <label for="ports">Select stop</label>
        </div>
        <div class="col-md-6">
          <select class="form-control"
            name="ports"
            id="ports"
            tabindex="1"
            [(ngModel)]="stop"
            required>
            <option></option>
            <option *ngFor="let por of availablePorts" [ngValue]="por">
              {{por.nameTranslations['en']}}
            </option>
          </select>
        </div>

        <button type="submit" class="btn btn-primary col-md-1"
          (click)="addStop(stop)">Add</button>
        <button class="btn btn-outline-secondary btn-light  col-md-1"
          (click)="reset()">Reset</button>

      </div>
      <div class="row">
        <div class="col-md-3">
          <label for="startDate">Star date</label>
        </div>
        <div class="col-md-6">
          <app-date-range id="startDate"
            [singleDateSelector]="true"
            [timePicker]="true"
            [(ngModel)]="startDate">
          </app-date-range>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <label for="daysAtPorts">Days at ports</label>
        </div>
        <div class="col-md-6">
          <input type="number"
            class="form-control"
            name="daysAtPorts" id="daysAtPorts" [(ngModel)]="daysAtPorts">
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <label for="selectedVessel">Vessel</label>
        </div>
        <div class="col-md-6">
          <select class="form-control"
            name="selectedVessel"
            id="selectedVessel"
            tabindex="1"
            [(ngModel)]="selectedVessel"
            required>
            <option></option>
            <option *ngFor="let v of vessels" [ngValue]="v">
              {{v.name.name}}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <label for="tonnage">Tonnage</label>
        </div>
        <div class="col-md-6">
          <input type="number"
            class="form-control"
            name="tonnage" id="tonnage" [(ngModel)]="tonnage">
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <label for="speed">Laden speed</label>
        </div>
        <div class="col-md-6">
          <input type="number"
            class="form-control"
            name="speed" id="speed" [(ngModel)]="ladenSpeed">
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <label for="ladenHfoConsumption">HFO cons pd</label>
        </div>
        <div class="col-md-6">
          <input type="number"
            class="form-control"
            name="ladenHfoConsumption" id="ladenHfoConsumption"
            [(ngModel)]="ladenHfoConsumption">
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <label for="ballastSpeed">Ballast speed</label>
        </div>
        <div class="col-md-6">
          <input type="number"
            class="form-control"
            name="ballastSpeed" id="ballastSpeed" [(ngModel)]="ballastSpeed">
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <label for="ballastHfoConsumption">HFO cons pd</label>
        </div>
        <div class="col-md-6">
          <input type="number"
            class="form-control"
            name="ballastHfoConsumption" id="ballastHfoConsumption"
            [(ngModel)]="ballastHfoConsumption">
        </div>
      </div>

    </div>
    <div class="card card-body">
      <div class="row">
        <div class="col-md-6" id="ladenConsumption">
          <div>Laden consumption</div>
          <table class="table table-sm table-striped">
            <thead>
              <th>Speed</th>
              <th>Consumption</th>
              <th>Count</th>
            </thead>
            <tbody>
              <tr *ngFor="let c of ladenHfoConsumptionTable">
                <td><input type="button" class="btn btn-link"
                    (click)="selectLadenSpeed(c[0], c[1])" [value]="c[0]"></td>
                <td>{{c[1] | number}}</td>
                <td>{{c[2] | number}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-md-6" id="ballastConsumption">
          <div>Ballast consumption</div>
          <table class="table table-sm table-striped">
            <thead>
              <th>Speed</th>
              <th>Consumption</th>
              <th>Count</th>
            </thead>
            <tbody>
              <tr *ngFor="let c of ballastHfoConsumptionTable">
                <td><input type="button" class="btn btn-link"
                    (click)="selectBallastSpeed(c[0], c[1])"
                    [value]="c[0]"></td>
                <td>{{c[1] | number}}</td>
                <td>{{c[2] | number}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-6">
    <div class="card card-body">
      <ul class="list-group">
        <ng-container *ngFor="let s of stops">
          <li class="list-group-item">
            <span class="badge">{{s[1]}}</span>
            {{s[0].nameTranslations['en'] ? s[0].nameTranslations['en'] :
            s[0].name}}</li>
        </ng-container>
      </ul>
      <div class="row">
        <div class="col-md-3">
          <label for="hours">Hours</label>
        </div>
        <div class="col-md-6">
          <span id="hours">{{hours | number}}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <label for="days">Days</label>
        </div>
        <div class="col-md-6">
          <span id="days">{{days | number}}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <label for="eta">ETA</label>
        </div>
        <div class="col-md-6">
          <span id="eta">{{eta}}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <label for="totalLadenHfoConsumption">Laden HFO cons</label>
        </div>
        <div class="col-md-6">
          <span id="totalLadenHfoConsumption">{{totalLadenHfoConsumption |
            number}}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <label for="totalBallastHfoConsumption">Ballast HFO cons</label>
        </div>
        <div class="col-md-6">
          <span id="totalBallastHfoConsumption">{{totalBallastHfoConsumption |
            number}}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <label for="totalHfoConsumption">HFO cons</label>
        </div>
        <div class="col-md-6">
          <span id="totalHfoConsumption">{{totalHfoConsumption | number}}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <label for="lastAvailableHfoPriceDate">Last HFO price</label>
        </div>
        <div class="col-md-6">
          <span id="lastAvailableHfoPriceDate">{{lastAvailableHFOPrice |
            number}} ({{lastAvailableHFOPriceDate | pgDate}})</span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <label for="hfoCost">HFO cost</label>
        </div>
        <div class="col-md-6">
          <span id="hfoCost">{{totalHfoConsumption * lastAvailableHFOPrice |
            number}}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <label for="dailyManagementCost">Daily cost</label>
        </div>
        <div class="col-md-6">
          <span id="dailyManagementCost">{{lastDailyManagementCost?.dailyCost |
            number}}
            ({{lastDailyManagementCost?.year}}-{{lastDailyManagementCost?.quarter}})</span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <label for="cost">Cost</label>
        </div>
        <div class="col-md-6">
          <span id="cost">{{totalCost | number}}
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <label for="costPerTon">Cost per ton</label>
        </div>
        <div class="col-md-6">
          <span id="costPerTon">{{costPerTon | number}}</span>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, EventEmitter, OnInit, Type } from '@angular/core';
import { PortalNewsService } from './portal-news.service';
import { PgTitleComponent } from '../shared/components/title-bar/title-bar.component';

interface PortalNews {
  title: string;
  content: string;
  date: Date;
  author: string;
}

@Component({
  selector: 'app-sd-home',
  templateUrl: 'home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, PgTitleComponent {
  subTitle: EventEmitter<string> = new EventEmitter();
  title: EventEmitter<string> = new EventEmitter();
  toolBarComponentType?: Type<any>;
  toolbarComponent: any;

  portalNews: PortalNews[];

  constructor(private _portalNewsService: PortalNewsService) { }

  ngOnInit(): void {
    this._portalNewsService.get().subscribe(l => {
      this.portalNews = l;
    });

    this.title.emit('Home');
    this.subTitle.emit('');
  }
}

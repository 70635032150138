import {GeoCoordinate} from '../models/GeoCoordinate';
import {Injectable} from '@angular/core';
import {HttpWrapper} from '../../shared/services';

export interface UnLocation {
  country: string;
  location: string;
  name: string;
  nameWoDiacritics: string;
  subdivision: string;
  status: string;
  function: string;
  date: string;
  iata: string;
  coordinates: GeoCoordinate;
}


@Injectable()
export class UnLocationsService {

  constructor(private _httpWrapper: HttpWrapper) { }

  getPorts() {
    return this._httpWrapper.get<UnLocation[]>('unlocations/ports');
  }
}

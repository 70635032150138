

<div class="card shadow-sm  col-md-12">
  <div class="card-header">
    <div class="pull-right">
      <input type="radio" name="fuelType" value="lsfo" [(ngModel)]="selectedFuelType" > LSFO
      <input type="radio" name="fuelType" value="lsmgo" [(ngModel)]="selectedFuelType"> LSMGO
    </div>
  </div>
  <div class="card-body">
    <table datatable style="width: 100%;"
           [dtOptions]="dtOptions"
           [dtTrigger]="dtTrigger"
           class="table table-sm no-margin">
      <thead>
      <tr>
        <th>Vessel</th>
        <th>Date</th>
        <td>Ballast steaming</td>
        <td>Ballast maneuvering</td>
        <td>Laden steaming</td>
        <td>Laden maneuvering</td>
        <td>Drifting</td>
        <td>Boiler</td>
        <td>Power generator</td>
        <td>Total</td>
        <td>ME Distance</td>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let item of consumptionPerDay">
        <td>{{item.vesselName}}</td>
        <td>{{item.date}}</td>
        <td>{{item.consumption.get('ballastSteaming') | number}}</td>
        <td>{{item.consumption.get('ballastManeuvering') | number}}</td>
        <td>{{item.consumption.get('ladenSteaming') | number}}</td>
        <td>{{item.consumption.get('ladenManeuvering') | number}}</td>
        <td>{{item.consumption.get('drifting') | number}}</td>
        <td>{{item.consumption.get('boilers') | number}}</td>
        <td>{{item.consumption.get('powerGenerators') | number}}</td>
        <td>{{item.consumption.get('total') | number}}</td>
        <td>{{item.consumption.get('ME distance') | number}}</td>
      </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="card shadow-sm  card-body col-md-12">
  <div id="bunkerConsumption"></div>
</div>


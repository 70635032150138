<div class="row">
  <div class="col-md-6">
    <div class="card card-body">
      <form #sofEntryForm="ngForm" (ngSubmit)="saveEntry()">
        <div class="form-group">
          <label for="sofType" class="col-form-label col-md-2">Type</label>
          <select id="sofType"
            name="sofType"
            class="form-control col-md-10"
            [(ngModel)]="sofEntry.sofType"
            [required]="true">
            <option value="L">Loading</option>
            <option value="D">Discharge</option>
          </select>
        </div>
        <div class="form-group">
          <label for="category" class="col-form-label col-md-2">Segment</label>
          <select id="category" name="category"
            class="form-control col-md-10"
            [(ngModel)]="sofEntry.phase"
            [required]="true">
            <option value="01">Arrival Operation / Inward Formalities</option>
            <option value="02">Cargo Segment</option>
            <option value="03">Departure Operation / Outward
              Formalities</option>
          </select>
        </div>
        <div class="form-group">
          <label for="events" class="col-form-label col-md-2">Events</label>
          <input type="text" id="events" name="events"
            class="form-control col-md-10"
            [(ngModel)]="sofEntry.events"
            [required]="true">
        </div>
        <div class="form-group">
          <label for="canonical"
            class="col-form-label col-md-2">Canonical</label>
          <input type="text" id="canonical" name="canonical"
            class="form-control col-md-10"
            [(ngModel)]="sofEntry.canonical"
            [required]="true">
        </div>

        <div class="form-group pull-right">
          <button class="btn btn-primary"
            [disabled]="!sofEntryForm.form.valid">Save</button>
        </div>
      </form>
    </div>
  </div>
</div>

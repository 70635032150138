<div class="card" *ngIf="selectedCrewPreviousAssignments">
  <div class="card-header">
    <h3 class="card-title">Last assignment</h3>
  </div>
  <div class="card-body">
    <div>
      <table class="table table-sm table-striped table-bordered">
        <thead>
          <tr>
            <th>Vessel</th>
            <th>From</th>
            <th>To</th>
            <th>Rank</th>
            <th>Duration</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let ch of selectedCrewPreviousAssignments; let i = index;">
            <td>
              <a
                [routerLink]="['/vessels/crew-change/crew-change-data-entry/', ch.id]"
                class="link-primary"> {{ch.vesselName}} </a>
            </td>
            <td>{{dateTime(crewChangeFindPlan(ch, 'On Board')?.time)}}</td>
            <td>{{dateTime(crewChangeFindPlan(ch, 'Off Board')?.time)}}</td>
            <td>{{ch.rank}}</td>
            <td>{{duration(ch)}}
              <td>{{ch.isCancelled ? 'Cancelled' : ''}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

<div class="row">
  <div class="col-md-6">
    <form class="form-horizontal" #gpsDataEntry="ngForm">
      <div class="card card-body">
        <div class="form-group row">
          <label for="imei"
            class="col-form-label col-md-4 ">شماره دستگاه ردیاب</label>
          <div class="col-md-8">
            <input type="text"
              class="form-control"
              name="imei"
              id="imei"
              maxlength="15"
              minlength="8"
              [(ngModel)]="selectedTracker.imei"
              [disabled]="edit"
              tabindex="1"
              required>
          </div>
        </div>
        <div class="form-group row">
          <label for="mobileNumber"
            class="col-form-label col-md-4 ">شماره همراه ردیاب</label>
          <div class="col-md-8">
            <input type="text"
              class="form-control"
              name="mobileNumber"
              id="mobileNumber"
              maxlength="11"
              minlength="11"
              [(ngModel)]="selectedTracker.mobileNumber"
              tabindex="2"
              required>
          </div>
        </div>
        <div class="form-group row">
          <label for="trackerType"
            class="col-form-label col-md-4 ">نوع ردیاب</label>
          <div class="col-md-8">
            <select class="form-control"
              name="trackerType"
              id="trackerType"
              [(ngModel)]="selectedTracker.trackerType"
              tabindex="3"
              required>
              <option value="VenusProvider">ونوس</option>
              <option value="JupiterProvider">ژوپیتر</option>
              <option value="KvlProvider">کاوشکام</option>
            </select>
          </div>
        </div>
        <div class="form-group row">
          <div>
            <button type="submit"
              class="btn btn-primary pull-right"
              [disabled]="!gpsDataEntry.form.valid"
              (click)="save()"
              tabindex="4">Save
            </button>
          </div>
        </div>
      </div>
      <div class="card card-body">
        <div class="col-md-12">
          <table
            class="table table-sm table-striped table-bordered">
            <thead>
              <tr>
                <th>شماره کشنده</th>
                <th>تاریخ شروع</th>
                <th>تاریخ پایان</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let object of history">
                <td>{{object.truckNumber}}</td>
                <td>{{dateFormatter(object.startDate)}}</td>
                <td>{{dateFormatter(object.endDate)}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </form>
  </div>
</div>
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewCorrespondenceTemplateComponent } from './new-correspondence-template/new-correspondence-template.component';
import { CorrespondenceTemplatesComponent } from './templates/correspondence-templates.component';
import { SharedModule } from '../shared/shared.module';
import { FormsModule } from '@angular/forms';
import { CorrespondenceService } from './correspondence.service';
import { DataTablesModule } from 'angular-datatables';
import { NewCorrespondenceComponent } from './new-correspondence/new-correspondence.component';
import { CorrespondencesComponent } from './correspondences/correspondences.component';
import { CorrespondenceToolbarComponent } from './correspondence-toolbar/correspondence-toolbar.component';
import { AuthenticationModule } from '../authentication/authentication.module';






@NgModule({
  declarations: [
    CorrespondenceTemplatesComponent,
    NewCorrespondenceTemplateComponent,
    NewCorrespondenceComponent,
    CorrespondencesComponent,
    CorrespondenceToolbarComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    DataTablesModule,
    AuthenticationModule
  ],
  providers: [CorrespondenceService]
})
export class CorrespondenceModule { }

<div class="row" >
    <div class="col-md-12 col-xs-12">
        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" 
        id="trucksList"
               class="table table-sm table-striped table-bordered " style="width:100%">
            <thead>
            <tr>
                <th>شماره پلاک</th>
                <th>شماره مخزن</th>
                <th>نام راننده</th>
                <th>شماره ردیاب</th>
                <th>شرکت حمل و نقل</th>
                <th>سند</th>
                <th>فعال</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let truck of list">
                <td><p > {{truck.formattedRegistrationNumber}} </p></td>
                <td [ngClass]="{'text-red': truck.tanker  && truck.tanker.licenseExpireDate}">
                    {{truck.tanker?.number}}
                    <a 
                        [routerLink]="['/trucks/edit', truck.formattedRegistrationNumber, 'tanker']"
                        *appHideIfNotInRole="['trucks-editor']">
                    <i class="fa fa-pencil-square-o"></i>
                </a>
            </td>
                <td class="col-md-2 text-truncate">{{truck?.driver}}
                    <a  
                        [routerLink]="['/trucks/edit', truck.formattedRegistrationNumber, 'driver']"
                        *appHideIfNotInRole="['trucks-editor']"> 
                    <i class="fa fa-pencil-square-o"></i>
                </a></td>
                <td>{{truck.imei }}
                    <a  [routerLink]="['/trucks/edit', truck.formattedRegistrationNumber, 'tracker']" 
                        *appHideIfNotInRole="['trucks-editor']">
                    <i class="fa fa-pencil-square-o"></i>
                </a></td>
                <td>{{truck.organisation}}
                    <a  [routerLink]="['/trucks/edit', truck.formattedRegistrationNumber, 'transporter']"
                        *appHideIfNotInRole="['trucks-editor']">
                    <i class="fa fa-pencil-square-o"></i>
                </a></td>
                <td>
                    <a (click)="downloadFile(truck.fileId, truck.formattedRegistrationNumber)" *ngIf ="truck.fileId" class="me-2">
                      <i class="fa fa-download" aria-hidden="true"></i>
                    </a>
                    <a  [routerLink]="['/trucks/edit', truck.formattedRegistrationNumber, 'file']"
                        *appHideIfNotInRole="['trucks-editor']">
                        <i class="fa fa-pencil-square-o"></i>
                    </a>
                </td>
                <td class="visible-md visible-lg">
                    <a  [routerLink]="['/trucks/edit', truck.formattedRegistrationNumber, 'active']"
                        *appHideIfNotInRole="['trucks-editor']">
                        <i class="fa fa-check-square-o" *ngIf="truck.active"></i>
                        <i class="fa fa-square-o" *ngIf="!truck.active"></i>
                    </a>
                    <a 
                        *ngIf="!hasRole('trucks-editor')">
                        <i class="fa fa-check-square-o" *ngIf="truck.active"></i>
                        <i class="fa fa-square-o" *ngIf="!truck.active"></i>
                    </a>

                </td>
                <td>
                    <a  [routerLink]="['/trucks/edit', truck.formattedRegistrationNumber, 'information']">
                        <i class="fa fa-info"></i></a>
                </td>
            </tr>
            </tbody>
        </table>
    </div >
    <div class="col-md-12 col-xs-12">
        <table datatable [dtOptions]="activeDtOptions" [dtTrigger]="dtTrigger"
               class="table table-sm table-striped table-bordered">
            <thead>
            <tr>
                <th>شرکت حمل و نقل</th>
                <th>تعداد کل کشنده های ثبت شده</th>
                <th>تعداد کشنده های فعال</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let truck of activeTrucks">
                <td>{{formatOrganisation(truck.transporterOrganisationName)}}</td>
                <td>{{truck.countOfTrucks}}</td>
                <td>{{truck.countOfActiveTrucks}}</td>
            </tr>
            </tbody>
        </table>
    </div >
</div>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { VoyagePartComponent } from '../voyage-part-component';
import { MiscDocument, Voyage } from '../../vessels.service';
import { PgMessagesService } from '../../../../shared/services/pg-messages.service';
import { LookupService } from '../../../../shared/services/lookup.service';

@Component({
  selector: 'app-misc-document',
  templateUrl: './misc-document.component.html'
})
export class MiscDocumentComponent implements OnInit, VoyagePartComponent {
  @Output() changed: EventEmitter<Voyage> = new EventEmitter();
  @Output() cancelled: EventEmitter<any> = new EventEmitter();

  key = 'Misc-Document';
  _uploadedFileId: string;
  _selectedVoyage: Voyage;
  _newUploadedFileId: string;

  miscDocument: MiscDocument;
  documentTypes: string[];

  @Input() set fileId(value: string) {
    this._uploadedFileId = value;
  }
  @Input() set data(value: any) {
    this._selectedVoyage = value as Voyage;
  }
  @Input() set updatedFileId(value: string) {
    this._newUploadedFileId = value;
  }


  constructor(private _pgMessagesService: PgMessagesService,
              private _lookupService: LookupService) {
    this.miscDocument = {
      fileId: null,
      comment: null,
      miscDocumentType: null,
    };
  }

  ngOnInit() {
    this._lookupService.get('voyage_miscellaneous_documents').subscribe(d => {
      this.documentTypes = d.items.map(d1 => d1[0]);
    });
  }

  saveMiscDocument() {
    this.miscDocument.fileId = this._uploadedFileId;
    if (this._newUploadedFileId) {
      this.miscDocument.fileId = this._newUploadedFileId;
    }

    if (this._selectedVoyage.miscDocuments) {
      this._selectedVoyage.miscDocuments.push(this.miscDocument);
    } else {
      this._selectedVoyage.miscDocuments = [this.miscDocument];
    }
    this.changed.emit(this._selectedVoyage);
  }

  deleteMiscDocument() {
    if (confirm(`Are you sure to delete this document`)) {
      const i = this._selectedVoyage.miscDocuments.findIndex(m => m.fileId === this.miscDocument.fileId);
      this._selectedVoyage.miscDocuments.splice(i, 1);
      this.changed.emit(this._selectedVoyage);
    }
  }

}

<form #blDoc="ngForm" class="form-horizontal">
  <fieldset style="border: 0;" [disabled]="!hasRole('vessels-voyages-editor')">
    <div class="form-group row">
      <label for="revise" class="col-form-label col-md-2 ">Revise</label>
      <div class="col-md-10">
        <input id="revise" name="revise" type="checkbox"
          [(ngModel)]="_selectedBillOfLading.revise">
      </div>
    </div>
    <div class="form-group row">
      <label for="no" class="col-form-label col-md-2 ">NO</label>
      <div class="col-md-10">
        <input type="text" class="form-control" name="no" id="no"
          [(ngModel)]="_selectedBillOfLading.no" required>
      </div>
    </div>
    <div class="form-group row">
      <label for="billOfLadingDate"
        class="col-form-label col-md-2 ">Date</label>
      <div class="col-md-10">
        <app-date-range id="billOfLadingDate"
          [singleDateSelector]="true"
          [(dateRange)]="selectedDate">
        </app-date-range>
      </div>
    </div>

    <div class="form-group row">
      <label for="shipper" class="col-form-label col-md-2 ">Shipper</label>

      <div class="col-md-10">
        <app-organisation-selector class="pg-component"
          [(organisation)]="shipper" [organisationLanguage]="'en'"
          name="shippers" [organisations]="shippers" required>
        </app-organisation-selector>
      </div>
    </div>
    <div class="form-group row">
      <label for="consignee" class="col-form-label col-md-2 ">Consignee</label>
      <div class="col-md-10">
        <app-organisation-selector class="pg-component"
          [(organisation)]="consignee" [organisationLanguage]="'en'"
          name="consignees" [organisations]="consignees" required>
        </app-organisation-selector>
      </div>
    </div>
    <div class="form-group row">
      <label for="agent" class="col-form-label col-md-2 ">Agent</label>
      <div class="col-md-10">
        <app-organisation-selector class="pg-component" [(organisation)]="agent"
          [organisationLanguage]="'en'"
          name="agents" [organisations]="agents" required>
        </app-organisation-selector>
      </div>
    </div>
    <div class="form-group row">
      <label for="Notifying" class="col-form-label col-md-2 ">Notifying</label>
      <div class="col-md-10">
        <app-organisation-selector class="pg-component"
          [(organisation)]="notifier" [organisationLanguage]="'en'"
          name="notifying" [organisations]="notifying">
        </app-organisation-selector>
      </div>
    </div>
    <div class="form-group row">
      <label for="loadingPort2" class="col-form-label col-md-2 ">Loading
        port</label>
      <div class="col-md-10">
        <app-organisation-selector id="loadingPort" class="pg-component"
          [(organisation)]="loadingPort"
          (organisationChange)="stsLoading($event)"
          [organisationLanguage]="'en'" name="loadingPort"
          [organisations]="ports" required>
        </app-organisation-selector>
      </div>
    </div>
    <div *ngIf="loadingSTS">
      <div class="form-group row"
        *ngFor="let sts of loadingStsVesselVoyage; let in=index">
        <label for="loadingSTS{{in}}vessel"
          class="col-form-label col-md-2 ">vessel STS from</label>
        <div class="col-md-4">
          <select class="form-control" name="loadingSTSId{{in}}vessel"
            id="loadingSTS{{in}}vessel"
            [(ngModel)]=loadingStsVesselVoyage[in].value.IMO
            (change)="getAllVesselVoyages($event.target.value, in, loadingStsVesselVoyage)"
            #name="ngModel" required>
            <option *ngFor="let vessel of vessels" value="{{vessel.IMO}}">
              {{vessel.name.name}}
            </option>
          </select>
        </div>
        <label for="loadingSTS{{in}}voyage"
          class="col-form-label col-md-2 ">voyage STS from</label>
        <div class="col-md-4">
          <select class="form-control" name="loadingSTSId{{in}}voyage"
            id="loadingSTS{{in}}voyage"
            [(ngModel)]="loadingStsVesselVoyage[in].value.shipVoyageNo"
            required>
            <option
              *ngFor="let voyage of loadingStsVesselVoyage[in].value.voyages"
              value="{{voyage.shipVoyageNo}}">
              {{voyage.vesselName}}-{{voyage.shipVoyageNo}}
            </option>
          </select>
        </div>
        <div class="col-md-8"></div>
        <a class="btn btn-link col-md-4 " (click)="removeLoadingSTS(in)"
          *ngIf="loadingStsVesselVoyage.length > 1">
          Remove STS
        </a>
      </div>
    </div>
    <div *ngIf="loadingSTS" class="form-group row">
      <div class="col-md-8"></div>
      <a class="btn btn-link col-md-4 " (click)="addLoadingSTS()">
        New loading STS
      </a>
    </div>
    <div class="form-group row">
      <label for="dischargePort2" class="col-form-label col-md-2 ">Discharge
        port</label>
      <div class="col-md-10">
        <app-organisation-selector class="pg-component"
          [(organisation)]="dischargePort" id="dischargePort2"
          name="dischargePort2" [organisationLanguage]="'en'"
          [organisations]="ports" required>
        </app-organisation-selector>
      </div>
    </div>

    <div class="form-group row" *ngFor="let product of products; let in=index;">
      <label for="productNameId{{in}}"
        class="col-form-label col-md-2 ">Product</label>
      <div class="col-md-4">
        <select class="form-control" name="productNameId{{in}}"
          id="products{{in}}" [(ngModel)]=product.product
          #name="ngModel" required>
          <option *ngFor="let productName of productNames"
            value="{{productName}}">
            {{productName}}
          </option>
        </select>
      </div>
      <label for="productGrossWeight"
        class="col-form-label col-md-2">Weight</label>
      <div class="col-md-4">
        <input type="number" id="productGrossWeight" class="form-control"
          name="productNameId{{in}}.number"
          id="products{{in}}.number" [(ngModel)]=product.grossWeight
          #name="ngModel">
      </div>
      <div class="col-md-8"></div>
      <a class="btn btn-link col-md-4 " (click)="removeProduct(in)"
        *ngIf="products.length > 1">
        Remove Product
      </a>
    </div>
  </fieldset>
  <div class="btn-group pull-right mt-3">
    <ng-container *ngIf="!isVerified && hasRole('vessels-voyages-editor')">
      <button class="btn btn-outline-secondary btn-light me-1"
        (click)="addNewProduct()">Add product</button>
    </ng-container>
    <ng-container *ngIf="isVerified && hasRole('admin-editor')">
      <button type="button" id="removeVerify"
        class="btn btn-outline-secondary btn-light me-1"
        (click)="removeVerification()" [disabled]="!isVerified">Remove
        verification
      </button>
    </ng-container>
    <ng-container *ngIf="!isVerified && hasRole('voyage-verifier')">
      <button type="button" id="verify"
        class="btn btn-outline-secondary btn-light me-1" (click)="verify()"
        [disabled]="isVerified">Verify
      </button>
    </ng-container>
    <ng-container *ngIf="mode ==='edit' && hasRole('vessels-voyages-editor')">
      <button type="button" id="delete"
        class="btn btn-outline-secondary btn-light me-1" (click)="deleteBL()"
        [disabled]="isVerified">Delete
      </button>
    </ng-container>
    <div *ngIf="!isVerified && hasRole('vessels-voyages-editor')">
      <button type="submit" id="submit" class="btn btn-primary"
        (click)="addUserAction(); saveBillOfLading();"
        [disabled]="!blDoc.form.valid">Save
      </button>
    </div>
  </div>
</form>
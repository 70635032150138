import { Component, EventEmitter, OnInit } from '@angular/core';
import { DataTablesService } from '../../../shared/datatables/data-tables.service';
import { Subject } from 'rxjs';
import { Vessel, VesselsService } from '../vessels.service';
import { KeycloakService } from 'src/app/authentication/keycloak.service';
import * as moment from 'moment';
import { MessageLevel, PgMessagesService } from '../../../shared/services/pg-messages.service';
import { saveAs } from 'file-saver';
import { PgTitleComponent, PgToolbarComponent } from '../../../shared/components/title-bar/title-bar.component';
import {VesselsToolbarComponent} from './vessels-toolbar/vessels-toolbar.component';
import _ from 'lodash';
import { DocumentService } from '../../../documents/documents.service';

@Component({
  selector: 'app-vessels-list',
  templateUrl: './vessels.component.html'
})
export class VesselsComponent implements OnInit, PgTitleComponent, PgToolbarComponent {
  title: EventEmitter<string> = new EventEmitter();
  toolbarComponentType = VesselsToolbarComponent;
  toolbarComponent: any;

  dtOptions: any;
  dtTrigger: Subject<any> = new Subject<any>();
  vessels: Vessel[];
  filteredVessels: Vessel[];
  isDataTablesInitialised = false;
  numberOfActiveVessels: number;
  minAge: number;
  maxAge: number;
  minCapacityMT: number;
  maxCapacityMT: number;
  minGrt: number;
  maxGrt: number;
  biggestFlag: string;
  numberOfBiggestFlag: number;

  photos = new Map<string, any>;

  constructor(
    private _vesselsService: VesselsService,
    private _pgMessagesService: PgMessagesService,
    private _documentsService: DocumentService,
    private _keycloakService: KeycloakService) {
  }

  ngOnInit() {
    this.title.emit('Vessels');
    this.dtOptions = DataTablesService.getOptionsWithNoColumnsSearchEn();
    this._vesselsService.getVessels().subscribe(v => {
      this.vessels = v;
      this.filteredVessels = v.filter(v1 => v1.active)
        .sort((a, b) => this.calculateVesselAge(a) - this.calculateVesselAge(b));
      this.filteredVessels.forEach(vessel => this.getVesselPhoto(vessel));
      this.numberOfActiveVessels = this.filteredVessels.length;
      const ages = this.filteredVessels.map(vessel => this.calculateVesselAge(vessel))
        .sort((a, b) => a - b);
      const capacities = this.filteredVessels.map(vessel => vessel.spec.dimensionsCapacities.capacity * 0.57)
        .sort((a, b) => a - b);
      const grt = this.filteredVessels.map(vessel => vessel.spec.dimensionsCapacities.GRT)
        .sort((a, b) => a - b);
      [this.minAge, this.maxAge] = [ages[0], ages[ages.length - 1]];
      [this.minCapacityMT, this.maxCapacityMT] = [capacities[0], capacities[capacities.length - 1]];
      [this.minGrt, this.maxGrt] = [grt[0], grt[grt.length - 1]];
      const flags = _.chain(this.filteredVessels)
        .groupBy(vessel => vessel.name.flag)
        .map((vessel, k) => ({
            flag: k,
            number: vessel.length
          }))
        .sort((a, b) => b.number - a.number)
        .value();
      [this.biggestFlag, this.numberOfBiggestFlag] = [flags[0].flag, flags[0].number];
    });
    this.toolbarComponent.activeFilterChange.subscribe(value => {
      if (value) {
        this.filteredVessels = this.vessels.filter(v => v.active)
          .sort((a, b) => this.calculateVesselAge(a) - this.calculateVesselAge(b));
      } else {
        this.filteredVessels = this.vessels;
      }
    });
    this.toolbarComponent.searchTextChange.subscribe(value => this.search(value));
  }

  recursiveSearch(object, search) {
    let result;
    if(typeof object === 'object') {
      const keys = Object.keys(object);
      result = keys.map(key => {
        const prop = object[key];
        return this.recursiveSearch(prop, search);
      });
    } else {
      return object.toString().toLocaleLowerCase().includes(search.toLowerCase());
    }
    return result.filter(r => r === true).length > 0;
  }

  search(value) {
    if(value  && value !== '') {
      this.filteredVessels = this.vessels.filter(v => this.recursiveSearch(v, value))
      .sort((a, b) => this.calculateVesselAge(a) - this.calculateVesselAge(b));
    } else {
      this.filteredVessels = this.vessels.filter(v => v.active)
      .sort((a, b) => this.calculateVesselAge(a) - this.calculateVesselAge(b));
  }
}

  hasRole(role: string) {
    return this._keycloakService.hasRole(role);
  }

  calculateVesselAge(v: Vessel) {
      return moment().diff(moment(new Date(v.spec.yearBuilt, 1, 1)), 'years');
  }

  downloadFile(id: string, fileName: string) {
    this._documentsService.download(id).subscribe(b => {
      saveAs(b.body, `${fileName}.pdf`);
    }, () => {
      this._pgMessagesService.publishMessage({
        message: `Could not download ${fileName}`,
        level: MessageLevel.Error,
        topic: 'Vessel document'
      });
    });
  }

  getVesselPhoto(v: Vessel) {
    if (v.spec.photoIds) {
      console.log(`Downloading file with id: ${v.spec.photoIds[length - 1]}`);
      this._documentsService.download(v.spec.photoIds[length - 1])
        .subscribe(b => {
          const reader = new FileReader();
          reader.readAsDataURL(b.body);
          reader.onload = () => {
            this.photos[v.IMO] = reader.result;
          };
        });
    }
  }

  findLatestQ88(vessel: Vessel) {
   const latestQ88 = vessel.documents.filter(d => d.documentType ==='Q88').sort((a, b) => b.issueDate - a.issueDate)[0];
   if(latestQ88) {
      return latestQ88;
   }
  }

  checQ88Expirey(vessel: Vessel) {
    const lastQ88 = this.findLatestQ88(vessel);
    if(lastQ88) {
      return moment().diff(moment(lastQ88.issueDate), 'M') > 1;
    }
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { ContractService, Contract, ContractLoadings } from 'src/app/contract/contract.service';
import { ActivatedRoute } from '@angular/router';
import { DataTablesService } from '../../shared/datatables/data-tables.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject, forkJoin } from 'rxjs';
import { DateTimeHelpers } from 'src/app/shared/helpers/date-time.helpers';
import { OrganisationService, Organisation } from 'src/app/organisation/organisation.service';

@Component({
  selector: 'app-contracts-balance',
  templateUrl: './contracts-balance.component.html',
})
export class ContractsBalanceComponent implements OnInit {
  @ViewChild(DataTableDirective, {static: true})
  dtElement: DataTableDirective;

  contracts: Contract[];
  _organisationsCache: { [key: string]: Organisation} = {};
  contractsLoading: [ContractLoadings, ContractLoadings][];
  organisations: Organisation[];
  balanceData: any[] = [];

  dtOptions = {};
  dtTrigger: Subject<any> = new Subject<any>();
  isDataTablesInitialised = false;

  constructor(private _contractService: ContractService,
              private _activateRoute: ActivatedRoute,
              private _organisationService: OrganisationService) { }

  ngOnInit() {
    this.dtOptions = DataTablesService.getOptionsEn();
    this.fillDataTable();
  }

  fillDataTable() {
    this._activateRoute.paramMap.subscribe(p => {
      const sourceName = p.get('loadingLocation');
      if (sourceName) {
        forkJoin([
          this._contractService.getBySource(sourceName),
          this._contractService.getContractBalance(),
          this._organisationService.get(),
        ])
        .subscribe(results => {
          this.contracts = results[0];
          this.contractsLoading = results[1];
          this.organisations = results[2];
          this.organisations.forEach(o => {
            this._organisationsCache[o.name] = o;
          });
          this.calculateBalance();
          if (!this.isDataTablesInitialised) {
            this.dtTrigger.next(false);
          } else {
            this.dtElement.dtInstance.then((dtInstance) => {
              dtInstance.destroy();
              this.dtTrigger.next(false);
            });
          }
        });
      }
    });
  }

  calculateBalance() {
    this.contracts.forEach(c => {
      const contractLoading = this.contractsLoading.find(l => c.contractNo === l[0].contractNo);
      let load = 0;
      let estmtd = 0;
      if (contractLoading === undefined) {
        load = 0;
        estmtd = 0;
      } else {
        load = contractLoading[0].total;
        estmtd = contractLoading[1].total;
      }

      this.balanceData.push({
        internalContractNo: c.internalContractNo,
        sourceOrganisationId: c.sourceOrganisationId,
        buyerOrganisationId: c.buyerOrganisationId,
        quantity: c.quantity,
        startDate: c.startDate,
        expirationDate: c.expirationDate,
        loading: load,
        balance: c.quantity - load - estmtd,
        estimated: estmtd
      });
    });
  }

  formatOrganisation(organisationId: string) {
    const foundOrganisation = this._organisationsCache[organisationId];
    if (foundOrganisation) {
      return foundOrganisation.nameTranslations['en'];
    } else {
      return '';
    }
  }

  formatDates(timestamp: number) {
    return DateTimeHelpers.formatLocalDate(timestamp);
  }


}

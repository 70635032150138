import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { SharedModule } from '../shared/shared.module';
import { LPGDistService } from './lpg-dist.service';
import { LpgReportComponent } from './reports/lpg-report.component';
import { SummaryReportComponent } from './reports/summary-report/summary-report.component';
import { CompareReportComponent } from './reports/compare-report/compare-report.component';
import { DataTablesModule } from 'angular-datatables';
import { LpgDistEntryComponent } from './data-entries/lpg-dist-data/lpg-dist-entry/lpg-dist-entry.component';
import { LpgPaymentEntryComponent } from './data-entries/payments-data/lpg-payment-entry/lpg-payment-entry.component';
import { LpgDistDetailComponent } from './data-entries/lpg-dist-data/lpg-dist-detail/lpg-dist-detail.component';
import { LpgDistPaymentDetailComponent } from './data-entries/payments-data/lpg-dist-payment-detail/lpg-dist-payment-detail.component';
import { DailyReportComponent } from './reports/daily-report/daily-report.component';
import { LpgQuotaEntryComponent } from './data-entries/lpg-dist-data/lpg-quota-entry/lpg-quota-entry.component';
import { OrganisationMapComponent } from 'src/app/lpg-dist/data-entries/organisation-map/organisation-map.component';
import { LpgDistToolbarComponent } from './lpg-dist-toolbar/lpg-dist-toolbar.component';
import { LpgDistFileUploaderComponent } from './data-entries/lpg-dist-file-uploader/lpg-dist-file-uploader.component';
import { AuthenticationModule } from '../authentication/authentication.module';
import { TrucksReportsComponent } from './reports/trucks-reports/trucks-reports.component';


@NgModule({
  imports: [SharedModule,
    FormsModule,
    DataTablesModule,
    AuthenticationModule,

  ],
  declarations: [
    LpgReportComponent,
    SummaryReportComponent,
    CompareReportComponent,
    LpgDistEntryComponent,
    LpgPaymentEntryComponent,
    LpgDistDetailComponent,
    LpgDistPaymentDetailComponent,
    DailyReportComponent,
    LpgQuotaEntryComponent,
    OrganisationMapComponent,
    LpgDistToolbarComponent,
    LpgDistFileUploaderComponent,
    TrucksReportsComponent],
  exports: [
    LpgReportComponent],
  providers: [LPGDistService]
})
export class LPGDistModule { }

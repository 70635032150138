<section class="content">
  <div class="row">
    <div class="col-lg-4">
      <div class="info-box card shadow-sm bg-info">
        <div class="card-body">
          <div class="inner" *ngIf="!_settings.calendar">
            <h3>{{totalLoadingWeight | number:'1.0-2' }} <sub>Ton</sub></h3>
  
            <p>Sum of Loading</p>
          </div>
          <div class="inner" *ngIf="_settings.calendar">
            <h3>{{totalLoadingWeight | number:'1.0-2'}} <sub>تن</sub></h3>
  
            <p>میزان برداشت</p>
          </div>
          <div class="icon">
            <i class="icon icon-bag"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="card shadow-sm bg-success info-box">
        <div class="card-body">
          <div class="inner" *ngIf="_settings.calendar">
            <h3>{{dailyWeight | number:'1.0-2'}} <sub>تن</sub></h3>
  
            <p>متوسط برداشت روزانه</p>
          </div>
          <div class="inner" *ngIf="!_settings.calendar">
            <h3>{{dailyWeight | number:'1.0-2' }} <sub>Ton</sub></h3>
  
            <p>Average</p>
          </div>
          <div class="icon">
            <i class="ion ion-person-add"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="card shadow-sm bg-primary info-box">
        <div class="card-body">
          <div class="inner" *ngIf="_settings.calendar">
            <h3>{{lastDayTotalWeight  | number:'1.0-2'}} <sub>تن</sub></h3>
  
            <p>جمع برداشت آخرین روز</p>
          </div>
          <div class="inner" *ngIf="!_settings.calendar">
            <h3>{{lastDayTotalWeight  | number:'1.0-2' }} <sub>Ton</sub></h3>
  
            <p>Last day Total</p>
          </div>
          <div class="icon">
            <i class="ion ion-person-add"></i>
          </div>
        </div>
      </div>
    </div>
<!--    <div class="col-lg-3 col-xs-6">-->
<!--      &lt;!&ndash; small box &ndash;&gt;-->
<!--      <div class="card bg-success">-->
<!--        <div class="inner">-->
<!--          <h3><i class="fa fa-download"></i></h3>-->

<!--          <p>ریز گزارش</p>-->
<!--        </div>-->
<!--        <div class="icon">-->
<!--          <i class="fa fa-file-excel-o"></i>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="col-lg-3 col-xs-6">-->
<!--      <div class="card bg-aqua">-->
<!--        <div class="inner">-->
<!--          <h3><i class="fa fa-share-alt"></i></h3>-->

<!--          <p>اشتراک</p>-->
<!--        </div>-->
<!--        <div class="icon">-->
<!--          <i class="ion ion-bag"></i>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  </div>

  <div class="row">
    <div class="col-md-12">
      <div class="card shadow-sm ">
        <div class="card-header">
          <h3 class="card-title" *ngIf="_settings.calendar">بارگیری روزانه</h3>
          <h3 class="card-title" *ngIf="!_settings.calendar">Daily Loading</h3>
        </div>
        <div class="card-body">
          <div id="dailyReportLineChartContainer"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="card shadow-sm ">
        <div class="card-header">
          <h3 class="card-title" *ngIf="_settings.calendar">برداشت شرکت ها</h3>
          <h3 class="card-title" *ngIf="!_settings.calendar">Contractors</h3>
        </div>
        <div class="card-body">
          <div id="transporterBarChartContainer"></div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="card ">
        <div class="card-header">
          <h3 class="card-title" *ngIf="_settings.calendar">بارگیری در تاریخ{{dateRange?.endDate | persianDateOnly}} شرکت ها</h3>
          <h3 class="card-title" *ngIf="!_settings.calendar">Loading On {{dateRange?.endDate |  date:'yyyy-MM-dd'}} by contractors</h3>
        </div>
        <div class="card-body">
          <div id="transporterDailyBarChartContainer"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="card shadow-sm ">
        <div class="card-header">
          <h3 class="card-title" *ngIf="_settings.calendar">جمع ارسال به مقاصد </h3>
          <h3 class="card-title" *ngIf="!_settings.calendar">Group by Destination </h3>
        </div>
        <div class="card-body">
          <div id="destinationBarChartContainer"></div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="card shadow-sm ">
        <div class="card-header">
          <h3 class="card-title" *ngIf="_settings.calendar">بارگیری در تاریخ{{dateRange?.endDate | persianDateOnly}}</h3>
          <h3 class="card-title" *ngIf="!_settings.calendar">Loading On {{dateRange?.endDate |  date:'yyyy-MM-dd'}}</h3>
        </div>
        <div class="card-body">
          <div id="dailyDestinationBarChartContainer"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="card shadow-sm ">
        <div class="card-header">
          <h3 class="card-title" *ngIf="_settings.calendar">مبادی</h3>
          <h3 class="card-title" *ngIf="!_settings.calendar">Sources</h3>
        </div>
        <div class="card-body">
          <div id="sourceBarChartContainer"></div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="card shadow-sm ">
        <div class="card-header">
          <h3 class="card-title" *ngIf="_settings.calendar">برداشت ماهانه</h3>
          <h3 class="card-title" *ngIf="!_settings.calendar">Monthly Loading</h3>
        </div>
        <div class="card-body">
          <div id="monthlyReportBarChartContainer"></div>
        </div>
      </div>
    </div>
  </div>
</section>

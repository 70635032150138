
import { PipeTransform, Pipe } from '@angular/core';
import { Organisation } from './organisation.service';

@Pipe({ name: 'organisationIdToEnName' })
export class OrganisationIdToEnNamePipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    const id = value;
    const organisationMap = args[0] as Map<string, Organisation>;
    if (id && organisationMap) {
      const foundOrganisation = organisationMap.get(id);
      if (foundOrganisation) {
        return foundOrganisation.nameTranslations['en'];
      }
      return 'error';
    }
  }
}

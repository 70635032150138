import { Injectable } from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
import {AnalyticsService} from './analytics.service';

@Injectable()
export class AnalyticsInterceptor  {

  constructor(private _router: Router,
              private _analyticsService: AnalyticsService) {
  }

  register() {
    this._router.events.subscribe(e => {
      if (e instanceof NavigationStart) {
        const navigationStart = e as NavigationStart;
        this.addCounter(navigationStart.url);
      }
    });
  }

  addCounter(url: string) {
    this._analyticsService.addCounter(url).subscribe({
      next: () => undefined,
      error: () => undefined
    });
  }
}

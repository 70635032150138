import { Component, EventEmitter, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { Organisation, OrganisationService } from '../../../organisation/organisation.service';
import { LPGDistData, LpgDistReportSummary, LPGDistService, LpgDistDistanceCost, ToolbarItem, Quota } from '../../lpg-dist.service';
import { DateRange } from '../../../shared/components';
import { TrucksService, Distance } from 'src/app/transportation';
import { LpgDistToolbarComponent } from 'src/app/lpg-dist/lpg-dist-toolbar/lpg-dist-toolbar.component';
import { UserLocalStoreService } from 'src/app/shared/services/user-local-store.service';

import * as moment from 'moment-jalaali';
import {
  PgDateRangeTitleComponent,
  PgDateRangeTitleEnum, PgTitleComponent,
  PgToolbarComponent
} from '../../../shared/components/title-bar/title-bar.component';

import { PgMessagesService, MessageLevel } from 'src/app/shared/services/pg-messages.service';

interface SummaryReportComponentSettings {
  companyId: string;
  calander: boolean;
}

@Component({
  selector: 'app-summary-report',
  templateUrl: './summary-report.component.html',
})
export class SummaryReportComponent implements OnInit, PgTitleComponent, PgDateRangeTitleComponent, PgToolbarComponent {
  title = new EventEmitter<string>();
  dateRangeSubTitle = new EventEmitter<[DateRange, PgDateRangeTitleEnum]>();
  toolbarComponentType = LpgDistToolbarComponent;
  toolbarComponent: LpgDistToolbarComponent;
  message: string;
  lpgDistData: LPGDistData[];
  organisations: Organisation[];
  sourceSummary: any[];
  destinationSummary: any[];
  dateRange: DateRange;
  distances: Distance[];
  costSummary: any[];
  quotaValue: Quota[];
  totalQuota: number;
  totalWeight: number;
  totalLoading: number;
  totalremaind: number;
  totalCost = 0;
  average = 0;
  selectedOrganisation: Organisation;

  undefinedDistance: boolean;

  _settings: SummaryReportComponentSettings;



  constructor(private _lpgDistService: LPGDistService,
              private _organisationService: OrganisationService,
              private _trucksService: TrucksService,
              private _router: Router,
              private _pgMessagesService: PgMessagesService,
              private _userLocalStorage: UserLocalStoreService,
              ) { }

  ngOnInit() {
      this._settings = this._userLocalStorage.get<SummaryReportComponentSettings>('SummaryReportComponent');
      if (!this._settings) {
        this._settings = { companyId: 'iran.parsagroup.orsagas', calander: true };
      }
      const end = moment().endOf('day').toDate();
      this.dateRange = {
        startDate:  moment(end).startOf('jMonth').toDate(),
        endDate: end
      };
      this.fetchData();
  }

  fetchData() {
    forkJoin([
      this._organisationService.get(),
      this._lpgDistService.getQuota(this.dateRange.startDate.getTime(),this.dateRange.endDate.getTime()),
      this._trucksService.getAllDistances()
    ]).subscribe(data => {
      this.organisations = data[0];
      this.distances = data[2];
      this.selectedOrganisation = this.organisations.find(o => o.name === this._settings.companyId);
      this.quotaValue = data[1].filter(q => q.organisationId === this.selectedOrganisation.name);
      this.setToolbar();
      this._lpgDistService.getOrganisationSummary(
        this.selectedOrganisation.name,
        this.dateRange.startDate.getTime(),
        this.dateRange.endDate.getTime()).subscribe(lpgDistData => {
          this.lpgDistData = lpgDistData;
          this.sourceSummary = this._lpgDistService.reportSourceSummary2(this.lpgDistData, this.quotaValue);
          this.destinationSummary = this._lpgDistService.reportDestinationSummary(this.lpgDistData, this.quotaValue);
          this.costSummary = this._lpgDistService.sourceDestinationSummary(this.lpgDistData, this.organisations, this.distances, this.quotaValue)
          .sort((t1, t2) => {
            if (t1.source > t2.source) {
              return 1;
            }
          });
          this.totalWeight = this.getTotalWeight(this.sourceSummary);
          this.totalCost = this.getTotalCost(this.costSummary);
          this.totalQuota = this.getTotalQuota(this.costSummary);
          this.totalLoading = this.getTotalLoading(this.costSummary);
          this.totalremaind = this.getTotalRemaind(this.costSummary);
          this.average = this.getAverageCost(this.costSummary);
          this.toolbarComponent.visibleOrganisation = true;
        });
      });
  }

  setToolbar() {
    this.title.emit('Transportation Costs');
    this.dateRangeSubTitle.emit([this.dateRange, PgDateRangeTitleEnum.persian]);
    this.toolbarComponent.dateRange = {
      startDate: this.dateRange.startDate,
      endDate: this.dateRange.endDate
    };
    this.toolbarComponent.jalali = this._settings.calander;
    this.toolbarComponent.singleDate = false;
    this.toolbarComponent.organisations = this.organisations
    .filter(o =>  ['domestic'].filter(s => o.properties[s]).length > 0);
    this.toolbarComponent.selectedOrganisation =  this.organisations.find(o => o.name === this.selectedOrganisation.name);
    this.toolbarComponent.itemsSelected.subscribe (t => {
      this.refresh(t);
    });
  }

  getTotalWeight(dataTable: LpgDistReportSummary[]) {
    if (dataTable) {
      return dataTable.reduce(((previousValue, currentValue) =>
        previousValue + currentValue.totalWeight), 0);
    }
  }


  getTotalQuota(dataTable) {
    if (dataTable) {
      return dataTable.map(t => t.quota).filter(d => d !== undefined).reduce(((a, b) => a + b), 0);
    }
  }

  getTotalLoading(dataTable) {
    if (dataTable) {
      return dataTable.map(t => t.primaryWeight).filter(d => d !== undefined).reduce(((a, b) => a + b), 0);
    }
  }

  getTotalRemaind(dataTable) {
    if (dataTable) {
      return dataTable.map(t => t.remaind).filter(d => d !== undefined && !Number.isNaN(d)).reduce(((a, b) => a + b), 0);
    }
  }


  getTotalCost(dataTable: LpgDistDistanceCost[]) {
    if (dataTable) {
      return  dataTable.map(t => t.cost).filter(d => d !== undefined && !Number.isNaN(d)).reduce(((a, b) => a + b), 0);
    }
  }

  getAverageCost(costs: LpgDistDistanceCost[]) {
    const totalWeight = costs.map(d => d.totalWeight).filter(c => c !== undefined).reduce(((a, b) => a + b), 0);
    const totalCost = costs.map(d => d.cost).filter(d => !Number.isNaN(d)).reduce(((a, b) => a + b), 0);
    return Math.round(totalCost / totalWeight);
  }

  organisationNameFormatter(organisationId: string) {
    const o = this.organisations.find(org => org.name === organisationId);
    return (o) ? o.nameTranslations['fa'] : '';
  }


  editRecord(item: any) {
    this._router.navigate(['lpg-dist/quta-entry'], { queryParams: {
      company: this.selectedOrganisation.name,
      sourceOrganisationId: item.source,
      destination: item.destination,
    }});
  }


  delete(item: any) {
    if (confirm(`Are you sure you want to delete this Quota?`)) {
      const quotas = this.quotaValue.filter(d =>
        d.destination === item.destination &&
        d.organisationId === this.selectedOrganisation.name &&
        d.sourceOrganisationId === item.source &&
        d.quotaType === 'destination'
        );
        if(quotas) {
      const quota = quotas.sort((a, b) => b.timestamp - a.timestamp)[0];
          quota.isDeleted = true;
          this._lpgDistService.updateQuota(quota).subscribe(() => {
          this.fetchData();
          this.message = 'Remove Quota';
          this._pgMessagesService.publishMessage({
              message: this.message,
              topic: 'Deleted',
              level: MessageLevel.Info,
            });
            });
        }
    }
  }

  refresh(item: ToolbarItem) {
    this._settings = {
        companyId: item.companyId,
        calander: item.selectedCalander
       };
    this.dateRange = item.dateRange;
    this._userLocalStorage.save('SummaryReportComponent', this._settings);
    this.fetchData();
  }
}

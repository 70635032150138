<div class="row">
  <div class="col-md-6">
    <form class="form-horizontal" #newCorrespondenceTemp="ngForm">
      <div class="card card-body">
        <div class="form-group row">
          <label for="owner" class="col-form-label col-md-4 ">Company</label>
          <div class="col-md-8">
              <app-organisation-selector 
                class= "pg-component" 
                [(organisation)]="organisation" 
                [organisationLanguage]= "'fa'"
                name="organisation" 
                [organisations]="organisations" 
                required>
              </app-organisation-selector>
          </div>
        </div>
        <div class="form-group row ">
          <label for="type" class="col-form-label col-md-4 ">Size</label>
          <div class="col-md-8">
            <select class="form-control"
            name="type"
            id="type"
            [(ngModel)]="size"
            (change)="getTemplate()"
            required>
              <option value="a4">A4</option>
              <option value="a5">A5</option>
            </select>
          </div>
        </div>
        <div  class="" *ngIf="ready">
          <button   id="download" 
          name="download" 
          type="submit" 
          class="btn btn-link" 
          (click)="downloadTemplate()">
            <a>Get Correspondence Main Template file</a>
          </button>
        </div>
        <div class="form-group row" *ngIf="ready">
          <label for="title"
                class="col-form-label col-md-4 ">Template Title</label>
          <div class="col-md-8">
            <input type="text"
                  class="form-control"
                  name="title"
                  id="title"
                  [disabled]="edit"
                  [(ngModel)] = "templateTitle"
                  tabindex="1"
                  required>
          </div>
        </div>
        <div class="form-group row" *ngIf="ready">
          <label for="template" class="col-form-label col-md-4 ">File</label>
          <app-file-uploader 
            class="col-md-8" 
            [accept]="'.docx'"
            name="template"
            [(ngModel)]="currentFiles">
          </app-file-uploader>
        </div>
        <div class="col-md-12 " *ngIf="ready">
          <button type="submit"
          class="btn btn-primary"
            [disabled]="!newCorrespondenceTemp.form.valid"
            (click)= "save()"
              tabindex="4">Save
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
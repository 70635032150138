import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Organisation, OrganisationService} from '../../../../../organisation/organisation.service';
import {TimestampWithZone} from '../../../../../shared/components/date-time-zone/date-time-zone.component';
import {CrewChange, CrewChangeItem, CrewChangeServices} from '../../crew-change.service';
import {KeycloakService} from '../../../../../authentication/keycloak.service';

@Component({
  selector: 'app-crew-change-plans',
  templateUrl: './crew-change-plans.component.html'
})
export class CrewChangePlansComponent implements OnInit {

  private _crewChange: CrewChange;

  planningPhases: string[];
  plannedPlaces: Organisation[];
  plannedTimes: TimestampWithZone[];
  items: CrewChangeItem[];

  organisations: Organisation[];
  places: Organisation[];
  disabled = true;

  get crewChange() {
    return this._crewChange;
  }

  @Input() set crewChange(value: CrewChange) {
    this._crewChange = value;
    this.items = this._crewChange.item.slice();
  }

  @Output() saved = new EventEmitter<void>();

  constructor(private _organisationService: OrganisationService,
              private _crewChangeService: CrewChangeServices,
              private _keycloakService: KeycloakService) { }

  ngOnInit() {
    this._organisationService.get().subscribe(organisations => {
      this.planningPhases = this._crewChangeService.getPlanningPhases();
      this.plannedPlaces = Array.from({length: this.planningPhases.length});
      this.plannedTimes = Array.from({length: this.planningPhases.length});

      this.organisations = organisations;
      this.places = organisations.filter(
        (x) => x.properties['airport'] === true
      );
      if (this.crewChange) {
        this.plannedPlaces = this.crewChange.item.map(i => this.organisations.find(o => o.name === i.place));
        this.plannedTimes = this.crewChange.item.map(i => ({
          timestamp: i.time,
          timeZone: this.organisations.find(o => o.name === i.place).timezone
        }));
      }
    });
  }

  setChangePlanPlace(item: CrewChangeItem, eve: any, index: number) {
    if (item && eve) {
      item.place = eve.name;
      this.plannedPlaces[index] = eve;
      this.plannedTimes[index] = {
        timestamp: null,
        timeZone: eve.timezone,
      };
    }
    this.disabled = this.saveDisable();
  }

  setChangePlanTime(item: CrewChangeItem, $event: any, index: number) {
    if ($event && $event.timestamp) {
      this.plannedTimes[index] = $event;
      item.time = $event?.timestamp;
    }
    this.disabled = this.saveDisable();
  }

  addPlan() {
    if (!this.items || this.items.length === 0) {
      this.items = [];
      this.plannedPlaces = [];
    }
    if (this.items.length !== this.planningPhases.length) {
      this.items.push({
        plan: this.planningPhases[this.items.length],
        time: null,
        place: null,
        isCancelled: false,
      });
    }
    if ([this.planningPhases[0], this.planningPhases[3]]
      .find(p => p === this.items[this.items.length -1].plan)) {
      this.places = this.organisations.filter(
        (x) => x.properties['airport'] === true
      );
    } else {
      this.places = this.organisations.filter(
        (x) => x.properties['isPort'] === true
      );
    }
    this.disabled = this.saveDisable();
  }

  removeLastPlan() {
    if (this.items?.length >= 1) {
      this.items.pop();
      this.plannedPlaces.pop();
      this.plannedTimes.pop();
    }
  }

  save() {
    this._crewChange.item = this.items;
    this.saved.emit();
  }

  hasRole(roleName: string) {
    return this._keycloakService.hasRole(roleName);
  }

  saveDisable() {
    return this.items.map(i =>  i.place!==null && i.plan!==null && i.time !==null).includes(false);
  }
}

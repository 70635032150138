<div class="row">
  <div class="col-md-6">
    <form #paymentEntry="ngForm" class="form-horizontal">
      <div class="card card-body">
        <div class="form-group row">
          <label for="payDate" class="col-form-label col-md-4 ">تاریخ پرداخت</label>
          <div class="col-md-8">
            <app-date-range id="reportStartDatePicker"
                id="payDate"
                name="payDate"
                [singleDateSelector]="true"
                [jalali]="true"
                [disabled]="edit"
                [(dateRange)]="daterange">
            </app-date-range>
          </div>
        </div>
        <div class="form-group row">
          <label for="paymentBillNo" class="col-form-label col-md-4 ">شماره فیش پرداختی</label>
          <div class="col-md-8">
              <input 	type="text"
                      name="paymentBillNo"
                      id="paymentBillNo"
                      class="form-control"
                      [ngModel]="payment?.paymentBillNo"
                      (ngModelChange)="payment.paymentBillNo = $event"
                      [disabled]="edit"
                      required>
          </div>
        </div>
        <div class="form-group row">
          <label for="company"
                  class="col-form-label col-md-4 ">شرکت</label>
          <div class="col-md-8">
              <select class="form-control"
                      name="company"
                      id="company"
                      [ngModel]="payment?.companyId"
                      (ngModelChange)="payment.companyId = $event"
                      required>
                <option *ngFor="let company of companies" value="{{company.name}}">
                  {{company.nameTranslations['fa']}}
                </option>
              </select>
          </div>
        </div> 
        <div class="form-group row">
          <label for="source"
                  class="col-form-label col-md-4 ">مبدا</label>
          <div class="col-md-8">
            <app-organisation-selector class= "pg-component" [(organisation)]="source" [organisationLanguage]= "'fa'"
            name="source" [organisations]="sources" required>
           </app-organisation-selector>
              <!-- <select class="form-control"
                      name="source"
                      id="source"
                      [ngModel]="payment?.sourceOrganisationId"
                      (ngModelChange)="payment.sourceOrganisationId = $event"
                      required>
                <option *ngFor="let source of sources" value="{{source.name}}">
                  {{source.nameTranslations['fa']}}
                </option>
              </select> -->
          </div>
        </div> 
        <div class="form-group row">
          <label for="totalWeight" class="col-form-label col-md-4 ">میزان خریداری شده</label>
          <div class="col-md-8">
              <input 	type="text"
                      name="totalWeight"
                      id="totalWeight"
                      class="form-control"
                      [ngModel]="payment?.totalWeight"
                      (ngModelChange)="payment.totalWeight = $event"
                      required>
                  </div>
        </div>
        <!-- <div class="form-group row">
              <label for="currency"
                      class="col-form-label col-md-4">نوع ارز</label>
                      <div class="col-md-8">
                        <select class="form-control col-md-8"
                                name="currency"
                                id="currency"
                                [ngModel]="payment?.currency"
                                (ngModelChange)="payment.currency = $event"
                                required>
                          <option *ngFor="let currency of currencies" value="{{currency}}">
                            {{currency}}
                          </option>
                        </select>
                      </div>
            </div>  -->
        <div class="form-group row">
          <label for="costPerKilo" class="col-form-label col-md-4 ">قیمت هر کیلو</label>
          <div class="col-md-8">
              <input 	type="text"
                      name="costPerKilo"
                      id="costPerKilo"
                      class="form-control"
                      [ngModel]="payment?.costPerKilo"
                      (ngModelChange)="payment.costPerKilo = $event"
                      required>
          </div>
        </div>
        <div class="form-group row">
          <label for="wholePayment" class="col-form-label col-md-4 ">مبلغ فیش واریزی</label>
          <div class="col-md-8">
              <input 	type="text"
                      name="wholePayment"
                      id="wholePayment"
                      class="form-control"
                      [ngModel]="payment?.wholePayment"
                      (ngModelChange)="payment.wholePayment = $event"
                      required>
          </div>
        </div>
        <div class="form-group row">
          <div>
            <input  type="button"
            name="pay"
            value="Save"
            class="btn btn-primary  pull-right"
            (click)="addPayment()"
            [disabled]="!paymentEntry.form.valid">
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { VesselsService } from '../../vessels.service';
import { KeycloakService } from 'src/app/authentication/keycloak.service';

@Component({
  selector: 'app-voyage-toolbar',
  templateUrl: './voyage-toolbar.component.html'
})
export class VoyageToolbarComponent {
  @Output() addDocument: EventEmitter<[string, boolean]> = new EventEmitter();

  _currentVoyageId: string;
  get currentVoyageId() {
    return this._currentVoyageId;
  }
  set currentVoyageId(voyageId: string) {
    this._currentVoyageId = voyageId;
    this._vesselsService.findPreviousVoyageId(voyageId).subscribe(previousId => {
      this.previousVoyageId = previousId;
    }, () => {
      this.previousVoyageId = '';
    });

    this._vesselsService.findNextVoyageId(voyageId).subscribe(nextVoyageId => {
      this.nextVoyageId = nextVoyageId;
    }, () => {
      this.nextVoyageId = '';
    });
  }
  previousVoyageId = '';
  nextVoyageId = '';

  constructor(private _router: Router,
              private _vesselsService: VesselsService,
              private _keycloakService: KeycloakService) { }

  navigateToVoyage(voyageId: string) {
    this._router.navigate(['vessels/voyage', voyageId]).then();
  }

  navigateToVoyageWithTimestamp(voyageId: string) {
    this._router.navigate(['vessels/voyage', voyageId], {
      queryParams: {
        t: new Date().getTime()
      }
    }).then();
  }

  showAddDocument(documentType: string, show = false) {
    this.addDocument.next([documentType, show]);
  }
}

<div class="row ">
  <div class="col-md-12">
      <table  datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" id="tblSearchCust"
              class="table table-sm table-striped  table-bordered">
        <thead>
          <tr>
            <td>Commitment Number</td>
            <td>Source</td>
            <td>Buyer</td>
            <td>Persian Start Date</td>
            <td>Persian EXP Date</td>
            <td>Gregorian Start Date</td>
            <td>Gregorian EXP Date</td>
            <td>Quantity</td>
            <td>Sum of loading</td>
            <td>Destination</td>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let cnt of balanceData">
          <td>{{cnt.internalContractNo}}</td>
          <td>{{formatOrganisation(cnt.sourceOrganisationId)}}</td>
          <td>{{formatOrganisation(cnt.buyerOrganisationId)}}</td>
          <td>{{cnt.startDate | persianDate }}</td>
          <td>{{cnt.expirationDate | persianDate}}</td>
          <td>{{formatDates(cnt.startDate)}}</td>
          <td>{{formatDates(cnt.expirationDate)}}</td>
          <td>{{cnt.quantity}}</td>
          <td>{{cnt.loading}}</td>
          <td>{{formatOrganisation(cnt.destination)}}</td>
        </tbody>
      </table>
  </div>
</div>

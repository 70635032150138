import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import moment from 'moment';
import { DateRange } from 'src/app/shared/components';
import { LookupService } from 'src/app/shared/services/lookup.service';
import { ToolbarItem, Vessel } from '../../vessels.service';

@Component({
  selector: 'app-loading-time-toolbar',
  templateUrl: './loading-time-toolbar.component.html',
})
export class LoadingTimeToolbarComponent implements OnInit {

  @Output()
  itemsSelected: EventEmitter<ToolbarItem> = new EventEmitter<ToolbarItem>();
  title = new EventEmitter<string>();


  dateRange: DateRange;
  _type: string;
  allSofItems: string[][];
  filterSofItems: string[][];
  selectableSofItems: string[][];
  extraSofEntry:  string[];
  extraSofEntry2: string[];
  toolbarItem: ToolbarItem;


  set type(value: string) {
    this._type = value;
    this.filterSOFItems();
  }

  constructor(
    private _lookupService: LookupService,) { }

  ngOnInit(): void {
    this._lookupService.get('voyage_sof_all').subscribe(item => {
      this.allSofItems = item.items;
    });
    if (!this.dateRange) {
      this.dateRange = {
        startDate: moment(new Date()).subtract(365, 'days').toDate(),
        endDate: new Date()
      };
    }
  }

  filterSOFItems() {
    if (this._type === 'loading') {
      this.filterSofItems = this.allSofItems.filter(s => s[0][0] === 'L');
    } else {
      this.filterSofItems = this.allSofItems.filter(s => s[0][0] === 'D');
    }
    this.extraSofEntry = this.filterSofItems[0];
    this.extraSofEntry2 = this.filterSofItems[1];
  }

  onSubmit() {
      this.toolbarItem = {
        dateRange: this.dateRange,
        type: this._type,
        firstSOF: this.extraSofEntry[0],
        secondeSOF: this.extraSofEntry2[0],
      };
    if (this.itemsSelected) {
      this.itemsSelected.emit(this.toolbarItem);
    }
  }

  lookupItemListFormatter(item: [string, string, string]) {
    return item[1];
  }

  vesselFormatter(vessel: Vessel) {
    return vessel.name.name;
  }


}

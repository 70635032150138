import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { Organisation, OrganisationService } from 'src/app/organisation/organisation.service';
import { forkJoin } from 'rxjs';
import { AuditReport, AuditsReportsService } from '../../audits-reports.service';
import { DocumentService } from '../../../../documents/documents.service';
import { saveAs } from 'file-saver';


@Component({
  selector: 'app-vessel-internal-audits',
  templateUrl: './vessel-internal-audits.component.html',
})
export class VesselInternalAuditsComponent implements OnInit {
  title = new EventEmitter<string>();
  locations: Map<string, Organisation>;
  auditReports: AuditReport[];

  @Input() set vesselIMO(value: string) {
    if (value) {
      this._auditReportsService.get(value).subscribe(s => {
        this.auditReports = s;
      });
    }
  }

  constructor(private _documentService: DocumentService,
    private _auditReportsService: AuditsReportsService,
    private _organisationService: OrganisationService) { }

  ngOnInit(): void {
    forkJoin([this._organisationService.getPorts()]).subscribe(results => {
      this.locations = new Map(results[0].map(r => [r.name, r]));
    });
  }

  getOrganisationName(id: string) {
    if (id) {
      return this.locations.get(id);
    }
  }

  downloadReport(report: AuditReport) {
    this._documentService.download(report.documentId).subscribe(f => {
      saveAs(f.body, `audit_report_${report.vesselName}_${report.isoDate}.pdf`);
    });
  }
}


<div class="row">
  <div class="col-md-6">
    <div>
      <fieldset style="border: 0;"
        [disabled]="crewChange?.isCancelled || !hasRole('vessels-crew-change-editor')"
        *ngIf="crew">
        <form class="form-horizontal" #CrewChange="ngForm">
          <div class="card">
            <div class="card-body">
              <div class="form-group row">
                <div class="col-md-4">
                  <div class="row text-center">
                    <app-img-viewer
                      *ngIf="crew"
                      [width]="'140px'"
                      [height]="'150px'"
                      [documentId]="crew?.photoDocumentId"
                      [alt]="crew?.firstName"
                      [class]="'rounded-circle'" />
                  </div>
                  <div class="row text-center">
                    <h5 class="my-3 text-capitalize">{{crew.firstName}}
                      {{crew.middleName}} {{crew.lastName}}</h5>
                  </div>
                </div>
                <div class="col-md-8">
                  <div class="form-group row">
                    <label for="rank"
                      class="col-form-label col-md-4 ">Position/Rank</label>
                    <div class="col-md-8">
                      <app-auto-complete
                        name="rank"
                        id="rank"
                        [formatter]="rankFormatter"
                        [ngModel]="crewChange?.rank"
                        (ngModelChange)="crewChange.rank = $event"
                        [items]="ranks"
                        required>
                      </app-auto-complete>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="vessel"
                      class="col-form-label col-md-4 ">Vessel</label>
                    <div class="col-md-8">
                      <app-auto-complete
                        name="vessel"
                        id="vessel"
                        [formatter]="vesselFormatter"
                        [(ngModel)]="selectedVessel"
                        (ngModelChange)="selectVessel($event)"
                        [disabled]="edit === true || disable"
                        [items]="vessels"
                        required>
                      </app-auto-complete>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="Voyage" class="col-form-label col-md-4 ">Voyage
                      NO</label>
                    <div class="col-md-8">
                      <app-auto-complete
                        id="voyage"
                        name="voyage"
                        [formatter]="voyageFormatter"
                        [(ngModel)]="selectedVoyage"
                        [disabled]="!voyages"
                        [items]="voyages"
                        required />
                    </div>
                  </div>
                </div>
              </div>
              <div class="btn-group pull-right mt-3">
                <button
                  type="submit"
                  class="btn btn-outline-secondary btn-light  me-1"
                  id="save"
                  *ngIf="crewChange?.id === '00000000-0000-0000-0000-000000000000'"
                  [disabled]="!CrewChange.form.valid"
                  (click)="save()">
                  Save
                </button>
              </div>
            </div>
          </div>
        </form>
      </fieldset>
      <div
        *ngIf="crewChange?.id && crewChange?.id !== '00000000-0000-0000-0000-000000000000'">
        <app-crew-change-plans
          [crewChange]="crewChange"
          (saved)="save()" />
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div *ngIf="selectedCrewPreviousAssignments">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Assignments</h3>
        </div>
        <div class="card-body">
          <div class="col-md-12">
            <table class="table table-sm table-striped table-bordered">
              <thead>
                <tr>
                  <th>Vessel</th>
                  <th>From</th>
                  <th>To</th>
                  <th>Rank</th>
                  <th>Duration</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let ch of selectedCrewPreviousAssignments; let i = index;">
                  <td><a
                      [routerLink]="['/vessels/crew-change/crew-change-data-entry/', ch.id]"
                      class="link-primary"> {{ch.vesselName}} </a></td>
                  <td>{{extractDateTime(crewChangeFindPlan(ch,
                    'On Board'))}}</td>
                  <td>{{extractDateTime(crewChangeFindPlan(ch,
                    'Off Board'))}}</td>
                  <td>{{ch.rank}}</td>
                  <td>{{duration(ch)}}</td>
                  <td>{{ch.isCancelled ? 'Cancelled' : ''}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="crewChange?.id && crewChange?.id !== '00000000-0000-0000-0000-000000000000'">
      <app-crew-contract
        [selectedCrewContract]="crewChange?.contract"
        (saveCrewContract)="saveContract($event)">
      </app-crew-contract>
    </div>
  </div>
</div>

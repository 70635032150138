import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UsersListComponent } from './list/users-list.component';
import { DocumentsModule } from '../documents/documents.module';
import { DataTablesModule } from 'angular-datatables';
import { SecurityService } from './security.service';
import { UserProfileDataEntryComponent } from './data-entry/user-profile-data-entry.component';
import { SharedModule } from '../shared/shared.module';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { AuthenticationModule } from '../authentication/authentication.module';



@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    DocumentsModule,
    DataTablesModule,
    AuthenticationModule
  ],
  declarations: [UsersListComponent, UserProfileDataEntryComponent, UserProfileComponent],
  providers: [
    SecurityService
  ]
})
export class SecurityModule { }

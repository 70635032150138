import { Injectable } from '@angular/core';
import { HttpWrapper } from '../../../shared/services';
import { LookupService } from '../../../shared/services/lookup.service';
import { map } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';

export interface SofDefinitionEntry {
  sofType: string;
  phase: string;
  events: string;
  canonical: string;
}

export interface SofTemplateDefinitionEntry {
  code: string;
  isRequired: boolean;
}

export interface SofTemplate {
  id: string;
  sofType: string;
  entries: SofTemplateDefinitionEntry[];
}

@Injectable()
export class SofTemplateService {

  constructor(private _httpWrapper: HttpWrapper,
              private _lookupService: LookupService) { }

  add(sofTemplateEntry: SofDefinitionEntry) {
    return this._httpWrapper.post<string, SofDefinitionEntry>('vessels/sofDefinitionEntry', sofTemplateEntry);
  }

  getAllSofItems(): Observable<[SofTemplateDefinitionEntry, string][]> {
    return this._lookupService.get('voyage_sof_all').pipe(map(i => (
      i.items.map(j => (
        [{
          code: j[0],
          isRequired: false
        }, j[2]]
      ))
    )));
  }

  getSofTemplate(id: string): Observable<[SofTemplateDefinitionEntry, string][]> {
    return forkJoin(
      [this._lookupService.get('voyage_sof_all'),
        this._lookupService.get(id)]).pipe(map(results => {
          const allSofItems = results[0].items;
          const sofTemplateDefinitionItems = results[1].items;

          return sofTemplateDefinitionItems.map(i => {
            const foundItem = this.findSofEntryByCode(allSofItems, i[0]);
            return [{
              code: foundItem[0],
              isRequired: i[1] === 'True' || i[1] === 'true'
            }, foundItem[2]];
          });
    }));
  }

  updateTemplate(sofTemplate: SofTemplate) {
    return this._httpWrapper.put<SofTemplate, SofTemplate>('vessels/sofTemplate', sofTemplate);
  }

  addTemplate(sofTemplate: SofTemplate) {
    return this._httpWrapper.post<SofTemplate, SofTemplate>('vessels/sofTemplate', sofTemplate);
  }

  private findSofEntryByCode(allItems: string[][], code: string) {
    const foundSof = allItems.find(s => s[0] === code);
    if (!foundSof) {
      console.error(`Could not find Sof entry for code ${code}`);
      return null;
    }
    return foundSof;
  }
}


<div class="card">
  <div class="card-header container-fluid">
    <div class="d-flex justify-content-between align-items-center">
      <div class="btn-group">
        <input type="button"
              class="btn btn-outline-secondary btn-light me-1"
              [ngClass]="{'disabled': pageNumber === 1}"
              (click)="pageNumber = pageNumber - 1"
              [disabled]="pageNumber === 1"
              value="Previous">
        <input type="button"
              class="btn btn-outline-secondary btn-light me-1"
              [ngClass]="{'disabled': pageNumber === (numberOfPages)}"
              (click)="pageNumber = pageNumber + 1"
              [disabled]="(pageNumber === (numberOfPages)) || !numberOfPages"
              value="Next">
        <input type="button"
              class="btn btn-outline-secondary btn-light me-1"
              (click)="zoomLevel = zoomLevel + .1"
              value="Zoom in">
        <input type="button"
              class="btn btn-outline-secondary btn-light me-1"
              (click)="zoomLevel = zoomLevel - .1"
              value="Zoom out">
        <input type="button"
              class="btn btn-outline-secondary btn-light me-1"
              (click)="download()"
              value="download">

      </div>
      <div>
        <span class="me-1">Page {{pageNumber}} of {{numberOfPages}}</span>
      </div>
    </div>
  </div>
  <div class="card-body">
    <pdf-viewer #pdfViewer1 [src]="src"
                [render-text]="true"
                [original-size]="false"
                [show-all]="false"
                [page]="pageNumber"
                [zoom]="zoomLevel"
                (after-load-complete)="pdfRendered($event)"
                [ngStyle]="{
                  width: (_width) ? _width : '100%',
                  height: (_height) ? _height : '600px',
                  }"
                (mousemove)="onDrag($event, pdfViewer1)"
                (mousedown)="onDragStarted()"
                (mouseup)="onDragEnded()"
                (mouseleave)="onDragEnded()"
               >
    </pdf-viewer>
  </div>
</div>

import { Component, OnInit } from '@angular/core';

import * as momentJ from 'moment-jalaali';
import * as HighCharts from 'highcharts';

import { TrucksService, Truck, GPSTrackerCoordinate } from '../trucks.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MapProviderService } from '../map/map.provider.service';
import { DateRange } from '../../../shared/components';
import * as moment from 'moment';
import { PgMessagesService, MessageLevel } from 'src/app/shared/services/pg-messages.service';

@Component({
  templateUrl: 'trucks-report.component.html',
  providers: [MapProviderService]
})
export class TrucksReportComponent implements OnInit {

  _selectedTruckImei: string;
  selectedTruck: Truck;
  selectedTruckTotalDistanceTraveled: number;
  selectedTruckTotalDistanceTraveledDaily: number;
  diff: number;
  trucks: Truck[];
  // truck: Truck;
  dateRange: DateRange;

  data: GPSTrackerCoordinate[];
  startTimestamp: number;
  endTimestamp: number;

  get selectedTruckImei(): string {
    return this._selectedTruckImei;
  }

  set selectedTruckImei(value: string) {
    if(value) {
      this._selectedTruckImei = value;
      this._trucksService.getByImei(this._selectedTruckImei).subscribe(t => {
        this.selectedTruck = t;
      });
    }
  }

  constructor(private _trucksService: TrucksService,
    private _activatedRoute: ActivatedRoute,
    private _mapProviderService: MapProviderService,
    private _router: Router,
    private _pgMessageService: PgMessagesService) {
    momentJ.loadPersian();
  }

  ngOnInit(): void {
    // this.flag = false;
    this._trucksService.get().subscribe(trucks => {
      this.trucks = trucks;
    });
    this._activatedRoute.paramMap.subscribe(params => {
        this.selectedTruckImei = params.get('imei');
        this.startTimestamp = +params.get('startTimestamp');
        this.endTimestamp = +params.get('endTimestamp');
        this.dateRange = {
          startDate: new Date(this.startTimestamp),
          endDate: new Date(this.endTimestamp)
        };
        this.diff = moment(this.endTimestamp).diff(moment(this.startTimestamp), 'hours' , true);
        this.getData();
      });
  }

  getData() {
    this._trucksService
    .getSpeedHistoryForTruckTimestamp(this.selectedTruckImei, this.startTimestamp, this.endTimestamp)
    .subscribe(data => {
      this.data = data;
      this.updateSpeedDashboard(data);
      this.updateAltitudeDashboard(data);
      if (this.diff > 48) {
        this.updateHeatMap(this.data);
        this.fitBound();
      } else {
        this.updateRoadTravelled(this.data);
        this.fitBound();
      }
      this.selectedTruckTotalDistanceTraveled = this._trucksService.getDistanceTraveledInKillometers(data);
      this.selectedTruckTotalDistanceTraveledDaily = this.selectedTruckTotalDistanceTraveled /
        (momentJ(this.dateRange.endDate).diff(this.dateRange.startDate, 'days' , true) + 1);

    }, (error) => {
      this._mapProviderService.initMap();
      if (error.status === 404) {
        this._pgMessageService.publishMessage({
          message: 'در این بازه زمانی برای این کشنده داده ای وجود ندارد',
          topic: 'Error',
          level: MessageLevel.Error
        });
      }
    });

  }

  truckValueFormatter(truck: Truck) {
    const driver = (typeof truck.driver === 'undefined') ? 'N/A' : truck.driver.associatedObject.name;
    const tanker = (typeof truck.tanker === 'undefined') ? 'N/A' : truck.tanker.associatedObject.number;
    return truck.formattedRegistrationNumber + '***' + tanker + '***' + driver;
  }

  report() {
    this._router.navigate(['/trucks-report', this.selectedTruck.tracker.associatedObject.imei,
      this.dateRange.startDate.getTime(), this.dateRange.endDate.getTime()]).then();
  }

  updateSpeedDashboard(data: GPSTrackerCoordinate[]) {
    const series = data.sort((t1, t2) => t1.timestamp - t2.timestamp)
      .map(t => [t.timestamp, t.speed]);
    HighCharts.chart('speedContainer', {
      title: {
        text: this.selectedTruck.formattedRegistrationNumber
      },
      chart: { type: 'column' },
      xAxis: {
        labels: {
          formatter() {
            const temp: any = this;
            if (temp.dateTimeLabelFormat === '%H:%M:%S') {
              return momentJ(temp.value).format('HH:mm:ss');
            } else if (temp.dateTimeLabelFormat === '%H:%M') {
              return momentJ(temp.value).format('HH:mm');
            } else if (temp.dateTimeLabelFormat === '%e. %b') {
              return momentJ(temp.value).format('jD jMMM');
            } else if (temp.dateTimeLabelFormat === '%b \'%y') {
              return momentJ(temp.value).format('jMMM jYYYY');
            } else if (temp.dateTimeLabelFormat === '%Y') {
              return momentJ(temp.value).format('jYYYY');
            } else if (temp.dateTimeLabelFormat === '%H:%M:%S.%L') {
              return momentJ(temp.value).format('HH:mm:ss');
            } else {
              console.log(`missing formatter for ${temp.dateTimeLabelFormat} and value: ${this.value}`);
            }

          }
        }
      },
      yAxis: {
        title: {
          text: 'کیلومتر بر ساعت'
        }
      },
      series: [{
        name: 'سرعت',
        type: 'column',
        data: series
      }],
      tooltip: {
        formatter() {
          return `${momentJ(this.x).format('jYYYY-jMM-jDD HH:mm')}<br>سرعت: ${Math.round(this.y)}`;
        }
      }
    });
  }

  updateAltitudeDashboard(data: GPSTrackerCoordinate[]) {
    const series = data.sort((t1, t2) => t1.timestamp - t2.timestamp)
      .map(t => [t.timestamp, t.altitude]);
    HighCharts.chart('altitudeContainer', {
      title: {
        text: this.selectedTruck.formattedRegistrationNumber
      },
      xAxis: {
        labels: {
          formatter() {
            const temp: any = this;
            if (temp.dateTimeLabelFormat === '%H:%M:%S') {
              return momentJ(temp.value).format('HH:mm:ss');
            } else if (temp.dateTimeLabelFormat === '%H:%M') {
              return momentJ(temp.value).format('HH:mm');
            } else if (temp.dateTimeLabelFormat === '%e. %b') {
              return momentJ(temp.value).format('jD jMMM');
            } else if (temp.dateTimeLabelFormat === '%b \'%y') {
              return momentJ(temp.value).format('jMMM jYYYY');
            } else if (temp.dateTimeLabelFormat === '%Y') {
              return momentJ(temp.value).format('jYYYY');
            } else if (temp.dateTimeLabelFormat === '%H:%M:%S.%L') {
              return momentJ(temp.value).format('HH:mm:ss');
            } else {
              console.log(`missing formatter for ${temp.dateTimeLabelFormat} and value: ${this.value}`);
            }
          }

        }
      },
      yAxis: {
        title: {
          text: 'ارتفاع از سطح دریا'
        }
      },
      series: [{
        type: 'column',
        name: 'ارتفاع',
        data: series
      }],
      tooltip: {
        formatter() {
          return `${momentJ(this.x).format('jYYYY-jMM-jDD HH:mm')}<br>ارتفاع به متر: ${Math.round(this.y)}`;
        }
      }
    });
  }

  private fitBound() {
    const bounds = this.data.map(d => d.geoCoordinate);
    this._mapProviderService.fitMapBound(bounds);
  }

  private updateHeatMap(t: GPSTrackerCoordinate[]) {
    this._mapProviderService.updateHeatMap(t.map(p => p.geoCoordinate));
  }

  private updateRoadTravelled(t: GPSTrackerCoordinate[]) {
    this._mapProviderService.updatePath(t);
  }
}

import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject, forkJoin } from 'rxjs';
import { PgTitleComponent } from 'src/app/shared/components/title-bar/title-bar.component';
import { DataTablesService } from 'src/app/shared/datatables/data-tables.service';
import { Vessel, VesselOffHire, VesselsService } from '../../vessels.service';
import { Organisation, OrganisationService } from 'src/app/organisation/organisation.service';
import { DateTimeHelpers } from 'src/app/shared/helpers/date-time.helpers';
import { Router } from '@angular/router';

@Component({
  selector: 'app-vessels-off-hires',
  templateUrl: './vessels-off-hires.component.html',
})
export class VesselsOffHiresComponent implements OnInit, PgTitleComponent {
  title = new EventEmitter<string>();

  constructor(
    private _vesselService: VesselsService,
    private _organisationService: OrganisationService,
    private _router: Router,
  ) {}

  @ViewChild(DataTableDirective, {static: true})
  dtElement: DataTableDirective;
  isDataTablesInitialised = false;
  dtOptions = {};
  dtTrigger: Subject<any> = new Subject<any>();

  offHires: VesselOffHire[];
  vessels: Vessel[];
  organisations: Organisation[];

  offHireStatus: Record<string, string> = {
    potential: 'Potential',
    claimed: 'Calimed',
    accepted: 'Accepted'
  };

  offHireTypes: Record<string, string> = {
    dryDock: 'Dry Dock',
    breakDown: 'Break Down',
  };

  ngOnInit() {
    this.title.emit('Vessels Off Hires');
    forkJoin([
      this._vesselService.getOffHires(),
      this._vesselService.getActiveVessels(),
      this._organisationService.get()
    ]).subscribe(results => {
      this.offHires = results[0];
      this.vessels = results[1];
      this.organisations = results[2];
      this.initialiseTankersDataTables();
    });
    this.dtOptions = DataTablesService.getOptionsEn();
  }

  initialiseTankersDataTables() {
    if (!this.isDataTablesInitialised) {
      this.dtTrigger.next(false);
    } else {
      this.dtElement.dtInstance.then((dtInstance) => {
        dtInstance.destroy();
        this.dtTrigger.next(false);
      });
    }
    this.isDataTablesInitialised = true;
  }

  vesselFormatter(imo: string) {
    return this.vessels.find(v => v.IMO === imo).name.name;
  }

  organisationFormatter(id: string) {
    return this.organisations.find(o => o.name === id).nameTranslations['en'];
  }

  offHiresDays(firstDate: string, secondDate: string) {
    return DateTimeHelpers.getTimeDiffFromDateStrings(firstDate, secondDate);
  }

  editRecord(offHire: VesselOffHire) {
    this._router.navigate(['vessels/off-hire', offHire.id]);
  }

}

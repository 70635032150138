import { Route } from '@angular/router';

import { PhoneBookComponent } from './phone-book.component';
import { PhoneBookDataEntryComponent } from './phone-book-data-entry/phone-book-data-entry.component';

export const phoneBookRoutes: Route[] = [
  { path: 'phone-book', component: PhoneBookComponent },
  { path: 'phone-book/entry/:mode', component: PhoneBookDataEntryComponent },
  { path: 'phone-book/entry/:mode/:title/:name/:companyName', component: PhoneBookDataEntryComponent },
];

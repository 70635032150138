<div>
  <div class="row">
    <div class="col-md-12">
      <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
        class="table table-sm table-striped  table-bordered ">
        <thead>
          <tr>
            <th>نام شرکت در کانتار</th>
            <th>ویرایش</th>
            <th>نام شرکت متناظر</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let kantarOrganisation of notMapped let i = index">
            <td>{{kantarOrganisation}}</td>
            <td>
              <button id="edit" name="edit" type="submit" class="btn btn-link"
                (click)="editRecord(i)">
                <i class="fa fa-pencil-square-o"></i>
              </button>
            </td>
            <td>
              <div class="form-group row" *ngIf="index === i && showDropDown">
                <div class="col-md-8">
                  <app-organisation-selector
                    id="organisation"
                    class="pg-component"
                    [(organisation)]="organisation"
                    [organisationLanguage]="'fa'"
                    name="organisation"
                    [organisations]="organisations"
                    required>
                  </app-organisation-selector>
                </div>
                <input type="button" value="save"
                  class="btn btn-primary col-md-1"
                  (click)="saveMapped(kantarOrganisation)">
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'persianNumber' })
export class PersianNumberPipe implements PipeTransform {

    convert(value: string): string {
        const map = new Map();
        map.set('0', '۰');
        map.set('1', '۱');
        map.set('2', '۲');
        map.set('3', '۳');
        map.set('4', '۴');
        map.set('5', '۵');
        map.set('6', '۶');
        map.set('7', '۷');
        map.set('8', '۸');
        map.set('9', '۹');

        let str = '';

        for (const c of value) {
            // const c = value[i];
            let replacement = map.get(c);
            if (!replacement) {
                replacement = c;
            }
            str += replacement;
        }
        return str;
    }

    transform(value: any): any {

        if (typeof value === 'number') {
            return this.convert(value.toString());
        } else if (typeof value === 'string') {
            return this.convert(value);
        }

        return undefined;
    }

}

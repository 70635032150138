import { Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Nor, Voyage, DataBaseAction, VesselsService} from '../../vessels.service';
import { VoyagePartComponent } from '../voyage-part-component';
import { Organisation, OrganisationService } from '../../../../organisation/organisation.service';
import { DateTimeHelpers } from '../../../../shared/helpers/date-time.helpers';
import { KeycloakService} from 'src/app/authentication/keycloak.service';
import { ActionType } from 'src/app/shared/services/database-action.service';
import { DateRange } from 'src/app/shared/components';

@Component({
  selector: 'app-nor',
  templateUrl: './nor.component.html',
})
export class NorComponent implements OnInit, VoyagePartComponent {

  @Output() changed: EventEmitter<Voyage> = new EventEmitter();
  @Output() cancelled: EventEmitter<any> = new EventEmitter();

  key = 'NOR';
  _uploadedFileId: string;
  _newUploadedFileId: string;
  _selectedVoyage: Voyage;
  _selectedNor: Nor;
  _selectedDate: DateRange = {
    startDate: new Date(),
    endDate: new Date()
  };
  dateSelection = false;
  previewMode = false;
  isVerified = false;

  portOrganisations: Organisation[];
  port: Organisation;

  @Input() set fileId(value: string) {
    this._uploadedFileId = value;
  }
  @Input() set updatedFileId(value: string) {
    this._newUploadedFileId = value;
  }
  @Input() set data(value: any) {
    this._selectedVoyage = value as Voyage;
  }


  selectedNorTypes = ['loading', 'discharging'];

  constructor(private _vesselsService: VesselsService,
    private _organisationsService: OrganisationService,
    private _keycloakService: KeycloakService) { }


  ngOnInit() {
    this._selectedNor = this._selectedVoyage.nors.find(nor => nor.fileId === this._uploadedFileId);
    if (this._selectedNor) {
      this.previewMode = true;
      this.isVerified = this._vesselsService.isVerified(this._selectedNor);
    } else {
      this._selectedNor = {
        timestamp: new Date().getTime(),
        norType: '',
        portOrganisationId: '',
        fileId: '',
        actions: []
      };
    }
    this._organisationsService.getPorts().subscribe(ports => {
      this.portOrganisations = ports;
      this.port = this.portOrganisations.find(p => p.name === this._selectedNor.portOrganisationId);
      if(this.port) {
        this._selectedDate = {
          startDate: new Date(DateTimeHelpers.getDateTimeFromTimestamp(this.port.timezone, this._selectedNor.timestamp)),
          endDate: new Date(DateTimeHelpers.getDateTimeFromTimestamp(this.port.timezone, this._selectedNor.timestamp)),
        };
      }
    });
  }

  hasRole(role: string) {
    return this._keycloakService.hasRole(role);
  }

  saveNor() {
    this._selectedNor.fileId = this._uploadedFileId;
    if (this._newUploadedFileId) {
      this._selectedNor.fileId = this._newUploadedFileId;
    }
    this._selectedNor.portOrganisationId = this.port.name;
    this._selectedNor.timestamp = this._selectedDate.startDate.getTime();
    if (!this.previewMode) {
      if (this._selectedVoyage.nors) {
        this._selectedVoyage.nors.push(this._selectedNor);
      } else {
        this._selectedVoyage.nors = [this._selectedNor];
      }
      const selectedTimezoneOffset = this.portOrganisations.find(o => o.name === this._selectedNor.portOrganisationId).timezone;
      if (!selectedTimezoneOffset) {
        console.error(`Timezone offset is missing for selected organisation Id: ${this._selectedNor.portOrganisationId}`);
      } else {
        const action: DataBaseAction = {
          userName: this._keycloakService.getUser().username,
          timestamp: new Date().getTime(),
          userAction: ActionType.Create
        };
        this._selectedNor.actions.push(action);
        this._selectedNor.timestamp = DateTimeHelpers.offsetTimestampWithZone(this._selectedNor.timestamp, selectedTimezoneOffset);
      }
    }
    this.changed.emit(this._selectedVoyage);
  }

  organisationValueFormatter(data: Organisation) {
    return data.nameTranslations['en'];
  }

  removeVerification() {
    if (this._vesselsService.removeVerification(this._selectedNor, 'NOR')) {
      this.saveNor();
    }
  }

  verify() {
    if (this._vesselsService.verify(this._selectedNor, 'NOR')) {
      this.saveNor();
    }
  }

  deleteNor() {
    if (confirm('Are you sure to delete this NOR')) {
      const i = this._selectedVoyage.nors.findIndex(n => n.fileId === this._selectedNor.fileId);
      this._selectedVoyage.nors.splice(i, 1);
      this.changed.emit(this._selectedVoyage);
    }
  }
}

import { Component, EventEmitter, OnInit } from '@angular/core';
import {
  BunkeringDeliveryNote,
  BunkerType,
  Vessel,
  VesselsService
} from 'src/app/transportation/vessels/vessels.service';
import { Organisation, OrganisationService } from 'src/app/organisation/organisation.service';
import { forkJoin } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { PgMessagesService, MessageLevel } from 'src/app/shared/services/pg-messages.service';
import { DocumentReference, DocumentService } from '../../../documents/documents.service';
import { DateRange } from '../../../shared/components';
import { PgTitleComponent } from '../../../shared/components/title-bar/title-bar.component';
import { KeycloakService } from 'src/app/authentication/keycloak.service';
import { saveAs } from 'file-saver';
import { DataBaseActionService, ActionType } from 'src/app/shared/services/database-action.service';
import {DateTimeHelpers} from '../../../shared/helpers/date-time.helpers';

@Component({
  selector: 'app-bunker-data-entry',
  templateUrl: './bunker-data-entry.component.html',
})
export class BunkerDataEntryComponent implements OnInit, PgTitleComponent {
  title: EventEmitter<string> = new EventEmitter();
  toolbarComponent: any;
  zoom = 0;
  uploadedFileId: string;
  currentFiles: FileList;
  fuelFile: FileList;
  selectedDocumentType: string;
  vessels: Vessel[];
  vesselIMO: string;
  timestamp: string;
  ports: Organisation[];
  bunkeringDeliveryNote: BunkeringDeliveryNote;
  _selectedPortOrganisation: Organisation;
  locationSelected = false;
  selectedSupplierOrganisation: Organisation;
  bdnDate = {
    startDate: new Date(),
    endDate: new Date(),
  };
  alongSide: DateRange = {
    startDate: new Date(),
    endDate: new Date(),
  };
  hoseConnected: DateRange = {
    startDate: new Date(),
    endDate: new Date(),
  };
  commencedPumping: DateRange = {
    startDate: new Date(),
    endDate: new Date(),
  };
  completedPumping: DateRange = {
    startDate: new Date(),
    endDate: new Date(),
  };
  hoseDisconnected: DateRange = {
    startDate: new Date(),
    endDate: new Date(),
  };
  message: string;
  product: string;
  supplier: Organisation[];
  productName = [
    BunkerType.HFO,
    BunkerType.VLSFO,
    BunkerType.ULSFO,
    BunkerType.MGO,
    BunkerType.LSMGO
  ];
  quantity: number;
  disable: boolean;
  valid: boolean;
  selectedVessel: Vessel;
  alongSideSetted = false;
  hoseConnectedSetted = false;
  commencedPumpingSetted = false;
  hoseDisconnectedSetted = false;

  constructor(private _activateRoute: ActivatedRoute,
    private _organisationService: OrganisationService,
    private _documentsService: DocumentService,
    private _actionService: DataBaseActionService,
    private _router: Router,
    private _keycloakService: KeycloakService,
    private _pgMessagesService: PgMessagesService,
    private _vesselsService: VesselsService) {
  }

  set selectedPortOrganisation(value: Organisation) {
    this._selectedPortOrganisation = value;
    this.locationSelected = true;
  }

  get selectedPortOrganisation() {
    if (this._selectedPortOrganisation) {
      return this._selectedPortOrganisation;
    }
  }

  alongSideSetter(dt: DateRange) {
    if (this._selectedPortOrganisation) {
      const timeZoneId = this._organisationService.getOrganisationTimezone(this._selectedPortOrganisation.name, this.ports);
      const d = DateTimeHelpers.getISODateStringZoneId(dt.startDate, timeZoneId);
      const alongSide = new Date(d).getTime();
      const bdnDate = new Date(this.bunkeringDeliveryNote.date).getTime();
      if (alongSide < bdnDate) {
        this.bunkeringDeliveryNote.sof.alongside = d;
        this.alongSideSetted = true;
      } else {
        this.message = 'Error';
        this._pgMessagesService.publishMessage({
          message: this.message,
          topic: 'SOFs along side Time Is Invalid',
          level: MessageLevel.Error
        });
      }
    }
  }

  hoseConnectedSetter(dt: DateRange) {
    if (this._selectedPortOrganisation) {
      const timeZoneId = this._organisationService.getOrganisationTimezone(this._selectedPortOrganisation.name, this.ports);
      const d = DateTimeHelpers.getISODateStringZoneId(dt.startDate, timeZoneId);
      const connected = new Date(d).getTime();
      const alongSide = new Date(this.bunkeringDeliveryNote.sof.alongside).getTime();
      const bdnDate = new Date(this.bunkeringDeliveryNote.date).getTime();
      if (connected < bdnDate && connected > alongSide) {
        this.bunkeringDeliveryNote.sof.hoseConnected = d;
        this.hoseConnectedSetted = true;
      } else {
        this.message = 'Error';
        this._pgMessagesService.publishMessage({
          message: this.message,
          topic: 'SOFs hose connected Time Is Invalid',
          level: MessageLevel.Error
        });
      }
    }
  }

  commencedPumpingSetter(dt: DateRange) {
    if (this._selectedPortOrganisation) {
      const timeZoneId = this._organisationService.getOrganisationTimezone(this._selectedPortOrganisation.name, this.ports);
      const d = DateTimeHelpers.getISODateStringZoneId(dt.startDate, timeZoneId);
      const commenced = new Date(d).getTime();
      const connected = new Date(this.bunkeringDeliveryNote.sof.hoseConnected).getTime();
      const bdnDate = new Date(this.bunkeringDeliveryNote.date).getTime();
      if (commenced < bdnDate && commenced > connected) {
        this.bunkeringDeliveryNote.sof.commencedPumping = d;
        this.commencedPumpingSetted = true;
      } else {
        this.message = 'Error';
        this._pgMessagesService.publishMessage({
          message: this.message,
          topic: 'SOFs commenced pumping Time Is Invalid',
          level: MessageLevel.Error
        });
      }
    }
  }

  completedPumpingSetter(dt: DateRange) {
    if (this._selectedPortOrganisation) {
      const timeZoneId = this._organisationService.getOrganisationTimezone(this._selectedPortOrganisation.name, this.ports);
      const d = DateTimeHelpers.getISODateStringZoneId(dt.startDate, timeZoneId);
      const completed = new Date(d).getTime();
      const commenced = new Date(this.bunkeringDeliveryNote.sof.commencedPumping).getTime();
      const bdnDate = new Date(this.bunkeringDeliveryNote.date).getTime();
      if (completed <= bdnDate && completed > commenced) {
        this.bunkeringDeliveryNote.sof.completedPumping = d;
        this.commencedPumpingSetted = true;
      } else {
        this.message = 'Error';
        this._pgMessagesService.publishMessage({
          message: this.message,
          topic: 'SOFs completed pumping Time Is Invalid',
          level: MessageLevel.Error
        });
      }
    }
  }

  hoseDisconnectedSetter(dt: DateRange) {
    if (this._selectedPortOrganisation) {
      const timeZoneId = this._organisationService.getOrganisationTimezone(this._selectedPortOrganisation.name, this.ports);
      const d = DateTimeHelpers.getISODateStringZoneId(dt.startDate, timeZoneId);
      const disconnect = new Date(d).getTime();
      const completed = new Date(this.bunkeringDeliveryNote.sof.completedPumping).getTime();
      const maxDate = new Date().getTime();
      if (disconnect < maxDate && disconnect > completed) {
        this.bunkeringDeliveryNote.sof.hoseDisconnected = d;
        this.hoseDisconnectedSetted = true;
      } else {
        this.message = 'Error';
        this._pgMessagesService.publishMessage({
          message: this.message,
          topic: 'SOFs hose disconnected Time Is Invalid',
          level: MessageLevel.Error
        });
      }
    }
  }

  ngOnInit() {
    this.valid = false;
    this.title.emit('Bunker Delivery Note');
    this._activateRoute.paramMap.subscribe(p => {
      this.vesselIMO = p.get('vesselIMO');
      this.timestamp = p.get('date');
      this.product = p.get('product');
      this.bunkeringDeliveryNote = {
        fileId: '00000000-0000-0000-0000-000000000000',
        fuelFileIds: [],
        bdnNumber: null,
        vesselName: null,
        vesselIMO: null,
        date: null,
        sof: {
          alongside: null,
          hoseConnected: null,
          commencedPumping: null,
          completedPumping: null,
          hoseDisconnected: null,
        },
        product: null,
        isDeleted: false,
        quantity: null,
        priorRob: null,
        supplierOrganisationId: null,
        locationOrganisationId: null,
        spec: {
          viscosity: null,
          density: null,
          water: null,
          sulphur: null,
          flashPoint: null
        },
        actions: []
      };
      forkJoin([this._organisationService.getPorts(),
          this._organisationService.getSupplier(),
        this._vesselsService.getVessels()]
      ).subscribe(results => {
        this.ports = results[0];
        this.supplier = results[1];
        this.vessels = results[2].filter(v => v.active);
        this.initBunkerDeliveryNote();
      });
    });
  }

  hasRole(roleName: string) {
    return this._keycloakService.hasRole(roleName);
  }

  initBunkerDeliveryNote() {
    if (this.vesselIMO && this.timestamp && this.product) {
      this.disable = true;
      this._vesselsService.getOneBunkeringDeliveryNote(this.vesselIMO, this.product, this.timestamp)
        .subscribe(t => {
          if(t.sof === undefined) {
            t.sof = this.bunkeringDeliveryNote.sof;
          }
          this.bunkeringDeliveryNote = t;
          this.selectedVessel = this.vessels.find(v => v.IMO === this.vesselIMO);
          this.bdnDate = {
            startDate: new Date(DateTimeHelpers.getLocalDateIsoStringToShow(t.date)),
            endDate: new Date(DateTimeHelpers.getLocalDateIsoStringToShow(t.date))
          };
          if (t.sof) {
            if (t.sof.alongside) {
              this.alongSide = {
                startDate: new Date(DateTimeHelpers.getLocalDateIsoStringToShow(t.sof.alongside)),
                endDate: new Date(DateTimeHelpers.getLocalDateIsoStringToShow(t.sof.alongside))
              };
            }
            if (t.sof.hoseConnected) {
              this.hoseConnected = {
                startDate: new Date(DateTimeHelpers.getLocalDateIsoStringToShow(t.sof.hoseConnected)),
                endDate: new Date(DateTimeHelpers.getLocalDateIsoStringToShow(t.sof.hoseConnected))
              };
            }
            if (t.sof.hoseDisconnected) {
              this.hoseDisconnected = {
                startDate: new Date(DateTimeHelpers.getLocalDateIsoStringToShow(t.sof.hoseDisconnected)),
                endDate: new Date(DateTimeHelpers.getLocalDateIsoStringToShow(t.sof.hoseDisconnected))
              };
            }
            if (t.sof.commencedPumping) {
              this.commencedPumping = {
                startDate: new Date(DateTimeHelpers.getLocalDateIsoStringToShow(t.sof.commencedPumping)),
                endDate: new Date(DateTimeHelpers.getLocalDateIsoStringToShow(t.sof.commencedPumping))
              };
            }
            if (t.sof.completedPumping) {
              this.completedPumping = {
                startDate: new Date(DateTimeHelpers.getLocalDateIsoStringToShow(t.sof.completedPumping)),
                endDate: new Date(DateTimeHelpers.getLocalDateIsoStringToShow(t.sof.completedPumping))
              };
            }
          }
          this._selectedPortOrganisation = this.ports.find(p => p.name === t.locationOrganisationId);
          this.selectedSupplierOrganisation = this.supplier.find(p => p.name === t.supplierOrganisationId);
          this.valid = t.fileId ? true : false;
          this.uploadedFileId = t.fileId;
        });
    }
  }

  date(dr: DateRange) {
    this.bdnDate.startDate = dr.startDate;
    this.bdnDate.endDate = dr.endDate;
    if (this._selectedPortOrganisation) {
      const timeZoneId = this._organisationService.getOrganisationTimezone(this._selectedPortOrganisation.name, this.ports);
      this.bunkeringDeliveryNote.date = DateTimeHelpers.getISODateStringZoneId(this.bdnDate.startDate, timeZoneId);
    }
  }

  uploadBDNFile(bdn: DocumentReference) {
    this.bunkeringDeliveryNote.fileId = bdn.id;
  }

  uploadFuelAnalysis(v: DocumentReference) {
    this.bunkeringDeliveryNote.fuelFileIds.push(v.id);
  }

  remove() {
    if (confirm(`Are you sure you want to delete this BDN?`)) {
      this.bunkeringDeliveryNote.isDeleted = true;
      this._vesselsService.upsertBunkering(this.bunkeringDeliveryNote).subscribe(() => {
        this._router.navigate(['/vessels/voyage/bunker/bunker-list']);
        this.message = 'Remove BDN';
        this._pgMessagesService.publishMessage({
          message: this.message,
          topic: 'Deleted',
          level: MessageLevel.Info
        });
      });
    }
  }

  save() {
    this.bunkeringDeliveryNote.actions.push(this._actionService.userAction(ActionType.Update));
    this.bunkeringDeliveryNote.vesselIMO = this.selectedVessel.IMO;
    this.bunkeringDeliveryNote.vesselName = this.selectedVessel.name.name;
    this.bunkeringDeliveryNote.supplierOrganisationId = this.selectedSupplierOrganisation.name;
    this.bunkeringDeliveryNote.locationOrganisationId = this.selectedPortOrganisation.name;
    this._vesselsService.upsertBunkering(this.bunkeringDeliveryNote).subscribe(() => {
      this.fuelFile = null;
      this.message = `${this.bunkeringDeliveryNote.vesselName}`;
      this._pgMessagesService.publishMessage({
        message: this.message,
        topic: 'Added New BDN',
        level: MessageLevel.Info
      });
    }, () => {
      this.message = 'Error';
      this._pgMessagesService.publishMessage({
        message: this.message,
        topic: 'BDN Insertion Error',
        level: MessageLevel.Error
      });
    });
  }

  downloadFile(fileId: string) {
    this._documentsService.download(fileId).subscribe(b => {
      saveAs(b.body, `${this.selectedSupplierOrganisation.name}.pdf`);
    }, () => {
      this._pgMessagesService.publishMessage({
        message: 'Could not download this file.',
        level: MessageLevel.Error,
        topic: 'Voyage document'
      });
    });
  }

  vesselFormatter(vessel: Vessel) {
    return vessel.name.name;
  }

}

<div class="row">
    <div class="col-md-12 col-xs-12">
        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
            id="gpsList"
            class="table table-sm table-striped table-bordered">
            <thead>
                <tr>
                    <th
                        *appHideIfNotInRole="['tracking-gps-editor']">ویرایش</th>
                    <th>شماره دستگاه ردیاب</th>
                    <th>شماره همراه ردیاب</th>
                    <th>نوع ردیاب</th>
                    <th>وضعیت</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let tracker of trackers">
                    <td *appHideIfNotInRole="['tracking-gps-editor']">
                        <button id="edit" name="edit" type="submit"
                            class="btn btn-link" (click)="editRecord(tracker)">
                            <i class="fa fa-pencil-square-o"></i>
                        </button>
                    </td>
                    <td>{{tracker.imei}}</td>
                    <td>{{tracker.mobileNumber}}</td>
                    <td>{{tracker.trackerType}}</td>
                    <td>{{checkTracker(tracker)}}</td>
                </tr>
            </tbody>
        </table>
    </div>
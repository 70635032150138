import { Component, EventEmitter, OnInit } from '@angular/core';
import { Organisation, OrganisationService } from 'src/app/organisation/organisation.service';
import { Truck, TrucksService } from 'src/app/transportation';
import { ActivatedRoute } from '@angular/router';
import { TankerData, MobileTrackerDevice, DriverData } from 'src/app/transportation/trucks/trucks.service';
import { forkJoin } from 'rxjs';
import { DocumentReference } from 'src/app/documents/documents.service';
import { PgMessagesService, MessageLevel } from 'src/app/shared/services/pg-messages.service';
import * as _ from 'lodash';
import * as momentJ from 'moment-jalaali';
import { PgTitleComponent } from '../../../../shared/components/title-bar/title-bar.component';

@Component({
  selector: 'app-new-truck',
  templateUrl: './truck-data-entry.component.html',
})
export class TruckDataEntryComponent implements OnInit, PgTitleComponent {
  title = new EventEmitter<string>();
  organisations: Organisation[] = [];

  selectedRegistrationNumber: string;
  dateRange = {
    startDate: new Date(),
    endDate: new Date(),
  };
  tanker: TankerData;
  tracker: MobileTrackerDevice;
  driver: DriverData = {
    name: null
  };
  transporter: string;
  truckZoom = 1;
  tankerZoom = 1;
  pagenumber = 1;
  downloadLink: string;
  showpdf: boolean;
  uploadedFileContent: any;

  tankerFileContent: any;

  selectedDocumentType: string;
  selectedTruck: Truck;
  mode: string;
  edit: boolean;
  tankers: TankerData[];
  trackers: MobileTrackerDevice[];
  trackersList: MobileTrackerDevice[];
  tankersList: TankerData[];
  trucks: Truck[];
  showMessage = false;
  success = false;
  pageNumber = 1;

  test = 1;

  editableField: string;

  associatedObjectList: any[];

  constructor(private _truckService: TrucksService,
    private _pgMessagesService: PgMessagesService,
    private _organisationsService: OrganisationService,
    private _activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.title.emit('Truck');
    this._activatedRoute.paramMap.subscribe(p => {
      this.mode = p.get('mode');
      if (this.mode === 'edit') {
        this.selectedRegistrationNumber = p.get('truckNumber');
        this.editableField = p.get('field');
      }
      this.getData();
    });
  }

  getData() {
    forkJoin([
      this._organisationsService.getTransporters(),
      this._truckService.getAllTankers(),
      this._truckService.getAllTracker(),
      this._truckService.getForLpgDist()
    ]).subscribe(result => {
      this.organisations = result[0];
      this.tankers = result[1];
      this.trackers = result[2];
      this.trucks = result[3];
      this.notUsedTrackers();
      if (this.mode === 'edit') {
        this._truckService.getByRegistrationNumber(this.selectedRegistrationNumber)
          .subscribe(truck => {
            this.selectedTruck = truck;
            this.transporter = this.selectedTruck.organisationId.associatedObject;
            this.notUsedTankers();
            if (this.selectedTruck.tanker) {
              this._truckService
                .getOneTanker(this.selectedTruck.tanker.associatedObject.number, this.selectedTruck.tanker.associatedObject.owner)
                .subscribe(tanker => {
                  this.tanker = tanker;
                  this.tankersList.push(this.tanker);
                });
            }
            if (this.selectedTruck.tracker !== undefined) {
              this.tracker = this.trackers.find(tracker => tracker.imei === this.selectedTruck.tracker.associatedObject.imei);
              this.trackersList.push(this.tracker);
            }
            if (this.selectedTruck.driver !== undefined) {
              this.driver = {
                name: this.selectedTruck.driver.associatedObject.name
              };
            }
            this.associatedObjectList = this.associatedObjectsFormatter();
          });
      }
      this.initialiseTruck();
    });
  }


  initialiseTruck() {
    if (this.mode === 'new') {
      this.selectedTruck = {
        fileId: '00000000-0000-0000-0000-000000000000',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        VIN: null,
        organisationId: null,
        tracker: null,
        lastLocation: null,
        formattedRegistrationNumber: '',
        truckNumber: [],
        tanker: null,
        isDeleted: false,
        driver: null,
        active: true,
        trackersHistory: [],
        tankersHistory: [],
        driversHistory: [],
        organisationsHistory: []
      };
    }
  }


  tankerValueFormatter(data: TankerData) {
    return data.number;
  }

  trackerValueFormatter(data: any) {
    return data.imei;
  }

  add() {
    this.selectedTruck.formattedRegistrationNumber = this.selectedTruck.truckNumber.slice(0, 4).join('-');
    if (this.mode === 'new') {
      this.addTemporalAssociation();
      this.edit = false;
      this._truckService.add(this.selectedTruck).subscribe(
        () => {
          this.getData();
          this._pgMessagesService.publishMessage({
            message: `truck No ${this.selectedTruck.truckNumber} imported`,
            topic: 'New truck',
            level: MessageLevel.Info
          });
        }, () => {
          this._pgMessagesService.publishMessage({
            message: 'Error',
            topic: 'Conflict',
            level: MessageLevel.Error
          });
        });
    } else if (this.mode !== 'new') {
      const edited = this.editTemporalAssociation();
      if (edited) {
        this.edit = true;
        this._truckService.update(this.selectedTruck).subscribe(() => {
          this.getData();
          this._pgMessagesService.publishMessage({
            message: `truck No ${this.selectedTruck.truckNumber} Edit`,
            topic: 'edit truck',
            level: MessageLevel.Info
          });
        }, () => {
          this._pgMessagesService.publishMessage({
            message: 'Error',
            topic: 'Tracker Insertion Error',
            level: MessageLevel.Error
          });
        });
      } else {
        alert('No Change');
      }
    }
  }

  uploadDocument(r: DocumentReference) {
    this.selectedTruck.fileId = r.id;
  }

  editTemporalAssociation() {
    if (this.editableField === 'tanker') {
      if (this.selectedTruck.tanker) {
        if (JSON.stringify(this.tanker) === JSON.stringify(this.selectedTruck.tanker.associatedObject)) {
          return false;
        } else {
          const tanker = this.selectedTruck.tanker;
          tanker.endTime = new Date().getTime();
          this.selectedTruck.tankersHistory.push(tanker);
        }
      }
      if (this.tanker !== undefined && this.tanker.number !== undefined) {
        this.selectedTruck.tanker = {
          startTime: new Date().getTime(),
          associatedObject: this.tanker,
          endTime: null
        };
      } else {
        this.selectedTruck.tanker = null;
      }
      return true;
    }
    if (this.editableField === 'driver') {
      if (this.selectedTruck.driver) {
        if (this.selectedTruck.driver.associatedObject.name === this.driver.name) {
          return false;
        } else {
          const driver = this.selectedTruck.driver;
          driver.endTime = new Date().getTime();
          this.selectedTruck.driversHistory.push(driver);
        }
      }
      if (this.driver !== undefined && this.driver.name !== undefined) {
        this.selectedTruck.driver = {
          startTime: new Date().getTime(),
          associatedObject: this.driver,
          endTime: null
        };
      } else {
        this.selectedTruck.driver = null;
      }
      return true;
    }

    if (this.editableField === 'transporter') {
      if (this.selectedTruck.organisationId) {
        if (this.selectedTruck.organisationId.associatedObject === this.transporter) {
          return false;
        } else {
          const transporter = this.selectedTruck.organisationId;
          transporter.endTime = new Date().getTime();
          this.selectedTruck.organisationsHistory.push(transporter);
        }
      }
      this.selectedTruck.organisationId = {
        startTime: new Date().getTime(),
        associatedObject: this.transporter,
        endTime: null
      };
      return true;
    }
    if (this.editableField === 'tracker') {
      if (this.selectedTruck.tracker) {
        if (JSON.stringify(this.selectedTruck.tracker.associatedObject) === JSON.stringify(this.tracker)) {
          return false;
        } else {
          const tracker = this.selectedTruck.tracker;
          tracker.endTime = new Date().getTime();
          this.selectedTruck.trackersHistory.push(tracker);
        }
      }
      if (this.tracker !== undefined && this.tracker.imei !== undefined) {
        this.selectedTruck.tracker = {
          startTime: new Date().getTime(),
          associatedObject: this.tracker,
          endTime: null
        };
      } else {
        this.selectedTruck.tracker = null;
      }
      return true;
    }
    if (this.editableField === 'file') {
      return true;
    }
    if (this.editableField === 'active') {
      return true;
    }
  }

  addTemporalAssociation() {
    if (this.tanker !== undefined && this.tanker.number !== undefined) {
      this.selectedTruck.tanker = {
        startTime: new Date().getTime(),
        associatedObject: this.tanker,
        endTime: null
      };
    } else {
      this.selectedTruck.tanker = null;
    }
    if (this.tracker !== undefined && this.tracker.imei !== undefined) {
      this.selectedTruck.tracker = {
        startTime: new Date().getTime(),
        associatedObject: this.tracker,
        endTime: null
      };
    } else {
      this.selectedTruck.tracker = null;
    }
    if (this.driver !== undefined && this.driver.name !== null) {
      this.selectedTruck.driver = {
        startTime: new Date().getTime(),
        associatedObject: this.driver,
        endTime: null
      };
    } else {
      this.selectedTruck.driver = null;
    }
    this.selectedTruck.organisationId = {
      startTime: new Date().getTime(),
      associatedObject: this.transporter,
      endTime: null
    };
  }

  associatedObjectsFormatter() {
    const organisations = this.selectedTruck.organisationsHistory.map(o => (
      {
        type: 'شرکت دارنده',
        objectId: this.organisations.find(t => t.name === o.associatedObject).nameTranslations['fa'],
        startTime: o.startTime,
        endTime: o.endTime
      }
    ));
    const drivers = this.selectedTruck.driversHistory.map(d => ({
      type: 'راننده',
      objectId: d.associatedObject.name,
      startTime: d.startTime,
      endTime: d.endTime
    }));

    const tankers = this.selectedTruck.tankersHistory.map(t => (
      {
        type: 'تانکر',
        objectId: t.associatedObject.number + this.organisations.find(ts => ts.name === t.associatedObject.owner).nameTranslations['fa'],
        startTime: t.startTime,
        endTime: t.endTime
      }
    ));
    const trackers = this.selectedTruck.trackersHistory.map(t => (
      {
        type: 'دستگاه ردیاب',
        objectId: t.associatedObject.imei,
        startTime: t.startTime,
        endTime: t.endTime
      }
    ));

    return trackers.concat(tankers).concat(drivers).concat(organisations).sort((a, b) => b.endTime - a.endTime).map(object => (
      {
        type: object.type,
        objectId: object.objectId,
        startTime: (object.startTime !== 0) ? momentJ.unix(object.startTime / 1000).format('jYYYY/jMM/jDD') : null,
        endTime: momentJ.unix(object.endTime / 1000).format('jYYYY/jMM/jDD')
      }
    ));
  }

  notUsedTankers() {
    const transporter = this.transporter.split('.').slice(0, 2).join('.');
    const transporterTankers = this.tankers.filter(t => t.owner.includes(transporter));
    const usedTransporterTankers = this.trucks.filter(t => t.tanker && t.tanker.associatedObject.owner.includes(transporter))
      .map(t1 => t1.tanker.associatedObject)
      .filter(t => t.owner.includes(transporter));
    const grouped = _.groupBy(usedTransporterTankers, (item) => item.number + '$' + item.owner);
    const result = _(grouped).map((items, key) => {
      const split = key.split('$');
      return {
        number: split[0],
        owner: split[1],
      };
    }).value();
    const map = result.reduce((hashMap, data) => {
      hashMap[data.number + data.owner] = data;
      return hashMap;
    }, {});
    this.tankersList = transporterTankers.filter(t => map[t.number + t.owner] === undefined);
  }

  notUsedTrackers() {
    const usedTrackers = this.trucks.filter(truck => truck.tracker && truck.tracker.associatedObject.imei !== '').map(t => t.tracker);
    const grouped = _.groupBy(usedTrackers, (item) => item.associatedObject.imei);
    const result = _(grouped).map((items, key) => ({
      imei: key
    })).value();
    const map = result.reduce((hashMap, data) => {
      hashMap[data.imei] = data;
      return hashMap;
    }, {});
    this.trackersList = this.trackers.filter(t => map[t.imei] === undefined);
  }
}

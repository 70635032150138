import { Component, EventEmitter, OnInit } from '@angular/core';
import { DateRange } from 'src/app/shared/components';
import { PgTitleComponent, PgDateRangeTitleComponent, PgToolbarComponent, PgDateRangeTitleEnum }
  from 'src/app/shared/components/title-bar/title-bar.component';
import { VesselsReportToolbarComponent } from '../vessels-report-toolbar/vessels-report-toolbar.component';
import { NoonReportsService, NoonReportV202005, HighchartsData } from '../services/noon-reports.service';
import { Vessel, VesselsService } from '../vessels.service';
import moment from 'moment';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-fuel-analysis',
  templateUrl: './fuel-analysis.component.html',
})
export class FuelAnalysisComponent implements OnInit, PgTitleComponent, PgDateRangeTitleComponent, PgToolbarComponent {
  title = new EventEmitter<string>();
  dateRangeSubTitle = new EventEmitter<[DateRange, PgDateRangeTitleEnum]>();
  toolbarComponentType = VesselsReportToolbarComponent;

  toolbarComponent: VesselsReportToolbarComponent;
  noonReports: NoonReportV202005[];
  dateRange: DateRange;
  vessels: Vessel[];
  vesselsConsumptions: any;
  selectedVessel: Vessel;
  chartSeries: HighchartsData[];
  chartFunction: any;
  dataExists: boolean[] = [true, true, true, true, true, true, true, true];

  constructor(
    private _noonReportsService: NoonReportsService,
    private _vesselService: VesselsService) { }

  ngOnInit(): void {

    this.initPage();
    this.toolbarComponent.date.subscribe(d => {
      this.dateRange = d;
      this.getData();
    });
    this.toolbarComponent.selectedVessel.subscribe(vessel => {
      this.selectedVessel = vessel;
      this.getData();
    });

    this.toolbarComponent.date.subscribe(dateRange => {
      this.dateRange = dateRange;
      this.getData();
    });
  }

  getData() {
    this.dataExists.forEach((d, index) => {
      this.dataExists[index] = true;
    });
    this.title.emit(`${this.selectedVessel.name.name} Noon reports analysis`);
    this._noonReportsService.getVesselNoonV202005(this.selectedVessel.IMO,this.dateRange).subscribe(noonReports => {
      this.noonReports = noonReports;
      this.vesselConsumptionBalance(this.noonReports);
    });
  }

  initPage() {
      this._vesselService.getActiveVessels().subscribe(results => {
      this.vessels = results;
      this.toolbarComponent.vessels = results;
      this.selectedVessel = this.vessels.find(v => v.IMO === '9534614');
      this.dateRange = {
        startDate: moment(new Date()).subtract(20,'days').toDate(),
        endDate: new Date()
      };
      this.toolbarComponent.dateRange = this.dateRange;
      this.toolbarComponent._selectedVessel = this.selectedVessel;
      this.title.emit(`${this.selectedVessel.name.name} Noon reports analysis`);
      this._noonReportsService.getVesselNoonV202005(this.selectedVessel.IMO,this.dateRange).subscribe(noonReports => {
        this.noonReports = noonReports;
        this.vesselConsumptionBalance(this.noonReports);
      });
    });
  }

  vesselConsumptionBalance(noonReports: NoonReportV202005[]) {
    this.vesselsConsumptions = this._noonReportsService.fuelConsumptionReport(noonReports);
    this.chartSeries = this.vesselsConsumptions.highchartsData;
    this.chartSeries.map((cons, index) => {
      this.plotPerProduct(cons.series, index.toString(), cons.series[0].name);
    });
    this.dataExists.forEach((d, index) => {
      if(this.chartSeries[index]) {
        this.dataExists[index] = true;
      } else {
        this.dataExists[index] = false;
      }
    });
  }

  plotPerProduct(series: any, divId: string, title: string, yMin = null, yMax = null) {
    if (!series && series.length === 0) {
      return;
    }
    Highcharts.chart(divId, {
      chart: {
        type: 'line'
      },
      title: {
        text: title
      },
      yAxis: {
        title: {
          text: 'ton'
        },
        labels: {
          // formatter() {
          //   return this.value;
          // }
        },
        min: yMin,
        max: yMax
      },
      tooltip: {
        pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}',
        split: true,
        xDateFormat: '%Y-%m-%d'
      },
      xAxis: {
        type: 'datetime',
      },
      plotOptions: {
        line: {
          marker: {
            enabled: false
          }
        }
      },
      series
    });
  }

  mapNoonReports(value) {
    return NoonReportsService.noonReporsAnalyseMapper(value);
  }

  consumptionsToDistanceRatio(consumptions: any[], distanceCovered: number) {
    return Math.floor(consumptions.reduce((a, b) => (a + b.sum), 0) * 1000 / distanceCovered);
  }

  duration() {
    return Math.round(moment.duration(moment(this.dateRange.endDate).diff(moment(this.dateRange.startDate))).asDays());
  }

  average() {
    return Math.round(this.vesselsConsumptions.distanceCovered /
    moment.duration(moment(this.dateRange.endDate).diff(moment(this.dateRange.startDate))).asDays());
  }

}



import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DateRange } from '../../../../shared/components';
import {
  Discharge,
  Vessel,
  Voyage,
  VesselsService,
  DataBaseAction,
  ProviderMeasurement,
  BillOfLading
} from '../../vessels.service';
import { Organisation, OrganisationService} from '../../../../organisation/organisation.service';
import { DateTimeHelpers } from '../../../../shared/helpers/date-time.helpers';
import { KeycloakService } from 'src/app/authentication/keycloak.service';
import { forkJoin } from 'rxjs';
import { MessageLevel, PgMessagesService } from '../../../../shared/services/pg-messages.service';
import { ActionType } from 'src/app/shared/services/database-action.service';

@Component({
  selector: 'app-discharge',
  templateUrl: './discharge.component.html'
})
export class DischargeComponent implements OnInit {

  @Output() changed: EventEmitter<Voyage> = new EventEmitter();
  @Output() cancelled: EventEmitter<any> = new EventEmitter();
  key = 'Discharge';
  _uploadedFileId: string;
  _selectedVoyage: Voyage;
  _selectedDischarge: Discharge;
  vessels: Vessel[];
  dischargeDate: DateRange;
  isVerified: boolean;
  surveyorFig: boolean;
  selectedVoyageBLNumbers: string[];
  daughterVessel: Vessel;

  surveyors: Organisation[];
  consignees: Organisation[];
  isSts = false;
  edit: boolean;
  daughterVesselVoyages: Voyage[] = [];

  productNames = ['Propane', 'Butane', 'LPG MIX'];

  portOrganisations: Organisation[];
  port: Organisation;
  consignee: Organisation;
  surveyor: Organisation = null;
  billOfLadingNumbers: string[];
  assignedBillOfLadings:BillOfLading[] = [];
  availableBillOfLadingsToAssign: BillOfLading[] = [];
  measurements: ProviderMeasurement[] = [];

  @Input() set fileId(value: string) {
    this._uploadedFileId = value;
  }
  @Input() set data(value: any) {
    this._selectedVoyage = value  as Voyage;
  }

  constructor(private _organisationsService: OrganisationService,
              private _vesselService: VesselsService,
              private _keycloakService: KeycloakService,
              private _pgMessagesService: PgMessagesService) { }

  ngOnInit() {
    this._selectedDischarge = {
      date: new Date().getTime(),
      measurements: [],
      sts: null,
      portOrganisationId: null,
      consigneeOrganisationId: null,
      fileId: null,
      actions: [],
      billOfLadingNumbers: []
    };
    forkJoin([
      this._organisationsService.get(),
      this._vesselService.getVessels()
    ]).subscribe(data => {
      this.vessels = data[1];
      this.consignees = data[0].filter(o => o.properties['isConsignee'] === true);
      this.portOrganisations = data[0].filter(o => o.properties['isAnchorage'] === true || o.properties['isPort'] === true);
      this.surveyors = data[0].filter(o => o.properties['isSurvey'] === true);
      this.selectedVoyageBLNumbers = this._selectedVoyage.billOfLadings.map(b => b.no);
      if (this._selectedVoyage.discharges.find(discharge => discharge.fileId === this._uploadedFileId)) {
        this.editDischarge();
      }
    });
  }

  hasRole(role: string) {
    return this._keycloakService.hasRole(role);
  }

  editDischarge() {
    this._selectedDischarge = this._selectedVoyage.discharges.find(discharge => discharge.fileId === this._uploadedFileId);
    this.isVerified = this._vesselService.isVerified(this._selectedDischarge);
    this.edit = true;
    this.port = this.portOrganisations.find(p => p.name === this._selectedDischarge.portOrganisationId);
    this.consignee = this.consignees.find(c => c.name === this._selectedDischarge.consigneeOrganisationId);
    this.dischargeDate = {
      startDate: new Date(DateTimeHelpers.getDateTimeFromTimestamp(this.port.timezone, this._selectedDischarge.date)),
      endDate: new Date(DateTimeHelpers.getDateTimeFromTimestamp(this.port.timezone, this._selectedDischarge.date))
    };
    this.billOfLadingNumbers = this._selectedDischarge.billOfLadingNumbers;
    this.assignedBillOfLadings = this._selectedVoyage.billOfLadings
      .filter(b => this._selectedDischarge.billOfLadingNumbers.includes(b.no));

    this.measurements = this._selectedDischarge.measurements;
    this.assignAvailableBillOfLadings();
    this.isSts = (this.port.properties['isAnchorage']) ? true : false;
    if (this._selectedDischarge.sts) {
      this.daughterVessel = this.vessels.find(v => v.IMO === this._selectedDischarge.sts.vesselIMO);
      this.getDaughterVesselVoyages(this.daughterVessel);
    }
  }

  assignAvailableBillOfLadings() {
    const usedBillOfLadings = this._selectedVoyage.discharges.flatMap(d => d.billOfLadingNumbers).concat(
      this.assignedBillOfLadings.filter(b => b && b.no).map(b => b.no));
    this.availableBillOfLadingsToAssign = this._selectedVoyage.billOfLadings
      .filter(b => !usedBillOfLadings.includes(b.no));
  }

  selectDate(dateRange: DateRange) {
    this._selectedDischarge.date = dateRange.startDate.getTime();
  }

  organisationValueFormatter(data: Organisation) {
    return data.nameTranslations['en'];
  }

  compareDates(first: number, second: number) {
    const dateOne = new Date(first);
    const dateTwo = new Date(second);
    return (
      dateOne.getFullYear() === dateTwo.getFullYear() &&
      dateOne.getMonth() === dateTwo.getMonth() &&
      dateOne.getDate() === dateTwo.getDate());
  }

  saveDischarge() {
    this._selectedDischarge.portOrganisationId = this.port.name;
    this._selectedDischarge.billOfLadingNumbers = this.assignedBillOfLadings.map(b => b.no);
    this._selectedDischarge.measurements = this.measurements;
    const selectedTimezoneOffset = this.portOrganisations.find(o => o.name === this._selectedDischarge.portOrganisationId).timezone;
    if (this.isSts) {
      this._selectedDischarge.sts.vesselIMO = this.daughterVessel.IMO;
    }
    if (!selectedTimezoneOffset) {
      console.error(`Timezone offset is missing for selected organisation Id: ${this._selectedDischarge.portOrganisationId}`);
    } else {
      this._selectedDischarge.fileId = this._uploadedFileId;
      if (!this.isSts && this.consignee) {
        this._selectedDischarge.consigneeOrganisationId = this.consignee.name;
      }
      if (this.isSts && this.daughterVesselVoyages.length > 0) {
        this._selectedDischarge.sts.vesselName = this.daughterVesselVoyages
          .find(v => this._selectedDischarge.sts.shipVoyageNo === v.shipVoyageNo.toString()).vesselName;
      }
      const tempDischarge = this._selectedVoyage.discharges.find(discharge => discharge.fileId === this._uploadedFileId);
      if (this.edit && this._selectedDischarge.date !== tempDischarge.date) {
        this._selectedDischarge.date = DateTimeHelpers.offsetTimestampWithZone(this._selectedDischarge.date, selectedTimezoneOffset);
      }
      if (!this.edit) {
        this._selectedDischarge.date = DateTimeHelpers.offsetTimestampWithZone(this._selectedDischarge.date, selectedTimezoneOffset);
        if (!this._selectedVoyage.discharges) {
          this._selectedVoyage.discharges = [];
        }
      }
      if (!this.edit) {
        const duplications = this._selectedVoyage.discharges
          .filter(s => s.portOrganisationId === this._selectedDischarge.portOrganisationId
            && s.consigneeOrganisationId === this._selectedDischarge.consigneeOrganisationId
            && this.compareDates(this._selectedDischarge.date, s.date));
        if (duplications.length >= 1) {
          this._pgMessagesService.publishMessage({
            message: `Duplicate Discharge`,
            level: MessageLevel.Error,
            topic: 'Voyage'
          });
        } else {
          this._selectedVoyage.discharges.push(this._selectedDischarge);
          this.changed.emit(this._selectedVoyage);
        }
      } else {
        this.changed.emit(this._selectedVoyage);
      }
    }
  }

  verify() {
    if (this._vesselService.verify(this._selectedDischarge, 'Discharge')) {
      this.saveDischarge();
    }
  }

  removeVerification() {
    if (this._vesselService.removeVerification(this._selectedDischarge, 'Discharge')) {
      this.saveDischarge();
    }
  }

  userAction() {
    if (this._selectedDischarge.actions === undefined) {
      this._selectedDischarge.actions = [];
    }
    if (this.edit  && this.isVerified !== true) {
      const action: DataBaseAction = {
        userName: this._keycloakService.getUser().username,
        timestamp: new Date().getTime(),
        userAction: ActionType.Update
      };
      this._selectedDischarge.actions.push(action);
    }
    if (!this.edit) {
      const action: DataBaseAction = {
        userName: this._keycloakService.getUser().username,
        timestamp: new Date().getTime(),
        userAction: ActionType.Create
      };
      this._selectedDischarge.actions.push(action);
    }
  }

  addNewMeasurement(providerName: string) {
    const measurement =  {
        providerId: null,
        provider: providerName,
        primary: null,
        product: null,
        weight: null
      };
    if (providerName === 'Mother Vessel Figure') {
      measurement.providerId = this._selectedVoyage.vesselIMO;
    } else if (providerName === 'Daughter Vessel Figure') {
      this.surveyorFig = false;
      measurement.providerId = this.daughterVessel.IMO;
    }
    this.measurements.push(measurement);
  }

  removeMeasurement(index: number) {
    this.measurements.splice(index, 1);
  }

  dischargeLocation(organisation: Organisation) {
    if(organisation) {
      this.isSts = (organisation.properties['isAnchorage'] === true);
      if (this.isSts) {
        this._selectedDischarge.sts = {
          vesselIMO: null,
          vesselName: null,
          shipVoyageNo: null
        };
      }
    }
  }

  getDaughterVesselVoyages(vessel: Vessel) {
    if(vessel) {
      this._vesselService.getVoyagesByIMO(vessel.IMO).subscribe(voyages => {
        this.daughterVesselVoyages = voyages;
    });
    }
  }

  providerFormatter(measurement: ProviderMeasurement) {
    if ((measurement.provider === 'Surveyor' || measurement.provider === 'Surveyor Loading')  && this.surveyors) {
      return this.surveyors.find(s => s.name === measurement.providerId).nameTranslations['en'];
    } else if (measurement.provider === 'Mother Vessel Figure') {
      return this._selectedVoyage.vesselName;
    } else if (measurement.provider === 'Daughter Vessel Figure' && this._selectedDischarge.sts.vesselName) {
      return this._selectedDischarge.sts.vesselName;
    } else if (measurement.provider === 'Daughter Vessel Figure') {
      return this.daughterVessel.name.name;
    }
  }

  addBl() {
    this.assignedBillOfLadings.push(null);
    this.assignAvailableBillOfLadings();
  }

  selectedAssignedBLChanged(i: number, bl: BillOfLading) {
    this.assignedBillOfLadings[i] = bl;
  }

  removeAssignedBL(i: number) {
    this.assignedBillOfLadings.splice(i, 1);
  }

  selectedMeasurementProviderChanged(measurement: ProviderMeasurement, organisation: Organisation) {
    measurement.providerId = organisation.name;
  }

  vesselFormatter(vessel: Vessel) {
    return vessel.name.name;
  }

  deleteDischarge() {
    if (confirm('Are you sure to delete this Discharge')) {
      const i = this._selectedVoyage.discharges.findIndex(d => d.fileId === this._selectedDischarge.fileId);
      this._selectedVoyage.discharges.splice(i, 1);
      this.changed.emit(this._selectedVoyage);
    }
  }
}

<nav class="d-md-block sidebar toggled">
  <div class="sidebar-sticky">
    <ul class="nav flex-column">
      <li class="nav-item brand">
        <img alt src="\assets\icons\main-logo\parsagroup-logo1.png"
          class="brand-image">
        <span class="sidebar-text">Parsagroup</span>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/profile">
          <img alt="{{userData?.fullName}}" [src]='thumbnail'
            class="profile-image rounded-circle">
          <span class="sidebar-text">{{userData.fullName}}
            <a class="btn" (click)="signOut()"><i
                class="fa fa-sign-out"></i></a></span>
        </a>
      </li>

      <ng-container *ngFor="let item of permittedSidebarItems">
        <li class="nav-item">
          <a href="{{'#sub' + item.label.replace(' ', '_')}}"
            data-bs-toggle="collapse" aria-expanded="false"
            aria-controls="{{'#sub' + item.label}}"
            class="nav-link">
            <span class="fa-stack fa-lg"><i [ngClass]="item.icon"></i></span>
            <span class="sidebar-text">{{ item.label }}</span>
          </a>
          <ul class="collapse" id="{{'sub' + item.label.replace(' ', '_')}}">
            <ng-container *ngFor="let subItem of item.subItems">
              <li class="nav-item submenu">
                <a class="nav-link sidebar-text sidebar-item-content"
                  [routerLink]="subItem.href"> {{ subItem.label }}
                  <i class="float-end fa"
                    [ngClass]="{
                     'fa-square-o': !isChecked(subItem.label),
                     'fa-check-square-o': isChecked(subItem.label)
                     }"
                    (click)="addOrRemoveShortcut(subItem.label, subItem.href)">
                  </i>
                </a>
              </li>
            </ng-container>
          </ul>
        </li>
      </ng-container>
    </ul>
  </div>
  <div class="version"><span>Version {{version}}</span></div>
</nav>

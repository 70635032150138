<ul class="nav flex-row-reverse">
  <li class="nav-item">
    <a class="btn text-muted"
      (click)="onSubmit()">Refresh</a>
  </li>
  <li class="nav-item">
    <select
      class="form-control"
      name="type"
      id="type"
      [(ngModel)]="_type"
      (change)="filterSOFItems()">
      <option value="loading">Loading</option>
      <option value="discharge">Discharge</option>
    </select>
  </li>
  <li class="nav-item">
    <app-auto-complete
      id="SofItem2"
      [formatter]="lookupItemListFormatter"
      [(ngModel)]="extraSofEntry2"
      [items]="filterSofItems"
      name="SofItem2">
    </app-auto-complete>
  </li>
  <li class="nav-item">
    <app-auto-complete
      id="SofItem1"
      [formatter]="lookupItemListFormatter"
      [(ngModel)]="extraSofEntry"
      [items]="filterSofItems"
      name="SofItem1">
    </app-auto-complete>
  </li>
  <li class="nav-item">
    <app-date-range
      id="selectedDateRangeGregorian"
      [(dateRange)]="dateRange">
    </app-date-range>
  </li>
</ul>


import {Component, EventEmitter, OnInit} from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { Router } from '@angular/router';
import * as Highcharts from 'highcharts';
import * as momentJ from 'moment-jalaali';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Organisation, OrganisationService } from '../../organisation/organisation.service';
import { DateRange } from '../../shared/components';
import { LPGDistService, LPGDistData, ToolbarItem } from '../lpg-dist.service';
import { LpgDistToolbarComponent } from 'src/app/lpg-dist/lpg-dist-toolbar/lpg-dist-toolbar.component';
import { UserLocalStoreService } from 'src/app/shared/services/user-local-store.service';
import {
  PgDateRangeTitleComponent,
  PgDateRangeTitleEnum, PgTitleComponent,
  PgToolbarComponent
} from '../../shared/components/title-bar/title-bar.component';

interface LpgReportComponentSettings {
  companyId: string;
  calendar: boolean;
}

@Component({
  selector: 'app-lpg-report',
  templateUrl: 'lpg-report.component.html'
})
export class LpgReportComponent implements OnInit, PgTitleComponent, PgDateRangeTitleComponent, PgToolbarComponent {
  title = new EventEmitter<string>();
  dateRangeSubTitle = new EventEmitter<[DateRange, PgDateRangeTitleEnum]>();
  toolbarComponentType = LpgDistToolbarComponent;
  toolbarComponent: LpgDistToolbarComponent;
  loadingData: Observable<LPGDistData[]>;
  dateRange: DateRange;
  companies: Organisation[];

  totalLoadingWeight: number;
  dailyWeight: number;
  selectedOrganisation: Organisation;
  lastDayTotalWeight: number;

  _settings: LpgReportComponentSettings;


  constructor(private _lpgDistService: LPGDistService,
              private _organisationService: OrganisationService,
              private _router: Router,
              private _userLocalStorage: UserLocalStoreService) { }

  ngOnInit(): void {
    this.initPage();
  }

  fetchData() {
    forkJoin([
      this._organisationService.get(),
      this._lpgDistService.getOrganisationSummary(this._settings.companyId,
        this.dateRange.startDate.getTime(),
        this.dateRange.endDate.getTime())
    ]).subscribe(data => {
      this.companies = data[0];
      this.setToolbar();
      const startOfLastDay =  momentJ(this.dateRange.endDate).startOf('day').toDate().getTime();
      const lastDayData = data[1].filter(d => d.loadTimestamp >= startOfLastDay);
      this.lastDayTotalWeight = Math.round(lastDayData.reduce((p, c) => p + c.loadingWeight, 0) / 1000);
      this.totalLoadingWeight = Math.round(data[1].reduce((p, c) => p + c.loadingWeight, 0) / 1000);
      const total = data[1].reduce((p, c) => p + c.loadingWeight, 0);
      const days = momentJ(this.dateRange.endDate).diff(this.dateRange.startDate, 'days');
      this.dailyWeight = Math.round(total / days / 1000);
      this.reportBySource(data[1]);
      this.reportByDestination(data[1], 'destinationBarChartContainer');
      this.reportByDestination(lastDayData, 'dailyDestinationBarChartContainer');
      this.reportByTransporter(data[1], 'transporterBarChartContainer');
      this.reportByTransporter(lastDayData, 'transporterDailyBarChartContainer');
      this.reportDaily(data[1]);
      this.reportMonthly(data[1]);
    });
  }

  setToolbar() {
    this.selectedOrganisation = this.companies.find(c => c.name === this._settings.companyId);
    this.title.emit('Loadings for' + ' ' + this.selectedOrganisation.nameTranslations['en']);
    this.dateRangeSubTitle.emit([this.dateRange, PgDateRangeTitleEnum.defaultTitle]);
    this.toolbarComponent.dateRange = {
      startDate: this.dateRange.startDate,
      endDate: this.dateRange.endDate
    };
    this.toolbarComponent.selectedOrganisation =  this.selectedOrganisation;
    this.toolbarComponent.visibleOrganisation = true;
    this.toolbarComponent.organisations = this.companies.filter(o =>  ['domestic', 'isSeller'].filter(p => o.properties[p]).length > 0);
    this.toolbarComponent.jalali = this._settings.calendar;
    this.toolbarComponent.itemsSelected.subscribe (t => {
      this.refreshData(t);
    });
  }


  initPage() {
    this._settings = this._userLocalStorage.get<LpgReportComponentSettings>('LpgReportComponent');
    if (!this._settings || !this._settings.companyId) {
      this._settings = { companyId: 'iran.nigc', calendar: false };
    }
    const endDate = momentJ().endOf('day').toDate();
    const startDate = (this._settings.calendar) ? momentJ(endDate).subtract(2, 'months').startOf('jMonth').toDate() :
      moment(endDate).subtract(2, 'months').startOf('month').toDate();
    this.dateRange = {
      startDate: new Date (startDate),
      endDate: new Date (endDate)
    };
    this.fetchData();
  }

  reportBySource(data: LPGDistData[]) {
    const groupBySource = this.groupBy<LPGDistData>(data, i => i.source);
    const result = [];
    groupBySource.forEach((values, key) => {
      const totalPerSource = values.reduce((p, v) => p + v.loadingWeight, 0);
      result.push({
        source: key,
        name: key,
        y: Math.round(totalPerSource / 1000)
      });
    });
    this.plotBarChart(result, 'sourceBarChartContainer', result.map(d => d.name));
  }

  reportByDestination(data: LPGDistData[], container: string) {
    const groupByDestination = this.groupBy<LPGDistData>(data, i => i.destinationOrganisationId);
    const result = [];
    groupByDestination.forEach((values, key) => {
      const totalPerDestination = values.reduce((p, v) => p + v.loadingWeight, 0);
      // result.set(key, sum)
      result.push({
        source: key,
        name: key,
        y: Math.round(totalPerDestination / 1000)
      });
    });
    const switchName = result.map(r => {
        const org = this.companies.find(c => c.name === r.source);
        const organisationName = (org !== undefined) ? org.nameTranslations[(this._settings.calendar) ? 'fa' : 'en'] : '';
        return {
          source: organisationName,
          name: organisationName,
          y: r.y
        };
    });
    this.plotBarChart(switchName, container, switchName.map(d => d.name));
  }

  reportByTransporter(data: LPGDistData[], container: string) {
    const groupByDestination = this.groupBy<LPGDistData>(data, i => i.transporterOrganisationId);
    const result = [];
    groupByDestination.forEach((values, key) => {
      const totalPerTransporter = values.reduce((p, v) => p + v.loadingWeight, 0);
      // result.set(key, sum)
      result.push({
        source: key,
        name: key,
        y: Math.round(totalPerTransporter / 1000)
      });
    });
    const switchName = result.map(r => {
        const org = this.companies.find(c => c.name === r.source);
        const organisationName = (org !== undefined) ? org.nameTranslations[(this._settings.calendar) ? 'fa' : 'en'] : '';
        return {
          source: organisationName,
          name: organisationName,
          y: r.y
        };
    });
    this.plotBarChart(switchName, container, switchName.map(d => d.name));
  }

  groupBy<T>(list: T[], keyGetter: (item: T) => (string | number)): Map<(string | number), T[]> {
    const m = new Map<(string | number), T[]>();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = m.get(key);
      if (!collection) {
        m.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return m;
  }

  reportDaily(data: LPGDistData[]) {
    const groupByDate = this.groupBy<LPGDistData>(data, i =>
      momentJ(i.loadTimestamp).startOf('day').unix() * 1000);
    const result = [];
    groupByDate.forEach((values, key) => {
      const totalPerDay = values.reduce((p, v) => p + v.loadingWeight, 0);
      result.push([key, Math.round(totalPerDay / 1000)]);
    });
    let j = 0;
    result.sort((a, b) => a[0] - b[0]);
    const dayMillisecond = 24 * 60 * 60 * 1000;
    while (j < result.length) {
      if ((j + 1) < result.length) {
        if (result[j][0] + dayMillisecond < result[j + 1][0]) {
          result.push([result[j][0] + dayMillisecond, 0]);
        }
        result.sort((a, b) => a[0] - b[0]);
      }
      j++;
    }

    this.plotLineChart(result.sort((a, b) => a[0] - b[0]), 'dailyReportLineChartContainer');
  }

  refreshData(item: ToolbarItem) {
    this._settings = {
      companyId: this.companies.find(o => o.name === item.companyId).name,
      calendar: item.selectedCalander
    };
    this.dateRange = item.dateRange;
    this._userLocalStorage.save('LpgReportComponent', this._settings);
    this.fetchData();
  }

  plotLineChart(chartSeries: any[], containerId: string | HTMLElement) {
    if (this._settings.calendar) {
      momentJ.loadPersian();
    }
    const selectedCalendar = this._settings.calendar;
    Highcharts.chart(containerId, {
      title: { text: '' },
      exporting: {
        allowHTML: true,
        chartOptions: { // specific options for the exported image
            plotOptions: {
                series: {
                    dataLabels: {
                        enabled: true
                    }
                }
            }
        },
        fallbackToExportServer: false
      },
      chart: { type: 'area' },
      xAxis: {
        type: 'datetime',
        labels: {
          formatter() {
            const a: any = this;
            if (selectedCalendar) {
              if (a.dateTimeLabelFormat === '%e. %b') {
                return momentJ(this.value).format('jD jMMM');
              } else if (a.dateTimeLabelFormat === '%b \'%y') {
                return momentJ(this.value).format('jMMM jYYYY');
              } else if (a.dateTimeLabelFormat === '%Y') {
                return momentJ(this.value).format('jYYYY');
              }
            } else {
              if (a.dateTimeLabelFormat === '%e. %b') {
                return momentJ(this.value).format('D MMM');
              } else if (a.dateTimeLabelFormat === '%b \'%y') {
                return momentJ(this.value).format('MMM YYYY');
              } else if (a.dateTimeLabelFormat === '%Y') {
                return momentJ(this.value).format('YYYY');
              }
            }
            if (a.dateTimeLabelFormat === '%H:%M:%S') {
              return momentJ(this.value).format('h:mm:ss');
            } else if (a.dateTimeLabelFormat === '%H:%M') {
              return momentJ(this.value).format('hh:mm');
            } else if (a.dateTimeLabelFormat === '%H:%M:%S.%L') {
              return momentJ(this.value).format('hh:mm:ss');
            } else {
              console.log(`missing formatter for ${a.dateTimeLabelFormat} and value: ${this.value}`);
            }
          }
        }
        },
      yAxis: {
        title: {
          text: (selectedCalendar) ? 'وزن - تن' : 'Ton'
        }
      },
      series: [{
        type: 'area',
        name: this.selectedOrganisation.nameTranslations['en'],
        data: chartSeries
      }],
      tooltip: {
        formatter() {
          if (selectedCalendar) {
            return `${momentJ(this.x).format('jYYYY-jMM-jDD')}<br> تن ${this.y}`;
          } else {
            return `${momentJ(this.x).format('YYYY-MM-DD')}<br>  ${this.y} Ton`;
          }

        }
      }
    });
  }

  plotBarChart(chartSeries, containerId, groups, color= true) {
    const selectedCalendar = this._settings.calendar;
    Highcharts.chart(containerId, {
      title: { text: '' },
      chart: { type: 'column' },
      xAxis: { categories: groups },
      yAxis: {
        title: { text: (selectedCalendar) ? 'وزن - تن' : 'Ton' }
      },
      series: [{
        type: 'column',
        name: this.selectedOrganisation.nameTranslations['en'],
        data: chartSeries,
        colorByPoint: color
      }],
      tooltip: {
        formatter() {
          return `${this.y} ${(selectedCalendar) ? 'تن' : 'Ton'} `;
        }
      }
    });
  }

  reportMonthly(data: LPGDistData[]) {
    let g = _.groupBy(data, i => momentJ(i.loadTimestamp).startOf('month').unix() * 1000);

    if (this._settings.calendar) {
      g = _.groupBy(data, i => momentJ(i.loadTimestamp).startOf('jMonth').unix() * 1000);
    }

    const monthlySum = _(g).map(
      (items, key) => [key, Math.round(items.reduce((p, v) => p + v.loadingWeight, 0) / 1000)])
      .sortBy(i => i[0])
      .map(i => {
        if (this._settings.calendar) {
          return [momentJ(+i[0]).format('jYYYY/jMMM'), i[1]];
        } else {
          return [momentJ(+i[0]).format('YYYY/MMM'), i[1]];
        }
      }).value();

    this.plotBarChart(monthlySum,
      'monthlyReportBarChartContainer',
      monthlySum.map(i => i[0]),
      false);
  }
}

<div class="row">
  <div class="col-md-6">
    <form class="form-horizontal" (submit)="uploadDocument()">
      <div class="card shadow-sm card-body">
        <div class="form-group">
          <div class="form-group row">
            <label for="noonReportFile"
              class="col-form-label col-md-2 ">File</label>
            <div class="col-md-10">
              <app-file-uploader [accept]="'.xlsx, .xls'"
                id="noonReportFile"
                name="noonReportFile"
                [(ngModel)]="currentFiles" [required]="true" tabindex="15">
              </app-file-uploader>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="selectedDateRange" class="col-md-2 "> Date: </label>
          <app-date-range
            class="col-md-10"
            id="selectedDateRange"
            [(dateRange)]="selectedDate"
            [singleDateSelector]="true"
            appDateRangeRequired="true">
          </app-date-range>
        </div>
        <div class="form-group row">
          <label for="noonReportTypeGroup" class="col-md-2 "> Type:</label>
          <div class="col-md-10" id="noonReportTypeGroup">
            <div class="radio">
              <label><input type="radio" name="optradio"
                  checked>V20200521</label>
            </div>
          </div>
        </div>
        <div>
          <div class="btn-group pull-right mt-3 col-md-4" role="group"
            aria-label="Button group with nested dropdown">
            <input type="submit" id="uploadDocument"
              class="btn btn-primary me-1" value="Upload"
              [disabled]="!currentFiles">
            <button class="btn btn-outline-secondary btn-light"
              (click)="cancel()"> Cancel
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

import { Component, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { DataTablesService } from './../shared/datatables/data-tables.service';
import { PhoneBookData, PhoneBookService, PhoneNumber } from './phone-book.service';
import { KeycloakService } from '../authentication/keycloak.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import {PgTitleComponent} from '../shared/components/title-bar/title-bar.component';

@Component({
  selector: 'app-phone-book',
  templateUrl: 'phone-book.component.html'
})
export class PhoneBookComponent implements OnInit, PgTitleComponent {
  @ViewChild(DataTableDirective, {static: true})
  dtElement: DataTableDirective;

  title = new EventEmitter<string>();

  showEntry: boolean; // = false;
  personInfo: PhoneBookData;
  editMode: boolean; // = false;
  searchSelection: string;
  phoneBookData: PhoneBookData[] = [];
  // dataFiltered: PhoneBookData[] = [];
  currentRow: string;


  dtOptions = {};
  dtTrigger: Subject<any> = new Subject<any>();
  isDataTablesInitialised = false;


  constructor(private _phoneBookService: PhoneBookService,
    private _keycloakService: KeycloakService,
    private _router: Router) {}


  ngOnInit() {
    this.title.emit('Phonebook');
    const currentComponent = this;
    const buttons = [{
          text: 'ایجاد مخاطب جدید',
          action: () => currentComponent.addRecord()
          }];
    if (this.hasRole('phonebook-editor')) {
      this.dtOptions = DataTablesService.getOptions(buttons);
    } else {
      this.dtOptions = DataTablesService.getOptions();
    }
    this.retrievePhoneNumber(true);
  }

  addRecord() {
    this._router.navigate(['/phone-book/entry/new']).then(_ => _);
  }

  findPhoneNumberFromCategory(phoneNumbers: PhoneNumber[], category: string) {
    return this._phoneBookService.findPhoneNumberFromCategory(phoneNumbers, category);
  }

  retrievePhoneNumber(success: boolean) {
    if (success) {
      this._phoneBookService.getNumbers().subscribe(data => {
        this.phoneBookData = data;
        if (!this.isDataTablesInitialised) {
          this.dtTrigger.next(false);
        } else {
          this.dtElement.dtInstance.then((dtInstance) => {
            dtInstance.destroy();
            this.dtTrigger.next(false);
          });
        }
        this.isDataTablesInitialised = true;
      });
    }
  }

  closePhoneBookEntry(close: boolean) {
    if (close) {
      this.showEntry = false;
    }
  }

  insertedNewContact(success: boolean) {
    this.retrievePhoneNumber(success);
  }


  hasRole(roleName: string) {
    return this._keycloakService.hasRole(roleName);
  }

  showinfo(dtinfo: PhoneBookData) {
    this._router.navigate(['/phone-book/entry/edit',
    encodeURIComponent(dtinfo.title),
    encodeURIComponent(dtinfo.name),
    encodeURIComponent(dtinfo.companyName)]).then(_ => _);
  }

}

import {OnInit, Component, ViewChild, EventEmitter} from '@angular/core';
import { TrucksService, Truck } from 'src/app/transportation/trucks/trucks.service';
import { KeycloakService } from 'src/app/authentication/keycloak.service';
import { Subject, forkJoin } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { Organisation, OrganisationService} from 'src/app/organisation/organisation.service';
import { DataTablesService } from 'src/app/shared/datatables/data-tables.service';
import * as moment from 'moment';
import {PgTitleComponent} from '../../../shared/components/title-bar/title-bar.component';

@Component({
  templateUrl: 'tracker-monitor-list.component.html',
})
export class TrackerMonitorListComponent implements  OnInit, PgTitleComponent {
  @ViewChild(DataTableDirective, {static: true})
  dtElement: DataTableDirective;

  title = new EventEmitter<string>();


  trucks: Truck[];
  isDataTablesInitialised = false;

  outliersCash: {[key: string]: any[]} = {};

  dtOptions = {};
  activeDtOptions = {};
  dtTrigger: Subject<any> = new Subject<any>();
  _organisationsCache: { [key: string]: Organisation } = {};

  constructor(private _trucksService: TrucksService,
              private _keycloakService: KeycloakService,
              private _organisationService: OrganisationService) { }

  ngOnInit(): void {
    this.title.emit('Tracker Monitoring');
    this.dtOptions = DataTablesService.getOptions();
    this.activeDtOptions = DataTablesService.getOptions();
    const startDate = moment().startOf('day').subtract(2, 'd').toDate().getTime();
    const endDate = moment().endOf('day').toDate().getTime();
    forkJoin([
      this._organisationService.get(),
      this._trucksService.getForLpgDist(),
      this._trucksService.getOutlierLocations(startDate, endDate)
    ]).subscribe(result => {
      result[0].forEach(o => {
        this._organisationsCache[o.name] = o;
      });
      this.trucks = result[1].filter(truck => truck.tracker && truck.tracker.associatedObject.imei !== '');
      this.outliersCash = result[2].reduce((hashMap, outliers) => {
        hashMap[outliers[0]] = outliers[1];
        return hashMap;
      }, {});
      this.initialiseTrucks();
    });
  }

  isTruckOutdated(truck: Truck) {
    const outDated = this._trucksService.isPositionOutdated(truck);
    if (truck.tracker && truck.tracker.associatedObject.imei !== '') {
      if (outDated) {
        return 'خارج از دسترس';
      } else {
        return 'در دسترس';
      }
    } else {
      return '';
    }
  }

  initialiseTrucks() {
      if (!this.isDataTablesInitialised) {
        this.dtTrigger.next(false);
      } else {
        this.dtElement.dtInstance.then((dtInstance) => {
          dtInstance.destroy();
          this.dtTrigger.next(false);
        });
      }
      this.isDataTablesInitialised = true;
  }

  formatOrganisation(organisationId: string) {
    const foundOrganisation = this._organisationsCache[organisationId];
    if (foundOrganisation) {
      return foundOrganisation.nameTranslations['fa'];
    } else {
      return '';
    }
  }


  getOutlierLocations(truck: Truck) {
    if (truck.tracker !== undefined && this.outliersCash[truck.tracker.associatedObject.imei]) {
      return this.outliersCash[truck.tracker.associatedObject.imei].length;
    } else {
      return null;
    }
  }

}

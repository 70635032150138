import { Component, Input } from '@angular/core';

export interface TimelineItem {
  title: string;
  description: string;
  date: string;
  iconStyles?: string;
}


@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.css']
})


export class TimelineComponent {

  _timelineHeader: string;
  _timelineItems: TimelineItem[];


  @Input() set timelineHeader(value: string) {
    this._timelineHeader = value;
  }

  @Input() set timelineItems(value: TimelineItem[]) {
    this._timelineItems = value;
  }
}

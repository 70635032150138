
import * as moment from 'moment-timezone';
import * as momentJ from 'moment-jalaali';
import {DateRange} from '../components';


export class DateTimeHelpers {

  static offsetTimestampWithZone(timestamp: number, timezoneId: string) {
    const n = new Date(timestamp).getTimezoneOffset();
    const zoneOffset = moment.tz.zone(timezoneId).utcOffset(timestamp);
    return timestamp + (zoneOffset - n) * 60 * 1000 ;
  }

  static reversOffsetTimestampWithZone(timestamp: number, timezoneId: string) {
    const n = new Date(timestamp).getTimezoneOffset();
    const zoneOffset = moment.tz.zone(timezoneId).utcOffset(timestamp);
    return timestamp - (zoneOffset - n) * 60 * 1000 ;
  }

  static getDateTimeFromTimestamp(timezoneId: string, timestamp: number) {
    return moment.tz(timestamp, timezoneId).format('YYYY-MM-DD HH:mm');
  }

  static getDateFromTimestamp(timezoneId: string, timestamp: number) {
    return moment.tz(timestamp, timezoneId).format('YYYY-MM-DD');
  }

  static formatLocalDateTime(timestamp: number | string) {
    return moment(timestamp).format('YYYY-MM-DD HH:mm');
  }

  static formatLocalDate(timestamp: number) {
    return moment(timestamp).format('YYYY-MM-DD');
  }

  static formatUTCDate(timestamp: number) {
    return moment(timestamp).utc().format('YYYY-MM-DD');
  }

  static formatPersianLocalDate(timestamp: number) {
    return momentJ(timestamp).format('jYYYY/jM/jD');
  }

  static formatPersianLocalDateTime(timestamp: number) {
    return momentJ(timestamp).format('jYYYY/jMM/jDD  HH:mm');
  }

  static getDate(timezoneId: string, timestamp: number, format: string) {
    return moment.tz(timestamp, timezoneId).format(format);
  }

  static isWeekend(timestamp: number) {
    const day = new Date(timestamp).getDay();
    return (day === 0 || day === 6);
  }

  static generateDatesBetween(dr: DateRange) {
    const start = moment(dr.startDate).startOf('day');
    const end = moment(dr.endDate).endOf('day');
    const diff = end.diff(start, 'days');
    const range = (startRange, endRange) => {
      const length = endRange - startRange;
      return Array.from({ length }, (_, i) => startRange + i);
    };
    return range(1, diff + 1).map(i => moment(dr.startDate).add(i, 'days').startOf('day').toDate());
  }

  static sameDay(dt: Date, timestamp: number) {
    const first = moment(dt).startOf('day').toDate();
    const second = moment(timestamp).startOf('day').toDate();
    return first.getFullYear() === second.getFullYear() &&
      first.getMonth() === second.getMonth() &&
      first.getDay() === second.getDay();
  }

  static getISODateStringZoneId = (date: Date | null, zoneId: string) => {
    if (date) {
      date.setSeconds(0, 0);
      const dt = moment(date);
      const zoneOffset = dt.tz(zoneId).format('Z');
      return `${moment(date).format('YYYY-MM-DDTHH:mm:ss')}${zoneOffset}`;
    }
    return '';
  };

  static  getLocalDateIsoStringToShow(isoDate: string)  {
    if (isoDate) {
      const timestamp = moment(isoDate).toDate().getTime();
      const n = new Date(timestamp).getTimezoneOffset();
      const zoneOffset = moment.parseZone(isoDate).utcOffset();
      const dt = timestamp + (zoneOffset + n) * 60 * 1000;
      return moment(dt).toISOString();
    }
    return '';
  }

  static  isoDateStringFormatter(isoDate: string)  {
    const endIndex =(isoDate.indexOf('+')) ? isoDate.indexOf('+') : isoDate.indexOf('-');
    return isoDate.replace('T', ' ').slice(0, 16) + ' ' + isoDate.slice(endIndex);
  }

  static getTimeDiffFromDateStrings(first: string, second: string) {
    return moment(second).diff(first, 'days', true).toFixed(2);
  }
}

import {Component, Input} from '@angular/core';
import {DocumentService} from '../../../documents/documents.service';

@Component({
  selector: 'app-img-viewer',
  template: `
      <img [src]="src"
           [alt]="_alt"
           [ngStyle]="{
           width: _width,
           height: _height
           }"
           [ngClass]= "_cssClasses"/>
  `
})
export class ImageViewerComponent {
  src: any;
  _width: any;
  _height: any;
  _alt: string;
  _cssClasses: string;
  constructor(private _documentsService: DocumentService) { }

  @Input() set documentId(value: string) {
    this.src = '/assets/icons/main-logo/temp.jpg';
    if (value) {
      this._documentsService.download(value).subscribe(b => {
        this.src = URL.createObjectURL(b.body);
      });
    }
  }

  @Input() set width(value: any) {
    this._width = value;
  }

  @Input() set class(value: string) {
    this._cssClasses = value;
  }

  @Input() set height(value: any) {
    this._height = value;
  }

  @Input() set alt(value: string) {
    this._alt = value;
  }
}

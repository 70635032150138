import { Injectable } from '@angular/core';
import { HttpWrapper } from '../shared';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(private _httpWrapper: HttpWrapper) { }

  addCounter(url: string) {
    const t = moment();
    return this._httpWrapper.post('analytics/counter', {
      url: url,
      isoDate: t.toISOString(true),
      timestamp: t.unix() * 1000,
    });
  }
}

import { Component, EventEmitter, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { TrucksService, Truck, OrganisationIdColour, GPSTrackerCoordinate } from '../trucks.service';
import { OpenStreetService, MapMarker } from 'src/app/transportation/openstreet/open-street.service';
import {
  Organisation,
  OrganisationService,
} from '../../../organisation/organisation.service';
import { LPGDistData } from 'src/app/lpg-dist/lpg-dist.service';
import { forkJoin } from 'rxjs';
import { MapToolbarComponent } from 'src/app/transportation/trucks/map-toolbar/map-toolbar.component';
import { DateTimeHelpers } from 'src/app/shared/helpers/date-time.helpers';
import { Router } from '@angular/router';
import { GeoCoordinate } from '../../models/GeoCoordinate';
import {
  PgSubTitleComponent,
  PgTitleComponent,
  PgToolbarComponent,
} from '../../../shared/components/title-bar/title-bar.component';
import { DateRange } from 'src/app/shared/components/date-range.component';
import * as momentJ from 'moment-jalaali';
import * as _ from 'lodash';
import * as HighCharts from 'highcharts';


@Component({
  templateUrl: 'trucks.component.html',
  providers: [OpenStreetService],
})
export class TrucksComponent
  implements OnInit, PgTitleComponent, PgSubTitleComponent, PgToolbarComponent {


  title = new EventEmitter<string>();
  subTitle = new EventEmitter<string>();
  toolbarComponentType = MapToolbarComponent;
  toolbarComponent: MapToolbarComponent;

  lastUpdated: Date;
  _lastLoadingsCache: {
    [key: string]: LPGDistData;
  } = {};
  trucks: Truck[];
  trucksInfo: any[];
  selectedTruckDistanceTraveled: number;
  _numberOfUnavailableTrucks: number;
  _numberOfAvailableTrucks: number;
  showMissingDataWarning: boolean;
  showSearchPanel = false;
  now = new Date().getTime();
  _organisationsCache: {
    [key: string]: Organisation;
  } = {};
  _selectedOrganisationId: string;
  organisationsColours: OrganisationIdColour[];
  _timeCode: number;
  _colorCodeByOrganisation: boolean;
  _showOutOfReachTrucks = false;
  _showAvailbaleTrucks = false;
  coordinates: GeoCoordinate[];
  selectedTruckTotalDistanceTraveled: number;
  selectedTruckTotalDistanceTraveledDaily: number;

  availableTruckSubject: Subject<number> = new Subject<number>();
  unAvailableTruckSubject: Subject<number> = new Subject<number>();
  selectedTruckSuject: Subject<Truck> = new Subject<Truck>();
  _dateRange: DateRange;




  _selectedTruck: Truck;
  get selectedTruck() {
    return this._selectedTruck;
  }
  set selectedTruck(value: Truck) {
    this._selectedTruck = value;
    if (this._selectedTruck) {
      this.selectedTruckSuject.next(this._selectedTruck);
    }
  }

  get selectedOrganisationId() {
    return this._selectedOrganisationId;
  }
  set selectedOrganisationId(value: string) {
    this._selectedOrganisationId = value;
    this.update();
  }

  get numberOfAvailableTrucks() {
    return this._numberOfAvailableTrucks;
  }
  set numberOfAvailableTrucks(value: number) {
    this._numberOfAvailableTrucks = value;
    this.availableTruckSubject.next(this._numberOfAvailableTrucks);
  }

  get numberOfUnavailableTrucks() {
    return this._numberOfUnavailableTrucks;
  }
  set numberOfUnavailableTrucks(value: number) {
    this._numberOfUnavailableTrucks = value;
    this.unAvailableTruckSubject.next(this._numberOfUnavailableTrucks);
  }

  colorsRotation = [
    '#0073b7',
    '#00a65a',
    '#dd4b39',
    '#F012BE',
    '#39CCCC',
    '#f39c12',
    '#605ca8',
    '#001F3F',
  ];

  get colorCodeByOrganisation() {
    return this._colorCodeByOrganisation;
  }
  set colorCodeByOrganisation(value: boolean) {
    this._colorCodeByOrganisation = value;
    this.update();
  }

  get colorCodeByTimestamp() {
    return this._timeCode;
  }

  set colorCodeByTimestamp(value: number) {
    this._timeCode = Number(value);
    this.update();
  }

  set dateRange(value: DateRange) {
    this._dateRange = value;
    this.updatePlots();
    this._trucksService
    .getLatestUpdate(value.endDate.getTime())
    .subscribe((a) => {
      this.trucks = a.filter(
        (t) =>
          t.lastLocation &&
          t.lastLocation.location &&
          t.lastLocation.location.latitude !== 0 &&
          t.lastLocation.location.latitude !== -1 &&
          t.active !== false
      );
      if(this.selectedTruck) {
        this.selectedTruck = this.trucks
        .find(truck => truck.formattedRegistrationNumber === this.selectedTruck.formattedRegistrationNumber);
      }
      this.coloring();
      this.update();
    });
  }

  get selectedTruckImei(): string {
    if (this.selectedTruck) {
      return this.selectedTruck.tracker.associatedObject.imei;
    }
    return '';
  }

  constructor(
    private _trucksService: TrucksService,
    private _mapProviderService: OpenStreetService,
    private _organisationService: OrganisationService,
    private _router: Router
  ) {
    _mapProviderService.markerSelected$.subscribe((mapMarker) => {
      const truck = mapMarker.id as Truck;
      if (truck) {
        this.showSearchPanel = true;
        this.selectTruck(truck);
      }
    });
  }

  ngOnInit(): any {
    this.getData();
  }

  getData() {
    forkJoin([
      this._organisationService.get(),
      this._trucksService.getLastLoadingOfTrucksHaveTrackers(),
      this._trucksService.get(),
    ]).subscribe((results) => {
      results[0].forEach((o) => {
        this._organisationsCache[o.name] = o;
      });
      results[1].forEach((loading) => {
        this._lastLoadingsCache[loading.truckNumber] = loading;
      });
      this.trucks = results[2].filter(
        (t) =>
          t.lastLocation &&
          t.lastLocation.location &&
          t.lastLocation.location.latitude !== 0 &&
          t.lastLocation.location.latitude !== -1 &&
          t.active !== false
      );
      this.coloring();
      this.update();
      this.fitMapBound();
      this.updateLastUpdatedTime();
      this.setToolbar();
    });
  }

  setToolbar() {
    this.title.emit('Fleet status');
    this.subTitle.emit('Trucks');
    this.toolbarComponent.organisationsColours = this.organisationsColours;
    this.toolbarComponent._organisationsCache = this._organisationsCache;
    this.toolbarComponent._numberOfAvailableTrucks =
      this.numberOfAvailableTrucks;
    this.toolbarComponent._numberOfUnavailableTrucks =
      this.numberOfUnavailableTrucks;
    this.toolbarComponent.trucks = this.trucks;
    this.toolbarComponent.filteredTruks = this.trucks;
    this.toolbarComponent.recenter.subscribe(() => {
      this.refresh();
    });
    this.toolbarComponent.selectedOrganisationId.subscribe((organisationId) => {
      this.selectedOrganisationId = organisationId;
    });
    this.toolbarComponent.zoomOut.subscribe(() => {
      this.fitMapBound();
    });
    this.toolbarComponent.showOutOfReachTruck.subscribe(() => {
      this.showOutOfReachTruck();
    });
    this.toolbarComponent.showAvailableTrucks.subscribe(() => {
      this.showAvailableTrucks();
    });
    this.toolbarComponent.colorCodeByOrganisation.subscribe((colorCode) => {
      this.colorCodeByOrganisation = colorCode;
    });
    this.toolbarComponent.colorCodeByTimestamp.subscribe((colorCode) => {
      this.colorCodeByTimestamp = colorCode;
    });
    this.toolbarComponent.dateRange.subscribe((date) => {
      this.dateRange = date;
    });
    this.toolbarComponent.clusterTrucks.subscribe(() => {
      this._mapProviderService.clusterMarkers(this.trucks.map(t => this.getMapMarker(t)));
    });
    this.toolbarComponent.selectedTruck.subscribe((truck) => {
      this.selectTruck(truck);
    });
    this.availableTruckSubject.subscribe((available) => {
      this.toolbarComponent._numberOfAvailableTrucks = available;
    });
    this.unAvailableTruckSubject.subscribe((unavailables) => {
      this.toolbarComponent._numberOfUnavailableTrucks = unavailables;
    });

    this.selectedTruckSuject.subscribe((truck) => {
      this.toolbarComponent._selectedTruck = truck;
      this.toolbarComponent.showReportLink = true;
    });
  }

  selectTruck(selectedTruck: Truck) {
    if (selectedTruck) {
      this.selectedTruck = selectedTruck;
      this.update();
      this.updatePlots();
    }
  }

  coloring() {
    this.organisationsColours = _.uniqBy(
      this.trucks.map((t) => t.organisationId.associatedObject),
      (e) => e
    ).map((o, index) => ({
      organisationId: o,
      colour: this.colorsRotation[index],
    }));
  }

  loadingTime(trucks: Truck[]) {
    const now = new Date().getTime();
    const hours = 3600 * 1000;
    const day = 24 * hours;
    const week = 7 * day;
    const mounth = 30 * day;
    if (this._timeCode === 1) {
      return trucks.filter(
        (b) =>
          now - this.findLastLoading(b.formattedRegistrationNumber).timestamp <
          week
      );
    } else if (this._timeCode === 2) {
      return trucks.filter(
        (b) =>
          now - this.findLastLoading(b.formattedRegistrationNumber).timestamp <
          mounth
      );
    } else if (this._timeCode === 3) {
      return trucks.filter(
        (b) =>
          now - this.findLastLoading(b.formattedRegistrationNumber).timestamp >
          mounth
      );
    } else {
      this._router.navigate(['/trucks']);
    }
  }

  fitMapBound() {
        const bounds: GeoCoordinate[] = [
      {
        latitude: 38.000000,
        longitude:  45.700000
      },
      {
        latitude: 26.00000,
        longitude: 60.200000
      }
    ];
    this._mapProviderService.fitMapBound(bounds);
  }

  refresh() {
    this.selectedTruck = null;
    this._trucksService.get().subscribe((trucks) => {
      this.trucks = trucks.filter(
        (t) =>
          t.lastLocation &&
          t.lastLocation.location &&
          t.lastLocation.location.latitude !== 0 &&
          t.lastLocation.location.latitude !== -1 &&
          t.active !== false
      );
      this.coloring();
      this.update();
      this.updateLastUpdatedTime();
    });
  }

  update() {
    let trucks = this.trucks;
    if (this.selectedTruck) {
      trucks = [this.selectedTruck];
    } else if (this.selectedOrganisationId) {
      trucks = this.trucks.filter(
        (t) => t.organisationId.associatedObject === this.selectedOrganisationId
      );
    }
    if (this._showOutOfReachTrucks) {
      trucks = trucks.filter((truck) =>
        this._trucksService.isPositionOutdated(truck)
      );
    }
    if (this._showAvailbaleTrucks) {
      trucks = trucks.filter(
        (truck) => !this._trucksService.isPositionOutdated(truck)
      );
    }
    if (this._timeCode) {
      trucks = this.loadingTime(trucks);
    }
    this.setMarkers(trucks);
    this.numberOfUnavailableTrucks = trucks.filter((truck) =>
      this._trucksService.isPositionOutdated(truck)
    ).length;
    this.numberOfAvailableTrucks = trucks.filter(
      (truck) => !this._trucksService.isPositionOutdated(truck)
    ).length;
    this.trucksInfo = this.initInfoTable(trucks);
  }

  updateLastUpdatedTime() {
    this.lastUpdated = new Date();
  }

  getMapMarker(truck: Truck): MapMarker {
    return {
      geoCoordinate: {
        latitude: truck.lastLocation.location
          ? truck.lastLocation.location.latitude
          : 0,
        longitude: truck.lastLocation.location
          ? truck.lastLocation.location.longitude
          : 0,
      },
      id: truck,
      rotation: truck.lastLocation.bearing,
      color: this.extractIconColor(truck),
      infoWindowContent: this.setInfowindo(truck),
    };
  }

  setMarkers(trucks: Truck[]) {
    this._mapProviderService.resetMarkers();
    if (trucks) {
      const markers: MapMarker[] = trucks.map(truck => this.getMapMarker(truck));
      if (trucks.length === 1) {
        const marker: MapMarker = {
          ...markers[0],
          popUpIsOpen: true,
        };
        this._mapProviderService.addOrUpdateMarkers([marker]);
      } else {
        this._mapProviderService.addOrUpdateMarkers(markers);
      }
    }
  }

  setInfowindo(truck: Truck) {
    const truckInfo = {
      organisation: this.formatOrganisation(
        truck.organisationId.associatedObject
      ),
      time: DateTimeHelpers.formatPersianLocalDateTime(truck.lastLocation.time),
      lastLoading: this.findLastLoading(truck.formattedRegistrationNumber),
      outDated: this.isPositionOutdated(truck),
      unreachableHoures: this.unreachableHoures(truck.lastLocation.time),
      reportStartTimeStamp: momentJ().add(-7, 'days').unix() * 1000,
      reportEndTimeStamp: Date.now(),
    };
    const infoWindowContent = `<div id="content">
      <table style="text-align: center; font-family: Lucida Grande, Lucida Sans Unicode, Arial, Helvetica, sans-serif;  border-width: 1px;">
        <tr>
          <td colspan="3">
            <h5>
              <b>
                <i dir="ltr">${truck.truckNumber[0]}</i>
                <i dir="ltr">${truck.truckNumber[3]}</i>
                <i dir="ltr">${truck.truckNumber[1]}</i>
                <i dir="ltr">ایران</i>
                <i dir="ltr">${truck.truckNumber[2]}</i>
              </b>
            </h5>
          </td>
        </tr>
        <tr><td>${truckInfo.organisation}</td><td></td><td>${
      truck.driver.associatedObject.name
    }</td></tr>
        <tr>
          <td  style="color: green;">${Math.round(
            truck.lastLocation.speed
          )}<sub>Km/h</sub>&nbsp
          </td><td>&#64;</td><td>${truckInfo.time}</td></tr>
        <tr><td  style="color: green;">${
          truckInfo.lastLoading.weight
        }<sub>Kg</sub>&nbsp
            </td><td>&#64;</td><td>${truckInfo.lastLoading.date}</td></tr>
        <tr><td>${truckInfo.lastLoading.source}&nbsp </td>
            <td  style="font-size: 20px;"><b>&#8594</b></td>
            <td>&nbsp${truckInfo.lastLoading.destination}</td></tr>`;
    if (truckInfo.outDated) {
      return (
        infoWindowContent +
        `<tr>
                <td colspan="4" style="color: #FFA500;">
                <i>کشنده ${truckInfo.unreachableHoures.days} روز و ${truckInfo.unreachableHoures.hours} ساعت خارج از دسترس<i>
                </td>
              </tr>
            </table>
          </div>`
      );
    } else {
      return (
        infoWindowContent +
        `</table>
          </div>`
      );
    }
  }

  updatePlots() {
    if (this.selectedTruck) {
      const startDate = (this._dateRange) ? this._dateRange.startDate.getTime() : new Date().getTime() - this._trucksService.historyHours;
      const endDate = (this._dateRange) ? this._dateRange.endDate.getTime() : new Date().getTime();
      this._trucksService
        .getSpeedHistoryForTruckTimestamp(
          this.selectedTruck.tracker.associatedObject.imei,
          startDate,
          endDate)
        .subscribe(
        (history) => {
          this.showMissingDataWarning = false;
          const sorted = history.sort((a, b) => a.timestamp - b.timestamp);
          this._mapProviderService.updatePath(sorted);
          this.updateSpeedDashboard(sorted);
          this.updateAltitudeDashboard(sorted);
          this.selectedTruckTotalDistanceTraveled = this._trucksService.getDistanceTraveledInKillometers(sorted);
          // this.selectedTruckTotalDistanceTraveledDaily = this.selectedTruckTotalDistanceTraveled /
          //   (momentJ(this.dateRange.endDate).diff(this.dateRange.startDate, 'days' , true) + 1);
        },
        (error) => {
          if (error.status === 404) {
            this.showMissingDataWarning = true;
            this.selectedTruckDistanceTraveled = 0;
          }
        }
      );
    }
  }

  showOutOfReachTruck() {
    if (this._showAvailbaleTrucks) {
      this._showAvailbaleTrucks = false;
    }
    this._showOutOfReachTrucks = !this._showOutOfReachTrucks;
    this.update();
  }

  showAvailableTrucks() {
    if (this._showOutOfReachTrucks) {
      this._showOutOfReachTrucks = false;
    }
    this._showAvailbaleTrucks = !this._showAvailbaleTrucks;
    this.update();
  }

  extractIconColor(truck: Truck): string {
    if (this.colorCodeByOrganisation) {
      return this.organisationsColours.find(
        (o) => o.organisationId === truck.organisationId.associatedObject
      ).colour;
    } else if (this._timeCode) {
      return this.timeColoring(truck);
    } else if (this._trucksService.isPositionOutdated(truck)) {
      return '#EF9B2C';
    } else if (truck.lastLocation.speed > 90) {
      return '#009f55';
    } else {
      return '#009f55';
    }
  }

  timeColoring(truck: Truck) {
    const now = new Date().getTime() - truck.lastLocation.time;
    const hours = 3600 * 1000;
    const day = 24 * hours;
    if (now < hours * 5) {
      return '#357a38';
    } else if (now < day) {
      return '#449d48';
    } else if (now < day * 3) {
      return '#2196f3';
    } else if (now < day * 7) {
      return '#ffc107';
    } else if (now > day * 7) {
      return '#f54f36';
    }
  }

  truckValueFormatter(truck: Truck) {
    const driver =
      typeof truck.driver === 'undefined'
        ? 'N/A'
        : truck.driver.associatedObject.name;
    const tanker =
      typeof truck.tanker === 'undefined'
        ? 'N/A'
        : truck.tanker.associatedObject.number;
    return truck.formattedRegistrationNumber + '***' + tanker + '***' + driver;
  }

  formatOrganisation(organisationId: string) {
    if (organisationId) {
      const foundOrganisation = this._organisationsCache[organisationId];
      if (foundOrganisation) {
        return foundOrganisation.nameTranslations['fa'];
      } else {
        return '';
      }
    }
  }

  findLastLoading(truckNumber: string) {
    if (truckNumber) {
      const loading = this._lastLoadingsCache[truckNumber];
      if (loading) {
        return {
          timestamp: loading.loadTimestamp,
          weight: loading.loadingWeight.toLocaleString(),
          date: DateTimeHelpers.formatPersianLocalDate(loading.loadTimestamp),
          source: this.formatOrganisation(loading.sourceOrganisationId),
          destination: this.formatOrganisation(
            loading.destinationOrganisationId
          ),
        };
      } else {
        return {
          weight: null,
          date: null,
          source: null,
          destination: null,
        };
      }
    }
  }

  unreachableHoures(lastUpdated: number) {
    if (lastUpdated) {
      const now = new Date().getTime();
      let delta = Math.abs(now - lastUpdated) / 1000;
      const numberOfDayes = Math.floor(delta / 86400);
      delta -= numberOfDayes * 86400;
      const numberOfHours = Math.floor(delta / 3600) % 24;
      return {
        days: numberOfDayes,
        hours: numberOfHours,
      };
    }
  }

  isPositionOutdated(truck: Truck) {
    return this._trucksService.isPositionOutdated(truck);
  }

  updateSpeedDashboard(data: GPSTrackerCoordinate[]) {
    const series = data.sort((t1, t2) => t1.timestamp - t2.timestamp)
      .map(t => [t.timestamp, t.speed]);
    HighCharts.chart('speedContainer', {
      title: {
        text: this.selectedTruck.formattedRegistrationNumber
      },
      chart: { type: 'column' },
      xAxis: {
        labels: {
          formatter() {
            const temp: any = this;
            if (temp.dateTimeLabelFormat === '%H:%M:%S') {
              return momentJ(temp.value).format('HH:mm:ss');
            } else if (temp.dateTimeLabelFormat === '%H:%M') {
              return momentJ(temp.value).format('HH:mm');
            } else if (temp.dateTimeLabelFormat === '%e. %b') {
              return momentJ(temp.value).format('jD jMMM');
            } else if (temp.dateTimeLabelFormat === '%b \'%y') {
              return momentJ(temp.value).format('jMMM jYYYY');
            } else if (temp.dateTimeLabelFormat === '%Y') {
              return momentJ(temp.value).format('jYYYY');
            } else if (temp.dateTimeLabelFormat === '%H:%M:%S.%L') {
              return momentJ(temp.value).format('HH:mm:ss');
            } else if (Number(temp.value)) {
              return momentJ(temp.value).format('jMM-jDD HH:mm');
            } else {
              console.log(`missing formatter for ${temp.dateTimeLabelFormat} and value: ${this.value}`);
            }

          }
        }
      },
      yAxis: {
        title: {
          text: 'کیلومتر بر ساعت'
        }
      },
      series: [{
        name: 'سرعت',
        type: 'column',
        data: series
      }],
      tooltip: {
        formatter() {
          return `${momentJ(this.x).format('jYYYY-jMM-jDD HH:mm')}<br>سرعت: ${Math.round(this.y)}`;
        }
      }
    });
  }

  updateAltitudeDashboard(data: GPSTrackerCoordinate[]) {
    const series = data.sort((t1, t2) => t1.timestamp - t2.timestamp)
      .map(t => [t.timestamp, t.altitude]);
    HighCharts.chart('altitudeContainer', {
      title: {
        text: this.selectedTruck.formattedRegistrationNumber
      },
      xAxis: {
        labels: {
          formatter() {
            const temp: any = this;
            if (temp.dateTimeLabelFormat === '%H:%M:%S') {
              return momentJ(temp.value).format('HH:mm:ss');
            } else if (temp.dateTimeLabelFormat === '%H:%M') {
              return momentJ(temp.value).format('HH:mm');
            } else if (temp.dateTimeLabelFormat === '%e. %b') {
              return momentJ(temp.value).format('jD jMMM');
            } else if (temp.dateTimeLabelFormat === '%b \'%y') {
              return momentJ(temp.value).format('jMMM jYYYY');
            } else if (temp.dateTimeLabelFormat === '%Y') {
              return momentJ(temp.value).format('jYYYY');
            } else if (temp.dateTimeLabelFormat === '%H:%M:%S.%L') {
              return momentJ(temp.value).format('HH:mm:ss');
            } else if (Number(temp.value)) {
              return momentJ(temp.value).format('jMM-jDD HH:mm');
            } else {
              console.log(`missing formatter for ${temp.dateTimeLabelFormat} and value: ${this.value}`);
            }
          }

        }
      },
      yAxis: {
        title: {
          text: 'ارتفاع از سطح دریا'
        }
      },
      series: [{
        type: 'column',
        name: 'ارتفاع',
        data: series
      }],
      tooltip: {
        formatter() {
          return `${momentJ(this.x).format('jYYYY-jMM-jDD HH:mm')}<br>ارتفاع به متر: ${Math.round(this.y)}`;
        }
      }
    });
  }

  initInfoTable(trucks: Truck[]) {
    return trucks.map(truck => ({
      truckNumber: truck.formattedRegistrationNumber,
      organisation: this.formatOrganisation(truck.organisationId.associatedObject),
      time: DateTimeHelpers.formatPersianLocalDateTime(truck.lastLocation.time),
      speed: Math.round(truck.lastLocation.speed),
      lastLoading: this.findLastLoading(truck.formattedRegistrationNumber),
      outDated: this.isPositionOutdated(truck),
      unreachableHoures: this.unreachableHoures(truck.lastLocation.time),
      reportStartTimeStamp: momentJ().add(-7, 'days').unix() * 1000,
      reportEndTimeStamp: Date.now(),
    }));
  }
}

import { Routes } from '@angular/router';

import { homeRoutes } from './home/home.routes';
import { transportationRoutes } from './transportation/transportation.routes';
import { lpgDistRoutes } from './lpg-dist';
import { phoneBookRoutes } from './phone-book/phone-book.routes';
import { contractRoutes } from './contract/contract.routes';
import { securityRoutes } from './security/security-routing.module';
import { marketRoutes } from './market/market.routes';
import { handlerRoutes } from './shared/handler-error/handler-error.routes';
import { correspondenceRoutes } from './correspondence/correspondence.routes';

export const routes: Routes = [
  ...homeRoutes,
  ...transportationRoutes,
  ...lpgDistRoutes,
  ...phoneBookRoutes,
  ...contractRoutes,
  ...securityRoutes,
  ...marketRoutes,
  ...handlerRoutes,
  ...correspondenceRoutes
];

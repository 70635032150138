<div class="row">
  <div class="col-md-6">
    <form class="form-horizontal" #auditForm=ngForm>

      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Audit</h3>
        </div>
        <div class="card-body">
          <div class="form-group row">
            <label for="selectedVessel"
              class="col-form-label col-md-2 ">Vessel</label>
            <div class="col-md-10">
              <app-auto-complete
                name="selectedVessel"
                id="selectedVessel"
                [formatter]="vesselsFormatter"
                [(ngModel)]="selectedVessel"
                [items]="vessels"
                required>
              </app-auto-complete>
            </div>
          </div>
          <div class="form-group row">
            <label for="selectedLocation"
              class="col-form-label col-md-2 ">Location</label>
            <div class="col-md-10">
              <app-organisation-selector
                id="selectedLocation"
                class="pg-component"
                [(organisation)]="selectedLocation"
                [organisationLanguage]="'en'"
                name="selectedLocation"
                [organisations]="locations"
                [disabled]="!selectedVessel"
                required>
              </app-organisation-selector>
            </div>
          </div>
          <div class="form-group row">
            <label for="auditDate" class="col-form-label col-md-2 ">Date</label>
            <div class="col-md-10">
              <app-date-time-zone
                [disabled]="!selectedLocation"
                [(ngModel)]="auditDate"
                [maxDate]="maxAuditReportDate"
                id="auditDate"
                name="auditDate"
                required />
            </div>
          </div>
          <div class="form-group row">
            <label for="overallRating" class="col-form-label col-md-2 ">Overall
              Rating</label>
            <div class="col-md-10">
              <input
                type="number"
                min="0"
                max="100"
                class="form-control"
                id="overallRating"
                name="overallRating"
                [disabled]="!(auditDate?.timestamp)"
                [(ngModel)]="auditReport.overallRating">
            </div>
          </div>
          <div class="form-group row">
            <label for="reportFile"
              class="col-form-label col-md-2 ">Report</label>
            <div class="col-md-10">
              <app-chunk-file-uploader
                id="reportFile"
                [disabled]="(auditReport.overallRating === 0)"
                (filesChanged)="documentFileAdded($event)"
                required />
            </div>
          </div>
          <div class="form-group">
            <label for="newVessel" class="col-form-label col-md-4"></label>
            <div class="col-md-8">
              <input type="submit" id="newVessel" class="btn btn-primary"
                [disabled]="!auditForm.valid || !(auditDate?.timeZone) || !(auditDate?.timestamp) || !uploadedDocument"
                value="Save"
                (click)="save()">
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="col-md-6" *ngIf="uploadedDocument">
    <app-pdf-viewer [fileId]="uploadedDocument?.id" />
  </div>
</div>

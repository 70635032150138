import { Pipe, PipeTransform } from '@angular/core';
import { GeoCoordinateDMS } from '../../transportation/models/GeoCoordinate';

@Pipe({
  name: 'geoDms'
})
export class GeoCoordinateDmsPipe implements PipeTransform {

  transform(value: any): any {
    if (value) {
      const val = value as GeoCoordinateDMS;
      return `${val?.latitude?.degree}°${val?.latitude?.minute}'${val?.latitude?.direction?.toUpperCase()} ` +
        `${val?.longitude?.degree}°${val?.longitude?.minute}'${val?.longitude?.direction?.toUpperCase()}`;
    }
    return null;
  }

}

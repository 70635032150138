import { Component, Output, EventEmitter, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-file-uploader',
  template: `
  <div>
    <input type="file"
           id="inputFile"
           (change)="fileChangedListener($event)"
           [accept]="_accept"
           [required]="true"
           class="form-control"
           [disabled]=_disabled>
  </div>`,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => FileUploadComponent),
  }]
})
export class FileUploadComponent implements ControlValueAccessor {

  @Output() filesChanged: EventEmitter<FileList> = new EventEmitter<FileList>();
  @Output() valid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() focused = new EventEmitter<any>();

  currentFiles: FileList;
  _accept: string;
  _disabled: boolean;
  _valid = false;

  private _onChangeCallback: any;
  private _onTouchedCallback: any;


  @Input() set accept(value: string) {
    this._accept = value;
  }

  @Input() set disabled(value: boolean) {
    this._disabled = value;
  }

  fileChangedListener(fileEvent: any) {
    if (fileEvent.target.files) {
      this._valid = true;
      this.currentFiles = fileEvent.target.files;
      this.filesChanged.emit(this.currentFiles);
      this.callChangeCallback(this.currentFiles);
    }
  }

  registerOnChange(fn: any): void {
    this._onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouchedCallback = fn;
  }

  writeValue(obj: any): void {
    if (obj) {
      this.currentFiles = obj;
    }
  }

  private callChangeCallback(value: any) {
    if (this._onChangeCallback) {
      this._onChangeCallback(value);
    }
  }
}

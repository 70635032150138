<div class="row">
  <fieldset style="border: 0;" [disabled]="disableEdit()">
    <form class="row form-horizontal" #Crew="ngForm">
      <div class="col-md-6">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Crew Information</h3>
          </div>
          <div class="card-body">
            <div class="form-group row">
              <div
                class="col-md-4 d-flex justify-content-around align-self-center">
                <app-img-viewer
                  [width]="'160px'"
                  [height]="'180px'"
                  [documentId]="crew?.photoDocumentId"
                  [alt]="crew?.firstName"
                  [class]="'rounded-circle'" />
              </div>
              <div class="col-md-8">
                <div class="form-group row">
                  <label for="firstname" class="col-form-label col-md-4 ">First
                    Name</label>
                  <div class="col-md-8">
                    <input
                      type="text"
                      class="form-control"
                      name="firstname"
                      id="firstname"
                      [disabled]="edit"
                      [(ngModel)]="crew.firstName"
                      tabindex="1"
                      required />
                  </div>
                </div>
                <div class="form-group row">
                  <label for="lastname" class="col-form-label col-md-4 ">Last
                    Name</label>
                  <div class="col-md-8">
                    <input
                      type="text"
                      class="form-control"
                      name="lastname"
                      id="lastname"
                      [disabled]="edit"
                      [(ngModel)]="crew.lastName"
                      tabindex="2"
                      required />
                  </div>
                </div>
                <div class="form-group row">
                  <label for="middleName"
                    class="col-form-label col-md-4 ">Middle Name</label>
                  <div class="col-md-8">
                    <input
                      type="text"
                      class="form-control"
                      name="middleName"
                      id="middleName"
                      [disabled]="edit"
                      [(ngModel)]="crew.middleName"
                      tabindex="3" />
                  </div>
                </div>
                <div class="form-group row">
                  <label for="nationality"
                    class="col-form-label col-md-4 ">Nationality</label>
                  <div class="col-md-8">
                    <app-organisation-selector
                      id="nationality"
                      class="pg-component"
                      [(organisation)]="nationality"
                      [organisationLanguage]="'en'"
                      name="nationality"
                      [organisations]="nationalityItem"
                      [disabled]="disableEdit()"
                      tabindex="14"
                      required>
                    </app-organisation-selector>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="birthDate" class="col-form-label col-md-4 ">Birth
                    Date</label>
                  <div class="col-md-8">
                    <input
                      type="date"
                      id="birthDate"
                      name="birthDate"
                      [max]="maxDate"
                      [(ngModel)]="crew.birthDate"
                      tabindex="4"
                      class="form-control" />
                  </div>
                </div>
                <div class="form-group row">
                  <label for="contactNumber"
                    class="col-form-label col-md-4 ">Contact Number</label>
                  <div class="col-md-8">
                    <input
                      type="tel"
                      class="form-control"
                      name="contactNumber"
                      id="contactNumber"
                      [(ngModel)]="crew.contactNumber"
                      tabindex="15" />
                  </div>
                </div>
                <div class="form-group row">
                  <label for="photo"
                    class="col-form-label col-md-4 ">Photo</label>
                  <div class="col-md-8">
                    <app-chunk-file-uploader
                      [accept]="'.img,.jpg'"
                      id="photo"
                      (filesChanged)="photoUploaded($event)"
                      tabindex="14" />
                  </div>
                </div>
                <div class="form-group row">
                  <label for="cvDocumentId"
                    class="col-form-label col-md-4 ">CV</label>
                  <div class="col-md-8">
                    <app-chunk-file-uploader
                      [accept]="'.pdf'"
                      name="cvDocumentId"
                      id="cvDocumentId"
                      (filesChanged)="uploadCvFile($event)"
                      tabindex="16">
                    </app-chunk-file-uploader>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Passport</h3>
          </div>
          <div class="card-body">
            <div class="form-group row">
              <label for="Passport" class="col-form-label col-md-4 ">Passport
                No</label>
              <div class="col-md-8">
                <input
                  type="text"
                  class="form-control"
                  name="Passport"
                  id="Passport"
                  [(ngModel)]="crew.passportDetails.passportNumber"
                  (ngModelChange)="changePassportNumber()"
                  [disabled]="disableEdit()"
                  tabindex="10"
                  required />
              </div>
            </div>
            <div class="form-group row">
              <label for="passportIssueDate"
                class="col-form-label col-md-4 ">Passport Issue Date</label>
              <div class="col-md-8">
                <input
                  type="date"
                  id="passportIssueDate"
                  name="passportIssueDate"
                  [(ngModel)]="crew.passportDetails.passportIssueDate"
                  required
                  tabindex="11"
                  class="form-control" />
              </div>
            </div>
            <div class="form-group row">
              <label for="passExpire" class="col-form-label col-md-4 ">Passport
                Expiration Date</label>
              <div class="col-md-8">
                <input
                  type="date"
                  id="passExpire"
                  name="passExpire"
                  [min]="minDateExpiryDate"
                  [(ngModel)]="crew.passportDetails.passportExpirationDate"
                  required
                  tabindex="12"
                  class="form-control" />
              </div>
            </div>
            <div class="form-group row">
              <label for="passportFileId"
                class="col-form-label col-md-4 ">Upload Passport</label>
              <div class="col-md-8">
                <app-chunk-file-uploader
                  [accept]="'.pdf'"
                  name="passportFileId"
                  id="passportFileId"
                  (filesChanged)="uploadPassportFile($event)"
                  [disabled]="disableEdit()"
                  tabindex="13">
                </app-chunk-file-uploader>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Seaman</h3>
          </div>
          <div class="card-body">
            <div class="form-group row">
              <label for="seaManNumber" class="col-form-label col-md-4 ">Seaman
                Number</label>
              <div class="col-md-8">
                <input
                  type="text"
                  class="form-control"
                  name="seaManNumber"
                  id="seaManNumber"
                  [(ngModel)]="crew.seamanDetails.seamanNumber"
                  (ngModelChange)="changeSeamanNumber()"
                  tabindex="7"
                  required />
              </div>
            </div>
            <div class="form-group row">
              <label for="expire" class="col-form-label col-md-4 ">Seaman
                Expiration Date</label>
              <div class="col-md-8">
                <input
                  type="date"
                  id="expire"
                  name="expire"
                  [min]="minDateExpiryDate"
                  [(ngModel)]="crew.seamanDetails.seamanExpirationDate"
                  tabindex="8"
                  required
                  class="form-control" />
              </div>
            </div>
            <div class="form-group row">
              <label for="seaManFileId" class="col-form-label col-md-4 ">Seaman
                book</label>
              <div class="col-md-8">
                <app-chunk-file-uploader
                  [accept]="'.pdf'"
                  name="seaManFileId"
                  id="seaManFileId"
                  (filesChanged)="uploadSeaManFile($event)"
                  [disabled]="disableEdit()"
                  tabindex="9">
                </app-chunk-file-uploader>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="btn-group pull-right" role="group">
              <button type="button" id="showSummary"
                class="btn btn-outline-secondary btn-light  me-1"
                [routerLink]="['/vessels/crew-change/crew/profile/', crew.id]">Back
                to Profile
              </button>
              <button
                type="submit"
                class="btn btn-primary"
                id="save"
                [disabled]="!Crew.form.valid"
                (click)="save()">
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </fieldset>
  <div class="col-md-6" *ngIf="crew?.cvDocumentId">
    <app-pdf-viewer [fileId]="crew.cvDocumentId"></app-pdf-viewer>
  </div>
  <div class="col-md-6" *ngIf="crew?.passportDetails.passportFileId">
    <app-pdf-viewer
      [fileId]="crew?.passportDetails.passportFileId"></app-pdf-viewer>
  </div>
  <div class="col-md-6"
    *ngIf="crew.oldDetails?.passport && crew.oldDetails.passport.length > 0">
    <div class="card">
      <div class="card-header">
        Old Passport details
      </div>
      <div class="card-body ">
        <table class="table table-sm table-striped table-bordered">
          <thead>
            <tr>
              <th>Passport number</th>
              <th>Passport issue date</th>
              <th>Passport Expiration date</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let detail of crew.oldDetails.passport">
              <td>{{detail.passportNumber}}</td>
              <td>{{detail.passportIssueDate}}</td>
              <td>{{detail.passportExpirationDate}}</td>
              <td>
                <a (click)="downLoadDocumnet(detail.passportFileId)"
                  *ngIf="detail.passportFileId">
                  <i class="fa fa-download" aria-hidden="true"></i>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="col-md-6"
    *ngIf="crew.oldDetails?.seaman && crew.oldDetails.seaman.length > 0">
    <div class="card">
      <div class="card-header">
        Old Seaman details
      </div>
      <div class="card-body ">
        <table class="table table-sm table-striped table-bordered">
          <thead>
            <tr>
              <th>Seaman number</th>
              <th>Seaman Expiration date</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let detail of crew.oldDetails.seaman">
              <td>{{detail.seamanNumber}}</td>
              <td>{{detail.seamanExpirationDate}}</td>
              <td>
                <a (click)="downLoadDocumnet(detail.seamanFileId)"
                  *ngIf="detail.seamanFileId">
                  <i class="fa fa-download" aria-hidden="true"></i>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

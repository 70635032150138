import { Injectable } from '@angular/core';
import { HttpWrapper } from '../shared/services';
import { Observable } from 'rxjs';
import { DocumentReference } from '../documents/documents.service';

export interface CorrespondenceTemplate {
  title: string;
  pageSize: string;
  organisationId: string;
  fileId: string;
  providerUserId: string;
  providerUserName: string;
}

export interface Correspondence {
  title: string;
  createdFileId: string;
  signedFileId: string;
  templateFileId: string;
  relatedDocsReferences: number[];
  providerUserId: string;
  providerUserName: string;
  timestamp: number;
  organisationId: string;
  toId: string;
  ref: number;
  incoming: boolean;
  keywords: string;
  incomingReference: string;
  attachments: string[];
}

@Injectable()

export class CorrespondenceService {
  constructor(private _httpWrapper: HttpWrapper) { }

  addTemplate(template: CorrespondenceTemplate): Observable<CorrespondenceTemplate> {
    return this._httpWrapper.post('correspondence/template', template);
  }

  getAllTemplates(): Observable<CorrespondenceTemplate[]> {
    return this._httpWrapper.get(`correspondence/templates`);
  }

  getAllCorrespondenceForOrganisation(organisationId: string): Observable<Correspondence[]> {
    return this._httpWrapper.get(`correspondence/${organisationId}`);
  }

  getAllCorrespondences(): Observable<Correspondence[]> {
    return this._httpWrapper.get(`correspondence`);
  }

  getOneTemplate(fileId: string): Observable<CorrespondenceTemplate> {
    return this._httpWrapper.get(`correspondence/templates/${fileId}`);
  }

  getCorrespondence(organisationId: string, reference: number): Observable<Correspondence> {
    return this._httpWrapper.get(`correspondence/${organisationId}/${reference}`);
  }

  updateCorrespondence(correspondence: Correspondence): Observable<Correspondence> {
    return this._httpWrapper.put(`correspondence/update`, correspondence);
  }

  getUploadTmeplateGetRefrence(fileId: string): Observable<CorrespondenceTemplate> {
    return this._httpWrapper.get(`correspondence/templates/${fileId}`);
  }

  createNewCorrespondence(correspondence: Correspondence, fileList: FileList): Observable<Correspondence> {
    return this._httpWrapper.postMultiPart<Correspondence>('correspondence/file',
      this.addDataToCorrespondenceFile(correspondence, fileList));
  }

  saveIncoming(correspondence: Correspondence,): Observable<Correspondence> {
    return this._httpWrapper.post('correspondence/incoming', correspondence);
  }

  downloadBaseTemplate(organisationId: string, size: string) {
    return this._httpWrapper.download(`correspondence/template/${organisationId}/${size}`);
  }

  addNewTemplateFile(documentType: string, organisationId: string, size: string, fileList: FileList): Observable<DocumentReference> {
    return this._httpWrapper.postMultiPart<DocumentReference>('correspondence/template/file',
      this.addDataToTemplateFile(documentType, organisationId, size, fileList));
  }

  private addDataToTemplateFile(documentType: string, organisationId: string, size: string, fileList: FileList) {
    const formData = new FormData();
    formData.append('documentType', documentType);
    formData.append('organisationId', organisationId);
    formData.append('size', size);
    formData.append('file', fileList.item(0));
    return formData;
  }

  private addDataToCorrespondenceFile(correspondence: Correspondence, fileList: FileList) {
    const formData = new FormData();
    formData.append('title', correspondence.title);
    formData.append('templateFileId', correspondence.templateFileId);
    formData.append('relatedDocsReferences', correspondence.relatedDocsReferences.toString());
    formData.append('providerUserId', correspondence.providerUserId);
    formData.append('providerUserName', correspondence.providerUserName);
    formData.append('organisationId', correspondence.organisationId);
    formData.append('toId', correspondence.toId);
    formData.append('keywords', correspondence.keywords);
    formData.append('file', fileList.item(0));
    return formData;
  }

}

import { Injectable } from '@angular/core';
import { HttpWrapper } from '../../shared';
import {DataBaseAction} from '../../shared/services/database-action.service';

export interface AuditReport {
  vesselIMO: string;
  vesselName: string;
  isoDate: string;
  locationOrganisationId: string;
  surveyorId: string;
  surveyorName: string;
  documentId: string;
  overallRating: number;
  actions: DataBaseAction[];
}

@Injectable()
export class AuditsReportsService {

  constructor(private _httpWrapper: HttpWrapper) { }

  get(vesselIMO: string) {
    return this._httpWrapper.get<AuditReport[]>(`vessels/audit/${vesselIMO}`);
  }

  add(auditReport: AuditReport) {
    return this._httpWrapper.post<AuditReport, AuditReport>(`vessels/audit`, auditReport);
  }
}

<div class="row" style="padding-top: 5px">
  <div class="col-md-12 overflow-auto">
    <table class="table table-sm table-striped table-bordered">
      <thead>
        <tr>
          <td>Date</td>
          <td>Overall rating</td>
          <td>Surveyor</td>
          <td>Location</td>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let report of auditReports">
          <tr>
            <td><i><a class="btn-link"
                  (click)="downloadReport(report)">{{report.isoDate}}</a></i></td>
            <td>{{report.overallRating}}%</td>
            <td>{{report.surveyorName}}</td>
            <td>{{getOrganisationName(report.locationOrganisationId).nameTranslations['en']}}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>

import {OnInit, Component, ViewChild, EventEmitter} from '@angular/core';
import { TrucksService, Truck, TankerData } from 'src/app/transportation/trucks/trucks.service';
import { KeycloakService } from 'src/app/authentication/keycloak.service';
import { Subject, forkJoin } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { Organisation, OrganisationService} from 'src/app/organisation/organisation.service';
import { DataTablesService } from 'src/app/shared/datatables/data-tables.service';
import * as _ from 'lodash';
import { DocumentService } from 'src/app/documents/documents.service';
import { PgMessagesService, MessageLevel } from 'src/app/shared/services/pg-messages.service';
import {saveAs} from 'file-saver';
import { Router } from '@angular/router';
import {PgTitleComponent} from '../../../shared/components/title-bar/title-bar.component';

@Component({
  templateUrl: 'trucks-list.component.html',
})
export class TrucksListComponent implements  OnInit, PgTitleComponent {
  @ViewChild(DataTableDirective, {static: true})
  dtElement: DataTableDirective;

  title = new EventEmitter<string>();



  trucks: Truck[];
  list: any[];

  isDataTablesInitialised = false;

  activeTrucks: any[];
  dtOptions = {};
  activeDtOptions = {};
  dtTrigger: Subject<any> = new Subject<any>();
  _organisationsCache: {
    [key: string]: Organisation;
  } = {};
  _tankerCache: {[key: string]: TankerData;
  } = {};
  today: number;

  constructor(private _trucksService: TrucksService,
              private _keycloakService: KeycloakService,
              private _organisationService: OrganisationService,
              private _documentsService: DocumentService,
              private _pgMessagesService: PgMessagesService,
              private _router: Router) { }

  ngOnInit(): void {
    this.today = new Date().getTime();
    this.title.emit('Trucks');
    const currentComponent = this;
    const buttons = [{
      text: 'کشنده جدید',
        action: () => currentComponent.addRecord()
      }
    ];
    this.dtOptions = DataTablesService.getOptions(buttons);
    this.activeDtOptions = DataTablesService.getOptions();
    this.getData();
  }

  getData() {
    forkJoin([
      this._organisationService.get(),
      this._trucksService.getForLpgDist(),
      this._trucksService.getAllTankers()
    ]).subscribe(result => {
      result[0].forEach(o => {
        this._organisationsCache[o.name] = o;
      });
      this.trucks = result[1];
      result[2].forEach(t => {
        this._tankerCache[t.number + t.owner] = t;
      });
      this.list = this.mapTrucks();
      this.reportActivatedTrcuks();
      this.initialiseDataTable();
    });
  }

  initialiseDataTable() {
    if (!this.isDataTablesInitialised) {
      this.dtTrigger.next(false);
    } else {
      this.dtElement.dtInstance.then((dtInstance) => {
        dtInstance.destroy();
        this.dtTrigger.next(false);
      });
    }
    this.isDataTablesInitialised = true;
  }

  hasRole(roleName: string) {
    return this._keycloakService.hasRole(roleName);
  }

  formatOrganisation(organisationId: string) {
    const foundOrganisation = this._organisationsCache[organisationId];
    if (foundOrganisation) {
      return foundOrganisation.nameTranslations['fa'];
    } else {
      return '';
    }
  }

  editRecord(truck: Truck, field: string) {
    this._router.navigate(['/trucks/edit', truck.formattedRegistrationNumber, field]);
  }

  addRecord() {
    this._router.navigate(['/trucks/new']);
  }

  reportActivatedTrcuks() {
    const grouped = _.groupBy(this.trucks, (item) => item.organisationId.associatedObject);
    this.activeTrucks = _(grouped).map((items, key) => (
      {
        transporterOrganisationName: key,
        countOfTrucks: _.size(items),
        countOfActiveTrucks: _.size(_.filter(items, i => i.active))
      }
    )).value();
  }

  downloadFile(fileId: string, prefix: string) {
    this._documentsService.download(fileId).subscribe(b => {
      saveAs(b.body, `${prefix}.pdf`);
    }, () => {
      this._pgMessagesService.publishMessage({
        message: 'Could not download this file.',
        level: MessageLevel.Error,
        topic: 'Voyage document'
      });
    });

  }

  findTanker(truck: Truck) {
    if (truck.tanker !== undefined) {
      const t = this._tankerCache[truck.tanker.associatedObject.number + truck.tanker.associatedObject.owner];
      if (t) {
        return {
          number: truck.tanker.associatedObject.number,
          licenseExpireDate: t.licenseExpireDate < this.today
        };
      }
    }
  }

  mapTrucks() {
    return this.trucks.map(truck => {
      const driverName = (truck.driver !== undefined) ? truck.driver.associatedObject.name : null;
      const selectedImei = (truck.tracker) ? truck.tracker.associatedObject.imei : null;
      return {
        formattedRegistrationNumber: truck.formattedRegistrationNumber,
        tanker: this.findTanker(truck) ,
        driver: driverName,
        imei: selectedImei,
        organisation: (truck.organisationId) ? this.formatOrganisation(truck.organisationId.associatedObject) : null,
        fileId: truck.fileId,
        active: truck.active
      };
    });
  }

}

import {Component, EventEmitter, OnInit, ViewChild} from '@angular/core';
import {forkJoin, Subject} from 'rxjs';
import {DataTablesService} from '../../../shared/datatables/data-tables.service';
import {DataTableDirective} from 'angular-datatables';
import {ActivatedRoute} from '@angular/router';
import {Vessel, VesselsService, Voyage, VoyageLength} from '../vessels.service';
import * as moment from 'moment';
import {KeycloakService} from 'src/app/authentication/keycloak.service';
import {DateRange} from 'src/app/shared/components';
import {Organisation, OrganisationService} from 'src/app/organisation/organisation.service';
import {DateTimeHelpers} from 'src/app/shared/helpers/date-time.helpers';
import * as _ from 'lodash';
import {saveAs} from 'file-saver';
import { MessageLevel, PgMessagesService } from '../../../shared/services/pg-messages.service';
import {
  PgDateRangeTitleComponent,
  PgDateRangeTitleEnum, PgTitleComponent
} from '../../../shared/components/title-bar/title-bar.component';

@Component({
  selector: 'app-voyages',
  templateUrl: './voyages.component.html'
})
export class VoyagesComponent implements OnInit, PgTitleComponent, PgDateRangeTitleComponent {
  @ViewChild(DataTableDirective, {static: true})
  dtElement: DataTableDirective;

  title: EventEmitter<string> = new EventEmitter();
  dateRangeSubTitle = new EventEmitter<[DateRange, PgDateRangeTitleEnum]>();
  voyages: Voyage[];
  voyagesLength: VoyageLength[];
  vessels: Vessel[];

  dateRange: DateRange;

  isDataTablesInitialised = false;

  dtOptions: any;
  dtTrigger: Subject<any> = new Subject<any>();

  voyageLengthFromLoadingToDischarge = VesselsService.voyageLengthFromLoadingToDischarge;
  calculateLaytime = VesselsService.calculateLaytime;
  calculateBerthTime = VesselsService.calculateBerthTime;
  organisationsMap: Map<string, Organisation>;

  constructor(private _vesselsService: VesselsService,
              private _organisationService: OrganisationService,
              private _activatedRoute: ActivatedRoute,
              private _pgMessagesService: PgMessagesService,
              private _keycloakService: KeycloakService) {
    this.dateRange = {
      startDate: moment().subtract(12, 'months').toDate(),
      endDate: moment().toDate()
    };
  }

  hasRole(role: string) {
    return this._keycloakService.hasRole(role);
  }

  ngOnInit() {
    const component = this;
    if (this.hasRole('vessels-voyages-editor')) {
      this.dtOptions = DataTablesService.getOptionsEn([{
        text: 'Download reports',
        action: () => component.downloadFile()
      }], [[1, 'asc'], [2, 'desc']]);
    } else {
      this.dtOptions = DataTablesService.getOptionsEn([{
        text: 'Download reports',
        action: () => component.downloadFile()
      }], [[1, 'asc'], [2, 'desc']]);
    }
    this.getParameters();
  }

  getParameters() {
    this._activatedRoute.queryParams.subscribe(p => {
      const startDate = +p['startTime'];
      const endDate = +p['endTime'];
      this.dateRange = {
        startDate: new Date(startDate),
        endDate: new Date(endDate)
      };

      this.title.emit('Voyages');
      this.dateRangeSubTitle.emit([this.dateRange, PgDateRangeTitleEnum.defaultTitle]);
      this.changeReportPeriod();
    });
  }

  findOrganisation(organisationId: string) {
    const org = this.organisationsMap.get(organisationId);
    if (typeof org !== 'undefined') {
      return org.nameTranslations['en'];
    } else {
      return '';
    }
  }

  formatNorDates(voyage: Voyage, norType: string) {
    const nors = voyage.nors.filter(n => n.norType === norType);
    if (nors && nors.length > 0 && this.organisationsMap) {
      const dt = nors.map(v => v.timestamp).sort((l1, l2) => l2 - l1)[0];
      const timezone = this.organisationsMap.get(voyage.nors.filter(v => v.timestamp === dt)[0].portOrganisationId)
      .timezone;
      return  DateTimeHelpers.getDateTimeFromTimestamp(timezone, dt);
    } else {
      return '';
    }
  }

  formatProductDates(voyage: Voyage) {
    if (voyage.billOfLadings && voyage.billOfLadings.length > 0) {
      const dt = voyage.billOfLadings.map(b => b.date).sort((l1, l2) => l2 - l1)[0];
      const timezone = this.organisationsMap.get(voyage.billOfLadings.filter(v => v.date === dt)[0].loadingPortOrganisationId)
      .timezone;
      return  DateTimeHelpers.getDateTimeFromTimestamp(timezone, dt);
    } else {
      return '';
    }
  }

  formatProductWeights(voyage: Voyage) {
    if (voyage.billOfLadings) {
      const result = voyage.billOfLadings.filter(bill => bill.revise !== true)
      .map(b => b.products.map(p => p.grossWeight).reduce((x, y) => x + y , 0))
        .reduce((m, n) => m + n , 0);
      return Math.round(result * 1000) / 1000;
    }
  }

  formatReviseWeights(voyage: Voyage) {
    if (voyage.billOfLadings) {
      const result =  voyage.billOfLadings.filter(bill => bill.revise === true)
      .map(b => b.products.map(p => p.grossWeight).reduce((x, y) => x + y , 0))
        .reduce((m, n) => m + n , 0);
      return Math.round(result * 1000) / 1000;
    }
  }

  formatDischargeDates(voyage: Voyage) {
    if (voyage.discharges && voyage.discharges.length > 0) {
      const dt = voyage.discharges.map(b => b.date).sort((l1, l2) => l2 - l1)[0];
      const timezone = this.organisationsMap.get(voyage.discharges.filter(v => v.date === dt)[0].portOrganisationId)
        .timezone;
      return  DateTimeHelpers.getDateFromTimestamp(timezone, dt);
    } else {
      return '';
    }
  }

  selectedDateRangeChanged(dateRange: DateRange) {
    this.dateRange.startDate = dateRange.startDate;
    this.dateRange.endDate = dateRange.endDate;
  }

  changeReportPeriod() {
    forkJoin([
      this._vesselsService.getVoyagesBetween(this.dateRange),
      this._vesselsService.getVessels(),
      this._organisationService.get()
    ]).subscribe(results => {
      this.vessels = results[1];
      this.voyagesLength = this._vesselsService.mapToVoyageLength(results[0].filter(v => v.nors.length > 0));
      this.organisationsMap = new Map(results[2].map(o => [o.name, o]));
      this.datatableInit();
    });
  }

  datatableInit() {
    if (!this.isDataTablesInitialised) {
      this.dtTrigger.next(false);
    } else {
      this.dtElement.dtInstance.then((dtInstance) => {
        dtInstance.destroy();
        this.dtTrigger.next(false);
      });
    }
    this.isDataTablesInitialised = true;
  }

  downloadFile() {
    this._vesselsService.downloadVoyageActivityExcel(this.dateRange.startDate.getTime(),
      this.dateRange.endDate.getTime()).subscribe(b => {
        saveAs(b.body, `${DateTimeHelpers.formatLocalDate(this.dateRange.startDate.getTime())}-to-` +
          `${DateTimeHelpers.formatLocalDate(this.dateRange.endDate.getTime())}-voyages-activity-report.xlsx`);
    }, () => {
        this._pgMessagesService.publishMessage({
          level: MessageLevel.Error,
          message: 'Could not download excel report',
          topic: 'Voyages report'
        });
    });
  }

  formatNorLocation(voyage: Voyage, norType: string) {
    if (voyage.nors) {
      const dischargeNors = voyage.nors.filter(n => n.norType === norType);
      if (dischargeNors) {
        return _.chain(dischargeNors.map(n => n.portOrganisationId.split('.')[0])
          .map(o => this.findOrganisation(o))).groupBy(i => i).map((items, k) => k).value().join(', ');
      }
    }
    return '';
  }

  formatNorPort(voyage: Voyage, norType: string) {
    if (voyage.nors) {
      const dischargeNors = voyage.nors.filter(n => n.norType === norType);
      if (dischargeNors) {
        return _.chain(dischargeNors)
          .map(o => this.findOrganisation(o.portOrganisationId))
          .groupBy(i => i).map((items, k) => k).value().join(', ');
      }
    }
    return '';
  }
}

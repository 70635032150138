import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, EventEmitter} from '@angular/core';
import { Correspondence, CorrespondenceService, CorrespondenceTemplate } from '../correspondence.service';
import { DocumentService } from 'src/app/documents/documents.service';
import { saveAs } from 'file-saver';
import { Organisation, OrganisationService } from 'src/app/organisation/organisation.service';
import { forkJoin } from 'rxjs';
import { KeycloakService, UserData } from 'src/app/authentication/keycloak.service';
import { PgMessagesService, MessageLevel } from 'src/app/shared/services/pg-messages.service';
import { DateRange } from 'src/app/shared/components';


@Component({
  selector: 'app-new-correspondence',
  templateUrl: './new-correspondence.component.html',
})
export class NewCorrespondenceComponent implements OnInit {

  template: CorrespondenceTemplate;
  fileId: string;
  mainFiles: FileList;
  signedFiles: FileList;
  attachment: FileList;
  newAttachments: boolean;


  organisations: Organisation[];
  organisation: Organisation;
  toOrganisation: Organisation;
  correspondence: Correspondence = {} as Correspondence;
  correspondences: Correspondence[] = [];
  user: UserData;
  edit: boolean;
  incoming: boolean;
  dateRange: DateRange;
  uploadedFileId: string;
  relatedDocs: Correspondence[] = [];
  relatedDoc: Correspondence;

  title = new EventEmitter<string>();



  constructor(private _activatedRoute: ActivatedRoute,
              private _conrrespondenceService: CorrespondenceService,
              private _documentsService: DocumentService,
              private _organisationService: OrganisationService,
              private _keycloakService: KeycloakService,
              private _messageService: PgMessagesService,
              private _router: Router) { }

  ngOnInit(): void {
    this.initCorrespondence();
  }

  initCorrespondence() {
    this._activatedRoute.paramMap.subscribe(params => {
      this.edit = params.get('mode') === 'edit';
      this.fileId = params.get('fileId');
      this.incoming = params.get('type') === 'true';

      const organisationId = params.get('organisationId');
      this.user = this._keycloakService.getUser();
      if(!this.edit && !this.incoming) {
        forkJoin([
          this._organisationService.get(),
          this._conrrespondenceService.getAllCorrespondenceForOrganisation(organisationId),
          this._conrrespondenceService.getOneTemplate(this.fileId)
        ]).subscribe(results => {
          this.organisations = results[0].filter(o => o.properties['correspondent'] === true);
          this.correspondences = results[1];
            this.title.emit('New Outgoing Correspondence');
            this.template = results[2];
            this.organisation = this.organisations.find(o => o.name === this.template.organisationId);
          });
        }
        if(!this.edit && this.incoming) {
          this.title.emit('New Incoming Correspondence');
          this._organisationService.get().subscribe(organisations => {
            this.organisations = organisations.filter(o => o.properties['correspondent'] === true);
          });
        }
        if (this.edit) {
          const reference = Number(params.get('reference'));
          forkJoin([
            this._organisationService.get(),
            this._conrrespondenceService.getAllCorrespondenceForOrganisation(organisationId),
            this._conrrespondenceService.getCorrespondence(organisationId, reference)
          ]).subscribe(results => {
            this.organisations = results[0].filter(o => o.properties['correspondent'] === true);
            this.correspondence = results[2];
            this.correspondences = results[1];
            this.relatedDocs = results[1].filter(r => this.correspondence.relatedDocsReferences.find(d => d === r.ref));
            this.title.emit(`${this.correspondence.title}`);
            this.uploadedFileId = (this.incoming) ? this.correspondence.createdFileId : this.correspondence.signedFileId;
            this.organisation = this.organisations.find(o => o.name === this.correspondence.organisationId);
            this.toOrganisation = this.organisations.find(o => o.name === this.correspondence.toId);
            this.dateRange = {
              startDate: new Date(this.correspondence.timestamp),
              endDate: new Date(this.correspondence.timestamp)
            };
          });
        }
      });
  }

  getCorrespondence(organisation: Organisation) {
    this.toOrganisation = organisation;
    if(!this.edit && this.incoming && organisation) {
      this._conrrespondenceService.getAllCorrespondenceForOrganisation(organisation.name).subscribe(c => {
        this.correspondences = c;
      });
    }
  }


  create() {
    if (!this.incoming) {
      this.createNewCorrespondence();
    } else {
      this.createIncomingCorrespondence();
    }
  }

  createNewCorrespondence() {
    this.correspondence.toId = this.toOrganisation.name;
    this.correspondence.relatedDocsReferences = this.relatedDocs.map(d => d.ref);
    this.correspondence.templateFileId = this.template.fileId;
    this.correspondence.providerUserId = this.user.username;
    this.correspondence.providerUserName = this.user.fullName;
    this.correspondence.organisationId = this.template.organisationId;
    this._conrrespondenceService.createNewCorrespondence(this.correspondence, this.mainFiles).subscribe(correspondence => {
      this.correspondence = correspondence;
      this._router.navigate(['correspondence/edit/false',correspondence.createdFileId, correspondence.organisationId, correspondence.ref]);
      this._messageService.publishMessage({
        message: 'نامه با موفقیت ثبت شد',
        level: MessageLevel.Info,
        topic: 'Correspondences'
      });
    }, (error) => {
      if (error.status === 404) {
        this._messageService.publishMessage({
          message: 'فایل ارسالی با فایل نمونه دریافت شده مطابقت ندارد',
          level: MessageLevel.Error,
          topic: 'Correspondences'
        });
      }
    });
  }

  createIncomingCorrespondence() {
    this.correspondence.organisationId = this.organisation.name;
    this.correspondence.relatedDocsReferences = this.relatedDocs.map(d => d.ref);
    this.correspondence.toId = this.toOrganisation.name;
    this.correspondence.providerUserId = this.user.username;
    this.correspondence.providerUserName = this.user.fullName;
    this.correspondence.incoming = true;
    this.correspondence.ref = 0;
    this.correspondence.attachments = [];

    if (this.mainFiles !== undefined) {
      if (this.mainFiles[0].size > 5000000) {
        confirm('The file size should be less than 5MB');
      } else {
        this._documentsService.add('', this.mainFiles).subscribe(r => {
          this.correspondence.createdFileId = r.id;
          this._conrrespondenceService.saveIncoming(this.correspondence).subscribe(c => {
            this._router.navigate(['correspondence/edit/true',c.createdFileId, c.organisationId, c.ref]);
            this._messageService.publishMessage({
              message: 'نامه با موفقیت ثبت شد',
              level: MessageLevel.Info,
              topic: 'Correspondences'
            });
          });
          this.mainFiles = null;
        }, () => {
            this._messageService.publishMessage({
              message: `Error occurred while uploading a file`,
              level: MessageLevel.Error,
              topic: 'Correspondence'
            });
        });
      }
    }
  }

  downloadNew() {
    this._documentsService.download(this.correspondence.createdFileId).subscribe(b => {
      saveAs(b.body, `${this.correspondence.title}.docx`);
    });
  }

  downloadAttachment(index: number) {
    this._documentsService.download(this.correspondence.attachments[index]).subscribe(b => {
      saveAs(b.body);
    });
  }

  downloadTemplate() {
    this._documentsService.download(this.template.fileId).subscribe(template => {
      saveAs(template.body, this.correspondence.title);
    });
  }


  corresPondenceFormatter(correspondence: Correspondence) {
    return correspondence.ref + '  ' + correspondence.title;
  }

  correspondenceFormatter2(refrence: number) {
    const doc = this.relatedDocs.find(d => d.ref === refrence);
    return 'Document refrence: ' + doc.ref + ' Title: ' + doc.title;
  }

  uploadSignedFile() {
    if (this.signedFiles !== undefined) {
      if (this.signedFiles[0].size > 5000000) {
        confirm('The file size should be less than 5MB');
      } else {
        this._documentsService.add('', this.signedFiles).subscribe(r => {
          if(this.incoming) {
            this.correspondence.createdFileId = r.id;
          } else {
            this.correspondence.signedFileId = r.id;
          }
          this.uploadedFileId = r.id;
          this.update();
          this.mainFiles = null;
        }, () => {
            this._messageService.publishMessage({
              message: `Error occurred while uploading a file`,
              level: MessageLevel.Error,
              topic: 'Tanker'
            });
        });
      }
    }
  }


  update() {
    this._conrrespondenceService.updateCorrespondence(this.correspondence).subscribe(c => {
      this._messageService.publishMessage({
        message: 'نامه با موفقیت ویرایش شد',
        level: MessageLevel.Info,
        topic: 'Correspondences'
      });
      this._router.navigate(['correspondence/edit/', this.incoming, c.createdFileId, c.organisationId, c.ref]);
    });
  }


  addAttachment() {
    if (this.attachment !== undefined) {
      if (this.attachment[0].size > 5000000) {
        confirm('The file size should be less than 5MB');
      } else {
        this._documentsService.add('correspondence attachment', this.attachment).subscribe(r => {
          this.correspondence.attachments.push(r.id);
          this.update();
          this.attachment = null;
        }, () => {
            this._messageService.publishMessage({
              message: `Error occurred while uploading a file`,
              level: MessageLevel.Error,
              topic: 'Correspondence'
            });
        });
      }
    }
  }

  removeAttachment(index: number) {
    this.correspondence.attachments.splice(index,1);
    this.update();
  }

  addRelatedDoc() {
    this.correspondence.relatedDocsReferences.push(this.relatedDoc.ref);
    this.update();
  }

  removeRelatedDoc(index: number) {
    this.correspondence.relatedDocsReferences.splice(index, 1);
    this.update();
  }

}



import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { HomeComponent } from './home.component';
import { PortalNewsService } from './portal-news.service';
import {AuthenticationModule} from '../authentication/authentication.module';

@NgModule({
  imports: [CommonModule, SharedModule, AuthenticationModule],
  declarations: [HomeComponent],
  exports: [HomeComponent],
  providers: [PortalNewsService]
})
export class HomeModule { }


import { Routes } from '@angular/router';
import { TrucksComponent } from './trucks/trucks-fleet-status/trucks.component';
import { TrucksListComponent } from './trucks/trucks-list/trucks-list.component';
import { TrucksReportComponent } from './trucks/trucks-report/trucks-report.component';
import { TrackerMonitorListComponent } from 'src/app/transportation/trucks/tracker-monitor-list/tracker-monitor-list.component';
import { TruckDataEntryComponent } from './trucks/data-entry/truck-data-entry/truck-data-entry.component';
import { VesselsComponent } from './vessels/vessels/vessels.component';
import { VoyagesComponent } from './vessels/voyages/voyages.component';
import { VoyageComponent } from './vessels/voyage/voyage.component';
import { NoonReportsComponent } from './vessels/noon-reports/noon-reports/noon-reports.component';
import { ImportNoonReportComponent } from './vessels/noon-reports/import-noon-report/import-noon-report.component';
import { FleetStatusComponent } from './vessels/fleet-status/fleet-status.component';
import { VoyageReportsComponent } from 'src/app/transportation/vessels/reports/voyage-reports.component';
import { SofTemplateComponent } from './vessels/sof-template/sof-template.component';
import { SofEntryComponent } from './vessels/sof-templates/sof-entry/sof-entry.component';
import { SofTemplatesComponent } from './vessels/sof-templates/sof-templates.component';
import { TankerDataEntryComponent } from './trucks/data-entry/tanker-data-entry/tanker-data-entry.component';
import { TankerListComponent } from './trucks/tanker-list/tanker-list.component';
import { GpsListComponent } from './trucks/gps-list/gps-list.component';
import { GpsDataEntryComponent } from './trucks/data-entry/gps-data-entry/gps-data-entry.component';
import { SelectVoyageReportComponent } from './vessels/select-report/select-voyage-report.component';
import { NauticalMileComponent } from './vessels/nautical-mile/nautical-mile.component';
import { BunkerDataEntryComponent } from './vessels/bunker/bunker-data-entry.component';
import { BunkerListComponent } from './vessels/bunker/bunker-list/bunker-list.component';
import { FleetNavigationHistoryComponent } from './vessels/fleet-navigation-history/fleet-navigation-history.component';
import { BunkerStatusComponent } from './vessels/bunker-status/bunker-status.component';
import { VoyagePlannerComponent } from './vessels/voyage-planner/voyage-planner.component';
import { VesselManagementCostComponent } from './vessels/vessel-management-cost/vessel-management-cost.component';
import { NewVoyageComponent } from './vessels/new-voyage/new-voyage.component';
import { SeaSharkStatusComponent } from './vessels/sea-shark-status/sea-shark.component';
import { NoonReportsV202005Component } from './vessels/noon-reports/noon-reports-v202005/noon-reports-v202005.component';
import { CertificateComponent } from './vessels/certificate/certificate.component';
import { CrewChangeComponent } from './vessels/crew-change/crew-change-data-entry/crew-change.component';
import { CrewChangeListComponent } from './vessels/crew-change/crew-changes/crew-changes.component';
import { CrewComponent } from './vessels/crew-change/crew/crew.component';
import { OpenStreetComponent } from './openstreet/open-street.component';
import { FuelAnalysisComponent } from './vessels/fuel-analysis/fuel-analysis.component';
import { LoadingTimeComponent } from './vessels/loading-time/loading-time.component';
import { NoonReportsV202205Component } from './vessels/noon-reports/noon-reports-v202205/noon-reports-v202205.component';
import { VesselDataEntryComponent } from './vessels/vessel/vessel-data-entry/vessel-data-entry.component';
import { VesselSpecComponent } from './vessels/vessel/vessel-spec/vessel-spec.component';
import { NewVesselComponent } from './vessels/vessel/new-vessel/new-vessel.component';
import { VesselInformationComponent } from './vessels/vessel/vessel-information/vessel-information.component';
import { FleetPersonnelManagementComponent } from './vessels/crew-change/fleet-personnel/fleet-personnel-management.component';
import { VesselInternalAuditComponent } from './vessels/vessel/vessel-internal-audit/vessel-internal-audit.component';
import { CrewAppraisalComponent } from './vessels/crew-change/appraisal/crew-appraisal.component';
import { CrewProfileComponent } from './vessels/crew-change/crew/crew-profile/crew-profile.component';
import { VesselOffHireComponent } from './vessels/vessel-off-hire/vessel-off-hire.component';
import { VesselsOffHiresComponent } from './vessels/vessel-off-hire/vessels-off-hires/vessels-off-hires.component';
import { FleetSummeryReportComponent } from 'src/app/transportation/vessels/fleet-status/fleet-summery-report/fleet-summery-report.component';

export const transportationRoutes: Routes = [
  { path: 'trucks', component: TrucksComponent },
  { path: 'tracker/openstreet', component: OpenStreetComponent },
  { path: 'trucks/:mode', component: TruckDataEntryComponent },
  { path: 'trucks/:mode/:truckNumber/:field', component: TruckDataEntryComponent },
  { path: 'trucks-list', component: TrucksListComponent },
  { path: 'trucks-report', component: TrucksReportComponent },
  { path: 'trucks-report/:imei/:startTimestamp/:endTimestamp', component: TrucksReportComponent },
  { path: 'tanker/:mode', component: TankerDataEntryComponent },
  { path: 'tanker/:mode/:tankerNumber/:owner', component: TankerDataEntryComponent },
  { path: 'tanker', component: TankerListComponent },
  { path: 'tracker', component: GpsListComponent },
  { path: 'tracker/monitor/list', component: TrackerMonitorListComponent},
  { path: 'tracker/:mode', component: GpsDataEntryComponent },
  { path: 'tracker/:mode/:imei', component: GpsDataEntryComponent },


  { path: 'vessels', component: VesselsComponent },
  { path: 'vessels/crew-change/crew-change-data-entry/:id', component: CrewChangeComponent },
  { path: 'vessels/crew-change/crew-change-data-entry/:id/:crewChangeId', component: CrewChangeComponent },
  { path: 'vessels/crew-change/crew/:id', component: CrewComponent },
  { path: 'vessels/crew-change/crew', component: CrewComponent },
  { path: 'vessels/crew-change/crew/profile/:id', component: CrewProfileComponent },
  { path: 'vessels/crew-change/crew-changes', component: CrewChangeListComponent },
  { path: 'vessels/crew-change/appraisal/:id', component: CrewAppraisalComponent },
  { path: 'vessels/fleet-personnel-management', component: FleetPersonnelManagementComponent },
  { path: 'vessels/nautical-mile', component: NauticalMileComponent },
  { path: 'vessels/select-report', component: SelectVoyageReportComponent },
  { path: 'vessels/reports', component: VoyageReportsComponent },
  { path: 'vessels/voyages', component: VoyagesComponent },
  { path: 'vessels/voyage', component: NewVoyageComponent },
  { path: 'vessels/off-hire', component: VesselOffHireComponent },
  { path: 'vessels/off-hire/:id', component: VesselOffHireComponent },
  { path: 'vessels/off-hires', component: VesselsOffHiresComponent },
  { path: 'vessels/voyage/planner', component: VoyagePlannerComponent },
  { path: 'vessels/voyage/management-budget', component: VesselManagementCostComponent },
  { path: 'vessels/voyage/bunker/bunker-list', component: BunkerListComponent},

  { path: 'vessels/voyage/bunker', component: BunkerDataEntryComponent},
  { path: 'vessels/voyage/bunker/:vesselIMO/:product/:date', component: BunkerDataEntryComponent},
  { path: 'vessels/noon-reports', component: NoonReportsComponent },
  { path: 'vessels/noon-reports-v202005', component: NoonReportsV202005Component },
  { path: 'vessels/noon-reports-v202205/:startDate/:endDate', component: NoonReportsV202205Component },
  { path: 'vessels/noon-reports/import-noon-report', component: ImportNoonReportComponent },
  { path: 'vessels/noon-reports/analysis', component: FuelAnalysisComponent },
  { path: 'vessels/noon-reports/latest-report', component: FleetSummeryReportComponent },
  { path: 'vessels/sea-shark-status', component: SeaSharkStatusComponent },
  { path: 'vessels/certificate/:imo', component: CertificateComponent },
  { path: 'vessels/status', component: FleetStatusComponent },
  { path: 'vessels/summery-report', component: FleetStatusComponent },
  { path: 'vessels/navigation-history/:startTime/:endTime', component: FleetNavigationHistoryComponent },
  { path: 'vessels/bunker/:startTime/:endTime', component: BunkerStatusComponent },
  { path: 'vessels/noon-reports', component: NoonReportsComponent },
  { path: 'vessels/loading-time', component: LoadingTimeComponent },
  { path: 'vessels/voyage/:id/:docType/:fileId', component: VoyageComponent },
  { path: 'vessels/voyage/:id', component: VoyageComponent },
  { path: 'vessels/sof/sof-entry', component: SofEntryComponent },
  { path: 'vessels/sof-templates', component: SofTemplatesComponent },
  { path: 'vessels/sof/sof-template/:id', component: SofTemplateComponent },
  { path: 'vessels/sof/sof-template', component: SofTemplateComponent },
  { path: 'vessels/vessel-information/:imo', component: VesselInformationComponent },
  { path: 'vessel/internal-audit', component: VesselInternalAuditComponent },
  { path: 'vessel/:imo', component: VesselDataEntryComponent },
  { path: 'vessel/spec/:imo', component: VesselSpecComponent },
  { path: 'vessel', component: NewVesselComponent },
];

<div class="row">
  <a style="cursor: pointer;" *appHideIfNotInRole="['trucks-viewer']" class="col-md-4"
     [routerLinkActive]="['active']" routerLink="/trucks"
    routerLinkActive="active">
    <div class="card shadow-sm mb-3 blue info-box">
        <div class="card-body">
          <h5 class="card-title"><i class="fa fa-truck"></i> Trucks</h5>
          <br>
          <hr class="hr" />
          <p class="card-text">Trucks fleet status</p>
      </div>
      <!-- /.info-box-content -->
    </div>
  </a>
  <a class="col-md-4" style="cursor: pointer;" *appHideIfNotInRole="['lpg-dist-viewer']" [routerLinkActive]="['active']" routerLink="/lpg/report"
    routerLinkActive="active">
    <div class="card shadow-sm mb-3 blue info-box">
      <div class="card-body">
        <h5 class="card-title"><i class="fa fa-area-chart"></i> LPG loadings</h5>
        <br>
        <hr class="hr" />
        <p class="card-text">LPG loading and discharging reports</p>
      </div>
    </div>
  </a>
  <a class="col-md-4" style="cursor: pointer;" *appHideIfNotInRole="['phonebook-viewer']" [routerLinkActive]="['active']" routerLink="/phone-book"
    routerLinkActive="active">
    <div class="card shadow-sm mb-3 blue info-box">
      <div class="card-body">
        <h5 class="card-title"><i class="fa fa-phone"></i> Phonebook</h5>
        <br>
        <hr class="hr" />
        <p class="card-text"></p>
      </div>
    </div>
  </a>
  <a  class="col-md-4" style="cursor: pointer;" *appHideIfNotInRole="['organisations-viewer']" [routerLinkActive]="['active']" routerLink="//organisations"
    routerLinkActive="active">
    <div class="card shadow-sm mb-3 blue  info-box">
      <div class="card-body">
        <h5 class="card-title"><i class="fa fa-sitemap"></i> Organisations</h5>
        <br>
        <hr class="hr" />
        <p class="card-text"></p>
      </div>
    </div>
  </a>
  <a class="col-md-4" style="cursor: pointer;" *appHideIfNotInRole="['vessels-viewer']" [routerLinkActive]="['active']" routerLink="/vessels/status"
     routerLinkActive="active">
    <div class="card shadow-sm mb-3 blue  info-box">
      <div class="card-body">
        <h5 class="card-title"><i class="fa fa-ship"></i> Fleet status</h5>
        <br>
        <hr class="hr" />
        <p class="card-text"> Vessels fleet status</p>
      </div>
    </div>
  </a>
  <a  class="col-md-4" style="cursor: pointer;" *appHideIfNotInRole="['digital-ids-viewer']" [routerLinkActive]="['active']" routerLink="/security/digital-ids"
     routerLinkActive="active">
    <div class="card shadow-sm mb-3 blue  info-box">
      <div class="card-body">
        <h5 class="card-title"><i class="fa fa-lock"></i> Digital ids</h5>
        <br>
        <hr class="hr" />
        <p class="card-text">Email encryption keys</p>
      </div>
    </div>
  </a>
  <a class="col-md-4" style="cursor: pointer;" *appHideIfNotInRole="['markets-viewer']" [routerLinkActive]="['active']" routerLink="/pricing/report"
      routerLinkActive="active">
    <div class="card shadow-sm mb-3 blue  info-box">
      <div class="card-body">
        <h5 class="card-title"><i class="fa fa-usd"></i> Market</h5>
        <br>
        <hr class="hr" />
        <p class="card-text"> Market in a nutshell</p>
      </div>
    </div>
  </a>
</div>

import {Component, OnDestroy, OnInit} from '@angular/core';
import { DocumentService } from 'src/app/documents/documents.service';
import { SecurityService, UserProfile } from 'src/app/security/security.service';
import { KeycloakService, UserData } from 'src/app/authentication/keycloak.service';
import { UserLocalStoreService } from 'src/app/shared/services/user-local-store.service';
import { ShortcutLinksService, Shortcut } from 'src/app/shared/services/shortcut-links.service';
import { VERSION } from 'src/environments/version';

interface SidebarSubItem {
  roles: string[];
  label: string;
  icon: string;
  href: string;
}

interface SidebarItem {
  roles: string[];
  label: string;
  icon: string;
  subItems: SidebarSubItem[];
}

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.css']
})
export class SideBarComponent implements OnInit, OnDestroy {

  isSignedIn: boolean;
  isSignedOut: boolean;
  userData: UserData;
  user: UserProfile;
  thumbnail: any;
  currentFiles: FileList;
  _settings: Shortcut[] = [];
  version: string;

  sidebarItems: SidebarItem[] = [
    {
      roles: ['trucks-viewer'],
      label: 'Trucks',
      icon: 'fa fa-truck fa-stack-1x',
      subItems: [
        {
          roles: [],
          href: '/trucks',
          icon: '',
          label: 'Map',
        },
        {
          roles: [],
          href: '/trucks-list',
          icon: '',
          label: 'Trucks',
        },
        {
          roles: [],
          href: '/tanker',
          icon: '',
          label: 'Tankers',
        },
        {
          roles: [],
          href: '/tracker',
          icon: '',
          label: 'GPS',
        },
        {
          roles: [],
          href: '/tracker/monitor/list',
          icon: '',
          label: 'GPS Monitoring',
        },
      ]
    },
    {
      roles: ['lpg-dist-viewer'],
      label: 'Loadings Reports',
      icon: 'fa fa-pie-chart fa-stack-1x',
      subItems: [
        {
          roles: [],
          href: '/lpg/report',
          icon: '',
          label: 'Lpg Report',
        },
        {
          roles: [],
          href: '/lpg/report/daily-report',
          icon: '',
          label: 'Daily',
        },
        {
          roles: [],
          href: '/lpg/report/compare/yearly',
          icon: '',
          label: 'Yearly',
        },
        {
          roles: [],
          href: '/lpg/report/summary',
          icon: '',
          label: 'Costs',
        },
        {
          roles: [],
          href: '/lpg-dist/detail',
          icon: '',
          label: 'Loadings',
        },
        {
          roles: [],
          href: '/lpg/report/truck',
          icon: '',
          label: 'Trucks report',
        },
        {
          roles: [],
          href: '/lpg-dist/payment',
          icon: '',
          label: 'Payments',
        },
        {
          roles: ['lpg-dist-editor'],
          href: '/lpg-dist/quta-entry',
          icon: '',
          label: 'Quota',
        },
        {
          roles: ['lpg-dist-editor'],
          href: '/lpg-dist/data-entry/new',
          icon: '',
          label: 'Loading',
        },
        {
          roles: ['lpg-dist-editor'],
          href: '/lpg-dist/payments/new/iran.parsagroup.orsagas',
          icon: '',
          label: 'Payments',
        },
        {
          roles: ['lpg-dist-kantar-editor'],
          href: '/lpg-dist/organisations',
          icon: '',
          label: 'Kantar Map',
        },
        {
          roles: ['lpg-dist-editor'],
          href: '/lpg-dist/file',
          icon: '',
          label: 'File uploader',
        },
      ]
    },
    {
      roles: ['phonebook-viewer'],
      label: 'Phonebook',
      icon: 'fa fa-phone fa-stack-1x',
      subItems: [
        {
          roles: [],
          href: '/phone-book',
          icon: '',
          label: 'Phonebook',
        },
      ]
    },
    {
      roles: ['organisations-editor'],
      label: 'Organisations',
      icon: 'fa fa-sitemap fa-stack-1x',
      subItems: [
        {
          roles: [],
          href: '/organisations/list',
          icon: '',
          label: 'Organisations Lists',
        },
      ]
    },
    {
      roles: ['vessels-viewer'],
      label: 'Vessels',
      icon: 'fa fa-ship fa-stack-1x',
      subItems: [
        {
          roles: [],
          href: '/vessels/status',
          icon: '',
          label: 'Fleet Status',
        },
        {
          roles: ['vessels-crew-viewer'],
          href: '/vessels/fleet-personnel-management',
          icon: '',
          label: 'Fleet Personnel',
        },
        {
          roles: [],
          href: '/vessels',
          icon: '',
          label: 'Vessels',
        },
        {
          roles: [],
          href: '/vessels/select-report',
          icon: '',
          label: 'Voyage Reports',
        },
        {
          roles: [],
          href: '/vessels/voyage/bunker/bunker-list',
          icon: '',
          label: 'Bunkering',
        },
        {
          roles: [],
          href: '/vessels/noon-reports/analysis',
          icon: '',
          label: 'Analysis',
        },
        {
          roles: ['vessels-sof-template-editor'],
          href: '/vessels/sof-templates',
          icon: '',
          label: 'Sof Templates',
        },
        {
          roles: ['distances-viewer'],
          href: '/vessels/nautical-mile',
          icon: '',
          label: 'Nautical mile',
        },
        {
          roles: [],
          href: '/vessels/loading-time',
          icon: '',
          label: 'Loading Time',
        },
        {
          roles: ['vessels-noon-reports-editor'],
          href: '/vessels/noon-reports/import-noon-report',
          icon: '',
          label: 'Import noon reports',
        },
        {
          roles: ['vessels-off-hire-editor'],
          href: '/vessels/off-hires',
          icon: '',
          label: 'Off hires',
        },
        {
          roles: [],
          href: '/vessels/noon-reports/latest-report',
          icon: '',
          label: 'Management Report',
        },
        // {
        //   roles: [],
        //   href: '/vessels/voyage/planner',
        //   icon: '',
        //   label: 'Voyage planner',
        // },
        // {
        //   roles: [],
        //   href: '/vessels/voyage/management-budget',
        //   icon: '',
        //   label: 'Vessel management',
        // },
      ]
    },
    {
      roles: ['markets-viewer'],
      label: 'Market',
      icon: 'fa fa-dollar fa-stack-1x',
      subItems: [
        {
          roles: [],
          href: '/pricing/report',
          icon: '',
          label: 'Markets',
        },
        {
          roles: [],
          href: '/pricing',
          icon: '',
          label: 'Markets report',
        },
        {
          roles: ['markets-editor'],
          href: '/pricing/file',
          icon: '',
          label: 'Price file uploader',
        },
      ]
    },
    {
      roles: ['digital-ids-viewer'],
      label: 'Digital Ids',
      icon: 'fa fa-lock fa-stack-1x',
      subItems: [
        {
          roles: [],
          href: '/security/digital-ids',
          icon: '',
          label: 'Digital Ids',
        },
      ]
    },
    {
      roles: ['correspondence-viewer'],
      label: 'Correspondence',
      icon: 'fa fa-envelope fa-stack-1x',
      subItems: [
        {
          roles: [],
          href: '/correspondence',
          icon: '',
          label: 'Correspondence List',
        },
        {
          roles: ['correspondence-editor'],
          href: '/correspondence',
          icon: '',
          label: 'New Correspondence',
        },
        {
          roles: ['correspondence-template-editor'],
          href: '/correspondence/templates/new',
          icon: '',
          label: 'New Template',
        },
      ]
    },
  ];

  permittedSidebarItems: SidebarItem[] = [];

  constructor(private _keycloakService: KeycloakService,
    private _securityService: SecurityService,
    private _documentsService: DocumentService,
    private _userLocalStorage: UserLocalStoreService,
    private _shortcutLinksService: ShortcutLinksService,
    ) {
    this.version = VERSION.version;
  }

  ngOnInit(): any {
    this.permittedSidebarItems = this.sidebarItems.filter(s =>
      s.roles.map(r => this._keycloakService.hasRole(r)).includes(true)
    )
    .map(s => (
      {
        ...s,
        subItems: s.subItems.filter(s1 => {
          if (s1.roles && s1.roles.length > 0) {
            return s1.roles.map(r => this._keycloakService.hasRole(r)).includes(true);
          }
          else {
            return true;
          }
        })
      }
    ));

    const flattedLinks = this.permittedSidebarItems.flatMap(s => s.subItems);
    const links  = this._userLocalStorage.get<Shortcut[]>('ShortCut');
    if (links) {
      this._settings = links.filter(s => {
        if (s.link) {
          return flattedLinks.map(s1 => s.link === s1.href && s.name === s1.label).includes(true);
        }
        return false;
      });
    } else {
      this._settings = [];
    }
    this._shortcutLinksService.publishShortcuts(this._settings);
    this.isSignedIn = true;
    this.isSignedOut = false;
    this.userData = this._keycloakService.getUser();
    this._securityService.getByPortalUserName().subscribe(d => {
      this.user = d;
      if (this.user.profileImageId) {
        this.downloadProfileImage();
      } else {
        this.thumbnail = '/assets/icons/main-logo/user.png';
      }
    }, () => {
      this.thumbnail = '/assets/icons/main-logo/user.png';
    });
    return undefined;
  }

  addOrRemoveShortcut(name: string, link: string) {
    const a: Shortcut = {
      name,
      link,
    };
    if(this._settings.filter(t => t.name === name || t.link === link).length > 0) {
      this._settings = this._settings.filter(t => !(t.name === name || t.link === link));
    } else {
      this._settings.push(a);
    }
    this._shortcutLinksService.publishShortcuts(this._settings);
  }

  isChecked(label: string) {
    return this._settings.filter(s => s.name === label).length > 0;
  }

  signOut() {
    this._keycloakService.logout();
  }

  ngOnDestroy(): any {
    return undefined;
  }

  downloadProfileImage() {
    this._documentsService.download(this.user.profileImageId).subscribe(b => {
      const reader = new FileReader();
      reader.readAsDataURL(b.body);
      reader.onload = () => {
        this.thumbnail= reader.result;
      };
    });
  }

}

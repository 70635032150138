import { Injectable } from '@angular/core';
import { HttpWrapper } from '../../shared/services';
import { Observable } from 'rxjs';
import { GeoCoordinate } from '../models/GeoCoordinate';
import { LPGDistData } from 'src/app/lpg-dist/lpg-dist.service';

export class TemporalAssociation<A> {
  startTime: number;
  endTime: number;
  associatedObject: A;
}

export interface Truck {
  fileId: string;
  formattedRegistrationNumber: string;
  truckNumber: string[];
  // eslint-disable-next-line @typescript-eslint/naming-convention
  VIN: string;
  lastLocation: DeviceLocation;
  organisationId: TemporalAssociation<string>;
  tracker: TemporalAssociation<MobileTrackerDevice>;
  tanker: TemporalAssociation<TankerData>;
  driver: TemporalAssociation<DriverData>;
  active: boolean;
  isDeleted: boolean;
  trackersHistory: TemporalAssociation<MobileTrackerDevice>[];
  driversHistory: TemporalAssociation<DriverData>[];
  organisationsHistory: TemporalAssociation<string>[];
  tankersHistory: TemporalAssociation<TankerData>[];
}

export interface DeviceLocation {
  time: number;
  location: GeoCoordinate;
  speed: number;
  bearing: number;
}

export interface MobileTrackerDevice {
  imei: string;
  mobileNumber: string;
  trackerType: string;
}

export interface GPSTrackerCoordinate {
  imei: string;
  timestamp: number;
  geoCoordinate: GeoCoordinate;
  speed: number;
  bearing: number;
  altitude: number;
  distanceTravelledMeters: number;
}

export interface TimeSpeed {
  timestamp: number;
  speed: number;
}

export interface TankerData {
  fileId: string;
  number: string;
  owner: string;
  capacity: number;
  licenseExpireDate: number;
  licenseNumber: number;
}

export interface DriverData {
  name: string;
}

export interface Distance {
  originOrganisationId: string;
  destinationOrganisationId: string;
  distance: number;
  duration: number;
}

export interface MapToolbarItem {
  recenter: boolean;
  zoomOut: boolean;
  showOutOfReachTruck: boolean;
  showSearchPanel: boolean;
  colorCodeByOrganisation: boolean;
  clusterTrucks: boolean;
  selectedOrganisationId: string;
  selectedTruck: string;
}

export interface OrganisationIdColour {
  organisationId: string;
  colour: string;
}


@Injectable()
export class TrucksService {

  // need to fix config URL here
  _trackingPath: string; // = 'ws://localhost:9000/api/tracking/listen';
  historyHours: number = 12 * 60 * 60 * 1000;
  updateHours: number = 72 * 60 * 60 * 1000;

  constructor(private _httpWrapper: HttpWrapper) { }

  // ngOnInit() {
  //   this._trackingPath = 'ws://localhost:9000/api/tracking/listen';
  // }

  get(): Observable<Truck[]> {
    return this._httpWrapper.get<Truck[]>('trucks');
  }

  getForLpgDist(): Observable<Truck[]> {
    return this._httpWrapper.get<Truck[]>('trucks/lpg-dist');
  }

  add(truck: Truck): Observable<Truck> {
    return this._httpWrapper.post('trucks', truck);
  }

  addTanker(tanker: TankerData): Observable<TankerData> {
    return this._httpWrapper.post('tanker', tanker);
  }

  addTracker(tracker: MobileTrackerDevice): Observable<MobileTrackerDevice> {
    return this._httpWrapper.post('tracker', tracker);
  }

  getAllTracker(): Observable<MobileTrackerDevice[]> {
    return this._httpWrapper.get<MobileTrackerDevice[]>(`tracker/getAll`);

  }

  getAllTankers(): Observable<TankerData[]> {
    return this._httpWrapper.get<TankerData[]>(`tanker/getAll`);
  }

  getAllTankersExcel() {
    return this._httpWrapper.download(`tanker/getAll/excel`);
  }

  getOneTanker(tankerNumber: string, tankerOwner): Observable<TankerData> {
    return this._httpWrapper.get<TankerData>(`tanker/getOne/${tankerNumber}/${tankerOwner}`);
  }

  getTankerHistory(tankerNumber: string): Observable<any[]> {
    return this._httpWrapper.get<any[]>(`tanker/getHistory/${tankerNumber}`);
  }

  getOneTracker(imei: string): Observable<MobileTrackerDevice> {
    return this._httpWrapper.get<MobileTrackerDevice>(`tracker/getOne/${imei}`);
  }

  getTrackerHistory(imei: string): Observable<any[]> {
    return this._httpWrapper.get<any[]>(`tracker/getHistory/${imei}`);
  }

  getLatestUpdate(date: number): Observable<Truck[]> {
    return this._httpWrapper.get<Truck[]>(`tracker/getLatestUpdate/${date}`);
  }

  update(truck: Truck): Observable<Truck> {
    return this._httpWrapper.put('trucks', truck);
  }
  updateTanker(updatetanker: TankerData): Observable<TankerData> {
    return this._httpWrapper.put('tanker', updatetanker);
  }

  updateTracker(updateTracker: MobileTrackerDevice): Observable<MobileTrackerDevice> {
    return this._httpWrapper.put('tracker', updateTracker);
  }

  getByImei(imei: string): Observable<Truck> {
    return this._httpWrapper.get<Truck>(`trucks/imei/${imei}`);
  }

  getByRegistrationNumber(registrationNumber: string): Observable<Truck> {
    return this._httpWrapper.get<Truck>(`trucks/registration-number/${registrationNumber}`);
  }

  getLastTwoHoursHistory(truck: Truck): Observable<GPSTrackerCoordinate[]> {
    const currentTime = Date.now();
    return this.getSpeedHistoryForTruckTimestamp(truck.tracker.associatedObject.imei, currentTime - this.historyHours, currentTime);
  }

  getDistance(originName: string, destinationName: string): Observable<Distance> {
    return this._httpWrapper.get<Distance>(`dictance/getDistance/${originName}/${destinationName}`);
  }

  getOutlierLocations(startTimestamp: number, endTimestamp: number): Observable<any> {
    return this._httpWrapper.get<any>(`tracking/outlier/${startTimestamp}/${endTimestamp}`);
  }

  getAllDistances(): Observable<Distance[]> {
    return this._httpWrapper.get<Distance[]>(`distance/getAllDistances`);
  }

  getDistanceTraveledInKillometers(history: GPSTrackerCoordinate[]) {
    if (history) {
      const sortedHistory = history.sort((a, b) => a.timestamp - b.timestamp);
      const firstItem = sortedHistory[0];
      const lastItem = sortedHistory[sortedHistory.length - 1];
      return (lastItem.distanceTravelledMeters - firstItem.distanceTravelledMeters) / 1000;
    }

    return 0;
  }

  getSpeedHistoryForTruck(imei: string, startTime: Date, endTime: Date) {
    return this.getSpeedHistoryForTruckTimestamp(imei, startTime.getTime(), endTime.getTime());
  }

  getSpeedHistoryForTruckTimestamp(imei: string, startTime: number, endTime: number) {
    return this._httpWrapper.get<GPSTrackerCoordinate[]>(`tracking/history/${imei}/${startTime}/${endTime}`);
  }

  isPositionOutdated(truck: Truck): boolean {
    if (truck.lastLocation) {
      const current = new Date().getTime();
      const diff = current - truck.lastLocation.time;

      return this.updateHours < diff;
    }
    return true;
  }

  getLastLoadingOfTrucksHaveTrackers(): Observable<LPGDistData[]> {
    return this._httpWrapper.get<LPGDistData[]>(`trucks/latest-loading`);
  }
}

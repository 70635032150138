import { Injectable } from '@angular/core';
import {HttpWrapper} from '../../../shared';
import {DateRange} from '../../../shared/components';
import {Observable} from 'rxjs';
import {BunkerType, DataBaseAction} from '../vessels.service';
import {GeoCoordinateDMS} from '../../models/GeoCoordinate';

export interface VesselInfo {
  vesselName?: string;
  vesselIMO?: string;
  master?: string;
}

export interface VesselReportDate {
  isoDate: string; // '2022-05-24T12:08Z+04:00'
}

export interface VesselReportedPlan {
  sourceId?: string;
  destinationId?: string;
  eta?: string;
}

export interface VesselReportedDistances {
  distanceCovered?: number;
  distanceByMainEngine?: number;
  distanceToGo?: number;
  totalDistanceRun?: number;
}

export interface VesselReportedLocation {
  geoCoordinate?: GeoCoordinateDMS;
  currentLocation?: string;
  bearing?: number;
}

export interface VesselReportedSpeed {
  averageCurrentReportSpeed?: number;
  averageVoyageSpeed?: number;
}

export enum Direction {
  calm = 'calm',
  north = 'north',
  south = 'south',
  west = 'west',
  east = 'east',
  northWest = 'northWest',
  northEast = 'northEast',
  southWest = 'southWest',
  southEast = 'southEast',
}

export enum MainDirection {
  north = 'north',
  south = 'south',
  west = 'west',
  east = 'east',
}

export enum DirectionPresentationMappings {
  calm = 'Calm',
  north = 'North',
  south = 'South',
  west = 'West',
  east = 'East',
  northWest ='North West',
  northEast = 'North East',
  southWest = 'South West',
  southEast = 'South East',
}

export interface WeatherForce {
  force?: number;
  direction?: Direction;
}

export interface VesselReportedWeatherCondition {
  seaForce?: WeatherForce;
  windForce?: WeatherForce;
  currentForce?: WeatherForce;
  swellForce?: WeatherForce;
}

export interface VesselReportedDraft {
  forwardDraft?: number;
  middleDraft?: number;
  aftDraft?: number;
}

export interface VesselReportedTankCondition {
  tankNo: string;
  product: string;
  quantity: number;
  temperature: number;
}

export enum BunkerReportPart {
  mainEngine = 'Main Engine',
  powerGenerators = 'Power Generators',
  boilers = 'Boilers',
  robs = 'Rob',
  none = 'None',
}

export interface VesselReportedBunker {
  reportPart: BunkerReportPart;
  bunkerType?: BunkerType;
  quantityMT?: number;
}

export interface VesselReportedLastReceived {
  receivedType?: string;
  timestamp?: number;
  quantityMT?: number;
}

export enum VesselReportedPositionStatus {
  atSea = 'atSea',
  atPort = 'atPort',
  atBunkering = 'atBunkering',
}

export enum ManoeuvringStatus {
  manoeuvring = 'manoeuvring',
  steaming = 'steaming',
  drifting = 'drifting',
}

export interface VesselReportedMainEngineBunkerConsumption
  extends VesselReportedBunker {
  isLaden: boolean;
  manoeuvringStatus: ManoeuvringStatus;
  hours: number;
  averageRpm: number;
}

export interface VesselReportedLubOilStatus {
  mainEngineCylinderOilConsumption: number;
  mainEngineSystemOilConsumption: number;
  auxiliarySystemOilConsumption: number;
  mainEngineCylinderOilRob: number;
  mainEngineSystemOilRob: number;
  auxiliarySystemOilRob: number;
}

export interface VesselReportedFreshWaterStatus {
  rob?: number;
  consumption?: number;
  generated?: number;
}

export interface VesselReportV202205 {
  vesselInfo?: VesselInfo;
  voyageNumber?: string;
  reportType?: string;
  reportDate?: VesselReportDate;
  currentPlan?: VesselReportedPlan;
  distances?: VesselReportedDistances;
  vesselLocation?: VesselReportedLocation;
  speed?: VesselReportedSpeed;
  weatherCondition?: VesselReportedWeatherCondition;
  drafts?: VesselReportedDraft;
  tanksCondition?: VesselReportedTankCondition[];
  bunkerReports?: VesselReportedBunker[];
  lubOilStatus?: VesselReportedLubOilStatus;
  freshWaterStatus?: VesselReportedFreshWaterStatus;
  ballastWaterTotalVolume?: number;
  comments?: string;
  actions?: DataBaseAction[];
  submittedAction: DataBaseAction;
  deletedAction: DataBaseAction;
}

@Injectable({
  providedIn: 'root'
})
export class NoonReportsV202205Service {

  constructor(private _httpWrapper: HttpWrapper) { }

  getNoonReportsV202205Between(dr: DateRange): Observable<VesselReportV202205[]> {
    return this._httpWrapper.get<VesselReportV202205[]>(`vessels/noon-report-v202205/${dr.startDate.getTime()}/${dr.endDate.getTime()}`);
  }

  downloadReports(startTime: number, endTime: number) {
    return this._httpWrapper.download(`vessels/noon-reports-v202205/reports/${startTime}/${endTime}`);
  }
}

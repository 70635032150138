import { Component, EventEmitter, OnInit } from '@angular/core';
import { KeycloakService, UserData } from '../../authentication/keycloak.service';
import { UserProfile, SecurityService } from '../../security/security.service';
import { MessageLevel, PgMessagesService} from '../../shared/services/pg-messages.service';
import { DocumentService} from '../../documents/documents.service';
import { saveAs } from 'file-saver';
import { DomSanitizer } from '@angular/platform-browser';
import { PgTitleComponent } from '../../shared/components/title-bar/title-bar.component';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
})
export class UserProfileComponent implements OnInit, PgTitleComponent {
  title = new EventEmitter<string>();

  userData: UserData;
  user: UserProfile;
  thumbnail: any;
  currentFiles: FileList;
  message: string;


  constructor(
    private _keycloakService: KeycloakService,
    private _securityService: SecurityService,
    private _documentsService: DocumentService,
    private _pgMessagesService: PgMessagesService,
    private _sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.title.emit('Profile');
    this._securityService.getByPortalUserName().subscribe(d => {
      this.user = d;
      this.downloadProfileImage();
    }, () => {
      this.userData = this._keycloakService.getUser();
      this.user = {
        portalUserName: this.userData.username,
        fullName: this.userData.fullName,
        email: this.userData.email,
        confirm: false,
        digitalIdFiles: [],
        profileImageId: null
      };
    });
  }


  openUpload(fileEvent: any) {
    this.currentFiles = fileEvent.target.files;
    if (this.currentFiles !== undefined) {
      if (this.currentFiles[0].size > 300000) {
        confirm('The file size should be less than 5MB');
      } else {
        this._documentsService.add('profile_image', this.currentFiles).subscribe(r => {
          this.user.profileImageId = r.id;
          this._securityService.upsert(this.user).subscribe(() => {
            this.message = `Profile Image added to  ${this.user.fullName} profile`;
            this._pgMessagesService.publishMessage({
              message: this.message,
              topic: 'New Image',
              level: MessageLevel.Info
            });
          });
          this.downloadProfileImage();
        });
      }
    } else {
      confirm('Please select a file');
    }
  }

  fileChangedListener(fileEvent: any) {
    this.currentFiles = fileEvent.target.files;
  }

  downloadProfileImage() {
    this._documentsService.download(this.user.profileImageId).subscribe(b => {
      const reader = new FileReader();
        reader.readAsDataURL(b.body);
        reader.onload = () => {
          this.thumbnail= reader.result;
        };
    });
  }

  downloadFile(fileId: string, fileName: string) {
    this._documentsService.download(fileId).subscribe(b => {
      saveAs(b.body, `${fileName}.p12`);
    }, () => {
      this._pgMessagesService.publishMessage({
        message: 'Could not download this file.',
        level: MessageLevel.Error,
        topic: 'User profile'
      });
    });
  }

  confirm() {
    this.user.confirm = true;
    this._securityService.upsert(this.user).subscribe(() => {
      console.log('add');
    });
  }

}

import { Component, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { forkJoin } from 'rxjs';
import { DocumentReference, DocumentService } from 'src/app/documents/documents.service';
import { Organisation, OrganisationService } from 'src/app/organisation/organisation.service';
import { LookupService } from 'src/app/shared/services/lookup.service';
import { PgMessagesService, MessageLevel } from 'src/app/shared/services/pg-messages.service';
import { Crew, PassportDetails, SeamanDetails} from 'src/app/transportation/vessels/crew-change/crew-change.service';
import { CrewChangeServices } from '../crew-change.service';
import { DataBaseActionService, ActionType } from 'src/app/shared/services/database-action.service';
import { KeycloakService } from '../../../../authentication/keycloak.service';
import { saveAs } from 'file-saver';


@Component({
  selector: 'app-crew',
  templateUrl: './crew.component.html'
})
export class CrewComponent implements OnInit {
  title = new EventEmitter<string>();
  crew: Crew;
  message: string;
  rankItem = [];
  edit: boolean;
  nationalityItem: Organisation[];
  getId: string;
  nationality: Organisation;
  minDateExpiryDate = moment().format('YYYY-MM-DD');
  maxDate = moment().format('YYYY-MM-DD');
  oldPassportDetails: PassportDetails;
  oldSeamanDetails: SeamanDetails;
  oldContactNumber: string;

  constructor(
     private _crewChangeService: CrewChangeServices,
     private _pgMessagesService: PgMessagesService,
     private _activateRoute: ActivatedRoute,
     private _actionService: DataBaseActionService,
     private _lookupService: LookupService,
     private _organisationService: OrganisationService,
     private _keycloakService: KeycloakService,
     private _documentService: DocumentService) { }

  ngOnInit(): void {
    this.nationalityItem = [];
    this.title.emit('Crew');
    this._activateRoute.paramMap.subscribe(p => {
      this.getId = p.get('id');
      this.crew = {
        id: '00000000-0000-0000-0000-000000000000',
        firstName: null,
        lastName: null,
        middleName: null,
        photoDocumentId: null,
        birthDate: null,
        passportDetails: {
          passportExpirationDate: null,
          passportFileId: null,
          passportIssueDate: null,
          passportNumber: null
        },
        seamanDetails: {
          seamanExpirationDate: null,
          seamanFileId: null,
          seamanNumber: null
        },
        nationality: null,
        contactNumber: null,
        crewChanges: [],
        cvDocumentId: null,
        actions: [],
        oldDetails: null
      };
      forkJoin([
        this._organisationService.get(),
        this._lookupService.get('ranks'),
      ]).subscribe((result) => {
        this.nationalityItem = result[0].filter(x => x.properties['country'] === true);
        this.rankItem = result[1].items;
        this.initCrew();
      });
    });
  }

  disableEdit() {
    return !this._keycloakService.hasRole('vessels-crew-change-editor');
  }

  initCrew() {
    if (this.getId) {
      this.edit = true;
      this._crewChangeService.getOneCrewById(this.getId).subscribe(x => {
        this.crew = x;
        this.nationality = this.nationalityItem.find(z => z.name === x.nationality);
        this.oldPassportDetails = {
          passportExpirationDate: this.crew.passportDetails.passportExpirationDate,
          passportFileId: this.crew.passportDetails.passportFileId,
          passportIssueDate: this.crew.passportDetails.passportIssueDate,
          passportNumber: this.crew.passportDetails.passportNumber
        };
        this.oldSeamanDetails = {
          seamanExpirationDate: this.crew.seamanDetails.seamanExpirationDate,
          seamanFileId: this.crew.seamanDetails.seamanFileId,
          seamanNumber: this.crew.seamanDetails.seamanNumber
        };
        this.oldContactNumber = this.crew.contactNumber;
    });
    }
  }

  uploadSeaManFile(r: DocumentReference) {
    this.crew.seamanDetails.seamanFileId = r.id;
  }

  uploadPassportFile(v: DocumentReference) {
    this.crew.passportDetails.passportFileId = v.id;
  }

  uploadCvFile(v: DocumentReference) {
    this.crew.cvDocumentId = v.id;
  }

  checkChangeDetails() {
    if(!this.crew.oldDetails) {
      this.crew.oldDetails = {
        passport:[],
        seaman: [],
        contactInfo: []
      };
    }
    if(this.crew.seamanDetails.seamanNumber !== this.oldSeamanDetails.seamanNumber) {
      this.crew.oldDetails.seaman.push(this.oldSeamanDetails);
    }
    if(this.crew.passportDetails.passportNumber !== this.oldPassportDetails.passportNumber) {
      this.crew.oldDetails.passport.push(this.oldPassportDetails);
    }
    if(this.crew.contactNumber !== this.oldContactNumber && this.oldContactNumber) {
      this.crew.oldDetails.contactInfo.push(this.oldContactNumber);
    }
  }

  changePassportNumber() {
    this.crew.passportDetails.passportExpirationDate = null;
    this.crew.passportDetails.passportIssueDate = null;
    this.crew.passportDetails.passportFileId = null;
  }

  changeSeamanNumber() {
    this.crew.seamanDetails.seamanExpirationDate = null;
    this.crew.seamanDetails.seamanFileId = null;
  }

  save() {
    const fLowercase = this.crew.firstName.toLowerCase();
    const lLowercase = this.crew.lastName.toLowerCase();
    const passTrim = this.crew.passportDetails.passportNumber.replace(/\s/g,'');
    this.crew.firstName = fLowercase[0].toUpperCase() + fLowercase.slice(1);
    this.crew.lastName = lLowercase[0].toUpperCase() + lLowercase.slice(1);
    this.crew.passportDetails.passportNumber = passTrim;
    this.crew.seamanDetails.seamanNumber = this.crew.seamanDetails.seamanNumber.replace(/\s/g,'');
    this.crew.nationality = this.nationality.name;
    this.crew.actions.push(this._actionService.userAction(ActionType.Update));
    if(!this.crew.passportDetails.passportFileId) {
      alert('Please import passport file');
      return;
    }
    if (!this.crew.seamanDetails.seamanFileId) {
      alert('Please import Seaman file');
      return;
    }
    if(this.edit) {
      this.checkChangeDetails();
        this._crewChangeService.updateCrew(this.crew).subscribe({next: () => {
            this.message = `${this.crew.firstName}`;
            this._pgMessagesService.publishMessage({
              message: this.message,
              topic: 'Updating Crew',
              level: MessageLevel.Info
            });
          }, error: () => {
            this.message = 'Error';
            this._pgMessagesService.publishMessage({
              message: this.message,
              topic: 'Crew Update Error',
              level: MessageLevel.Error
            });
          }
        });
    } else {
      this._crewChangeService.getCrew(this.crew.firstName, this.crew.lastName, this.crew.birthDate)
      .subscribe(x => {
        if (x === null || x.length === 0) {
          this._crewChangeService.saveCrew(this.crew).subscribe({
              next: () => {
                this.message = `${this.crew.firstName}`;
                this._pgMessagesService.publishMessage({
                  message: this.message,
                  topic: 'Added New Crew',
                  level: MessageLevel.Info
                });
              }, error: (error: any) => {
                console
                  .log(error);
                if (error.status && error.status === 409) {
                  this.message = 'Error';
                  this._pgMessagesService.publishMessage({
                    message: error.error,
                    topic: 'Conflict',
                    level: MessageLevel.Error
                  });
                } else {
                  this.message = 'Error';
                  this._pgMessagesService.publishMessage({
                    message: this.message,
                    topic: 'Crew Insertion Error',
                    level: MessageLevel.Error
                  });
                }
              }
            }
          );
        } else if (x.length > 0) {
          if (confirm(`${x.length} persons with the name ${this.crew.firstName} ${this.crew.lastName},\n Passport Numbers ${x.map(r1 => r1.passportDetails.passportNumber).join(", ")} and\n Sea Man Numbers ${x.map(r1 => r1.seamanDetails.seamanNumber).join(", ")} Already exists.\n\nAre you sure you want to add a new one?`)) {
            this._crewChangeService.saveCrew(this.crew).subscribe({next: () => {
                this.message = `${this.crew.firstName}`;
                this._pgMessagesService.publishMessage({
                  message: this.message,
                  topic: 'Added New Crew',
                  level: MessageLevel.Info
                });
              }, error: () => {
                this.message = 'Error';
                this._pgMessagesService.publishMessage({
                  message: this.message,
                  topic: 'Crew Insertion Error',
                  level: MessageLevel.Error
                });
              }
            });
          }
        }
      });
    }

  }

  photoUploaded(docReference: DocumentReference) {
    this.crew.photoDocumentId = docReference.id;
  }

  downLoadDocumnet(id) {
    this._documentService.download(id).subscribe(f => {
      saveAs(f.body, `${id}.pdf`);
    });
  }
}

import {Component, EventEmitter, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {VesselsService} from '../../vessels.service';
import {Subject} from 'rxjs';
import {DataTablesService} from '../../../../shared/datatables/data-tables.service';
import {MessageLevel, PgMessagesService} from '../../../../shared/services/pg-messages.service';
import {DateRange} from '../../../../shared/components';
import * as moment from 'moment';
import {PgTitleComponent} from '../../../../shared/components/title-bar/title-bar.component';
import {NoonReportsService} from '../../services/noon-reports.service';

@Component({
  selector: 'app-import-noon-report',
  templateUrl: './import-noon-report.component.html'
})
export class ImportNoonReportComponent implements OnInit, PgTitleComponent {
  title = new EventEmitter<string>();

  currentFiles: FileList;
  validationResult: string[];
  selectedDate: DateRange;

  dtOptions: any;
  dtTrigger: Subject<any> = new Subject<any>();

  constructor(private _router: Router,
              private _vesselsService: VesselsService,
              private _noonReportService: NoonReportsService,
              private _pgMessagesService: PgMessagesService) {
    this.dtOptions = DataTablesService.getOptionsEn();
    this.selectedDate = {
      startDate: moment().startOf('day').toDate(),
      endDate: moment().startOf('day').toDate(),
    };
  }

  ngOnInit() {
    this.title.emit('Import noon reports');
  }

  uploadDocument() {
    if (this.currentFiles[0].size > 2000000) {
      confirm('The file size should be less than 2MB');
    } else {
      this._noonReportService.importV202005Report(
        moment(this.selectedDate.startDate).hour(12).utc().unix() * 1000, this.currentFiles)
        .subscribe({
          next: () => {
            this._pgMessagesService.publishMessage({
              message: 'Imported a noon report',
              level: MessageLevel.Info,
              topic: 'Noon reports'});
            this.validationResult = [];
          }, error: error => {
            this.validationResult = error.error;
          }
        });
    }
  }

  cancel() {
    this._router.navigate(['vessels/noon-reports']).then();
  }
}

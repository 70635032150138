import { Component, EventEmitter, OnInit } from '@angular/core';
import { TrucksService, MobileTrackerDevice } from 'src/app/transportation/trucks/trucks.service';
import { ActivatedRoute } from '@angular/router';
import { PgMessagesService, MessageLevel } from 'src/app/shared/services/pg-messages.service';
import { forkJoin } from 'rxjs';
import { DateTimeHelpers } from 'src/app/shared/helpers/date-time.helpers';
import { PgTitleComponent } from '../../../../shared/components/title-bar/title-bar.component';

@Component({
  selector: 'app-gps-data-entry',
  templateUrl: './gps-data-entry.component.html',
})
export class GpsDataEntryComponent implements OnInit, PgTitleComponent {
  title = new EventEmitter<string>();
  history: any[];
  selectedTracker: MobileTrackerDevice;
  selectedRegistrationNumber: string;
  mode: string;
  edit: boolean;
  associatedObjectList: any[];
  constructor(private _truckService: TrucksService,
              private _activateRoute: ActivatedRoute,
              private _pgMessagesService: PgMessagesService) { }

  ngOnInit() {
    this.title.emit('Tracker');
    this.selectedTracker = {
      imei: null,
      mobileNumber: null,
      trackerType: null,
    };

    this._activateRoute.paramMap.subscribe(p => {
      this.mode = p.get('mode');
      if (p.get('mode') === 'edit') {
        this.edit = true;
        this.selectedRegistrationNumber = p.get('imei');
        forkJoin([
          this._truckService.getOneTracker(this.selectedRegistrationNumber),
          this._truckService.getTrackerHistory(this.selectedRegistrationNumber)
        ]).subscribe(t => {
            this.selectedTracker = t[0];
            this.history = t[1];
          });
        }
      });
  }
  dateFormatter(timestamp: number) {
    if(timestamp !== 0) {
      return DateTimeHelpers.formatPersianLocalDate(timestamp);
    }
  }

  save() {
    if (this.mode === 'new') {
      this.edit = false;
      this._truckService.addTracker(this.selectedTracker).subscribe(() => {
        this._pgMessagesService.publishMessage({
          message: `Tracker No ${this.selectedTracker.imei} Imported`,
          topic: 'New Tracker',
          level: MessageLevel.Info
        });
      }, () => {
        this._pgMessagesService.publishMessage({
          message: 'Error',
          topic: 'Conflict',
          level: MessageLevel.Error
        });
      });
    } else if (this.mode !== 'new') {
      this.edit = true;
      this._truckService.updateTracker(this.selectedTracker).subscribe(() => {
        this._pgMessagesService.publishMessage({
          message: `Tracker No ${this.selectedTracker.imei} Edit`,
          topic: 'Edit Tracker',
          level: MessageLevel.Info
        });
      }, () => {
        this._pgMessagesService.publishMessage({
          message: 'Error',
          topic: 'Tracker Insertion Error',
          level: MessageLevel.Error
        });
      });
    }
  }
}

<div class="btn-group" role="toolbar" aria-label="Button group with nested dropdown">
  <div class="btn-group" *appHideIfNotInRole="['vessels-crew-change-editor']">
    <button class="btn btn-outline-secondary btn-light dropdown-toggle"
            type="button" id="actionsButtonGroup" data-bs-toggle="dropdown" aria-expanded="false">
      Actions
    </button>
    <ul class="dropdown-menu" aria-labelledby="actionsButtonGroup">
        <li><a class="dropdown-item" (click)="cancelAll()">Cancel All</a></li>
        <li><a class="dropdown-item" (click)="delete()">Delete</a></li>
        <li><a class="dropdown-item" (click)="addContractExtension()" *ngIf="crewChange?.contract">Add contract extension</a></li>
    </ul>
  </div>
</div>

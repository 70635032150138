import { Component } from '@angular/core';
import { Router } from '@angular/router';
import {KeycloakService} from '../../../../authentication/keycloak.service';

@Component({
  selector: 'app-bunker-list-toolbar',
  templateUrl: './bunker-list-toolbar.component.html'
})
export class BunkerListToolbarComponent {

  constructor(private _router: Router,
              private _keycloakService: KeycloakService) {
  }

  addBunker() {
    this._router.navigate(['vessels/voyage/bunker']).then();
  }
}

import * as momentJ from 'moment-jalaali';
import {
  Component, ElementRef, ViewChild, OnInit,
  Output, Input, EventEmitter, forwardRef, Directive,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import * as moment from 'moment';
import {
  AbstractControl, ControlValueAccessor,
  FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR,
  ValidationErrors, Validator
} from '@angular/forms';
declare let $: any;

export interface DateRange {
  startDate: Date;
  endDate: Date;
}

export const dateRangeRequiredValidator = (value: boolean) =>
  (abstractControl: AbstractControl) => {
    const formControl = abstractControl as FormControl;
    if (value === false) {
      return null;
    } else if (formControl.value && formControl.value as DateRange) {
      return null;
    } else {
      return {
        error: 'A date range is not selected correctly'
      };
    }
  };


@Directive({
  selector: '[appDateRangeRequired]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: DateRangeComponentValidatorDirective,
    multi: true
  }]
})
export class DateRangeComponentValidatorDirective implements Validator {

  private _onValidatorChange: () => void;
  private _isRequired: boolean;

  @Input('appDateRangeRequired') set required01(value: boolean) {
    this._isRequired = value;
    if (this._onValidatorChange) {
      this._onValidatorChange();
    }
  }

  registerOnValidatorChange(fn: () => void): void {
    this._onValidatorChange = fn;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return dateRangeRequiredValidator(this._isRequired)(control);
  }
}

@Component({
  selector: 'app-date-range',

  template: `
  <div class="input-group">
    <span class="input-group-text" id="basic-addon1" (click)="changeCalendar()">
      <i class="fa fa-calendar me-1"></i>
      <span *ngIf="_jalali">J</span>
      <span *ngIf="!_jalali">G</span>
    </span>
    <input
      type="text"
      class="form-control"
      placeholder="Date"
      aria-label="Date"
      aria-describedby="basic-addon1"
      #dateRangeComponent
      [disabled]="_disabled"
    >
  </div>
  `,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => DateRangeComponent),
  }]
})
export class DateRangeComponent implements OnInit, ControlValueAccessor, OnChanges {

  @ViewChild('dateRangeComponent', { static: true }) dateRangeElement: ElementRef;
  _dateRange: DateRange = {
    startDate: moment().subtract(30, 'days').toDate(),
    endDate: new Date()
  };
  _singleDateSelector: boolean;
  _timePicker: boolean;
  _dateRangePickerJQuery: any;
  _jalali = false;
  _disabled = false;
  _onChangedCallback: any;
  _onTouchedCallback: any;
  _timezone: string;

  private _localeDateFormat = 'YYYY-MM-DD';
  private _daysOfWeek = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
  private _monthNames = ['January', 'February', 'March', 'April', 'May',
    'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  private PersianmonthNames = ['فروردین', 'اردیبهشت', 'خرداد', 'تیر', 'مرداد',
    'شهریور', 'مهر', 'آبان', 'آذر', 'دی', 'بهمن', 'اسفند'];

  @Input() set dateRange(dr: DateRange) {
    if (dr) {
      this._dateRange = dr;
    } else if (this._singleDateSelector) {
      this._dateRange.startDate = new Date();
    }
  }

  @Output() dateRangeChange = new EventEmitter<DateRange>();

  @Input() set singleDateSelector(value: boolean) {
    this._singleDateSelector = value;
  }

  @Input() set localeDateFormat(value: string) {
    this._localeDateFormat = value;
  }

  @Input() set timePicker(value: boolean) {
    this._timePicker = value;
  }

  @Input() set disabled(value: boolean) {
    this._disabled = value;
  }

  @Input() set jalali(value: boolean) {
    this._jalali = value;
  }

  @Input() set timezone(value: boolean) {
    this._jalali = value;
  }

  @Output() jalaliChange = new EventEmitter<boolean>();


  ngOnInit(): void {
    this.selectedCalendar();
  }

  changeCalendar() {
    this._jalali = !this._jalali;
    this.jalaliChange.emit(this._jalali);
    this.selectedCalendar();

  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['dateRange'] && this._dateRange) {
      this.selectedCalendar();
    }
  }

  selectedCalendar() {
    this._dateRangePickerJQuery = $(this.dateRangeElement.nativeElement);
    if(!this._jalali) {
      this._dateRangePickerJQuery.daterangepicker(
        this.setDate(this._monthNames, this._jalali).b,
        this.setDate(this._monthNames, this._jalali).t,
      );
    } else {
      if (!this._disabled) {
        this._dateRangePickerJQuery = $(this.dateRangeElement.nativeElement);
        this._dateRangePickerJQuery.persiandaterangepicker(
          this.setDate(this.PersianmonthNames, this._jalali).b,
          this.setDate(this.PersianmonthNames, this._jalali).t,
        );
      } else {
        $(this.dateRangeElement.nativeElement).attr('disabled', 'disabled');
        $(this.dateRangeElement.nativeElement).val(momentJ(this._dateRange.startDate).format('jYYYY/jMM/jDD'));
      }
    }
  }

  registerOnChange(fn: any): void {
    this._onChangedCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouchedCallback = fn;
  }

  setDate(mounth: string[], jalali: boolean) {
    const t = (start: any, end: any) => {
      const result = {
        startDate: new Date(start),
        endDate: new Date(end),
      };
      this.dateRangeChange.emit(result);


      if (this._onChangedCallback) {
        this._onChangedCallback(result);
      }
    };
    const b = {
      locale: {
        ...(!jalali  && {
          format: this._localeDateFormat,
          daysOfWeek: this._daysOfWeek,
        }),
        monthNames: mounth
      },
      startDate: this._dateRange.startDate,
      endDate: this._dateRange.endDate,
      singleDatePicker: this._singleDateSelector,
      timePicker: this._timePicker,
      timePicker24Hour: true,
      ...(!this._singleDateSelector && {
        ranges: this.rangesChange(jalali).ranges
      })
    };
    return { b, t };
  }

  rangesChange(jalali: boolean) {
    if (!this._singleDateSelector) {
      if (!jalali) {
        return {
          ranges: {
            'Today': [moment(), moment()],
            'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
            'Last 30 Days': [moment().subtract(29, 'days'), moment()],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            'This Year': [moment().startOf('year'), moment().endOf('month')],
          },
        };
      } else {
        return {
          ranges: {
            'امروز': [momentJ(), momentJ()],
            'دیروز': [momentJ().subtract(1, 'days'), momentJ().subtract(1, 'days')],
            'هفت روز گدشته': [momentJ().subtract(6, 'days'), momentJ()],
            'سی روز گذشته': [momentJ().subtract(29, 'days'), momentJ()],
            'این ماه': [momentJ().startOf('jMonth'), momentJ().endOf('jMonth')],
            'ماه گذشته': [momentJ().subtract(1, 'jMonth').startOf('jMonth'), momentJ().subtract(1, 'jMonth').endOf('jMonth')],
            'امسال': [momentJ().startOf('jyear'), momentJ().endOf('jMonth')],

          },
        };
      }
    }
  }

  writeValue(obj: any): void {
    const value = obj as DateRange;
    if (obj) {
      this.dateRange = value;
    }
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { OrganisationRoutingModule } from './organisation-routing.module';
import { NewOrganisationComponent } from './new-organisation/new-organisation.component';
import { FormsModule } from '@angular/forms';
import { OrganisationsListComponent } from './organisations-list/organisations-list.component';
import { DataTablesModule } from 'angular-datatables';
import { OrganisationIdToEnNamePipe } from './organisation-id-to-name.pipe';
import { AuthenticationModule } from '../authentication/authentication.module';


@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    FormsModule,
    OrganisationRoutingModule,
    DataTablesModule,
    AuthenticationModule
  ],
  exports: [
    OrganisationIdToEnNamePipe,
  ],
  declarations: [ NewOrganisationComponent, OrganisationsListComponent, OrganisationIdToEnNamePipe ]
})
export class OrganisationModule { }

import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import { KeycloakService } from './keycloak.service';

@Directive({
  selector: '[appHideIfNotInRole]'
})
export class HideIfNotInRoleDirective {

  @Input('appHideIfNotInRole') set permittedRoles(roles: string[]) {
    if (roles && !roles.some(s => this._keycloakService.hasRole(s))) {
      this._viewContainerRef.clear();
    } else {
      this._viewContainerRef.createEmbeddedView(this._templateRef);
    }
  }

  constructor(private _templateRef: TemplateRef<any>,
              private _viewContainerRef: ViewContainerRef,
              private _keycloakService: KeycloakService) { }
}

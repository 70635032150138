import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { GPSTrackerCoordinate } from '../trucks.service';
import { GeoCoordinate } from '../../models/GeoCoordinate';

export interface MapLabel {
  fontFamily: string;
  text: string;
  color: string;
  fontSize?: string;
}

export interface MapMarker {
  geoCoordinate: GeoCoordinate;
  infoWindowContent?: string;
  imagePath?: string;
  id: any;
  rotation: number;
  label?: MapLabel;
  color?: string;
  popUpIsOpen?: boolean;
}

@Injectable()
export class MapProviderService {

  //  _distanceMatrix: google.maps.DistanceMatrixService;

  _markersUpdatedSource = new Subject<MapMarker>();
  _infowindowOpen = new Subject<boolean>();
  _clusterMarkersSource = new Subject<boolean>();
  _markersResetSource = new Subject<any>();
  _markerSelectedSource = new Subject<MapMarker>();
  _openStreetMarkerSelectedSource = new Subject<MapMarker[]>();

  _pathUpdatedSource = new Subject<GPSTrackerCoordinate[]>();
  _mapCenterUpdateSource = new Subject<GeoCoordinate>();
  _mapCenterWithZoomUpdateSource = new Subject<GeoCoordinate>();
  _fitMapBound = new Subject <GeoCoordinate[]>();
  _initMap = new Subject<boolean>();

  _mapHeatSource = new Subject<GeoCoordinate[]>();

  initMap$ = this._initMap.asObservable();
  markersUpdated$ = this._markersUpdatedSource.asObservable();
  openStreetmarkersUpdated$ = this._openStreetMarkerSelectedSource.asObservable();

  infowindowOpen$ = this._infowindowOpen.asObservable();
  markerSelected$ = this._markerSelectedSource.asObservable();
  clusterMarkersRequested = this._clusterMarkersSource.asObservable();
  pathUpdated$ = this._pathUpdatedSource.asObservable();
  mapCenterUpdated$ = this._mapCenterUpdateSource.asObservable();
  mapCenterWithZoomUpdated$ = this._mapCenterWithZoomUpdateSource.asObservable();
  markersReset$ = this._markersResetSource.asObservable();
  fitMapBound$ = this._fitMapBound.asObservable();

  heatMapSourceUpdate$ = this._mapHeatSource.asObservable();

  constructor() {
    // this._distanceMatrix = new google.maps.DistanceMatrixService();
   }

  initMap() {
    this._initMap.next(true);
  }

  addOrUpdateMarker(marker: MapMarker) {
    this._markersUpdatedSource.next(marker);
  }

  addOrUpdateMarkers(markers: MapMarker[]) {
    this._openStreetMarkerSelectedSource.next(markers);
  }

  clusterMarkers(cluster: boolean) {
    this._clusterMarkersSource.next(cluster);
  }

  markerSelected(mapMarker: MapMarker) {
    this._markerSelectedSource.next(mapMarker);
  }

  updatePath(coordinates: GPSTrackerCoordinate[]) {
    this._pathUpdatedSource.next(coordinates);
  }

  updateHeatMap(coordinates: GeoCoordinate[]) {
    this._mapHeatSource.next(coordinates);
  }

  setMapCentre(geoCoordinate: GeoCoordinate) {
    this._mapCenterUpdateSource.next(geoCoordinate);
  }

  setMapCentreWithZoom(geoCoordinate: GeoCoordinate) {
    this._mapCenterWithZoomUpdateSource.next(geoCoordinate);
  }

  resetMarkers() {
    this._markersResetSource.next(null);
  }

  fitMapBound(coordinates: GeoCoordinate[]) {
    this._fitMapBound.next(coordinates);
  }


  // findDistance(origin: LatLng[], destination: LatLng[]) {
  //   const request = {
  //     origins: origin,
  //     destinations: destination,
  //     travelMode: google.maps.TravelMode.DRIVING,
  //     unitSystem: google.maps.UnitSystem.METRIC,
  //   };
  //   const f = bindCallback(this._distanceMatrix.getDistanceMatrix);
  //   const result = f(request);
  //   const m = map((response: any, status: any) => {
  //     if (response[1] === 'OK') {
  //       return response;
  //     } else {
  //       return null;
  //     }
  //   });
  //   return result.pipe(m);
  // }
}

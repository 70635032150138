import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-vessel-toolbar',
  templateUrl: './vessel-toolbar.component.html',
})
export class VesselToolbarComponent {

  constructor(private _router: Router) { }

  navigateToInternalAuditPage() {
    console.log(`Navigating to internal audit page`);
    this._router.navigate(['vessel/internal-audit']).then(() => {
      console.log(`Navigated`);
    });
  }
}

<div class="row">
  <div class="col-md-6">
    <div class="card card-body">
      <form class="form-horizontal" (submit)="uploadDocument()">
        <div class="form-group row">
          <label for="publisher" class="col-form-label col-md-2 ">Publisher</label>
          <div class="col-md-8">
            <select class="form-control" id="publisher" name="publisher" [(ngModel)]="selectedPublisher">
              <option></option>
              <option *ngFor="let publisher of publishers" [ngValue]="publisher">
                {{publisher.publisher}}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group row">
          <label for="publicationName" class="col-form-label col-md-2 ">Name</label>
          <div class="col-md-8">
            <select [disabled]="!selectedPublisher" class="form-control" id="publicationName" name="publicationName"
                    [(ngModel)]="selectedPublication"
            >
              <option></option>
              <option *ngFor="let publication of selectedPublisher?.publications" [ngValue]="publication">
                {{publication?.publication}}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group row">
          <label for="publicationDate" class="col-form-label col-md-2 ">Date</label>
          <div class="col-md-8">
            <input type="date" [disabled]="!selectedPublication"
                   class="form-control"
                   id="publicationDate" name="publicationDate"
            [(ngModel)]="selectedDate"/>
          </div>
        </div>
        <div class="form-group row">
          <label for="priceFile" class="col-form-label col-md-2 ">File</label>
          <div class="col-md-8">
            <app-file-uploader
              [disabled]="!selectedDate"
              [accept]="'.pdf,'"
              name="priceFile"
              id="priceFile"
              [(ngModel)]="currentFiles">
            </app-file-uploader>
          </div>
        </div>
        <div class="form-group pull-right">
          <label for="uploadDocument" class="col-form-label col-md-4"></label>
          <div class="col-md-8">
            <input type="submit" id="uploadDocument" class="btn btn-primary" value="Upload" [disabled]="!currentFiles">
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

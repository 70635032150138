<div class="btn-group" role="toolbar" aria-label="Button group with nested dropdown">
  <div class="btn-group me-1" *appHideIfNotInRole="['vessels-crew-change-editor']">
    <button class="btn btn-outline-secondary btn-light dropdown-toggle"
            type="button" id="addButtonGroup" data-bs-toggle="dropdown" aria-expanded="false">
      Actions
    </button>
    <ul class="dropdown-menu" aria-labelledby="addButtonGroup">
      <li><a class="dropdown-item" (click)="editCrew()">Edit Crew</a></li>
      <li><a class="dropdown-item" *ngIf="!onBoard()" (click)="addCrewChange()">New Crew Change</a></li>
      <li><a class="dropdown-item" *ngIf="crew && crew.crewChanges.length > 0" (click)="addAppraisal()">Appraisal</a></li>
    </ul>
  </div>
</div>
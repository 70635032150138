<div class="row">
  <div class="col-md-12 col-xs-12 col-sm-12">
    <div class="card">
      <form class="form-horizontal">
        <div class="card-body">
          <div class="row">
            <div class="col-md-3 col-sm-12">
              <app-date-range id="selectedDateRangeGregorian"
                name="selectedDateRangeGregorian"
                [singleDateSelector]="false"
                [(dateRange)]="dateRange">
              </app-date-range>
            </div>
            <div class="col-md-9 col-sm-12">
              <div class="btn-group pull-right">
                <button type="button"
                  [routerLink]="['/vessels/voyages/']"
                  [queryParams]="{startTime: dateRange?.startDate.getTime(),
                        endTime: dateRange?.endDate.getTime()}"
                  class="btn btn-outline-secondary btn-light me-1">
                  Voyages</button>
                <button type="button"
                  [routerLink]="['/vessels/navigation-history/', dateRange?.startDate.getTime(), dateRange?.endDate.getTime()]"
                  class="btn btn-outline-secondary btn-light me-1">
                  Navigation history</button>
                <button type="button"
                  [routerLink]="['/vessels/bunker/', dateRange?.startDate.getTime(), dateRange?.endDate.getTime()]"
                  class="btn btn-outline-secondary btn-light me-1">
                  Bunker</button>
                <button type="button"
                  [routerLink]="['/vessels/noon-reports/']"
                  [queryParams]="{startTime: dateRange?.startDate.getTime(),
                          endTime: dateRange?.endDate.getTime()}"
                  class="btn btn-outline-secondary btn-light me-1">
                  Noon Reports</button>
                <button type="button"
                  [routerLink]="['/vessels/noon-reports-v202005/']"
                  [queryParams]="{startTime: dateRange?.startDate.getTime(),
                          endTime: dateRange?.endDate.getTime()}"
                  class="btn btn-outline-secondary btn-light me-1">
                  Noon Reports V202005</button>
                <button type="button"
                  [routerLink]="['/vessels/noon-reports-v202205/', dateRange?.startDate.getTime(), dateRange?.endDate.getTime()]"
                  class="btn btn-outline-secondary btn-light me-1">
                  Noon Reports V202205</button>
                <button type="button"
                  [routerLink]="['/vessels/reports/']"
                  [queryParams]="{startTime: dateRange?.startDate.getTime(),
                          endTime: dateRange?.endDate.getTime()}"
                  class="btn btn-outline-secondary btn-light me-1">
                  Port Dues Report</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

import { Route } from '@angular/router';
import { ContractComponent } from './contract.component';
import { ContractDataEntryComponent } from './data-entry/contract-data-entry.component';
import { ContractDetailComponent } from './contract-detail/contract-detail.component';
import { ContractsBalanceComponent } from 'src/app/contract/contracts-balance/contracts-balance.component';
import { BuyerContractBalanceComponent } from 'src/app/contract/buyer-contract-balance/buyer-contract-balance.component';

export const contractRoutes: Route[] = [
  { path: 'contracts', component: ContractComponent },
  { path: 'contracts/balance/:loadingLocation', component: ContractsBalanceComponent },
  { path: 'contracts/buyer/:buyerId', component: BuyerContractBalanceComponent },
  { path: 'contracts/entry/:mode', component: ContractDataEntryComponent },
  { path: 'contracts/entry/:mode/:contractNo', component: ContractDataEntryComponent },
  { path: 'contracts/detail/:startDate/:endDate', component: ContractDetailComponent },
];



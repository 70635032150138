import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpWrapper } from '../shared/services';

interface PortalNews {
    title: string;
    content: string;
    date: Date;

    author: string;
}

@Injectable()
export class PortalNewsService {

    constructor(private _httpWrapper: HttpWrapper) {}

    get(): Observable<PortalNews[]> {
        return this._httpWrapper.get<PortalNews[]>('news');
    }
}

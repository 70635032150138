
<div class="row">
  <div class="row col-md-12">
    <div class="col-md-6">
      <div class="card">
        <div class="card-body">
          <h4>All Sof Items</h4>
          <table datatable
            [dtOptions]="allSofItemsDtOptions"
            [dtTrigger]="dtTrigger"
            class="table table-sm table-striped">
            <thead>
              <tr>
                <th>Code</th>
                <th>Description</th>
                <th>Canonical description</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let i of allSofItems">
                <td>{{i.code}}</td>
                <td>{{i.event}}</td>
                <td>{{i.canonical}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="card">
        <div class="card-body ">
          <h4>Sof Templates</h4>
          <table datatable
            [dtOptions]="sofTemplatesDtOptions"
            [dtTrigger]="dtTrigger"
            class="table table-sm table-striped table-bordered">
            <thead>
              <tr>
                <th></th>
                <th>Id</th>
                <th>Description</th>
                <th>Sof Type</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let i of allSofTemplates">
                <td><a [routerLink]="['/vessels/sof/sof-template', i.id]"><i
                      class="fa fa-edit"></i></a></td>
                <td>{{i.id}}</td>
                <td>{{i.description}}</td>
                <td>{{i.sofType}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import {
  Organisation,
  OrganisationService,
} from 'src/app/organisation/organisation.service';
import { Subscription } from 'rxjs';
import { DateRange } from 'src/app/shared/components';

@Component({
  selector: 'app-correspondence-toolbar',
  templateUrl: './correspondence-toolbar.component.html',
})
export class CorrespondenceToolbarComponent implements OnInit, OnDestroy {
  @Output()
  incomingItem: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  dateRangeItem: EventEmitter<DateRange> = new EventEmitter<DateRange>();

  @Output()
  organisation: EventEmitter<Organisation> = new EventEmitter<Organisation>();

  @Output()
  showList: EventEmitter<boolean> = new EventEmitter<boolean>();

  visibleOrganisation = true;
  organisations: Organisation[];
  dateRange = {
    startDate: new Date(),
    endDate: new Date(),
  };
  incoming: boolean;
  selectedOrganisation: Organisation;
  jalali: boolean;

  private eventsSubscription: Subscription;

  constructor(private _organisationService: OrganisationService) {}

  ngOnDestroy() {
    if (this.eventsSubscription) {
      this.eventsSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this._organisationService.get().subscribe((o) => {
      this.organisations = o.filter(
        (a) => a.properties['correspondent'] === true
      );
    });
  }

  emitOrganisation(organisation) {
    this.selectedOrganisation = organisation;
    if (this.selectedOrganisation) {
      this.organisation.emit(this.selectedOrganisation);
    }
  }

  showAll() {
    this.showList.emit(true);
  }

  inputOutput(e: string) {
    if (e === 'input') {
      this.incoming = true;
    } else if (e === 'output') {
      this.incoming = false;
    }
    this.incomingItem.emit(this.incoming);
  }

  selectedDateRangeChanged(e: any) {
    this.dateRange = {
      startDate: e.startDate,
      endDate: e.endDate,
    };
    this.dateRangeItem.emit(this.dateRange);
  }
}

import {Component, EventEmitter, OnInit} from '@angular/core';
import { Router} from '@angular/router';
import { DateRange } from '../shared/components';
import * as moment from 'moment';
import { MarketService } from './market.service';
import { PgTitleComponent } from '../shared/components/title-bar/title-bar.component';

@Component({
  selector: 'app-market',
  templateUrl: './market.component.html',
})
export class MarketComponent implements OnInit, PgTitleComponent {
  title = new EventEmitter<string>();
  dateRange: DateRange;
  publishers: string[];
  selectedProvider: string;

  constructor(private _router: Router,
              private _marketService: MarketService) {
    this.dateRange = {
      startDate: moment().add(-1, 'year').startOf('year').toDate(),
      endDate: moment().endOf('month').toDate()
    };
  }

  async ngOnInit() {
    this.title.emit('Select price report');
    this._marketService.getProviders().subscribe(results => {
      this.publishers = results.map(r => r.providerName);
    });
  }

  pricingList() {
    this._router.navigate([`pricing/${this.selectedProvider}`,
                            this.dateRange.startDate.getTime(),
                            this.dateRange.endDate.getTime()]).then(_ => _);
  }

  pricingPlotAll() {
    this._router.navigate([`pricing/report`,
                            this.dateRange.startDate.getTime(),
                            this.dateRange.endDate.getTime()]).then(e => console.log(`result of navigation is ${e}`));
  }

  marketFiles() {
    this._router.navigate([`pricing/files`,
    this.selectedProvider,
    this.dateRange.startDate.getTime(),
    this.dateRange.endDate.getTime()]).then(_ => _);
  }
}

import { Component, OnInit  } from '@angular/core';
import { DateRange } from '../shared/components';
import * as momentJ from 'moment-jalaali';
import { ContractService, Contract } from './contract.service';
import { Router } from '@angular/router';
import { Organisation, OrganisationService } from '../organisation/organisation.service';

@Component({
  selector: 'app-contract',
  templateUrl: './contract.component.html',
})
export class ContractComponent implements  OnInit {

  dateRange: DateRange;
  startDate: Date;
  endDate: Date;
  contracts: Contract[];
  selectedCalendar = 'jalali';
  loadingLocations: Organisation[];
  source: Organisation;
  buyer: Organisation;
  buyers: Organisation[];


  constructor(
    private _contractService: ContractService,
    private _router: Router,
    private _organisationService: OrganisationService
  ) { }

  ngOnInit() {
    this.dateRange = {
      startDate: momentJ().startOf('month').toDate(),
      endDate: momentJ().endOf('month').toDate()
    };
    this._organisationService.get().subscribe(organisations => {
      this.loadingLocations = organisations.filter(o => (o.properties['isLoading'] === true));
      this.buyers = organisations.filter(o => (o.properties['isBuyer'] === true));
    });
  }

  organisationValueFormatter(data: Organisation) {
    return data.nameTranslations['en'];
  }

  onSubmit() {
      this._router.navigate(['contracts/detail', this.dateRange.startDate.getTime(), this.dateRange.endDate.getTime()]);
  }

  getBalances() {
    this._router.navigate(['contracts/balance', this.source.name]);
  }

  getBuyerBalances() {
    this._router.navigate(['contracts/buyer/', this.buyer.name]);
  }

}


  <div class="col-md-6">
    <div class="card card-body">
    <form #digitalEntryForm="ngForm"
          class="form-horizontal" >
      <div class="form-group row">
        <label for="portalUserName" class="col-form-label col-md-4 ">User Id</label>
        <div class="col-md-8">
          <input
            [(ngModel)]="digitalId.portalUserName"
            class="form-control"
            [disabled]="mode ==='edit'"
            id="portalUserName"
            name="portalUserName"
            required
            type="text">
        </div>
      </div>
      <div class="form-group row">
        <label for="portalUserName" class="col-form-label col-md-4 ">Digital Ids</label>
        <div class="col-md-8">
          <ul>
            <li class="col-md-12" *ngFor="let digitalIdFile of digitalId.digitalIdFiles">
           <div class="form-group row">
              <a class="col-md-8" (click)="downloadFile(digitalIdFile.fileId, digitalIdFile.email)">{{digitalIdFile.email}}</a>             
              <button class="btn btn-danger btn-sm pull-right col-md-4" 
                      (click)="deleteDigitalId(digitalIdFile)" 
                      *appHideIfNotInRole="['digital-ids-editor']" >Delete</button>
            </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="form-group " *appHideIfNotInRole="['digital-ids-editor']">
        <!-- <div class="col-md-4"> -->
          <!-- <div class="col-md-8"> -->
            <input type="button" name="save" value="Save"
                  class="btn btn-primary"
                  (click)="onAddNewId()"
                  [disabled]="!digitalEntryForm.form.valid"
                  *ngIf="mode==='new'">
            <input type="button"
                  name="addNewDigitalId"
                  class="btn btn-primary"
                  *ngIf="mode==='edit'"
                  value="Add digital id"
                  (click)="showAddNewDigitalIdForm()">
          <!-- </div> -->
        <!-- </div> -->
      </div>
    </form>
  
  </div>
</div>
  <div class="col-md-6">
    <form #digitalIdUploadForm="ngForm"
          *ngIf="showAddNewDigitalId"
          class="form-horizontal">
          <div class="card card-body">
      <div class="form-group row">
        <label for="portalUserName" class="col-form-label col-md-4 ">Email</label>
        <div class="col-md-8">
          <input
            [(ngModel)]="selectedDigitalIdFile.email"
            class="form-control"
            id="email"
            name="email"
            required
            type="email">
        </div>
      </div>
      <div class="form-group row">
        <label for="licenseNumber" class="col-form-label col-md-4 ">File</label>
        <app-chunk-file-uploader 
          class="col-md-8" 
          [accept]="'.p12,.crt'"
          name="uploadedCertificate"
          (filesChanged)="addNewDigitalIdFile($event)" 
          [required] ="true">
        </app-chunk-file-uploader>
      </div>
    </div>
    </form>
</div>


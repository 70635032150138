<div class="btn-group pull-right" role="group" aria-label="Button group with nested dropdown">
  <ng-container *appHideIfNotInRole="['vessels-delivery-note-editor']">
    <button class="btn btn-outline-secondary btn-light dropdown-toggle"
            type="button" id="addButtonGroup" data-bs-toggle="dropdown" aria-expanded="false">
      Add
    </button>
    <ul class="dropdown-menu" aria-labelledby="addButtonGroup">
      <li><a class="dropdown-item" (click)="addBunker()">Bunker</a></li>
    </ul>
  </ng-container>
</div>



<form #norUpload="ngForm"
  class="form-horizontal"
  (ngSubmit)="saveNor()">
  <div class="form-group row">
    <label for="selectedDate"
      class="col-form-label col-md-4 ">Date & time</label>
    <div class="col-md-8">
      <app-date-range
        id="selectedDate"
        name="selectedDate"
        [singleDateSelector]="true"
        [disabled]="previewMode"
        [(dateRange)]="_selectedDate"
        [timePicker]="true"
        required
        [localeDateFormat]="'YYYY-MM-DD HH:mm'">
      </app-date-range>
    </div>
  </div>
  <div class="form-group row">
    <label for="port2"
      class="col-form-label col-md-4 ">port</label>
    <div class="col-md-8">
      <app-organisation-selector
        class="pg-component"
        [(organisation)]="port"
        [organisationLanguage]="'en'"
        name="port2"
        [organisations]="portOrganisations"
        [disabled]="previewMode"
        required>
      </app-organisation-selector>
    </div>
  </div>
  <div class="form-group row">
    <label for="loadingDischarging"
      class="col-form-label col-md-4 ">NOR type</label>
    <div class="col-md-8">
      <select id="loadingDischarging"
        name="selectedDocumentType"
        class="form-control"
        [disabled]="previewMode"
        [(ngModel)]="_selectedNor.norType"
        required>
        <option *ngFor="let norType of selectedNorTypes ">
          {{norType}}
        </option>
      </select>
    </div>
  </div>

  <div class="form-group col-md-12">
    <label for="submit" class="col-form-label col-md-4"></label>
    <div class="col-md-12" style="padding:0">
      <div class="btn-group pull-right">
        <ng-container *ngIf="isVerified && hasRole('admin-editor')">
          <button type="button" id="removeVerify"
            class="btn btn-outline-secondary btn-light  me-1"
            (click)="removeVerification()"
            [disabled]="!isVerified">Remove verification
          </button>
        </ng-container>
        <ng-container *ngIf="!isVerified && hasRole('voyage-verifier')">
          <button type="button" id="verify"
            class="btn btn-outline-secondary btn-light  me-1" (click)="verify()"
            [disabled]="isVerified">Verify
          </button>
        </ng-container>
        <ng-container *ngIf="hasRole('vessels-voyages-editor')">
          <button type="button" id="delete"
            class="btn btn-outline-secondary btn-light  me-1"
            (click)="deleteNor()"
            [disabled]="isVerified">Delete
          </button>
        </ng-container>
        <!--        <button type="button"-->
        <!--                id="cancel"-->
        <!--                class="btn btn-outline-secondary btn-light"-->
        <!--                (click)="cancelled.emit()">Back to voyage-->
        <!--        </button>-->
        <ng-container *ngIf="!previewMode">
          <button type="submit"
            id="submit"
            class="btn btn-primary"
            [disabled]="!norUpload.form.valid || !_selectedDate">Save
          </button>
        </ng-container>
      </div>
    </div>
  </div>
</form>

<div class="row">
  <div class="row" *ngIf="vesselsConsumptions">
    <div class="col-lg-3">
      <div class="card shadow-sm card-body bg-info info-box">
        <div class="inner">
          <h4>{{vesselsConsumptions.distanceCovered}} NMs in {{duration()}}
            days</h4>
          <p>Distance Covered</p>
          <h4>{{average()}} NMs per day</h4>
          <p>Average</p>
        </div>
      </div>
    </div>
    <div class="col-lg-3">
      <div class="card shadow-sm card-body bg-info info-box">
        <div class="inner">
          <h4 *ngFor="let c of vesselsConsumptions.sumConsumptions">
            {{mapNoonReports(c.id)}}: {{c.sum}}
          </h4>
          <p> Sum of Bunker consumptions (ton)</p>
        </div>
      </div>
    </div>
    <div class="col-lg-3">
      <div class="card shadow-sm card-body bg-info info-box">
        <div class="inner">
          <span *ngIf="vesselsConsumptions.balances > 0">
            <h4 *ngFor="let c of vesselsConsumptions.balances">
              {{mapNoonReports(c.id)}}: {{c.value}}
            </h4>
          </span>
          <p>Non zero Balances Of Bunker consumptions</p>
        </div>
      </div>
    </div>
    <div class="col-lg-3">
      <div class="card shadow-sm card-body bg-info info-box">
        <div class="inner">
          <h4 *ngFor="let c of vesselsConsumptions.sumConsumptions">
            {{consumptionsToDistanceRatio(vesselsConsumptions.sumConsumptions,
            vesselsConsumptions.distanceCovered)}} kg
          </h4>
          <p>Consumption per nautical mile</p>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-3" *ngIf="dataExists[0]">
      <div class="card ">
        <div id="0"></div>
      </div>
    </div>
    <div class="col-md-3" *ngIf="dataExists[1]">
      <div class="card ">
        <div id="1"></div>
      </div>
    </div>
    <div class="col-md-3" *ngIf="dataExists[2]">
      <div class="card ">
        <div id="2"></div>
      </div>
    </div>
    <div class="col-md-3" *ngIf="dataExists[3]">
      <div class="card ">
        <div id="3"></div>
      </div>
    </div>
    <div class="col-md-3" *ngIf="dataExists[4]">
      <div class="card ">
        <div id="4"></div>
      </div>
    </div>
    <div class="col-md-3" *ngIf="dataExists[5]">
      <div class="card ">
        <div id="5"></div>
      </div>
    </div>
    <div class="col-md-3" *ngIf="dataExists[6]">
      <div class="card ">
        <div id="6"></div>
      </div>
    </div>
    <div class="col-md-3" *ngIf="dataExists[7]">
      <div class="card ">
        <div id="7"></div>
      </div>
    </div>
    <!-- {{plotPerProduct(cons.series, cons.divId, cons.series[0].name)}} -->
  </div>
  <div class="row" *ngIf="vesselsConsumptions">
    <div class="col-md-12">
      <div class="card ">
        <div class="card-header">
          <div class="col-md-12 bg-secondary"
            style="text-align: center;"><h2>{{selectedVessel.name.name}} Bunker</h2></div>
        </div>
        <div class="card-body">
          <table style="text-align: center"
            class="table table-sm table-striped   table-bordered ">
            <thead>
              <tr>
                <td>Date</td>
                <td>Type</td>
                <td colspan="6">MGO</td>
                <td colspan="6">LSMGO</td>
                <td colspan="6">HFO</td>
                <td colspan="6">LSFO</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Pre ROB</td>
                <td>Received</td>
                <td>Cons</td>
                <td>ROB</td>
                <td>Sum</td>
                <td>Diff</td>
                <td>Pre ROB</td>
                <td>Received</td>
                <td>Cons</td>
                <td>ROB</td>
                <td>Sum</td>
                <td>Diff</td>
                <td>Pre ROB</td>
                <td>Received</td>
                <td>Cons</td>
                <td>ROB</td>
                <td>Sum</td>
                <td>Diff</td>
                <td>Pre ROB</td>
                <td>Received</td>
                <td>Cons</td>
                <td>ROB</td>
                <td>Sum</td>
                <td>Diff</td>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let cons of vesselsConsumptions.analysis">
                <td>{{cons.date }}</td>
                <td>{{cons.reportType }}</td>
                <td>{{cons.consumptions["mgo"].previousRob}}</td>
                <td>{{cons.consumptions["mgo"].lastReceived}}</td>
                <td>{{cons.consumptions["mgo"].consumption}}</td>
                <td>{{cons.consumptions["mgo"].rob}}</td>
                <td>{{cons.consumptions["mgo"].sum}}</td>
                <td
                  [ngClass]="{'bg-warning': cons.consumptions['mgo'].diff > .05 || cons.consumptions['mgo'].diff < -.05}">{{cons.consumptions["mgo"].diff}}</td>
                <td>{{cons.consumptions["lsmgo"].previousRob}}</td>
                <td>{{cons.consumptions["lsmgo"].lastReceived}}</td>
                <td>{{cons.consumptions["lsmgo"].consumption}}</td>
                <td>{{cons.consumptions["lsmgo"].rob}}</td>
                <td>{{cons.consumptions["lsmgo"].sum}}</td>
                <td
                  [ngClass]="{'bg-warning': cons.consumptions['lsmgo'].diff > .05 || cons.consumptions['lsmgo'].diff < -.05}">{{cons.consumptions["lsmgo"].diff}}</td>
                <td>{{cons.consumptions["hfo"].previousRob}}</td>
                <td>{{cons.consumptions["hfo"].lastReceived}}</td>
                <td>{{cons.consumptions["hfo"].consumption}}</td>
                <td>{{cons.consumptions["hfo"].rob}}</td>
                <td>{{cons.consumptions["hfo"].sum}}</td>
                <td
                  [ngClass]="{'bg-warning': cons.consumptions['hfo'].diff > .05 || cons.consumptions['hfo'].diff < -.05}">{{cons.consumptions["hfo"].diff}}</td>
                <td>{{cons.consumptions["lsfo"].previousRob}}</td>
                <td>{{cons.consumptions["lsfo"].lastReceived}}</td>
                <td>{{cons.consumptions["lsfo"].consumption}}</td>
                <td>{{cons.consumptions["lsfo"].rob}}</td>
                <td>{{cons.consumptions["lsfo"].sum}}</td>
                <td
                  [ngClass]="{'bg-warning': cons.consumptions['lsfo'].diff > .05 || cons.consumptions['lsfo'].diff < -.05}">{{cons.consumptions["lsfo"].diff}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="vesselsConsumptions">
    <div class="col-md-12">
      <div class="card ">
        <div class="card-header">
          <div class="col-md-12 bg-secondary"
            style="text-align: center;"><h2>{{selectedVessel.name.name}} Oil</h2></div>
        </div>
        <div class="card-body">
          <table style="text-align: center"
            class="table table-sm table-striped   table-bordered ">
            <thead>
              <tr>
                <td>Date</td>
                <td>Type</td>
                <td colspan="5">Auxiliary System Oil</td>
                <td colspan="5">Main Engine Cylinder Oil</td>
                <td colspan="5">Main Engine System Oil</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Received</td>
                <td>Cons</td>
                <td>ROB</td>
                <td>Sum</td>
                <td>Diff</td>
                <td>Received</td>
                <td>Cons</td>
                <td>ROB</td>
                <td>Sum</td>
                <td>Diff</td>
                <td>Received</td>
                <td>Cons</td>
                <td>ROB</td>
                <td>Sum</td>
                <td>Diff</td>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let cons of vesselsConsumptions.analysis">
                <td>{{cons.date }}</td>
                <td>{{cons.reportType }}</td>
                <td>{{cons.oilConsumptions.auxiliarySystemOil.lastReceivedQt}}</td>
                <td>{{cons.oilConsumptions.auxiliarySystemOil.consumption}}</td>
                <td>{{cons.oilConsumptions.auxiliarySystemOil.rob}}</td>
                <td>{{cons.oilConsumptions.auxiliarySystemOil.sum}}</td>
                <td
                  [ngClass]="{'bg-warning': cons.oilConsumptions.auxiliarySystemOil.diff > .05 || cons.oilConsumptions.auxiliarySystemOil.diff < -.05}">{{cons.oilConsumptions.auxiliarySystemOil.diff}}</td>
                <td>{{cons.oilConsumptions.mainEngineCylinderOil.lastReceivedQt}}</td>
                <td>{{cons.oilConsumptions.mainEngineCylinderOil.consumption}}</td>
                <td>{{cons.oilConsumptions.mainEngineCylinderOil.rob}}</td>
                <td>{{cons.oilConsumptions.mainEngineCylinderOil.sum}}</td>
                <td
                  [ngClass]="{'bg-warning': cons.oilConsumptions.mainEngineCylinderOil.diff > .05 || cons.oilConsumptions.mainEngineCylinderOil.diff < -.05}">{{cons.oilConsumptions.mainEngineCylinderOil.diff}}</td>
                <td>{{cons.oilConsumptions.mainEngineSystemOil.lastReceivedQt}}</td>
                <td>{{cons.oilConsumptions.mainEngineSystemOil.consumption}}</td>
                <td>{{cons.oilConsumptions.mainEngineSystemOil.rob}}</td>
                <td>{{cons.oilConsumptions.mainEngineSystemOil.sum}}</td>
                <td
                  [ngClass]="{'bg-warning': cons.oilConsumptions.mainEngineSystemOil.diff > .05 || cons.oilConsumptions.mainEngineSystemOil.diff < -.05}">{{cons.oilConsumptions.mainEngineSystemOil.diff}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
    <div class="col-md-12 col-xs-12">
        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
            id="tankerList"
            class="table table-sm table-striped table-bordered"
            style="width:100%">
            <thead>
                <tr>
                    <th>ویرایش</th>
                    <th>شماره مخزن</th>
                    <th>مالک مخزن</th>
                    <th>ظرفیت</th>
                    <th>تاریخ تست</th>
                    <th>شماره گواهینامه</th>
                    <th>سند</th>
                    <th>وضعیت</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let tanker of tankers">
                    <td>
                        <button id="edit" name="edit" type="submit"
                            class="btn btn-link" (click)="editRecord(tanker)">
                            <i class="fa fa-pencil-square-o"></i>
                        </button>
                    </td>
                    <td>{{tanker.number }}</td>
                    <td>{{formatOrganisation(tanker.owner)}}</td>
                    <td>{{tanker.capacity}}</td>
                    <td
                        [ngClass]="{'text-red': tanker.licenseExpireDate <  today}">{{tanker.licenseExpireDate
                        | persianDate}}</td>
                    <td>{{tanker.licenseNumber}}</td>
                    <td>
                        <a (click)="downloadFile(tanker.fileId, tanker.number)"
                            *ngIf="tanker.fileId">
                            <i class="fa fa-download" aria-hidden="true"></i>
                        </a>
                    </td>
                    <td>{{checkUsed(tanker)}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

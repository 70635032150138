import { Component, OnInit } from '@angular/core';
import { MessageLevel, PgMessagesService, PublishedMessage } from '../../services/pg-messages.service';
import moment from 'moment';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
})
export class MessagesComponent implements OnInit {

  showMessages = false;
  messageType = MessageLevel;
  moment = moment;
  messages: PublishedMessage[] = [];

  constructor(private _pgMessagesService: PgMessagesService) {}

  ngOnInit() {
    this._pgMessagesService.messagePublished.subscribe((message) => {
      this.messageReceived(message);
    });
  }

  messageReceived(message: PublishedMessage) {
    setTimeout(() => {
      this.showMessages = false;
      this.messages.push(message);
      this.messages = this.messages.sort((a, b) => (b.time.timestamp - a.time.timestamp));
      setTimeout(() => {
        this.showMessages = true;
      }, 100);
    }, 100);
  }

  textStyle(message, fontfa: boolean) {
    if (message === MessageLevel.Info) {
      return (fontfa) ? ['text-success', 'fa fa-check'] : ['text-success'];
    } else if (message === MessageLevel.Error) {
      return  (fontfa) ? ['text-danger', 'fas fa-exclamation-triangle'] : ['text-danger'];
    } else if (message === MessageLevel.Debug) {
      return  (fontfa) ? ['text-info', 'fa fa-bug'] : ['text-info'];
    } else if (message === MessageLevel.Warning) {
      return  (fontfa) ? ['text-warning ', 'fas fa-exclamation'] : ['text-warning'];
    } else if (message === MessageLevel.Critical) {
      return  (fontfa) ? ['text-primary', 'fa fa-fire'] : ['text-primary'];
    } else if (message === MessageLevel.Oops) {
      return  (fontfa) ? ['text-warning', 'fa fa-frown' , 'fa-2x'] : ['text-warning'];
    }
  }

}

import { Component, Output, EventEmitter, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {DocumentReference, DocumentService} from '../../../documents/documents.service';

@Component({
  selector: 'app-chunk-file-uploader',
  template: `
  <div>
    <input type="file"
           id="inputFile"
           (change)="fileChangedListener($event)"
           [accept]="_accept"
           [required]="_required"
           class="form-control"
           [disabled]=_disabled>
  </div>`,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => ChunkFileUploadComponent),
  }]
})
export class ChunkFileUploadComponent implements ControlValueAccessor {

  @Output() filesChanged: EventEmitter<DocumentReference> = new EventEmitter<DocumentReference>();
  @Output() valid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() focused = new EventEmitter<any>();

  currentFiles: FileList;
  _accept: string;
  _disabled: boolean;
  _valid = false;
  _required = false;

  private _onChangeCallback: any;
  private _onTouchedCallback: any;

  @Input() set accept(value: string) {
    this._accept = value;
  }

  @Input() set disabled(value: boolean) {
    this._disabled = value;
  }

  @Input() set required(value: boolean) {
    this._required = value;
  }

  constructor(private _documentService: DocumentService) { }

  fileChangedListener(fileEvent: any) {
    if (fileEvent.target.files) {
      this._valid = true;
      this.currentFiles = fileEvent.target.files;

      const fileToUpload: any = fileEvent.target.files.item(0);
      if (fileToUpload.size <= 5000000) {
        this._documentService.addChunks('pdf', fileToUpload, fileToUpload.fileName).subscribe(results => {
          this.filesChanged.emit(results);
          // this.callChangeCallback(this.currentFiles);
        });
      } else {
        confirm('File size should be shorter than 5MB');
      }
    }
  }

  registerOnChange(fn: any): void {
    this._onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouchedCallback = fn;
  }

  writeValue(obj: any): void {
    if (obj) {
      this.currentFiles = obj;
    }
  }

  private callChangeCallback(value: any) {
    if (this._onChangeCallback) {
      this._onChangeCallback(value);
    }
  }
}

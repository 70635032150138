
const hostname = window.location.hostname;
const parts = hostname.split('.');
const uatEnv = parts[0] && parts[0].includes('uat');
const domainName = `${parts[1]}.${parts[2]}` ;
export const isProduction = hostname !== 'localhost';
const ssoBaseUrl = (isProduction) ? `https://sso.${domainName}` : `http://localhost:8080/`;
const sub = uatEnv ? 'uat-api' : 'api';
export const apiUrl = (isProduction) ? `https://${sub}.${domainName}/api` : 'http://localhost:9000/api';
export const logoutUrl = `${ssoBaseUrl}/realms/hello/protocol/openid-connect/logout`;

export function getKeycloakConfig() {
    return {
      url: ssoBaseUrl,
      realm: 'hello',
      clientId: 'portal'
    };
}



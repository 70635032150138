import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { DateRange } from 'src/app/shared/components';
import {
  LookupItem,
  LookupService,
} from 'src/app/shared/services/lookup.service';
import { Vessel, VesselsService } from '../vessels.service';
import { forkJoin, Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentService } from 'src/app/documents/documents.service';
import {
  PgMessagesService,
  MessageLevel,
} from 'src/app/shared/services/pg-messages.service';
import { DateTimeHelpers } from 'src/app/shared/helpers/date-time.helpers';
import { DataTablesService } from 'src/app/shared/datatables/data-tables.service';
import { DataTableDirective } from 'angular-datatables';
import * as moment from 'moment';
import { KeycloakService } from 'src/app/authentication/keycloak.service';
import { saveAs } from 'file-saver';
import {ActionType, DataBaseActionService} from '../../../shared/services/database-action.service';

@Component({
  selector: 'app-certificate',
  templateUrl: './certificate.component.html'
})
export class CertificateComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: true })
  dtElement: DataTableDirective;
  certificatesList: LookupItem = {
    categoryName: '',
    items: [],
  };

  certificatesListToShow: LookupItem = {
    categoryName: '',
    items: [],
  };

  dateRange: DateRange = {
    startDate: new Date(),
    endDate: new Date(),
  };

  isuueDate: DateRange;
  expirationDate: DateRange;
  fileId: string;
  history: any;
  moment = moment;
  name: string;
  dtOptions = {};
  dtTrigger: Subject<any> = new Subject<any>();
  isDataTablesInitialised = false;
  showAddItem = false;
  vessel: Vessel;
  newItem: boolean;
  mode: string;
  currentFiles: FileList[] = [];
  selectedDocumentType: string;
  index: number;
  title = new EventEmitter<string>();

  constructor(
    private _router: Router,
    private _lookupService: LookupService,
    private _activateRoute: ActivatedRoute,
    private _vesselsService: VesselsService,
    private _documentsService: DocumentService,
    private _pgMessagesService: PgMessagesService,
    private _keycloakService: KeycloakService,
    private _actionService: DataBaseActionService
  ) {}

  set certificateItem(value: string[]) {
    if (this.certificatesList.items.includes(value)) {
      this.vessel.certificates.push({
        name: value[0],
        abbr: value[1],
        info: {
          issueDate: null,
          expirationDate: null,
          fileId: null,
        },
        history: [],
      });
      this.showAddItem = false;
      this.certificatesListToShow.items = this.filterList();
      this.mode = 'new';
    }
  }
  ngOnInit(): void {
    $('#infoTable').on('click', 'tbody tr', function() {
      $(this).addClass('highlight').siblings().removeClass('highlight');
    });
    const currentComponent = this;
    const buttons = [
      {
        text: 'Add New Item',
        action: () => currentComponent.editMode(this.vessel.certificates.length, 'new'),
      },
      {
        text: 'Vessel Info',
        action: () => currentComponent.backToVessel(),
      },
    ];
    this.dtOptions = DataTablesService.getOptionsEn(buttons);
    this._activateRoute.params.subscribe((params) => {
      const imo = params['imo'];
      if (imo) {
        forkJoin([
          this._vesselsService.getVessel(imo),
          this._lookupService.get('vesselCertificate'),
        ]).subscribe((result) => {
          this.vessel = result[0];
          this.certificatesList = result[1];
          this.title.emit(this.vessel.name.name + '  Certificate');
          this.initialiseCertificateDataTables();
          this.vessel.certificates.map(() => this.currentFiles.push(null));
          if (this.vessel.certificates.length > 0) {
            this.vessel.certificates.find(() => {
              this.certificatesListToShow.items = this.filterList();
            });
          } else {
            this.certificatesListToShow.items = this.certificatesList.items;
          }
        });
      }
    });
  }

  filterList() {
    return this.certificatesList.items.filter(
      (c) => this.vessel.certificates.find((o) => o.name === c[0]) === undefined
    );
  }

  backToVessel() {
    this._router.navigate(['/vessel/', this.vessel.IMO]);
  }

  maxLength(text: string) {
    if (text.length > 20) {
      return text.substring(0 , 20) + '...';
    }
    return text;
  }

  downloadFile(fileId: string, prefix: string) {
    this._documentsService.download(fileId).subscribe(
      (b) => {
        saveAs(b.body, `${prefix}.pdf`);
      },
      () => {
        this._pgMessagesService.publishMessage({
          message: 'Could not download this file.',
          level: MessageLevel.Error,
          topic: 'Voyage document',
        });
      }
    );
  }

  hasRole(roleName: string) {
    return this._keycloakService.hasRole(roleName);
  }

  duration(endDate) {
    const now = new Date().getTime();
    return moment(endDate).diff(now, 'days');
  }

  initialiseCertificateDataTables() {
    if (!this.isDataTablesInitialised) {
      this.dtTrigger.next(false);
    } else {
      this.dtElement.dtInstance.then((dtInstance) => {
        dtInstance.destroy();
        this.dtTrigger.next(false);
      });
    }
    this.isDataTablesInitialised = true;
  }

  editMode(i: number, mode: string) {
    if (mode === 'edit') {
      this.index = i;
      this.name = this.vessel.certificates[i].name;
      this.fileId = this.vessel.certificates[i].info.fileId;
      this.history = this.vessel.certificates[i].history;
    } else if(mode === 'new') {
      if(!this.newItem) {
        this.showAddItem = true;
        this.newItem = true;
        this.index = i;
        this.fileId = null;
        this.history = null;
      }
    } else if(mode === 'delete') {
      if (confirm(`Are you sure you want to delete this BDN?`)) {
      this.vessel.certificates.splice(i,1);
      this._vesselsService.updateVessel(this.vessel).subscribe(
        () => {
          this._pgMessagesService.publishMessage({
            level: MessageLevel.Info,
            topic: 'Certificate',
            message: `The certificate Removed`,
          });
        });
      this.certificatesListToShow.items =  this.filterList();
      this.fileId = null;
      this.history = null;
      this.newItem = false;
      this.showAddItem = false;
      }
    } else if(mode === 'cancel') {
      this.index = -1;
    }
  }

  viewPDF(i: number) {
    this.fileId = this.vessel.certificates[i].info.fileId;
    this.name = this.vessel.certificates[i].name;
    this.history = this.vessel.certificates[i].history;
  }

  saveCertificate(index: number) {
    this.newItem = false;
    this.showAddItem = false;
    if (this.currentFiles[index] !== undefined) {
      if (this.currentFiles[index][0].size > 5000000) {
        confirm('The file size should be less than 5MB');
      } else {
        this._documentsService
          .add(this.selectedDocumentType, this.currentFiles[index])
          .subscribe(
            (r) => {
              this.fileId = r.id;
              this.save(index);
              this.currentFiles[index] = null;
              this.showAddItem = false;
            },
            () => {
              this._pgMessagesService.publishMessage({
                message: `Error occurred while uploading a file with type ${this.selectedDocumentType}`,
                level: MessageLevel.Error,
                topic: 'certificate',
              });
            }
          );
      }
    } else {
      this._pgMessagesService.publishMessage({
        message: `Please Upload Document`,
        level: MessageLevel.Error,
        topic: 'certificate',
      });
    }
  }

  lookupItemListFormatter(item: [string, string]) {
    return item[0];
  }

  dateTime(timestamp: number) {
    return DateTimeHelpers.formatLocalDate(timestamp);
  }

  save(index: number) {
    this.index = null;
    if (this.vessel.certificates[index].info.expirationDate !== null) {
      this.vessel.certificates[index].history.push(
        this.vessel.certificates[index].info
      );
    }
    this.vessel.certificates[index].info = {
      issueDate: this.isuueDate.startDate.getTime(),
      expirationDate: this.expirationDate.startDate.getTime(),
      fileId: this.fileId,
    };
    if (this.isuueDate.startDate.getTime() < this.expirationDate.startDate.getTime()) {
      this.vessel.actions.push(this._actionService.userAction(ActionType.Update));
      this._vesselsService.updateVessel(this.vessel).subscribe(
        () => {
          this._pgMessagesService.publishMessage({
            level: MessageLevel.Info,
            topic: 'Vessel',
            message: `The vessel ${this.vessel.IMO} information edited.`,
          });
        },
        () => {
          this._pgMessagesService.publishMessage({
            level: MessageLevel.Error,
            topic: 'Vessel',
            message: 'There is a problem to add this vessel.',
          });
        }
      );
    } else {
      this._pgMessagesService.publishMessage({
        level: MessageLevel.Error,
        topic: 'Vessel',
        message: 'Certificate start date and end date are not correct',
      });
    }
  }
}

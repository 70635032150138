<div class="row">
    <div class="col-md-6">
        <div class="card shadow-sm card-body">
            <form class="form-horizontal" (submit)="uploadDocument()">
                <div class="form-group row">
                    <label for="uploadNIOPDCFile" class="col-form-label col-md-4 "> NIOPDC File</label>
                    <div class="col-md-8">
                        <app-file-uploader 
                            [accept]="'.xls,.xlsx'" name="uploadNIOPDCFile"
                            [(ngModel)]="currentFiles">
                        </app-file-uploader>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="company1" class="col-form-label col-md-4 ">Company</label>
                    <div class="col-md-8">
                        <app-organisation-selector class="pg-component" 
                        [(organisation)]="company"
                        [organisationLanguage]="'fa'" 
                        name="organisation" 
                        [organisations]="organisations" 
                        required>
                        </app-organisation-selector>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="lpgPrice" class="col-form-label col-md-4 ">Price(IRR)</label>
                    <div class="col-md-8">
                        <input 	type="number"
                            name="lpgPrice"
                            id="lpgPrice"
                            class="form-control"
                            [(ngModel)]="lpgPrice"
                            required>
                    </div>
                  </div>
                <div class="form-group pull-right">
                    <label for="uploadDocument" class="col-form-label col-md-4"></label>
                    <div class="col-md-8">
                        <input type="submit" id="uploadDocument" class="btn btn-primary" value="Upload"
                            [disabled]="!currentFiles">
                    </div>
                </div>
            </form>
        </div>


        <div class="card shadow-sm card-body">
            <form class="form-horizontal" (submit)="uploadQuotaDocument()">
                <div class="form-group row">
                    <label for="uploadQuotaFile" class="col-form-label col-md-4 "> Quota File</label>
                    <div class="col-md-8">
                        <app-file-uploader [accept]="'.xlsx'" name="uploadQuotaFile"
                        [(ngModel)]="currentQuotaFiles">
                    </app-file-uploader>
                    </div>
                </div>
                <div class="form-group pull-right">
                    <label for="uploadQuotaDocument" class="col-form-label col-md-4"></label>
                    <div class="col-md-8">
                        <input type="submit" id="uploadQuotaDocument" class="btn btn-primary" value="Upload"
                            [disabled]="!currentQuotaFiles">
                    </div>
                </div>
            </form>
        </div>


        <div class="card shadow-sm card-body">
            <form class="form-horizontal" (submit)="uploadPaymentDocument()">
                <div class="form-group row">
                    <label for="uploadPaymentFile" class="col-form-label col-md-4 "> Payment File</label>
                    <div class="col-md-8">
                        <app-file-uploader [accept]="'.xlsx'" name="uploadPaymentFile"
                        [(ngModel)]="currentPaymentFiles">
                        </app-file-uploader>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="organisationforPayment" class="col-form-label col-md-4 ">Company</label>
                    <div class="col-md-8">
                        <app-organisation-selector class="pg-component" 
                        [(organisation)]="companyForPayment"
                        [organisationLanguage]="'fa'" 
                        name="organisationforPayment" 
                        [organisations]="organisations" 
                        required>
                        </app-organisation-selector>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="lpgPricePayment" class="col-form-label col-md-4 ">Price(IRR)</label>
                    <div class="col-md-8">
                        <input 	type="number"
                            name="lpgPricePayment"
                            id="lpgPricePayment"
                            class="form-control"
                            [(ngModel)]="lpgPriceForPayment"
                            required>
                    </div>
                  </div>
                <div class="form-group pull-right">
                    <label for="uploadPaymentDocument" class="col-form-label col-md-4"></label>
                    <div class="col-md-8">
                        <input type="submit" id="uploadPaymentDocument" class="btn btn-primary" value="Upload"
                            [disabled]="!currentPaymentFiles">
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
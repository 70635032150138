import { Component, OnInit, EventEmitter } from '@angular/core';
import { KeycloakService, UserData } from 'src/app/authentication/keycloak.service';
import { Organisation, OrganisationService } from 'src/app/organisation/organisation.service';
import { PgMessagesService, MessageLevel } from 'src/app/shared/services/pg-messages.service';
import { CorrespondenceService, CorrespondenceTemplate } from '../correspondence.service';
import { saveAs } from 'file-saver';


@Component({
  selector: 'app-new-correspondence-template',
  templateUrl: './new-correspondence-template.component.html',
})
export class NewCorrespondenceTemplateComponent implements OnInit {

  organisation: Organisation;
  organisations: Organisation[];
  currentFiles: FileList;
  edit: boolean;
  uploadedFileId: string;
  template: CorrespondenceTemplate;
  user: UserData;
  templateTitle: string;
  size: string;
  ready: boolean;

  title = new EventEmitter<string>();




  constructor(private _organisationService: OrganisationService,
              private _pgMessagesService: PgMessagesService,
              private _keycloakService: KeycloakService,
              private _correspondenceService: CorrespondenceService) { }


  ngOnInit(): void {
    this.title.emit('New Correspondence Template');
    this._organisationService.get().subscribe(organisations =>{
      this.organisations = organisations.filter(o => o.name.includes('parsagroup') && o.properties['correspondent'] === true);
    });
    this.user = this._keycloakService.getUser();
  }

  save() {
    if (this.currentFiles !== undefined) {
      if (this.currentFiles[0].size > 5000000) {
        confirm('The file size should be less than 5MB');
      } else {
        this._correspondenceService
          .addNewTemplateFile('correspondence-template',this.organisation.name, this.size, this.currentFiles)
          .subscribe(r => {
            this.uploadedFileId = r.id;
            this.addTemplate();
            this.currentFiles = null;
          }, () => {
              this._pgMessagesService.publishMessage({
                message: `فایل ارسال شده با فایل اصلی دانلود شده همخوانی ندارد`,
                level: MessageLevel.Error,
                topic: 'Correspondence'
              });
          });
      }
    }
  }

  getTemplate() {
    if(this.organisation) {
      this.ready = true;
    }
  }

  downloadTemplate() {
  this._correspondenceService.downloadBaseTemplate(this.organisation.name, this.size).subscribe(t => {
    saveAs(t.body, `${this.organisation.nameTranslations['en']}-${this.size}.docx`);
    }, () => {
      this._pgMessagesService.publishMessage({
        message: 'Could not download report template.',
        level: MessageLevel.Error,
        topic: 'Correspondence report template'
      });
    });
  }

  addTemplate() {
    this.template = {
      title: this.templateTitle,
      pageSize: this.size,
      organisationId: this.organisation.name,
      fileId: this.uploadedFileId,
      providerUserId: this.user.username,
      providerUserName: this.user.fullName
    };
    this._correspondenceService.addTemplate(this.template).subscribe(() => {
      this._pgMessagesService.publishMessage({
        message: `New temlpate added successfully`,
        level: MessageLevel.Info,
        topic: 'Correspondence'
      });
    }, () => {
      this._pgMessagesService.publishMessage({
        message: `Something wrong happened`,
        level: MessageLevel.Error,
        topic: 'Correspondence'
      });
    });
  }

}

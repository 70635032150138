
<table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" id="crews"
  class="table table-sm table-striped table-bordered">
  <thead>
    <tr>
      <th>Name</th>
      <th>Vessel</th>
      <th>Rank</th>
      <th>Age</th>
      <th>Months on board</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let crew of filteredByRanks">
      <td>{{crew.firstName}} {{crew.lastName}}</td>
      <td>{{crew.crewChanges[0].vesselName}}</td>
      <td>{{crew.crewChanges[0].rank}}</td>
      <td>{{getAge(crew)}}</td>
      <td>{{_crewChangeService.calculateOnboardTime(crew.crewChanges[0])}}</td>
    </tr>
  </tbody>
</table>

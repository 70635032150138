import { Injectable } from '@angular/core';
import { HttpWrapper } from '../../../shared';

export interface VesselBudget {
  vesselName: string;
  vesselIMO: string;
  year: number;
  quarter: string;
  dailyCost: number;
}

export interface VesselConsumption {
  vesselName: string;
  vesselIMO: number;
  cargoStatus: string;
  averageSpeed: number;
  fuelType: string;
  dailyConsumption: number;
}

@Injectable({
  providedIn: 'root'
})
export class VesselManagementService {

  constructor(private _httpWrapper: HttpWrapper) { }

  getBudgets() {
    return this._httpWrapper.get<VesselBudget[]>('vessels/management/budgets');
  }

  saveBudget(vesselBudget: VesselBudget) {
    return this._httpWrapper.post<VesselBudget, VesselBudget>('vessels/management/budgets', vesselBudget);
  }

  getConsumptionTable() {
    return this._httpWrapper.get<VesselConsumption[]>('vessels/management/consumption');
  }
}


<section class="content">
  <div class="row">
    <div class="col-lg-2">
      <div class="card shadow-sm bg-info info-box">
        <div class="card-body">
          <div class="inner">
            <h3>{{numberOfActiveVessels}}</h3>
            <p>Active vessels</p>
          </div>
          <div class="icon">
            <i class="icon icon-bag"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-2">
      <div class="card shadow-sm bg-info info-box">
        <div class="card-body">
          <div class="inner">
            <h3>{{minAge}} - {{maxAge}}</h3>
            <p>Age range</p>
          </div>
          <div class="icon">
            <i class="icon icon-bag"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-3">
      <div class="card shadow-sm bg-info info-box">
        <div class="card-body">
          <div class="inner">
            <h3>{{minCapacityMT | number : '1.0-0'}} - {{maxCapacityMT | number
              : '1.0-0'}}</h3>
            <p>Capacity range</p>
          </div>
          <div class="icon">
            <i class="icon icon-bag"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-3">
      <div class="card shadow-sm bg-info info-box">
        <div class="card-body">
          <div class="inner">
            <h3>{{minGrt | number}} - {{maxGrt | number}}</h3>
            <p>GRT range</p>
          </div>
          <div class="icon">
            <i class="icon icon-bag"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-2">
      <div class="card shadow-sm bg-info info-box">
        <div class="card-body">
          <div class="inner">
            <h3>{{biggestFlag}}</h3>
            <p>With {{numberOfBiggestFlag}} vessels</p>
          </div>
          <div class="icon">
            <i class="icon icon-bag"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <ng-container *ngFor="let v of filteredVessels">
      <div class="col-md-2">
        <div class="card shadow-sm">
          <img [src]="photos[v.IMO]" class="card-img-top" alt="{{v.name.name}}"
            style="height: 10rem">
          <div class="card-body">
            <a id="edit"
              class="btn btn-link text-decoration-none p-0"
              [routerLink]="['/vessel/', v.IMO]">
              <h5>{{v.name.name}}</h5>
            </a>
            <p class="card-text">
              IMO: {{v.IMO}}<br />
              GRT: {{v.spec.dimensionsCapacities.GRT | number}}<br />
              Age: {{calculateVesselAge(v)}}<br />
              <span *ngIf="findLatestQ88(v)" [ngClass] ="{'text-red': checQ88Expirey(v)}"> 
                Q88: {{findLatestQ88(v).issueDate | date}}             
                <a class="btn btn-link" 
                (click)="downloadFile(findLatestQ88(v).documentId, v.name.name + '-Q88')">
                  <i class="fa fa-download" aria-hidden="true"></i></a> 
              </span>
              <br />
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</section>

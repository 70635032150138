import {Component, EventEmitter, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PhoneNumber, Address, PhoneBookData, PhoneBookService } from '../phone-book.service';
import { KeycloakService } from 'src/app/authentication/keycloak.service';
import {PgTitleComponent} from '../../shared/components/title-bar/title-bar.component';

@Component({
  selector: 'app-phone-book-data-entry',
  templateUrl: './phone-book-data-entry.component.html',
})
export class PhoneBookDataEntryComponent implements OnInit, PgTitleComponent {
  title = new EventEmitter<string>();

  phoneNumbers: PhoneNumber[] = [];
  addresses: Address[] = [];
  phoneBookData: PhoneBookData;
  mode: string;
  message: string;
  showMessage: boolean;
  success: boolean;
  remove = true;

  constructor(private _activatedRoute: ActivatedRoute,
    private _phoneBookService: PhoneBookService,
    private _keycloakService: KeycloakService) { }

  ngOnInit() {
    this.title.emit('Phone Book');
    this._activatedRoute.paramMap.subscribe(p => {
      this.mode = p.get('mode');
      if (this.mode === 'new') {
        this.initPhoneBookData(false);
        this.phoneNumbers.push({category: '', number: ''});
        this.addresses.push({address: '', city: '', postalCode: '', country: ''});
      } else if (this.mode === 'edit') {
        const title = decodeURIComponent(p.get('title'));
        const name = decodeURIComponent(p.get('name'));
        let companyName = decodeURIComponent(p.get('companyName'));
        if (companyName === '') {
          companyName = '---';
        }
         this._phoneBookService.getOneContact(title, name, companyName).subscribe(phoneBooks => {
          this.phoneBookData = phoneBooks[0];
          this.phoneNumbers = this.phoneBookData.phoneNumbers;
          if (this.phoneNumbers.length === 1) {
            this.remove = false;
          }
          this.addresses = this.phoneBookData.addresses;
        });
      }
    });
  }

  addNewPhoneNumber() {
    this.phoneNumbers.push({category: '', number: ''});
    if (this.phoneNumbers.length > 1) {
      this.remove = true;
    }
  }

  addNewAddress() {
    this.addresses.push({address: '', city: '', postalCode: '', country: ''});
  }

  removePhoneNumber(index: number) {
    this.phoneNumbers.splice(index, index + 1);
    if (this.phoneNumbers.length === 1) {
      this.remove = false;
    }
  }

  removeAddress(index: number) {
    this.addresses.splice(index, index + 1);
  }

  onSubmit() {
    this.phoneBookData.title = this.phoneBookData.title.trim();
    this.phoneBookData.name = this.phoneBookData.name.trim();
    this.phoneBookData.companyName = this.phoneBookData.companyName.trim();
    this.phoneBookData.phoneNumbers = this.phoneNumbers;
    this.phoneBookData.addresses = this.addresses;
    if (this.mode === 'new') {
      this._phoneBookService.add(this.phoneBookData).subscribe(() => {
        this.message = 'اطلاعات مربوط به' + ' ' + this.phoneBookData.title + ' ' + this.phoneBookData.name + ' ' + 'با موفقیت وارد شد';
        this.success = true;
        this.showMessage = true;
      }, () => {
        this.success = false;
        this.message = ' اطلاعات وارد به دلیل تکراری بودن یا دلایل دیگر وارد نشد';
        this.showMessage = true;
      });
    } else if (this.mode === 'edit') {
      this._phoneBookService.update(this.phoneBookData).subscribe(() => {
        this.message = 'اطلاعات مربوط به' + ' ' + this.phoneBookData.title + ' ' + this.phoneBookData.name + ' ' + 'با موفقیت ویرایش شد';
        this.success = true;
        this.showMessage = true;
      }, () => {
        this.success = false;
        this.message = ' مشکلی در ویرایش داده اتفاق افتاده است';
        this.showMessage = true;
      });
    }

  }

  initPhoneBookData(edit: boolean) {
    if (!edit) {
      this.phoneBookData = {
        name: '',
        title: '',
        phoneNumbers: [],
        addresses: [],
        companyName: '',
        createdOn: 0,
        updatedOn: 0,
        isDeleted: false
      };
    }
  }

   hasRole(roleName: string) {
    return this._keycloakService.hasRole(roleName);
  }

}

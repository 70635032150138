import { Injectable } from '@angular/core';


@Injectable()
export class DataTablesService {
  private static customDom = `
          <'row mt-2 justify-content-between'
            <'d-md-flex justify-content-between align-items-center dt-layout-start col-md-auto me-auto'B>
            <'d-md-flex justify-content-between align-items-center dt-layout-end col-md-auto ms-auto'f>
          >
          <'row mt-2 justify-content-between dt-layout-table'
            <'d-md-flex justify-content-between align-items-center col-12 dt-layout-full col-md'tr>
          >
          <'row mt-2 justify-content-between'
            <'d-md-flex justify-content-between align-items-center dt-layout-start col-md-auto me-auto'i>
            <'d-md-flex justify-content-between align-items-center dt-layout-end col-md-auto ms-auto'p>
          >`;
  static getOptions(customButtons: any[] = [], scrollx = false) {
    return {
      pagingType: 'full_numbers',
      order: [],
      pageLength: 10,
      dom: this.customDom,
      colReorder: true,
      stateSave: true,
      buttons: ['colvis', customButtons],
      language: {
        buttons: {
          colvis: 'ستونها'
        },
        search: '',
        searchPlaceholder: 'جستجو',
      },
      scrollX: scrollx,
    };
  }

  static getOptionsEn(customButtons: any[] = [], priority: [number, string][] = [[0, 'asc']], pageLength = 10, print= false) {
    const p = (print) ? 'print' : '';
    return {
      pagingType: 'full_numbers',
      pageLength,
      dom: this.customDom,
      colReorder: true,
      stateSave: true,
      order: priority,
      buttons: ['colvis', customButtons , p],
      language: {
        buttons: {
          colvis: 'Columns'
        },
        search: '',
        searchPlaceholder: 'Search'
      },
    };
  }

  static getOptionsWithNoColumnsEn(customButtons: any[] = [],
                                   scrollx = false,
                                   priority: [number, string][] = [[0, 'asc']]) {
    return {
      pagingType: 'full_numbers',
      pageLength: 10,
      dom: this.customDom,
      colReorder: true,
      stateSave: true,
      buttons: [customButtons],
      order: priority,
      language: {
        buttons: {
          colvis: 'Columns'
        },
        search: 'Search',
      },
      scrollX: scrollx
    };
  }

  static getOptionsWithNoColumnsSearchEn(
    priority: [number, string][] = [[0, 'asc']]) {
    return {
      pagingType: 'full_numbers',
      pageLength: 100,
      dom: this.customDom,
      colReorder: true,
      stateSave: true,
      order: priority,
      language: {
        buttons: {
          colvis: 'Columns'
        },
      },
      scrollX: false
    };
  }
}

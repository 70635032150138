import { Component } from '@angular/core';
import { PushNotificationService } from 'src/app/shared/services/push-notification.service';

@Component({
  selector: 'app-notification-toolbar',
  templateUrl: './notification-toolbar.component.html',
})
export class NotificationToolbarComponent {

  constructor(private _pushNotificationService: PushNotificationService) { }

  subscribe() {
    return this._pushNotificationService.registerPushNotifications();
  }

}

import {Component, EventEmitter, OnInit} from '@angular/core';
import {MarketPublisher, MarketPublicationConfig, MarketService} from '../../market.service';
import {MessageLevel, PgMessagesService} from '../../../shared/services/pg-messages.service';
import {PgTitleComponent} from '../../../shared/components/title-bar/title-bar.component';

@Component({
  selector: 'app-import-publication',
  templateUrl: './import-publication.component.html',
})
export class ImportPublicationComponent implements OnInit, PgTitleComponent {
  title = new EventEmitter<string>();

  currentFiles: FileList;
  publishers: MarketPublisher[];
  selectedPublisher: MarketPublisher;
  selectedPublication: MarketPublicationConfig;
  selectedDate: string;

  constructor(
    private _pricingService: MarketService,
    private _pgMessagesService: PgMessagesService) { }

  ngOnInit() {
    this.title.emit('Import publication');
    this._pricingService.getMarketPublishers().subscribe(r => {
      this.publishers = r;
    });
  }

  uploadDocument() {
    this._pricingService.importPriceFile(
      this.selectedPublisher.publisher,
      this.selectedPublication.publication,
      this.selectedDate, this.currentFiles).subscribe(() => {
      this._pgMessagesService.publishMessage({
        level: MessageLevel.Info,
        topic: 'Market',
        message: 'Successfully imported publication file'
      });
    });
  }
}

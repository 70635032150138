import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-date-time-text',
  templateUrl: './date-time-text.component.html',
  styleUrls: ['./date-time-text.component.css']
})
export class DateTimeTextComponent {


  @Output() selectedDateChanged = new EventEmitter<Date>();

  _selectedDate: Date;

  year: number;
  month: number;
  day: number;

  hour: number;
  minute: number;

  @Input() selectedDate(value: Date) {
    this._selectedDate = value;
    this.year = value.getFullYear();
    this.month = value.getMonth();
    this.day = value.getDay();
    this.hour = value.getHours();
    this.minute = value.getMinutes();
  }

  isValidDate(d: any) {
    return d instanceof Date && !isNaN(d.getTime());
  }

  keyup() {
    const selectedDate = new Date(this.year, this.month - 1, this.day, this.hour, this.minute);

    if (this.isValidDate(selectedDate)) {
      this.selectedDateChanged.emit(selectedDate);
    }
  }
}

import {Component, EventEmitter, OnInit, ViewChild} from '@angular/core';
import { Subject, forkJoin } from 'rxjs';
import { TrucksService, MobileTrackerDevice, Truck} from 'src/app/transportation/trucks/trucks.service';
import { DataTableDirective } from 'angular-datatables';
import { Router } from '@angular/router';
import { DataTablesService } from 'src/app/shared/datatables/data-tables.service';
import * as _ from 'lodash';
import {PgTitleComponent} from '../../../shared/components/title-bar/title-bar.component';
import { KeycloakService } from 'src/app/authentication/keycloak.service';

@Component({
  selector: 'app-gps-list',
  templateUrl: './gps-list.component.html'
})
export class GpsListComponent implements OnInit, PgTitleComponent {
  @ViewChild(DataTableDirective, {static: true})
  dtElement: DataTableDirective;

  title = new EventEmitter<string>();

  isDataTablesInitialised = false;
  dtOptions = {};
  dtTrigger: Subject<any> = new Subject<any>();

  activeTracker: any[];
  trackers: MobileTrackerDevice[];
  trackersCashe: {
    [key: string]: MobileTrackerDevice; } = {};


  constructor(private _truckService: TrucksService,
              private _keycloakService: KeycloakService,
              private _router: Router) {}

  ngOnInit() {
    this.title.emit('Trackers');
    const currentComponent = this;
    forkJoin([
      this._truckService.getAllTracker(),
      this._truckService.getForLpgDist()
    ]).subscribe(result => {
      this.trackers = result[0];
      this.initialiseTankersDataTables();
      this.trackersCashe = this.trackerMap(result[1]);
    });
    const buttons = [{
      text: 'ردیاب جدید',
        action: () => currentComponent.addTracker()
      }
    ];
    this.dtOptions = DataTablesService.getOptions(buttons);
  }

  initialiseTankersDataTables() {
    if (!this.isDataTablesInitialised) {
      this.dtTrigger.next(false);
    } else {
      this.dtElement.dtInstance.then((dtInstance) => {
        dtInstance.destroy();
        this.dtTrigger.next(false);
      });
    }
    this.isDataTablesInitialised = true;
  }
  addTracker() {
    this._router.navigate(['tracker/new']);
  }
  editRecord(tracker: MobileTrackerDevice) {
    this._router.navigate(['tracker/edit', tracker.imei]);
  }

  trackerMap(trucks: Truck[]) {
    const usedTrackers = trucks.filter(truck => truck.tracker && truck.tracker.associatedObject.imei !== '').map(t => t.tracker);
    const grouped = _.groupBy(usedTrackers, (item) => item.associatedObject.imei);
    return _(grouped).map((items, key) => (
      {
        imei: key
      }
    )).value().reduce((hashMap, data) => {
      hashMap[data.imei] = data;
      return hashMap;
    }, {});
  }

  checkTracker(tracker: MobileTrackerDevice) {
    return (this.trackersCashe[tracker.imei]) ? 'used' : 'notUsed';
  }
}

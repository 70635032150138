import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { DataTablesService } from '../../../../shared/datatables/data-tables.service';
import { DataTableDirective } from 'angular-datatables';
import { Router } from '@angular/router';
import { DateTimeHelpers } from 'src/app/shared/helpers/date-time.helpers';
import { VesselsService, BunkeringDeliveryNote } from 'src/app/transportation/vessels/vessels.service';
import { Organisation, OrganisationService } from 'src/app/organisation/organisation.service';
import { forkJoin, Subject } from 'rxjs';
import { KeycloakService } from 'src/app/authentication/keycloak.service';
import { saveAs } from 'file-saver';
import { MessageLevel, PgMessagesService } from '../../../../shared/services/pg-messages.service';
import { DocumentService } from '../../../../documents/documents.service';
import { PgTitleComponent, PgToolbarComponent } from '../../../../shared/components/title-bar/title-bar.component';
import { BunkerListToolbarComponent } from '../bunker-list-toolbar/bunker-list-toolbar.component';
import _ from 'lodash';


@Component({
  selector: 'app-bunker-list',
  templateUrl: './bunker-list.component.html',
})
export class BunkerListComponent implements OnInit, PgTitleComponent, PgToolbarComponent {
  title: EventEmitter<string> = new EventEmitter();
  toolbarComponentType = BunkerListToolbarComponent;
  toolbarComponent: any;

  @ViewChild(DataTableDirective, {static: true})
  dtElement: DataTableDirective;

  isDataTablesInitialised = false;
  dtOptions = {};
  dtTrigger: Subject<any> = new Subject<any>();
  bunkeringDeliveryNote: BunkeringDeliveryNote[];
  organisations: Map<string, Organisation>;
  numberOfBunkering: number = null;
  numberOfBunkeringLocations: number = null;
  numberOfSuppliers: number = null;
  biggestSupplierOrganisationId: string = null;
  biggestSupplierProvidedTonnage: number = null;

  constructor(private _router: Router,
              private _documentsService: DocumentService,
              private _vesselsService: VesselsService,
              private _keycloakService: KeycloakService,
              private _organisationService: OrganisationService,
              private _pgMessagesService: PgMessagesService) { }

  ngOnInit() {
    this.dtOptions = DataTablesService.getOptionsWithNoColumnsEn();
    forkJoin([this._vesselsService.getAllBunkeringDeliveryNote(),
    this._organisationService.getOrganisationIdToOrganisationMap()]).subscribe((value) => {
      this.bunkeringDeliveryNote = value[0];
      this.organisations = value[1];
      this.numberOfBunkering = this.bunkeringDeliveryNote.length;
      this.numberOfBunkeringLocations = [...new Set(this.bunkeringDeliveryNote.map(b => b.locationOrganisationId))].length;
      this.numberOfSuppliers = [...new Set(this.bunkeringDeliveryNote.map(b => b.supplierOrganisationId))].length;
      const highest = _.chain(this.bunkeringDeliveryNote)
        .groupBy(b => b.supplierOrganisationId)
        .map((v, k) => ({
            supplierOrganisationId: k,
            tons: v.reduce((a, b) => a + b.quantity , 0)
          }))
        .sort((a, b) => b.tons - a.tons)
        .value()[0];
      this.biggestSupplierOrganisationId = highest.supplierOrganisationId;
      this.biggestSupplierProvidedTonnage = highest.tons;
      this.initialiseBDNDataTables();
    });
    this.title.emit('Bunker deliveries');
  }

  initialiseBDNDataTables() {
    if (!this.isDataTablesInitialised) {
      this.dtTrigger.next(false);
    } else {
      this.dtElement.dtInstance.then((dtInstance) => {
        dtInstance.destroy();
        this.dtTrigger.next(false);
      });
    }
    this.isDataTablesInitialised = true;
  }

  editRecord(bdn: BunkeringDeliveryNote) {
    this._router.navigate(['vessels/voyage/bunker/', bdn.vesselIMO, bdn.product, bdn.date ]);
  }

  dateTime(dt: string) {
    return DateTimeHelpers.isoDateStringFormatter(dt);
  }

  hasRole(roleName: string) {
    return this._keycloakService.hasRole(roleName);
  }

  downloadFile(fileId: string, prefix: string) {
    this._documentsService.download(fileId).subscribe(b => {
      saveAs(b.body, `${prefix}.pdf`);
    }, () => {
      this._pgMessagesService.publishMessage({
        message: 'Could not download this file.',
        level: MessageLevel.Error,
        topic: 'Voyage document'
      });
    });
  }
}

<div>
  <div class="row">
    <div class="col-md-12">
      <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
        id="tblSearchCust"
        class="table table-sm table-striped  table-bordered " style="width:100%">
      </table>
    </div>
  </div>
</div>

import { Component, EventEmitter, OnInit } from '@angular/core';
import { LPGDistService } from '../../lpg-dist.service';
import { MessageLevel, PgMessagesService } from '../../../shared/services/pg-messages.service';
import { Organisation, OrganisationService } from '../../../organisation/organisation.service';

@Component({
  selector: 'app-lpg-dist-file-uploader',
  templateUrl: './lpg-dist-file-uploader.component.html',
})
export class LpgDistFileUploaderComponent implements OnInit {
  title = new EventEmitter<string>();

  company: Organisation;
  companyForPayment: Organisation;
  lpgPriceForPayment: number;
  organisations: Organisation[];
  currentFiles: FileList;
  currentQuotaFiles: FileList;
  currentPaymentFiles: FileList;
  lpgPrice: number;

  constructor(private _lpgdistservice: LPGDistService,
    private _organisationService: OrganisationService,
    private _pgMessagesService: PgMessagesService) { }


  async ngOnInit(): Promise<void> {
    this.title.emit('Import NIPDC file');
    this._organisationService.get().subscribe(o => {
      this.organisations = o.filter(organisation => organisation.properties['domestic'] === true);
    });
  }

  uploadQuotaDocument() {
    this._lpgdistservice.importQuotaFile(this.currentQuotaFiles).subscribe(() => {
      this._pgMessagesService.publishMessage({
        level: MessageLevel.Info,
        topic: 'Quota',
        message: 'Successfully imported file'
      });
    });
  }

  uploadPaymentDocument() {
    this._lpgdistservice.importPaymentFile(this.currentPaymentFiles,
      this.companyForPayment.name, String(this.lpgPriceForPayment)).subscribe(() => {
      this._pgMessagesService.publishMessage({
        level: MessageLevel.Info,
        topic: 'Payment',
        message: 'Successfully imported file'
      });
    });
  }

  uploadDocument() {
    this._lpgdistservice.importLpgDistFile(this.company.name, this.currentFiles, String(this.lpgPrice)).subscribe(() => {
      this._pgMessagesService.publishMessage({
        level: MessageLevel.Info,
        topic: 'NIOPDC',
        message: 'Successfully imported file'
      });
    });
  }
}

<!-- <div class="col-md-12">
  <div class="box" style="padding: 5px">
    <div class="box-header with-border">
      <h3 class="box-title">فهرست قرارداد ها</h3>
    </div>
    <div class="box-body">
      <form  #contractReportForm="ngForm">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <app-persian-date-range id="selectedDateRange"  
                                      [startDate]="dateRange.startDate "  
                                      [endDate]="dateRange.endDate"
                                      (dateRangeChange)="selectedDateRangeChanged($event)">
              </app-persian-date-range>
            </div>
          </div>
          <div class="col-md-2 col-xs-12">
              <button type="button" class="btn btn-block" (click)="onSubmit()">گزارش</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div> -->

<div class="col-md-12">
  <div class="box" style="padding: 5px">
    <div class="row">
      <div class="col-md-12">
        <div class="radio-inline">
          <label>
            <input type="radio"
                   name="calendarSelection"
                   id="jalaliOption"
                   checked
                   value="jalali"
                   [(ngModel)]="selectedCalendar"
                   >Persian Calendar</label>
        </div>
        <div class="radio-inline">
          <label>
            <input type="radio"
                   name="calendarSelection"
                   id="gregorianOption"
                   value="gregorian"
                   [(ngModel)]="selectedCalendar"
                   ngModel>Gregorian Calendar</label>
        </div>
      </div>
      <div class="col-md-6" *ngIf="selectedCalendar==='jalali'">
        <!-- <app-persian-date-range id="selectedDateRange"
                                [singleDateSelector]="false"
                                [startDate]="dateRange.startDate"
                                [endDate]="dateRange.endDate"
                                (dateRangeChange)="selectedDateRangeChanged($event)">
        </app-persian-date-range> -->
      </div>
      <div class="col-md-6" *ngIf="selectedCalendar==='gregorian'">
        <app-date-range id="selectedDateRangeGregorian"
                        [singleDateSelector]="false"
                        [(dateRange)]="dateRange">
        </app-date-range>
      </div>
      <div class="col-md-2 col-xs-12">
        <button type="button" class="btn btn-block" (click)="onSubmit()">Report</button>
      </div>
    </div>
  </div>
</div>
<div class="col-md-12">
  <div class="box" style="padding: 5px">
    <div class="box-header with-border">
      <h3 class="box-title">Commitment balances</h3>
    </div>
    <div class="box-body">
      <form  #contractReportForm="ngForm">
        <div class="row">
          <div class="form-group visible-xs visible-sm">
            <label for="company"
                   class="col-form-label col-md-4">Source</label>
            <div class="col-md-8">
                <select class="form-control col-md-8"
                      name="company"
                      id="company"
                      [(ngModel)]="source"
                      required>
                <option *ngFor="let company of loadingLocations" [ngValue]="company">
                  {{company.nameTranslations['en']}}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group visible-md visible-lg col-md-6">
            <label for="company1" class="col-form-label col-md-2">Source</label>
            <div class="col-md-10">
              <app-organisation-selector 
                 class= "pg-component" [(organisation)]="source" 
              name="company1" [organisations]="loadingLocations" required></app-organisation-selector>
            </div>
          </div>
          <div class="col-md-2 col-xs-12">
              <button type="button" class="btn btn-block" (click)="getBalances()">Report</button>
          </div>
        </div>
        <div class="row">
          <div class="form-group visible-xs visible-sm">
            <label for="buyer2"
                   class="col-form-label col-md-4">Buyer</label>
            <div class="col-md-8">
                <select class="form-control col-md-8"
                      name="buyer2"
                      id="buyer2"
                      [(ngModel)]="buyer"
                      required>
                <option *ngFor="let company of buyers" [ngValue]="company">
                  {{company.nameTranslations['en']}}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group visible-md visible-lg col-md-6">
            <label for="buyer" class="col-form-label col-md-2">Buyer</label>
            <div class="col-md-10">
              <app-organisation-selector class= "pg-component" [(organisation)]="buyer" [organisationLanguage]= "'en'"
              name="buyer" [organisations]="buyers" required></app-organisation-selector>
            </div>
          </div>
          <div class="col-md-2 col-xs-12">
              <button type="button" class="btn btn-block" (click)="getBuyerBalances()">Report</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>


import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { forkJoin, Subject } from 'rxjs';
import { DataTablesService } from 'src/app/shared/datatables/data-tables.service';
import { PgToolbarComponent } from 'src/app/shared/components/title-bar/title-bar.component';
import { DurationSof, ToolbarItem, Vessel, VesselsService } from '../vessels.service';
import { LoadingTimeToolbarComponent } from './loading-time-toolbar/loading-time-toolbar.component';
import { DateTimeHelpers } from 'src/app/shared/helpers/date-time.helpers';
import { Organisation, OrganisationService } from 'src/app/organisation/organisation.service';
import { UserLocalStoreService } from 'src/app/shared/services/user-local-store.service';
import moment from 'moment';
import { LookupService } from 'src/app/shared/services/lookup.service';
import { saveAs } from 'file-saver';
import { PgMessagesService } from 'src/app/shared/services/pg-messages.service';

interface SOFComponentSettings {
  type: string;
  sofItem1: string;
  sofItem2: string;
}
@Component({
  selector: 'app-loading-time',
  templateUrl: './loading-time.component.html',
})
export class LoadingTimeComponent implements OnInit, PgToolbarComponent {
  @ViewChild(DataTableDirective, { static: true })
  dtElement: DataTableDirective;
  toolbarComponentType = LoadingTimeToolbarComponent;
  toolbarComponent: LoadingTimeToolbarComponent;


  title: EventEmitter<string> = new EventEmitter();

  isDataTablesInitialised = false;
  dtOptions = {};
  dtTrigger: Subject<any> = new Subject<any>();
  sofItems: DurationSof[];
  ports: Organisation[];
  vesselIMO: string;
  moment = moment;
  lookUpSofItems: string[][];
  selectSofItems: [string, string, string];
  sofItem1: string;
  sofItem2: string;
  sofName1: string;
  sofName2: string;
  toolTip1: string;
  toolTip2: string;
  dateRange = {
    startDate : moment(new Date()).subtract(365, 'days').toDate(),
    endDate: new Date()
  };
  startDate: number;
  endDate: number;
  vessels: Vessel[];
  _settings: SOFComponentSettings;
  private _type: string;
  constructor(private _vesselsService: VesselsService,
    private _organisationService: OrganisationService,
    private _lookupService: LookupService,
    private _pgMessagesService: PgMessagesService,
    private _userLocalStorage: UserLocalStoreService
  ) { }

  ngOnInit(): void {
    this.title.emit('Loading Time');
    const currentComponent = this;
    forkJoin([this._organisationService.getPorts(),
    this._vesselsService.getActiveVessels(),
    this._lookupService.get('voyage_sof_all')])
      .subscribe(item => {
        this.ports = item[0];
        this.vessels = item[1];
        this.lookUpSofItems = item[2].items;
        this.fillDataTable();
      });
    const buttons = [
      {
        text: 'Download',
        action: () => currentComponent.excelExport()
      }
    ];
    this.dtOptions = DataTablesService.getOptionsEn(buttons);
  }

  fillDataTable() {
    this._settings = this._userLocalStorage.get<SOFComponentSettings>('SOFItemComponent');
    if (this._settings === null) {
      this._settings = {
         type: 'loading',
        sofItem1: 'L01011', sofItem2: 'L02051'
      };
    }
    this._type = this._settings.type;
    this.toolbarComponent.type = this._settings.type;
    this.sofItem1 = this._settings.sofItem1;
    this.sofItem2 = this._settings.sofItem2;
    if (!this.startDate && !this.endDate) {
      this.startDate = this.dateRange.startDate.getTime();
      this.endDate = this.dateRange.endDate.getTime();
    }
    this.getSof(this.startDate, this.endDate, this._type, this.sofItem1, this.sofItem2);
    this.lookUpSofItems.map(f => {
      const code = f[0];
      const name = f[1];
      const des = f[2];
      if (this._settings.sofItem1 === code) {
        this.sofName1 = name.substring(0,20);
        this.toolTip1 = name;
        this.toolbarComponent.extraSofEntry = [code, name, des];
      }
      if (this._settings.sofItem2 === code) {
        this.sofName2 = name.substring(0,20);
        this.toolTip2 = name;
        this.toolbarComponent.extraSofEntry2 = [code, name, des];
      }
    });
    this.toolbarComponent.itemsSelected.subscribe(t => {
      this.refresh(t);
    });
  }

  getSof(startDate: number, endDate: number, type: string, item1: string, item2: string) {
    this._vesselsService.getSOFDuration(startDate, endDate,
       type, item1, item2).subscribe(s => {
        this.sofItems = [];
        this.sofItems = s;
        this.initialiseSOFDataTables();
      });
  }


  duration(start: number, end: number) {
    if (start && end) {
      const d = end - start;
      if(d > 0) {
        return moment.duration(d).days() + '-' + moment.duration(d).hours() + ':' + moment.duration(d).minutes();
      } else {
        return 'Error';
      }
    } else {
      return '';
    }
  }

  refresh(item: ToolbarItem) {
    this._settings = {
      type: item.type,
      sofItem1: item.firstSOF,
      sofItem2: item.secondeSOF,
    };
    this.startDate = item.dateRange.startDate.getTime();
    this.endDate = item.dateRange.endDate.getTime();
    this._userLocalStorage.save('SOFItemComponent', this._settings);
    this.fillDataTable();
  }

  initialiseSOFDataTables() {
    if (!this.isDataTablesInitialised) {
      this.dtTrigger.next(false);
    } else {
      this.dtElement.dtInstance.then((dtInstance) => {
        dtInstance.destroy();
        this.dtTrigger.next(false);
      });
    }
    this.isDataTablesInitialised = true;
  }

  dateTime(id: string, timestamp: number) {
    const port = this.ports.find(p => p.name === id);
    if (port && timestamp) {
      return DateTimeHelpers.getDateTimeFromTimestamp(port.timezone, timestamp);
    }
  }

  formatPorts(portOrganisationId: string) {
    if (this.ports) {
      const port = this.ports.find(p => p.name === portOrganisationId);
      return (port !== undefined) ? port.nameTranslations['en'] : '';
    }
  }

  excelExport() {
    this._vesselsService.getSOFDurationExcel(this.startDate, this.endDate
      , this._type, this.sofItem1, this.sofItem2).subscribe(file => {
        const t = DateTimeHelpers.formatLocalDate(this.endDate);
        saveAs(file.body, `Loading Time ${t}.xlsx`);
      });
  }

}

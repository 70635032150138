import { Injectable } from '@angular/core';
import { HttpWrapper } from 'src/app/shared';
import { Observable } from 'rxjs';
import { SwPush } from '@angular/service-worker';
import { KeycloakService } from 'src/app/authentication/keycloak.service';
import { Router } from '@angular/router';
import { PgMessagesService, MessageLevel } from '../services/pg-messages.service';

export interface Keys {
  p256dh: string;
  auth: string;
}

export interface Endpoint {
  endpoint: string;
  expirationTime: number;
  keys: Keys;
  subscriptionTimestamp: number;
}
export interface Member {
  endpoint: Endpoint[];
  userId: string;
  scope: string[];
}

@Injectable()
export class PushNotificationService {


  constructor(private _httpWrapper: HttpWrapper,
    private _swPush: SwPush,
    private _keycloakService: KeycloakService,
    private router: Router,
    private _pgMessagesService: PgMessagesService) { }

  addMember(data: Member): Observable<Member> {
    return this._httpWrapper.post('notifications/add/', data);
  }

  getMember(userId: string): Promise<Member> {
    return this._httpWrapper.get<Member>(`notifications/get/${userId}`).toPromise();
  }

  updateMember(member, sub) {
    if (member) {
      if (!member.endpoint.map(e => e.endpoint).includes(sub.endpoint)) {
        member.endpoint.push({
          endpoint: sub.endpoint,
          expirationTime: 0,
          keys: sub.keys,
          subscriptionTimestamp: new Date().getTime()
        });
      }
      if (!member.scope.includes(this.router.url)) {
        member.scope.push(this.router.url);
      }
      return member;
    } else {
      const m: Member = {
        endpoint: [{
          endpoint: sub.endpoint,
          expirationTime: 0,
          keys: sub.keys,
          subscriptionTimestamp: new Date().getTime()
        }],
        userId: this._keycloakService.getUser().username,
        scope: [this.router.url],
      };
      return (m);
    }
  }

  registerPushNotifications() {
    fetch('assets/notification/pushNotificationPublicKey.txt').then(d => d.text()).then(publicKey => {
      Notification.requestPermission().then(notificationPermission => {
        if (notificationPermission === 'granted') {
          this._swPush.requestSubscription({
            serverPublicKey: publicKey
          })
            .then(sub => {
              const sub1 = JSON.parse(JSON.stringify(sub));
              this.getMember(this._keycloakService.getUser().username)
                .then(d => {
                  this.addMember(this.updateMember(d, sub1)).subscribe(() => {
                    this._pgMessagesService.publishMessage({
                      message: `Subscribe to price notifications`,
                      level: MessageLevel.Info,
                      topic: 'Notification'
                    });
                  });
                });
            })
            .catch(err => this._pgMessagesService.publishMessage({
              message: `Could not subscribe to notifications: ${err}`,
              level: MessageLevel.Error,
              topic: 'Notification'
            }));
        }
      });
    });
  }
}

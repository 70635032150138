import { Component, EventEmitter, Input, Output} from '@angular/core';
import { DocumentService } from '../../../documents/documents.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-slideshow',
  templateUrl: './slideshow.component.html',
})
export class SlideshowComponent {
  @Output()
  rmFileId: EventEmitter<string> = new EventEmitter<string>();

  thumbnail: any[] = [];
  fullScreen: string;
  remove: string;
  hasrole: boolean;
  fileName: string;

  constructor(private _documentsService: DocumentService) {}

  @Input() set fileId(value: []) {
    this.thumbnail = [];
    value.forEach((o) => {
      this._documentsService.download(o).subscribe((b) => {
        const reader = new FileReader();
        reader.readAsDataURL(b.body);
        reader.onload = () => {
          this.thumbnail.push(reader.result);
        };
      });
    });
  }

  @Input() set photoName(value: string) {
    this.fileName = value;
  }

  @Input() set role(value: boolean) {
    this.hasrole = value;
  }

  editMode(rm: string) {
    this.remove = rm.split('/').slice(-1)[0];
    this.rmFileId.emit(this.remove);
  }

  onFullscreen(id: string) {
    this.fullScreen = id;
  }

  downloadDocumentId(blob: string) {
    saveAs(blob, this.fileName);
  }
}

import { Route } from '@angular/router';

import { LpgReportComponent } from './reports/lpg-report.component';
import { SummaryReportComponent } from './reports/summary-report/summary-report.component';
import { CompareReportComponent } from './reports/compare-report/compare-report.component';
import { DailyReportComponent } from './reports/daily-report/daily-report.component';
import { LpgDistEntryComponent } from './data-entries/lpg-dist-data/lpg-dist-entry/lpg-dist-entry.component';
import { LpgPaymentEntryComponent } from './data-entries/payments-data/lpg-payment-entry/lpg-payment-entry.component';
import { LpgDistDetailComponent } from './data-entries/lpg-dist-data/lpg-dist-detail/lpg-dist-detail.component';
import { LpgDistPaymentDetailComponent } from './data-entries/payments-data/lpg-dist-payment-detail/lpg-dist-payment-detail.component';
import { LpgQuotaEntryComponent } from './data-entries/lpg-dist-data/lpg-quota-entry/lpg-quota-entry.component';
import { OrganisationMapComponent } from 'src/app/lpg-dist/data-entries/organisation-map/organisation-map.component';
import { LpgDistFileUploaderComponent } from './data-entries/lpg-dist-file-uploader/lpg-dist-file-uploader.component';
import { TrucksReportsComponent } from './reports/trucks-reports/trucks-reports.component';


export const lpgDistRoutes: Route[] = [
  { path: 'lpg/report/daily-report', component: DailyReportComponent },
  { path: 'lpg/report', component: LpgReportComponent },
  { path: 'lpg/report/truck', component: TrucksReportsComponent},
  { path: 'lpg/report/summary', component: SummaryReportComponent },
  { path: 'lpg/report/compare/yearly', component: CompareReportComponent },
  { path: 'lpg-dist/detail', component: LpgDistDetailComponent },
  { path: 'lpg-dist/data-entry/:mode', component: LpgDistEntryComponent },
  { path: 'lpg-dist/data-entry/:mode/:company/:loadTimestamp/:searchKey', component: LpgDistEntryComponent },
  { path: 'lpg-dist/payments/:mode/:company', component: LpgPaymentEntryComponent },
  { path: 'lpg-dist/payments/:mode/:company/:payTimestamp/:paymentBillNo', component: LpgPaymentEntryComponent },
  { path: 'lpg-dist/payment', component: LpgDistPaymentDetailComponent },
  { path: 'lpg-dist/quta-entry', component: LpgQuotaEntryComponent},
  { path: 'lpg-dist/organisations', component: OrganisationMapComponent},
  { path: 'lpg-dist/file', component: LpgDistFileUploaderComponent}
];

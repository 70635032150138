
<div class="boxContainer">
  <input type="number"
         id="year"
         size="5"
         maxlength="4"
         min="2000"
         max="2019"
         placeholder="YYYY"
         (keyup)="keyup()"
         tabindex="1"
         [(ngModel)]="year">
  <input type="number"
         id="month"
         size="3"
         maxlength="2"
         placeholder="MM"
         min="1"
         max="12"
         (keyup)="keyup()"
         tabindex="2"
         [(ngModel)]="month">
  <input type="number"
         id="day"
         size="3"
         maxlength="2"
         placeholder="DD"
         min="1"
         max="31"
         (keyup)="keyup()"
         tabindex="3"
         [(ngModel)]="day">
  &nbsp;&nbsp;&nbsp;&nbsp;
  <input type="number"
         id="hour"
         size="3"
         maxlength="2"
         placeholder="hh"
         min="00"
         max="23"
         (keyup)="keyup()"
         tabindex="3"
         [(ngModel)]="hour">
  :
  <input type="number"
         id="minute"
         size="3"
         maxlength="2"
         placeholder="mm"
         tabindex="4"
         min="1"
         (keyup)="keyup()"
         max="59"
         [(ngModel)]="minute">
</div>

import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { SharedModule } from '../shared/shared.module';
import { ContractComponent } from './contract.component';
import { ContractService } from './contract.service';
import { ContractDataEntryComponent } from './data-entry/contract-data-entry.component';
import { DataTablesModule } from 'angular-datatables';
import { ContractDetailComponent } from './contract-detail/contract-detail.component';
import { ContractsBalanceComponent } from './contracts-balance/contracts-balance.component';
import { BuyerContractBalanceComponent } from './buyer-contract-balance/buyer-contract-balance.component';

@NgModule({
  imports: [SharedModule, FormsModule, DataTablesModule ],
  declarations: [
    ContractComponent,
    ContractDataEntryComponent,
    ContractDetailComponent,
    ContractsBalanceComponent,
    BuyerContractBalanceComponent
  ],
  exports: [ContractComponent, ContractDataEntryComponent],
  providers: [ContractService]
})
export class ContractModule {}

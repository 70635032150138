import {Component, EventEmitter, OnInit} from '@angular/core';
import {LookupService} from '../../../shared/services/lookup.service';
import {forkJoin, Subject} from 'rxjs';
import {DataTablesService} from '../../../shared/datatables/data-tables.service';
import {KeycloakService} from 'src/app/authentication/keycloak.service';
import {Router} from '@angular/router';
import {PgTitleComponent} from '../../../shared/components/title-bar/title-bar.component';

interface SofTemplateDescription {
  id: string;
  description: string;
  sofType: string;
}

interface SofDescription {
  code: string;
  event: string;
  canonical: string;
}

@Component({
  selector: 'app-sof-templates',
  templateUrl: './sof-templates.component.html'
})
export class SofTemplatesComponent implements OnInit, PgTitleComponent {
  title = new EventEmitter<string>();

  allSofItems: SofDescription[];
  allSofTemplates: SofTemplateDescription[];
  allSofItemsDtOptions: any;
  sofTemplatesDtOptions: any;
  dtTrigger: Subject<any> = new Subject<any>();

  constructor(private _keycloakService: KeycloakService,
              private _lookupService: LookupService,
              private _router: Router) {
  }

  ngOnInit() {
    this.title.emit('SOF design');
    this.setupTables();
    forkJoin([this._lookupService.get('voyage_sof_all'),
      this._lookupService.get('voyage_sof_templates')]).subscribe(a => {
        this.allSofItems = a[0].items.map(s => (
          {
            code: s[0],
            event: s[1],
            canonical: s[2]
          }
        ));
        this.allSofTemplates = a[1].items.map(s => (
          {
            id: s[0],
            description: s[1],
            sofType: s[2]
          }
        ));
        this.dtTrigger.next(false);
    });
  }

  setupTables() {
    const currentComponent = this;
    this.allSofItemsDtOptions = DataTablesService.getOptionsWithNoColumnsEn([{
      text: 'New SOF item',
      action:  () => currentComponent.addNewSofRecord()
    }
    ]);
    this.sofTemplatesDtOptions = DataTablesService.getOptionsWithNoColumnsEn([{
      text: 'New SOF template',
      action:  () => currentComponent.addNewSofTemplate()
    }]);
  }

  addNewSofRecord() {
    this._router.navigate(['vessels/sof/sof-entry']).then();
  }

  addNewSofTemplate() {
    this._router.navigate(['vessels/sof/sof-template']).then();
  }
}

<div class="row">
  <div class="col-md-12 col-xs-12">
    <table
      datatable
      [dtOptions]="dtOptions"
      [dtTrigger]="dtTrigger"
      class="table table-sm table-striped table-bordered text-center"
      style="width: 100%">
      <thead>
        <tr>
          <th>عنوان</th>
          <th>شماره نامه</th>
          <th>از</th>
          <th>به</th>
          <th>تاریخ</th>
          <th>تهیه کننده</th>
          <th>فایل اصلی</th>
          <th>فایل امضا شده</th>
          <th>نوع نامه</th>
          <th>ویرایش</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let correspondence of filterCorrespondences; let i = index">
          <td>{{ correspondence.title }}</td>
          <td>{{ correspondence.ref }}</td>
          <td>{{ organisationFormatter(correspondence.organisationId) }}</td>
          <td>{{ organisationFormatter(correspondence.toId) }}</td>
          <td>{{ correspondence.timestamp | persianDate }}</td>
          <td>{{ correspondence.providerUserName }}</td>
          <td>
            <button
              id="download"
              name="download"
              type="submit"
              class="btn btn-link"
              (click)="download(correspondence.createdFileId, 'docx', i)">
              <i class="fa fa-download"></i>
            </button>
          </td>
          <td>
            <button
              id="download"
              *ngIf="correspondence.signedFileId"
              name="download"
              type="submit"
              class="btn btn-link"
              (click)="download(correspondence.signedFileId, 'pdf', i)">
              <i class="fa fa-download"></i>
            </button>
          </td>
          <td>
            <i class="fa fa-arrow-down" *ngIf="correspondence.incoming"></i>
            <i class="fa fa-arrow-up" *ngIf="!correspondence.incoming"></i>
          </td>
          <td>
            <button
              id="download"
              name="download"
              type="submit"
              class="btn btn-link"
              (click)="edit(correspondence)">
              <i class="fa fa-edit"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

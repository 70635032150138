<form #commentForm="ngForm"
  class="form-horizontal"
  (ngSubmit)="saveComment()">
  <div class="form-group row">
    <label for="selectedDate"
      class="col-form-label col-md-4 ">Date & time</label>
    <div class="col-md-8">
      <app-date-range
        id="selectedDate"
        name="incidentDate"
        [singleDateSelector]="true"
        [(dateRange)]="_selectedDate"
        [timePicker]="true"
        [localeDateFormat]="'YYYY-MM-DD HH:mm'">
      </app-date-range>
    </div>
  </div>
  <div class="form-group row">
    <label for="comment"
      class="col-form-label col-md-4 ">Comment</label>
    <div class="col-md-8">
      <textarea id="comment"
        name="comment"
        class="form-control"
        cols="40" rows="5"
        [(ngModel)]="comment.content"
        required></textarea>
    </div>
  </div>

  <div class="btn-group" role="group" aria-label="Button group">
    <button type="button"
      id="cancel"
      class="btn btn-outline-secondary btn-light"
      (click)="cancelled.emit()">Back to voyage
    </button>
    <button type="submit"
      id="submit"
      class="btn btn-primary"
      [disabled]="!commentForm.form.valid">Save
    </button>
  </div>

</form>

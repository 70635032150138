import {Component, Directive, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import * as moment from 'moment-timezone';
import {DateTimeHelpers} from '../../helpers/date-time.helpers';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR, ValidationErrors,
  Validator
} from '@angular/forms';

export interface TimestampWithZone {
  timestamp: number;
  timeZone: string;
}

export const dateTimezoneRequiredValidator01 = (value: boolean) =>
   (abstractControl: AbstractControl) => {
    const formControl = abstractControl as FormControl;
    if (value === false) {
      return null;
    } else if (formControl.value && (formControl.value as TimestampWithZone).timestamp)  {
      return null;
    } else {
      return {
        error: 'DateTimeWithZone is not valid'
      };
    }
  };

@Directive({
  selector: '[appDateTimeZoneRequired]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: DateTimeZoneComponentValidatorDirective,
    multi: true
  }]
})
export class DateTimeZoneComponentValidatorDirective implements Validator {

  private _onValidatorChange: () => void;
  private _isRequired: boolean;

  @Input('appDateTimeZoneRequired') set required01(value: boolean) {
    this._isRequired = value;
    if (this._onValidatorChange) {
      this._onValidatorChange();
    }
  }

  registerOnValidatorChange(fn: () => void): void {
    this._onValidatorChange = fn;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return dateTimezoneRequiredValidator01(this._isRequired)(control);
  }
}

/**
 * This class allows a user to select a date with a selected Timezone.
 * If a time zone is not specified it will use the current timezone.
 */
@Component({
  selector: 'app-date-time-zone',
  template: `<input type="datetime-local"
                    [value]="_selectedDate"
                    [disabled]="_isDisabled"
                    (change)="selectedDateChanged($event)"
                    (focus)="onFocus()"
                    max="_maxDate"
                    class="form-control">`,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => DateTimeZoneComponent),
  }]
})
export class DateTimeZoneComponent implements ControlValueAccessor {

  @Output() dateChanged = new EventEmitter<TimestampWithZone>();
  @Output() focused = new EventEmitter<any>();

  _isDisabled = false;
  _maxDate: Date;

  _selectedDate: string;


  private localDateFormat = 'YYYY-MM-DDTHH:mm';
  private _value: TimestampWithZone;
  private _onChangeCallback: any;
  private _onTouchedCallback: any;


  @Input() set maxDate(value: Date) {
    this._maxDate = value;
  }

  selectedDateChanged($event: any) {
    if (this._value) {
      const ts = DateTimeHelpers.offsetTimestampWithZone(
        moment($event.target.value, this.localDateFormat).unix() * 1000,
        this._value.timeZone);
      this._value = {
        timestamp: ts,
        timeZone: this._value.timeZone
      };
      this.dateChanged.emit(this._value);
      this.callChangeCallback(this._value);
    } else {
      this._value = {
        timestamp: moment($event.target.value, this.localDateFormat).unix() * 1000,
        timeZone: undefined
      };
      this.dateChanged.emit(this._value);
      this.callChangeCallback(this._value);
    }
  }



  onFocus() {
    if (this.focused) {
      this.focused.emit(null);
    }
    if (this._onTouchedCallback) {
      this._onTouchedCallback();
    }
  }

  registerOnChange(fn: any): void {
    this._onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouchedCallback = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._isDisabled = isDisabled;
    if (isDisabled) {
      this.dateChanged.emit(null);
      this.callChangeCallback(null);
    } else {
      this.dateChanged.emit(this._value);
      this.callChangeCallback(this._value);
    }
  }

  writeValue(obj: any): void {
    const date = obj as TimestampWithZone;
    if (date) {
      this._value = date;
      if (date.timestamp) {
        this._selectedDate = moment(date.timestamp).tz(date.timeZone).format(this.localDateFormat);
      }
    }
  }

  private callChangeCallback(value: TimestampWithZone) {
    if (this._onChangeCallback) {
      this._onChangeCallback(value);
    }
  }
}

 <div class="input-group">
  <app-date-range id="selectedDateRangeGregorian" *ngIf="dateRange"
    [singleDateSelector]="singleDate"
    [(dateRange)]="dateRange"
    [(jalali)]="jalali"
    class="ms-1">
  </app-date-range>
  <app-organisation-selector
    *ngIf="visibleOrganisation && jalali"
    class="pg-component ms-1"
    style="text-align: center;"
    placeholder="Company"
    [(organisation)]="selectedOrganisation"
    [organisationLanguage]="'fa'"
    name="organisation"
    [organisations]="organisations"
    required>
  </app-organisation-selector>
  <app-organisation-selector
    *ngIf="visibleOrganisation && !jalali"
    class="pg-component ms-1"
    style="text-align: center;"
    placeholder="Company"
    [(organisation)]="selectedOrganisation"
    [organisationLanguage]="'en'"
    name="organisation"
    [organisations]="organisations"
    [disabled]="edit"
    required>
  </app-organisation-selector>
  <button class="btn btn-outline-secondary ms-1" (click)="onSubmit()"
    type="button">Refresh</button>
</div>

import { Pipe, PipeTransform } from '@angular/core';
import {WeatherForce} from '../../services/noon-reports-v202205.service';

@Pipe({
  name: 'weather'
})
export class WeatherConditionPipe implements PipeTransform {

  transform(value: any): any {
    if (value) {
      const val = value as WeatherForce;
      return `${val?.force} ${val?.direction}`;
    }
    return null;
  }

}

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import moment from 'moment';
import { DateRange } from 'src/app/shared/components';
import { Vessel } from '../vessels.service';

@Component({
  selector: 'app-vessels-report-toolbar',
  templateUrl: './vessels-report-toolbar.component.html',
})
export class VesselsReportToolbarComponent implements OnInit {
  @Output()
  date: EventEmitter<DateRange> = new EventEmitter<DateRange>();
  @Output()
  selectedVessel: EventEmitter<Vessel> = new EventEmitter<Vessel>();


  dateRange: DateRange;
  vessels: Vessel[];
  _selectedVessel: Vessel;

  ngOnInit(): void {
    if(!this.dateRange) {
      this.dateRange = {
        startDate: moment(new Date()).subtract(100,'days').toDate(),
        endDate: new Date()
      };
    }
  }

  vesselListFormatter(vessel: Vessel) {
    return vessel.name.name;
  }

  selectVessel(vessel: Vessel) {
    if(vessel) {
      this.selectedVessel.emit(vessel);
    }
  }

  selectDateRange(dateRange: DateRange) {
    if(dateRange) {
      this.date.emit(dateRange);
    }
  }

}

import { Injectable } from '@angular/core';
import { GeoCoordinate } from '../../models/GeoCoordinate';
import { HttpWrapper } from '../../../shared';
import { DateRange } from '../../../shared/components';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as L from 'leaflet';
import * as _ from 'lodash';
import { DateTimeHelpers } from '../../../shared/helpers/date-time.helpers';
import * as moment from 'moment';
import { GenericNoonReportValidationRule } from '../vessels.service';
import { Router } from '@angular/router';
export class NoonReportDate {
  timestamp: number;
  hoursSinceLastReport: number;
  totalSteamingTime: number;
}

export class NoonReportVoyageDetails {
  voyageNumber: string;
  source: string;
  destination: string;
  isLaden: boolean;
  eta: number;
  distanceCovered: number;
  distanceByMainEngine: number;
  distanceToGo: number;
  totalDistanceRun: number;
  slip() {
    return this.distanceCovered - this.distanceByMainEngine / this.distanceByMainEngine;
  }
}

export class NoonReportPosition {
  geoCoordinate: GeoCoordinate;
  currentLocation: string;
}

export class NoonReportSpeed {
  speed: number;
  averageSpeed: number;
  mainEngineAverageRpm: number;
}

export class NoonReportCondition {
  seaForce: number;
  seaForceDirection: string;
  windForce: number;
  windForceDirection: string;
  currentForce: number;
  currentForceDirection: string;
  forwardDraft: number;
  middleDraft: number;
  aftDraft: number;
}

export class NoonReportTankCondition {
  tankNo: string;
  product: string;
  quantity: number;
  temperature: number;
}

export class NoonReportBunker {
  bunkerType: string;
  quantityMT: number;
}

export class NoonReportLastReceived {
  receivedType: string;
  timestamp: number;
  quantityMT: number;
}

export class NoonReportLocation {
  vesselName: string;
  vesselIMO: string;
  date: number;
  reportType: string;
  from: string;
  to: string;
  eta: string;
  isLaden: boolean;
  geoCoordinate: GeoCoordinate;
  averageSpeed: number;
  isReal: boolean;
  voyageNumber: string;
  master: string;
  fileId: string;
}

export enum SailingStatus {
  ladenSteaming = 'ladenSteaming',
  ladenManeuvering = 'ladenManeuvering',
  ballastSteaming = 'ballastSteaming',
  ballastManeuvering = 'ballastManeuvering',
  drifting = 'drifting'
}

export interface BunkerConsumption {
  bunkerConsumption: NoonReportBunker[];
}

export class NoonReportSailingBunkerConsumption implements BunkerConsumption {
  status: SailingStatus;
  hours: number;
  averageRpm: number;
  bunkerConsumption: NoonReportBunker[];
}

export class NoonReportOtherBunkerConsumption implements BunkerConsumption {
  partName: string;
  bunkerConsumption: NoonReportBunker[];
}

export class NoonReportBunkerStatus {
  sailingBunkerConsumption: NoonReportSailingBunkerConsumption[];
  otherBunkerConsumption: NoonReportOtherBunkerConsumption[];
  rob: NoonReportBunker[];
  lastReceived: NoonReportLastReceived[];
}

export class LubOilStatus {
  mainEngineCylinderOilConsumption: number;
  mainEngineSystemOilConsumption: number;
  auxiliarySystemOilConsumption: number;
  mainEngineCylinderOilRob: number;
  mainEngineSystemOilRob: number;
  auxiliarySystemOilRob: number;
  lastReceived: NoonReportLastReceived[];
}

export class NoonReportV202005 {
  private static hfoVariants = ['lsfo', 'hfo'];
  private static mgoVariants = ['lsmgo', 'mgo'];

  vesselName: string;
  vesselIMO: string;
  date: NoonReportDate;
  reportType: string;
  voyageDetails: NoonReportVoyageDetails;
  master: string;
  isDeleted: boolean;
  position: NoonReportPosition;
  speed: NoonReportSpeed;
  condition: NoonReportCondition;
  tanksCondition: NoonReportTankCondition[];
  noonReportBunkerStatus: NoonReportBunkerStatus;
  lubOilStatus: LubOilStatus;
  fileId: string;
  comments: string;

  static getSailingBunkerConsumption(report: NoonReportV202005, sailingStatus: SailingStatus) {
    if (report.noonReportBunkerStatus.sailingBunkerConsumption) {
      return report.noonReportBunkerStatus.sailingBunkerConsumption
        .find(r => r.status === sailingStatus);
    }
  }

  static getOtherBunkerConsumption(report: NoonReportV202005, partName: string) {
    if (report.noonReportBunkerStatus.otherBunkerConsumption) {
      return report.noonReportBunkerStatus.otherBunkerConsumption
        .find(r => r.partName === partName);
    }
  }

  static getTotalBunkerConsumption(consumption: BunkerConsumption[], fuelType: string) {
    if (consumption) {
      return consumption.filter(s => s).map(s => s.bunkerConsumption
        .filter(b => {
          if (fuelType === 'lsfo') {
            return NoonReportV202005.hfoVariants.some(b1 => b1 === b.bunkerType);
          } else if (fuelType === 'lsmgo') {
            return NoonReportV202005.mgoVariants.some(b1 => b1 === b.bunkerType);
          }
        })
        .map(b => b.quantityMT).flatMap(i => i))
        .flatMap(i => i).reduce((i, j) => i + j, 0);
    }
  }

  static getRob(rob: NoonReportBunker[], fuelType: string) {
    if (rob) {
      return rob.filter(b => {
          if (fuelType === 'lsfo') {
            return NoonReportV202005.hfoVariants.some(b1 => b1 === b.bunkerType);
          } else if (fuelType === 'lsmgo') {
            return NoonReportV202005.mgoVariants.some(b1 => b1 === b.bunkerType);
          }
        }).map(b => b.quantityMT).flatMap(i => i)
        .flatMap(i => i).reduce((i, j) => i + j, 0);
    }
  }

  static getTotalSailingConsumption(sailingConsumptions: NoonReportSailingBunkerConsumption[], fuelType: string) {
    return NoonReportV202005.getTotalBunkerConsumption(sailingConsumptions, fuelType);
  }

  static getTotalOtherConsumption(otherConsumptions: NoonReportOtherBunkerConsumption[], fuelType: string) {
    return NoonReportV202005.getTotalBunkerConsumption(otherConsumptions, fuelType);
  }
}

export interface SelectedLocation {
  locations: NoonReportLocation[];
  selected: boolean;
  vesselName: string;
  vesselIMO: string;
}

export interface HighchartsSeries {
  name: string;
  type: string;
  visible: boolean;
  data: [number, number][];
}

export interface HighchartsData {
  id: string;
  divId: string;
  series: HighchartsSeries[];
}

@Injectable({
  providedIn: 'root'
})
export class NoonReportsService {

  private mapDisplayFixBounds: [number, number][] = [[30.43, 37.28], [36.86, 133.6], [-4.7, 109.69]];

  constructor(private _httpWrapper: HttpWrapper, private _router: Router,) { }

  static noonReporsAnalyseMapper(value: string) {
    const mapper = new Map<string, string>([
      ['hfoCons', 'HFO Cons'],
      ['hfoRob', 'HFO ROB'],
      ['lsfoCons', 'LSFO Cons'],
      ['lsfoRob', 'LSFO ROB'],
      ['mgoCons', 'MGO Cons'],
      ['mgoRob', 'MGO ROB'],
      ['lsmgoCons', 'LSMGO Cons'],
      ['lsmgoRob', 'LSMGO ROB'],
      ['hfoBalance', 'HFO Balance'],
      ['lsfoBalance', 'LSFO Balance'],
      ['mgoBalance', 'MGO Balance'],
      ['lsmgoBalance', 'LSMGO Balance'],
    ]);
    return mapper.get(value);
  }

  getNoonReportsV202005Between(dr: DateRange): Observable<NoonReportV202005[]> {
    return this._httpWrapper.get<NoonReportV202005[]>(`vessels/noon-reports-v20200521/${dr.startDate.getTime()}/${dr.endDate.getTime()}`);
  }

  getVesselNoonV202005(vesselIMO: string, dr: DateRange): Observable<NoonReportV202005[]> {
    return this._httpWrapper
    .get<NoonReportV202005[]>(`vessels/noon-reports-v20200521/${vesselIMO}/${dr.startDate.getTime()}/${dr.endDate.getTime()}`);
  }

  getLocationsInterpolated(dr: DateRange): Observable<NoonReportLocation[]> {
    return this._httpWrapper
    .get<NoonReportLocation[]>(`vessels/noon-reports-locations-interpolated/${dr.startDate.getTime()}/${dr.endDate.getTime()}`);
  }

  deleteNoonV202005(noon: NoonReportV202005) {
    return this._httpWrapper.put(`vessels/noon-report-v202005/delete`, noon);
  }

  getLocations(dr: DateRange): Observable<NoonReportLocation[]> {
    return this._httpWrapper.get<NoonReportLocation[]>(`vessels/noon-reports-locations/${dr.startDate.getTime()}/${dr.endDate.getTime()}`);
  }

  getLatestLocation(dt: Date): Observable<NoonReportLocation[]> {
    return this._httpWrapper.get<NoonReportLocation[]>(`vessels/noon-reports-locations/latest/${dt.getTime()}`);
  }

  getVesselLatestReport(): Observable<any[]> {
    return this._httpWrapper.get<any[]>(`vessels/noon-reports-v20200521/latest-report`);
  }

  getVesselLatestReportExcel() {
    return this._httpWrapper.download(`vessels/noon-reports-v20200521/latest-report/excel`);
  }

  getArrivalsAndDepartures(dr: DateRange): Observable<NoonReportV202005[]> {
    const reportTypes = ['arrival', 'departure'];
    return this._httpWrapper.get<NoonReportV202005[]>(`vessels/noon-reports-v20200521/${dr.startDate.getTime()}/${dr.endDate.getTime()}`)
      .pipe(map(reports => reports.filter(r => reportTypes.some(t => t === r.reportType.toLocaleLowerCase()))));
  }

  getLocationBounds(reports: SelectedLocation[]) {
    const points: number[][] = reports.filter(s1 => s1.selected).map(s1 => s1.locations)
      .flatMap(n => n.map(n1 => [n1.geoCoordinate.latitude, n1.geoCoordinate.longitude]));
    if (points.length <= 1) {
      return new L.LatLngBounds(this.mapDisplayFixBounds);
    } else {
      return new L.LatLngBounds(points);
    }
  }

  groupLocations(reports: NoonReportLocation[]): SelectedLocation[] {
    return _.chain(reports)
      .groupBy(item => `${item.vesselName}§${item.vesselIMO}`)
      .map((items, key) => {
        const vessel = key.split('§')[0];
        const imo = key.split('§')[1];
        return {
          locations: items.sort((a, b) => a.date - b.date),
          selected: true,
          vesselName: vessel,
          vesselIMO: imo
        };
      }).value();
  }

  getLocationPopupContent(noon: NoonReportLocation) {
    let ladenStatus = noon.isLaden ? 'LADEN' : 'BALLAST';
    if (noon.isLaden === null || noon.isLaden === undefined) {
      ladenStatus = 'Unknown';
    }
    return `
<a class="btn-link text-decoration-none" id="name"><h5>${noon.vesselName}</h5></a>
<table>
<tr>
<td>Date &nbsp;</td>
<td>${DateTimeHelpers.formatLocalDate(noon.date)}</td>
</tr>
<tr>
<tr>
<td>Report &nbsp;</td>
<td>${noon.reportType}</td>
</tr>
<tr>
<td>From</td>
<td>${noon.from}</td>
</tr>
<tr>
<td>To</td>
<td>${noon.to}</td>
</tr>
<tr>
<td>ETA</td>
<td>${noon.eta}</td>
</tr><tr>
<tr>
<td>Speed</td>
<td>${Math.round(noon.averageSpeed * 100) / 100 }</td>
</tr><tr>
<td>Cargo &nbsp; &nbsp;</td>
<td>${ladenStatus}</td>
</tr>
</table>
`;
  }

  getVesselLocationMarkerLayer(noon: NoonReportLocation) {
    let colour = noon.isLaden ? '#159653' : '#DD4B39';
    if (noon.isLaden === null || noon.isLaden === undefined) {
      colour = 'grey';
    }
    const diff = moment().diff(moment.unix(noon.date / 1000), 'hours');
    const opacity = diff < 24 ? 1.0 : 0.5;
    const markerOptions: L.MarkerOptions = {
      icon: L.divIcon({
        html: `<i class="fa fa-ship" style="font-size:20px; color:${colour}; opacity: 0.9">
               </i><br><i style="color: ${colour}">${noon.vesselName}</i>`,
        iconSize: [30, 30],
        className: 'myDivIcon'
      }),
      opacity
    };


    const marker = L.marker([noon.geoCoordinate.latitude, noon.geoCoordinate.longitude], markerOptions)
      .bindPopup(this.getLocationPopupContent(noon), {maxWidth: 500}).on("popupopen", (a) => {
        const popUp = a.target.getPopup();
        popUp.getElement().querySelector("#name").addEventListener("click", () => {
          this._router.navigate(['vessel', noon.vesselIMO]);
        });
      });
    return marker;
  }

  downloadConsumptionsReport(startTime: number, endTime: number) {
    return this._httpWrapper.download(`vessels/noon-reports-v20200521/bunker-consumption/${startTime}/${endTime}`);
  }

  downloadReports(startTime: number, endTime: number) {
    return this._httpWrapper.download(`vessels/noon-reports-v20200521/reports/${startTime}/${endTime}`);
  }

  importV202005Report(timestamp: number, fileList: FileList): Observable<GenericNoonReportValidationRule[]> {
    const formData = new FormData();
    formData.append('file', fileList.item(0));
    return this._httpWrapper.postMultiPart(`vessels/noon-report-v202005/${timestamp}`, formData);
  }


  calculateTypeConsumption(noonReport: NoonReportV202005, previousReport: NoonReportV202005) {
    const sailingConsumptions = noonReport.noonReportBunkerStatus.sailingBunkerConsumption
    .flatMap(c => c.bunkerConsumption);
    const otherConsumptions = noonReport.noonReportBunkerStatus.otherBunkerConsumption
    .flatMap(c => c.bunkerConsumption);
    const robMap = noonReport.noonReportBunkerStatus.rob.reduce((hashMap, data) => {
      hashMap[data.bunkerType] = data.quantityMT;
      return hashMap;
    }, {});

    const previousRobMap = (previousReport) ? previousReport.noonReportBunkerStatus.rob.reduce((hashMap, data) => {
      hashMap[data.bunkerType] = data.quantityMT;
      return hashMap;
    }, {}) : [];

    const lastReceivedRobMap = noonReport.noonReportBunkerStatus.lastReceived.reduce((hashMap, data) => {
      hashMap[data.receivedType] = data;
      return hashMap;
    }, {});
      const analyse = _(_.groupBy(sailingConsumptions.concat(otherConsumptions), (c) => c.bunkerType)).map((cons, key) => {
      const consumption = Math.round(_.sumBy(cons, c => c.quantityMT) * 100) / 100;
      const sum = Math.round((robMap[key] + consumption) * 100) / 100;
      const prevRob = previousRobMap[key];
      const reportDate = DateTimeHelpers.formatUTCDate(noonReport.date.timestamp);
      const lastReceivedDate =  lastReceivedRobMap[key] ? DateTimeHelpers.formatUTCDate(lastReceivedRobMap[key].timestamp) : '';
      const preDate = DateTimeHelpers.formatUTCDate(moment(noonReport.date.timestamp).subtract(1, 'days').unix() * 1000);
      const diff = ((sum - prevRob) > 10  && (lastReceivedDate === reportDate || preDate === lastReceivedDate)) ?
        sum - prevRob - lastReceivedRobMap[key].quantityMT : sum - prevRob;
      return {
        bunkerType: key,
        consumption,
        rob: robMap[key],
        previousRob: prevRob,
        sum,
        diff: Math.round(diff * 100) / 100,
        lastReceived: (lastReceivedDate === reportDate) ? lastReceivedRobMap[key].quantityMT : '',
        consumptionChart: [noonReport.date.timestamp, consumption],
        robChart: [noonReport.date.timestamp, robMap[key]],
      };
    }).value();

    return analyse.reduce((hashMap, data) => {
      hashMap[data.bunkerType] = data;
      return hashMap;
    }, {});
  }


  calculateAuxiliarySystemOilConsumption(noonReport: NoonReportV202005, previousReport: NoonReportV202005) {
    const previousRob = (previousReport) ? previousReport.lubOilStatus.auxiliarySystemOilRob: noonReport.lubOilStatus.auxiliarySystemOilRob;
    const sum = noonReport.lubOilStatus.auxiliarySystemOilConsumption + noonReport.lubOilStatus.auxiliarySystemOilRob;
    const lastReceived = noonReport.lubOilStatus.lastReceived.find(l => l.receivedType === 'AE SYS Oil');
    const lastReceivedDate =  lastReceived ? DateTimeHelpers.formatUTCDate(lastReceived.timestamp) : '';
    const reportDate = DateTimeHelpers.formatUTCDate(noonReport.date.timestamp);
    const preDate = DateTimeHelpers.formatUTCDate(moment(noonReport.date.timestamp).subtract(1, 'days').unix() * 1000);
    const diff = ((sum - previousRob) > 10  && (lastReceivedDate === reportDate || preDate === lastReceivedDate)) ?
      sum - previousRob - lastReceived.quantityMT : sum - previousRob;
    return {
      previousRob,
      consumption: noonReport.lubOilStatus.auxiliarySystemOilConsumption,
      rob: noonReport.lubOilStatus.auxiliarySystemOilRob,
      sum,
      diff: Math.round(diff * 100) / 100,
      lastReceivedQt: (lastReceivedDate === reportDate || preDate === lastReceivedDate) ? lastReceived.quantityMT : ''
    };
  }
  calculateMainEngineCylinderOilConsumption(noonReport: NoonReportV202005, previousReport: NoonReportV202005) {
    const previousRob = (previousReport) ? previousReport.lubOilStatus.mainEngineCylinderOilRob: noonReport.lubOilStatus.mainEngineCylinderOilRob;
    const sum = noonReport.lubOilStatus.mainEngineCylinderOilConsumption + noonReport.lubOilStatus.mainEngineCylinderOilRob;
    const lastReceived = noonReport.lubOilStatus.lastReceived.find(l => l.receivedType === 'ME CYL Oil');
    const lastReceivedDate =  lastReceived ? DateTimeHelpers.formatUTCDate(lastReceived.timestamp) : '';
    const reportDate = DateTimeHelpers.formatUTCDate(noonReport.date.timestamp);
    const preDate = DateTimeHelpers.formatUTCDate(moment(noonReport.date.timestamp).subtract(1, 'days').unix() * 1000);
    const diff = ((sum - previousRob) > 10  && (lastReceivedDate === reportDate || preDate === lastReceivedDate)) ?
      sum - previousRob - lastReceived.quantityMT : sum - previousRob;
    return {
      previousRob,
      consumption: noonReport.lubOilStatus.mainEngineCylinderOilConsumption,
      rob: noonReport.lubOilStatus.mainEngineCylinderOilRob,
      sum,
      diff: Math.round(diff * 100) / 100,
      lastReceivedQt: (lastReceivedDate === reportDate || preDate === lastReceivedDate) ? lastReceived.quantityMT : ''
    };
  }

  calculateMainEngineSystemOilConsumption(noonReport: NoonReportV202005, previousReport: NoonReportV202005) {
    const previousRob = (previousReport) ? previousReport.lubOilStatus.mainEngineSystemOilRob: noonReport.lubOilStatus.mainEngineSystemOilRob;
    const sum = noonReport.lubOilStatus.mainEngineSystemOilConsumption + noonReport.lubOilStatus.mainEngineSystemOilRob;
    const lastReceived = noonReport.lubOilStatus.lastReceived.find(l => l.receivedType === 'ME SYS Oil');
    const lastReceivedDate =  lastReceived ? DateTimeHelpers.formatUTCDate(lastReceived.timestamp) : '';
    const reportDate = DateTimeHelpers.formatUTCDate(noonReport.date.timestamp);
    const preDate = DateTimeHelpers.formatUTCDate(moment(noonReport.date.timestamp).subtract(1, 'days').unix() * 1000);
    const diff = ((sum - previousRob) > 10  && (lastReceivedDate === reportDate || preDate === lastReceivedDate)) ?
      sum - previousRob - lastReceived.quantityMT : sum - previousRob;
    return {
      previousRob,
      consumption: noonReport.lubOilStatus.mainEngineSystemOilConsumption,
      rob: noonReport.lubOilStatus.mainEngineSystemOilRob,
      sum,
      diff: Math.round(diff * 100) / 100,
      lastReceivedQt: (lastReceivedDate === reportDate || preDate === lastReceivedDate) ? lastReceived.quantityMT : ''
    };
  }

  calculateOilCunsumption(noonReport: NoonReportV202005, previousReport: NoonReportV202005) {
    return {
      auxiliarySystemOil: this.calculateAuxiliarySystemOilConsumption(noonReport, previousReport),
      mainEngineCylinderOil: this.calculateMainEngineCylinderOilConsumption(noonReport, previousReport),
      mainEngineSystemOil: this.calculateMainEngineSystemOilConsumption(noonReport, previousReport),
    };
  }

  fuelConsumptionReport(noonReports: NoonReportV202005[]) {
    const grouped = _.groupBy(noonReports, (item) => item.vesselIMO);
    for (const l of Object.keys(grouped)) {
      grouped[l] = grouped[l].sort((a, b) => a.date.timestamp - b.date.timestamp);
    }
    return _(grouped).map((items, key) => {
      const analysis = items.map((noonReport, index) => {
        const previousReport = (index > 0) ? items[index - 1] : null;
        return {
          date: DateTimeHelpers.formatUTCDate(noonReport.date.timestamp),
          reportType: noonReport.reportType,
          consumptions: this.calculateTypeConsumption(noonReport, previousReport),
          oilConsumptions: this.calculateOilCunsumption(noonReport, previousReport)
        };
      });
      const consumptions = analysis.map(r =>({
        hfoCons: r.consumptions['hfo'].consumptionChart,
        hfoRob:  r.consumptions['hfo'].robChart,
        lsfoCons:  r.consumptions['lsfo'].consumptionChart,
        lsfoRob:  r.consumptions['lsfo'].robChart,
        mgoCons:  r.consumptions['mgo'].consumptionChart,
        mgoRob:  r.consumptions['mgo'].robChart,
        lsmgoCons:  r.consumptions['lsmgo'].consumptionChart,
        lsmgoRob:  r.consumptions['lsmgo'].robChart
      }));

      const diff = analysis.map(r =>({
        hfoDiff: r.consumptions['hfo'].diff,
        lsfoDiff:  r.consumptions['lsfo'].diff,
        mgoDiff:  r.consumptions['mgo'].diff,
        lsmgoDiff:  r.consumptions['lsmgo'].diff,
      }));

      const balances = [
        {id: 'hfoBalance', value: Math.round(diff.map(a => a.hfoDiff).reduce((a, b) => ((b) ? a + b : a + 0), 0) * 100) / 100},
        {id: 'lsfoBalance', value: Math.round(diff.map(a => a.lsfoDiff).reduce((a, b) => ((b) ? a + b : a + 0), 0) * 100) / 100},
        {id: 'mgoBalance', value:  Math.round(diff.map(a => a.mgoDiff).reduce((a, b) => ((b) ? a + b : a + 0), 0) * 100) / 100},
        {id: 'lsmgoBalance', value:  Math.round(diff.map(a => a.lsmgoDiff).reduce((a, b) => ((b) ? a + b : a + 0), 0) * 100) / 100},
      ].filter(b => b.value > 0);

      const highchartsData = this.groupedForHighCharts(consumptions);
      const sumConsumptions = highchartsData.filter(h => !h.id.includes('Rob')).map(h => ({
        id: h.id,
        sum: Math.round(this.sumConsumptions(h.series[0].data))
      }));
      return {
        vesselname: items[0].vesselName,
        vesselIMO: key,
        distanceCovered: this.sumDistanceCovered(items),
        analysis,
        highchartsData,
        sumConsumptions,
        balances
      };
    }
    ).value()[0];
  }

  sumConsumptions(consumptions: [number, number][]) {
    return consumptions.reduce((a, b) => (a + b[1]), 0);
  }

  groupedForHighCharts(consumptions: any) {
    const charts: HighchartsData[] =
      [
        {
          id: 'hfoCons',
          divId: 'hfoConsDiv',
          series:[{
            name: 'HFO Consumption',
            type: 'line',
            visible: true,
            data: consumptions.map(c => c.hfoCons)
          }]
        },
        {
          id: 'hfoRob',
          divId: 'hfoRobDiv',
          series: [
            {
          name: 'HFO ROB',
          type: 'line',
          visible: true,
          data: consumptions.map(c => c.hfoRob)
            }
          ]
        },
        {
          id: 'lsfoCons',
          divId: 'lsfoConsDiv',
          series: [
        {
          name: 'LSFO Consumption',
          type: 'line',
          visible: true,
          data: consumptions.map(c => c.lsfoCons)
        }]
        },
        {
          id: 'lsfoRob',
          divId: 'lsfoRobDiv',
          series: [
            {
              name: 'LSFO ROB',
              type: 'line',
              visible: true,
              data: consumptions.map(c => c.lsfoRob)
            }
          ]
        },
        {
          id: 'mgoCons',
          divId: 'mgoConsDiv',
          series: [
            {
              name: 'MGO Consumption',
              type: 'line',
              visible: true,
              data: consumptions.map(c => c.mgoCons)
            }
          ]
        },
        {
          id: 'mgoRob',
          divId: 'mgoRobDiv',
          series: [
            {
              name: 'MGO ROB',
              type: 'line',
              visible: true,
              data: consumptions.map(c => c.mgoRob)
            }
          ]
        },
        {
          id: 'lsmgoCons',
          divId: 'lsmgoConsDiv',
          series: [
            {
              name: 'LSMGO Consumption',
              type: 'line',
              visible: true,
              data: consumptions.map(c => c.lsmgoCons)
            }
          ]
        },
        {
          id: 'lsmgoRob',
          divId: 'lsmgoRobDiv',
          series: [
            {
              name: 'LSMGO ROB',
              type: 'line',
              visible: true,
              data: consumptions.map(c => c.lsmgoRob)
            }
          ]
        }
      ];
    return charts.filter(s =>this.sumConsumptions(s.series[0].data) > 0);
  }

  sumDistanceCovered(noonReports: NoonReportV202005[]) {
    return noonReports.map(a => a.voyageDetails.distanceCovered).reduce((a, b) => (a + b), 0);
  }

}

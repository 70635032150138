import { Component, OnInit } from '@angular/core';
import {LoaderService} from './loader.service';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {

  constructor(private _loaderService: LoaderService,
              private spinner: NgxSpinnerService) { }

  ngOnInit() {

    this._loaderService.isLoading.asObservable().subscribe(v => {
      if (v) {
        this.spinner.show().then();
      } else {
        this.spinner.hide().then();
      }
    });
  }

}

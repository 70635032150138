import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CrewContract} from '../../crew-change.service';
import {KeycloakService} from '../../../../../authentication/keycloak.service';
import {Organisation, OrganisationService} from '../../../../../organisation/organisation.service';
import { DocumentService } from 'src/app/documents/documents.service';
import { saveAs } from 'file-saver';


@Component({
  selector: 'app-crew-contract',
  templateUrl: './crew-contract.component.html'
})
export class CrewContractComponent implements OnInit {

  crewContract: CrewContract;
  managements: Organisation[];
  selectedManagement: Organisation;
  _title: string;

  get selectedCrewContract() {
    return this.crewContract;
  }
  @Input() set selectedCrewContract(value: CrewContract) {
    if (value) {
      this.crewContract = value;
    }
  }

  @Input() set title(value: string) {
    if (value) {
      this._title = value;
    }
  }

  @Output() saveCrewContract: EventEmitter<CrewContract> = new EventEmitter<CrewContract>();

  constructor(private _keycloakService: KeycloakService,
              private _organisationService: OrganisationService,
            private _documentService: DocumentService) { }

  hasRole(roleName: string) {
    return this._keycloakService.hasRole(roleName);
  }

  ngOnInit() {
    this._organisationService.get().subscribe(organisations => {
      this.managements = organisations.filter(
        (x) => x.properties['technical'] === true
      );
      if (this.crewContract && this.crewContract.managementOrganisationId) {
        this.selectedManagement = this.managements.find(x => x.name === this.crewContract.managementOrganisationId);
      }
    });
    if (!this.crewContract) {
      this.crewContract = {
        contractDocumentId: null,
        date: null,
        basicSalaryUSD: null,
        durationInMonths: null,
        guaranteedOvertimeUSD: null,
        leavePayUSD: null,
        leaveSubsistenceUSD: null,
        managementOrganisationId: null,
        tankerAllowanceUSD: null,
        extensions: []
      };
    }
  }

  saveContract() {
    this.crewContract.managementOrganisationId = this.selectedManagement.name;
    this.saveCrewContract.emit(this.crewContract);
  }

  saveContractExtension(event, i) {
    this.crewContract.extensions[i] = event;
    this.saveContract();
  }

  downLoadDocumnet(id) {
    this._documentService.download(id).subscribe(f => {
      saveAs(f.body, `${id}.pdf`);
    });
  }
}

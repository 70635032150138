<div class="row">
  <div class="col-md-6">
    <fieldset style="border: 0;" [disabled]="!hasRole('trucks-editor')">
      <form class="form-horizontal" #tankerDataEntry="ngForm">
        <div class="card card-body">
          <div class="form-group row">
            <label for="tankernumber" class="col-md-4 ">شماره تانکر</label>
            <div class="col-md-8">
              <input type="text"
                class="form-control"
                name="tankerNumber"
                id="tankerNumber"
                [disabled]="edit"
                [ngModel]="tankerNumber"
                (ngModelChange)="tankerNumber = $event"
                tabindex="1"
                required>
            </div>
          </div>
          <div class="form-group row">
            <label for="owner" class="col-form-label col-md-4 ">دارنده
              تانکر</label>
            <div class="col-md-8">
              <app-organisation-selector class="pg-component"
                [(organisation)]="organisation" [organisationLanguage]="'fa'"
                name="organisation" [organisations]="organisations"
                [disabled]="edit" required>
              </app-organisation-selector>
            </div>
          </div>
          <div class="form-group row">
            <label for="capacity" class="col-form-label col-md-4 ">ظرفیت</label>
            <div class="col-md-8">
              <input type="text"
                class="form-control"
                name="capacity"
                id="capacity"
                [(ngModel)]="capacity"
                tabindex="2"
                required>
            </div>
          </div>
          <div class="form-group row">
            <label for="selectedDate" class="col-form-label col-md-4 ">تاریخ تست
              تانکر</label>
            <div class="col-md-8">
              <app-date-range id="selectedDate"
                [singleDateSelector]="true"
                [(dateRange)]="dateRange"
                [jalali]="true"
                tabindex="4">
              </app-date-range>
            </div>
          </div>
          <div class="form-group row">
            <label for="licenseNumber" class="col-form-label col-md-4 ">شماره
              گواهینامه</label>
            <div class="col-md-8">
              <input type="number"
                class="form-control"
                name="licenseNumber"
                id="licenseNumber"
                [(ngModel)]="licenseNumber"
                tabindex="5"
                required>
            </div>
          </div>
          <div class="form-group row">
            <label for="licenseNumber"
              class="col-form-label col-md-4 ">File</label>
            <div class="col-md-8">
              <app-chunk-file-uploader
                [accept]="'.pdf'"
                name="document"
                (filesChanged)="uploadDocument($event)">
              </app-chunk-file-uploader>
            </div>
          </div>
          <div class="form-group row">
            <div>
              <button type="submit"
                class="btn btn-primary pull-right"
                [disabled]="!tankerDataEntry.form.valid"
                (click)="save()"
                tabindex="15">Save
              </button>
            </div>
          </div>
        </div>
        <div class="card card-body">
          <div class="col-md-12">
            <table
              class="table table-sm table-striped table-bordered">
              <thead>
                <tr>
                  <th>شماره کشنده</th>
                  <th>تاریخ شروع</th>
                  <th>تاریخ پایان</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let object of history">
                  <td>{{object.truckNumber}}</td>
                  <td>{{dateFormatter(object.startDate)}}</td>
                  <td>{{dateFormatter(object.endDate)}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </form>
    </fieldset>
  </div>
  <div class="col-md-6" *ngIf="uploadedFileId">
    <app-pdf-viewer [fileId]="uploadedFileId"></app-pdf-viewer>
  </div>
</div>

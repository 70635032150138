<div class="row">
  <div class="col-md-6">
    <form #lpgDistEntry="ngForm" class="form-horizontal" >
      <div class="card card-body">
        <div class="form-group row">
          <label for="loadingDate" class="col-form-label col-md-4 ">تاریخ بارگیری</label>
          <div class="col-md-8">
            <app-date-range
            id="loadingDate"
            [singleDateSelector]="true"
            [(dateRange)]="selectedDate"
            [disabled]="disabled"
            [jalali]="true"
            >
        </app-date-range>
          </div>
        </div>
        <div class="form-group row">
          <label for="company1" class="col-form-label col-md-4 ">نام شرکت</label>
          <div class="col-md-8">
            <app-organisation-selector
            class= "pg-component"
            [(organisation)]="company"
            [organisationLanguage]= "'fa'"
            name="organisation"
            [organisations]="organisations"
            [disabled]="disabled"
            required>
           </app-organisation-selector>
        </div>
        </div>
        <div class="form-group row ">
          <label for="loadingBillNumber" class="col-form-label col-md-4 "> شماره بارنامه</label>
          <div class="col-md-8">
              <input
              [disabled]="mode==='edit'"
              type="text"
              class="form-control"
              name="loadingBillNumber"
              id="loadingBillNumber"
              [ngModel]="lpgDistData?.billOfLadingNumber"
              (ngModelChange)="lpgDistData.billOfLadingNumber = $event"
              required>
          </div>
        </div>
        <div class="form-group row">
          <label for="source2" class="col-form-label col-md-4 ">مبدا</label>
          <div class="col-md-8 ">
            <app-organisation-selector id="source2"
            class= "pg-component"
            [(organisation)]="source"
            [organisationLanguage]= "'fa'"
            name="source2"
            [organisations]="sources"
            [disabled]="disabled"
            required>
           </app-organisation-selector>
          </div>
        </div>
        <div class="form-group row ">
            <label for="truck" class="col-form-label col-md-4 ">شماره کشنده</label>
            <div class="col-md-8">
              <app-auto-complete
                [formatter]="truckValueFormatter"
                [(ngModel)]="truck"
                [items]="trucks"
                name="truck">
              </app-auto-complete>
            </div>
        </div>
        <div class="form-group row">
          <label for="primaryDestination" class="col-form-label col-md-4 "> مقصد ابتدایی</label>
          <div class="col-md-8">
            <app-organisation-selector id="primaryDestination" class= "pg-component"
            [(organisation)]="primaryDestination"
            [organisationLanguage]= "'fa'"
            (click)="changeDestination()"
            name="primaryDestination"
            [organisations]="destinations"
            required>
           </app-organisation-selector>
        </div>
        </div>
        <div class="form-group row">
          <label for="destination2" class="col-form-label col-md-4 ">مقصد</label>
          <div class="col-md-8">
            <app-organisation-selector id="destination2" class= "pg-component"
            [(organisation)]="destination"
            [organisationLanguage]= "'fa'"
            name="destination2"
            [organisations]="destinations"
            required>
           </app-organisation-selector>
        </div>
        </div>
        <div class="form-group row ">
            <label for="weight" class="col-form-label col-md-4 ">وزن</label>
            <div class="col-md-8">
              <input 	type="text"
                      name="weight"
                      id="weight"
                      class="form-control"
                      [disabled]="disabled"
                      [ngModel]="lpgDistData?.loadingWeight"
                      (ngModelChange)="lpgDistData.loadingWeight = $event"
                      required>
            </div>
        </div>
        <div class="form-group row ">
          <label for="comment" class="col-form-label col-md-4">توضیحات</label>
          <div class="col-md-8">
              <textarea name="comment"
                        id="comment"
                        class="form-control"
                        rows="1"
                        [ngModel]="lpgDistData?.comments"
                        (ngModelChange)="lpgDistData.comments = $event">
            </textarea>
          </div>
        </div>
        <div class="form-group row">
          <div class="">
            <input type="button"
            name="pay"
              value="Save"
                class="btn btn-primary  pull-right"
                (click)="onSubmit()"
                  [disabled]="!lpgDistEntry.form.valid">
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="col-md-6"  *ngIf ="mode !=='edit'">
    <table
        class="table table-sm table-striped  table-bordered">
      <thead >
      <tr>
        <td>شرکت</td>
        <td>مبدا</td>
        <td> مجموع برداشت</td>
      </tr>
      </thead>
      <tr *ngFor="let sum of sourceSummary">
        <td>{{sum.company }}</td>
        <td>{{sum.name }}</td>
        <td>{{sum.totalWeight | number }}</td>
      </tr>
    </table>
  </div>
  <div class="col-md-12" *ngIf ="mode !=='edit'">
    <table
      class="table table-sm table-striped  table-bordered ">
      <thead>
        <tr>
          <td>ویرایش</td>
          <td>تاریخ</td>
          <td>مبدا</td>
          <td>شماره بارنامه</td>
          <td>نام راننده</td>
          <td>شماره کشنده</td>
          <td>شماره تانکر</td>
          <td>مقصد</td>
          <td>وزن</td>
        </tr>
      </thead>
      <tr *ngFor="let dt of importedData; let i=index;">
        <td >
          <button id="edit" name="edit" type="submit" class="btn btn-link" (click)="editRecord(dt, i)">
              <i class="fa fa-pencil-square-o"></i>
          </button>
        </td>
        <td>{{dt.loadTimestamp | persianDate}}</td>
        <td>{{dt.source}}</td>
        <td>{{dt.billOfLadingNumber}}</td>
        <td>{{dt.driverName}}</td>
        <td>{{dt.truckNumber}}</td>
        <td >{{dt.tankNumber}}</td>
        <td>{{dt.destination}}</td>
        <td>{{dt.loadingWeight | number}}</td>
      </tr>
    </table>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <table datatable
           [dtOptions]="dtOptions"
           [dtTrigger]="dtTrigger"
           class="table table-sm table-striped table-bordered">
      <thead>
      <tr>
        <td>Confirm</td>
        <td>User Id</td>
        <td>Portal full name</td>
        <td>Email</td>
        <td>DIgital Ids</td>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let v of userProfiles">
        <td>
          <a [routerLink]="['/security/digital-id/',v.portalUserName]" >
            <i class="fa fa-info"></i></a>
          <i class="fa fa-check-square-o text-success" *ngIf = "v.confirm" ></i>
          <button id="edit" name="edit" type="submit" class="btn btn-link"
                  (click)="editRecord(v)" 
                  *appHideIfNotInRole="['digital-ids-editor']">
            <i class="fa fa-pencil-square-o"></i>
          </button>
        </td>
        <td>{{v.portalUserName}}</td>
        <td>{{v.fullName}}</td>
        <td>{{v.email}}</td>
        <td>{{formatDigitalIdsList(v.digitalIdFiles)}}</td>
      </tr>
      </tbody>
    </table>
  </div>
</div>

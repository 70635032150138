<section class="content">
  <div class="row">
    <div class="col-lg-3">
      <div class="card shadow-sm card-body bg-info info-box">
        <div class="inner">
          <h3>{{numberOfCrewOnBoard}}</h3>
          <p>Crew onboard</p>
        </div>
        <div class="icon">
          <i class="icon icon-bag"></i>
        </div>
      </div>
    </div>
    <div class="col-lg-3">
      <div class="card shadow-sm card-body bg-info info-box">
        <div class="inner">
          <h3>{{numberOfMastersOnboard}}</h3>
          <p>Masters onboard</p>
        </div>
        <div class="icon">
          <i class="icon icon-bag"></i>
        </div>
      </div>
    </div>
    <div class="col-lg-3">
      <div class="card shadow-sm card-body bg-info info-box">
        <div class="inner">
          <h3>{{numberOfOnSignersInLast30Days}}</h3>
          <p>On signers in the last 30 days</p>
        </div>
        <div class="icon">
          <i class="icon icon-bag"></i>
        </div>
      </div>
    </div>
    <div class="col-lg-3">
      <div class="card shadow-sm card-body bg-info info-box">
        <div class="inner">
          <h3>{{numberOfOffSignersInLast30Days}}</h3>
          <p>Off signers in the last 30 days</p>
        </div>
        <div class="icon">
          <i class="icon icon-bag"></i>
        </div>
      </div>
    </div>
  </div>
</section>
<div class="row">
  <div class="col-md-6">
    <h2>Masters onboard</h2>
    <app-onboards [onBoards]="onBoards" [ranks]="['Master']"></app-onboards>
  </div>
  <div class="col-md-6">
    <h2>Chiefs onboard</h2>
    <app-onboards [onBoards]="onBoards"
      [ranks]="['Chief Engineer', 'Chief Officer', 'Second Engineer']"></app-onboards>
  </div>
</div>

<div class="row">
  <h2>Changes</h2>
  <app-crew-changes [crews]="crews"
    [organisationsMap]="organisationsMap"></app-crew-changes>
</div>

import { Component, OnInit, Output, EventEmitter, forwardRef, Input, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export interface AutoCompleteObject {
  label: string;
  item: any;
}

@Component({
    selector: 'app-auto-complete',
    template: `
    <div>
      <input
        autocomplete="off"
        class="form-control"
        [attr.list]="ids"
        [name]="id"
        [id]="id"
        type="text"
        [value]= _value
        (input)="valueChanged($event)"
        [disabled]="_disabled"
        [readOnly]="_disabled"
        required>
      <datalist [id]="ids">
        <option *ngFor="let item of _itemsList">
          {{item.label}}
        </option>
      </datalist>
    </div>`,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => AutoCompleteComponent),
  }],
  encapsulation: ViewEncapsulation.None
})
export class AutoCompleteComponent implements OnInit, ControlValueAccessor {
  @Input() formatter: (r: any) => string;
  @Input() id: string;
  @Input() set disabled(value: boolean) {
      this._disabled = value;
  }
  @Input() set items(value: any[]) {
    if (value) {
      this._itemsList = value.map(r => ({
        label: this.formatter(r),
        item: r
      }));
    }
  }
  @Output() itemChanged: EventEmitter<any> = new EventEmitter<any>();
  ids: string;
  item: any;
  _disabled: boolean;
  _itemsList: AutoCompleteObject[] = [];
  _valid = false;
  _value = '';
  private _onChangeCallback: any;
  private _onTouchedCallback: any;

  randomiseIds(length: number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }


  valueChanged(event: any) {
    this.item = this._itemsList.find(o => o.label && (o.label.replace(/[\t\n ]/g,'') === event.target.value.replace(/[\t\n ]/g,'')));
    if (this.item) {
      this.callChangeCallback(this.item.item);
      this.itemChanged.emit(this.item.item);
    } else {
      this.callChangeCallback(this.item);
      this.itemChanged.emit(this.item);
    }
    if(this._onTouchedCallback) {
      this._onTouchedCallback();
    }
  }

  ngOnInit() {
    this.ids = this.randomiseIds(10);
  }

  registerOnChange(fn: any): void {
    this._onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouchedCallback = fn;
  }

  writeValue(obj: any): void {
    if (obj) {
      this.item = {
        label: this.formatter(obj),
        item: obj
      };
      this._value = this.formatter(obj);
    }
  }

  private callChangeCallback(value: any) {
    if (this._onChangeCallback) {
      this._onChangeCallback(value);
    }
  }
}

import { Injectable } from '@angular/core';
import {HttpWrapper} from '../shared/services';

export interface DigitalIdFile {
  email: string;
  fileId: string;
}

export interface UserProfile {
  portalUserName: string;
  profileImageId: string;
  email: string;
  fullName: string;
  digitalIdFiles: DigitalIdFile[];
  confirm: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class SecurityService {

  constructor(private _httpWrapper: HttpWrapper) { }

  get() {
    return this._httpWrapper.get<UserProfile[]>('security/profiles');
  }

  add(userProfile: UserProfile) {
    return this._httpWrapper.post('security/profile', userProfile);
  }

  getById(portalUserName: string) {
    return this._httpWrapper.get<UserProfile>(`security/profile/${portalUserName}`);
  }

  getByPortalUserName() {
    return this._httpWrapper.get<UserProfile>(`security/profile/userProfile/id`);
  }

  update(userProfile: UserProfile) {
    return this._httpWrapper.put<UserProfile, UserProfile>(`security/profile`, userProfile);
  }

  upsert(userProfile: UserProfile) {
    return this._httpWrapper.put<UserProfile, UserProfile>(`security/upsert/profile`, userProfile);
  }
}

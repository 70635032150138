<div>
  <fieldset style="border: 0;"
    [disabled]="!hasRole('vessels-crew-change-editor')">
    <form class="form-horizontal" #CrewChangeContract="ngForm">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Contract {{_title}}</h3>
          <a (click)="downLoadDocumnet(crewContract.contractDocumentId)"
            *ngIf="crewContract.contractDocumentId"
            class="pull-right">
            <i class="fa fa-download" aria-hidden="true"></i>
          </a>
        </div>
        <div class="card-body">
          <div class="form-group row">
            <label for="contractDate"
              class="col-form-label col-md-4 ">Date</label>
            <div class="col-md-8">
              <input type="date"
                class="form-control"
                id="contractDate"
                name="contractDate"
                [(ngModel)]="crewContract.date"
                required />
            </div>
          </div>
          <div class="form-group row">
            <label for="managementOrganisation"
              class="col-form-label col-md-4 ">Management</label>
            <div class="col-md-8">
              <app-organisation-selector
                [(organisation)]="selectedManagement"
                [organisations]="managements"
                [organisationLanguage]="'en'"
                [disabled]="!hasRole('vessels-crew-change-editor')"
                class="pg-component"
                name="managementOrganisation"
                id="managementOrganisation"
                required>
              </app-organisation-selector>
            </div>
          </div>
          <div class="form-group row">
            <label for="durationInMonths"
              class="col-form-label col-md-4 ">Duration (months)</label>
            <div class="col-md-8">
              <input type="number"
                class="form-control"
                id="durationInMonths"
                name="durationInMonths"
                [(ngModel)]="crewContract.durationInMonths"
                required />
            </div>
          </div>
          <div class="form-group row">
            <label for="basicSalaryUSD" class="col-form-label col-md-4 ">Basic
              Salary</label>
            <div class="col-md-8">
              <input type="number"
                class="form-control"
                id="basicSalaryUSD"
                name="basicSalaryUSD"
                [(ngModel)]="crewContract.basicSalaryUSD"
                required />
            </div>
          </div>
          <div class="form-group row">
            <label for="guaranteedOvertimeUSD"
              class="col-form-label col-md-4 ">Guaranteed overtime</label>
            <div class="col-md-8">
              <input type="number"
                class="form-control"
                id="guaranteedOvertimeUSD"
                name="guaranteedOvertimeUSD"
                [(ngModel)]="crewContract.guaranteedOvertimeUSD"
                required />
            </div>
          </div>
          <div class="form-group row">
            <label for="leavePayUSD" class="col-form-label col-md-4 ">Leave
              pay</label>
            <div class="col-md-8">
              <input type="number"
                class="form-control"
                id="leavePayUSD"
                name="leavePayUSD"
                [(ngModel)]="crewContract.leavePayUSD"
                required />
            </div>
          </div>
          <div class="form-group row">
            <label for="leaveSubsistenceUSD"
              class="col-form-label col-md-4 ">Leave Subsistence</label>
            <div class="col-md-8">
              <input type="number"
                class="form-control"
                id="leaveSubsistenceUSD"
                name="leaveSubsistenceUSD"
                [(ngModel)]="crewContract.leaveSubsistenceUSD"
                required />
            </div>
          </div>
          <div class="form-group row">
            <label for="tankerAllowanceUSD"
              class="col-form-label col-md-4 ">Tanker Allowance</label>
            <div class="col-md-8">
              <input type="number"
                class="form-control"
                id="tankerAllowanceUSD"
                name="tankerAllowanceUSD"
                [(ngModel)]="crewContract.tankerAllowanceUSD"
                required />
            </div>
          </div>
          <div class="form-group row">
            <label for="estimatedSalaryContractUSD"
              class="col-form-label col-md-4 ">Total</label>
            <div class="col-md-8">
              <output name="estimatedSalaryContractUSD" id="estimatedSalaryContractUSD">
                <b>
                  {{crewContract.basicSalaryUSD +
                crewContract.guaranteedOvertimeUSD +
                crewContract.leavePayUSD +
                crewContract.leaveSubsistenceUSD +
                crewContract.tankerAllowanceUSD }}
                </b>
              </output>
            </div>
          </div>
          <div class="form-group row">
            <label for="contractFile"
              class="col-form-label col-md-4 ">Contract</label>
            <div class="col-md-8">
              <app-chunk-file-uploader
                name="contractFile"
                id="contractFile"
                [accept]="'.pdf'"
                (filesChanged)="crewContract.contractDocumentId = $event.id"
                [required]="true" />
            </div>
          </div>
          <div class="btn-group pull-right mt-3">
            <button
              type="button"
              id="saveContract"
              class="btn btn-outline-secondary btn-light  me-1"
              *ngIf="hasRole('vessels-crew-change-editor')"
              [disabled]="!CrewChangeContract.form.valid || !crewContract.contractDocumentId"
              (click)="saveContract()">
              Save
            </button>
          </div>
        </div>
      </div>
    </form>
  </fieldset>
  <fieldset
    style="border: 0;"
    [disabled]="!hasRole('vessels-crew-change-editor')"
    *ngFor="let extension of crewContract.extensions,let i=index">
    <app-crew-contract
      [selectedCrewContract]="extension"
      [title]="'Extension No. ' +  (i + 1)"
      (saveCrewContract)="saveContractExtension($event, i)">
    </app-crew-contract>
  </fieldset>
</div>

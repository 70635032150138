<div class="row" *ngIf="selectedOrganisation">
  <div class="col-md-6">
    <div class="col-md-12 card" style="padding: 5px">
      <div class="row">
        <div class="col-md-12">
          <div class="card ">
            <div class="card-header">
              <div class="col-md-12 bg-secondary" style="text-align: center;">
                برداشت شرکت
                {{selectedOrganisation.nameTranslations['fa']}}
              </div>
            </div>
            <div class="card-body">
              <table style="text-align: center"
                class="table table-sm table-striped   table-bordered ">
                <thead>
                  <tr>
                    <td>مبدا</td>
                    <td>تعیین مبدا</td>
                    <td> مجموع برداشت</td>
                    <td>مانده</td>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let sum of sourceSummary">
                    <td>{{organisationNameFormatter(sum.name) }}</td>
                    <td>{{sum.totalQuota | number }}</td>
                    <td>{{sum.totalWeight | number }}</td>
                    <td>{{sum.remained | number }}</td>
                  </tr>
                  <tr class="bg-secondary">
                    <td>مجموع</td>
                    <td>{{totalQuota | number}}</td>
                    <td>{{totalWeight | number}}</td>
                    <td>{{totalremaind | number}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 card" style="padding: 5px">
      <div class="row">
        <div class="col-md-12">
          <div class="card ">
            <div class="card-header">
              <div class="col-md-12 bg-secondary" style="text-align: center;">
                تخلیه شرکت
                {{selectedOrganisation.nameTranslations['fa']}}
              </div> 
            </div>
            <div class="card-body">
              <table style="text-align: center"
                class="table table-sm table-striped   table-bordered ">
                <thead>
                  <tr>
                    <td>مقصد </td>
                    <td>تعیین مبدا</td>
                    <td>تخلیه</td>
                    <td>مانده</td>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let sum of destinationSummary">
                    <td>{{organisationNameFormatter(sum.name) }}</td>
                    <td>{{sum.totalQuota | number }}</td>
                    <td>{{sum.totalWeight | number }}</td>
                    <td>{{sum.remained | number }}</td>
                  </tr>
                  <tr class="bg-secondary">
                    <td>مجموع</td>
                    <td>{{totalQuota | number}}</td>
                    <td>{{totalWeight | number}}</td>
                    <td>{{totalremaind | number}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="col-md-12 card" style="padding: 5px">
      <div class="row">
        <div class="col-md-12">
          <div class="card ">
            <div class="card-header">
              <div class="col-md-12 bg-secondary" style="text-align: center;">   
                شعاع حمل    {{selectedOrganisation.nameTranslations['fa']}} = {{average}}
              </div> 
            </div>
            <div class="card-body">
              <table style="text-align: center"
                class="table table-sm table-striped   table-bordered ">
                <thead>
                  <tr>
                    <td></td>
                    <td>مبدا </td>
                    <td>مقصد </td>
                    <td>تعیین مبدا </td>
                    <td>بارگیری از سهمیه </td>
                    <td>مجموع تخلیه </td>
                    <td>مانده </td>
                    <td>فاصله </td>
                    <td>هزینه </td>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let sum of costSummary">
                    <td><button name="delete" *ngIf="sum.quota" type="button"
                        class="btn btn-link" (click)="delete(sum)">
                        <i class="fa fa-trash-o"></i></button></td>
                    <td>{{ organisationNameFormatter(sum.source) }}</td>
                    <td>{{ organisationNameFormatter(sum.destination) }}</td>
                    <td>{{ sum.quota | number }}</td>
                    <td>{{ sum.primaryWeight | number }}</td>
                    <td>{{ sum.totalWeight | number }}</td>
                    <td>{{ sum.remaind | number}}</td>
                    <td>{{ sum.distance | number }}</td>
                    <td>{{ sum.cost | number }}</td>
                  </tr>
                  <tr class="bg-gray">
                    <td></td>
                    <td>مجموع</td>
                    <td></td>
                    <td>{{totalQuota | number}}</td>
                    <td>{{totalLoading | number}}</td>
                    <td>{{totalWeight | number}}</td>
                    <td>
                      {{totalremaind | number}}
                    </td>
                    <td></td>
                    <td>{{totalCost| number}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

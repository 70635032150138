import { Component, Input } from '@angular/core';
import { Crew, CrewChange, CrewChangeServices } from '../crew-change.service';
import { DateTimeHelpers } from '../../../../shared/helpers/date-time.helpers';
import moment from 'moment/moment';

@Component({
  selector: 'app-crew-assignments',
  templateUrl: './crew-assignments.component.html',
  styleUrls: ['./crew-assignments.component.css']
})
export class CrewAssignmentsComponent {

  selectedCrewPreviousAssignments: CrewChange[];

  @Input()
  set crew(value: Crew) {
    if(value) {
        this.selectedCrewPreviousAssignments = value.crewChanges;
    }
  }

  constructor(private _crewChange: CrewChangeServices) { }

  dateTime(timestamp: number) {
    if (timestamp) {
      return DateTimeHelpers.formatLocalDateTime(timestamp);
    }
  }

  crewChangeFindPlan(ch: CrewChange, plan: string) {
    return ch.item?.find(c => c.plan === plan);
  }

  duration(ch: CrewChange) {
    const on = this.crewChangeFindPlan(ch, 'On Board');
    const off = this.crewChangeFindPlan(ch, 'Off Board');
    if (on && off) {
      return moment(off?.time)
        .diff(moment(on?.time), 'months', true)
        .toFixed(2);
    } else {
      return '-';
    }
  }

}

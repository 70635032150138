import {Component, EventEmitter, OnInit} from '@angular/core';
import {Vessel, VesselName, VesselsService, Voyage} from '../vessels.service';
import { Organisation } from '../../../organisation/organisation.service';
import {MessageLevel, PgMessagesService} from '../../../shared/services/pg-messages.service';
import {Router} from '@angular/router';
import {PgTitleComponent} from '../../../shared/components/title-bar/title-bar.component';
import { ActionType, DataBaseActionService } from 'src/app/shared/services/database-action.service';

@Component({
  selector: 'app-new-voyage',
  templateUrl: './new-voyage.component.html'
})
export class NewVoyageComponent implements OnInit, PgTitleComponent {
  title = new EventEmitter<string>();

  vessels: Vessel[];
  selectedVoyage: Voyage;
  organisations: Organisation[];
  ports: Organisation[];
  _selectedVessel: Vessel;

  get selectedVessel() {
    return this._selectedVessel;
  }

  set selectedVessel(value: Vessel) {
    this._selectedVessel = value;
    this.selectedVoyage.vesselName = value.name.name;
    this.selectedVoyage.vesselIMO = value.IMO;
  }

  set vesselOldName(value: VesselName) {
    if (value.name !== undefined) {
      this.selectedVoyage.vesselName = value.name;
    } else {
      this.selectedVoyage.vesselName = this.selectedVessel.name.name;
    }
  }

  constructor(private _vesselsService: VesselsService,
              private _pgMessagesService: PgMessagesService,
              private _actionService: DataBaseActionService,
              private _router: Router) {
    this.selectedVoyage = {
      id: '00000000-0000-0000-0000-000000000000',
      vesselIMO: '',
      vesselName: '',
      shipVoyageNo: null,
      agentVoyageNo: null,
      charterer: null,
      nors: [],
      sofs: [],
      discharges: [],
      billOfLadings: [],
      completed: false,
      comments: [],
      miscDocuments: [],
      commitmentNo: null,
      actions: [],
    };
  }

  ngOnInit() {
    this.title.emit('New Voyage');
    this._vesselsService.getVessels().subscribe(vessels => {
      this.vessels = vessels;
    });
  }

  saveVoyage() {
    this.selectedVoyage.actions.push(this._actionService.userAction(ActionType.Create));
    this._vesselsService.addVoyage(this.selectedVoyage).subscribe(results => {
      this._pgMessagesService.publishMessage({
        level: MessageLevel.Info,
        message: `Save voyage for ${this.selectedVoyage.vesselName}`,
        topic: 'Voyage'
      });
      this._router.navigate(['/vessels/voyage', results.id]).then();
    });
  }
  vesselFormatter(vessel: Vessel) {
    return vessel.name.name;
  }
}

<div class="row" >
  <div class="col-md-12 col-xs-12">
      <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" 
             class="table table-sm table-striped table-bordered text-center" style="width:100%">
          <thead>
          <tr>
              <th>نامه جدید</th>
              <th>عنوان</th>
              <th>تهیه کننده</th>
              <th>شرکت</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let template of templates">
              <td>
                  <button   id="download" 
                            name="download" 
                            type="submit" 
                            class="btn btn-link" 
                            (click)="startNewCorrespondence(template)">
                      <i class="fa fa-plus"></i>
                  </button>
              </td>
              <td>{{template.title}}</td>
              <td>{{template.providerUserName}}</td>
              <td>{{organisationFormatter(template.organisationId)}}</td>
          </tr>
          </tbody>
      </table>
  </div >
</div>
<div class="card card-body">
    <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-inner">
            <div class="carousel-item text-center" *ngFor="let photoId of thumbnail; let i = index"
                [ngClass]="{'active': i === 0 }">
                <img alt="" [src]="photoId" class="mw-150 img-thumbnail" style="height: 400px;">
                <div class="card-footer border-primary">
                    <button type="button" (click)="downloadDocumentId(photoId)" class="btn btn-outline-secondary btn-light me-1">
                        <i class="fa fa-download"></i> Download</button>
                    <button *ngIf="hasrole" id="delete" class="btn btn-outline-secondary btn-light me-1" (click)="editMode(photoId)"
                        aria-hidden="true"><i class="fa fa-trash"></i>Remove</button>
                    <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
                        FullScreen
                    </button>
                </div>
            </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls"
            data-bs-slide="prev">
            <span style="filter: invert(100%);" class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls"
            data-bs-slide="next">
            <span style="filter: invert(100%);" class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
        </button>
    </div>
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div id="ControlsModal" class="carousel slide" data-ride="carousel">
                    <div class="carousel-inner">
                        <div class="carousel-item text-center" *ngFor="let photoId of thumbnail; let i = index"
                            [ngClass]="{'active': i === 0 }">
                            <img alt="" [src]="photoId" class="img-thumbnail">
                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#ControlsModal"
                            data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#ControlsModal"
                            data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

import { Injectable } from '@angular/core';
import {HttpWrapper} from './http-wrapper.service';
import {Observable} from 'rxjs';


export interface LookupItem {
  categoryName: string;
  items: string[][];
}

@Injectable({
  providedIn: 'root'
})
export class LookupService {

  constructor(private _httpWrapper: HttpWrapper) { }

  get(categoryName: string): Observable<LookupItem> {
    return this._httpWrapper.get(`lookup/${categoryName}`);
  }
  add(data: LookupItem): Observable<LookupItem> {
    return this._httpWrapper.post('lookup', data);
  }

  update(data: LookupItem): Observable<LookupItem> {
    return this._httpWrapper.put('lookup', data);
  }

  getKantarNotMappedOrganisations(): Observable<string[]> {
    return this._httpWrapper.get(`kantar-sync/missing-maps`);
  }
}

<div class="row">
  <div class="col-md-6">
    <form #quotaDistEntry="ngForm" class="form-horizontal">
      <div class="card card-body">
        <div class="form-group row ">
          <label for="date" class="col-form-label col-md-4 ">تاریخ </label>
          <div class="col-md-8">
            <app-date-range id="date"
            [singleDateSelector]="true"
            [(dateRange)]="selectedDate"
            [jalali]="true"
            [disabled]="edit">
        </app-date-range>
          </div>
        </div>
        <div class="form-group row">
          <label for="company" class="col-form-label col-md-4 ">نام شرکت</label>
          <div class="col-md-8">
            <app-organisation-selector
            class="pg-component"
            [(organisation)]="company"
            (ngModelChange)="sourceFilter()"
            [organisationLanguage]="'fa'"
            name="company" 
            [organisations]="companies"
            required
            [disabled]="edit">
            </app-organisation-selector>
          </div>
        </div>
        <div class="form-group row ">
          <label for="type" class="col-form-label col-md-4 ">نوع</label>
          <div class="col-md-8">
            <select class="form-control"
            name="type"
            id="type"
            [(ngModel)]="type"
            (change)="sourceFilter()"
            required
            [disabled]="edit">
              <option value="quota">سهمیه</option>
              <option value="sold">فروش</option>
              <option value="stored">موجودی</option>
              <!-- <option value="destination">تعیین مبدا</option> -->
            </select>
          </div>
        </div>

        <div class="form-group row">
          <label for="source" class="col-form-label col-md-4 ">منبع</label>
          <div class="col-md-8">
            <app-organisation-selector
            class="pg-component"
            [(organisation)]="source"
            [organisationLanguage]="'fa'"
            name="source"
            [disabled] = "company === null || type === null"
            [organisations]="sources"
            [disabled]="edit"
            required>
            </app-organisation-selector>
          </div>
        </div>
<!-- 
        <div class="form-group row"  *ngIf="type === 'destination'">
          <label for="company1" class="col-form-label col-md-4 ">مقصد</label>
          <div class="col-md-8">
            <app-organisation-selector
            id="destination"
            class= "pg-component"
            [(organisation)]="destination"
            [organisationLanguage]= "'fa'"
            name="destination"
            [organisations]="destinations"
            [disabled]="edit"
            required>
           </app-organisation-selector>
        </div>
        </div> -->
        <div class="form-group row ">
            <label for="value" class="col-form-label col-md-4 ">مقدار</label>
            <div class="col-md-8">
              <input    
                    type="text"
                      name="value"
                      id="value"
                      class="form-control"
                      [(ngModel)]="value"
                      [disabled] = "company === null || type === null"
                      required>
            </div>
        </div>
        <div class="form-group row">
          <div>
            <input type="button" name="save" value="Save" class="btn btn-primary pull-right" (click)="onSubmit()"
            [disabled]="!quotaDistEntry.form.valid">
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="col-md-12 visible-md visible-lg">
    <table class="table table-sm table-striped  table-bordered ">
      <thead>
        <tr>
          <td>تاریخ</td>
          <td>نوع</td>
          <td>نام شرکت</td>
          <td>منبع</td>
          <td>مقصد</td>
          <td>مقدار</td>
        </tr>
      </thead>
      <tr *ngFor="let dt of importedData; let i=index;">
        <td>{{dt.timestamp | persianDateOnly}}</td>
        <td>{{fotmatType(dt.quotaType)}}</td>
        <td>{{fotmatOrganisation(dt.organisationId)}}</td>
        <td>{{fotmatOrganisation(dt.sourceOrganisationId)}}</td>
        <td>{{fotmatOrganisation(dt.destination)}}</td>
        <td>{{dt.value}}</td>
      </tr>
    </table>
  </div>
</div>
import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { MarketDoc, MarketService } from '../market.service';
import { ActivatedRoute } from '@angular/router';
import { DocumentService } from 'src/app/documents/documents.service';
import { MessageLevel, PgMessagesService } from 'src/app/shared/services/pg-messages.service';
import { saveAs } from 'file-saver';
import { DateTimeHelpers } from 'src/app/shared/helpers/date-time.helpers';
import { PgTitleComponent } from 'src/app/shared/components/title-bar/title-bar.component';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { DataTablesService } from 'src/app/shared/datatables/data-tables.service';


@Component({
  selector: 'app-market-files',
  templateUrl: './market-files.component.html',
  styleUrls: ['./market-files.component.css']
})
export class MarketFilesComponent implements OnInit, PgTitleComponent {
  @ViewChild(DataTableDirective, {static: true})
  dtElement: DataTableDirective;
  marketDocs: MarketDoc[] = [];
  title = new EventEmitter<string>();
  isDataTablesInitialised = false;
  dtOptions = {};
  dtTrigger: Subject<any> = new Subject<any>();

  constructor(private pricingService: MarketService,
              private activatedRoute: ActivatedRoute,
              private documentService: DocumentService,
              private messageService: PgMessagesService) { }


  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(prarams => {
      const publisher = prarams.get('publisher');
      this.title.emit(`${publisher} files`);
      const startDate = Number(prarams.get('startDate'));
      const endDate = Number(prarams.get('endDate'));
      this.dtOptions = DataTablesService.getOptionsEn();
      this.pricingService.getPublisherDocs(publisher, startDate, endDate).subscribe(docs => {
        this.marketDocs = docs.sort((a, b) => b.timestamp - a.timestamp);
        this.initialiseTankersDataTables();
      });
    });
  }

  initialiseTankersDataTables() {
    if (!this.isDataTablesInitialised) {
      this.dtTrigger.next(false);
    } else {
      this.dtElement.dtInstance.then((dtInstance) => {
        dtInstance.destroy();
        this.dtTrigger.next(false);
      });
    }
    this.isDataTablesInitialised = true;
  }

  downloadFile(fileId: string, fileName: string) {
    this.documentService.download(fileId).subscribe(b => {
      saveAs(b.body, fileName);
    }, () => {
      this.messageService.publishMessage({
        message: 'Could not download this file.',
        level: MessageLevel.Error,
        topic: 'Voyage document'
      });
    });
  }

  formatDate(timestamp: number) {
    return DateTimeHelpers.formatUTCDate(timestamp);
  }

}

import { Component, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Organisation } from 'src/app/organisation/organisation.service';

@Component({
    selector: 'app-organisation-selector',
    template: `
    <div>
        <app-auto-complete
            [formatter]="organisationFormatter"
            [ngModel]="_organisation"
            (ngModelChange) = valueChanged($event)
            name="organisation"
            [items]="_organisations"
            [disabled]= "_disabled">
        </app-auto-complete>
    </div>`,
})
export class OrganisationSelectorComponent implements OnChanges {
    _organisationLanguage: string;
    _organisation: Organisation;
    _disabled: boolean;
    _organisations: Organisation[] = [];

    organisationFormatter: (o: Organisation) => string;

    @Input() set organisation(value: Organisation) {
        this._organisation = value;
    }

    @Output() organisationChange: EventEmitter<Organisation> = new EventEmitter<Organisation>();

    @Input() set disabled(value: boolean) {
        this._disabled = value;
    }

    @Input() set organisations(value: Organisation[]) {
        this._organisations = value;
    }
    @Input() set organisationLanguage(value: string) {
        this._organisationLanguage = value;
    }

    valueChanged(event: any) {
        this.organisationChange.emit(event);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes['organisationLanguage']) {
            this.organisationFormatter = (o: Organisation) => o.nameTranslations[this._organisationLanguage];
        }
    }
}


import {Component, ComponentFactoryResolver, EventEmitter, Input, Type, ViewChild} from '@angular/core';
import {TitleToolbarDirective} from './title-toolbar.directive';
import {DateTimeHelpers} from '../../helpers/date-time.helpers';
import {DateRange} from '../date-range.component';

export interface PgTitleComponent {
  title: EventEmitter<string>;
}

export interface PgSubTitleComponent {
  subTitle: EventEmitter<string>;
}

export enum PgDateRangeTitleEnum {
  persian,
  defaultTitle
}

export interface PgDateRangeTitleComponent {
  dateRangeSubTitle: EventEmitter<[DateRange, PgDateRangeTitleEnum]>;
}

export interface PgToolbarComponent {
  toolbarComponentType?: Type<any>;
  toolbarComponent: any;
}

@Component({
  selector: 'app-title-bar',
  templateUrl: './title-bar.component.html',
  styleUrls: ['./title-bar.component.css']
})
export class TitleBarComponent {

  @ViewChild(TitleToolbarDirective, { static: true }) toolbarHost: TitleToolbarDirective;

  title: string;
  subTitle: string;

  private _component: any;

  @Input() set component(value: any) {
    if (value) {
      this._component = value;
      this.routerOutletActivated(this._component);
    }
  }

  constructor(private _componentFactoryResolver: ComponentFactoryResolver) { }

  routerOutletActivated(component: any) {
    this.title = '';
    const titleComponent = component as PgTitleComponent;
    if (titleComponent.title) {
      titleComponent.title.subscribe(t => {
        if (t) {
          this.title = t;
        }
      });
    }

    this.subTitle = '';
    const subTitleComponent = component as PgSubTitleComponent;
    if (subTitleComponent.subTitle) {
      subTitleComponent.subTitle.subscribe(t => {
        if (t) {
          this.subTitle = t;
        }
      });
    }

    this.subTitle = '';
    const subTitleDateRangeComponent = component as PgDateRangeTitleComponent;
    if (subTitleDateRangeComponent.dateRangeSubTitle) {
      subTitleDateRangeComponent.dateRangeSubTitle.subscribe(t => {
        if (t[1] === PgDateRangeTitleEnum.persian) {
          this.subTitle = `${t[0].startDate.toLocaleDateString('fa-IR')} - ` +
            `${t[0].endDate.toLocaleDateString('fa-IR')}`;
        } else {
          this.subTitle = `${DateTimeHelpers.formatLocalDate(t[0].startDate.getTime())} till ` +
            `${DateTimeHelpers.formatLocalDate(t[0].endDate.getTime())}`;
        }
      });
    }

    this.toolbarHost.viewContainerRef.clear();
    const toolbarComponent = component as PgToolbarComponent;
    if (toolbarComponent.toolbarComponentType) {
      this.toolbarHost.viewContainerRef.clear();
      const componentFactory = this._componentFactoryResolver
        .resolveComponentFactory(toolbarComponent.toolbarComponentType);
      const componentRef = this.toolbarHost.viewContainerRef.createComponent(componentFactory);
      toolbarComponent.toolbarComponent = componentRef.instance;
    }
  }
}

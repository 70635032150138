import {Component, EventEmitter, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DateRange} from '../../../../shared/components';
import {forkJoin, Subject} from 'rxjs';
import {BunkerType, Vessel, VesselsService} from '../../vessels.service';
import {DataTableDirective} from 'angular-datatables';
import {DocumentService} from '../../../../documents/documents.service';
import {MessageLevel, PgMessagesService} from '../../../../shared/services/pg-messages.service';
import * as moment from 'moment';
import {DateTimeHelpers} from '../../../../shared/helpers/date-time.helpers';
import {
  PgDateRangeTitleComponent,
  PgDateRangeTitleEnum,
  PgTitleComponent
} from '../../../../shared/components/title-bar/title-bar.component';
import {
  BunkerReportPart,
  NoonReportsV202205Service,
  VesselReportV202205
} from '../../services/noon-reports-v202205.service';
import {Organisation, OrganisationService} from '../../../../organisation/organisation.service';
import { DataTablesService } from '../../../../shared/datatables/data-tables.service';
import { saveAs } from 'file-saver';


@Component({
  selector: 'app-noon-reports-v202205',
  templateUrl: './noon-reports-v202205.component.html',
  styleUrls: []
})
export class NoonReportsV202205Component implements OnInit, PgTitleComponent, PgDateRangeTitleComponent {

  @ViewChild(DataTableDirective, {static: true}) dtElement: DataTableDirective;
  tableInitialised = false;

  title = new EventEmitter<string>();
  dateRangeSubTitle = new EventEmitter<[DateRange, PgDateRangeTitleEnum]>();

  dtOptions: any;
  dtTrigger: Subject<any> = new Subject<any>();

  dateRange: DateRange;
  reports: VesselReportV202205[];
  vessels: Vessel[];
  options: any;
  organisationsMap: Map<string, Organisation>;

  constructor(private _vesselsService: VesselsService,
              private _router: Router,
              private _activatedRoute: ActivatedRoute,
              private _noonReportsService: NoonReportsV202205Service,
              private _documentsService: DocumentService,
              private _pgMessagesService: PgMessagesService,
              private _organisationsService: OrganisationService) {
    this.dateRange = {
      startDate: moment().startOf('day').subtract(7, 'd').toDate(),
      endDate: moment().endOf('day').toDate()
    };
  }

  ngOnInit(): void {
    const component = this;
    this.dtOptions = DataTablesService.getOptionsEn([{
      text: 'Download',
      action: () => component.downloadReport()
    }], [[2, 'desc']], 50);
    this.title.emit('Noon reports V202005');
    this._activatedRoute.paramMap.subscribe(p => {
      if (p.get('startDate')) {
        this.dateRange = {
          startDate: new Date(+p.get('startDate')),
          endDate: new Date(+p.get('endDate'))
        };
      }
      this.dateRangeSubTitle.emit([this.dateRange, PgDateRangeTitleEnum.defaultTitle]);
      this.getReport();
    });
  }

  getReport() {
    forkJoin([
      this._noonReportsService.getNoonReportsV202205Between(this.dateRange),
      this._organisationsService.getOrganisationIdToOrganisationMap()]).subscribe(results => {
      this.reports = results[0];
      this.organisationsMap = results[1];
      if (!this.tableInitialised) {
        this.dtTrigger.next(false);
        this.tableInitialised = true;
      } else {
        this.dtElement.dtInstance.then((dtInstance) => {
          dtInstance.destroy();
          this.dtTrigger.next(false);
        });
      }
    });
  }

  formatDate(timestamp: number) {
    if (timestamp > 0) {
      return DateTimeHelpers.formatLocalDateTime(timestamp);
    } else {
      return '';
    }
  }

  downloadReport() {
    this._noonReportsService.downloadReports(this.dateRange.startDate.getTime(),
      this.dateRange.endDate.getTime()).subscribe(b => {
      saveAs(b.body, `${DateTimeHelpers.formatLocalDate(this.dateRange.startDate.getTime())}-to-` +
        `${DateTimeHelpers.formatLocalDate(this.dateRange.endDate.getTime())}-reports.xlsx`);
    }, () => {
      this._pgMessagesService.publishMessage({
        level: MessageLevel.Error,
        message: 'Could not download noon reports',
        topic: 'Voyages report'
      });
    });
  }

  // downloadFile(report: NoonReportV202005) {
  //   this._documentsService.download(report.fileId).subscribe(b => {
  //     const n = report.vesselName.split(' ').map(s => s.toLowerCase()).join('-');
  //     saveAs(b.body, `${n}-${DateTimeHelpers.formatLocalDate(report.date.timestamp)}-report.xlsx`);
  //   }, () => {
  //     this._pgMessagesService.publishMessage({
  //       message: 'Could not download report.',
  //       level: MessageLevel.Error,
  //       topic: 'Vessel report'
  //     });
  //   });
  // }

  // formatCargoOnboard(report: NoonReportV202005) {
  //   return _.chain(report.tanksCondition)
  //     .groupBy(r => r.product)
  //     .map((items, key) => `${key}: ${Math.round(items.map(i => i.quantity).reduce((p, c) => p + c, 0))}`)
  //     .value().join(', ');
  // }

  getTotalBunkerConsumption(report: VesselReportV202205, fuelType: string) {
    const bunkerType = fuelType as BunkerType;
    return report.bunkerReports
      .filter(b => b.bunkerType === bunkerType && b.reportPart !== BunkerReportPart.robs)
      .reduce((i, j) => i + j.quantityMT, 0);
  }

  getRob(report: VesselReportV202205, fuelType: string) {
    const bunkerType = fuelType as BunkerType;
    return report.bunkerReports
      .filter(b => b.bunkerType === bunkerType && b.reportPart === BunkerReportPart.robs)
      .reduce((i, j) => i + j.quantityMT, 0);
  }
}

import {OnInit, Component, ViewChild, EventEmitter} from '@angular/core';
import { TrucksService, TankerData, Truck } from 'src/app/transportation/trucks/trucks.service';
import { Subject, forkJoin } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { Organisation, OrganisationService} from 'src/app/organisation/organisation.service';
import { Router } from '@angular/router';
import { DataTablesService } from 'src/app/shared/datatables/data-tables.service';
import { DocumentService } from 'src/app/documents/documents.service';
import { saveAs } from 'file-saver';
import { MessageLevel, PgMessagesService } from 'src/app/shared/services/pg-messages.service';
import * as _ from 'lodash';
import {PgTitleComponent} from '../../../shared/components/title-bar/title-bar.component';

@Component({
  selector: 'app-tanker-list',
  templateUrl: './tanker-list.component.html'
})
export class TankerListComponent implements OnInit, PgTitleComponent {
  @ViewChild(DataTableDirective, {static: true})
  dtElement: DataTableDirective;
  title = new EventEmitter<string>();

  isDataTablesInitialised = false;
  dtOptions = {};
  dtTrigger: Subject<any> = new Subject<any>();

  tankers: TankerData[];
  tankersCash: {
    [key: string]: TankerData;
  } = {};
  today: number;

  _organisationsCache: {
    [key: string]: Organisation;
  } = {};

  constructor(
    private _truckService: TrucksService,
    private _router: Router,
    private _organisationService: OrganisationService,
    private _documentsService: DocumentService,
    private _pgMessagesService: PgMessagesService) { }

  ngOnInit() {
    this.title.emit('Tankers');
    this.today = new Date().getTime();
    const currentComponent = this;
    forkJoin([
      this._truckService.getAllTankers(),
      this._organisationService.get(),
      this._truckService.getForLpgDist()
    ]).subscribe(results => {
      this.tankers = results[0];
      this.initialiseTankersDataTables();
      results[1].forEach(o => {
        this._organisationsCache[o.name] = o;
      });
      this.tankersCash = this.tankersMap(results[2]);
    });
    const buttons = [{
      text: 'مخزن جدید',
      action: () => currentComponent.addTanker()
      },
      {
        text: 'خروجی اکسل',
        action: () => currentComponent.excelExport()
      }
    ];
    this.dtOptions = DataTablesService.getOptions(buttons);
  }

  initialiseTankersDataTables() {
    if (!this.isDataTablesInitialised) {
      this.dtTrigger.next(false);
    } else {
      this.dtElement.dtInstance.then((dtInstance) => {
        dtInstance.destroy();
        this.dtTrigger.next(false);
      });
    }
    this.isDataTablesInitialised = true;
  }

  addTanker() {
    this._router.navigate(['tanker/new']);
  }
  formatOrganisation(organisationId: string) {
    const foundOrganisation = this._organisationsCache[organisationId];
    if (foundOrganisation) {
      return foundOrganisation.nameTranslations['fa'];
    } else {
      return '';
    }
  }

  editRecord(tanker: TankerData) {
    this._router.navigate(['tanker/edit', tanker.number, tanker.owner]);
  }

  downloadFile(fileId: string, prefix: string) {
    this._documentsService.download(fileId).subscribe(b => {
      saveAs(b.body, `${prefix}.pdf`);
    }, () => {
      this._pgMessagesService.publishMessage({
        message: 'Could not download this file.',
        level: MessageLevel.Error,
        topic: 'Voyage document'
      });
    });
  }

  tankersMap(trucks: Truck[]) {
    const usedTankers = trucks.filter(t => t.tanker)
    .map(t1 => t1.tanker.associatedObject);
    const grouped = _.groupBy(usedTankers, (item) => item.number + '$' + item.owner);
    return _(grouped).map((items, key) => {
      const split = key.split('$');
      return {
        number: split[0],
        owner: split[1],
      };
    }).value().reduce((hashMap, data) => {
      hashMap[data.number + data.owner] = data;
      return hashMap;
    }, {});
  }

  checkUsed(tanker: TankerData) {
    return (this.tankersCash[tanker.number + tanker.owner]) ? 'used' : 'notUsed';
  }

  excelExport() {
    this._truckService.getAllTankersExcel().subscribe(file => {
      saveAs(file.body, `tankers.xlsx`);
    });
  }

}

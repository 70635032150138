
<form class="form-horizontal" #sofTemplateForm="ngForm" (ngSubmit)="save()">
  <div class="card card-body col-md-6">
    <div class="form-group row">
      <label for="id" class="col-form-label col-md-4 ">Template Id</label>
      <div class="col-md-8">
        <input class="form-control"
          type="text"
          id="id"
          name="id"
          [(ngModel)]="id"
          [disabled]="mode==='edit'"
          [required]="true">
      </div>
    </div>

    <div class="form-group row">
      <label for="selectedSofType" class="col-form-label col-md-4 ">Sof
        Type</label>
      <div class="col-md-8">
        <select class="form-control"
          id="selectedSofType"
          name="selectedSofType"
          [(ngModel)]="selectedSofType"
          [disabled]="mode==='edit'"
          [required]="true">
          <option value="loading">loading</option>
          <option value="discharge">discharge</option>
        </select>
      </div>
    </div>
  </div>

  <div class="card card-body col-md-12">
    <div class="form-group row">
      <div class="col-md-5">
        <select multiple
          class="form-control"
          size="10"
          id="sofTemplateEntries"
          name="sofTemplateEntries"
          [(ngModel)]="selectedUsedSofEntries">
          <ng-container *ngFor="let i of sofTemplate">
            <option [value]="i">
              <!--            <b>something</b>-->
              {{i[0].code}}&nbsp;{{i[1]}}&nbsp;{{i[0].isRequired ? 'REQUIRED':
              ''}}
              <!--            <i *ngIf="i.isRequired" class="fa fa-check-square-o"></i>-->
            </option>
          </ng-container>
        </select>
      </div>

      <div class="col-md-1" style="text-align: center">
        <div class="row justify-content-center"><a (click)="verifyAdd()"> <i
              class="fa fa-2x fa-arrow-left"></i></a></div>
        <div class="row justify-content-center"><a (click)="verifyRemove()"
            disabled="true"> <i class="fa fa-2x fa-arrow-right"></i></a></div>
        <div class="row justify-content-center"><a (click)="up()"
            disabled="true"> <i class="fa fa-2x fa-arrow-up"></i></a></div>
        <div class="row justify-content-center"><a (click)="down()"
            disabled="true"> <i class="fa fa-2x fa-arrow-down"></i></a></div>
      </div>

      <div class="col-md-5">
        <select multiple
          class="form-control"
          size="10"
          id="sofEntries"
          name="sofEntries"
          [(ngModel)]="selectedUnusedSofEntries">
          <ng-container *ngFor="let i of unusedSofEntries">
            <option [value]="i">{{i[0].code}}&nbsp;{{i[1]}}</option>
          </ng-container>
        </select>
      </div>
    </div>
    <div class="col-md-12 ">
      <button class="btn btn-primary"
        [disabled]="!sofTemplateForm.form.valid">Save</button>
    </div>
  </div>
</form>

import { Component, OnInit, ViewChild } from '@angular/core';
import { ContractService, Contract, ContractLoadingBuyer } from 'src/app/contract/contract.service';
import { ActivatedRoute } from '@angular/router';
import { DataTablesService } from '../../shared/datatables/data-tables.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject, forkJoin } from 'rxjs';
import { DateTimeHelpers } from 'src/app/shared/helpers/date-time.helpers';
import { OrganisationService, Organisation } from 'src/app/organisation/organisation.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-buyer-contract-balance',
  templateUrl: './buyer-contract-balance.component.html',

})
export class BuyerContractBalanceComponent implements OnInit {

  @ViewChild(DataTableDirective, {static: true})
  dtElement: DataTableDirective;

  contracts: Contract[];
  _organisationsCache: { [key: string]: Organisation } = {};
  _contractsCashe: {[key: string]: Contract} = {};
  contractsLoadingBuyer: ContractLoadingBuyer[];
  organisations: Organisation[];
  balanceData: any[] = [];

  dtOptions = {};
  dtTrigger: Subject<any> = new Subject<any>();
  isDataTablesInitialised = false;


  constructor(
    private _contractService: ContractService,
    private _activateRoute: ActivatedRoute,
    private _organisationService: OrganisationService,
    private _router: Router,
    ) { }

  ngOnInit() {
    this.dtOptions = DataTablesService.getOptionsEn();
    this.fillDataTable();
  }

  fillDataTable() {
    this._activateRoute.paramMap.subscribe(p => {
      const buyerName = p.get('buyerId');
      if (buyerName) {
        forkJoin([
          this._contractService.getByBuyer(buyerName),
          this._organisationService.get(),
          this._contractService.getContractBalancePerBuyer()
        ])
        .subscribe(results => {
          this.contracts = results[0];
          this.contractsLoadingBuyer = results[2];
          this._contractsCashe = this.contracts.reduce((hashMap, contract) => {
            hashMap[contract.contractNo] = contract;
            return hashMap;
          }, {});
          this.organisations = results[1];
          this._organisationsCache = this.organisations.reduce((hashMap, organisation) => {
            hashMap[organisation.name] = organisation;
            return hashMap;
          }, {});
          this.balanceData = this.calculateBalance();
          if (!this.isDataTablesInitialised) {
            this.dtTrigger.next(false);
          } else {
            this.dtElement.dtInstance.then((dtInstance) => {
              dtInstance.destroy();
              this.dtTrigger.next(false);
            });
          }
        });
      } else {
        this._router.navigate(['contracts']);
      }
    });
  }

  calculateBalance() {
    return this.contractsLoadingBuyer
    .filter(loading =>
      loading.contractNo !== ''
      && loading.contractNo !== undefined
      && loading.contractNo !== null
      && this._contractsCashe[loading.contractNo] !== undefined
    ).map(c => {
      const contract = this._contractsCashe[c.contractNo];
      if (contract !== undefined) {
        return {
          internalContractNo: contract.internalContractNo,
          sourceOrganisationId: contract.sourceOrganisationId,
          buyerOrganisationId: contract.buyerOrganisationId,
          quantity: contract.quantity,
          startDate: contract.startDate,
          expirationDate: contract.expirationDate,
          loading: c.total,
          destination: c.destination,
        };
      }
    });
  }

  formatOrganisation(organisationId: string) {
    const foundOrganisation = this._organisationsCache[organisationId];
    if (foundOrganisation) {
      return foundOrganisation.nameTranslations['en'];
    } else {
      return '';
    }
  }

  formatDates(timestamp: number) {
    return DateTimeHelpers.formatLocalDate(timestamp);
  }

}

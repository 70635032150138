<div class="row">
  <div class="col-md-6">
    <div class="card card-body">
      <div class="row">
        <div class="col-md-3">
          <label for="ports">Select vessel</label>
        </div>
        <div class="col-md-7">
          <select class="form-control"
            name="ports"
            id="ports"
            tabindex="1"
            [(ngModel)]="vessel"
            required>
            <option *ngFor="let v of vessels | async" [ngValue]="v">
              {{v.name.name}}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <label for="years">Year</label>
        </div>
        <div class="col-md-7">
          <select class="form-control"
            name="years"
            id="years"
            tabindex="1"
            [(ngModel)]="selectedYear"
            required>
            <option *ngFor="let y of years" [ngValue]="y"> {{y}} </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <label for="quarters">Quarter</label>
        </div>
        <div class="col-md-7">
          <select class="form-control"
            name="quarters"
            id="quarters"
            tabindex="1"
            [(ngModel)]="selectedQuarter"
            required>
            <option *ngFor="let q of ['Q1', 'Q2', 'Q3', 'Q4']" [ngValue]="q">
              {{q}} </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <label for="dailyCost">Daily cost</label>
        </div>
        <div class="col-md-7">
          <input class="form-control"
            type="numbers"
            name="dailyCost"
            id="dailyCost"
            tabindex="1"
            [(ngModel)]="daily"
            required>
        </div>
      </div>
      <div class="col-md-12 ">
        <button class="btn btn-primary" (click)="addBudget()">Add</button>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="card card-body">
      <table datatable
        [dtOptions]="dtOptions"
        [dtTrigger]="dtTrigger"
        class="table table-sm table-striped">
        <thead>
          <tr>
            <th>Vessel</th>
            <th>Year</th>
            <th>Quarter</th>
            <th>Daily budget</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let b of budgets | async">
            <td>{{b.vesselName}}</td>
            <td>{{b.year}}</td>
            <td>{{b.quarter}}</td>
            <td>{{b.dailyCost}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

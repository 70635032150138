import {Component, EventEmitter, OnInit} from '@angular/core';
import { LPGDistService, LPGDistData } from '../../../lpg-dist.service';
import { DateRange } from '../../../../shared/components';
import * as momentJ from 'moment-jalaali';
import { Organisation, OrganisationService } from '../../../../organisation/organisation.service';
import { TrucksService, Truck } from '../../../../transportation';
import { ActivatedRoute } from '@angular/router';
import { PgMessagesService, MessageLevel } from 'src/app/shared/services/pg-messages.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject, forkJoin } from 'rxjs';
import { DataTablesService } from '../../../../shared/datatables/data-tables.service';
import { Contract, TempContract} from 'src/app/contract/contract.service';
import {PgTitleComponent} from '../../../../shared/components/title-bar/title-bar.component';


@Component({
  selector: 'app-lpg-dist-entry',
  templateUrl: './lpg-dist-entry.component.html',
})
export class LpgDistEntryComponent implements OnInit, PgTitleComponent {
  title = new EventEmitter<string>();

  trucks: Truck[];
  truck: Truck;
  trucksForSelection: string[];
  lpgDistData: LPGDistData;
  companies: Organisation[];
  sources: Organisation[];
  organisations: Organisation[];
  destinations: Organisation[];
  mode: string;
  dataRange: DateRange;

  company: Organisation;
  source: Organisation;
  destination: Organisation;
  primaryDestination: Organisation;

  importedData: LPGDistData[] = [];
  sourceSummary: any[];

  dtOptions = {};
  dtTrigger: Subject<any> = new Subject<any>();
  isDataTablesInitialised = false;
  dtElement: DataTableDirective;

  totalDtOptions = {};
  message: string;
  hideTable: boolean;
  success: boolean;
  errorMessage: string;
  weight: number;
  companyId: string;
  loadingContract: Contract;
  contractsList: Contract[] = [];

  tempContract: TempContract;
  temporalContracts: TempContract[];

  remove: boolean;

  submitted: boolean;
  selectedDate: DateRange;
  showMessage: boolean;
  disabled: boolean;

  constructor(private _lpgDistService: LPGDistService,
               private _trucksService: TrucksService,
               private _activatedRoute: ActivatedRoute,
               private _organisationService: OrganisationService,
               private _pgMessagesService: PgMessagesService) { }


  getOrganisationAndTruckData(companyId: string) {
    forkJoin([
      this._trucksService.getForLpgDist(),
      this._organisationService.getLoadingsLocations(),
      this._organisationService.getDischargeLocations(),
      this._organisationService.getRootOrganisations(),
      this._organisationService.get(),
    ]).subscribe(results => {
      this.trucks = results[0];
      this.sources = results[1];
      this.destinations = results[2];
      this.companies = results[3];
      this.organisations = results[4];
      if (this.mode === 'new') {
        this.organisations = results[4]. filter(organisation => organisation.properties['allowEnteryFromUI'] === true);
      }
      this.company = this.companies.find(c => c.name === companyId);
      this.temporalContracts = this.contractsList.map(c => {
        const s: TempContract = {
          contractNo: c.contractNo,
          buyer: this.organisations.find(o => o.name === c.buyerOrganisationId).nameTranslations['fa'],
          source: this.organisations.find(o => o.name === c.sourceOrganisationId).nameTranslations['fa'],
          quantity: c.quantity
        };
        return s;
      });
      this.initPage();
      if (this.mode === 'edit' || this.mode === 'niopdc') {
        this.disabled = true;
      }
    });
  }

  initPage() {
    if (this.mode !== 'new') {
      this.truck = this.trucks.find (trk => trk.formattedRegistrationNumber === this.lpgDistData.truckNumber);
      this.selectedDate = {
        startDate: new Date(this.lpgDistData.loadTimestamp),
        endDate: new Date(this.lpgDistData.loadTimestamp)
      };
      this.source = this.sources.filter(c => c.name === this.lpgDistData.sourceOrganisationId)[0];
      this.destination = this.destinations.filter(c => c.name === this.lpgDistData.destinationOrganisationId)[0];
      this.primaryDestination = this.destinations.filter(c => c.name === this.lpgDistData.primaryDestination)[0];
    } else {
      this.selectedDate = {
        startDate: momentJ().subtract(1, 'days').startOf('day').toDate(),
        endDate: momentJ().subtract(1, 'days').startOf('day').toDate()
      };
      this.lpgDistData = {
        company: '',
        companyId: '',
        destination: '',
        primaryDestination: '',
        destinationOrganisationId: '',
        source: '',
        sourceOrganisationId: '',
        billOfLadingNumber: '',
        loadTimestamp: null,
        unloadTimestamp: null,
        loadingWeight: null,
        truckNumber: '',
        tankNumber: '',
        driverName: '',
        comments: '',
        isDeleted: false,
        unload: false,
        verify: false,
        contractNo: null,
        transporterOrganisationId: null,
        modify: {
          insertedOn: null,
          updatedOn: null
        },
        foreignKey: null
      };
    }
  }

  ngOnInit() {
    this.title.emit('Loadings');
    this.dtOptions = DataTablesService.getOptions();
    this.totalDtOptions = DataTablesService.getOptions();
    this._activatedRoute.paramMap.subscribe(p => {
      this.mode = p.get('mode');
      this.companyId = p.get('company');
      const searchKey = p.get('searchKey');
        if (this.mode === 'edit')  {
          const loadTimestamp = Number(p.get('loadTimestamp'));
          this._lpgDistService.getLpgDistRecord(this.companyId, loadTimestamp, searchKey).subscribe(lpg => {
            this.lpgDistData = lpg;
            if (!this.lpgDistData.modify) {
              this.lpgDistData.modify = {
                insertedOn: 0,
                updatedOn: null
              };
            }
          });
        } else if (this.mode === 'niopdc')  {
          this._lpgDistService.getNiopdcRecord(searchKey).subscribe(lpg => {
            this.lpgDistData = lpg;
            if (!this.lpgDistData.modify) {
              this.lpgDistData.modify = {
                insertedOn: 0,
                updatedOn: null
              };
            }
          });
        }
        this.getOrganisationAndTruckData(this.companyId);
    });
  }

  organisationValueFormatter(data: Organisation) {
    return data.nameTranslations['fa'];
  }


  changeDestination() {
    this.destination = this.primaryDestination;
  }

  contractValueFormatter(data: any) {
    return data.contractNo + '===' + data.source + '===' + data.quantity + '===' + data.buyer;
  }


  truckValueFormatter(truck: Truck) {
    const driver = (typeof truck.driver === 'undefined') ? '' : truck.driver.associatedObject.name;
    const tanker = (typeof truck.tanker === 'undefined') ? '' : truck.tanker.associatedObject.number;
    return truck.formattedRegistrationNumber + ' ' + tanker + ' ' + driver;
  }

  onSubmit() {
    this.lpgDistData.company = this.company.nameTranslations['fa'];
    this.lpgDistData.companyId = this.company.name;
    this.lpgDistData.source = this.source.nameTranslations['fa'];
    this.lpgDistData.sourceOrganisationId = this.source.name;
    this.lpgDistData.destination = this.destination.nameTranslations['fa'];
    this.lpgDistData.primaryDestination = this.primaryDestination.name;
    this.lpgDistData.destinationOrganisationId = this.destination.name;
    this.lpgDistData.loadTimestamp = this.correctTimestamp(this.selectedDate.startDate);
    this.lpgDistData.unloadTimestamp = this.correctTimestamp(this.selectedDate.startDate);
    this.lpgDistData.truckNumber = this.truck.formattedRegistrationNumber;
    this.lpgDistData.tankNumber = (typeof this.truck.tanker === 'undefined') ? '' : this.truck.tanker.associatedObject.number;
    this.lpgDistData.driverName = (typeof this.truck.driver === 'undefined') ? '' : this.truck.driver.associatedObject.name;
    this.lpgDistData.transporterOrganisationId = (typeof this.truck.organisationId.associatedObject === 'undefined') ? '' :
     this.truck.organisationId.associatedObject;
    this.lpgDistData.loadingWeight = +this.lpgDistData.loadingWeight;
    if (this.tempContract) {
      this.lpgDistData.contractNo = this.tempContract.contractNo;
    }
    if (this.mode !== 'edit') {
      this.lpgDistData.modify.insertedOn = new Date().getTime();
      this.lpgDistData.modify.updatedOn = new Date().getTime();
      this._lpgDistService.add(this.lpgDistData)
      .subscribe(() => {
        this.message = ' داده مورده نظر با شماره بارنامه ' + this.lpgDistData.billOfLadingNumber + ' با موفقیت ثبت شد  ';
        this._pgMessagesService.publishMessage({
          message: this.message,
          topic: 'New Loading',
          level: MessageLevel.Info
        });
        this.updateTabbles(this.lpgDistData);
        this.resetForm();
      }, () => {
        this.message = 'Error';
        this._pgMessagesService.publishMessage({
          message: this.message,
          topic: 'Error',
          level: MessageLevel.Error
        });
      });
    } else if (this.mode === 'edit') {
      this.lpgDistData.modify.updatedOn = new Date().getTime();
      this._lpgDistService.update(this.lpgDistData)
      .subscribe(() => {
        this.message = ' داده مورده نظر با شماره بارنامه ' + this.lpgDistData.billOfLadingNumber + ' با موفقیت ویرایش شد  ';
        this._pgMessagesService.publishMessage({
          message: this.message,
          topic: 'Edit Loading',
          level: MessageLevel.Info
        });
        if (this.mode !== 'edit') {
          this.updateTabbles(this.lpgDistData);
          this.resetForm();
        }
      }, () => {
        this.message = 'Error';
        this._pgMessagesService.publishMessage({
          message: this.message,
          topic: 'Error',
          level: MessageLevel.Error
        });
      });
    }
  }

  correctTimestamp(date: Date): number {
    return date.getTime() - (date.getHours() * 3600
      + date.getMinutes() * 60
      + date.getSeconds()) * 1000
      - date.getMilliseconds();
  }

  resetForm() {
    const billNumber = this.lpgDistData.billOfLadingNumber;
    const cmpId = this.lpgDistData.companyId;
    this.source = this.sources.filter(c => c.name === this.lpgDistData.sourceOrganisationId)[0];
    this.destination = null;
    this.lpgDistData = {
      company: '',
      companyId: cmpId,
      destination: '',
      primaryDestination: '',
      destinationOrganisationId: '',
      source: '',
      sourceOrganisationId: this.source.name,
      billOfLadingNumber: billNumber,
      loadTimestamp: null,
      unloadTimestamp: null,
      loadingWeight: null,
      truckNumber: '',
      tankNumber: '',
      driverName: '',
      comments: '',
      isDeleted: false,
      unload: false,
      verify: false,
      contractNo: null,
      transporterOrganisationId: null,
      modify: {
        insertedOn: null,
        updatedOn: null
      },
      foreignKey: ''
    };
    this.truck = null;
    this.loadingContract = null;
  }

  updateTabbles(data: LPGDistData) {
    this.importedData.push(data);
    this.sourceSummary = this._lpgDistService.reportSourceSummary(this.importedData);
  }

  editRecord(data: LPGDistData, index: number) {
    this.lpgDistData = data;
    this.importedData.splice(index, 1);
    this.sourceSummary = this._lpgDistService.reportSourceSummary(this.importedData);
    this.initPage();
  }
}

<div class="row">
  <div class="col-md-6">
    <!-- meta data and document upload -->
    <div *ngIf="!showTabs">
      <div class="card shadow-sm ">
        <div class="card-header with-border">
          {{selectedDocumentType}}
          <i class="fas fa-check-double fa-2x text-success"
            *ngIf="selectedDocumentIsVerified"></i>
        </div>
        <div class="card-body">
          <div>
            <ng-template appDocumentHost></ng-template>
          </div>
          <form class="form-horizontal" *ngIf="showDocumentUpload">
            <div class="form-group col-md-12 mb-3">
              <label for="uploadedCertificate"
                class="col-form-label col-md-4">File</label>
              <app-chunk-file-uploader [accept]="'.pdf'"
                name="uploadedCertificate"
                id="uploadedCertificate"
                (filesChanged)="uploadDocument($event)"
                [required]="true">
              </app-chunk-file-uploader>
            </div>
            <div class="form-group col-md-12">
              <div class="btn-group pull-right">
                <button class="btn btn-outline-secondary btn-light me-1"
                  *ngIf="canSkipDocumentUpload" (click)="skipDocumentUpload()">
                  Skip
                </button>
                <button class="btn btn-outline-secondary btn-light me-1"
                  (click)="showTabsToggle()"> Back to voyage
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- Tabs -->
    <div class="card shadow-sm " *ngIf="showTabs">
      <div class="card-body">
        <ul class="nav nav-tabs" id="pills-tab" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link active"
              id="loading_nor_tab"
              data-bs-toggle="pill"
              data-bs-target="#loading_nor"
              type="button"
              role="tab"
              aria-controls="loading_nor"
              aria-selected="true">NORs ({{selectedVoyage.nors.length}})
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link"
              id="loading_sof_tab"
              data-bs-toggle="pill"
              data-bs-target="#loading_sof"
              type="button"
              role="tab"
              aria-controls="loading_sof"
              aria-selected="false">
              SOFs ({{selectedVoyage.sofs.length}})
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link"
              id="bl_tab"
              data-bs-toggle="pill"
              data-bs-target="#bl"
              type="button"
              role="tab"
              aria-controls="bl"
              aria-selected="false">
              Bill Of Ladings ({{selectedVoyage.billOfLadings.length}})
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="discharge_tab"
              data-bs-toggle="pill"
              data-bs-target="#discharge"
              type="button"
              role="tab"
              aria-controls="discharge"
              aria-selected="false">
              Discharges ({{selectedVoyage.discharges.length}})
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="comments_tab"
              data-bs-toggle="pill"
              data-bs-target="#comments"
              type="button"
              role="tab"
              aria-controls="comments"
              aria-selected="false">
              Comments ({{selectedVoyage.comments.length}})
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="documents_tab"
              data-bs-toggle="pill"
              data-bs-target="#documents"
              type="button"
              role="tab"
              aria-controls="documents"
              aria-selected="false">
              Documents ({{selectedVoyage.miscDocuments.length}})
            </button>
          </li>
        </ul>
        <div class="tab-content" id="pills-tabContent">
          <div class="tab-pane fade show active" id="loading_nor"
            role="tabpanel" aria-labelledby="loading_nor_tab">
            <table class="table table-sm table-bordered table-striped"
              *ngIf="selectedVoyage.nors.length > 0 ">
              <thead>
                <th></th>
                <th>Type</th>
                <th>Port</th>
                <th>Date</th>
              </thead>
              <tbody>
                <tr *ngFor="let nor of selectedVoyage.nors let i=index">
                  <td class="text-center">
                    <div class="icon-set">
                      <i class="fas fa-check-double text-success"
                        *ngIf="_vesselsService.isVerified(nor)"></i>
                      <a (click)="downloadFile(nor.fileId, 'nor')">
                        <i class="fa fa-download" aria-hidden="true"></i>
                      </a>
                    </div>
                  </td>
                  <td class="text-center">
                    <a id="previewNor" [routerLink]="['NOR', nor.fileId]"
                      class="btn btn-link text-decoration-none">{{nor.norType}}</a>
                  </td>
                  <td
                    class="text-center">{{findOrganisation(nor.portOrganisationId)}}</td>
                  <td class="text-center">{{formatDate(nor.timestamp,
                    nor.portOrganisationId)}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="tab-pane fade"
            id="loading_sof"
            role="tabpanel"
            aria-labelledby="loading_sof_tab">
            <table class="table table-sm table-bordered table-striped"
              *ngIf="selectedVoyage.sofs.length > 0 ">
              <thead>
                <tr>
                  <th></th>
                  <th>Type</th>
                  <th>Port</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let sof of selectedVoyage.sofs let i=index">
                  <td class="text-center">
                    <div class="icon-set">
                      <i class="fas fa-check-double text-success"
                        *ngIf="_vesselsService.isVerified(sof)"></i>
                      <a (click)="downloadFile(sof.fileId, 'sof')"> <i
                          class="fa fa-download" aria-hidden="true"></i></a>
                      <a
                        *ngIf="hasRole('vessels-voyages-editor') && !_vesselsService.isVerified(sof)"
                        id="uploadSof"
                        (click)="updateDocument('SOF', sof.fileId)">
                        <i class="fa fa-upload"></i>
                      </a>
                    </div>
                  </td>
                  <td class="text-center">
                    <a id="editSof" class="btn btn-link text-decoration-none"
                      [routerLink]="['SOF', sof.fileId]">{{sof.sofType}}</a>
                  </td>
                  <td
                    class="text-center">{{findOrganisation(sof.portOrganisationId)}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            class="tab-pane fade"
            id="bl"
            role="tabpanel"
            aria-labelledby="bl_tab">
            <table class="table table-sm table-bordered table-striped"
              *ngIf="selectedVoyage.billOfLadings.length > 0 ">
              <thead>
                <tr>
                  <th></th>
                  <th>Date</th>
                  <th>Port</th>
                  <th>Product</th>
                  <th>Revise</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let bl of selectedVoyage.billOfLadings let i=index">
                  <td class="text-center">
                    <div class="icon-set">
                      <i class="fas fa-check-double text-success"
                        *ngIf="_vesselsService.isVerified(bl)"></i>
                      <a (click)="downloadFile(bl.fileId, 'BL')"><i
                          class="fa fa-download" aria-hidden="true"></i></a>
                      <a
                        *ngIf="hasRole('vessels-voyages-editor') && !_vesselsService.isVerified(bl)"
                        id="updateBLFile"
                        (click)="updateDocument('Bill of Lading', bl.fileId)">
                        <i class="fa fa-upload"></i>
                      </a>
                    </div>
                  </td>
                  <td class="text-center">
                    <a id="editBL" class="btn btn-link text-decoration-none"
                      [routerLink]="['Bill of Lading', bl.fileId]">
                      {{formatDate(bl.date,bl.loadingPortOrganisationId)}}</a>
                  </td>
                  <td
                    class="text-center">{{findOrganisation(bl.loadingPortOrganisationId)}}</td>
                  <td class="text-center">{{formatProducts(bl)}}</td>
                  <td class="text-center"><i
                      class="fa fa-check-square-o text-success"
                      *ngIf="bl?.revise"></i></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            class="tab-pane fade"
            id="discharge"
            role="tabpanel"
            aria-labelledby="discharge_tab">
            <table class="table table-sm table-bordered table-striped"
              *ngIf="selectedVoyage.discharges.length > 0 ">
              <thead>
                <tr>
                  <th></th>
                  <th>Date</th>
                  <th>Port</th>
                  <th>Consignee</th>
                  <th>Products</th>
                  <th>Primary</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let discharge of selectedVoyage.discharges let i=index">
                  <td class="text-center">
                    <div class="icon-set">
                      <i class="fas fa-check-double text-success"
                        *ngIf="_vesselsService.isVerified(discharge)"></i>
                      <a (click)="downloadFile(discharge.fileId, 'discharge')">
                        <i class="fa fa-download" aria-hidden="true"></i>
                      </a>
                    </div>
                  </td>
                  <td class="text-center">
                    <a id="edit" class="btn btn-link text-decoration-none"
                      [routerLink]="['Discharge', discharge.fileId]">
                      {{formatDate(discharge.date,discharge.portOrganisationId)}}
                    </a>
                  </td>
                  <td
                    class="text-center">{{findOrganisation(discharge.portOrganisationId)}}</td>
                  <td
                    class="text-center">{{findOrganisation(discharge.consigneeOrganisationId)}}</td>
                  <td class="text-center">
                    <ng-container *ngFor="let p of sumDischarges(discharge)">
                      {{p['product']}}:{{p['weight'] | number}}
                    </ng-container>
                  </td>
                  <td class="text-center"><i
                      class="fa fa-check-square-o text-success"
                      *ngIf="containsPrimary(discharge)"></i></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            class="tab-pane fade"
            id="comments"
            role="tabpanel"
            aria-labelledby="comments_tab">
            <table class="table table-sm table-bordered table-striped"
              *ngIf="selectedVoyage.comments.length > 0 ">
              <thead>
                <tr>
                  <th></th>
                  <th>Author</th>
                  <th>Date</th>
                  <th>Comment</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let comment of selectedVoyage.comments let i=index">
                  <td class="text-center">
                    <div class="icon-set">
                      <a *ngIf="comment.fileId"
                        (click)="downloadFile(comment.fileId, 'comment')">
                        <i class="fa fa-download" aria-hidden="true"></i>
                      </a>
                    </div>
                  </td>
                  <td class="text-center">{{comment.author}}</td>
                  <td class="text-center">{{comment.incidentTimestamp |
                    date}}</td>
                  <td class="text-center">
                    <p>{{comment.content}}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            class="tab-pane fade"
            id="documents"
            role="tabpanel"
            aria-labelledby="documents_tab">
            <table class="table table-sm table-bordered table-striped"
              *ngIf="selectedVoyage.miscDocuments.length > 0 ">
              <thead>
                <tr>
                  <th></th>
                  <th>Type</th>
                  <th>Comment</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let miscDocument of selectedVoyage.miscDocuments let i=index">
                  <td class="text-center">
                    <div class="icon-set">
                      <a *ngIf="miscDocument.fileId"
                        (click)="downloadFile(miscDocument.fileId, 'misc')">
                        <i class="fa fa-download" aria-hidden="true"></i>
                      </a> &nbsp;
                    </div>
                  </td>
                  <td class="text-center">{{miscDocument.miscDocumentType}}</td>
                  <td class="text-center">{{miscDocument.comment}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            class="tab-pane fade"
            id="pills-contact"
            role="tabpanel"
            aria-labelledby="bl_tab">
            ...
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="col-md-6">
    <!-- Preview -->
    <div *ngIf="uploadedFileId && !showTabs">
      <app-pdf-viewer [fileId]="uploadedFileId"> </app-pdf-viewer>
    </div>
    <!-- Voyage Summery -->
    <div *ngIf="showTabs" class="card shadow-sm ">
      <div *ngIf="volumes !== undefined" class="card-body">
        <table class="table table-sm table-striped table-bordered">
          <thead>
            <tr>
              <td class="bg-info text-center">Items</td>
              <td class="bg-primary text-center">Loading</td>
              <td class="bg-success text-center">Discharging</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="text-center">No. Of Ports</td>
              <td class="text-center">{{volumes.noLoadingPorts |
                number}}</td><td>{{volumes.noDischargePorts | number}}</td>
            </tr>
            <tr>
              <td class="text-center">Ports</td>
              <td class="text-center">
                <ng-container *ngFor="let p of volumes.loadPorts">
                  <span>{{p}}</span>
                  <br>
                </ng-container>
              </td>
              <td class="text-center">
                <ng-container *ngFor="let p of volumes.dischargePorts">
                  <span>{{p}}</span>
                  <br>
                </ng-container>
              </td>
            </tr>
            <tr>
              <td class="text-center">Weight (Ton)</td>
              <td class="text-center">
                <span>Real: <br>
                  <ng-container *ngFor="let p of volumes.loadingRealWeight">
                    {{p['product']}}: {{p['grossWeight'] | number}}<br>
                  </ng-container>
                </span>
                <span>Revise: <br>
                  <ng-container *ngFor="let p of volumes.loadingReviseWeight">
                    {{p['product']}}: {{p['grossWeight'] | number}}<br>
                  </ng-container>
                </span>
              </td>
              <td class="text-center">
                <ng-container *ngFor="let p of volumes.dischargeWeight">
                  {{p['product']}}: {{p['weight'] | number}}<br>
                </ng-container>
              </td>
            </tr>
            <tr>
              <td class="text-center">Berth Time (Hours)</td>
              <td class="text-center">{{volumes.loadingBerthTime | number}}
              </td><td>{{volumes.dischargeBerthTime | number}} </td>
            </tr>
            <tr>
              <td class="text-center">Laytime (Hours)</td>
              <td class="text-center">{{volumes.loadingLaytime | number}}
              </td><td>{{volumes.dischargeLaytime | number}} </td>
            </tr>
            <tr>
              <td class="text-center">Total Real Weight</td>
              <td class="text-center">{{volumes.allLoading | number}} </td>
              <td class="text-center">{{volumes.allDischarging | number}} </td>
            </tr>
          </tbody>
          <thead>
            <tr><td colspan="3"
                class="text-center bg-warning text-center">Differences</td></tr>
            <tr>
              <td class="text-center" colspan="2">Time between Loading Discharge
                (Days)</td>
              <td class="text-center">Weight Difference (Ton)</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="text-center"
                colspan="2">{{volumes.timeBetweenLoadingDis}} </td>
              <td>
                <ng-container *ngFor="let d of volumes?.diff">
                  <span>{{d['product']}}: {{d['diff'] | number}}</span><br>
                </ng-container>
              </td>
            </tr>
            <tr>
              <td class="text-center" colspan="2">Total Difference</td>
              <td class="text-center">{{volumes.allDiff | number}} </td>
            </tr>
          </tbody>
        </table>
        <span class="info">Weight differences are between Real BL and primary
          discharges</span>
      </div>
    </div>
  </div>
</div>

<div class="row">
    <div class="col-md-12">
        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
            id="loadingSOF"
            class="table table-sm table-striped table-bordered">
            <thead>
                <tr>
                    <th>Vessel Name</th>
                    <th>IMO</th>
                    <th>Voyage NO</th>
                    <th>Port</th>
                    <th data-bs-toggle="tooltip" data-bs-placement="top"
                        title={{toolTip1}}>{{sofName1}}</th>
                    <th data-bs-toggle="tooltip" data-bs-placement="top"
                        title={{toolTip2}}>{{sofName2}}</th>
                    <th>Duration(day-time)</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let sof of sofItems">
                    <td>{{sof.vesselName}}</td>
                    <td>{{sof.vesselIMO}}</td>
                    <td>{{sof.shipVoyageNo}}</td>
                    <td>{{formatPorts(sof.portOrganisationId)}}</td>
                    <td>{{dateTime (sof.portOrganisationId,
                        sof.startTime)}}</td>
                    <td>{{dateTime (sof.portOrganisationId, sof.endTime)}}</td>
                    <td>{{duration(sof.startTime, sof.endTime)}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
import {Component, EventEmitter, OnInit, ViewChild} from '@angular/core';

import * as L from 'leaflet';
import { Map } from 'leaflet';
import 'iso8601-js-period';
import 'leaflet.markercluster';
import 'leaflet-toolbar';
import 'leaflet-easyprint';
import 'leaflet-timedimension';
import 'leaflet.fullscreen';
import {VesselsService, Voyage} from '../vessels.service';
import * as moment from 'moment';
import {MessageLevel, PgMessagesService} from '../../../shared/services/pg-messages.service';
import {forkJoin, Subject} from 'rxjs';
import {DataTablesService} from '../../../shared/datatables/data-tables.service';
import {
  NoonReportsService,
  NoonReportV202005,
  SelectedLocation,
} from '../services/noon-reports.service';
import {DataTableDirective} from 'angular-datatables';
import {FleetStatusToolbarComponent} from './fleet-status-toolbar/fleet-status-toolbar.component';
import { Organisation, OrganisationService } from 'src/app/organisation/organisation.service';
import {DateRange} from '../../../shared/components';
import {
  PgDateRangeTitleComponent,
  PgDateRangeTitleEnum, PgTitleComponent,
  PgToolbarComponent
} from '../../../shared/components/title-bar/title-bar.component';


@Component({
  selector: 'app-fleet-status',
  templateUrl: './fleet-status.component.html',
})
export class FleetStatusComponent implements OnInit, PgTitleComponent, PgDateRangeTitleComponent, PgToolbarComponent {
  @ViewChild(DataTableDirective, {static: true}) dtElement: DataTableDirective;

  title = new EventEmitter<string>();
  dateRangeSubTitle = new EventEmitter<[DateRange, PgDateRangeTitleEnum]>();
  toolbarComponentType = FleetStatusToolbarComponent;
  toolbarComponent: FleetStatusToolbarComponent;
  _map: Map;
  ongoingVoyages: Voyage[];
  locations: SelectedLocation[];
  arrivalDepartures: NoonReportV202005[];
  ports: Organisation[];
  offices: Organisation[];


  _toolbar: any;
  _currentVesselsLayer: any;

  calculateDays = VesselsService.voyageLengthFromLoadingToDischarge;

  dtOptions: any;
  isDataTableInitialised = false;
  dtTrigger: Subject<any> = new Subject<any>();

  constructor(private _vesselsService: VesselsService,
              private _noonReportV202005sService: NoonReportsService,
              private _pgMessagesService: PgMessagesService,
              private _organisationService: OrganisationService) {
    this.dtOptions = DataTablesService.getOptionsWithNoColumnsEn();
  }

  ngOnInit() {
    this._organisationService.get().subscribe(organisations => {
      this.ports = organisations.filter(o => (o.location.latitude !== 0 && o.location.longitude !== 0)
        && (o.properties['isAnchorage'] === true || o.properties['isPort'] === true));
      this.offices = organisations.filter(o => (o.location.latitude !== 0 && o.location.longitude !== 0)
        && (o.properties['office'] === true));
      this.setupMap();
      this.refreshData();
    });
  }

  refreshData() {
    const dr = {
      startDate: moment().startOf('day').add(-7, 'days').toDate(),
      endDate: moment().endOf('day').toDate()
    };
    forkJoin([
      this._vesselsService.getLastActiveVesselVoyages(),
      this._noonReportV202005sService.getLatestLocation(dr.startDate),
      this._noonReportV202005sService.getArrivalsAndDepartures(dr)
    ]).subscribe(results => {
      this.ongoingVoyages = results[0];
      this.locations = this._noonReportV202005sService.groupLocations(results[1])
        .sort((a, b) => a.vesselName.localeCompare(b.vesselName));
      this.title.emit('Fleet status');
      this.dateRangeSubTitle.emit([dr, PgDateRangeTitleEnum.defaultTitle]);
      this.toolbarComponent.locations = this.locations;
      this.toolbarComponent.itemsSelected.subscribe(l => this.onItemSelected(l));

      this.arrivalDepartures = results[2].sort((a, b) =>
        a.date.timestamp - b.date.timestamp);
      if (!this.isDataTableInitialised) {
        this.dtTrigger.next(false);
        this.isDataTableInitialised = true;
      } else {
        this.dtElement.dtInstance.then((dtInstance) => {
          dtInstance.destroy();
          this.dtTrigger.next(false);
        });
      }

      if (this.locations.length === 0) {
        this._pgMessagesService.publishMessage({
          message: `Noon reports are missing`,
          topic: 'Noon reports',
          level: MessageLevel.Warning
        });
      } else {
        this.drawLatestLocations(this.locations);
      }
    });
  }

  setupMap() {
    if (!this._map) {
      const baseLayer = L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png', {
        attribution: `&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> &copy;
        <a href="https://carto.com/attributions">CARTO</a>`
      });

      const mapOptions = {
        zoomControl: false,
        zoom: 14,
        layers: [baseLayer]
      };

      this._map = L.map('mapid', mapOptions);
      this._map.scrollWheelZoom.disable();

      const baseMaps = {
        basemap: baseLayer
      };

      const layers = {
          ports: L.layerGroup(this.getPortLocationMarkerLayer(this.ports)),
          offices: L.layerGroup(this.getOfficeLocationMarkerLayer(this.offices))
      };

      L.control.layers(baseMaps,layers,{position: 'topleft'}).addTo(this._map);
      L.control.zoom({position: 'topleft'}).addTo(this._map);
      L.control.fullscreen().addTo(this._map);


      L.easyPrint({
        title: 'Print the map',
        position: 'topleft',
        sizeModes: ['A4Landscape']
      }).addTo(this._map);

      const component = this;
      const refreshAction = L.Toolbar2.Action.extend({
        options: {
          toolbarIcon: {
            html: '<i class="fa fa-window-maximize"></i>',
            tooltip: 'Reset bounds'
          }
        },
        addHooks:  () => {
          const bounds = component._noonReportV202005sService.getLocationBounds(component.locations);
          component._map.fitBounds(bounds);
        }
      });

      // const showPopupsAction = L2.Toolbar2.Action.extend({
      //   options: {
      //     toolbarIcon: {
      //       html: '<i class="fa fa-comment"></i>',
      //       tooltip: 'Show details'
      //     }
      //   },
      //   addHooks: function () { component.showPopups(); }
      // });

      L.Toolbar2.Action.extend({
        initialize(map, myAction) {
          this.map = map;
          this.myAction = myAction;
          L.Toolbar2.Action.prototype.initialize.call(this);
        },
        addHooks() {
          this.myAction.disable();
        }
      });

      this._toolbar = new L.Toolbar2.Control({
        position: 'topleft',
        // actions: [refreshAction, showPopupsAction]
        actions: [refreshAction]
      }).addTo(this._map);
    }
  }

  getPortLocationMarkerLayer(ports: Organisation[]) {
    return ports.map(p => {
      const colour =  'gray';
      const markerOptions: L.MarkerOptions = {
        icon: L.divIcon({
          html: `<i class="fa fa-anchor" style="font-size:15px; color:${colour}; opacity: 0.9">
                 </i><br><i style="color: ${colour}"></i>`,
          iconSize: [30, 30],
          className: 'portDivIcon'
        }),
      };
    return L.marker([p.location.latitude, p.location.longitude], markerOptions)
    .bindPopup(`${p.nameTranslations['en']}`, {maxWidth: 500, closeButton: false});
    });
  }

  getOfficeLocationMarkerLayer(office: Organisation[]) {
    return office.map(p => {
      const colour =  'gray';
      const markerOptions: L.MarkerOptions = {
        icon: L.divIcon({
          html: `<i class="fa fa-building" style="font-size:15px; color:${colour}; opacity: 0.9">
                 </i><br><i style="color: ${colour}"></i>`,
          iconSize: [30, 30],
          className: 'portDivIcon'
        }),
      };
    return L.marker([p.location.latitude, p.location.longitude], markerOptions)
    .bindPopup(`${p.nameTranslations['en']}`, {maxWidth: 500});
    });
  }

  onItemSelected(locations: SelectedLocation[]) {
    this.drawLatestLocations(locations.filter(r => r.selected));
  }

  drawLatestLocations(locations: SelectedLocation[]) {
    const bounds = this._noonReportV202005sService.getLocationBounds(locations);
    const vesselsLayer = this.getVesselsLatestLocationLayer(locations);

    if (this._currentVesselsLayer) {
      this._map.removeLayer(this._currentVesselsLayer);
    }

    this._currentVesselsLayer = vesselsLayer;

    this._map.fitBounds(bounds);
    this._map.addLayer(vesselsLayer);
  }


  private getVesselsLatestLocationLayer(reports: SelectedLocation[]) {
    const pointGeoJson = reports.map(r => (
      {
        type: 'Feature',
        properties: {
          name: r.vesselName,
          locations: r.locations,
          times: r.locations.map(n => n.date)
        },
        geometry: {
          type: 'Point',
          coordinates: r.locations.map(n =>
            [n.geoCoordinate.longitude, n.geoCoordinate.latitude])[0]
        }
      }
    ));

    return L.geoJSON(pointGeoJson as any, {
      pointToLayer: (feature) => {
        if (feature.geometry.coordinates) {
          return this._noonReportV202005sService.getVesselLocationMarkerLayer(feature.properties.locations[0]);
        }
      },
      filter: () => true
    });
  }

}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NewOrganisationComponent } from './new-organisation/new-organisation.component';
import { OrganisationsListComponent } from './organisations-list/organisations-list.component';

const routes: Routes = [
  { path: 'organisations/list/:parentOrganisationId', component: OrganisationsListComponent },
  { path: 'organisations/:mode/:organisationId', component: NewOrganisationComponent },
  { path: 'organisations/list', component: OrganisationsListComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OrganisationRoutingModule { }

import { Component, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { forkJoin } from 'rxjs';
import { DocumentService } from 'src/app/documents/documents.service';
import { Organisation, OrganisationService } from 'src/app/organisation/organisation.service';
import { LookupService } from 'src/app/shared/services/lookup.service';
import { Crew, CrewAppraisal, CrewChange } from 'src/app/transportation/vessels/crew-change/crew-change.service';
import { CrewChangeServices } from 'src/app/transportation/vessels/crew-change/crew-change.service';
import { CrewProfileToolbarComponent } from './crew-profile-toolbar/crew-profile-toolbar.component';
import { PgToolbarComponent } from 'src/app/shared/components/title-bar/title-bar.component';
import { saveAs } from 'file-saver';
import { TimelineItem } from 'src/app/shared/components/timeline/timeline.component';

declare let $: any;
// import * as bootstrap from 'bootstrap';


@Component({
  selector: 'app-crew-profile',
  templateUrl: './crew-profile.component.html',
})
export class CrewProfileComponent implements OnInit, PgToolbarComponent {

  title = new EventEmitter<string>();
  toolbarComponentType = CrewProfileToolbarComponent;
  toolbarComponent: CrewProfileToolbarComponent;
  crew: Crew;
  message: string;
  rankItem = [];
  edit: boolean;
  crewId: string;
  nationality: Organisation;
  _organisationsMap: Map<string, Organisation>;
  rate: any[];
  appraisals: CrewAppraisal[];

  crewChangeTimeline: TimelineItem[];
  previewFileId: string;
  filePreviewDialogModal: any | undefined;
  filePreviewTitle: string;

  constructor(
     private _crewChangeService: CrewChangeServices,
     private _activateRoute: ActivatedRoute,
     private _lookupService: LookupService,
     private _organisationService: OrganisationService,
     private _documentService: DocumentService) { }

  ngOnInit(): void {
    this.title.emit('Crew');
    this._activateRoute.paramMap.subscribe(p => {
      this.crewId = p.get('id');
      forkJoin([
        this._organisationService.getOrganisationIdToOrganisationMap(),
        this._lookupService.get('ranks'),
      ]).subscribe((result) => {
        this._organisationsMap = result[0];
        this.rankItem = result[1].items;
        this.initCrew();
      });
    });

    this.filePreviewDialogModal = $('#filePreviewModal').modal();
  }

  getOrganisation(id: string) {
    if (id) {
      return this._organisationsMap.get(id);
    }
  }

  initCrew() {
    if (this.crewId) {
      this.edit = true;
      this._crewChangeService.getOneCrewById(this.crewId).subscribe(x => {
        this.crew = x;
        this.appraisals = this.crew.crewChanges.filter(c => c.appraisals && c.appraisals.length > 0).flatMap(c => c.appraisals);
        this.rate = this.calculateRating(this.appraisals);
        this.crew.crewChanges = x.crewChanges.filter(crewChange => crewChange.isDeleted === false);
        this.toolbarComponent.crew= this.crew;
        this.nationality = this._organisationsMap.get(x.nationality);
        this.calculateAge();
        this.crewChangeTimeline = this.convertCrewChangeToTimeline(this.crew.crewChanges);
    });
    }
  }

  nationalityFormatter(data: Organisation) {
    return data.nameTranslations['en'];
  }

  calculateAge() {
    return  moment().diff(moment(this.crew.birthDate), 'year');
  }

  getStatus() {
    return this._crewChangeService.getCrewStatus(this.crew);
  }

  planTime(crewChange: CrewChange, selectedPlan: string) {
    if(this._organisationsMap) {
      return this._crewChangeService.planTime(crewChange, selectedPlan, this._organisationsMap);
    }
  }

  planPlace(crewChange: CrewChange, selectedPlan: string) {
    if(this._organisationsMap) {
      return this._crewChangeService.planPlace(crewChange, selectedPlan, this._organisationsMap);
    }
  }

  onboardTimeMonthsDays(crewChange: CrewChange) {
    return  this._crewChangeService.onboardTimeMonthsDays(crewChange);
  }

  travelTimeDays(crewChange: CrewChange) {
    return this._crewChangeService.travelTimeDays(crewChange);
  }

  getLastCrewChange(crewChanges: CrewChange[]) {
    if(crewChanges.length > 0) {
      return this._crewChangeService.getLastCrewChange(crewChanges);
    } else {
      return null;
    }
  }

  downLoadDocumnet(id) {
    this._documentService.download(id).subscribe(f => {
      saveAs(f.body, `${id}.pdf`);
    });
  }

  convertCrewChangeToTimeline(crewChanges: CrewChange[]) {
    const sorted = crewChanges
      .flatMap(crewChange => crewChange.item
        .sort((i1, i2) => i2.time - i1.time)
        .map(i => (
          {
            plan: i.plan,
            place: i.place,
            time: i.time,
            vesselName: crewChange.vesselName,
            rank: crewChange.rank,
          }
        )))
      .sort((i1, i2) => i2.time - i1.time);
      const t = sorted.map ((plan, index) =>  {
        const timeline: TimelineItem = {
          title: plan.plan,
          description: this.description(plan, sorted[index + 1]),
          date: moment(plan.time).format('YYYY-MM-DD'),
          iconStyles: (plan.plan ==='Off Board' || plan.plan === 'Outgoing Flight') ? 'bg-warning' : 'bg-success'
        };
        return timeline;
      });
    return t;
  }

  findDiff(plan, before, timeUnit) {
    if(before) {
      if(timeUnit === 'months') {
        return `${Math.round(+ moment(plan.time).diff(moment(before.time), 'months', true).toFixed(2))} months
        (${moment(plan.time).diff(moment(before.time), 'days', true).toFixed(2)} days)`;
      } else if (timeUnit === 'days') {
        return `${moment(plan.time).diff(moment(before.time), 'days', true).toFixed(2)} days
        (${moment(plan.time).diff(moment(before.time), 'hours', true).toFixed(2)} hours)`;
      }
    } else {
      return '';
    }
  }

  description(plan: any , before) {
    if(plan.plan === this._crewChangeService.getPlanningPhases()[0] && before) {
      return `${this._organisationsMap.get(plan.place).nameTranslations['en']} (Rest: ${this.findDiff(plan, before, 'months')})`;
    } else if(plan.plan === this._crewChangeService.getPlanningPhases()[0] && !before) {
      return `${this._organisationsMap.get(plan.place).nameTranslations['en']}`;
    } else if(plan.plan === this._crewChangeService.getPlanningPhases()[1]) {
      return `${plan.vesselName} in
      ${this._organisationsMap.get(plan.place).nameTranslations['en']} (Travel: ${this.findDiff(plan, before, 'days')}) `;
    } else if(plan.plan === this._crewChangeService.getPlanningPhases()[2]) {
      return `Onboard for ${this.findDiff(plan, before, 'months')}`;
    } else if(plan.plan === this._crewChangeService.getPlanningPhases()[3]) {
      return `${this._organisationsMap.get(plan.place).nameTranslations['en']} (Travel: ${this.findDiff(plan, before, 'days')})`;
    }
  }

  calculateRating(crewAppraisals?: CrewAppraisal[]) {
    return this._crewChangeService.calculateRating(crewAppraisals);
  }

  showFilePreview(fileId: string | undefined, title: string) {
    if(fileId) {
      this.filePreviewDialogModal.show();
      this.previewFileId = fileId;
      this.filePreviewTitle = title;
    }
  }

  closeFilePreview() {
    this.filePreviewDialogModal.hide();
    this.previewFileId = '';
  }
}

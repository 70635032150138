<div class="row">
  <div class="col-md-12">
    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
      id="crewChanges"
      class="table table-sm table-striped table-bordered">
      <thead>
        <tr>
          <th>Name</th>
          <th>Passport</th>
          <th>Sea Man</th>
          <th>Status</th>
          <th>Onsigns</th>
          <th>Rank</th>
          <th>Vessel Name</th>
          <th>Management</th>
          <th>Voyage Number</th>
          <th>Incoming</th>
          <th>On Board</th>
          <th>Off Board</th>
          <th>Outgoing</th>
          <th>Months Onboard</th>
          <th>Travel (Days)</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let crew of _crews">
          <td><a class="btn btn-link text-decoration-none text-capitalize"
              [routerLink]="['/vessels/crew-change/crew/profile/', crew.id]">{{crew.firstName}}
              {{crew.lastName}}</a></td>
          <td>
            {{crew.passportDetails.passportNumber}}
            <br>
            {{crew.passportDetails.passportExpirationDate}}
          </td>
          <td>
            {{crew.seamanDetails.seamanNumber}}
            <br>
            {{crew.seamanDetails.seamanExpirationDate}}
          </td>
          <td>{{getStatus(crew)}}</td>
          <td>{{numberOfCrewChanges(crew.crewChanges)}}</td>
          <ng-container
            *ngIf="crew.crewChanges.length > 0; then content else other_content"></ng-container>
          <ng-template #content>
            <td>{{getLastCrewChange(crew.crewChanges)?.rank}}</td>
            <td>{{crew.crewChanges[0].vesselName}}</td>
            <td>{{getOrganisationNameById(crew.crewChanges[0]?.contract?.managementOrganisationId)}}</td>
            <td>{{crew.crewChanges[0].voyageNumber}}</td>
            <td>
              {{planPlace(crew.crewChanges[0], 'Incoming Flight')}}
              <br />
              {{planTime(crew.crewChanges[0], 'Incoming Flight')}}
            </td>
            <td>
              {{planPlace(crew.crewChanges[0], 'On Board')}}
              <br />
              {{planTime(crew.crewChanges[0], 'On Board')}}
            </td>
            <td>
              {{planPlace(crew.crewChanges[0], 'Off Board')}}
              <br />
              {{planTime(crew.crewChanges[0], 'Off Board')}}
            </td>
            <td>
              {{planPlace(crew.crewChanges[0], 'Outgoing Flight')}}
              <br />
              {{planTime(crew.crewChanges[0], 'Outgoing Flight')}}
            </td>
            <td>{{onboardTimeMonthsDays(crew.crewChanges[0])}}</td>
            <td>{{travelTimeDays(crew.crewChanges[0])}}</td>
          </ng-template>
          <ng-template #other_content>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </ng-template>
        </tr>
      </tbody>
    </table>
  </div>
</div>
